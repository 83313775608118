import React from 'react'
import { Table } from 'semantic-ui-react'

export default function ClientReqDLCWageTableDetailAWage6({
  gumakStyle,
  centerStyle,
  inputWageStyle,
  inputCommentStyle,
  cAWage6,
  cAWEName6,
  cAWage6Comment,
  handelInputChange
}) {

  return (
    <Table.Row textAlign='center'>
      <Table.Cell className='gubun' style={centerStyle}>평균임금</Table.Cell>
      <Table.Cell className='gumak' style={centerStyle}><input style={inputWageStyle} name='cAWEName6' value = {cAWEName6} onChange= {(e) => handelInputChange(e)} /></Table.Cell>
      <Table.Cell className='gumak' style={gumakStyle}><input style={inputWageStyle} name='cAWage6' value = {cAWage6} onChange= {(e) => handelInputChange(e)} /></Table.Cell>
      <Table.Cell textAlign='left' colSpan='6' className='sulmung'><textarea style={inputCommentStyle} name='cAWage6Comment' value = { cAWage6Comment } onChange = {(e) => handelInputChange(e)}/></Table.Cell>
    </Table.Row>
  )
}
