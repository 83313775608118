import { format } from 'date-fns';
import { 
  CREATE_EVENT, 
  DELETE_EVENT, 
  UPDATE_EVENT, 
  FETCH_EVENTS, 
  LISTEN_TO_EVENT_CHAT, 
  CLEAR_COMMENTS, 
  LISTEN_TO_SELECTED_EVENT, 
  CLEAR_EVENTS, 
  SET_FILTER, 
  SET_START_DATE, 
  RETAIN_STATE,
  CLEAR_SELECTED_EVENT,
  CLEAR_EVENTS_TABLE,
  FETCH_EVENTS_TABLE,

  FETCH_MONTHLY_REPORTS,
  CLEAR_MONTHLY_REPORTS,
  CLEAR_SELECTED_MONTHLY_REPORT,
  LISTEN_TO_SELECTED_MONTHLY_REPORT,

  FETCH_COMPETENCIES,
  CLEAR_COMPETENCIES,
  SET_YEARMONTH_COMPETENCY,
  SEND_COMPETENCYREPORTID, 

  LISTEN_TO_SELECTED_RISK_SUSPECT_REPORT,
  CLEAR_SELECTED_RISK_SUSPECT_REPORT,
  CLEAR_RISK_SUSPECT_REPORTS,
  SET_YEARMONTH_RISK_SUSPECT,
  SEND_RISK_SUSPECT_REPORT_ID,
  FETCH_RISK_SUSPECT_REPORTS,

  SET_SELECTED_CONVERSATION_ID,
  ADD_MESSAGE,
  SET_CONVERSATIONS,
  SET_CONVERSATION_HISTORY,
  DELETE_CONVERSATIONS,

} from './eventConstants';

const thisYear = format(new Date(), 'yyyy')
const thisMonth = format(new Date(), 'MM')
const yearMonth = thisYear+thisMonth

const initialState = {
  events: [],
  eventsTable: [],
  comments: [],
  moreEvents: true,
  selectedEvent: null,
  lastVisible: null,
  filter: 'isHosting',
  startDate: new Date(),
  retainState: false,

  monthlyReports: [],
  selectedMonthlyReport: null,

  competencies: [],
  yearMonth: yearMonth,
  competencyReportId: '',

  riskSuspectReports: [],
  selectedRiskSuspectReport: null,
  riskSuspectReportId: '',
  yearMonthRS: yearMonth,

  sessionEstablished: false,
  conversations: {},
  selectedConversationId: null,


};

export default function eventReducer(state=initialState, {type, payload}) {
  switch (type) {
    case CREATE_EVENT:
      return {
        ...state,
        events: [...state.events, payload]
      };
    case UPDATE_EVENT:
      return {
        ...state,
        events: [...state.events.filter(evt => evt.id !== payload.id), payload]
      };
    case DELETE_EVENT:
      return {
        ...state,
        events: [...state.events.filter(evt => evt.id !== payload)]
      };
    case FETCH_EVENTS:
      return {
        ...state,
        events: [...state.events, ...payload.events],
        moreEvents: payload.moreEvents,
        lastVisible: payload.lastVisible,
      };
    case FETCH_EVENTS_TABLE:
      return {
        ...state,
        eventsTable: [...state.eventsTable, ...payload.eventsTable],
      };
    case LISTEN_TO_EVENT_CHAT:
      return {
        ...state,
        comments: payload,
      };
    case CLEAR_COMMENTS:
      return {
        ...state,
        comments: [],
      };
    case LISTEN_TO_SELECTED_EVENT:
      return {
        ...state,
        selectedEvent: payload
      };
    case CLEAR_SELECTED_EVENT:
      return {
        ...state,
        selectedEvent: null,
      };
    case CLEAR_EVENTS:
      return {
        ...state,
        events: [],
        moreEvents: true,
        lastVisible: null,
      };
    case CLEAR_EVENTS_TABLE:
      return {
        ...state,
        eventsTable: [],
      };
    case SET_START_DATE:
      return {
        ...state,
        retainState: false,
        moreEvents: true,
        startDate: payload,
      };
    case RETAIN_STATE:
      return {
        ...state,
        retainState: true,
      };
          
    case FETCH_MONTHLY_REPORTS:
      return {
        ...state,
        monthlyReports: [...state.monthlyReports, ...payload.monthlyReports],
      };

    case SET_FILTER:
      return {
        ...state,
        retainState: false,
        moreEvents: true,
        filter: payload,
      };
    case SET_YEARMONTH_COMPETENCY:
      return {
        ...state,
        yearMonth: payload,
      };
    case FETCH_COMPETENCIES:
      return {
        ...state,
        competencies: [...state.competencies, ...payload.competencies],
      };
    case CLEAR_COMPETENCIES:
      return {
        ...state,
        competencies: [],
      };
    case CLEAR_MONTHLY_REPORTS:
      return {
        ...state,
        monthlyReports: [],
      };
    case CLEAR_SELECTED_MONTHLY_REPORT:
      return {
        ...state,
        selectedMonthlyReport: null,
        };
    case LISTEN_TO_SELECTED_MONTHLY_REPORT:
      return {
        ...state,
        selectedMonthlyReport: payload
      };
    case SEND_COMPETENCYREPORTID:
        return {
          ...state,
          competencyReportId: payload
        };

    case FETCH_RISK_SUSPECT_REPORTS:
      return {
        ...state,
        riskSuspectReports: [...state.riskSuspectReports, ...payload.riskSuspectReports],
      };
    
    case CLEAR_SELECTED_RISK_SUSPECT_REPORT:
      return {
        ...state,
        selectedRiskSuspectReport: null,
        };

    case SEND_RISK_SUSPECT_REPORT_ID:
      return {
        ...state,
        riskSuspectReportId: payload
      };
    case SET_YEARMONTH_RISK_SUSPECT:
      return {
        ...state,
        yearMonthRS: payload,
      };

    case LISTEN_TO_SELECTED_RISK_SUSPECT_REPORT:
      return {
        ...state,
        selectedRiskSuspectReport: payload
      };
    case CLEAR_RISK_SUSPECT_REPORTS:
      return {
        ...state,
        riskSuspectReports: [],
      };

    case SET_SELECTED_CONVERSATION_ID:
      return {
        ...state,
        selectedConversationId: payload
      };

    case ADD_MESSAGE: {
      const { message, conversationId } = payload;
    
      // 새로운 conversations 객체 초기화
      let newConversations = { ...state.conversations };
    
      // 대화 찾기 및 메시지 추가
      if (newConversations[conversationId]) {
        // messages 배열이 존재하는지 확인
        if (!Array.isArray(newConversations[conversationId].messages)) {
          newConversations[conversationId].messages = [];
        }
        newConversations[conversationId].messages.push(message);
      } else {
        // 대화가 존재하지 않는 경우 새 대화 추가
        newConversations[conversationId] = { id: conversationId, messages: [message] };
      }
    
      return {
        ...state,
        conversations: newConversations
      };
    }
      

    case SET_CONVERSATIONS:
      return {
        ...state,
        conversations: payload,
        sessionEstablished: true,
      };
    

    case SET_CONVERSATION_HISTORY: {
      const { id, messages } = payload;
    
      // 대화 기록 업데이트
      let updatedConversations = { ...state.conversations };
    
      if (updatedConversations[id]) {
        updatedConversations[id] = {
          ...updatedConversations[id],
          messages: messages
        };
      } else {
        // 대화가 없는 경우 새로 추가
        updatedConversations[id] = { id, messages };
      }
    
      return {
        ...state,
        conversations: updatedConversations
      };
    }
      
    case DELETE_CONVERSATIONS:
      return {
        ...state,
        conversations: {},
        selectedConversationId: null,
      }
      
    case "UPDATE_CONVERSATIONS":
      return {
        ...state,
        conversations: payload,
      };
      
    default:
      return state;
  }
}