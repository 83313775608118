import React from 'react'
// import React, { useState } from 'react'
import { Link, useHistory } from 'react-router-dom';
import { Button, Segment } from 'semantic-ui-react';
import { delay } from '../../../app/common/util/util';

export default function PaydocuDetailedHeader({selectedPaydocu, isBizWorker}) {
  const history = useHistory();
  function personalConHnadler (id) {
    delay(1000);
    history.push(`/clientRDPersonalInfoAgree/${id}`)
  } 

  return (
    <>
      <Segment.Group>
        <Segment attached='bottom' clearing>
          <>
            {isBizWorker ? null :
              <Button onClick={() => personalConHnadler(selectedPaydocu?.workerInfo?.workerId)} color='teal' floated='right'>
                근로계약서 등 보기
              </Button>
            }
            <Button as={Link} to={`/paydocuDetailedPage/${selectedPaydocu?.id}`} color='purple' floated='right'>
              임금명세서
            </Button>
          </>
        </Segment>
      </Segment.Group>
    </>
  )
}
