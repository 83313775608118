import React from 'react';
import { josaGaType, josaUnType } from '../../../../../app/common/util/util';

export default function ClientReqDLCWorkContents({selectedClientReq4In, companyName, worker}) {
  const companyUn = josaUnType(companyName);
  const workerGa = josaGaType(worker);

  return (
    <div className="jau__contents">
      <span style={{fontSize : '1rem'}}><strong>{`제3조 근무장소`}</strong></span>
      <ul>
        <li>
          ① {` ${companyName}의 사업 또는 사업장 등을 ${workerGa} 본 계약서 제2조의 담당업무를 수행할 수 있는 장소로 하고, 
          ${companyUn} 사업상 필요에 따라 ${worker}의 근무장소를 변경할 수 있다.  `}
        </li>
      </ul>
    </div>
  )
}
