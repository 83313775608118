import React from 'react';
import ModalWrapper from '../../../app/common/modals/ModalWrapper';
import YouTube from 'react-youtube';

export default function PayPaperMinusTime() {

  const opts = {
    // height: '300%',
    width: '100%',
    playVars: {
      autoplay: 1
    }
  };

  return (
    <ModalWrapper size='large' header="AI 노무사 결근공제, 주휴공제, 지각, 조퇴, 연차 급여작업">
      <YouTube videoId='h6GEVRIciYM' opts={opts} />
    </ModalWrapper>
  )
}
