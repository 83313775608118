import React from 'react';
import { Table } from 'semantic-ui-react';
import { handleNaN } from '../../../../app/common/util/util';

export default function ClientRDayWorkerMonthWageTableBasicInfo({
	centerStyle,
	worker,
	decryptoData,
	numberToCommaString,
	roundUp,
	dayWorkerWage,
	dayWokerTimeWage,
	dayWorkerWorkingTimeDayTotal,
	roundUp2,
	dayWorkerDayTotalPaidConversedTime,
	numOfWorkers,
	dayWorkerWorkingTimeDayBasic,
	dayWorkerPaidHolidayLBTime,
	dayWorkerWorkingTimeDayOver,
	dayWorkerWorkingTimeDayNight,
}) {
	return (
		<>
			<Table.Row>
				<Table.Cell style={centerStyle}>
					성명 <br /> (생년월일)
				</Table.Cell>
				<Table.Cell style={centerStyle}>평일 일당</Table.Cell>
				<Table.Cell style={centerStyle}>통상시급</Table.Cell>
				<Table.Cell style={centerStyle}>
					1일 근로시간 <br /> (전체)
				</Table.Cell>
				<Table.Cell style={centerStyle}>
					1일 유급시간 <br />
					(전체){' '}
				</Table.Cell>
				<Table.Cell style={centerStyle}>1일 기본급시간</Table.Cell>
				<Table.Cell style={centerStyle}>1일 주휴수당</Table.Cell>
				<Table.Cell style={centerStyle}>1일 연장시간</Table.Cell>
				<Table.Cell style={centerStyle}>1일 야간시간</Table.Cell>
			</Table.Row>
			<Table.Row>
				<Table.Cell style={centerStyle}>
					{worker}
					<br /> {decryptoData?.workerSocialNumberFront}
				</Table.Cell>
				<Table.Cell style={centerStyle}>
					{numberToCommaString(roundUp(handleNaN(dayWorkerWage)))}
				</Table.Cell>
				<Table.Cell style={centerStyle}>
					{numberToCommaString(roundUp(handleNaN(dayWokerTimeWage)))}
				</Table.Cell>
				<Table.Cell style={centerStyle}>
					{roundUp2(handleNaN(dayWorkerWorkingTimeDayTotal))}
				</Table.Cell>
				<Table.Cell style={centerStyle}>
					{roundUp2(handleNaN(dayWorkerDayTotalPaidConversedTime))}
				</Table.Cell>
				<Table.Cell style={centerStyle}>
					{roundUp2(
						handleNaN(
							numOfWorkers >= 5
								? dayWorkerWorkingTimeDayBasic
								: dayWorkerWorkingTimeDayTotal
						)
					)}
				</Table.Cell>
				<Table.Cell style={centerStyle}>
					{roundUp2(handleNaN(dayWorkerPaidHolidayLBTime))}
				</Table.Cell>
				<Table.Cell style={centerStyle}>
					{roundUp2(handleNaN(dayWorkerWorkingTimeDayOver))}
					<br /> 연장환산 (
					{roundUp2(handleNaN(dayWorkerWorkingTimeDayOver * 1.5))}){' '}
				</Table.Cell>
				<Table.Cell style={centerStyle}>
					{roundUp2(handleNaN(dayWorkerWorkingTimeDayNight))}
					<br /> 야간환산 (
					{roundUp2(handleNaN(dayWorkerWorkingTimeDayNight * 0.5))})
				</Table.Cell>
			</Table.Row>
		</>
	);
}
