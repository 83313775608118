import { Formik, Form } from 'formik';
import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { Link, Redirect } from 'react-router-dom';
import { Button, Grid, Header, Segment } from 'semantic-ui-react'
import * as Yup from 'yup';
import MyTextInput from '../../../app/common/form/MyTextInput';
import MySelectInput from '../../../app/common/form/MySelectInput';
import {
  untactCustomerType,
  salesType,
  salesFailType,
  inupApplyOkNo,
  vauchorApplyOkNo,
  zeroPayOkNo,
  vauchorConfirmOkNo,
  inupSelectOkNo,
  jobanalysisOkNo,
  manuallDoneOkNo,
} from '../../../app/api/dropdownOption';
import MyDateInput from '../../../app/common/form/MyDateInput';
import useFirestoreDoc from '../../../app/hooks/useFirestoreDoc';
import { 
  addUntactToFirestore, 
  listenToUntactFromFirestore, 
  updateUntactInFirestore } from '../../../app/firestore/firestoreService';
import LoadingComponent from '../../../app/layout/LoadingComponent';
import { toast } from 'react-toastify';
import { clearSelectedUntact, listenToSelectedUntact } from '../untactActions';

export default function UntactForm({ match, history, location }) {
  const dispatch = useDispatch();
  const {selectedUntact} = useSelector(state => state.untactStore)
  const {loading, error} = useSelector(state => state.async);

  useEffect(() => {
    if (location.pathname !== '/createUntact') return;
    dispatch(clearSelectedUntact());
  }, [location.pathname, dispatch])

  const initialValues = selectedUntact ??  {
    clientName:'',
    untactCustomerType:'',
    personName:'',
    workerName:'',
    phone:'',
    fax:'',
    email:'',
    region:'',
    consultant:'',
    salesType:'',
    salesFailType:'',
    date:'',
    inupApplyOkNo:'',
    inupApplyDate:'',
    vauchorApplyOkNo:'',
    vauchorApplyDate:'',
    vauchorConfirmOkNo:'',
    vauchorConfirmDate:'',
    zeroPayOkNo:'',
    zeroPayDate:'',
    inupSelectOkNo:'',
    inupSelectDate:'',
    otherService:'',
    jobanalysisOkNo:'',
    jobanalysisDate:'',
    otherInupService:'',
    manuallDoneOkNo:'',
    manuallDoneDate:'',
  };

  const validationSchema = Yup.object({
    clientName: Yup.string().required(),
    untactCustomerType: Yup.string().required(),
    personName: Yup.string().required(),
    workerName: Yup.string().required(),
    phone: Yup.string().required(),
    region: Yup.string().required('지역을 선택하세요.'),
    email: Yup.string().required(),
    consultant: Yup.string().required(),
    salesType: Yup.string().required(),
    date: Yup.string().required('답변 근거를 입력하세요.'),
  });

  useFirestoreDoc({
    shouldExcute: match.params.id !== selectedUntact?.id && location.pathname !== '/createUntact',
    query: () => listenToUntactFromFirestore(match.params.id),
    data: untact => dispatch(listenToSelectedUntact(untact)),
    deps: [match.params.id, dispatch]
  })
  
  if (loading) 
    return <LoadingComponent content='Loading Untact service lists ...' />;
  if (error) return <Redirect to='/error' />;

  return (
    <Segment clearing>
      <Formik
        enableReinitialize
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={async (values, {setSubmitting}) => {
          try {
            selectedUntact 
            ? await updateUntactInFirestore(values)
            : await addUntactToFirestore(values);
            setSubmitting(false);
            history.push('/untacts');
          } catch (error) {
              console.log(error.message);
              toast.error(error.message);
              setSubmitting(false);
          }
        }}
      >
        {({isSubmitting, dirty, isValid, values}) => (
          <Form className='ui form'>
            <Header sub color='teal' content='Untact Vaucher Service Management' />
            <br />
            <hr />
            <Grid>
              <Grid.Column width={6}>
                <MyTextInput name='clientName' placeholder='거래처명' autoComplete='off' />
              </Grid.Column>
              <Grid.Column width={6}>
                <MySelectInput  name='untactCustomerType' placeholder='고객분류' options={untactCustomerType} />
              </Grid.Column>
              <Grid.Column width={4}>
                <MyTextInput name='personName' placeholder='상담 대상자/직위' autoComplete='off' />
              </Grid.Column>
            </Grid>
            <Grid>
              <Grid.Column width={4}>
                <MyTextInput name='workerName' placeholder='업무담당자/직위' autoComplete='off'/>
              </Grid.Column>
              <Grid.Column width={6}>
                <MyTextInput name='phone' placeholder='연락처' autoComplete='off' />
              </Grid.Column>
              <Grid.Column width={6}>
                <MyTextInput name='fax' placeholder='Fax번호' autoComplete='off' />
              </Grid.Column>
 
            </Grid>
            <Grid>
              <Grid.Column width={7}>
                <MyTextInput name='email' placeholder='담당자 이메일' autoComplete='off' />
              </Grid.Column>
              <Grid.Column width={3}>
                <MyTextInput name='region' placeholder='지역' />
              </Grid.Column>
              <Grid.Column width={6}>
                <MyTextInput name='consultant' placeholder='컨설턴트' autoComplete='off' />
              </Grid.Column>
            </Grid>
            <Grid>
              <Grid.Column width={8}>
                <MySelectInput name='salesType' placeholder='영업시 방문 등 상담 형식' options={salesType} />
              </Grid.Column>
              <Grid.Column width={8}>
              <MyDateInput 
                  name='date' 
                  placeholderText='달력 사용( 또는 0000-00-00  형식으로 입력요망)'
                  timeFormat='HH:mm'
                  showTimeSelect
                  timeCaption='time'
                  dateformat='yyyy-MM-dd'
                  autoComplete='off'
                />
              </Grid.Column>
            </Grid>
            <Grid>
            <Grid.Column width={16}>
                <MySelectInput  name='salesFailType' placeholder='영업성공 또는 불발사유' options={salesFailType} />
              </Grid.Column>
            </Grid>
            <br /> <hr /> <br />
            <Grid>
              <Grid.Column width={8}>
                <MySelectInput 
                  name='inupApplyOkNo'
                  placeholder='지원참여(인업) 신청여부' 
                  options={inupApplyOkNo} 
                />
              </Grid.Column>
              <Grid.Column width={8}>
                <MyDateInput 
                  name='inupApplyDate' 
                  placeholderText='달력 사용( 또는 0000-00-00  형식으로 입력요망)'
                  timeFormat='HH:mm'
                  showTimeSelect
                  timeCaption='time'
                  dateformat='yyyy-MM-dd'
                  autoComplete='off'
                />
              </Grid.Column>
            </Grid>

            <Grid>
              <Grid.Column width={8}>
                <MySelectInput 
                  name='vauchorApplyOkNo'
                  placeholder='중기부 바우처 신청 여부' 
                  options={vauchorApplyOkNo} 
                />
              </Grid.Column>
              <Grid.Column width={8}>
                <MyDateInput 
                  name='vauchorApplyDate' 
                  placeholderText='달력 사용( 또는 0000-00-00  형식으로 입력요망)'
                  timeFormat='HH:mm'
                  showTimeSelect
                  timeCaption='time'
                  dateformat='yyyy-MM-dd'
                  autoComplete='off'
                />
              </Grid.Column>
            </Grid>
            <Grid>
              <Grid.Column width={8}>
                <MySelectInput 
                  name='vauchorConfirmOkNo'
                  placeholder='수요기업 선정 여부' 
                  options={vauchorConfirmOkNo} 
                />
              </Grid.Column>
              <Grid.Column width={8}>
                <MyDateInput 
                  name='vauchorConfirmDate' 
                  placeholderText='달력 사용( 또는 0000-00-00  형식으로 입력요망)'
                  timeFormat='HH:mm'
                  showTimeSelect
                  timeCaption='time'
                  dateformat='yyyy-MM-dd'
                  autoComplete='off'
                />
              </Grid.Column>
            </Grid>

            <Grid>
              <Grid.Column width={8}>
                <MySelectInput 
                  name='zeroPayOkNo'
                  placeholder='제로페이 구매여부' 
                  options={zeroPayOkNo} 
                />
              </Grid.Column>
              <Grid.Column width={8}>
              <MyDateInput 
                  name='zeroPayDate' 
                  placeholderText='달력 사용( 또는 0000-00-00  형식으로 입력요망)'
                  timeFormat='HH:mm'
                  showTimeSelect
                  timeCaption='time'
                  dateformat='yyyy-MM-dd'
                  autoComplete='off'
                />
              </Grid.Column>
            </Grid>

            <Grid>
              <Grid.Column width={5}>
                <MySelectInput 
                  name='inupSelectOkNo'
                  placeholder='인업 선택 여부' 
                  options={inupSelectOkNo} 
                />
              </Grid.Column>
              <Grid.Column width={5}>
              <MyDateInput 
                  name='inupSelectDate' 
                  placeholderText='달력 사용( 또는 0000-00-00  형식으로 입력요망)'
                  timeFormat='HH:mm'
                  showTimeSelect
                  timeCaption='time'
                  dateformat='yyyy-MM-dd'
                  autoComplete='off'
                />
              </Grid.Column>
              <Grid.Column width={6}>
                <MyTextInput 
                  name='otherService' 
                  placeholder='선택한 나머지 비대면 서비스' 
                  autoComplete='off'
                />
              </Grid.Column>
            </Grid>
            <Grid>
              <Grid.Column width={5}>
                <MySelectInput 
                  name='jobanalysisOkNo'
                  placeholder='직무분석 여부' 
                  options={jobanalysisOkNo} 
                />
              </Grid.Column>
              <Grid.Column width={5}>
              <MyDateInput 
                  name='jobanalysisDate' 
                  placeholderText='달력 사용( 또는 0000-00-00  형식으로 입력요망)'
                  timeFormat='HH:mm'
                  showTimeSelect
                  timeCaption='time'
                  dateformat='yyyy-MM-dd'
                  autoComplete='off'
                />
              </Grid.Column>
              <Grid.Column width={6}>
                <MyTextInput 
                  name='otherInupService' 
                  placeholder='인업제공 추가 서비스' 
                  autoComplete='off'
                />
              </Grid.Column>
            </Grid>
            <Grid>
              <Grid.Column width={8}>
                <MySelectInput
                  name='manuallDoneOkNo' 
                  placeholder='매뉴얼 납품 완료여부' 
                  options={manuallDoneOkNo} 
                />
              </Grid.Column>
              <Grid.Column width={8}>
              <MyDateInput 
                  name='manuallDoneDate' 
                  placeholderText='달력 사용( 또는 0000-00-00  형식으로 입력요망)'
                  timeFormat='HH:mm'
                  showTimeSelect
                  timeCaption='time'
                  dateformat='yyyy-MM-dd'
                  autoComplete='off'
                />
              </Grid.Column>
            </Grid>
            <br></br>
            <Button 
              loading={isSubmitting}
              disabled={!isValid || !dirty || isSubmitting}
              type='submit' 
              floated='right' 
              positive 
              content='Submit' 
            />
            <Button 
              disabled={isSubmitting}
              as={Link} to='/untacts'
              type='submit' 
              floated='right' 
              content='Cancel' 
            />
          </Form>
        )}
      </Formik>
    </Segment>
  )
}
