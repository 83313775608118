import React, { useEffect, useRef } from 'react';
import { useHistory } from 'react-router-dom';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const ErrorComponent = ({ errorMessage = '페이지를 찾을 수 없습니다. 초기화면으로 이동합니다.', redirectPath = '/clientReqs4In' }) => {
  const history = useHistory();
  const isMounted = useRef(true); // 컴포넌트 마운트 상태를 추적하는 ref

  useEffect(() => {
    // 컴포넌트가 마운트된 상태일 때만 toast를 표시합니다.
    if (isMounted.current) {
      toast.error(errorMessage, {
        onClose: () => {
          if (isMounted.current) {
            history.push(redirectPath); // 메시지가 닫힐 때, 컴포넌트가 마운트된 상태라면 리다이렉트
          }
        },
        autoClose: 2000, // 5초 후에 자동으로 메시지 닫기
      });
    }

    // 클린업 함수에서 컴포넌트의 마운트 상태를 false로 설정합니다.
    return () => {
      isMounted.current = false;
    };
  }, [errorMessage, redirectPath, history]);

  return <ToastContainer />;
};

export default ErrorComponent;
