import React from 'react';
import { Table } from 'semantic-ui-react';

function ClientWageTableTop5InsureExplain({
	values,
	numOfWorkers,
	handelInputChange,
	centerStyle,
	payPaperRetireYTOpen,
	payPaperMinusMoneyYTOpen,
	underInOut5daysWorker,
	centerStyleInOutBack,
	perDayMealTaxFree,
	centerStyleTeal,
	isRetireMonth,
	monthLBTime,
	hourOrdinaryWage,
	workerEnteredDate,
	workerRetiredDate,
	startToEndDate,
	payDay,
}) {
	return (
		<>
			<Table.Row>
				{values.plusDeductHealthCare ? (
					<Table.Cell
						textAlign='center'
						colSpan='6'
						className='gubun'
						style={centerStyle}>
						<input
							style={{ width: '100%' }}
							name='plusDeductHealthCareComment'
							placeholder='건강보험 정산금 설명(계산방법)'
							value={values.plusDeductHealthCareComment}
							onChange={(e) => handelInputChange(e)}
							autoComplete='off'
						/>
					</Table.Cell>
				) : null}
				{values.plusDeductOldCare ? (
					<Table.Cell
						textAlign='center'
						colSpan='6'
						className='gubun'
						style={centerStyle}>
						<input
							style={{ width: '100%' }}
							name='plusDeductOldCareComment'
							placeholder='장기요양보험료 정산금 설명(계산방법)'
							value={values.plusDeductOldCareComment}
							onChange={(e) => handelInputChange(e)}
							autoComplete='off'
						/>
					</Table.Cell>
				) : null}
			</Table.Row>
			<Table.Row>
				{values.plusDeductWorkTax ? (
					<Table.Cell
						textAlign='center'
						colSpan='6'
						className='gubun'
						style={centerStyle}>
						<input
							style={{ width: '100%' }}
							name='plusDeductWorkTaxComment'
							placeholder='근로소득세 정산금 설명(계산방법)'
							value={values.plusDeductWorkTaxComment}
							onChange={(e) => handelInputChange(e)}
							autoComplete='off'
						/>
					</Table.Cell>
				) : null}
				{values.plusDeductWorkRegionTax ? (
					<Table.Cell
						textAlign='center'
						colSpan='6'
						className='gubun'
						style={centerStyle}>
						<input
							style={{ width: '100%' }}
							name='plusDeductWorkRegionTaxComment'
							placeholder='지방소득세 정산금 설명(계산방법)'
							value={values.plusDeductWorkRegionTaxComment}
							onChange={(e) => handelInputChange(e)}
							autoComplete='off'
						/>
					</Table.Cell>
				) : null}
			</Table.Row>
			<Table.Row>
				{values.plusDeductWorkerCompanyLoan ? (
					<Table.Cell
						textAlign='center'
						colSpan='6'
						className='gubun'
						style={centerStyle}>
						<input
							style={{ width: '100%' }}
							name='plusDeductWorkerCompanyLoanComment'
							placeholder='대여, 가불금 등 회사가 빌려준돈 정산금 설명(계산방법)'
							value={values.plusDeductWorkerCompanyLoanComment}
							onChange={(e) => handelInputChange(e)}
							autoComplete='off'
						/>
					</Table.Cell>
				) : null}
				{values.plusDeductDuru ? (
					<Table.Cell
						textAlign='center'
						colSpan='6'
						className='gubun'
						style={centerStyle}>
						<input
							style={{ width: '100%' }}
							name='plusDeductDuruComment'
							placeholder='두루누리 설명(계산방법)'
							value={values.plusDeductDuruComment}
							onChange={(e) => handelInputChange(e)}
							autoComplete='off'
						/>
					</Table.Cell>
				) : null}
			</Table.Row>
			<Table.Row>
				{values.plusMinus4Ins ? (
					<Table.Cell
						textAlign='center'
						colSpan='6'
						className='gubun'
						style={centerStyle}>
						<input
							style={{ width: '100%' }}
							name='plusMinus4InsComment'
							placeholder='과거 4대보험 과오납'
							value={values.plusMinus4InsComment}
							onChange={(e) => handelInputChange(e)}
							autoComplete='off'
						/>
					</Table.Cell>
				) : null}
			</Table.Row>
			{numOfWorkers < 5 ? (
				<Table.Row>
					<Table.Cell textAlign='center' colSpan='9'>
						* 5인미만 사업장은 근로기준법에 따라 소정근로시간이 없으므로
						연장근로가 아닌 초과근로로 표시되고, 초과된 근로에 대한 1배만
						지급되며, 연장근로, 야간근로, 휴일근로 가산수당이 적용되지 않습니다.
					</Table.Cell>
				</Table.Row>
			) : null}
		</>
	);
}

export default ClientWageTableTop5InsureExplain;
