// import { format } from 'date-fns';
import React from 'react';
import { format } from 'date-fns/esm';
import { Button, Grid, Tab, Table } from 'semantic-ui-react'
import { printYouthSFSystem } from '../../../app/common/util/util';
import { Link } from 'react-router-dom';

export default function YouthSFSystemAgreeContents({profile}) {
  let today = new Date(); 

  return (
    <Tab.Pane>
      <Grid>
        <Grid.Column width={16}>
          <>
            <Button.Group>
              <Button 
                color='yellow' 
                style={{display: "inline", color: 'black', margin: '5px'}}
                as={Link} to={`/clientReqs4In`} 
              >직원 List
              </Button>
              <Button 
                color='blue' 
                style={{display: "inline", color: 'black', margin: '5px',}}
                onClick={() => printYouthSFSystem()}
              >중소기업 지원사업 통합시스템 활용 동의서 출력
              </Button>
            </Button.Group>
            <div id='youthSFSystem'>
              <table width ="100%" align = "center" border='1px solid black'>
                <Table.Body>
                  <Table.Row>
                    <Table.Cell align = "center"><h1 style={{textAlign : 'center', margin : '20px', fontSize : '30px'}}>{` 중소기업 지원사업 통합관리시스템 정보 활용을 위한 동의서 `}</h1></Table.Cell>
                  </Table.Row>
                </Table.Body>
              </table>
              <table width ="100%" align = "center">
                <Table.Body>

                  <Table.Row>
                    <Table.Cell>
                      □ 목 적
                      <br />
                      <br />
                      ①정부와 지자체 중소기업 지원사업의 효율적 수행을 위해 운영하는 “중소기업 지원사업 통합관리시스템” 에서 신청기업 및 지원기업 지원이력 정보의 수집･조회 및 활용
                      <br />
                      <br />
                      ②동 통합관리시스템에서 관리하는 기업정보의 확인, 지원효과 분석, 통계관리 등 효율적인 중소기업 정책 수립을 위해 국세청･관세청･고용부 등에서 보유하고 있는 과세정보 등의 수집･활용
                      <br />
                      <br />
                      □ 수집･조회 및 활용 정보
                      <br />
                      <br />
                      ①(지원이력정보) 사업자등록번호 등 기업 식별정보 및 신청일, 지원금액 등 사업의 신청 및 수혜정보
                      <br />
                      <br />
                      ②(과세정보 및 행정정보 등)중소기업기본법 제20조의2에 따라 중소기업 지원사업에 신청한 기업의 과세정보(국세기본법 제81조의13 및 관세법 제116조), 고용정보(고용보험법 제2조의1), 직장가입자 수(국민건강보험법 제6조) 등
                      <br />
                      <br />
                      ③(인증정보) 기업의 인증･확인정보로서 신청일, 획득일, 유효기간 등
                      <br />
                      <br />
                      □ 수집･조회 및 활용 기관
                      <br />
                      <br />
                      ◦ 사업자가 사업 신청시 본 동의서를 제출한 시점 이후
                      <br />
                      <br />
                      <span style={{fontSize : "7px"}}>&nbsp;&nbsp;* 제공된 목적달성 후에는 중소기업 지원사업 통합관리시스템의 효율적인 운영을 위해 필요한 범위 내에서만 보유･이용</span>
                      <br />
                      <br />
                      ◦ 기업정보 수집 시점 : 신청 및 수혜기업의 사업 신청 이전 3개년부터 참여 이후 5년간
                      <br />
                      <br />
                      <span style={{fontSize : "7px"}}>&nbsp;&nbsp;* 보유 정보가 없을 경우 수집하지 않음</span>
                      <br />
                      <br />
                      ※ 본 동의서식의 동의서 징구는 ｢민법｣ 제114조의 행정행위의 대리권 행사방식의 현명주의를 적용하여, 중소기업 지원사업의 효율적인 운영을 위해 본 사업의 소관부처 및 수행기관이 대신하여 받는 것임을 알려 드립니다.
                      <br />
                      <br />
                      <Table>
                        <Table.Body>
                          <Table.Row>
                            <Table.Cell>
                              본인은 위 목적으로 동의서에 적시된 정보 및 기관에 한해 본인의 기업정보를 수집･조회 및 활용하는 것에 동의합니다.
                            </Table.Cell>
                          </Table.Row>
                        </Table.Body>
                      </Table>
                    
                    </Table.Cell>
                  </Table.Row>
                  <Table.Row>
                    <Table.Cell align = "center">{format(today, 'yyyy-MM-dd')}</Table.Cell>
                  </Table.Row>
                </Table.Body>
              </table>
              <br />
              <table  style={{width : "100%", height : "150", textAlign : "left"}}>
                <Table.Body>
                  <Table.Row>
                    <Table.Cell style={{width : "90%", textAlign : 'right'}}>
                      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                      동의자(회사) : {profile?.companyName} &nbsp;&nbsp;&nbsp;
                      <br />
                      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                      동의자(대표자) : {profile?.ceoName} &nbsp;&nbsp;&nbsp;
                    </Table.Cell>
                    <Table.Cell>
                      (인) 또는 서명
                    </Table.Cell>
                  </Table.Row>
                </Table.Body>
              </table>
            </div>
          </>
        </Grid.Column>
      </Grid>
    </Tab.Pane>
  );
};




// {/* <div>Member since: {format(profile?.createdAt, 'yyyy-MM-dd')}</div> */}
// <div style={{ marginBottom: 10 }}>
// <strong>
//   <br />
//   <div>대표자 성명 : {profile?.ceoName || null}</div>
//   <div>사업자등록번호 : {profile?.companyBizNumber || null}</div>
//   <div>업태 : {profile?.bizType || null}</div>
//   <div>종목 : {profile?.bizItem || null}</div>
// </strong>
// <br />
// <div>팩스번호 : {profile?.companyFax || null}</div>
// <div>주소 : {profile?.companyAddress || null}</div>
// <div>담당자 이름 : {profile?.staffName || null}</div>
// <div>담당자 이메일 : {profile?.staffEmail || null}</div>
// </div>