import React, { useEffect } from 'react';
import MyDateInput from '../../../../../app/common/form/MyDateInput';
import MyTextInput from '../../../../../app/common/form/MyTextInput';
import MyNumberInput from '../../../../../app/common/form/MyNumberInput';
import MyTimeInput from '../../../../../app/common/form/MyTimeInput';
import MySelectInput from '../../../../../app/common/form/MySelectInput';
import {
	construct,
	dayType,
	dayWorkerTaxOrNot,
	over14InWeek,
} from '../../../../../app/api/dropdownOption';
import { Checkbox } from 'semantic-ui-react';

import {
	getForeignerFromSocialNumber,
	timeErrorAlert,
} from '../../../../../app/common/util/util';
import CrwfDayWorkerBasicInfo from './CrwfDayWorkerBasicInfo';
import CrwfDayWorkerTimeAndWageInfo from './CrwfDayWorkerTimeAndWageInfo';

export default function CrwfDayWorkerInfo({
	selectedClientReq4In,
	currentUserProfile,
	values,
	workerRegistryType,
	dayWorker,
	timeWorker,
	bizIncomeWorker,
	openTax4In,
	setOpenTax4In,
	openPensionPayType,
	setOpenPensionPayType,
	openHealthCarePayType,
	setOpenHealthCarePayType,
	openNoJobPayType,
	setOpenNoJobPayType,
	openOldCarePayType,
	setOpenOldCarePayType,
	lawBaselowWageIncludePaidHoliday,
	setWeek14OverBLTimeV,
	week14OverBLTimeV,
}) {
	useEffect(() => {
		timeErrorAlert(
			values.daylyPay?.workingStartTime,
			values.daylyPay?.workingEndTime,
			values.daylyPay?.workingEndTimeNextDayOrNot
		);
	}, [
		values.daylyPay?.workingStartTime,
		values.daylyPay?.workingEndTime,
		values.daylyPay?.workingEndTimeNextDayOrNot,
	]);

	const isForiegner = getForeignerFromSocialNumber(
		values?.workerSocialNumberBack
	);

	return (
		<>
			<CrwfDayWorkerBasicInfo
				currentUserProfile={currentUserProfile}
				MyTextInput={MyTextInput}
				MyDateInput={MyDateInput}
				Checkbox={Checkbox}
				isForiegner={isForiegner}
				MySelectInput={MySelectInput}
				dayWorker={dayWorker}
				timeWorker={timeWorker}
				bizIncomeWorker={bizIncomeWorker}
			/>
			<CrwfDayWorkerTimeAndWageInfo
				selectedClientReq4In={selectedClientReq4In}
				MyTextInput={MyTextInput}
				MyNumberInput={MyNumberInput}
				over14InWeek={over14InWeek}
				MyTimeInput={MyTimeInput}
				dayType={dayType}
				MySelectInput={MySelectInput}
				workerRegistryType={workerRegistryType}
				dayWorker={dayWorker}
				timeWorker={timeWorker}
				bizIncomeWorker={bizIncomeWorker}
				openTax4In={openTax4In}
				setOpenTax4In={setOpenTax4In}
				openPensionPayType={openPensionPayType}
				setOpenPensionPayType={setOpenPensionPayType}
				openHealthCarePayType={openHealthCarePayType}
				setOpenHealthCarePayType={setOpenHealthCarePayType}
				openNoJobPayType={openNoJobPayType}
				setOpenNoJobPayType={setOpenNoJobPayType}
				openOldCarePayType={openOldCarePayType}
				setOpenOldCarePayType={setOpenOldCarePayType}
				construct={construct}
				dayWorkerTaxOrNot={dayWorkerTaxOrNot}
				values={values}
				lawBaselowWageIncludePaidHoliday={lawBaselowWageIncludePaidHoliday}
				week14OverBLTimeV={week14OverBLTimeV}
				setWeek14OverBLTimeV={setWeek14OverBLTimeV}
			/>
		</>
	);
}
