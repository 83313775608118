import React from 'react';
import ModalWrapper from '../../../app/common/modals/ModalWrapper';
import YouTube from 'react-youtube';

export default function EtcLeave() {

  const opts = {
    // height: '300%',
    width: '100%',
    playVars: {
      autoplay: 1
    }
  };

  return (
    <ModalWrapper size='large' header="AI 노무사 기타 개인휴직 등록">
      <YouTube videoId='96EBV6fKfpc' opts={opts} />
    </ModalWrapper>
  )
}
