import React from 'react';
import { Table } from 'semantic-ui-react';
import { josaUnType } from '../../../../../app/common/util/util';

export default function ClientReqDLCHeadRuleObey({worker, selectedClientReq4In, companyName, laborContractStyle}) {
  const workerUn = josaUnType(worker);

  return (
    <>
      <Table width ="100%" textAlign = "center">
        <Table.Body>
          <Table.Row>
            <Table.Cell textAlign = "center"><h1 style={{textAlign : 'center', margin : '20px', fontSize : '20px'}}>{` 복무 준수, 정보보안 준수, 영업비밀 준수 서약서 `}</h1></Table.Cell>
          </Table.Row>
        </Table.Body>
      </Table>
        <div style={laborContractStyle}>
          {`
            ${workerUn}
            ${companyName}에 근무하는 동안 
              아래 사항에 대해 준수할 것을 서약합니다. 
          `}
        </div>
    </>
  )
}
