import React from 'react'

export default function ClientDash2022NewLaw({setOpenWhatsNew2022, openWhatsNew2022}) {
  return (
    <>
        <div style={{display: 'flex', alignItems: 'center', alignContent: 'center' }} onClick={() => setOpenWhatsNew2022(!openWhatsNew2022)}>
          <span className="material-icons" style={{fontSize: "25px"}}>notification_important</span>      
          <span style={{fontSize : '23px', color : 'darkBlue', cursor:'pointer'}}>2022년 <span style={{color : 'red'}}> 변경 노동법 안내</span></span>
        </div>
          <br />
          <div style={{cursor:'pointer'}} onClick={() => setOpenWhatsNew2022(!openWhatsNew2022)}>
            <h3>시행시기 1월 1일 : 최저임금 시간당 9,160원(209시간(주휴포함) 월급기준 1,914,440원</h3>
            <p>최저임금 계산시 정기상여금은 월 191,444원을 한도(월 1,914,440원의 10%), 복리후생비는 38,289원(월 1,914,440원의 2%)을 한도로 제외됨.</p>

            <h3>시행시기 1월 1일 : 공휴일 법정유급휴일 및 대체공휴일 5인이상 29인이하 사업장 확대 적용</h3>
            
            <h3>시행시기 1월 1일 : 육아휴직급여 인상</h3>
            <p>* 최초 3개월의 육아휴직급여가 통상임금의 80%에서 100%(첫째 달 200만원, 둘째 달 250만원, 세째 달 300만원 한도)로 인상 4개월부터는 80%(150만원 한도)</p>
            
            <h3>시행시기 1월 1일 : 가족돌봄 등을 위한 근로시간 단축 29인이하 1인이상 모든 사업장으로 확대 적용</h3>
            <p>* 6개월 이상 근무한 직원이 자식의 가족이나 본인의 건강을 돌보는 경우, 만 55세 이상의 직원이 은퇴를 준비하는 경우, 직원이 학업을 위해 1년에서 사용가능함.</p>
            <p>* 단축시 근로시간은 주당 15시간 이상이여야 하고 30시간을 넘길 수 없음.</p>
            <p>* 사업주가 직업안정기관에 구인신청을 하고 14일 이상 대체인력을 채용하기 위해 노력하였으나 대체인력을 채용못한 경우, 업무성격상 근로시간을 분할하여 수행하기 곤란하거나 가족돌봄등 근로시간 단축 종료일로부터 년이 지나지 않은 근로자가 시청한 경우 단축을 허용하지 않을 수 있음.</p>
            
            <h3>시행시기 1월 1일 : 직원수 50인이상 사업장 전체 직원의 3.4%에서 3.6%로 장애인 의무고용률 인상</h3>
            <p>* 직장 내 장애인 인식개선 교육의 실효성 확보를 위해 노동부 장관은 50인이상 사업주에 대해 교육 실시 결과제출을 명할 수 있음.</p>
            <p>* 5인이상 사업장은 장애인 인식개선 교육 실시자료를 3년간 보관해야 함.</p>

            <h3>시행시기 1월 1일 : 건강보험료 6.86%에서 6.99%로 인상(회사 3.495% + 직원 3.495%)</h3>
            <p>* 장기요양보험료는 건보료의 11.52%에서 12.27%로 인상.</p>
            
            <h3>시행시기 1월 27일 : 직원수 50인이상 사업장 중대재해 발생시 책임자 등 처벌</h3>
            <p>* 중대산업재해는 사망자가 1명이상 발생하거나 동일한 원인으로 6개월 이상 치료가 필요한 부상자가 2명이상 발생, 동일한 유해요인으로 발생하는 직원성 질병자가 1년에 3명이상 발생할 경우를 말함.</p>
            <p>* 중대시민재해는 특정한 원료나 제조물, 공중이용시설, 대중교통수당의 설계와 제조, 설치, 관리상의 결함으로 인하여 사망자가 1명이상 발생하거나 동일한 사고로 2개월 이상 치료가 필요한 부상자가 10명이상 발생, 동일한 원인으로 3개월 이상 치료가 필요한 질병자가 10명이상 발생할 경우를 말함.</p>
            <p>* 중대재해시 안전조치의무를 위반하여 사망한 경우 : 1년이상의 징역 또는 10억원 이하의 벌금(법인에 대해서는 50억원 이하의 벌금).</p>
            <p>* 중대재해시 부상자나 질병자가 발생한 경우 : 7년이하의 징역 또는 1억원 이하의 벌금(법인에 대해서는 10억원 이하의 벌금).</p>

            <h3>시행시기 5월 19일 : 직장 내 성희롱 피해자에 대해 사업주가 조치의무를 이행하지 않거나 성차별 행위가 발생한 경우 피해 근로자가 노동위원회에 구제신청할 수 있음.</h3>
            <p>* 노동위원회는 사업주에 대해 적절한 조치의 이행, 불리한 행위의 중지, 배상 등을 내용으로 하는 시정명령, 시정명령 불이행시 과태료 부과함. 또한 성차별이 인정될 경우 차별행위의 중지, 취업규칙, 단체협약 등의 근로조건 개선, 적절한 배상 등을 내용으로 하는 시정명령을 함.</p>

            <h3>시행시기 6월 16일 : 가사근로자의 고용개선 등에 관한 법률 제정 시행.</h3>
            <p>* 가사서비스 제공기관 인증제 시행, 근로조건 법정화(최저임금, 사회보험, 퇴직금 및 연차휴가 등), 가사서비스 이용계약 체결 등의 의무화.</p>

            <h3>시행시기 7월 1일 : 고용보험 중 실업급여 보험료 1.6%에서 1.8%로 인상(회사 0.9% + 직원 0.9%)</h3>

            <h3>시행시기 8월 18일 : 사업장 내 휴게시설 설치의무화(산업안전보건법 제128조의2)</h3>
            <p>* 관계수급인의 직원을 포함하여 사업의 종류 및 상시 근로자수 등 대통령령으로 정하는 기준에 해당하는 사업주는 고용노동부령으로 정하는 설치/관리기준에 맞는 휴게시설을 갖춰야 함.</p>
            <p>* 미설치시 1500만원 이하의 과태료, 휴게시설 설치/관리기준 미준수시 1000만원이하의 과태료 부과</p>


            <br />
            <button style={{fontSize : '15px', color: 'black', height: '30px', width: '200px', background: 'white'}} onClick={() => setOpenWhatsNew2022(!openWhatsNew2022)}>내용 닫기</button>
            <hr />
          </div>
        </>
  )
}
