// import { format } from 'date-fns';
import React from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Button, Grid } from 'semantic-ui-react';
import { addUserPrivateAgreementToFirestore } from '../../app/firestore/firestoreService4In';
import ClientPrivateProtectPaperContents from './ClientPrivateProtectPaperContents';

export default function ClientPrivateProtectPaper() {
  const history = useHistory();

  const {currentUserProfile} = useSelector(state => state.profile)
  const {clientInfo} = useSelector(state => state.companyReducer)


  const agentType = clientInfo?.agentType ? clientInfo?.agentType : currentUserProfile?.agentType;
  const referralAgentType = clientInfo?.referralAgentType ? clientInfo?.referralAgentType : currentUserProfile?.referralAgentType;

  async function userPrivateAgreementComfirmF () {
    history.goBack();
    await addUserPrivateAgreementToFirestore();
  };
  return (
    <>
      <Button color='facebook'onClick={() => userPrivateAgreementComfirmF()}>개인정보처리방침에 동의합니다.</Button>
      <Grid>
        <Grid.Column width={16}>
          <ClientPrivateProtectPaperContents currentUserProfile={currentUserProfile} agentType={agentType} referralAgentType={referralAgentType} />
        </Grid.Column>
      </Grid>
      <Button color='facebook' onClick={() => userPrivateAgreementComfirmF()}>개인정보처리방침에 동의합니다.</Button>
    </>
  )
}
