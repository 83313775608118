import { useState, useEffect } from 'react';

export default function useScrollRestoration() {
	const [scrollPosition, setScrollPosition] = useState(null);

	function saveScrollPosition() {
		setScrollPosition(window.scrollY);
		sessionStorage.setItem('scrollPosition', window.scrollY);
	}

	function restoreScrollPosition() {
		const storedScrollPosition = sessionStorage.getItem('scrollPosition');
		if (storedScrollPosition !== null) {
			window.scrollTo(0, parseInt(storedScrollPosition, 10));
		} else if (scrollPosition !== null) {
			window.scrollTo(0, scrollPosition);
		}
	}

	useEffect(() => {
		window.addEventListener('beforeunload', saveScrollPosition);

		return () => {
			window.removeEventListener('beforeunload', saveScrollPosition);
		};
	}, [scrollPosition]);

	return { saveScrollPosition, restoreScrollPosition };
}
