import moment from 'moment';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Label } from 'semantic-ui-react';
import {
	lowWageOKComment,
	lowWageUnderWonComment,
	lowWageViolationComment,
	numberToCommaString,
	restOKComment,
	restViolationDashboardComment,
	specialPermission52Comment,
	under5Comment,
	week52OKComment,
	week52ViolationComment,
} from '../../../../../app/common/util/util';
import { useHistory } from 'react-router-dom/cjs/react-router-dom';
import { clearPaydocu } from '../../../../companyManage/companyActions';

export default function ClientReqDLCJudgeWageTable({
	daylyTimePay,
	selectedClientReq4In,
	handleFormSubmit,
	values,
	isDayOrTimeWorker,
	numOfWorkDaysMatch,
	sumOfCountWorkDays,
	selectedPaydocuFirst,
	isBizWorker,
}) {
	const history = useHistory();
	const dispatch = useDispatch();
	const [loading, setLoading] = useState(false); // 로딩 상태 추가

	const { currentUserProfile } = useSelector((state) => state.profile);
	const { clientInfo } = useSelector((state) => state.companyReducer);

	const judge52 = selectedClientReq4In?.lawBase?.judge52;
	const restTotalViolation = selectedClientReq4In?.lawBase?.restTotalViolation;
	const judgeLowestWage = selectedClientReq4In?.lawBase?.judgeLowestWage;
	const insufficientWage = selectedClientReq4In?.lawBase?.insufficientWage;
	const numOfWorkers = selectedClientReq4In?.numOfWorkers;
	const over52permission = clientInfo?.over52permission
		? clientInfo?.over52permission
		: currentUserProfile?.over52permission;
	const dayWorkerInsufficientWage =
		selectedClientReq4In?.dayWorker?.insufficientDayWage;
	const retiredYearMonth = moment(
		selectedClientReq4In?.retiredDate ? selectedClientReq4In?.retiredDate : ''
	).format('YYYYMM');
	const thisYearMonth = values.thisYear + values.thisMonth;

	// Define submitFunc here
	const submitFunc = async () => {
		let isMounted = true;

		try {
			if (isMounted) setLoading(true); // Start loading only if component is mounted
			await handleFormSubmit(values); // Call the form submission function
			if (isMounted) {
				alert(
					`${selectedClientReq4In?.worker.name}의 ${values.thisYear}년 ${
						values.thisMonth
					}월 ${isBizWorker ? '사업소득명세서' : '임금명세서'}를 저장했습니다.`
				);
			}
		} catch (error) {
			console.error('저장 중 오류 발생:', error); // Log any errors
		} finally {
			if (isMounted) setLoading(false); // Stop loading if component is still mounted
		}

		if (
			isMounted &&
			selectedPaydocuFirst?.info?.yearMonth === thisYearMonth &&
			selectedPaydocuFirst?.workerInfo.workerId === selectedClientReq4In?.id
		) {
			dispatch(clearPaydocu()); // Clear the document only if component is mounted
		}

		// Cleanup function to set isMounted to false if the component unmounts
		return () => {
			isMounted = false;
		};
	};

	function manageClientRMonthWageTableHnadler() {
		history.push(`/paydocus`);
	}

	return (
		<>
			{/* <Button loading>Loading</Button> */}
			{isBizWorker ? (
				<>
					<Button
						type='button'
						color='blue'
						disabled={retiredYearMonth < thisYearMonth}
						loading={loading}
						onClick={() => submitFunc()}>
						{values.thisYear}년 {values.thisMonth}월 사업소득 명세서 저장
					</Button>
					<Button
						style={{ marginLeft: '1rem' }}
						type='button'
						onClick={() => manageClientRMonthWageTableHnadler()}
						color='red'>
						사업소득 명세서 작업 돌아가기
					</Button>
				</>
			) : (
				<>
					{isDayOrTimeWorker ? (
						<>
							{daylyTimePay > 0 ? (
								<>
									<Button
										type='button'
										color='blue'
										disabled={retiredYearMonth < thisYearMonth}
										loading={loading}
										onClick={() => submitFunc()}>
										{values.thisYear}년 {values.thisMonth}월 임금대장 저장
									</Button>
									<Button
										style={{ marginLeft: '1rem' }}
										type='button'
										onClick={() => manageClientRMonthWageTableHnadler()}
										color='red'>
										급여작업 돌아가기
									</Button>
								</>
							) : (
								<>
									<Button
										type='button'
										color='blue'
										disabled={
											!numOfWorkDaysMatch ||
											sumOfCountWorkDays === 0 ||
											retiredYearMonth < thisYearMonth
										}
										loading={loading}
										onClick={() => submitFunc()}>
										{values.thisYear}년 {values.thisMonth}월 임금대장 저장
									</Button>
									<Button
										style={{ marginLeft: '1rem' }}
										type='button'
										onClick={() => manageClientRMonthWageTableHnadler()}
										color='red'>
										급여작업 돌아가기
									</Button>
								</>
							)}
							{daylyTimePay > 0 ? null : (
								<>
									<Label
										content={
											dayWorkerInsufficientWage
												? lowWageViolationComment()
												: lowWageOKComment()
										}
									/>
									{dayWorkerInsufficientWage ? (
										<Label
											content={
												dayWorkerInsufficientWage > 0
													? '일급기준' +
													  numberToCommaString(dayWorkerInsufficientWage) +
													  lowWageUnderWonComment()
													: ''
											}
										/>
									) : null}
									{!numOfWorkDaysMatch || sumOfCountWorkDays === 0 ? (
										<p
											style={{
												fontSize: '1.1rem',
												color: 'red',
												marginTop: '1rem',
											}}>
											* 총 근무일수가 없거나 총 근무일수와 선택된 근무일수가
											일치하지 않으면 저장되지 않습니다.{' '}
										</p>
									) : null}
								</>
							)}
						</>
					) : (
						<>
							{over52permission === '52특례사업장' ? (
								<>
									{restTotalViolation !== 'undefined' ? (
										<>
											<Button
												type='button'
												disabled={retiredYearMonth < thisYearMonth}
												loading={loading}
												color='blue'
												onClick={() => submitFunc()}>
												{values.thisYear}년 {values.thisMonth}월 임금대장 저장
											</Button>
											<Button
												style={{ marginLeft: '1rem' }}
												type='button'
												onClick={() => manageClientRMonthWageTableHnadler()}
												color='red'>
												급여작업 돌아가기
											</Button>
										</>
									) : (
										<>
											<Button
												type='button'
												disabled={retiredYearMonth < thisYearMonth}
												loading={loading}
												color='blue'
												onClick={() => submitFunc()}>
												{values.thisYear}년 {values.thisMonth}월 임금대장 저장
											</Button>
											<Button
												style={{ marginLeft: '1rem' }}
												type='button'
												onClick={() => manageClientRMonthWageTableHnadler()}
												color='red'>
												급여작업 돌아가기
											</Button>
										</>
									)}
									{numOfWorkers < 5 ? (
										<Label
											content={
												judge52 === true
													? under5Comment()
													: specialPermission52Comment()
											}
										/>
									) : (
										<Label
											content={
												judge52 === true
													? week52OKComment()
													: specialPermission52Comment()
											}
										/>
									)}
									{restTotalViolation !== 'undefined' ? (
										<Label
											content={
												restTotalViolation === true
													? restOKComment()
													: restViolationDashboardComment()
											}
										/>
									) : null}
									<Label
										content={
											judgeLowestWage === true
												? lowWageOKComment()
												: lowWageViolationComment()
										}
									/>
									{!judgeLowestWage && (
										<Label
											content={
												insufficientWage > 0
													? '월급기준' +
													  numberToCommaString(insufficientWage) +
													  lowWageUnderWonComment()
													: ''
											}
										/>
									)}
									{retiredYearMonth < thisYearMonth ? (
										<p style={{ color: 'red' }}>
											퇴사월 다음달부터는 급여대장이 만들어지지 않습니다.
										</p>
									) : null}
								</>
							) : (
								<>
									{restTotalViolation !== 'undefined' ? (
										<>
											<Button
												type='button'
												color='blue'
												disabled={retiredYearMonth < thisYearMonth}
												loading={loading}
												onClick={() => submitFunc()}>
												{values.thisYear}년 {values.thisMonth}월 임금대장 저장
											</Button>
											<Button
												style={{ marginLeft: '1rem' }}
												type='button'
												onClick={() => manageClientRMonthWageTableHnadler()}
												color='red'>
												급여작업 돌아가기
											</Button>
										</>
									) : (
										<>
											<Button
												type='button'
												color='blue'
												disabled={retiredYearMonth < thisYearMonth}
												loading={loading}
												onClick={() => submitFunc()}>
												{values.thisYear}년 {values.thisMonth}월 임금대장 저장
											</Button>
											<Button
												style={{ marginLeft: '1rem' }}
												type='button'
												onClick={() => manageClientRMonthWageTableHnadler()}
												color='red'>
												급여작업 돌아가기
											</Button>
										</>
									)}
									{numOfWorkers < 5 ? (
										<Label
											content={
												judge52 === true
													? under5Comment()
													: week52ViolationComment()
											}
										/>
									) : (
										<Label
											content={
												judge52 === true
													? week52OKComment()
													: week52ViolationComment()
											}
										/>
									)}
									{restTotalViolation !== 'undefined' ? (
										<Label
											content={
												restTotalViolation === true
													? restOKComment()
													: restViolationDashboardComment()
											}
										/>
									) : null}
									<Label
										content={
											judgeLowestWage === true
												? lowWageOKComment()
												: lowWageViolationComment()
										}
									/>
									{!judgeLowestWage && (
										<Label
											content={
												insufficientWage > 0
													? '월급기준' +
													  numberToCommaString(insufficientWage) +
													  lowWageUnderWonComment()
													: ''
											}
										/>
									)}
									{retiredYearMonth < thisYearMonth ? (
										<p style={{ color: 'red' }}>
											퇴사월 다음달부터는 급여대장이 만들어지지 않습니다.
										</p>
									) : null}
								</>
							)}
						</>
					)}
				</>
			)}
		</>
	);
}
