import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import * as XLSX from 'xlsx';
import {
	isForeignerF,
	noJobPayDetailF,
	numberToCommaString,
	roundUp,
} from '../../../app/common/util/util';
import { decrypto } from '../../../app/firestore/firestoreService';
import { Button, Table } from 'semantic-ui-react';

export default function PaydocusDayWorkerTableListReport({
	paydocus,
	searchResults,
	setSearchResults,
	searchTerm,
	year,
	month,
	// filter
}) {
	const { currentUserProfile } = useSelector((state) => state.profile);
	const { clientInfo } = useSelector((state) => state.companyReducer);

	const id = clientInfo?.id
		? clientInfo?.id
		: clientInfo?.clientUid
		? clientInfo?.clientUid
		: currentUserProfile?.id;

	useEffect(() => {
		const searchedPaydocus = paydocus.filter((paydocu) => {
			return (
				paydocu?.workerInfo?.worker?.includes(searchTerm) ||
				paydocu?.info?.yearMonth?.includes(searchTerm) ||
				paydocu?.info?.year?.includes(searchTerm) ||
				paydocu?.info?.month?.includes(searchTerm) ||
				paydocu?.info?.workerDivisions?.includes(searchTerm) ||
				paydocu?.info?.workerTitles?.includes(searchTerm) ||
				paydocu?.companyInfo?.companyName?.includes(searchTerm)
			);
		});
		setSearchResults(searchedPaydocus);
	}, [searchTerm, setSearchResults, paydocus]);

	const exportToExcel = () => {
		// 테이블을 HTML로부터 복사하여 조작할 수 있도록 함
		const table = document.getElementById('table-to-xls').cloneNode(true);
		const rows = table.getElementsByTagName('tr');

		// 각 행에서 '회사명'과 '년월'에 해당하는 셀을 제거
		Array.from(rows).forEach((row) => {
			row.deleteCell(1); // '년월' 셀 인덱스
			row.deleteCell(0); // '회사명' 셀 인덱스
		});

		// 수정된 테이블로부터 Excel 워크시트 생성
		const workSheet = XLSX.utils.table_to_sheet(table, {
			cellDates: true,
			cellNF: false,
			cellText: false,
			cellStyles: true,
			sheetStubs: true,
		});

		// 숫자 앞의 '0'을 유지하기 위해 특정 셀의 데이터 형식을 조정
		const range = XLSX.utils.decode_range(workSheet['!ref']);
		for (let R = range.s.r; R <= range.e.r; ++R) {
			for (let C = range.s.c; C <= range.e.c; ++C) {
				const cell_address = { c: C, r: R };
				const cell_ref = XLSX.utils.encode_cell(cell_address);
				const cell = workSheet[cell_ref];
				if (cell && cell.t === 'n') {
					// 숫자 타입이면
					const cellValue = cell.v.toString();
					// "직종코드"가 있는 열을 찾아서 숫자 앞에 '0'을 추가하여 최소 길이 3 유지
					if (C === 9) {
						// 9은 "직종코드" 열의 인덱스
						if (cellValue.length < 3) {
							cell.t = 's'; // 문자열 타입으로 변경
							cell.v = cellValue.padStart(3, '0'); // 숫자 앞에 0을 추가하여 최소 길이 3 유지
						}
					}
					if (C === 2) {
						// 2은 "주민번호" 열의 인덱스
						if (cellValue.length < 13) {
							cell.t = 's'; // 문자열 타입으로 변경
							cell.v = cellValue.padStart(13, '0'); // 숫자 앞에 0을 추가하여 최소 길이 3 유지
						}
					}
				}
			}
		}

		const workBook = XLSX.utils.book_new();
		XLSX.utils.book_append_sheet(
			workBook,
			workSheet,
			`${year}년 ${month}월 신고대장`
		);

		// 생성된 Excel 파일 저장
		XLSX.writeFile(workBook, `${year}년 ${month}월 신고대장.xlsx`);
	};

	function createTableCells(workDates, days) {
		return Array.from({ length: days }, (_, i) => i + 1).map((day) => (
			<Table.Cell key={day}>{workDates?.includes(day) ? 1 : 0}</Table.Cell>
		));
	}

	function createTableHeaders(days) {
		return Array.from({ length: days }, (_, i) => i + 1).map((day) => (
			<Table.HeaderCell key={day}>{`${day}일`}</Table.HeaderCell>
		));
	}

	function extractDates(data) {
		return data.map((item) => parseInt(item.date));
	}

	return (
		<>
			{paydocus.length !== 0 && (
				<>
					<Button
						onClick={exportToExcel}
						color='blue'>{`${year}년 ${month}월 일용직 4대보험 신고 엑셀 다운`}</Button>
					<br />
					<br />
					<div
						style={{
							overflowX: 'auto',
							width: '100%',
							whiteSpace: 'nowrap',
							borderCollapse: 'collapse',
						}}>
						<Table
							style={{ textAlign: 'center', width: '100%', border: '1' }}
							id='table-to-xls'>
							<Table.Header>
								<Table.Row>
									<Table.HeaderCell>회사명</Table.HeaderCell>
									<Table.HeaderCell>년월</Table.HeaderCell>
									<Table.HeaderCell>보험구분</Table.HeaderCell>
									<Table.HeaderCell>성명</Table.HeaderCell>
									<Table.HeaderCell>주민등록번호</Table.HeaderCell>
									<Table.HeaderCell>신고월</Table.HeaderCell>
									<Table.HeaderCell>국적코드</Table.HeaderCell>
									<Table.HeaderCell>체류자격코드</Table.HeaderCell>
									<Table.HeaderCell>전화(지역번호)</Table.HeaderCell>
									<Table.HeaderCell>전화(국번)</Table.HeaderCell>
									<Table.HeaderCell>전화(뒷번호)</Table.HeaderCell>
									<Table.HeaderCell>직종코드</Table.HeaderCell>
									{createTableHeaders(31)}
									<Table.HeaderCell>근무일수</Table.HeaderCell>
									<Table.HeaderCell>일평균근로시간</Table.HeaderCell>
									<Table.HeaderCell>
										보수지급
										<br />
										기초일수
									</Table.HeaderCell>
									<Table.HeaderCell>
										보수총액
										<br />
										과세소득
									</Table.HeaderCell>
									<Table.HeaderCell>임금총액</Table.HeaderCell>

									<Table.HeaderCell>이직사유코드</Table.HeaderCell>
									<Table.HeaderCell>
										보험료부과
										<br />
										구분부호
									</Table.HeaderCell>
									<Table.HeaderCell>
										보험료부과
										<br />
										구분사유
									</Table.HeaderCell>

									<Table.HeaderCell>
										국세청
										<br />
										일용근로소득
										<br />
										신고여부
									</Table.HeaderCell>
									<Table.HeaderCell>지급년월</Table.HeaderCell>
									<Table.HeaderCell>
										총지급액 <br />
										(과세소득)
									</Table.HeaderCell>
									<Table.HeaderCell>비과세소득</Table.HeaderCell>
									<Table.HeaderCell>소득세</Table.HeaderCell>
									<Table.HeaderCell>지방소득세</Table.HeaderCell>
								</Table.Row>
							</Table.Header>
							<Table.Body>
								{searchResults.map((searchResult, index) => {
									const decryptedSocialNumber = `${decrypto(
										searchResult?.workerInfo?.workerSocialNumberFront,
										String(process.env.CRYPTO_KEY)
									)}${decrypto(
										searchResult?.workerInfo?.workerSocialNumberBack,
										String(process.env.CRYPTO_KEY)
									)}`;
									const dayInsurConfig =
										searchResult?.dayWorkerInfo?.dayWorkerCalNoJobInsurance >
											0 || searchResult?.workerInfo?.netAgeEntered > 65
											? '5'
											: '1';
									const isForeigner = isForeignerF(
										searchResult?.workerInfo?.workerSocialNumberBack,
										String(process.env.CRYPTO_KEY)
									);
									return (
										(searchResult?.companyInfo?.agentUid === id ||
											searchResult.companyInfo.companyId === id) &&
										searchResult?.dayWorkerInfo?.dayWorker && (
											<Table.Row key={searchResult.id}>
												<Table.Cell>
													{searchResult?.companyInfo?.companyName}
												</Table.Cell>
												<Table.Cell>{searchResult?.info?.yearMonth}</Table.Cell>
												<Table.Cell>{dayInsurConfig}</Table.Cell>
												<Table.Cell>
													{searchResult?.workerInfo?.worker}
												</Table.Cell>
												<Table.Cell>{decryptedSocialNumber}</Table.Cell>
												<Table.Cell>{searchResult?.info?.yearMonth}</Table.Cell>
												<Table.Cell>
													{isForeigner
														? searchResult?.workerInfo?.nationality
														: ''}
												</Table.Cell>
												<Table.Cell>
													{isForeigner
														? searchResult?.workerInfo?.visaType
														: ''}
												</Table.Cell>
												<Table.Cell></Table.Cell>
												<Table.Cell></Table.Cell>
												<Table.Cell></Table.Cell>
												<Table.Cell>
													{noJobPayDetailF(searchResult?.noJobPayDetail)}
												</Table.Cell>
												{createTableCells(
													extractDates(searchResult?.workerInfo?.workDates),
													31
												)}
												<Table.Cell>
													{searchResult?.workerInfo?.workDates.length}
												</Table.Cell>
												<Table.Cell>
													{roundUp(
														+searchResult?.dayWorkerInfo
															?.workingTimeDayRealAverage
													)}
												</Table.Cell>
												<Table.Cell>
													{searchResult?.workerInfo?.workDates.length}
												</Table.Cell>
												<Table.Cell>
													{numberToCommaString(
														roundUp(
															searchResult?.dayWorkerInfo?.dayWorkerTaxWageSum
														)
													)}
												</Table.Cell>
												<Table.Cell>
													{numberToCommaString(
														roundUp(
															searchResult?.dayWorkerInfo
																?.dayWorkerTotalMonthWageAmount
														)
													)}
												</Table.Cell>
												<Table.Cell>{1}</Table.Cell>
												<Table.Cell></Table.Cell>
												<Table.Cell></Table.Cell>
												<Table.Cell></Table.Cell>
												<Table.Cell></Table.Cell>
												<Table.Cell>
													{numberToCommaString(
														roundUp(
															searchResult?.dayWorkerInfo?.dayWorkerDeductWage
														)
													)}
												</Table.Cell>
												<Table.Cell></Table.Cell>
												{/* 비과세 소득 */}
												<Table.Cell>
													{numberToCommaString(
														roundUp(
															searchResult?.dayWorkerInfo
																?.dayWorkerUserFixedTax !== '' &&
																!isNaN(
																	searchResult?.dayWorkerInfo
																		?.dayWorkerUserFixedTax
																)
																? searchResult?.dayWorkerInfo
																		?.dayWorkerUserFixedTax
																: searchResult?.dayWorkerInfo?.dayWorkerCalTax
														)
													)}
												</Table.Cell>
												<Table.Cell>
													{numberToCommaString(
														roundUp(
															searchResult?.dayWorkerInfo
																?.dayWorkerUserFixedRegionTax !== '' &&
																!isNaN(
																	searchResult?.dayWorkerInfo
																		?.dayWorkerUserFixedRegionTax
																)
																? searchResult?.dayWorkerInfo
																		?.dayWorkerUserFixedRegionTax
																: searchResult?.dayWorkerInfo
																		?.dayWorkerCalRegionTax
														)
													)}
												</Table.Cell>
											</Table.Row>
										)
									);
								})}
							</Table.Body>
						</Table>
					</div>
				</>
			)}
		</>
	);
}
