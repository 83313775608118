import { Formik, Form } from 'formik';
import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { Link, Redirect } from 'react-router-dom';
import { Button, Grid, Header, Segment } from 'semantic-ui-react'
import * as Yup from 'yup';
import MyTextInput from '../../../app/common/form/MyTextInput';
import MyTextArea from '../../../app/common/form/MyTextArea';
import MySelectInput from '../../../app/common/form/MySelectInput';
import MyNumberInput from '../../../app/common/form/MyNumberInput';
import {
  communicationType, 
  customerType, 
  counselingCategory, 
  counselingBasis,
  answerType,
  workType,
} from '../../../app/api/dropdownOption';
import MyDateInput from '../../../app/common/form/MyDateInput';
import useFirestoreDoc from '../../../app/hooks/useFirestoreDoc';
import { 
  addEventToFirestore, 
  listenToEventFromFirestore, 
  updateEventInFirestore } from '../../../app/firestore/firestoreService';
import LoadingComponent from '../../../app/layout/LoadingComponent';
import { toast } from 'react-toastify';
import { clearSelectedEvent, listenToSelectedEvent } from '../eventActions';
import { getDownloadURL, getStorage, ref, uploadBytesResumable } from 'firebase/storage';

export default function EventForm({ match, history, location }) {
  const dispatch = useDispatch();
  const {selectedEvent} = useSelector(state => state.eventStore)
  const {currentUser} = useSelector(state => state.auth);
  const {loading, error} = useSelector(state => state.async);

  useEffect(() => {
    if (location.pathname !== '/createEvent') return;
    dispatch(clearSelectedEvent());
  }, [location.pathname, dispatch])

  const initialValues = selectedEvent ??  {
    date:'',
    billableHour:'',
    customerType:'',
    counselingType:'',
    region:'',
    clientName:'',
    personName:'',
    clientPhone:'',
    clientEmail:'',
    counselingCategory:'',
    question:'',
    workType:[],
    counselingBasis:'',
    counselingBasisContents:'',
    answer:'',
    answerType:'',
    government:'',
    governmentPerson:'',
    governmentEmail:'',
  };

  const validationSchema = Yup.object({
    date: Yup.string().required('일시를 입력하세요.'),
    billableHour: Yup.string().required('소요시간은 분단위로 숫자입력'),
    customerType: Yup.string().required('고객유형을 선택하세요.'),
    answerType: Yup.string().required('처리형식을 선택하세요.'),
    counselingType: Yup.string().required('형식을 선택하세요.'),
    region: Yup.string().required('지역을 입력하세요.'),
    clientName: Yup.string().required('기관명은 필수입니다.'),
    personName: Yup.string().required('요청자명은 필수입니다.'),
    counselingCategory: Yup.string().required('상담 분야는 필수 입니다.'),
    question: Yup.string().required('요청사항 또는 질문을 입력하세요.'),
    workType: Yup.array().of(Yup.string()).required('업무/사건/상담'),
    counselingBasis: Yup.string().required('답변 근거를 입력하세요.'),
    answer: Yup.string().required('답변을 입력하세요.'),
  });

  useFirestoreDoc({
    shouldExcute: match.params.id !== selectedEvent?.id && location.pathname !== '/createEvent',
    query: () => listenToEventFromFirestore(match.params.id),
    data: event => dispatch(listenToSelectedEvent(event)),
    deps: [match.params.id, dispatch]
  })
  
  if (loading) 
    return <LoadingComponent content='Loading event ...' />;
  if (error) return <Redirect to='/error' />;

  const onFileChange = async (e) => {
    const file = e.target.files[0];
    const storage = getStorage();
    const storageRef = ref(storage, `inupFiles/${currentUser.uid}/${file.name}`);
    
    const uploadTask = uploadBytesResumable(storageRef, file);
  
    uploadTask.on('state_changed', 
      (snapshot) => {
        // progress function can be used here to show the progress
      }, 
      (error) => {
        // Handle unsuccessful uploads
        console.error('Upload failed:', error);
      }, 
      () => {
        // Upload completed successfully, now we can get the download URL
        getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
          console.log('File available at', downloadURL);
        });
      }
    );
  }

  return (
    <>
    {/* <FirebaseStorageList /> */}
    <Segment clearing>
      <Formik
        enableReinitialize
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={async (values, {setSubmitting}) => {
          try {
            selectedEvent 
            ? await updateEventInFirestore(values)
            : await addEventToFirestore(values);
            setSubmitting(false);
            history.push('/events');
          } catch (error) {
              console.log(error.message);
              toast.error(error.message);
              setSubmitting(false);
          }
        }}
      >
        {({isSubmitting, dirty, isValid, values}) => (
          <Form className='ui form'>
            <Header sub color='teal' size='huge' content='INUP WORKS DATABASE' />
            <br />
            <hr />
            <br />
            <Grid>
              <Grid.Column width={5}>
                <MyDateInput
                  label='일시'
                  name='date' 
                  placeholderText='달력 사용( 또는 0000-00-00  형식으로 입력요망)'
                  timeFormat='HH:mm'
                  showTimeSelect
                  timeCaption='time'
                  dateFormat='yyyy-MM-dd'
                  autoComplete='off'
                />
              </Grid.Column>
              <Grid.Column width={2}>
                <MyNumberInput
                  label='소요시간(분)'
                  name='billableHour' 
                  placeholder='숫자만 입력' 
                  autoComplete='off'
                />
              </Grid.Column>
              <Grid.Column width={3}>
                <MySelectInput
                  label='대상'
                  name='customerType' 
                  placeholder='INUP, 자문사 등' 
                  options={customerType} 
                />
              </Grid.Column>
              <Grid.Column width={4}>
                <MySelectInput 
                  label='요청 커뮤니케이션'
                  name='counselingType' 
                  placeholder='방문/유선/E-mail 등'
                  options={communicationType} 
                />
              </Grid.Column>
              <Grid.Column width={2}>
                <MyTextInput 
                  label='지역'
                  name='region' 
                  placeholder='대상 지역'
                  autoComplete='off'
                />
              </Grid.Column>
            </Grid>
            <Grid>
              <Grid.Column width={5}>
                <MyTextInput
                  label='대상명칭' 
                  name='clientName' 
                  placeholder='소속명(INUP 또는 거래처 등)/일반 상담의 경우 상담자' 
                  autoComplete='off'
                />
              </Grid.Column>
              <Grid.Column width={3}>
                <MyTextInput
                  label='요청자명' 
                  name='personName' 
                  placeholder='성명 + 직책 포함' 
                  autoComplete='off'
                />
              </Grid.Column>
              <Grid.Column width={3}>
                <MyTextInput 
                  label='요청자 연락처'
                  name='clientPhone' 
                  placeholder='[선택] 유선 상담자는 필수' 
                  autoComplete='off'
                />
              </Grid.Column>
              <Grid.Column width={5}>
                <MyTextInput 
                  label='요청자 이메일'
                  name='clientEmail' 
                  placeholder='[선택] 이메일' 
                  autoComplete='off'
                />
              </Grid.Column>
            </Grid>
            <br />
            <hr />
            <br />
            <MyTextArea
              label='요청 내용'
              name='question' 
              placeholder='요청 및 질문 내용' 
              autoComplete='off'
              rows={3} 
            />
            <Grid>
              <Grid.Column width={3}>
                <MySelectInput
                  multiple
                  label='구분'
                  name='workType' 
                  placeholder='업무/사건/상담' 
                  options={workType} 
                />
              </Grid.Column>
              <Grid.Column width={5}>
                <MySelectInput
                  label='업무 분야'
                  name='counselingCategory' 
                  placeholder='자문/해고/임금체불/답변분야 등' 
                  options={counselingCategory} 
                />
              </Grid.Column>
              <Grid.Column width={4}>
                <MySelectInput 
                  label='처리 커뮤니케이션'
                  name='answerType' 
                  placeholder='유선/방문/온라인 등' 
                  options={answerType} 
                />
              </Grid.Column>
              <Grid.Column width={4}>
                <MySelectInput
                  label='주요 근거' 
                  name='counselingBasis' 
                  placeholder='법률/판례/공공기관 담당자 의견 등' 
                  options={counselingBasis} 
                />
              </Grid.Column>
            </Grid>
            <Grid>
              <Grid.Column width={5}>
                <MyTextInput
                  label='담당 관공서 명칭'
                  name='government' 
                  placeholder='[선택] 담당기관명' 
                  autoComplete='off'
                />
              </Grid.Column>
              <Grid.Column width={3}>
                <MyTextInput 
                  label='담당 기관 담당자명'
                  name='governmentPerson' 
                  placeholder='[선택] 기관이 있는 경우 필수' 
                  autoComplete='off'
                />
              </Grid.Column>
              <Grid.Column width={3}>
                <MyTextInput 
                  label='담당기관 연락처'
                  name='governmentPhone' 
                  placeholder='[선택] 기관이 있는 경우 필수' 
                  autoComplete='off'
                />
              </Grid.Column>
              <Grid.Column width={5}>
                <MyTextInput 
                  label='담당기관 이메일'
                  name='governmentEmail' 
                  placeholder='[선택] 기관이 있는 경우 필수' 
                  autoComplete='off'
                />
              </Grid.Column>
            </Grid>
            <br />
            <MyTextArea 
              label='업무/사건/상담 내용'
              name='answer' 
              placeholder='업무/사건/상담 내용' 
              rows={5} 
            />
            <input type='file' onChange={onFileChange} />
            <br />
            <br />
            <Button 
              loading={isSubmitting}
              disabled={!isValid || !dirty || isSubmitting}
              type='submit' 
              floated='right'
              positive 
              content='Submit' 
            />
            <Button 
              disabled={isSubmitting}
              as={Link} to='/events'
              type='submit' 
              floated='right' 
              content='Cancel' 
            />
          </Form>
        )}
      </Formik>
    </Segment>
    </>
  )
}
