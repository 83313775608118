import React from 'react';
import { Form, Formik } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Menu } from 'semantic-ui-react';
import { sendPaydocuCompnayName, setFilterPaydocu, setPaydocuWorkerName } from '../companyActions';
import * as Yup from 'yup';
import { toast } from 'react-toastify';
import MyTextInput from '../../../app/common/form/MyTextInput';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';

export default function PaydocuWorkerFilters({
    loading, 
    isWorker, 
    filter, 
    isBizWorkerUser,
    setPydocuWorkerCode
  }) {

  const history = useHistory();
  const dispatch = useDispatch();
  const {currentUserProfile} = useSelector(state => state.profile);

  function onClickHandler () {
    dispatch(setFilterPaydocu('isWorker'))
  }

  const handleNavigate = () => {
    history.push('/paydocuWorkerCode');
  };
  
  return (
    <>
      {isWorker ? 
        <Menu vertical size='large' style={{width: '100%'}}>
          <Menu.Item 
            content={isBizWorkerUser ? '사업소득명세서 불러오기' : '임금명세서 불러오기'} 
            active={filter === 'isWorker'}
            onClick={onClickHandler}
            disabled={loading}
            />
        </Menu>
        :
        <>
          <Formik
            initialValues = {{
              companyName: currentUserProfile?.companyName,
              paydocuWorkerName: '',
              pydocuWorkerCompanyCode: '',
            }}
            validationSchema={
              Yup.object({
                paydocuWorkerName: Yup.string().required('필수입니다.'),
              })}
              onSubmit={(values, {setSubmitting}) => {
              try {
                dispatch(setPaydocuWorkerName(values.paydocuWorkerName))
                dispatch(sendPaydocuCompnayName(filter))
                setPydocuWorkerCode(values.pydocuWorkerCode)
                setSubmitting(false);
              } catch (error) {
                console.log(error.message);
                toast.error(error.message);
                setSubmitting(false);
              }
            }}
          >
          {({isSubmitting, isValid, dirty})=>(
            <Form className='ui form'>
              <div style={{ display : 'flex', flexWrap : 'wrap', justifyContent: 'flex-start', alignItems : 'center', alignContent : 'flex-end'}}>
                <div className='margin' style={{width : '19.5%', marginRight : '3px'}}>
                  <MyTextInput 
                    label='직원명' 
                    name='paydocuWorkerName' 
                    autoComplete='off'
                  />
                </div>
                <div className='margin' style={{width : '19.5%', marginRight : '3px'}}>
                  <MyTextInput 
                    label='직원등록코드' 
                    name='pydocuWorkerCode' 
                    autoComplete='off'
                    placeholder='선택(동명이인인 경우)'
                  />
                </div>
                <Button
                  loading={isSubmitting}
                  disabled={isSubmitting || !isValid}
                  floated='left'
                  type='submit'
                  size='large'
                  positive
                  content='검색하기' 
                />
              </div>
              <Button
                type="button"
                size="small"
                color='teal'
                onClick={handleNavigate}
                style={{ marginTop: '10px' }}
              >
                직원등록코드[엑셀/직원코드]
              </Button>
            </Form>
          )}
          </Formik>
          <hr />
        </>
      }
    </>
  )
}
