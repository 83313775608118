import React, { useEffect } from 'react';
// import InfiniteScroll from 'react-infinite-scroller';
// import { Button } from 'semantic-ui-react';
// import { deleteCompanyInFirestore } from '../../../app/firestore/firestoreService4In';
// import { deleteCompany } from '../companyActions';
// import { asyncActionError } from '../../../app/async/asyncReducer';
// import { useDispatch, useSelector } from 'react-redux';
import { useSelector } from 'react-redux';

// import { Link } from 'react-router-dom';
import { format } from 'date-fns';
import * as XLSX from 'xlsx';
import { Button, Table } from 'semantic-ui-react';


export default function PaymentsInfoList({
  searchResults,
  setSearchResults,
  searchTerm,
  paymentsInfo
}) {

  const currentUserProfile = useSelector(state => state.profile.currentUserProfile)
  // const dispatch = useDispatch();
	// const { currentUserProfile } = useSelector(state => state.profile);
	
	//유저 권한(10: 회원가입, 20: 근로계약서 계약, 30: 아웃소싱 계약, 50: 노무사)
	// const viewLevel = currentUserProfile?.authLevel;	

  useEffect(() => {
    const searchedPaymentsInfo = paymentsInfo.filter(paymentInfo => {
      return paymentInfo?.companyName?.includes(searchTerm) 
        || currentUserProfile?.companyName?.includes(searchTerm) 
    });
    setSearchResults(searchedPaymentsInfo);    
  }, [searchTerm, setSearchResults, paymentsInfo, currentUserProfile])

  const exportToExcel = () => {
    let table = document.getElementById('table-to-xls');
    let workbook = XLSX.utils.table_to_book(table, { sheet: '회원사' });
    XLSX.writeFile(workbook, '회원사명부.xlsx');
  }

  return (        
    <>
      {paymentsInfo.length !== 0 && (
        <>
        {/* <InfiniteScroll
          pageStart={0}
          initialLoad={false}
        > */}
          <Button onClick={exportToExcel} color='green'>
            회원사 엑셀파일 전환
          </Button>
        
        <Table border='1' width ="100%" align = "center" id = 'table-to-xls'> 
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell>
                회사명
              </Table.HeaderCell>
              <Table.HeaderCell>
                결재일
              </Table.HeaderCell>
              <Table.HeaderCell>
                가격
              </Table.HeaderCell>
              <Table.HeaderCell>
                가입 이메일
              </Table.HeaderCell>
              <Table.HeaderCell>
                직원수
              </Table.HeaderCell>
              <Table.HeaderCell>
                등급
              </Table.HeaderCell>
              {/* <Table.HeaderCell>
                수정
              </Table.HeaderCell> */}
              {/* <Table.HeaderCell>
                삭제
              </Table.HeaderCell> */}
            </Table.Row>
          </Table.Header>
        {searchResults.map(searchResult => (
          <Table.Body>
            <Table.Row key={searchResult.id}>
              <Table.Cell>
                {searchResult?.companyName}
              </Table.Cell>
              <Table.Cell>
                {searchResult?.cardPayDate ? format(new Date(searchResult?.cardPayDate), 'yyyy-MM-dd') : null}
              </Table.Cell>
              <Table.Cell>
                {searchResult?.price}
              </Table.Cell>
              <Table.Cell>
                {searchResult?.email}
              </Table.Cell>
              <Table.Cell>
                {searchResult?.numOfWorkers}
              </Table.Cell>
              <Table.Cell>
                {searchResult?.authLevel}
              </Table.Cell>
              {/* <Table.Cell>
                {viewLevel >= 100 &&
                  <Button as={Link} to={`/manageCompany/${searchResult?.id}`} color='orange' floated='right'>
                    수정
                  </Button>}
              </Table.Cell> */}
              {/* <Table.Cell>
              {viewLevel >= 100 &&
                <Button 
                onClick={async () => {
                    try {
                      await deleteCompanyInFirestore(searchResult.id);
                      await dispatch(deleteCompany(searchResult.id));
                    } catch (error) {
                      dispatch(asyncActionError());
                    }
                  } } 
                  color='red'
                  floated='right' 
                  content='Delete'
                  />
                }
              </Table.Cell> */}
            </Table.Row>
          </Table.Body>
        ))}
        </Table>
        {/* </InfiniteScroll> */}
        </>
      )}
    </>
  )
}


