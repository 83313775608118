import React, { useState, useMemo, useEffect } from 'react';
import '../Roe1GeneralRules.css'; // CSS 파일 불러오기

const RuleSection = ({
	number,
	title,
	content,
	isOpen,
	toggleOpen,
	amended,
}) => {
	const formatContent = (content) => {
		if (!content) return null; // content가 undefined일 경우 null 반환

		let mainCount = 0; // 0부터 시작하여 CSS에서 올바르게 매핑될 수 있도록 설정

		return content.map((item, index) => {
			if (Array.isArray(item)) {
				return (
					<ol key={index} type='1'>
						{item.map((subItem, subIndex) => (
							<li
								key={subIndex}
								className='rule-sub-item'
								data-index={subIndex}>
								{subItem}
							</li>
						))}
					</ol>
				);
			} else {
				const formattedItem = item;
				return (
					<li key={index} className='rule-item' data-index={mainCount++}>
						{formattedItem}
					</li>
				);
			}
		});
	};

	return (
		<div>
			<div className='jau__title sectionup' onClick={toggleOpen}>
				{`제${number}조 ${title}`}
			</div>
			{isOpen && (
				<div className='jau__contents'>
					<ul>{formatContent(content)}</ul>
					{amended && (
						<div className='no-print'>
							<ul>
								{amended.map((amendment, i) => (
									<li
										key={i}
										style={{
											color: amendment?.includes('개정') ? 'red' : 'black',
										}}>
										{amendment}
									</li>
								))}
							</ul>
						</div>
					)}
				</div>
			)}
		</div>
	);
};

export default function RoeBS2SystemRules({
	serviceStartDate,
	profile,
	company,
	companyUn,
	companyGa,
	companyWa,
	allOpen,
	previousSectionCount1, // 이전 섹션 수를 전달받음
	setSectionCount,
}) {
	const sections = useMemo(() => {
		let baseSections = [
			{
				title: `영업비밀 관리위원회`,
				content: [
					`영업비밀 관리위원회는 영업비밀 관리업무를 효율적으로 수행하기 위하여 대표이사 또는 관리부서의 선임 임원을 위원장으로 하고, 인사, 문서, 전산, 기술 및 연구개발 부분의 임원 및 부서장 등 3~5인 이내로 구성하여 운영한다.`,
					`영업비밀 관리위원회의 정기회의는 매월 마지막 주 금요일에 소집하며, 임시회의는 대표이사가 필요한 경우에 소집한다.`,
					`영업비밀 관리위원회는 다음 사항을 심의·의결하되 재적위원 과반수의 찬성으로 한다.`,
					[
						`영업비밀의 기본정책과 기본계획`,
						`영업비밀의 등급분류와 보상 결정`,
						`영업비밀 사고에 따른 징계회부와 우수자 표창 결정`,
						`영업비밀의 폐기 결정`,
						`기타 영업비밀 보호에 관한 주요 사항`,
					],
					`${company}의 경영상 이유로 영업비밀 관리위원회를 운영하지 아니하는 경우 이하 조항에서 "영업비밀 관리위원회"가 행하는 모든 행위는 "대표이사"가 수행한다.`,
					`기타 필요한 사항은 별도로 정할 수 있다.`,
				],
			},
			{
				title: `영업비밀 취급`,
				content: [
					`영업비밀 취급은 영업비밀의 생산준비, 생산, 관리, 폐기까지의 직접 접촉 및 열람을 말한다.`,
					`영업비밀의 취급자는 다음 각 호와 같이 분류한다.`,
					[
						`영업비밀 당연 취급자: 일정한 보직을 받음으로써 영업비밀을 당연히 취급하는 자`,
						`영업비밀 인가 취급자: 영업비밀 당연 취급자의 인가를 받음으로써 영업비밀을 취급하는 자`,
					],
					`영업비밀 당연 취급자는 다음과 같다.`,
					[
						`제1급 영업비밀: 대표이사와 임원 및 영업비밀 총괄 책임자`,
						`제2급 영업비밀: 대표이사와 임원`,
						`제3급 영업비밀: 대표이사, 임원, 팀장`,
						`대외비: 회사의 모든 정규직원`,
					],
					`영업비밀 인가 취급자는 다음과 같다.`,
					[
						`제1급 영업비밀: 팀장 차하위 직급의 직원`,
						`제2급 영업비밀: 팀장 차차하위 직급의 직원`,
						`제3급 영업비밀: 모든 정규직원의 직원`,
					],
					`연구·개발 또는 영업비밀을 취급할 직원은 별도로 영업비밀 취급 적격 여부를 조사할 수 있다.`,
					`기타 필요한 사항은 별도로 정할 수 있다.`,
				],
			},
			{
				title: `임원`,
				content: [
					`대표이사는 영업비밀 관리위원회의 장으로서 제1급 영업비밀 당연 취급자이다.`,
					`이사급 이상의 임원은 영업비밀 관리위원회의 위원으로서 제1급 영업비밀 당연 취급자이다.`,
					`임원은 영업비밀 관리위원회의 임무를 수행한다.`,
					`기타 필요한 사항은 별도로 정할 수 있다.`,
				],
			},
			{
				title: `영업비밀 총괄책임자`,
				content: [
					`영업비밀 총괄책임자는 인사 부서장이 되며, 영업비밀관리위원회 간사를 겸임하고, 제1급 영업비밀 당연 취급자이다.`,
					`영업비밀 총괄책임자는 다음 각 호의 임무를 수행한다.`,
					[
						`영업비밀에 관한 년간 계획 수립 및 집행`,
						`영업비밀관리규정 및 지침 제정`,
						`영업비밀의 총괄, 조정, 교육 및 지도점검 실시`,
						`영업비밀사고 조사 및 징계회부 조치`,
						`영업비밀관리위원회 간사업무 수행`,
						`영업비밀의 생산, 등급 분류, 배포, 보관 등의 결정`,
						`영업비밀의 도난, 누설, 분실과 기타 사고 방지를 위한 안전 관리`,
						`제1급 영업비밀 취급의 승인`,
						`기타 대표이사 및 영업비밀관리 위원회 위원장 지시사항 수행`,
					],
					`영업비밀 총괄책임자를 보조하기 위한 인사, 문서, 기술, 전산분야 별로 보조 책임자를 둘 수 있다.`,
					`기타 필요한 사항은 별도로 정할 수 있다.`,
				],
			},
			{
				title: `부서별 영업비밀 관리책임자`,
				content: [
					`${companyUn} 영업비밀의 관리·보호를 위하여 각 부서의 장을 영업비밀 관리책임자를 지정하여야 한다.`,
					`각 부서의 장은 담당부서의 제2급 영업비밀 당연 취급자이다.`,
					`영업비밀 관리책임자의 직무는 다음과 같다.`,
					[
						`부서별 영업비밀에 관한 계획 수립 및 조정`,
						`소관 영업비밀 생산 시 영업비밀등급 잠정분류`,
						`영업비밀에 관한 교육 실시`,
						`영업비밀 보유현황 조사 및 관리 감독`,
						`영업비밀유지 계약 및 서약서 등의 집행`,
						`소관시설, 장비, 자재 및 통신시설 등의 보호관리`,
						`영업비밀 담당자 및 영업비밀 보호 당직자 지정`,
						`영업비밀 담당자의 영업비밀 보호 업무 지도·감독`,
						`제2급 영업비밀 취급의 승인`,
						`기타 부서내 영업비밀 보호에 관한 총괄지도·감독`,
					],
					`영업비밀 관리책임자는 월별로 대표이사 및 영업비밀 총괄책임자에게 그 현황을 보고하여야 한다. 새롭고 중요한 영업비밀을 창출하였을 경우에도 또한 같다.`,
					`기타 필요한 사항은 별도로 정할 수 있다.`,
				],
			},
			{
				title: `부서별 영업비밀 담당자`,
				content: [
					`회사는 영업비밀의 관리·보호를 위하여 각 부서별로 영업비밀 담당자를 지정하여야 한다.`,
					`각 부서의 영업비밀 담당자는 담당부서의 제3급 영업비밀 당연 취급자이다.`,
					`영업비밀 담당자의 직무는 다음과 같다.`,
					[
						`일일 영업비밀보호 당직자 지도`,
						`내방객 출입 관리`,
						`주요기기, 장비 등 관리`,
						`영업비밀수발 대장관리 등 기타 영업비밀 업무의 수행`,
						`영업비밀관리 책임자의 보조`,
						`기타 영업비밀에 관하여 필요한 사항`,
					],
					`영업비밀 담당자는 주별로 영업비밀 관리책임자에게 그 현황을 보고하여야 한다. 새롭고 중요한 영업비밀을 창출하였을 경우에도 또한 같다.`,
					`기타 필요한 사항은 별도로 정할 수 있다.`,
				],
			},
			{
				title: `정규직원`,
				content: [
					`정규직원은 회사의 대외비 영업비밀 당연 취급자이다.`,
					`정규직원은 주별로 영업비밀 관리책임자에게 그 현황을 보고하여야 한다. 새롭고 중요한 영업비밀을 창출하였을 경우에도 또한 같다.`,
					`기타 필요한 사항은 별도로 정할 수 있다.`,
				],
			},
			{
				title: `팀장 차하위 직급의 직원`,
				content: [
					`팀장 차하위 직급의 직원은 영업비밀총괄책임자의 인가를 받아서 제1급 영업비밀을 취급할 수 있다.`,
					`기타 필요한 사항은 별도로 정할 수 있다.`,
				],
			},
			{
				title: `팀장 차차하위 직급의 직원`,
				content: [
					`팀장 차차하위 직급의 직원은 영업비밀관리책임자의 인가를 받아서 제2급 영업비밀을 취급할 수 있다.`,
					`팀장 차차하위 직급의 직원은 어떠한 경우에도 제1급 영업비밀을 취급할 수 없다.`,
					`기타 필요한 사항은 별도로 정할 수 있다.`,
				],
			},
			{
				title: `모든 정규직원`,
				content: [
					`모든 정규직원은 영업비밀관리책임자의 인가를 받아서 제3급 영업비밀을 취급할 수 있다.`,
					`위 영업비밀 당연 취급자와 제1급 내지 제2급 영업비밀 인가 취급자를 제외한 모든 정규직원은 어떠한 경우에도 허가를 득하지 아니한 경우 제1급과 제2급 영업비밀을 취급할 수 없다.`,
					`기타 필요한 사항은 별도로 정할 수 있다.`,
				],
			},
			{
				title: `비정규 직원`,
				content: [
					`비정규 직원은 영업비밀관리책임자의 인가를 받아서 회사의 대외비를 취급할 수 있다.`,
					`비정규 직원은 어떠한 경우에도 제1급과 제2급 및 제3급 영업비밀을 취급할 수 없다.`,
					`기타 필요한 사항은 별도로 정할 수 있다.`,
				],
			},
			{
				title: `영업비밀보호 당직제도 운용`,
				content: [
					`영업비밀 당직은 영업비밀 관리책임자가 정규직원 중에서 지명한다.`,
					`영업비밀보호 당직자는 당일 사무실과 사업현장에 대한 영업비밀보호 및 안전에 대한 책임을 진다.`,
					`영업비밀보호 당직자는 퇴실 시 안전점검일지의 점검사항을 확인하고, 익일 출근 시 영업비밀관리책임자에게 보고한 후 다음 영업비밀보호 당직자에게 인계하여야 한다.`,
					`기타 필요한 사항은 별도로 정할 수 있다.`,
				],
			},
			{
				title: `영업비밀 관리의 날 행사 및 영업비밀 소유현황 조사`,
				content: [
					`영업비밀 관리의 날은 매분기 마지막 달 마지막 주 금요일이다.`,
					`각 부서의 영업비밀 관리책임자는 영업비밀 관리의 날에 다음 각 호를 수행한다.`,
					[
						`담당부서의 영업비밀 관리상태를 점검`,
						`담당부서 영업비밀 보호의 취약점을 보완`,
						`담당부서의 모든 직원에게 영업비밀 직무교육을 실시`,
					],
					`영업비밀 총괄책임자는 영업비밀 관리의 날에 관내 순회점검을 실시할 수 있다.`,
					`${companyUn} 년 2회(6월, 12월) 영업비밀 관리의 날에 영업비밀 보유현황을 파악하여야 한다.`,
					`기타 필요한 사항은 별도로 정할 수 있다.`,
				],
			},
			{
				title: `영업비밀 보호교육 및 지도점검`,
				content: [
					`영업비밀 총괄책임자는 모든 직원에 대하여 매년 6월 영업비밀관리의 날에 영업비밀보호에 대한 교육을 실시하여야 한다.`,
					`영업비밀 총괄책임자는 점검반을 편성하여 모든 부서에 대하여 매년 6월 영업비밀관리의 날에 영업비밀보호에 대한 점검을 실시하여야 한다.`,
					`영업비밀 총괄책임자는 영업비밀의 보호에 특별히 필요한 경우 영업비밀보호에 대한 교육 또는 점검을 실시하여야 한다.`,
					`기타 필요한 사항은 별도로 정할 수 있다.`,
				],
			},
			{
				title: `상벌`,
				content: [
					`${companyUn} 영업비밀 보호업무에 대한 지도점검 및 영업비밀 관리의 날에 순회지도 점검의 결과 등을 기준으로 하여 년 1회 이상 우수한 부서와 임원 및 사원에 대하여는 포상을 실시한다.`,
					`우수한 영업비밀을 연구개발 또는 창안한 자는 각 부서 영업비밀 관리책임자의 추천을 받아 영업비밀 관리위원회의 심의를 거쳐 적정한 보상을 하여야 한다.`,
					`영업비밀 보호업무를 고의 또는 중대한 과실로 누설하거나 업무를 태만히 한 자는 민·형사 책임과 부정경쟁방지 및 영업비밀 보호에 관한 법률상의 책임 및 회사규정상의 징계책임을 진다.`,
					`기타 필요한 사항은 별도로 정할 수 있다.`,
				],
			},
			{
				title: `영업비밀 사고의 처리`,
				content: [
					`영업비밀의 유출 및 도난 등 사고를 발견한 임원 및 사원은 즉시 영업비밀 총괄책임자에게 통보하여야 한다.`,
					`영업비밀 총괄책임자는 영업비밀 관리위원회에 보고하고 그 진상을 조사하여 고의 또는 중과실이 있는 경우에 관련자를 징계 회부하여야 한다.`,
					`조사 결과 경쟁사 등 제3자가 개입되었을 때에는 영업비밀 관리위원회에 회부하여 그 처리를 결정한다.`,
					`임원 또는 사원은 영업비밀의 누설, 유출과 책임을 인지하거나 위험이 있을 때에는 즉시 영업비밀 총괄책임자에게 신고하여야 한다. 다만, 신고자가 원하지 않을 때에는 비밀로 하여 신분을 보호하여야 한다.`,
					`기타 필요한 사항은 별도로 정할 수 있다.`,
				],
			},
		];

		// if (['대한민국', '한국', '일본']?.includes(company)) {
		// 	const additionalSection = {
		// 		title: `소정근로시간`,
		// 		content: [
		// 			`사원의 소정근로시간은 아래와 같다.`,
		// 			[
		// 				`1일 소정근로시간은 휴게시간을 제외하고 8시간을 원칙으로 하되, 단시간 사원의 경우 1일 8시간을 한도로 근로계약에 의거 소정근로시간을 달리 정할 수 있다.`,
		// 				`1주 소정근로시간은 40시간을 원칙으로 하되, 단시간 사원의 경우 1주 40시간 한도로 근로계약에 의거 소정근로시간을 달리 정할 수 있다.`,
		// 			],
		// 			`15세 이상 18세 미만인 사원의 소정근로시간은 아래와 같다.`,
		// 			[
		// 				`1일 소정근로시간은 휴게시간을 제외하고 7시간을 원칙으로 하되, 단시간 사원의 경우 1일 7시간을 한도로 근로계약에 의거 소정근로시간을 달리 정할 수 있다.`,
		// 				`1주 소정근로시간은 35시간을 원칙으로 하되, 단시간 사원의 경우 1주 35시간 한도로 근로계약에 의거 소정근로시간을 달리 정할 수 있다.`,
		// 			],
		// 			`1주 소정근로시간은 월요일의 시업시각부터 기산하고, 1월 소정근로시간은 매월 1일의 시업시각부터 기산한다.`,
		// 			`경영상 필요한 경우 근로계약서 또는 임금 계약서의 작성을 통하여 기존에 제1항 및 제2항의 규정의 적용받는 것과 달리 1일 또는 1주 소정근로시간을 약정할 수 있다.`,
		// 			`고용노동부장관의 승인을 받은 감시적, 단속적 근로에 종사하는 자(경비직, 시설관리직, 운전기사 등) 및 관리, 감독업무 또는 기밀 사무를 취급하는 자는 본 규칙에서 정한 근로시간, 휴게와 휴일에 관한 규정은 적용하지 아니한다.`,
		// 			`기타 필요한 사항은 별도로 정할 수 있다.`,
		// 		],
		// 	};
		// 	baseSections.splice(1, 0, additionalSection); // "목적" 다음에 "소정근로시간" 삽입
		// }

		const excludedCompanies = ['주식회사 허브601'];

		// 조건에 따라 섹션 제거
		if (excludedCompanies?.includes(company)) {
			// "title"이 여러 개일 때 제거할 섹션 목록
			const excludedTitles = ['영업비밀 관리위원회'];

			baseSections = baseSections.filter(
				(section) => !excludedTitles?.includes(section.title)
			);
		}

		setSectionCount(baseSections.length); // 조항 수 설정

		return baseSections;
	}, []); // eslint-disable-line react-hooks/exhaustive-deps

	const [openSections, setOpenSections] = useState({});

	useEffect(() => {
		const newOpenSections = sections.reduce((acc, section) => {
			acc[section.title] = allOpen;
			return acc;
		}, {});
		setOpenSections(newOpenSections);
	}, [allOpen, sections]);

	const toggleSection = (section) => {
		setOpenSections((prevState) => ({
			...prevState,
			[section]: !prevState[section],
		}));
	};

	return (
		<>
			<div className='jang sectionup'>제2장 근로시간제도</div>
			{sections.map((section, index) => (
				<div key={index}>
					<RuleSection
						number={index + previousSectionCount1 + 1}
						title={section.title}
						content={section.content}
						isOpen={!!openSections[section.title]}
						toggleOpen={() => toggleSection(section.title)}
						amended={section.amended}
					/>
				</div>
			))}
		</>
	);
}
