import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { Dropdown, Menu } from 'semantic-ui-react'
import { delay } from '../../app/common/util/util';
import { clickConfirmedTimeOfLaborContract } from '../../app/firestore/firestoreService4In';

export default function ContractWorkerMenu({currentUserProfile, isDayOrTimeWorker, PiUserListFill}) {

  const history = useHistory();
  const [isMobile, setIsMobile] = useState(window.innerWidth < 1000);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 1000);
    };
    
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);
    
  const [dropdownOpen, setDropdownOpen] = useState(false);
  
  const handleMouseOver = () => {
    setDropdownOpen(true);
  };

  const handleMouseLeave = () => {
    setDropdownOpen(false);
  };

  const laborContractId = currentUserProfile?.paydocuWorkerUid;

  function viewLaborContractHandlerWorker (laborContractId) {
    delay(1000)
    history.push(`/clientReqs4In/${laborContractId}`)
    clickConfirmedTimeOfLaborContract(laborContractId)
  }
  
  function viewLaborContractHandlerDayWorker (laborContractId) {
    delay(1000)
    history.push(`/clientReqDaylyPayContract/${laborContractId}`)
    clickConfirmedTimeOfLaborContract(laborContractId)
  }

  function viewWageContractHandlerWorker (laborContractId) {
    delay(1000)
    history.push(`/clientRDLaborWageContract/${laborContractId}`)
    clickConfirmedTimeOfLaborContract(laborContractId)
  }
  
  function viewRuleObeyHandlerWorker (laborContractId) {
    delay(1000)
    history.push(`/clientRDRuleObeyAgree/${laborContractId}`)
  }
    
  function viewPersonalInfoAgreeHandlerWorker (laborContractId) {
    delay(1000)
    history.push(`/clientRDPersonalInfoAgree/${laborContractId}`)
  }

  return (
    <>
      <Menu.Item onMouseOver={handleMouseOver} onMouseLeave={handleMouseLeave}>
        {!isMobile && <PiUserListFill className="icon-spacing" />}
        <Dropdown style={{width:'160px'}} pointing='top left' text='인사서류' open={dropdownOpen}>
          <Dropdown.Menu>
              <>
                {isDayOrTimeWorker 
                ? 
                  <Dropdown.Item onClick={() => viewLaborContractHandlerDayWorker(laborContractId)}  text='근로계약서' />
                : 
                  <>
                    <Dropdown.Item onClick={() => viewLaborContractHandlerWorker(laborContractId)}  text='근로계약서' />
                    <Dropdown.Item onClick={() => viewWageContractHandlerWorker(laborContractId)}  text='임금계약서' />
                    <Dropdown.Item onClick={() => viewRuleObeyHandlerWorker(laborContractId)}  text='복무서약서' />
                    <Dropdown.Item onClick={() => viewPersonalInfoAgreeHandlerWorker(laborContractId)}  text='개인정보동의서' />

                  </>
                }
              </>
     
          </Dropdown.Menu>
        </Dropdown>
      </Menu.Item>
    </>
  )
}
