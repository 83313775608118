import React from 'react'
import { Table } from 'semantic-ui-react'
import PaydocuDetailedPageDayPlusForDayWorker from './PaydocuDetailedPageDayPlusForDayWorker';
import PaydocuDetailedPageDayPlusForTimeWorker from './PaydocuDetailedPageDayPlusForTimeWorker';

export default function PaydocuDetailedPageWorkDayPlusTimeForDTWorker({
  daylyTimePay,
  worker,
  countOfNomalDayWork,
  countOfHoliDayWork,
  countOfOverWorkingDayWork,
  sumOfCountWorkDays,
  workDatesPaydocu,
  dayWorkerPlusOTime,
  numOfWorkers,
  dayWorkerPlusNTime,
  dayWorkerPlusHBTime,
  dayWorkerPlusHOTime,
  dayWorkerPlusHNTime,
  dayWorkerAbsentTimes,
  dayWorkerPlusOverTimeDates,
  dayWorkerPlusNightTimeDates,
  dayWorkerPlusHoliBasicTimeDates,
  dayWorkerPlusHoliOverTimeDates,
  dayWorkerPlusHoliNightTimeDates,
  dayWorkerAbsentTimeDates,

  timeWorkerPlusMonthOWT,
  timeWorkerPlusMonthNWT,
  timeWorkerPlusMonthHoliBasicTime,
  timeWorkerPlusMonthOWTHoli,
  timeWorkerPlusMonthOffBasicTime,
  timeWorkerPlusMonthOWTOff,
  timeWorkerPlusOverTimeDates,
  timeWorkerPlusNightTimeDates,
  timeWorkerPlusHoliBasicTimeDates,
  timeWorkerPlusHoliOverTimeDates,
  timeWorkerPlusOffBasicTimeDates,
  timeWorkerPlusOffOverTimeDates,

}) {

  function formatWorkDates(workDatesPaydocu) {
    if (Array.isArray(workDatesPaydocu) && typeof workDatesPaydocu[0] === 'number') {
      // workDatesPaydocu가 숫자로 이루어진 배열인 경우
      return `근무일 : ${workDatesPaydocu.sort((a, b) => b - a).join(', ')} [총 ${workDatesPaydocu.length}일]`;
    } else if (Array.isArray(workDatesPaydocu) && typeof workDatesPaydocu[0] === 'object' && workDatesPaydocu[0].date !== undefined) {
      // workDatesPaydocu가 객체 배열인 경우
      return `근무일 : ${workDatesPaydocu.map(({ date }) => date).join(', ')} [총 ${workDatesPaydocu.length}일]`;
    } else {
      return '유효하지 않은 데이터 형식입니다.';
    }
  }

  return (
    <>
      <Table.Row>
        <Table.Cell
          colSpan='9' >{`2. ${worker}의 근무일`}</Table.Cell>
      </Table.Row>
      {daylyTimePay > 0 ? null :
        <>
          <Table.Row>
            {countOfNomalDayWork && <Table.Cell
              style={{ width: '11.11%', textAlign: 'center' }}>평일 근무일수</Table.Cell>}
            {countOfHoliDayWork && <Table.Cell
              style={{ width: '11.11%', textAlign: 'center' }}>휴일 근무일수</Table.Cell>}
            {countOfOverWorkingDayWork && <Table.Cell
              style={{ width: '11.11%', textAlign: 'center' }}>휴무일 근무일수 <br />(전체가 연장근로)</Table.Cell>}
            {sumOfCountWorkDays && <Table.Cell
              style={{ width: '11.11%', textAlign: 'center' }}>총 근무일</Table.Cell>}
            {!countOfNomalDayWork && <Table.Cell></Table.Cell> }
            {!countOfHoliDayWork && <Table.Cell></Table.Cell> }
            {!countOfOverWorkingDayWork && <Table.Cell></Table.Cell> }
            {!sumOfCountWorkDays && <Table.Cell></Table.Cell> }
            <Table.Cell></Table.Cell>
            <Table.Cell></Table.Cell>
            <Table.Cell></Table.Cell>
            <Table.Cell></Table.Cell>
            <Table.Cell></Table.Cell>
          </Table.Row>
          <Table.Row>
            {countOfNomalDayWork && <Table.Cell
              style={{ width: '11.11%', textAlign: 'center' }}>{countOfNomalDayWork}</Table.Cell>}
            {countOfHoliDayWork && <Table.Cell
              style={{ width: '11.11%', textAlign: 'center' }}>{countOfHoliDayWork}</Table.Cell>}
            {countOfOverWorkingDayWork && <Table.Cell
              style={{ width: '11.11%', textAlign: 'center' }}>{countOfOverWorkingDayWork}</Table.Cell>}
            {sumOfCountWorkDays && <Table.Cell
              style={{ width: '11.11%', textAlign: 'center' }}>{sumOfCountWorkDays}</Table.Cell>}
            {!countOfNomalDayWork && <Table.Cell></Table.Cell> }
            {!countOfHoliDayWork && <Table.Cell></Table.Cell> }
            {!countOfOverWorkingDayWork && <Table.Cell></Table.Cell> }
            {!sumOfCountWorkDays && <Table.Cell></Table.Cell> }
            <Table.Cell></Table.Cell>
            <Table.Cell></Table.Cell>
            <Table.Cell></Table.Cell>
            <Table.Cell></Table.Cell>
            <Table.Cell></Table.Cell>
          </Table.Row>
        </>
      }
      <Table.Row>
        <Table.Cell colSpan='9' style={{color : 'blue'}}>
          {formatWorkDates(workDatesPaydocu)}
        </Table.Cell>
      </Table.Row>
      <Table.Row>
        <Table.Cell
          textAlign='left' colSpan='9'><span style={{color:'darkcyan', fontWeight:'bold'}}>{`2-1. ${worker}의 추가근무시간 및 공제내역`}</span></Table.Cell>
      </Table.Row>
      {daylyTimePay > 0 
        ?
          <PaydocuDetailedPageDayPlusForTimeWorker
            numOfWorkers={numOfWorkers}
            timeWorkerPlusMonthOWT={timeWorkerPlusMonthOWT}
            timeWorkerPlusMonthNWT={timeWorkerPlusMonthNWT}
            timeWorkerPlusMonthHoliBasicTime={timeWorkerPlusMonthHoliBasicTime}
            timeWorkerPlusMonthOWTHoli={timeWorkerPlusMonthOWTHoli}
            timeWorkerPlusMonthOffBasicTime={timeWorkerPlusMonthOffBasicTime}
            timeWorkerPlusMonthOWTOff={timeWorkerPlusMonthOWTOff}
            timeWorkerPlusOverTimeDates={timeWorkerPlusOverTimeDates}
            timeWorkerPlusNightTimeDates={timeWorkerPlusNightTimeDates}
            timeWorkerPlusHoliBasicTimeDates={timeWorkerPlusHoliBasicTimeDates}
            timeWorkerPlusHoliOverTimeDates={timeWorkerPlusHoliOverTimeDates}
            timeWorkerPlusOffBasicTimeDates={timeWorkerPlusOffBasicTimeDates}
            timeWorkerPlusOffOverTimeDates={timeWorkerPlusOffOverTimeDates}
          />
        :
          <PaydocuDetailedPageDayPlusForDayWorker
            numOfWorkers={numOfWorkers}
            dayWorkerPlusOTime={dayWorkerPlusOTime}
            dayWorkerPlusNTime={dayWorkerPlusNTime}
            dayWorkerPlusHBTime={dayWorkerPlusHBTime}
            dayWorkerPlusHOTime={dayWorkerPlusHOTime}
            dayWorkerPlusHNTime={dayWorkerPlusHNTime}
            dayWorkerAbsentTimes={dayWorkerAbsentTimes}
            dayWorkerPlusOverTimeDates={dayWorkerPlusOverTimeDates}
            dayWorkerPlusNightTimeDates={dayWorkerPlusNightTimeDates}
            dayWorkerPlusHoliBasicTimeDates={dayWorkerPlusHoliBasicTimeDates}
            dayWorkerPlusHoliOverTimeDates={dayWorkerPlusHoliOverTimeDates}
            dayWorkerPlusHoliNightTimeDates={dayWorkerPlusHoliNightTimeDates}
            dayWorkerAbsentTimeDates={dayWorkerAbsentTimeDates}
          />
      }
    </>
  
  )
}
