import React from 'react';
import { Table } from 'semantic-ui-react';
import {
  numberToCommaString,
  roundUp,
  roundUp2,
} from '../../../app/common/util/util';

export default function PaydocuPayDetailTimeWorker({
  numOfWorkers,
  totalTimeWorkerWage,
  totalTimeWorkerPaidHoliWage,
  timeWorkerTimeWage,
  week14OverBLTime,
  timeWorkerTimePaidHoliWage,
  numPaidHoliTimeW,
  finalPaidHoliWageTimeSum,
  monthTotalWorkTime,
  finalPaidHoliWageTime,
  normalWorkerWeekWorkDays,
  paidHoliWageDays,
  paidHoliWageText,

  timeWorkerPlusMonthOWT,
  timeWorkerPlusMonthNWT,
  timeWorkerPlusMonthHoliBasicTime,
  timeWorkerPlusMonthOWTHoli,
  timeWorkerPlusMonthOffBasicTime,
  timeWorkerPlusMonthOWTOff,
  numOWageForTW,
  numNWageForTW,
  numHBWageForTW,
  numHOWageForTW,
  numOffWageForTW,
  numOffOWageForTW,

  dayWorkerCAWage5,
  dayWorkerCAWEName5,
  dayWorkerCAWage5Comment,
  dayWorkerCAWage6,
  dayWorkerCAWEName6,
  dayWorkerCAWage6Comment,
  dayWorkerCAWage7,
  dayWorkerCAWEName7,
  dayWorkerCAWage7Comment,

  timeWorkerSpecialBonus,

}) {

  return (
    <>
      <Table.Row>
        <Table.Cell
          style={{ width: '11.11%', textAlign: 'center' }}>기본임금</Table.Cell>
        <Table.Cell
          style={{ width: '11.11%', textAlign: 'center' }}>임금</Table.Cell>
        <Table.Cell
          style={{ width: '11.11%', textAlign: 'right' }}>{numberToCommaString(roundUp(totalTimeWorkerWage))}</Table.Cell>
        <Table.Cell
          colSpan='6' className='sulmung'>통상시급 {numberToCommaString(roundUp(timeWorkerTimeWage))}원 * 총 기본근무시간 {roundUp2(monthTotalWorkTime)}시간{<br />}(근로를 제공한 모든 일자(휴무일과 휴일이 포함된 경우 이를 포함)의 1일 8시간 이내의 모든 근로)</Table.Cell>
      </Table.Row>
      {week14OverBLTime === '주휴수당 포함' 
        ?
          <Table.Row>
            <Table.Cell
              style={{ width: '11.11%', textAlign: 'center' }}>주휴수당</Table.Cell>
            <Table.Cell
              style={{ width: '11.11%', textAlign: 'center' }}>임금</Table.Cell>
            <Table.Cell
              style={{ width: '11.11%', textAlign: 'right' }}>{numberToCommaString(roundUp(totalTimeWorkerPaidHoliWage))}</Table.Cell>
            <Table.Cell
              colSpan='6' className='sulmung'>주휴수당 시급{numberToCommaString(roundUp(timeWorkerTimePaidHoliWage))}원 * 총 기본근무시간 {roundUp2(monthTotalWorkTime)}시간</Table.Cell>
          </Table.Row>
        :
          <Table.Row>
            <Table.Cell
              style={{ width: '11.11%', textAlign: 'center' }}>주휴수당</Table.Cell>
            <Table.Cell
              style={{ width: '11.11%', textAlign: 'center' }}>임금</Table.Cell>
            <Table.Cell
              style={{ width: '11.11%', textAlign: 'right' }}>{numberToCommaString(roundUp(numPaidHoliTimeW))}</Table.Cell>
            {numPaidHoliTimeW > 0 ?
              <>
                <Table.Cell
                  colSpan='6' className='sulmung'>통상시급{numberToCommaString(roundUp(timeWorkerTimeWage))}원 * 총 주휴시간 {roundUp2(finalPaidHoliWageTime)}시간
                  {<br />}{`[(시급제(단시간) 근로자 4주 소정근로시간 (${numberToCommaString(roundUp(finalPaidHoliWageTimeSum))})) / (통상근로자 4주 소정근무일(${normalWorkerWeekWorkDays})) * 주휴일수 (${paidHoliWageDays})]`}
                  {<br />}{(paidHoliWageText)}
                </Table.Cell>
              </>
              :
                <Table.Cell
                  colSpan='6' >1주 15시간을 초과하지 않습니다.</Table.Cell>
            }
          </Table.Row>
      }
      {numOWageForTW
        ? 
          <Table.Row>
            <Table.Cell
              style={{ width: '11.11%', textAlign: 'center' }}>평일연장근로</Table.Cell>
            <Table.Cell
              style={{ width: '11.11%', textAlign: 'center' }}>평균임금</Table.Cell>
            <Table.Cell
              style={{ width: '11.11%', textAlign: 'right' }}>{numberToCommaString(roundUp(numOWageForTW))}</Table.Cell>
            <Table.Cell
              colSpan='6' className='sulmung'>통상시급 {numberToCommaString(roundUp(timeWorkerTimeWage))}원 * 8시간 초과{timeWorkerPlusMonthOWT}시간 {numOfWorkers >= 5 ? '* 1.5배' : '* 1배'}</Table.Cell>
          </Table.Row>
        :
          null
      }
      {numNWageForTW
        ? 
          <Table.Row>
            <Table.Cell
              style={{ width: '11.11%', textAlign: 'center' }}>야간근로{<br />}가산임금</Table.Cell>
            <Table.Cell
              style={{ width: '11.11%', textAlign: 'center' }}>평균임금</Table.Cell>
            <Table.Cell
              style={{ width: '11.11%', textAlign: 'right' }}>{numberToCommaString(roundUp(numNWageForTW))}</Table.Cell>
            <Table.Cell
              colSpan='6' className='sulmung'>통상시급 {numberToCommaString(roundUp(timeWorkerTimeWage))}원 * 야간근로 {timeWorkerPlusMonthNWT}시간 {numOfWorkers >= 5 ? '* 0.5배' : '* 0배'}
              {<br />}(근로를 제공한 모든 일자(휴무일과 휴일이 포함된 경우 이를 포함)의 오후 10시이후 오전 6시이전 모든 야간 근로)
            </Table.Cell>
          </Table.Row>
        :
          null
      }
      {numHBWageForTW
        ? 
          <Table.Row>
            <Table.Cell
              style={{ width: '11.11%', textAlign: 'center' }}>휴일기본{<br />}가산임금</Table.Cell>
            <Table.Cell
              style={{ width: '11.11%', textAlign: 'center' }}>평균임금</Table.Cell>
            <Table.Cell
              style={{ width: '11.11%', textAlign: 'right' }}>{numberToCommaString(roundUp(numHBWageForTW))}</Table.Cell>
            <Table.Cell
              colSpan='6' className='sulmung'>통상시급 {numberToCommaString(roundUp(timeWorkerTimeWage))}원 * 휴일근로 {timeWorkerPlusMonthHoliBasicTime}시간 {numOfWorkers >= 5 ? '* 0.5배' : '* 0배'}
              {<br />}{numOfWorkers > 5 ? '(휴일 8시간 이내 근무에 대한 댓가 1배는 기본임금에 포함되어 있기에 0.5배만 가산함.)': ''}
            </Table.Cell>
          </Table.Row>
        :
          null
      }
      {numHOWageForTW
        ? 
          <Table.Row>
            <Table.Cell
              style={{ width: '11.11%', textAlign: 'center' }}>휴일초과{<br />}임금</Table.Cell>
            <Table.Cell
              style={{ width: '11.11%', textAlign: 'center' }}>평균임금</Table.Cell>
            <Table.Cell
              style={{ width: '11.11%', textAlign: 'right' }}>{numberToCommaString(roundUp(numHOWageForTW))}</Table.Cell>
            <Table.Cell
              colSpan='6' className='sulmung'>통상시급 {numberToCommaString(roundUp(timeWorkerTimeWage))}원 * 휴일근로 8시간 초과 {timeWorkerPlusMonthOWTHoli}시간 {numOfWorkers >= 5 ? '* 2배' : '* 1배'}
              {<br />}{numOfWorkers > 5 ? "(휴일 8시간 초과근로에 대해서는 기본임금에 포함되어 있지 않으므로 기본근로와 가산근로 합계 2배를 계산함.)" : ""}
            </Table.Cell>
          </Table.Row>
        :
          null
      }
      {numOffWageForTW
        ? 
          <Table.Row>
            <Table.Cell
              style={{ width: '11.11%', textAlign: 'center' }}>휴무일기본{<br />}가산임금</Table.Cell>
            <Table.Cell
              style={{ width: '11.11%', textAlign: 'center' }}>평균임금</Table.Cell>
            <Table.Cell
              style={{ width: '11.11%', textAlign: 'right' }}>{numberToCommaString(roundUp(numOffWageForTW))}</Table.Cell>
            <Table.Cell
              colSpan='6' className='sulmung'>통상시급 {numberToCommaString(roundUp(timeWorkerTimeWage))}원 * 휴무일근로 {timeWorkerPlusMonthOffBasicTime}시간 {numOfWorkers >= 5 ? '* 0.5배' : '* 0배'}
              {<br />}{numOfWorkers > 5 ? '(휴무일 8시간 이내 근무에 대한 댓가 1배는 기본임금에 포함되어 있으므로 가산임금 0.5배만 계산함.)' : ''}
            </Table.Cell>
          </Table.Row>
        :
          null
      }
      {numOffOWageForTW
        ? 
          <Table.Row>
            <Table.Cell
              style={{ width: '11.11%', textAlign: 'center' }}>휴무일초과{<br />}임금</Table.Cell>
            <Table.Cell
              style={{ width: '11.11%', textAlign: 'center' }}>평균임금</Table.Cell>
            <Table.Cell
              style={{ width: '11.11%', textAlign: 'right' }}>{numberToCommaString(roundUp(numOffOWageForTW))}</Table.Cell>
            <Table.Cell
              colSpan='6' className='sulmung'>통상시급 {numberToCommaString(roundUp(timeWorkerTimeWage))}원 * 휴무일근로 8시간 초과 {timeWorkerPlusMonthOWTOff}시간 {numOfWorkers >= 5 ? '* 1.5배' : '* 1배'}
              {<br />}{numOfWorkers > 5 ? '(휴무일 8시간 초과근로에 대해서는 기본임금에 포함되어 있지 않으므로 기본근로와 가산근로 합계 1.5배를 계산함.)' : ''}
            </Table.Cell>
          </Table.Row>
        :
          null
      }
      {dayWorkerCAWage5 && 
        <Table.Row>
          <Table.Cell
            style={{ width: '11.11%', textAlign: 'center' }}>월정액<br />평균임금</Table.Cell>
          <Table.Cell
            style={{ width: '11.11%', textAlign: 'center' }}>{dayWorkerCAWEName5}</Table.Cell>
          <Table.Cell
            style={{ width: '11.11%', textAlign: 'right' }}>{numberToCommaString(dayWorkerCAWage5)}</Table.Cell>
          <Table.Cell
            colSpan='6' className='sulmung'>{ dayWorkerCAWage5Comment }</Table.Cell>
        </Table.Row>}
      {dayWorkerCAWage6 && 
        <Table.Row>
          <Table.Cell
            style={{ width: '11.11%', textAlign: 'center' }}>월정액<br />평균임금</Table.Cell>
          <Table.Cell
            style={{ width: '11.11%', textAlign: 'center' }}>{dayWorkerCAWEName6}</Table.Cell>
          <Table.Cell
            style={{ width: '11.11%', textAlign: 'right' }}>{numberToCommaString(dayWorkerCAWage6)}</Table.Cell>
          <Table.Cell
            colSpan='6' className='sulmung'>{ dayWorkerCAWage6Comment }</Table.Cell>
        </Table.Row>}
      {dayWorkerCAWage7 &&
        <Table.Row>
          <Table.Cell
            style={{ width: '11.11%', textAlign: 'center' }}>월정액<br />평균임금</Table.Cell>
          <Table.Cell
            style={{ width: '11.11%', textAlign: 'center' }}>{dayWorkerCAWEName7}</Table.Cell>
          <Table.Cell
            style={{ width: '11.11%', textAlign: 'right' }}>{numberToCommaString(dayWorkerCAWage7)}</Table.Cell>
          <Table.Cell
            colSpan='6' className='sulmung'>{ dayWorkerCAWage7Comment }</Table.Cell>
        </Table.Row>
      }
      {timeWorkerSpecialBonus &&
        <Table.Row>
          <Table.Cell
            style={{ width: '11.11%', textAlign: 'center' }}>특별금품</Table.Cell>
          <Table.Cell
            style={{ width: '11.11%', textAlign: 'center' }}>특별 상여금</Table.Cell>
          <Table.Cell
            style={{ width: '11.11%', textAlign: 'right' }}>{numberToCommaString(timeWorkerSpecialBonus)}</Table.Cell>
          <Table.Cell
            colSpan='6' className='sulmung'>근로의 대가와 상관없는 은혜적 특별상여금(평균임금에도 포함되지 않음)</Table.Cell>
        </Table.Row>
      }
    </>
  )
}
