import React from 'react';

function ClientReqDLCDDayOrTimeWorkerWagePaper({
	eDate,
	daylyPay,
	realTimeWage,
	inputWageStyle,
	dayWorkerTax,
	regionTax,
	noJob,
	worker,
	deductedPayOption,
	handelInputChange,
	format,
	numberToCommaString,
	roundUp,
	daylyTimePay,
	week14OverBLTime,
}) {
	const isWeek14OverBLTimeText =
		week14OverBLTime === '주휴수당 포함' ? '(주휴수당 포함)' : '';

	return (
		<>
			{daylyTimePay ? null : (
				<>
					<h3 style={{ textAlign: 'center' }}>임금명세서</h3>
					<p>
						귀하의 노고에 감사드립니다. 본 임금명세서를 근로계약서와 함께 교부해
						드립니다.
					</p>
					<>
						<div>성명 : {worker}</div>
						<div>입사일 : {eDate}</div>
						<div>일당 : {numberToCommaString(roundUp(daylyPay))}원</div>
						<div>
							시급 : {numberToCommaString(roundUp(realTimeWage))}원{' '}
							{isWeek14OverBLTimeText}
						</div>
						<div>
							근로소득세 :{' '}
							<input
								style={inputWageStyle}
								name='dayWorkerTax'
								defaultValue={numberToCommaString(dayWorkerTax(daylyPay))}
								onChange={(e) => handelInputChange(e)}
							/>
							원 <br /> &nbsp;&nbsp;&nbsp;&nbsp;[150,000원 초과 과세대상 임금 *
							소득세율 2.7% (단, 1,000원 미만 절사)]
						</div>
						<div>
							지방소득세 :{' '}
							<input
								style={inputWageStyle}
								name='dayWorkerRegionTax'
								defaultValue={numberToCommaString(regionTax(daylyPay))}
								onChange={(e) => handelInputChange(e)}
							/>
							원 [소득세의 10%]
						</div>
						<div>
							고용보험료 :{' '}
							<input
								style={inputWageStyle}
								name='dayWorkerNoJob'
								defaultValue={numberToCommaString(noJob(daylyPay))}
								onChange={(e) => handelInputChange(e)}
							/>
							원 [과세대상 임금 * 0.9%]
						</div>
						<div>차인지급액 : {numberToCommaString(deductedPayOption)}원</div>
						<div>
							*일용직이지만 일당직 등 불특정 근로가 예정되어 특정일(예, 매월
							10일)에 일당의 합계를 받는 경우 중 1개월간 8일 이상 출근시
							국민연금료 등 4대보험료가 추가 공제됨을 안내해 드립니다.
						</div>
						<hr />
						<h5 style={{ textAlign: 'center' }}>
							임금명세서를 교부받았음을 확인하며, 아래 계좌로 입금을 요청합니다.
						</h5>
						<div>* 입금은행 : </div>
						<div>* 계좌번호 : </div>
						<div>* 예금주 : </div>
					</>
					<br />
					<p style={{ textAlign: 'center' }}>
						{format(new Date(), 'yyyy-MM-dd')}
					</p>
				</>
			)}
		</>
	);
}

export default ClientReqDLCDDayOrTimeWorkerWagePaper;
