import React from 'react';
import ClientRBizWorkerMonthWageTableBasicInfo from './ClientRBizWorkerMonthWageTableBasicInfo';
import ClientRTimeWorkerMonthWageTableBasicInfo from './ClientRTimeWorkerMonthWageTableBasicInfo';
import ClientRDayWorkerMonthWageTableBasicInfo from './ClientRDayWorkerMonthWageTableBasicInfo';

export default function ClientRBizDayTimeWorkerMonthWageTableBasicInfo({
	centerStyle,
	worker,
	decryptoData,
	numberToCommaString,
	roundUp,
	dayWorkerWage,
	dayWokerTimeWage,
	dayWorkerWorkingTimeDayTotal,
	roundUp2,
	dayWorkerDayTotalPaidConversedTime,
	numOfWorkers,
	dayWorkerWorkingTimeDayBasic,
	dayWorkerPaidHolidayLBTime,
	dayWorkerWorkingTimeDayOver,
	dayWorkerWorkingTimeDayNight,
	daylyTimePay,
	timeWorkerTimeWage,
	timeWorkerTimePaidHoliWage,
	isBizWorker,
	inputBizIncomeStyle,
	handelInputChange,
	bizIncomeValue,
	bizDeductionBeforeTaxValue,
	bizDeductionBeforeTaxCommentValue,
	bizDeductionAfterTaxValue,
	bizDeductionAfterTaxCommentValue,
	values,
	setBizIncomeValue,
	setBizDeductionAfterTaxValue,
	setBizDeductionAfterTaxCommentValue,
}) {
	return (
		<>
			{isBizWorker ? (
				<ClientRBizWorkerMonthWageTableBasicInfo
					centerStyle={centerStyle}
					worker={worker}
					decryptoData={decryptoData}
					isBizWorker={isBizWorker}
					inputBizIncomeStyle={inputBizIncomeStyle}
					handelInputChange={handelInputChange}
					bizIncomeValue={bizIncomeValue}
					// bizDeductionBeforeTaxValue={bizDeductionBeforeTaxValue}
					// bizDeductionBeforeTaxCommentValue={bizDeductionBeforeTaxCommentValue}
					bizDeductionAfterTaxValue={bizDeductionAfterTaxValue}
					bizDeductionAfterTaxCommentValue={bizDeductionAfterTaxCommentValue}
					values={values}
					setBizIncomeValue={setBizIncomeValue}
					setBizDeductionAfterTaxValue={setBizDeductionAfterTaxValue}
					setBizDeductionAfterTaxCommentValue={
						setBizDeductionAfterTaxCommentValue
					}
				/>
			) : (
				<>
					{daylyTimePay > 0 ? (
						<>
							{/* <Table.Row>
								<Table.Cell style={centerStyle}>성명</Table.Cell>
								<Table.Cell style={centerStyle}>생년월일</Table.Cell>
								<Table.Cell style={centerStyle}>책정시급</Table.Cell>
								<Table.Cell style={centerStyle}>통상임금시급</Table.Cell>
								<Table.Cell style={centerStyle}>주휴시급</Table.Cell>
							</Table.Row>
							<Table.Row>
								<Table.Cell style={centerStyle}>{worker}</Table.Cell>
								<Table.Cell style={centerStyle}>
									{decryptoData?.workerSocialNumberFront}
								</Table.Cell>
								<Table.Cell style={centerStyle}>
									{numberToCommaString(roundUp(handleNaN(daylyTimePay)))}
								</Table.Cell>
								<Table.Cell style={centerStyle}>
									{numberToCommaString(roundUp(handleNaN(timeWorkerTimeWage)))}
								</Table.Cell>
								<Table.Cell style={centerStyle}>
									{numberToCommaString(
										roundUp(handleNaN(timeWorkerTimePaidHoliWage))
									)}
								</Table.Cell>
							</Table.Row> */}
							<ClientRTimeWorkerMonthWageTableBasicInfo
								centerStyle={centerStyle}
								worker={worker}
								decryptoData={decryptoData}
								numberToCommaString={numberToCommaString}
								roundUp={roundUp}
								daylyTimePay={daylyTimePay}
								timeWorkerTimeWage={timeWorkerTimeWage}
								timeWorkerTimePaidHoliWage={timeWorkerTimePaidHoliWage}
							/>
						</>
					) : (
						<>
							{/* <Table.Row>
								<Table.Cell style={centerStyle}>
									성명 <br /> (생년월일)
								</Table.Cell>
								<Table.Cell style={centerStyle}>평일 일당</Table.Cell>
								<Table.Cell style={centerStyle}>통상시급</Table.Cell>
								<Table.Cell style={centerStyle}>
									1일 근로시간 <br /> (전체)
								</Table.Cell>
								<Table.Cell style={centerStyle}>
									1일 유급시간 <br />
									(전체){' '}
								</Table.Cell>
								<Table.Cell style={centerStyle}>1일 기본급시간</Table.Cell>
								<Table.Cell style={centerStyle}>1일 주휴수당</Table.Cell>
								<Table.Cell style={centerStyle}>1일 연장시간</Table.Cell>
								<Table.Cell style={centerStyle}>1일 야간시간</Table.Cell>
							</Table.Row>
							<Table.Row>
								<Table.Cell style={centerStyle}>
									{worker}
									<br /> {decryptoData?.workerSocialNumberFront}
								</Table.Cell>
								<Table.Cell style={centerStyle}>
									{numberToCommaString(roundUp(handleNaN(dayWorkerWage)))}
								</Table.Cell>
								<Table.Cell style={centerStyle}>
									{numberToCommaString(roundUp(handleNaN(dayWokerTimeWage)))}
								</Table.Cell>
								<Table.Cell style={centerStyle}>
									{roundUp2(handleNaN(dayWorkerWorkingTimeDayTotal))}
								</Table.Cell>
								<Table.Cell style={centerStyle}>
									{roundUp2(handleNaN(dayWorkerDayTotalPaidConversedTime))}
								</Table.Cell>
								<Table.Cell style={centerStyle}>
									{roundUp2(
										handleNaN(
											numOfWorkers >= 5
												? dayWorkerWorkingTimeDayBasic
												: dayWorkerWorkingTimeDayTotal
										)
									)}
								</Table.Cell>
								<Table.Cell style={centerStyle}>
									{roundUp2(handleNaN(dayWorkerPaidHolidayLBTime))}
								</Table.Cell>
								<Table.Cell style={centerStyle}>
									{roundUp2(handleNaN(dayWorkerWorkingTimeDayOver))}
									<br /> 연장환산 (
									{roundUp2(handleNaN(dayWorkerWorkingTimeDayOver * 1.5))}){' '}
								</Table.Cell>
								<Table.Cell style={centerStyle}>
									{roundUp2(handleNaN(dayWorkerWorkingTimeDayNight))}
									<br /> 야간환산 (
									{roundUp2(handleNaN(dayWorkerWorkingTimeDayNight * 0.5))})
								</Table.Cell>
							</Table.Row> */}
							<ClientRDayWorkerMonthWageTableBasicInfo
								centerStyle={centerStyle}
								worker={worker}
								decryptoData={decryptoData}
								numberToCommaString={numberToCommaString}
								roundUp={roundUp}
								dayWorkerWage={dayWorkerWage}
								dayWokerTimeWage={dayWokerTimeWage}
								dayWorkerWorkingTimeDayTotal={dayWorkerWorkingTimeDayTotal}
								roundUp2={roundUp2}
								dayWorkerDayTotalPaidConversedTime={
									dayWorkerDayTotalPaidConversedTime
								}
								numOfWorkers={numOfWorkers}
								dayWorkerWorkingTimeDayBasic={dayWorkerWorkingTimeDayBasic}
								dayWorkerPaidHolidayLBTime={dayWorkerPaidHolidayLBTime}
								dayWorkerWorkingTimeDayOver={dayWorkerWorkingTimeDayOver}
								dayWorkerWorkingTimeDayNight={dayWorkerWorkingTimeDayNight}
							/>
						</>
					)}
				</>
			)}
		</>
	);
}
