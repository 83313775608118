import React from 'react'

export default function ClientDash2023NewLaw({setOpenWhatsNew2023, openWhatsNew2023}) {
  return (
    <>
      <br />
      <div  onClick={() => setOpenWhatsNew2023(!openWhatsNew2023)} style={{cursor:'pointer'}}>
        <h3>시행시기 2022년 12월 11일 : 노사협의회 근로자위원 선출방식 변경</h3>
        <p>* 근로자 10인이상 추천 조항 삭제, 근로자위원은 전체 근로자 과반수가 투표(기존 직접, 비밀, 무기명 투표는 존속)에 참여하여 선출해야 함.</p>
        
        <h3>시행시기 2022년 12월 11일 : 교원 및 공무원도 근로시간 면제제도 시행</h3>
        <p>* 노동조합 업무를 근무시간에 수행할 경우 노사가 합의한 일정 한도에서 유급으로 인정하는 제도가 공무원과 교원에게도 시행됨.</p>
        
        <h3>시행시기 2023년 1월 1일 : 최저임금 시간당 9,620원, 주휴수당 포함시 11,544원, (209시간(주휴포함) 월급기준 2,010,580원)</h3>
        <p>최저임금 계산시 정기상여금은 월 100,529원을 한도(월 2,010,580원의 5%), 복리후생비는 20,106원(월 2,010,580원의 1%)을 한도로 제외됨.</p>
        
        <h3>시행시기 2023년 8월 18일 : 사업장 내 휴게시설 설치의무화(산업안전보건법 제128조의2)</h3>
        <p>* 상시근로자 20인 이상 사업주(상시근로자 10인이상 사업장 중 상담원 또는 경비원인 근로자를 2명이상 채용한 사업장)는 고용노동부령으로 정하는 설치/관리기준에 맞는 휴게시설을 갖춰야 함.</p>
        <p>* 미설치시 1500만원 이하의 과태료, 휴게시설 설치/관리기준(주요내용 : 최소면적 약2평, 최소높이 2.1m, 냉,난방 시설(18도~28도 유지) 등) 미준수시 1000만원이하의 과태료 부과</p>

        <h3>시행시기 2023년 1월 1일 : 건강보험료 6.99%에서 7.09%로 인상(회사 3.545% + 직원 3.545%)</h3>
        <p>* 장기요양보험료는 건보료의 12.27%에서 12.81%로 인상.</p>
        <p>* 2023년 월 209시간 최저임금 2,010,580원 대비 건강보험 근로자 부담액 : 71,270원, 요양보험 근로자 부담액 : 9,120원임.</p>


        <h3>시행시기 2023년 1월 1일 : 실업급여액 인상</h3>
        <p>* 마지막 근무일이 2023년 1월 1일이후인 경우 상한액은 기존 66,000원과 동일하나 소정근로시간 8시간 근무시 61,568원으로 2022년의 60,120원보다 상향됨.</p>
        <p>* 기타 소정근로시간별 하한액은 7시간인 경우는 53,872원, 6시간인 경우는 46,176원, 5시간인 경우는 38,480원,4시간 이하는 경우는 30,784원임. </p>
        
        <h3>시행시기 2023년 1월 1일 : 식대 비과세 월 20만원으로 인상</h3>
        <p>* 사업장에서 식사를 제공하는 경우는 식대 비과세 처리가 되지 않으니 주의요망.</p>

        <h3>기타 수시로 변경되는 법령을 추가로 올리겠습니다.</h3>

        <br />
        <button style={{fontSize : '15px', color: 'black', height: '30px', width: '200px', background: 'white'}} onClick={() => setOpenWhatsNew2023(!openWhatsNew2023)}>내용 닫기</button>
        <hr />
      </div>
    </>
  )
}
