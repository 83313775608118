export const CREATE_UNTACT = 'CREATE_UNTACT';
export const UPDATE_UNTACT = 'UPDATE_UNTACT';
export const DELETE_UNTACT = 'DELETE_UNTACT';
export const FETCH_UNTACTS = 'FETCH_UNTACTS';
export const FETCH_UNTACTS_TABLE_SAMPLE = 'FETCH_UNTACTS_TABLE_SAMPLE';
export const LISTEN_TO_UNTACT_CHAT = 'LISTEN_TO_UNTACT_CHAT';
export const CLEAR_COMMENTS = 'CLEAR_COMMENTS';
export const LISTEN_TO_SELECTED_UNTACT = 'LISTEN_TO_SELECTED_UNTACT';
export const CLEAR_UNTACTS = 'CLEAR_UNTACTS';
export const CLEAR_UNTACTS_TABLE_SAMPLE = 'CLEAR_UNTACTS_TABLE_SAMPLE';
export const CLEAR_SELECTED_UNTACT = 'CLEAR_SELECTED_UNTACT';
export const SET_FILTER_UNTACT = 'SET_FILTER_UNTACT'; 
export const SET_START_DATE_UNTACT = 'SET_START_DATE_UNTACT'; 
export const RETAIN_STATE = 'RETAIN_STATE'; 


