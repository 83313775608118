import React from 'react'
import { Table } from 'semantic-ui-react'

export default function ClientReqDLCWageTableDayWorkerDetailAWage5({
  gumakStyle,
  centerStyle,
  inputWageStyle,
  inputCommentStyle,
  dayWorkerCAWage5,
  dayWorkerCAWEName5,
  dayWorkerCAWage5Comment,
  handelInputChange
}) {

  return (
    <>
      <Table.Cell className='gubun' style={centerStyle}>평균임금</Table.Cell>
      <Table.Cell className='gumak' style={gumakStyle}><input style={inputWageStyle} name='dayWorkerCAWEName5' value = {dayWorkerCAWEName5} onChange= {(e) => handelInputChange(e)} /></Table.Cell>
      <Table.Cell className='gumak' style={gumakStyle}><input style={inputWageStyle} name='dayWorkerCAWage5' value = {dayWorkerCAWage5} onChange= {(e) => handelInputChange(e)} /></Table.Cell>
      <Table.Cell colSpan='6' className='sulmung'><textarea style={inputCommentStyle} name='dayWorkerCAWage5Comment' value = { dayWorkerCAWage5Comment } onChange = {(e) => handelInputChange(e)}/></Table.Cell>
    </>
  )
}
