import React from 'react'
import { Table } from 'semantic-ui-react'

export default function ClientReqDLCWageTableDetailTFEtc({
  weekLBTime,
  gumakStyle,
  cTFEtc,
  cTFEtcComment,
  isExceptTimeLawWorker
}) {

  return (
    <>
      <Table.Cell className='gubun'>통상임금 {(weekLBTime < 15 || isExceptTimeLawWorker) ? null : <> <br /> (주휴포함)</>}</Table.Cell>
      <Table.Cell className='hangmok'>그외 비과세 통상임금계</Table.Cell>
      <Table.Cell className='gumak' style={gumakStyle}>{cTFEtc}</Table.Cell>
      <Table.Cell textAlign='left' colSpan='6' className='sulmung'>{ cTFEtcComment }</Table.Cell>
    </>
  )
}
