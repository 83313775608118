import { useEffect } from 'react';
import { onAuthStateChanged } from 'firebase/auth';
import {
	getFirestore,
	doc,
	getDoc,
	updateDoc,
	serverTimestamp,
	arrayUnion,
	Timestamp,
} from 'firebase/firestore';
import { auth } from '../../app/config/firebase'; // Firebase Auth 인스턴스 import

const UpdateLastSignIn = () => {
	useEffect(() => {
		const unsubscribe = onAuthStateChanged(auth, async (user) => {
			if (user) {
				const db = getFirestore();
				const userRef = doc(db, 'users', user.uid);

				try {
					// 사용자 문서에서 현재 데이터를 가져옵니다.
					const docSnap = await getDoc(userRef);
					if (docSnap.exists()) {
						// 문서가 존재하면 authLevel을 확인합니다.
						const userData = docSnap.data();
						const authLevel = userData.authLevel || 0; // authLevel이 없는 경우 기본값은 0으로 설정

						// authLevel이 100 미만인 경우에만 loginHistory를 업데이트합니다.
						if (authLevel < 100) {
							await updateDoc(userRef, {
								lastSignInTime: serverTimestamp(), // 마지막 로그인 시간 업데이트
								loginHistory: arrayUnion(Timestamp.now()), // 로그인 기록 배열에 현재 Timestamp 추가
							});
							// console.log("Last sign-in time and login history updated");
						} else {
							// authLevel이 100 이상인 경우, 마지막 로그인 시간만 업데이트합니다.
							await updateDoc(userRef, {
								lastSignInTime: serverTimestamp(),
							});
							// console.log("Last sign-in time updated for authLevel >= 100");
						}
					} else {
						console.log('No such document!');
					}
				} catch (error) {
					console.error(
						'Error updating last sign-in time and login history:',
						error
					);
				}
			}
		});

		// 컴포넌트 언마운트 시 리스너 해제
		return () => unsubscribe();
	}, []);

	return null;
};

export default UpdateLastSignIn;
