import React from 'react';
import { toast } from 'react-toastify';
import { Button } from 'semantic-ui-react';

function ClientWageTableTopButton({
	initialTaxButtonClickHandler,
	selfDeductButtonClickHandler,
}) {
	return (
		<div>
			<Button.Group floated='right'>
				<Button
					type='button'
					onClick={() => initialTaxButtonClickHandler()}
					onMouseOver={() =>
						toast('수기입력된 세금, 4대보험을 법정기준으로 다시 계산합니다.')
					}
					color='teal'>
					공제자동계산
				</Button>
				<Button
					type='button'
					onClick={() => selfDeductButtonClickHandler()}
					color='red'>
					공제수기입력
				</Button>
			</Button.Group>
		</div>
	);
}

export default ClientWageTableTopButton;
