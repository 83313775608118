import React, { useState } from 'react';
import * as moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';
import { Item, Segment } from 'semantic-ui-react';
import { decrypto } from '../../../../../app/firestore/firestoreService';
import { deleteClientReq4InInFirestore } from '../../../../../app/firestore/firestoreService4In';
import { deleteClientReq4In } from '../../clientReqActions4In';
import { asyncActionError } from '../../../../../app/async/asyncReducer';
import { freeComClient } from '../../../../../app/common/util/util';
import CustomConfirm from '../../../../../app/layout/ConfirmComponent';
import BizWorkerListItem4InButton from '../BizWorkerListItem4InButton';

export default function ClientReqListItem4InBiz({
	clientReq4In,
	index,
	clientReqs4In,
	authLevel,
	companyName,
	bizIncomeWorker,
}) {
	const dispatch = useDispatch();
	const { currentUserProfile } = useSelector((state) => state.profile);
	const [loadingDelete, setLoadingDelete] = useState(false);
	const [confirmOpen, setConfirmOpen] = useState(false);

	// 유효한 날짜 값인지 확인하고 포맷하는 함수
	const formatDateWithMoment = (dateString) => {
		return moment(dateString).isValid()
			? moment(dateString).format('YYYY-MM-DD')
			: '';
	};

	const decryptoClientReq = {};
	for (const prop in clientReq4In) {
		decryptoClientReq[prop] =
			prop === 'workerSocialNumberFront' ||
			prop === 'workerSocialNumberBack' ||
			prop === 'familySocialNumber' ||
			prop === 'healthCarePeopleComments'
				? decrypto(clientReq4In[prop], String(process.env.CRYPTO_KEY))
				: clientReq4In[prop];
	}

	//입사일
	const enterDate = clientReq4In
		? formatDateWithMoment(decryptoClientReq?.workerEnteredDate)
		: '';

	async function handleDeleteToggle() {
		setConfirmOpen(false);
		setLoadingDelete(true);
		try {
			await deleteClientReq4InInFirestore(clientReq4In.id);
			await dispatch(deleteClientReq4In(clientReq4In.id));
			setLoadingDelete(false);
		} catch (error) {
			setLoadingDelete(true);
			dispatch(asyncActionError());
		}
	}

	return (
		<>
			<Segment.Group style={{ marginBottom: '-30px' }}>
				<Segment>
					<Item.Group>
						<Item>
							<Item.Content>
								{authLevel >= 10 && (
									<>
										<Item.Description>
											<BizWorkerListItem4InButton
												clientReq4In={clientReq4In}
												freeComClient={freeComClient}
												index={index}
												clientReqs4In={clientReqs4In}
												currentUserProfile={currentUserProfile}
												enterDate={enterDate}
												loadingDelete={loadingDelete}
												setConfirmOpen={setConfirmOpen}
												companyName={companyName}
												bizIncomeWorker={bizIncomeWorker}
											/>
										</Item.Description>
										<br />
									</>
								)}
							</Item.Content>
						</Item>
					</Item.Group>
				</Segment>

				<CustomConfirm
					title='삭제 확인' // 제목을 적절하게 설정하세요.
					content='해당 DATA가 모두 삭제됩니다. 진짜 삭제하시겠습니까?'
					open={confirmOpen}
					onCancel={() => setConfirmOpen(false)}
					onConfirm={handleDeleteToggle}
				/>
			</Segment.Group>
		</>
	);
}
