import React from 'react';
import { useSelector } from 'react-redux';
import { Grid, Loader } from 'semantic-ui-react';
import EventTable from '../../events/eventsTableComponent/EventTable'

export default function EventDashboardForNomusa() {
  const { loading } = useSelector(state => state.async);
  const {currentUserProfile} = useSelector(state => state.profile)

  return (
    <>
      {currentUserProfile?.authLevel >= 90 &&
      <EventTable />}
      <Grid>
        <Grid.Column width={16}>
          <Loader active={loading} />
        </Grid.Column>
      </Grid>
    </>
  );
};

