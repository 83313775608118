import { format } from 'date-fns/esm';
import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';
import { Button, Header, Image, Item, Segment } from 'semantic-ui-react';
import { delay, freeComClient } from '../../../../app/common/util/util';
import UnauthModal from '../../../auth/UnauthModal';
import { dataFromSnapshotIdDate } from '../../../../app/firestore/firestoreService';
import {
	clickConfirmedTimeOfLaborContract,
	clickConfirmedTimeOfWageContract,
	getWorkerUserProfile,
} from '../../../../app/firestore/firestoreService4In';
import { listenToWorkerUsers } from '../../../profiles/profileActions';

const eventImageStyle = {
	filter: 'brightness(30%)',
	height: '100px',
};

const eventImageTextStyle = {
	position: 'absolute',
	bottom: '5%',
	left: '5%',
	width: '100%',
	height: 'auto',
	color: 'white',
};

export default function ClientReqDetailedHeader4In({
	isDayOrTimeWorker,
	selectedClientReq4In,
	companyName,
	clientReqs4In,
	daylyTimePay,
	isBizWorker,
}) {
	const dispatch = useDispatch();
	const history = useHistory();
	const [modalOpen, setModalOpen] = useState(false);

	const { currentUserProfile } = useSelector((state) => state.profile);
	const { clientInfo, superAdminForFreeTrue } = useSelector(
		(state) => state.companyReducer
	);

	const isWorker = currentUserProfile?.isWorker;
	const numOfWorkers = +currentUserProfile?.numOfWorkers;
	const authLevel = clientInfo?.authLevel
		? clientInfo?.authLevel
		: currentUserProfile?.authLevel;
	const ceoName = clientInfo?.ceoName
		? clientInfo?.ceoName
		: currentUserProfile?.ceoName;

	useEffect(() => {
		const unSubscribe = getWorkerUserProfile({
			next: (snapshot) =>
				dispatch(
					listenToWorkerUsers(
						snapshot.docs.map((docSnapshot) =>
							dataFromSnapshotIdDate(docSnapshot)
						)
					)
				),
			error: (error) => console.log(error),
		});
		return () => {
			unSubscribe();
		};
	}, [dispatch]);

	function goToPaydocuList() {
		history.push('/paydocus');
	}

	function goToAnnualLeavesWorker() {
		history.push('/annualLeavesWorker');
	}

	function laborConHnadler(id) {
		delay(1000);
		history.push(`/clientReqs4In/${id}`);
	}

	function laborConHnadlerWorker(id) {
		delay(1000);
		clickConfirmedTimeOfLaborContract(id);
		history.push(`/clientReqs4In/${id}`);
	}

	function wageConHnadler(id) {
		delay(1000);
		history.push(`/clientRDLaborWageContract/${id}`);
	}

	function wageConHnadlerWorker(id) {
		delay(1000);
		clickConfirmedTimeOfWageContract(id);
		history.push(`/clientRDLaborWageContract/${id}`);
	}

	function obeyConHnadler(id) {
		delay(1000);
		history.push(`/clientRDRuleObeyAgree/${id}`);
	}

	function personalConHnadler(id) {
		delay(1000);
		history.push(`/clientRDPersonalInfoAgree/${id}`);
	}

	const dayWorkerOrTimeWorkerText = daylyTimePay
		? '시급직 근로계약서'
		: '일용직 근로계약서';

	const freeOpenCopyYesOrNot = superAdminForFreeTrue
		? false
		: freeComClient(authLevel, ceoName) && clientReqs4In.length === 1
		? false
		: freeComClient(authLevel, ceoName)
		? true
		: false;

	return (
		<>
			{modalOpen && <UnauthModal setModalOpen={setModalOpen} />}
			{!isWorker ? null : (
				<>
					<Button onClick={() => goToPaydocuList()} color='teal'>
						임금명세서
					</Button>
					{numOfWorkers >= 5 && !isDayOrTimeWorker && (
						<Button onClick={() => goToAnnualLeavesWorker()} color='yellow'>
							연차사용내역
						</Button>
					)}
				</>
			)}
			<Segment.Group>
				<Segment basic attached='top' style={{ padding: '0' }}>
					<Image
						src={`/assets/categoryImages/travel.jpg`}
						fluid
						style={eventImageStyle}
					/>
					{isWorker ? null : (
						<Segment basic style={eventImageTextStyle}>
							<Item.Group>
								<Item>
									<Item.Content>
										<Header
											size='huge'
											content={selectedClientReq4In?.workerName}
											style={{ color: 'white' }}
										/>
										<p>
											등록일 :{' '}
											{selectedClientReq4In.date
												? format(
														selectedClientReq4In?.date,
														'yyyy-MM-dd h:mm a'
												  )
												: ''}
										</p>
										<p>
											작성자 :{' '}
											<strong>
												<Link to={`/profile/${selectedClientReq4In?.hostUid}`}>
													{companyName}
												</Link>
											</strong>
										</p>
									</Item.Content>
								</Item>
							</Item.Group>
						</Segment>
					)}
				</Segment>
				<Segment attached='bottom' clearing>
					<>
						{isWorker ? null : (
							<>
								{isDayOrTimeWorker || isBizWorker ? (
									<>
										<Button
											disabled={freeOpenCopyYesOrNot}
											as={Link}
											to={`/manageClientReqDayWorker/${selectedClientReq4In?.id}`}
											color='red'
											floated='left'>
											인사정보(수정/복사)
										</Button>
										<Button
											as={Link}
											to={`/clientRDaylyWorkerMonthWageTable/${selectedClientReq4In?.id}`}
											color='purple'
											floated='left'>
											명세서작성
										</Button>
									</>
								) : (
									<>
										<Button
											as={Link}
											to={`/clientRDWageTable/${selectedClientReq4In?.id}`}
											color='purple'
											floated='left'>
											명세서작성
										</Button>
										<Button
											disabled={freeOpenCopyYesOrNot}
											as={Link}
											to={`/manageClientReq4In/${selectedClientReq4In?.id}`}
											color='red'
											floated='left'>
											인사정보(수정/복사)
										</Button>
									</>
								)}
							</>
						)}
						{isBizWorker ? (
							<Button
								onClick={() => history.push('/clientReqs4In')}
								color='green'
								floated='right'>
								{isWorker ? '설명' : '직원List'}
							</Button>
						) : (
							<>
								{!isDayOrTimeWorker ? (
									<>
										{isWorker ? (
											<>
												<Button
													onClick={() =>
														laborConHnadlerWorker(selectedClientReq4In?.id)
													}
													color='purple'
													floated='right'>
													근로계약서
												</Button>
												<Button
													onClick={() =>
														wageConHnadlerWorker(selectedClientReq4In?.id)
													}
													color='green'
													floated='right'>
													임금계약서
												</Button>
											</>
										) : (
											<>
												<Button
													onClick={() =>
														laborConHnadler(selectedClientReq4In?.id)
													}
													color='purple'
													floated='right'>
													근로계약서
												</Button>
												<Button
													onClick={() =>
														wageConHnadler(selectedClientReq4In?.id)
													}
													color='green'
													floated='right'>
													임금계약서
												</Button>
											</>
										)}
										<Button
											disabled={
												superAdminForFreeTrue
													? false
													: freeComClient(authLevel, ceoName) && !isWorker
													? true
													: false
											}
											onClick={() => obeyConHnadler(selectedClientReq4In?.id)}
											color='yellow'
											floated='right'>
											복무서약서
										</Button>
										<Button
											disabled={
												superAdminForFreeTrue
													? false
													: freeComClient(authLevel, ceoName) && !isWorker
													? true
													: false
											}
											onClick={() =>
												personalConHnadler(selectedClientReq4In?.id)
											}
											color='teal'
											floated='right'>
											개인정보동의서
										</Button>
										<Button
											onClick={() => history.push('/clientReqs4In')}
											color='green'
											floated='right'>
											{isWorker ? '설명' : '직원List'}
										</Button>
									</>
								) : (
									<>
										<Button
											as={Link}
											to={`/clientReqDaylyPayContract/${selectedClientReq4In?.id}`}
											color='purple'
											floated='right'>
											{dayWorkerOrTimeWorkerText}
										</Button>
										<Button
											disabled={
												superAdminForFreeTrue
													? false
													: freeComClient(authLevel, ceoName) && !isWorker
													? true
													: false
											}
											as={Link}
											to={`/clientRDPersonalInfoAgree/${selectedClientReq4In?.id}`}
											color='teal'
											floated='right'>
											개인정보동의서
										</Button>
										<Button
											onClick={() => history.push('/clientReqs4In')}
											color='green'
											floated='right'>
											{isWorker ? '설명' : '직원List'}
										</Button>
									</>
								)}
							</>
						)}
					</>
				</Segment>
			</Segment.Group>
		</>
	);
}
