import React, { useEffect, useState } from 'react';
import { Button, Table } from 'semantic-ui-react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { format } from 'date-fns';
import {
	delay,
	numberToCommaString,
	roundUp,
} from '../../../app/common/util/util';
import {
	clickConfirmTiemOfWorker,
	clickConfirmTimeOfCompany,
	deletePaydocuInFirestore,
} from '../../../app/firestore/firestoreService4In';
import {
	deletePaydocu,
	sendPaydocuCopy,
	sendPaydocuFix,
	sendPaydocuId,
} from '../companyActions';
import { asyncActionError } from '../../../app/async/asyncReducer';
import moment from 'moment';
import ColorChangingButton from './ColorChangingButton';
// import * as XLSX from 'xlsx';
import CustomConfirm from '../../../app/layout/ConfirmComponent';

export default function PaydocuList({
	refetchPaydocus,
	filteredWorker,
	paydocus,
	searchResults,
	setSearchResults,
	searchTerm,
	filter,
	yearMonth,
	paydocuWorkerUid,
	isWorker,
	isBizWorkerUser,
	freeDeleteDisable,
}) {
	const history = useHistory();
	const dispatch = useDispatch();
	const [loadingDelete, setLoadingDelete] = useState(false);
	const [loadingAllToggle, setLoadingAllToggle] = useState(false);

	const { currentUserProfile } = useSelector((state) => state.profile);
	const { clientInfo } = useSelector((state) => state.companyReducer);
	const viewLevel = clientInfo?.authLevel
		? clientInfo?.authLevel
		: currentUserProfile?.authLevel;
	const deleteRight = currentUserProfile?.authLevel >= 101 ? true : false;

	const [buttonStates, setButtonStates] = useState(
		Array(paydocus.length).fill({ color: 'teal', text: '승인' })
	);
	const [confirmData, setConfirmData] = useState({
		open: false,
		type: null,
		searchResult: {},
		index: -1,
	});
	const [updatedPaydocus, setUpdatedPaydocus] = useState(paydocus);
	const [allConfirmed, setAllConfirmed] = useState(false);

	const id = clientInfo?.id
		? clientInfo?.id
		: clientInfo?.clientUid
		? clientInfo?.clientUid
		: currentUserProfile?.id;

	const handleCancel = () => {
		setConfirmData({ ...confirmData, open: false });
	};

	const handleClick = (searchResult, index, type, id) => {
		setConfirmData({ open: true, type, searchResult, index, id });
	};

	useEffect(() => {
		if (paydocus && paydocus.length > 0) {
			setUpdatedPaydocus(paydocus);
		}
	}, [paydocus]);

	const handleConfirm = async () => {
		const { id, index, type } = confirmData;

		try {
			if (type === 'confirm' || type === 'unconfirm') {
				const isConfirmed = type === 'confirm' ? false : true;
				await clickConfirmTimeOfCompany(id, isConfirmed);
				// updatedPaydocus 상태 업데이트
				const newUpdatedPaydocus = updatedPaydocus.map((doc, idx) =>
					idx === index ? { ...doc, isCompanyComformedTime: isConfirmed } : doc
				);
				setUpdatedPaydocus(newUpdatedPaydocus);
			} else if (type === 'delete') {
				await handleDeleteToggle(id);
				// 삭제 후 필요한 상태 업데이트 로직
			}
		} catch (error) {
			console.error('Confirm handling failed:', error);
			// 에러 처리 로직 추가 (예: 사용자에게 알림)
		} finally {
			setConfirmData({ ...confirmData, open: false }); // 모달 닫기
			if (typeof refetchPaydocus === 'function') {
				refetchPaydocus(filter, yearMonth, paydocuWorkerUid, clientInfo); // 데이터 새로고침, 필요한 인자 포함
			}
		}
	};

	useEffect(() => {
		setButtonStates(
			updatedPaydocus.map((paydocu) => ({
				color: paydocu.isCompanyComformedTime ? 'orange' : 'teal',
				text: paydocu.isCompanyComformedTime ? '마감 해제' : '승인',
			}))
		);
	}, [updatedPaydocus]);

	const handleAllToggle = async () => {
		setLoadingAllToggle(true);
		try {
			const newAllConfirmed = !allConfirmed;
			await Promise.all(
				updatedPaydocus.map((doc) =>
					clickConfirmTimeOfCompany(doc.id, doc.isCompanyComformedTime)
				)
			);

			const newUpdatedPaydocus = updatedPaydocus.map((doc) => ({
				...doc,
				isCompanyComformedTime: newAllConfirmed,
			}));
			setUpdatedPaydocus(newUpdatedPaydocus);
			// 페이지 새로고침
			window.location.reload();
			setAllConfirmed(newAllConfirmed);
		} catch (error) {
			console.error('All toggle operation failed:', error);
		} finally {
			setLoadingAllToggle(false);
		}
	};

	// useEffect 사용하여 buttonStates 업데이트
	useEffect(() => {
		setButtonStates(
			updatedPaydocus.map((paydocu) => ({
				color: paydocu.isCompanyComformedTime ? 'orange' : 'teal',
				text: paydocu.isCompanyComformedTime ? '마감 해제' : '승인',
			}))
		);
	}, [updatedPaydocus]);

	useEffect(() => {
		const searchedPaydocus = paydocus.filter(
			(paydocu) =>
				paydocu?.workerInfo?.worker?.includes(searchTerm) ||
				paydocu?.info?.yearMonth?.includes(searchTerm) ||
				paydocu?.companyInfo?.companyName?.includes(searchTerm)
		);

		// 상태 업데이트가 필요한 경우에만 setState 호출
		if (JSON.stringify(searchResults) !== JSON.stringify(searchedPaydocus)) {
			setSearchResults(searchedPaydocus);
		}
	}, [searchTerm, paydocus, searchResults, setSearchResults]);

	function viewPaydocuHandlerWorker(id, isWorkerComformedTime) {
		delay(1000);
		history.push(`/paydocuDetailedPage/${id}`);
		clickConfirmTiemOfWorker(id, isWorkerComformedTime);
	}

	function viewPaydocuHandler(id) {
		delay(1000);
		history.push(`/paydocuDetailedPage/${id}`);
	}

	async function handleDeleteToggle(id) {
		setLoadingDelete(true); // 삭제 작업 시작 시 로딩 상태 활성화
		try {
			await deletePaydocuInFirestore(id);
			dispatch(deletePaydocu(id)); // 성공적으로 삭제되면 상태 업데이트
		} catch (error) {
			console.error('Delete operation failed:', error);
			dispatch(asyncActionError(error)); // 에러 발생 시 에러 처리
		} finally {
			setLoadingDelete(false); // 작업 완료 후 로딩 상태 비활성화
		}
	}

	async function handleManageWageTable(id, workerId, copy, fix) {
		history.push(`/manageClientRDWageTable/${workerId}`);
		dispatch(sendPaydocuId(id));
		dispatch(sendPaydocuCopy(copy));
		dispatch(sendPaydocuFix(fix));
	}

	async function handleManageDayWorkerWageTable(id, workerId) {
		history.push(`/manageClientRDaylyWorkerMonthWageTable/${workerId}`);
		dispatch(sendPaydocuId(id));
	}

	async function healthCareRecalHandler(id, workerId) {
		history.push(`/healthCareRecal/${workerId}`);
		dispatch(sendPaydocuId(id));
	}

	async function retireWageCalHandler(id, workerId) {
		history.push(`/retireWageCal/${workerId}`);
		dispatch(sendPaydocuId(id));
	}

	async function retireFundPageHandler(id, workerId) {
		history.push(`/clientRetireFundPage/${workerId}`);
		history.go(0);
		dispatch(sendPaydocuId(id));
	}

	const copy = true;
	const fix = true;

	const dayWorkersPaydocus =
		filteredWorker(searchResults)[0]?.dayWorker === true ? true : false;
	const timeWorkersPaydocus =
		filteredWorker(searchResults)[0]?.isTimeWorker === true ? true : false;
	const bizWorkersPaydocus =
		filteredWorker(searchResults)[0]?.isBizWorker === true ? true : false;

	const workerType =
		dayWorkersPaydocus || timeWorkersPaydocus
			? '단시간(일용/시급)'
			: bizWorkersPaydocus
			? '사업소득직'
			: '월급직(정규/계약)';

	return (
		<>
			{paydocus.length !== 0 && (
				<>
					{isWorker ? (
						<Table
							style={{ border: '1', width: '100%', textAlign: 'center' }}
							id='table-to-xls'>
							<Table.Header>
								<Table.Row>
									<Table.HeaderCell>No.</Table.HeaderCell>
									<Table.HeaderCell>년월</Table.HeaderCell>
									<Table.HeaderCell>부서명</Table.HeaderCell>
									<Table.HeaderCell>사번</Table.HeaderCell>
									<Table.HeaderCell>직원명</Table.HeaderCell>
									<Table.HeaderCell>공제 전 임금</Table.HeaderCell>
									<Table.HeaderCell>공제 후 임금</Table.HeaderCell>
									<Table.HeaderCell>명세서 작성일</Table.HeaderCell>
									<Table.HeaderCell>열람 시간</Table.HeaderCell>
									<Table.HeaderCell>명세서보기</Table.HeaderCell>
								</Table.Row>
							</Table.Header>
							<Table.Body>
								{paydocus
									.slice()
									.sort((a, b) =>
										b.info.yearMonth.localeCompare(a.info.yearMonth)
									) // 최신 순으로 정렬
									.map((workerData, i) => (
										<Table.Row key={workerData?.id}>
											<Table.Cell>{i + 1}</Table.Cell>
											<Table.Cell>{workerData?.info?.yearMonth}</Table.Cell>
											<Table.Cell>
												{workerData?.workerInfo?.workerDivisions}
											</Table.Cell>
											<Table.Cell>
												{workerData?.workerInfo?.workerComNumber}
											</Table.Cell>
											<Table.Cell>{workerData?.workerInfo?.worker}</Table.Cell>
											<Table.Cell>
												{workerData?.isCompanyComformedTime
													? numberToCommaString(
															workerData?.workerInfo?.dayWorker ||
																workerData?.isTimeWorker
																? workerData?.dayWorkerInfo
																		?.dayWorkerTotalMonthWageAmount
																: workerData?.info?.totalMonthWageAmount
													  ) + '원'
													: '작업중'}
											</Table.Cell>
											<Table.Cell>
												{workerData?.isCompanyComformedTime
													? numberToCommaString(
															workerData?.workerInfo?.dayWorker ||
																workerData?.isTimeWorker
																? workerData?.dayWorkerInfo?.dayWorkerDeductWage
																: workerData?.info?.deductWage
													  ) + '원'
													: '작업중'}
											</Table.Cell>
											<Table.Cell>
												{workerData?.createdAt
													? format(
															new Date(workerData?.createdAt),
															'yyyy-MM-dd h:mm a'
													  )
													: null}
											</Table.Cell>
											<Table.Cell>
												{workerData?.isWorkerComformedTime
													? format(
															new Date(workerData?.isWorkerComformedTime),
															'yyyy-MM-dd h:mm a'
													  )
													: '미열람'}
											</Table.Cell>
											<Table.Cell>
												<Button
													disabled={
														workerData?.info?.yearMonth >= '202207' &&
														!workerData?.isCompanyComformedTime
													}
													onClick={() =>
														viewPaydocuHandlerWorker(
															workerData?.id,
															workerData.isWorkerComformedTime
														)
													}
													color='orange'
													floated='right'>
													{isBizWorkerUser
														? '사업소득 명세서 보기'
														: '임금명세서보기'}
												</Button>
											</Table.Cell>
										</Table.Row>
									))}
							</Table.Body>
						</Table>
					) : (
						<>
							{filteredWorker(searchResults).length > 0 ? (
								<>
									{viewLevel >= 101 && (
										<Button
											color={allConfirmed ? 'orange' : 'teal'}
											onClick={handleAllToggle}
											loading={loadingAllToggle}
											content={
												allConfirmed ? '전체 마감 해제' : '전체 마감/해제'
											}
										/>
									)}
									<h2>{workerType}</h2>
									<Table
										style={{ border: '1', width: '100%', textAlign: 'center' }}
										id='table-to-xls'>
										<Table.Header>
											<Table.Row>
												<Table.HeaderCell>No.</Table.HeaderCell>
												{viewLevel >= 101 ||
												currentUserProfile?.email === 'freya0207' ? (
													<Table.HeaderCell>회사명</Table.HeaderCell>
												) : null}
												<Table.HeaderCell>
													직원명
													<br />/<br />
													사번
												</Table.HeaderCell>
												<Table.HeaderCell>
													부서명 <br />/<br />
													구분
												</Table.HeaderCell>
												<Table.HeaderCell>
													입사일
													<br /> / <br />
													퇴직일
												</Table.HeaderCell>
												<Table.HeaderCell>
													작성일
													<br />/<br />
													직원열람
												</Table.HeaderCell>
												<Table.HeaderCell>
													{workerType === '사업소득직'
														? '세전 사업소득'
														: '세전 근로소득'}
													<br /> / <br />
													{workerType === '사업소득직'
														? '세후 사업소득'
														: '세후 근로소득'}
												</Table.HeaderCell>
												{workerType === '월급직(정규/계약)' ? (
													<Table.HeaderCell>
														명세서보기 <br />/<br />
														이월복사
													</Table.HeaderCell>
												) : (
													<Table.HeaderCell>명세서보기</Table.HeaderCell>
												)}
												<Table.HeaderCell>
													마감
													<br />/<br />
													수정
												</Table.HeaderCell>
												{workerType === '단시간(일용/시급)' ||
												workerType === '사업소득직' ? null : (
													<>
														<Table.HeaderCell>
															<span style={{ color: 'red' }}>
																퇴직(건보 등) 정산 <br />
																(필수)
															</span>
															<span style={{ color: 'blue' }}>
																<br />* 정산 후 4대보험 <br />
																퇴직신고 가능
															</span>
														</Table.HeaderCell>
														<Table.HeaderCell>
															퇴직금 <br />
															(평균임금)
															<br />/ <br />
															명세서
														</Table.HeaderCell>
													</>
												)}
												<Table.HeaderCell>삭제</Table.HeaderCell>
											</Table.Row>
										</Table.Header>
										<Table.Body>
											{filteredWorker(searchResults).map(
												(searchResult, index) => (
													<React.Fragment key={searchResult?.id || index}>
														{(searchResult?.companyInfo?.agentUid === id ||
															searchResult.companyInfo.companyId === id ||
															currentUserProfile?.authLevel >= 100) && (
															<Table.Row key={searchResult?.id}>
																<Table.Cell>{index + 1}</Table.Cell>
																{viewLevel >= 101 ||
																currentUserProfile?.email === 'freya0207' ? (
																	<Table.Cell>
																		{searchResult?.companyInfo?.companyName}
																	</Table.Cell>
																) : null}
																<Table.Cell>
																	{searchResult?.workerInfo?.worker} <br /> /{' '}
																	<br />{' '}
																	{searchResult?.workerInfo?.workerComNumber
																		? searchResult?.workerInfo?.workerComNumber
																		: '없음'}
																</Table.Cell>
																<Table.Cell>
																	{searchResult?.workerInfo?.workerDivisions}
																	<br />/<br />
																	{searchResult?.isTimeWorker
																		? '시급직'
																		: searchResult?.dayWorker
																		? '일용(당)직'
																		: '정규직'}
																</Table.Cell>
																<Table.Cell>
																	{searchResult?.workerInfo?.workerEnteredDate}{' '}
																	<br /> / <br />
																	{searchResult?.workerInfo?.workerRetiredDate
																		? searchResult?.workerInfo
																				?.workerRetiredDate
																		: '재직자'}
																</Table.Cell>
																<Table.Cell>
																	{searchResult?.createdAt
																		? format(
																				new Date(searchResult?.createdAt),
																				'MM-dd h:mm a'
																		  )
																		: null}
																	<br />/<br />
																	{searchResult?.isWorkerComformedTime
																		? format(
																				new Date(
																					searchResult?.isWorkerComformedTime
																				),
																				'MM-dd h:mm a'
																		  )
																		: '안함.'}
																</Table.Cell>
																<Table.Cell>
																	{numberToCommaString(
																		roundUp(
																			searchResult?.dayWorkerInfo?.dayWorker
																				? searchResult?.dayWorkerInfo
																						?.dayWorkerTotalMonthWageAmount
																				: searchResult?.bizTotalIncome
																				? searchResult?.bizTotalIncome
																				: searchResult?.info
																						?.totalMonthWageAmount
																		)
																	)}{' '}
																	원
																	<br /> / <br />
																	{numberToCommaString(
																		roundUp(
																			searchResult?.dayWorkerInfo?.dayWorker
																				? searchResult?.dayWorkerInfo
																						?.dayWorkerDeductWage
																				: searchResult?.bizDeductWage
																				? searchResult?.bizDeductWage
																				: searchResult?.info?.deductWage
																		)
																	)}{' '}
																	원
																</Table.Cell>
																{workerType === '월급직(정규/계약)' ? (
																	<Table.Cell>
																		<Button
																			onClick={() =>
																				viewPaydocuHandler(searchResult?.id)
																			}
																			color='orange'
																			floated='right'>
																			보기
																		</Button>
																		<br />
																		&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;/&nbsp;&nbsp;
																		<br />
																		<Button
																			loading={loadingDelete}
																			disabled={
																				viewLevel >= 100
																					? false
																					: viewLevel === 10 ||
																					  searchResult?.isCompanyComformedTime
																					? false
																					: true
																			}
																			onClick={() =>
																				searchResult?.dayWorkerInfo
																					?.dayWorker ||
																				searchResult?.isBizWorker
																					? handleManageDayWorkerWageTable(
																							searchResult?.id,
																							searchResult?.workerInfo?.workerId
																					  )
																					: handleManageWageTable(
																							searchResult?.id,
																							searchResult?.workerInfo
																								?.workerId,
																							copy,
																							false
																					  )
																			}
																			color='yellow'
																			floated='right'
																			content='복사'
																		/>
																	</Table.Cell>
																) : (
																	<Table.Cell>
																		<Button
																			onClick={() =>
																				viewPaydocuHandler(searchResult?.id)
																			}
																			color='orange'
																			floated='right'>
																			보기
																		</Button>
																	</Table.Cell>
																)}
																<Table.Cell>
																	<ColorChangingButton // 마감
																		loading={loadingDelete}
																		viewLevel={viewLevel}
																		searchResult={searchResult}
																		onClick={() =>
																			handleClick(
																				searchResult,
																				index,
																				searchResult?.isCompanyComformedTime
																					? 'unconfirm'
																					: 'confirm',
																				searchResult?.id
																			)
																		}
																		buttonState={buttonStates[index]}
																	/>
																	<br />
																	&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;/&nbsp;&nbsp;
																	<br />
																	<Button
																		loading={loadingDelete}
																		disabled={
																			viewLevel >= 100
																				? false
																				: viewLevel === 10 ||
																				  searchResult?.isCompanyComformedTime ||
																				  freeDeleteDisable
																				? true
																				: false
																		}
																		onClick={() =>
																			searchResult?.dayWorkerInfo?.dayWorker ||
																			searchResult?.isBizWorker
																				? handleManageDayWorkerWageTable(
																						searchResult?.id,
																						searchResult?.workerInfo?.workerId
																				  )
																				: handleManageWageTable(
																						searchResult?.id,
																						searchResult?.workerInfo?.workerId,
																						false,
																						fix
																				  )
																		}
																		color='yellow'
																		floated='right'
																		content='수정'
																	/>
																</Table.Cell>
																{workerType === '단시간(일용/시급)' ||
																workerType === '사업소득직' ? null : (
																	<>
																		<Table.Cell>
																			{moment(
																				new Date(
																					searchResult?.workerInfo?.workerRetiredDate
																				)
																			).format('YYYYMM') ===
																			searchResult?.info?.yearMonth ? (
																				<Button
																					loading={loadingDelete}
																					onClick={() =>
																						healthCareRecalHandler(
																							searchResult?.id,
																							searchResult?.workerInfo?.workerId
																						)
																					}
																					color='linkedin'
																					floated='right'
																					content='정산'
																				/>
																			) : null}
																		</Table.Cell>
																		<Table.Cell>
																			<Button
																				loading={loadingDelete}
																				onClick={() =>
																					retireWageCalHandler(
																						searchResult?.id,
																						searchResult?.workerInfo?.workerId
																					)
																				}
																				color='twitter'
																				floated='right'
																				content='계산'
																			/>
																			<br />
																			&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;/&nbsp;&nbsp;
																			<br />
																			{moment(
																				new Date(
																					searchResult?.workerInfo?.workerRetiredDate
																				)
																			).format('YYYYMM') ===
																				searchResult?.info?.yearMonth &&
																			moment(
																				new Date(
																					searchResult?.workerInfo?.workerRetiredDate
																				)
																			).diff(
																				moment(
																					new Date(
																						searchResult?.workerInfo?.workerEnteredDate
																					)
																				).subtract(1, 'days'),
																				'year'
																			) >= 1 ? (
																				<Button
																					loading={loadingDelete}
																					onClick={() =>
																						retireFundPageHandler(
																							searchResult?.id,
																							searchResult?.workerInfo?.workerId
																						)
																					}
																					color='teal'
																					floated='right'
																					content='명세'
																				/>
																			) : (
																				<span style={{ textAlign: 'center' }}>
																					&nbsp;&nbsp;&nbsp;&nbsp;없음
																				</span>
																			)}
																		</Table.Cell>
																	</>
																)}
																<Table.Cell>
																	<Button
																		loading={loadingDelete}
																		disabled={
																			deleteRight
																				? false
																				: viewLevel === 10 ||
																				  searchResult?.isWorkerComformedTime ||
																				  searchResult?.isCompanyComformedTime ||
																				  freeDeleteDisable
																				? true
																				: false
																		}
																		onClick={() =>
																			handleClick(
																				searchResult,
																				index,
																				'delete',
																				searchResult?.id
																			)
																		}
																		color='red'
																		floated='right'
																		content='삭제'
																	/>
																</Table.Cell>
															</Table.Row>
														)}
													</React.Fragment>
												)
											)}
										</Table.Body>
									</Table>
									<br />
								</>
							) : null}
						</>
					)}
					<CustomConfirm
						title='확인'
						content={
							confirmData.type === 'confirm'
								? '마감하면 직원이 임금명세서를 볼 수 있습니다. 정말 마감하시겠습니까?'
								: confirmData.type === 'unconfirm'
								? '마감을 해제하시면 직원이 임금명세서를 볼 수 없습니다. 정말 마감을 해제하시겠습니까?'
								: '정말 삭제하시겠습니까?'
						}
						open={confirmData.open}
						onCancel={handleCancel}
						onConfirm={handleConfirm}
					/>
				</>
			)}
		</>
	);
}
