import * as moment from 'moment';

// 입사간주 연도(회계년도 기준 1월 1일이 아닌 이상 다음년도)
export function financeEnterForYear(enterDate, finBaseDate) {
  const realEntD = moment(enterDate);
  const finEntD = moment(finBaseDate);
  const madeFinM = finEntD.get('month');
  const madeFinD = finEntD.get('date');
  const compareFinEntD = moment().set({ 'year': realEntD.get('year'), 'month': madeFinM, 'date': madeFinD });
  const madeFinY = realEntD.isSameOrBefore(compareFinEntD) ? realEntD.get('year') : realEntD.get('year') + 1;
  return moment(moment().set({ 'year': madeFinY, 'month': madeFinM, 'date': madeFinD })).format('YYYY-MM-DD');
}

// 입사간주 월(회계년도 기준 1일이 아닌 이상 다음달)
export function financeEnterForMonth(enterDate, finBaseDate) {
  const realEntD = moment(enterDate);
  const finEntD = moment(finBaseDate);
  const madeFinD = finEntD.get('date');
  const madeFinM = finEntD.get('date') === realEntD.get('date') ? realEntD.get('month') : realEntD.get('month') + 1;
  return moment(moment().set({ 'year': realEntD.get('year'), 'month': madeFinM, 'date': madeFinD })).format('YYYY-MM-DD');
}

