import React, { useEffect } from 'react';
import { Button } from 'semantic-ui-react';
// import { useSelector } from 'react-redux';

import { Link } from 'react-router-dom';
import { format } from 'date-fns';
import SortableTable from '../../../app/common/form/SortableTable';
import { safeStringCompare } from '../../../app/common/util/util';

export default function CompetencyManageList({
	competencies,
	searchResults,
	setSearchResults,
	searchTerm,
}) {
	// const currentUserProfile = useSelector(state => state.profile.currentUserProfile)

	useEffect(() => {
		const searchedCompetencies = competencies.filter((competencies) => {
			return competencies?.companyName?.includes(searchTerm);
		});
		setSearchResults(searchedCompetencies);
	}, [searchTerm, setSearchResults, competencies]);

	const headers = [
		{ key: 'createdAt', label: '회원가입일' },
		{ key: 'companyName', label: '회사이름' },
		{ key: 'workerName', label: '직원이름' },
		{ key: 'workerName', label: '직원이름' },

		{ key: 'toCustomerContext', label: '고객칭찬내용' },
		{ key: 'toCustomerReason', label: '고객칭찬이유' },

		{ key: 'toCoWorkerName', label: '지원한 직원' },
		{ key: 'toCoWorkerContext', label: '지원내용' },
		{ key: 'toCoWorkerReason', label: '지원이유' },

		{ key: 'toCommunicationName', label: '업무공유 직원' },
		{ key: 'toCommunicationContext', label: '업무공유 내용' },
		{ key: 'toCommunicationReason', label: '업무공유 이유' },

		{ key: 'toWorkRecordJob', label: '업무기록' },
		{ key: 'toWorkRecordJobContext', label: '업무기록내용' },
		{ key: 'toWorkRecordJobReason', label: '업무기록이유' },

		{ key: 'toWorkRecordProudJob', label: '모범업무' },
		{ key: 'toWorkRecordSelfPlanedJob', label: '자발적업무' },
		{ key: 'toWorkRecordUnSupportedJob', label: '도움필요업무' },

		{ key: 'toWorkAnalysisHappyJob', label: '즐거운 업무' },
		{ key: 'toWorkAnalysisHappyReason', label: '즐거운 이유' },
		{ key: 'toWorkAnalysisSadJob', label: '힘든 업무' },
		{ key: 'toWorkAnalysisSadReason', label: '힘든 이유' },

		{ key: 'toStudyJob', label: '업무학습' },
		{ key: 'toStudyPersonnal', label: '개인학습' },
		{ key: 'hopeEducationJob', label: '업무요청학습' },
		{ key: 'hopeEducationPersonnal', label: '개인요청학습' },

		{ key: 'grievance', label: '고충사항' },
		{ key: 'compliance', label: '컴플라이언스' },
	];

	const sortFunctions = {
		createdAt: (a, b) => safeStringCompare(a.createdAt, b.createdAt),
		companyName: (a, b) => safeStringCompare(a.companyName, b.companyName),
		workerName: (a, b) => safeStringCompare(a.workerName, b.workerName),
		toCoWorkerName: (a, b) =>
			safeStringCompare(a.toCoWorkerName, b.toCoWorkerName),
		toCustomerContext: (a, b) =>
			safeStringCompare(a.toCustomerContext, b.toCustomerContext),
		toCustomerReason: (a, b) =>
			safeStringCompare(a.toCustomerReason, b.toCustomerReason),
		toCoWorkerContext: (a, b) =>
			safeStringCompare(a.toCoWorkerContext, b.toCoWorkerContext),
		toCoWorkerReason: (a, b) =>
			safeStringCompare(a.toCoWorkerReason, b.toCoWorkerReason),
		toCommunicationName: (a, b) =>
			safeStringCompare(a.toCommunicationName, b.toCommunicationName),
		toCommunicationContext: (a, b) =>
			safeStringCompare(a.toCommunicationContext, b.toCommunicationContext),
		toCommunicationReason: (a, b) =>
			safeStringCompare(a.toCommunicationReason, b.toCommunicationReason),
		toWorkRecordJob: (a, b) =>
			safeStringCompare(a.toWorkRecordJob, b.toWorkRecordJob),
		toWorkRecordJobContext: (a, b) =>
			safeStringCompare(a.toWorkRecordJobContext, b.toWorkRecordJobContext),
		toWorkRecordJobReason: (a, b) =>
			safeStringCompare(a.toWorkRecordJobReason, b.toWorkRecordJobReason),
		toWorkRecordProudJob: (a, b) =>
			safeStringCompare(a.toWorkRecordProudJob, b.toWorkRecordProudJob),
		toWorkRecordSelfPlanedJob: (a, b) =>
			safeStringCompare(
				a.toWorkRecordSelfPlanedJob,
				b.toWorkRecordSelfPlanedJob
			),
		toWorkRecordUnSupportedJob: (a, b) =>
			safeStringCompare(
				a.toWorkRecordUnSupportedJob,
				b.toWorkRecordUnSupportedJob
			),
		oWorkAnalysisHappyJob: (a, b) =>
			safeStringCompare(a.toWorkAnalysisHappyJob, b.toWorkAnalysisHappyJob),
		oWorkAnalysisHappyReason: (a, b) =>
			safeStringCompare(
				a.toWorkAnalysisHappyReason,
				b.toWorkAnalysisHappyReason
			),
		oWorkAnalysisSadJob: (a, b) =>
			safeStringCompare(a.toWorkAnalysisSadJob, b.toWorkAnalysisSadJob),
		oWorkAnalysisSadReason: (a, b) =>
			safeStringCompare(a.toWorkAnalysisSadReason, b.toWorkAnalysisSadReason),
		toStudyJob: (a, b) => safeStringCompare(a.toStudyJob, b.toStudyJob),
		toStudyPersonnal: (a, b) =>
			safeStringCompare(a.toStudyPersonnal, b.toStudyPersonnal),
		hopeEducationJob: (a, b) =>
			safeStringCompare(a.hopeEducationJob, b.hopeEducationJob),
		hopeEducationPersonnal: (a, b) =>
			safeStringCompare(a.hopeEducationPersonnal, b.hopeEducationPersonnal),
		grievance: (a, b) => safeStringCompare(a.grievance, b.grievance),
		compliance: (a, b) => safeStringCompare(a.compliance, b.compliance),
	};

	const formattedData = searchResults.map((result) => ({
		createdAt: result?.addDate
			? format(new Date(result.addDate), 'yyyy-MM-dd')
			: null,
		companyName: result?.companyName,
		workerName: result?.workerName,
		toCoWorkerName: result?.toCoWorker?.name,
		toCustomerContext: result?.toCustomer?.context,
		toCustomerReason: result?.toCustomer?.reason,
		toCoWorkerContext: result?.toCoWorker?.context,
		toCoWorkerReason: result?.toCoWorker?.reason,
		toCommunicationName: result?.toCommunication?.name,
		toCommunicationContext: result?.toCommunication?.context,
		toCommunicationReason: result?.toCommunication?.reason,
		toWorkRecordJob: result?.toWorkRecord?.job,
		toWorkRecordJobContext: result?.toWorkRecord?.context,
		toWorkRecordJobReason: result?.toWorkRecord?.reason,
		toWorkRecordProudJob: result?.toWorkRecord?.proudJob,
		toWorkRecordSelfPlanedJob: result?.toWorkRecord?.selfPlanedJob,
		toWorkRecordUnSupportedJob: result?.toWorkRecord?.unSupportedJob,
		oWorkAnalysisHappyJob: result?.toWorkAnalysis?.happyJob,
		oWorkAnalysisHappyReason: result?.toWorkAnalysis?.happyReason,
		oWorkAnalysisSadJob: result?.toWorkAnalysis?.sadJob,
		oWorkAnalysisSadReason: result?.toWorkAnalysis?.sadReason,
		toStudyJob: result?.toStudy?.job,
		toStudyPersonnal: result?.toStudy?.personnal,
		hopeEducationJob: result?.hopeEducation?.job,
		hopeEducationPersonnal: result?.hopeEducation?.personnal,
		grievance: result?.grievance,
		compliance: result?.compliance,

		id: result?.id,
	}));

	const title = '회사 목록';

	const customRenderers = {
		// 예: 'action'이라는 열 키에 대한 사용자 정의 렌더러 제공
		action: (row) => (
			<Button
				as={Link}
				to={`/manageCompany/${row.id}`}
				color='orange'
				floated='right'>
				수정
			</Button>
		),
	};

	return (
		<SortableTable
			headers={headers}
			data={formattedData}
			sortFunctions={sortFunctions}
			customRenderers={customRenderers}
			title={title}
		/>
	);
}
