import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Grid, Input, Loader } from 'semantic-ui-react';
import { fetchCompetencyFromFirestoreService4In } from '../companyActions';
import CompetencyManageList from './CompetencyManageList';
import PaydocuShortCut from '../paydocuDashboard/PaydocuShortCut';
import { clearClientReqs4In, fetchClientReqs4In } from '../../clientsRequest/clientReq4In/clientReqActions4In';

export default function CompetencyManageDashboard() {
  const dispatch = useDispatch();
  const { competencies, filter, clientInfo } = useSelector(state => state.companyReducer);
 
  const { loading } = useSelector(state => state.async);
  const { authenticated } = useSelector(state => state.auth);
  const {currentUserProfile}   = useSelector(state => state.profile);
  const { clientReqs4In } = useSelector((state) => state.clientReq4InStore);

  const [searchTerm, setSearchTerm] = useState("");
  const [searchResults, setSearchResults] = useState([]);

  const companyBizNumber = clientInfo?.companyBizNumber ? clientInfo?.companyBizNumber : currentUserProfile?.companyBizNumber;

  const isWorker = currentUserProfile?.isWorker;  

  const handleChange = event => {
    setSearchTerm(event.target.value);
  };

  useEffect(() => {
    const fetchClientReqs = async () => {
      try {
        await dispatch(fetchClientReqs4In(filter));
      } catch (error) {
        console.error("Error fetching client requests:", error);
      }
    };

    fetchClientReqs();

    return () => {
      dispatch(clearClientReqs4In());
    };
  }, [dispatch, filter]);

  useEffect(() => {
    const fetchCompetency = async () => {
      try {
        await dispatch(fetchCompetencyFromFirestoreService4In());
      } catch (error) {
        console.error("Error fetching competency data:", error);
      }
    };

    fetchCompetency();
  }, [dispatch]);

  const authLevel = clientInfo?.authLevel ? clientInfo?.authLevel : currentUserProfile?.authLevel;
  
  return (
    <>
      {isWorker ? null : 
        <>
         <PaydocuShortCut filter={filter} clientReqs4In={clientReqs4In}/>    
  
          {authLevel >= 100 ? "회사명" : "직원성명"}
          : <Input
            type="text"
            placeholder="Search"
            value={searchTerm}
            onChange={handleChange}
          />
          <Grid>
            <Grid.Column width={16}>
              <>
                <hr />
                {authenticated &&
                <CompetencyManageList 
                  competencies={competencies}
                  loading={loading}
                  searchResults = {searchResults}
                  setSearchResults = {setSearchResults}
                  searchTerm = {searchTerm}
                  companyBizNumber = {companyBizNumber}
                />}
              </>
            </Grid.Column>
            <Grid.Column width={16}>
              <Loader active={loading} />
            </Grid.Column>
          </Grid>
        </>
      }
    </>
  );
};