import React from 'react';
import ModalWrapper from '../../../app/common/modals/ModalWrapper';
import YouTube from 'react-youtube';

export default function PayPaperMinusMoney() {

  const opts = {
    // height: '300%',
    width: '100%',
    playVars: {
      autoplay: 1
    }
  };

  return (
    <ModalWrapper size='large' header="AI 노무사 임금명세서 4대보험 과오납, 반제금, 두루누리, 선지급 공제 등록 설정">
      <YouTube videoId='svN2PiDe7sU' opts={opts} />
    </ModalWrapper>
  )
}
