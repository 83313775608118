import { financeEnterForYear } from './changeDateALF.js';
import moment from 'moment'

//오늘 현재 일자 계산 회계년도 기본년차
export function calALFinTodBagicYear(enterDate, finBaseDate, workersNumber) {
    const enterD = moment(financeEnterForYear(enterDate, finBaseDate), 'YYYY-MM-DD');
    const executeTodayDate = moment();
    const emplPeriodY = executeTodayDate.diff(enterD, 'years');
    return parseInt(workersNumber) < 5 ? 0 : emplPeriodY >= 1 ? 15 : 0;
}

//오늘 현재 일자 계산 회계년도 가산연차
export function calALFinTodPlusYear(enterDate, finBaseDate, workersNumber) {
    const enterD = moment(financeEnterForYear(enterDate, finBaseDate), 'YYYY-MM-DD');
    const executeTodayDate = moment();
    const emplPeriodY = executeTodayDate.diff(enterD, 'years');
    const sumPlus = emplPeriodY >= 3 ? Math.floor((emplPeriodY - 1) / 2) : 0;
    return parseInt(workersNumber) < 5 ? 0 : sumPlus >= 10 ? 10 : sumPlus;
}

// 오늘 현재 일자 계산 회계기준 비례연차 일수 구하기
export function calALFinTodRatioYear(enterDate, finBaseDate, workersNumber) {
    const realEntD = moment(enterDate, 'YYYY-MM-DD');
    const enterDFi = moment(financeEnterForYear(enterDate, finBaseDate), 'YYYY-MM-DD');
    const enterFiPlusOneYear = moment([enterDFi.get('year') + 1, enterDFi.get('month'), enterDFi.get('date')]);
    const executeTodayDate = moment();
    const ratioDayCount = enterDFi.diff(realEntD, 'days');
    const ratioDay = executeTodayDate.isBetween(enterDFi, enterFiPlusOneYear, undefined, '[]') ? Math.ceil((ratioDayCount / 365) * 15) : 0;    
    return parseInt(workersNumber) < 5 ? 0 : ratioDay;
}


