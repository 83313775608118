// import { format } from 'date-fns';
import React from 'react';
import { format } from 'date-fns/esm';
import { Button, Grid, Tab, Table } from 'semantic-ui-react'
import { printCmsContract } from '../../../app/common/util/util';
import { Link } from 'react-router-dom';

export default function YouthSFCeoConfirmContents({profile}) {
  let today = new Date(); 

  return (
    <Tab.Pane>
      <Grid>
        <Grid.Column width={16}>
          <>
            <Button.Group>
              <Button 
                color='yellow' 
                style={{display: "inline", color: 'black', margin: '5px'}}
                as={Link} to={`/clientReqs4In`} 
              >직원 List
              </Button>
              <Button 
                color='blue' 
                style={{display: "inline", color: 'black', margin: '5px',}}
                onClick={() => printCmsContract()}
              >청년채용특별장려금 사업주 확인서 출력
              </Button>
            </Button.Group>
            <div id='cmsContract'>
              <Table width ="100%" align = "center" border='1px solid black'>
                <Table.Body>
                  <Table.Row>
                    <Table.Cell align = "center"><h1 style={{textAlign : 'center', margin : '20px', fontSize : '30px'}}>{` 청년채용특별장려금 사업주 확인서 `}</h1></Table.Cell>
                  </Table.Row>
                </Table.Body>
              </Table>
              <table width ="100%" align = "center" border='1px solid black'>
                <Table.Body>
                  <Table.Row>
                    <Table.Cell>
                      □ 청년채용특별장려금은 중소·중견기업이 청년을 정규직으로 신규 채용하면 인건비를 지원하는 사업입니다.
                    </Table.Cell>
                  </Table.Row>
                  <Table.Row>
                    <Table.Cell>
                      □ 위 장려금 지원을 위해 아래 사항을 확인하고자 하오니, 해당 부분(예, 아니오)에 진하게 체크표시(√)해 주시기 바랍니다.
                    </Table.Cell>
                  </Table.Row>
                </Table.Body>
              </table>
              <br />
              <table  border='1px solid black' style={{width : "100%", height : "150", textAlign : "center"}}>
                <Table.Body>
                  <Table.Row>
                    <Table.Cell width ="16.6%" align='left'>□ 지원금 대상자 성명</Table.Cell>
                    <Table.Cell width ="16.6%"></Table.Cell>
                  </Table.Row>
                  <Table.Row>
                    <Table.Cell width ="16.6%" align='left'>1. 채용일 <br/> <span style={{fontSize:'7px'}}>※ 국민취업지원제도 일경험 프로그램(인턴형) 참여자만 전환일 기재</span></Table.Cell>
                    <Table.Cell width ="16.6%" align='left'>(채용일) 2021년 &nbsp;  월 &nbsp;  일 
                      <br/> (전환일) 2021년 &nbsp;  월 &nbsp;  일</Table.Cell>
                  </Table.Row>
                </Table.Body>
              </table>
              <br />
              <table  border='1px solid black' style={{width : "100%", height : "150", textAlign : "center"}}>
                <Table.Body>
                  <Table.Row>
                    <Table.Cell width ="84%" align='left'>□ 사업장 요건</Table.Cell>
                    <Table.Cell width ="8%">예</Table.Cell>
                    <Table.Cell width ="8%">아니오</Table.Cell>
                  </Table.Row>
                  <Table.Row>
                    <Table.Cell width ="84%" align='left'>
                      1. 청년 최초 신규 채용월의 전년도 연평균 피보험자 수((‘21년도 고용보험 신규 성립 사업장의 경우에는 보험관계 성립일이 속한 달의 말일 기준)보다 기업 전체 근로자 수가 증가하였습니다.                    
                    </Table.Cell>
                    <Table.Cell width ="8%"></Table.Cell>
                    <Table.Cell width ="8%"></Table.Cell>
                  </Table.Row>
                  <Table.Row>
                    <Table.Cell width ="84%" align='left'>
                      2. 우선지원기업 또는 중견기업에 해당됩니다.
                    </Table.Cell>
                    <Table.Cell width ="8%"></Table.Cell>
                    <Table.Cell width ="8%"></Table.Cell>
                  </Table.Row>
                  <Table.Row>
                    <Table.Cell width ="84%" align='left'>
                      3. 임금체불 중 또는 임금체불로 명단 공개중이 아닙니다.
                    </Table.Cell>
                    <Table.Cell width ="8%"></Table.Cell>
                    <Table.Cell width ="8%"></Table.Cell>
                  </Table.Row>
                  <Table.Row>
                    <Table.Cell width ="84%" align='left'>
                      4. 신청대상 근로자의 채용 전 1년 이내 관련 사업주가 아닙니다.<br/>
                      <span style={{fontSize: '7px'}}>※ 지침 p10 2-8 참조</span>
                    </Table.Cell>
                    <Table.Cell width ="8%"></Table.Cell>
                    <Table.Cell width ="8%"></Table.Cell>
                  </Table.Row>
                  <Table.Row>
                    <Table.Cell width ="84%" align='left'>
                      5. ｢산업안전보건법｣ 제10조에 따라 중대재해 발생 등으로 명단이 공표된 기업이 아닙니다. <br/>
                      <span style={{fontSize: '7px'}}>※ 지침 p10 2-5 참조</span>
                    </Table.Cell>
                    <Table.Cell width ="8%"></Table.Cell>
                    <Table.Cell width ="8%"></Table.Cell>
                  </Table.Row>
                  <Table.Row>
                    <Table.Cell width ="84%" align='left'>
                      7. 국가, 지자체 등으로부터 근로자 채용 등 조건으로 금전적 지원(인건비)을 받고 있지 않습니다. (청년내일채움공제는 중복지원 가능)<br/>
                      <span style={{fontSize: '7px'}}>※ 고용보험법시행령 제40조, 제40조의2 참조</span>
                    </Table.Cell>
                    <Table.Cell width ="8%"></Table.Cell>
                    <Table.Cell width ="8%"></Table.Cell>
                  </Table.Row>
                </Table.Body>
              </table>
              <br />
              <table  border='1px solid black' style={{width : "100%", height : "150", textAlign : "center"}}>
                <Table.Body>
                  <Table.Row>
                    <Table.Cell width ="84%" align='left'>□ 근로자 요건(반드시 개인별로 판단하여야 합니다)</Table.Cell>
                    <Table.Cell width ="8%">예</Table.Cell>
                    <Table.Cell width ="8%">아니오</Table.Cell>
                  </Table.Row>
                  <Table.Row>
                    <Table.Cell width ="84%" align='left'>
                      1. 신청대상 근로자는 기간의 정함이 없는 근로자입니다.
                    </Table.Cell>
                    <Table.Cell width ="8%"></Table.Cell>
                    <Table.Cell width ="8%"></Table.Cell>
                  </Table.Row>
                  <Table.Row>
                    <Table.Cell width ="84%" align='left'>
                      2. 신청대상 근로자는 주 소정근로시간 30시간 이상입니다.
                    </Table.Cell>
                    <Table.Cell width ="8%"></Table.Cell>
                    <Table.Cell width ="8%"></Table.Cell>
                  </Table.Row>
                  <Table.Row>
                    <Table.Cell width ="84%" align='left'>
                      3. 신청대상 근로자는 4대보험에 가입되어 있습니다.
                    </Table.Cell>
                    <Table.Cell width ="8%"></Table.Cell>
                    <Table.Cell width ="8%"></Table.Cell>
                  </Table.Row>
                  <Table.Row>
                    <Table.Cell width ="84%" align='left'>
                      4. 신청대상 근로자는 채용당시 재학생(고등학교 또는 대학 재학 중인 자)이 아닙니다.<br/>
                      <span style={{fontSize: '7px'}}>※ 채용시점에 최종학교 졸업예정자인 경우 졸업(수료)예정일: ( 년 월 일 ) * 확인자료 첨부 제출</span>
                    </Table.Cell>
                    <Table.Cell width ="8%"></Table.Cell>
                    <Table.Cell width ="8%"></Table.Cell>
                  </Table.Row>
                  <Table.Row>
                    <Table.Cell width ="84%" align='left'>
                      5. 신청대상 근로자는 사업주(법인의 경우 법인 대표이사)의 배우자, 사업주(법인의 경우 법인 대표이사)와 4촌이내의 혈족 및 인척에 해당하지 않습니다. 
                    </Table.Cell>
                    <Table.Cell width ="8%"></Table.Cell>
                    <Table.Cell width ="8%"></Table.Cell>
                  </Table.Row>
                  <Table.Row>
                    <Table.Cell width ="84%" align='left'>
                      7. 신청대상 근로자는 채용 당시 사업자등록증을 소지하고 있거나, 자영업 등을 영위하고 있는 사실이 없습니다.
                    </Table.Cell>
                    <Table.Cell width ="8%"></Table.Cell>
                    <Table.Cell width ="8%"></Table.Cell>
                  </Table.Row>
                  <Table.Row>
                    <Table.Cell width ="84%" align='left'>
                      8. 신청대상 근로자는 일학습병행제 참여자로 등록된 근로자가 아닙니다.
                    </Table.Cell>
                    <Table.Cell width ="8%"></Table.Cell>
                    <Table.Cell width ="8%"></Table.Cell>
                  </Table.Row>
                  <Table.Row>
                    <Table.Cell width ="84%" align='left'>
                      9. 신청대상 근로자는 외국인이 아닙니다.
                    </Table.Cell>
                    <Table.Cell width ="8%"></Table.Cell>
                    <Table.Cell width ="8%"></Table.Cell>
                  </Table.Row>
                  <Table.Row>
                    <Table.Cell width ="84%" align='left'>
                      10. 신청대상 근로자는 청년디지털 일자리사업, 청년일경험 지원사업에 참여한 적이 없습니다.
                    </Table.Cell>
                    <Table.Cell width ="8%"></Table.Cell>
                    <Table.Cell width ="8%"></Table.Cell>
                  </Table.Row>
                  <Table.Row>
                    <Table.Cell width ="84%" align='left'>
                      11. 신청대상 근로자는 국민취업지원제도 일경험 프로그램 인턴형에 참여한 적이 있습니다.
                    </Table.Cell>
                    <Table.Cell width ="8%"></Table.Cell>
                    <Table.Cell width ="8%"></Table.Cell>
                  </Table.Row>
                </Table.Body>
              </table>
              <br />
              <table  border='1px solid black' style={{width : "100%", height : "150", textAlign : "center"}}>
                <Table.Body>
                  <Table.Row>
                    <Table.Cell width ="84%" align='left'>□ 기타</Table.Cell>
                    <Table.Cell width ="8%">예</Table.Cell>
                    <Table.Cell width ="8%">아니오</Table.Cell>
                  </Table.Row>
                  <Table.Row>
                    <Table.Cell width ="84%" align='left'>
                      1. 사업주는 청년채용특별장려금 지원신청 시 동 지침을 명확히 숙지하고 있습니다.&nbsp;  &nbsp;  &nbsp;  &nbsp;  &nbsp;  &nbsp;  
                    </Table.Cell>
                    <Table.Cell width ="8%"></Table.Cell>
                    <Table.Cell width ="8%"></Table.Cell>
                  </Table.Row>
                  <Table.Row>
                    <Table.Cell width ="84%" align='left'>
                      2. 지원금 지급 신청시 제출한 자료는 모두 사실과 다름 없음을 확인합니다.
                    </Table.Cell>
                    <Table.Cell width ="8%"></Table.Cell>
                    <Table.Cell width ="8%"></Table.Cell>
                  </Table.Row>
                </Table.Body>
              </table>
              <br />
              <table border='1px solid black' style={{width : "100%", height : "150", textAlign : "left"}}>
                <Table.Body>
                  <Table.Row>
                    <Table.Cell>
                      위 내용을 충분히 이해하였으며, 허위 기타 부정한 방법으로 지원금을 지급받거나 지급받고자 한 자의 경우에는 
                      지원금을 지급하지 아니하며 <strong>이미 지원된 지원금의 반환조치 및 부정수급액의 최고 5배 추가징수, 1년의 범위 내에서 장려금 지급 제한, 형사처벌 될 수 있음을 확인</strong>합니다.
                    </Table.Cell>
                  </Table.Row>
                  <Table.Row>
                    <Table.Cell align = "center">{format(today, 'yyyy-MM-dd')}</Table.Cell>
                  </Table.Row>
                  <Table.Row>
                    <Table.Cell style={{width : "20%", textAlign : 'right'}}>
                      사업장명 : {profile?.companyName} (인) 또는 서명
                    </Table.Cell>
                  </Table.Row>
                  <Table.Row>
                    <Table.Cell style={{width : "20%", textAlign : 'right'}}>
                      확인자(대표자) : {profile?.ceoName} (인) 또는 서명
                    </Table.Cell>
                  </Table.Row>
                </Table.Body>
              </table>
            </div>
          </>
        </Grid.Column>
      </Grid>
    </Tab.Pane>
  );
};




// {/* <div>Member since: {format(profile?.createdAt, 'yyyy-MM-dd')}</div> */}
// <div style={{ marginBottom: 10 }}>
// <strong>
//   <br />
//   <div>대표자 성명 : {profile?.ceoName || null}</div>
//   <div>사업자등록번호 : {profile?.companyBizNumber || null}</div>
//   <div>업태 : {profile?.bizType || null}</div>
//   <div>종목 : {profile?.bizItem || null}</div>
// </strong>
// <br />
// <div>팩스번호 : {profile?.companyFax || null}</div>
// <div>주소 : {profile?.companyAddress || null}</div>
// <div>담당자 이름 : {profile?.staffName || null}</div>
// <div>담당자 이메일 : {profile?.staffEmail || null}</div>
// </div>