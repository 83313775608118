import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { Button, Grid, Input, Loader } from 'semantic-ui-react';
import { delay } from '../../../app/common/util/util';
import { clickConfirmedTimeOfLaborContract } from '../../../app/firestore/firestoreService4In';
import { clearPaydocu, fetchAllPaydocusFormFirestoreService4In } from '../companyActions';
import AnnualLeavesWorkerList from './AnnualLeavesWorkerList';
import PaydocuShortCut from '../paydocuDashboard/PaydocuShortCut';
import { clearClientReqs4In, fetchClientReqs4In } from '../../clientsRequest/clientReq4In/clientReqActions4In';

export default function AnnualLeavesWorkerDashboard() {
  const history = useHistory();
  const dispatch = useDispatch();
  const { paydocus } = useSelector(state => state.companyReducer);
  const { currentUserProfile} = useSelector(state => state.profile);
  const { loading } = useSelector(state => state.async);
  const { authenticated } = useSelector(state => state.auth);
  const { clientReqs4In } = useSelector((state) => state.clientReq4InStore);

  const [searchTerm, setSearchTerm] = useState("");
  const [searchResults, setSearchResults] = useState([]);
  const handleChange = event => {
    setSearchTerm(event.target.value);
  };

  const isWorker = currentUserProfile?.isWorker;
  const paydocuWorkerUid = currentUserProfile?.paydocuWorkerUid;

  const filter = isWorker ? 'isWorker' : 'isCompany';

  useEffect(() => {
    let isActive = true; // 컴포넌트 활성 상태 추적
    const fetchData = async () => {
      try {
        await dispatch(fetchAllPaydocusFormFirestoreService4In(filter, paydocuWorkerUid));
      } finally {
        if (isActive) {
          dispatch(clearPaydocu());}
      }
    };
    fetchData();
    return () => {
      isActive = false; // 컴포넌트가 언마운트될 때 상태 변경 방지
      dispatch(clearPaydocu());
    };
  }, [dispatch, filter, paydocuWorkerUid]);

  useEffect(() => {
    dispatch(fetchClientReqs4In(filter)).then(() => {
    });
    return () => {
      dispatch(clearClientReqs4In()); 
    };
  }, [dispatch, filter]);

  function reloadToAnnualLeavesWorker () {
    history.push('/annualLeavesWorker');
  }

  const laborContractId = currentUserProfile?.paydocuWorkerUid;

  function viewLaborContractHandlerWorker (laborContractId) {
    delay(1000)
    history.push(`/clientReqs4In/${laborContractId}`)
    clickConfirmedTimeOfLaborContract(laborContractId)
  }

  function goToPaydocuList () {
    history.push('/paydocus')
  }
  const titleOfTable = '연차 대장';

  return (
    <>
      <h1 style={{textAlign: 'center', margin: '50px'}}>{titleOfTable}</h1>
      {isWorker 
        ? 
          <>
            <Button style={{ color: 'blue', background: 'yellow' }} onClick={() => viewLaborContractHandlerWorker(laborContractId)}>
              근로계약서
            </Button>
            <Button onClick={() => goToPaydocuList()} color='teal'>임금명세서</Button>
            <br />
         
            {/* <Button onClick={()=>reloadToAnnualLeavesWorker() } color='teal'>새로고침</Button><span>* 리스트가 사라진 경우 <span style={{color: 'teal'}}>새로고침 버튼 클릭 후</span> 아래 "연차사용내역 불러오기" 클릭</span> */}
          </>
        :
          <>
            {isWorker ? null : <PaydocuShortCut filter={filter} clientReqs4In={clientReqs4In}/> }
            <Button onClick={()=>reloadToAnnualLeavesWorker() } color='yellow'>새로고침</Button><span>* 리스트가 사라진 경우 새로고침 클릭</span>
          </>
      }
      <>
        {isWorker ? null
          :
          <>
            년월, 직원성명, 작성일
              : <Input
                type="text"
                placeholder="Search"
                value={searchTerm}
                onChange={handleChange}
              />
              <br />
              <br />
          </>
        }
      </>
      <Grid>
        <Grid.Column width={16}>
          <>
            <hr />
            {authenticated &&
            <AnnualLeavesWorkerList 
              paydocus={paydocus}
              searchResults = {searchResults}
              setSearchResults = {setSearchResults}
              searchTerm = {searchTerm}
              title={titleOfTable}
              paydocuWorkerUid={paydocuWorkerUid}
            />}
          </>
        </Grid.Column>
        <Grid.Column width={16}>
          <Loader active={loading} />
        </Grid.Column>
      </Grid>
    </>
  );
};