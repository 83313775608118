import { Formik, Form } from 'formik';
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { Link, Redirect } from 'react-router-dom';
import { Button, Checkbox, Segment } from 'semantic-ui-react'
import * as Yup from 'yup';
import useFirestoreDoc from '../../../app/hooks/useFirestoreDoc';
import LoadingComponent from '../../../app/layout/LoadingComponent';
import { toast } from 'react-toastify';
import { clearSelectedRiskSuspectReport, listenToSelectedRiskSuspectReport } from '../eventActions';
import {
  addRiskSuspectReportsToFirestore,
  listenToRiskSuspectReportsFromFirestore,
  updateRiskSuspectReportsInFirestore
} from '../../../app/firestore/firestoreService4In';
import { weekNumberByMonth } from '../../../app/common/util/util'
import MyTextArea from '../../../app/common/form/MyTextArea';
// import MyNumberInput from '../../../app/common/form/MyNumberInput';

export default function RiskSuspectReportForm({ match, history, location }) {
  const dispatch = useDispatch();
  const { selectedRiskSuspectReport } = useSelector(state => state.eventStore)
  const { loading, error } = useSelector(state => state.async);
  const { currentUserProfile} = useSelector(state => state.profile);

  const companyId = selectedRiskSuspectReport?.companyId ? selectedRiskSuspectReport?.companyId : currentUserProfile?.companyId 
  const workerId = selectedRiskSuspectReport?.workerId ? selectedRiskSuspectReport?.workerId : currentUserProfile?.paydocuWorkerUid;
  const isWorker = currentUserProfile?.isWorker;

  const [openToCustomer, setOpenToCustomer] = useState(true);
  const [openToCoWorker, setOpenToCoWorker] = useState(true);

  const [openProposal, setOpenProposal] = useState(true);
  const [openHopeEducation, setOpenHopeEducation] = useState(true);

  const [openGrievance, setOpenGrievance] = useState(true);
  const [openCompliance, setOpenCompliance] = useState(true);


  useEffect(() => {
    if (location.pathname !== '/createRiskSuspectReport') return;
    dispatch(clearSelectedRiskSuspectReport());
  }, [location.pathname, dispatch])

  const initialValues = selectedRiskSuspectReport ?? {
    nomalTask : {
      context : '',
      reason : ''
    },
    nomalTaskFeedBack : '',
    riskSuspect : {
      name: '',
      context : '',
      reason : '',
    },
    riskSuspectFeedBack : '',

    proposalSafety: '',
    proposalSafetyFeedBack : '',

    hopeEducationSafety: '',
    hopeEducationSafetyFeedBack : '',

    grievanceSafety: '',
    grievanceSafetyFeedBack : '',

    complianceSafety: '',
    complianceSafetyFeedBack : '',

  };

  const validationSchema = Yup.object({
    // date: Yup.string().required('일시를 입력하세요.'),
    // billableHour: Yup.string().required('소요시간은 분단위로 숫자입력'),
    // customerType: Yup.string().required('고객유형을 선택하세요.'),
    // answerType: Yup.string().required('처리형식을 선택하세요.'),
    // counselingType: Yup.string().required('형식을 선택하세요.'),
    // region: Yup.string().required('지역을 입력하세요.'),
    // clientName: Yup.string().required('기관명은 필수입니다.'),
    // personName: Yup.string().required('요청자명은 필수입니다.'),
    // counselingCategory: Yup.string().required('상담 분야는 필수 입니다.'),
    // question: Yup.string().required('요청사항 또는 질문을 입력하세요.'),
    // workType: Yup.array().of(Yup.string()).required('업무/사건/상담'),
    // counselingBasis: Yup.string().required('답변 근거를 입력하세요.'),
    // answer: Yup.string().required('답변을 입력하세요.'),
  });

  useFirestoreDoc({
    query: () => match.params.id ? listenToRiskSuspectReportsFromFirestore(match.params.id) : null,
    data: report => dispatch(listenToSelectedRiskSuspectReport(report)),
    deps: [match.params.id, dispatch],
    shouldExcute: match.params.id !== selectedRiskSuspectReport?.id && location.pathname !== '/createRiskSuspectReport',
  })

  if (loading)
    return <LoadingComponent content='Loading risk suspect report ...' />;
  if (error) return <Redirect to='/riskSuspectReports' />;

  const getWeekJS = weekNumberByMonth(new Date());

  return (
    <>
      <Segment clearing>
        <Formik
          enableReinitialize
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={async (values, { setSubmitting }) => {
            try {
              selectedRiskSuspectReport
                ? await updateRiskSuspectReportsInFirestore(values)
                : await companyId ? addRiskSuspectReportsToFirestore(values, companyId, workerId) : alert('직원만 입력가능합니다.')
              setSubmitting(false);
              history.push('/riskSuspectReports');
            } catch (error) {
              console.log(error.message);
              toast.error(error.message);
              setSubmitting(false);
            }
          }}
        >
          {({ isSubmitting, dirty, isValid, values }) => (
            <Form className='ui form'>
              {/* <h1 style={{marginTop : '1em'}}>{getWeekJS.month} 월 {getWeekJS.weekNo} 주차 WEEKLY REPORT</h1> */}
              <h1 style={{ marginTop: '1em' }}>{getWeekJS.month}월 안전보건 현업 담당자 안전보건 활동 REPORT</h1>
              <p>본 안전보건 활동 기록은 본인 및 회사 동료 그리고 협력사 직원 등 모든 구성원의 안전과 보건 확보를 위한 것이므로 성실히 작성해 주시기 바랍니다. </p>
              <br />
              <p style={{ color: 'blue', fontSize: '1.3rem' }}>* 안전보건 활동 기록하기</p>
              <>
                <Checkbox 
                  label="1. 일상 업무 중 안전보건 관련 내용 기록하기"
                  onChange={() => setOpenToCustomer(!openToCustomer)} 
                  type='checkbox' 
                />
                {openToCustomer &&
                <>
                  <hr />
                  <div style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'flex-start', alignItems: 'center', alignContent: 'flex-end' }}>
                    <div className='margin' style={{ width: '99.5%', marginRight: '3px' }}>
                      <MyTextArea
                        disabled={!isWorker}
                        label='1-1. 귀하 및 귀하의 부서원들이 이달에 수행한 매일 또는 매주 등 정기적으로 수행한 일상 업무는?'
                        placeholder='해당 사항이 없는 경우 없음으로 적어주세요'
                        name='nomalTask.context'
                        rows={1}
                        autoComplete='off'
                      />
                    </div>

                    <div className='margin' style={{ width: '99.5%', marginRight: '3px' }}>
                      <MyTextArea
                        disabled={!isWorker}
                        label='1-2, 위 업무를 수행하면서 본인 또는 동료(회사직원 및 협력업체 직원을 포함)에게 사고가 발생되었거나 사고가 발생될 수도 있었던 아차했던 작업 및 그 원인은?'
                        placeholder='해당 사항이 없는 경우 없음으로 적어주세요'
                        name='nomalTask.achaAccident'
                        rows={1}
                        autoComplete='off'
                      />
                    </div>
                    <div className='margin' style={{ width: '99.5%', marginRight: '3px' }}>
                      <MyTextArea
                        disabled={!isWorker}
                        label='1-3, 사고가 발생되지는 않았지만 위 업무를 수행하면서 조금이라도 위험하다고 생각되거나 느낀 작업 및 그 이유는?'
                        placeholder='해당 사항이 없는 경우 없음으로 적어주세요'
                        name='nomalTask.riskThought'
                        rows={1}
                        autoComplete='off'
                      />
                    </div>
                    <div className='margin' style={{ width: '99.5%', marginRight: '3px' }}>
                      <MyTextArea
                        label='1-4, 위 일상업무 수행함에 있어 다음달에 기계, 기구, 설비, 원재료를 신규 도입해야 하거나 변경될 내용 또는 정비나 보수가 예정되었거나 작업방법 또는 작업절차가 변경될 내용은?'
                        placeholder='해당 사항이 없는 경우 없음으로 적어주세요'
                        name='nomalTask.changePlan'
                        rows={1}
                        autoComplete='off'
                        disabled={!isWorker}
                      />
                    </div>
                    {isWorker ? null :  
                      <div className='margin' style={{ width: '99.5%', marginRight: '3px' }}>
                        <MyTextArea
                          label={<p style={{color : 'teal'}}>안전보건 관리자 피드백 및 조치</p> }
                          name='nomalTaskFeedBack'
                          rows={1}
                          autoComplete='off'
                        />
                      </div>
                    }
                  </div>
                  </>
                }
                <br />
                <Checkbox 
                  label="2. 위험성 평가  기록하기"
                  onChange={() => setOpenToCoWorker(!openToCoWorker)} 
                  type='checkbox' 
                />
                {openToCoWorker && 
                  <>
                    <hr />
                    <div style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'flex-start', alignItems: 'center', alignContent: 'flex-end' }}>
                      <div className='margin' style={{ width: '49.5%', marginRight: '3px' }}>
                        <MyTextArea
                          label='2-1.위 1-4의 변경 작업 전까지 위험성 평가를 실시했는지 ?'
                          name='riskSuspect.planedChange'
                          placeholder='해당 사항이 없는 경우 없음으로 적어주세요'
                          rows={1}
                          disabled={!isWorker}
                          autoComplete='off'
                        />
                      </div>
                      <div className='margin' style={{ width: '49.5%', marginRight: '3px' }}>
                        <MyTextArea
                          label='2-2. 위 업무 수행에 사용한 기계 또는 설비, 도구의 이상 유무 및 이상시 조치사항은?'
                          name='riskSuspect.machine'
                          placeholder='해당 사항이 없는 경우 없음으로 적어주세요'
                          rows={1}
                          disabled={!isWorker}
                          autoComplete='off'
                        />
                      </div>
                      <div className='margin' style={{ width: '99.5%', marginRight: '3px' }}>
                        <MyTextArea
                          label='2-3. 위 업무를 수행하면서 느낀 작업 공간 위험 또는 위해 요인 및 조치사항은 ?'
                          placeholder='해당 사항이 없는 경우 없음으로 적어주세요'
                          name='riskSuspect.space'
                          rows={1}
                          disabled={!isWorker}
                          autoComplete='off'
                        />
                      </div>
                      <div className='margin' style={{ width: '99.5%', marginRight: '3px' }}>
                        <MyTextArea
                          label='2-4. 위 업무를 본인 및 부서원들이 작업하면서 신체에 무리를 주는 작업 및 자세 및 조치사항은?'
                          placeholder='해당 사항이 없는 경우 없음으로 적어주세요'
                          name='riskSuspect.person'
                          rows={1}
                          disabled={!isWorker}
                          autoComplete='off'
                        />
                      </div>
                      <div className='margin' style={{ width: '99.5%', marginRight: '3px' }}>
                        <MyTextArea
                          label='2-4. 이달에 본인 및 부서원들의 안전보건을 위해  안전보건 관리자 및 선임자에게 도움을 요청한 내용 및 그들의 조치는?'
                          placeholder='해당 사항이 없는 경우 없음으로 적어주세요'
                          name='riskSuspect.help'
                          rows={1}
                          disabled={!isWorker}
                          autoComplete='off'
                        />
                      </div>
                      <div className='margin' style={{ width: '99.5%', marginRight: '3px' }}>
                        <MyTextArea
                          label='2-5. 회사가 본인에게 1주일 이상 휴가를 부여한다면 별도의 인수인계없이 매뉴얼만으로 후임자가 업무가 가능한지?'
                          placeholder='해당 사항이 없는 경우 없음으로 적어주세요'
                          name='riskSuspect.manual'
                          rows={1}
                          disabled={!isWorker}
                          autoComplete='off'
                        />
                      </div>
                    </div>
                    <br />
                    <br />
                    <div style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'flex-start', alignItems: 'center', alignContent: 'flex-end' }}>
                      {isWorker ? null :  
                        <div className='margin' style={{ width: '99.5%', marginRight: '3px' }}>
                          <MyTextArea
                            label={<p style={{color : 'teal'}}>안전보건 관리자 피드백 및 조치</p> }
                            name='riskSuspectFeedBack'
                            rows={1}
                            autoComplete='off'
                          />
                        </div>
                      }
                    </div>
                  </>
                  }
                <br />

                <p style={{ color: 'blue', fontSize: '1.3rem' }}>* 안전보건 건의사항 등 기록하기</p>
                <Checkbox 
                  label="1. 안전보건 개선을 위한 제안사항(해결하고자 하는 문제점을 함께 기재요망)"
                  onChange={() => setOpenProposal(!openProposal)} 
                  type='checkbox' 
                />
                {openProposal && 
                  <>
                    <hr />
                    <div style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'flex-start', alignItems: 'center', alignContent: 'flex-end' }}>
                      <div className='margin' style={{ width: '99.5%', marginRight: '3px' }}>
                        <MyTextArea
                          label='이번달 안전보건 개선을 위한 제안사항'
                          placeholder='해당 사항이 없는 경우 없음으로 적어주세요'
                          name='proposalSafety'
                          disabled={!isWorker}
                          rows={2}
                        />
                      </div>
                      {isWorker ? null :  
                        <div className='margin' style={{ width: '99.5%', marginRight: '3px' }}>
                          <MyTextArea
                            label={<p style={{color : 'teal'}}>안전보건 제안사항에 대한 안전보건 관리자 피드백 및 조치</p> }
                            name='proposalSafetyFeedBack'
                            rows={1}
                            autoComplete='off'
                          />
                        </div>
                      }
                    </div>
                  </>
                }
                <br />
                <Checkbox 
                  label="2. 안전보건 개선을 위해 회사에 요청하는 교육?"
                  onChange={() => setOpenHopeEducation(!openHopeEducation)} 
                  type='checkbox' 
                />
                {openHopeEducation && 
                  <>
                    <hr />
                    <div style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'flex-start', alignItems: 'center', alignContent: 'flex-end' }}>
                      <div className='margin' style={{ width: '99.5%', marginRight: '3px' }}>
                        <MyTextArea
                          label='안전보건 개선을 위해 회사에 요청하는 교육은?'
                          placeholder='해당 사항이 없는 경우 없음으로 적어주세요'
                          name='hopeEducationSafety'
                          disabled={!isWorker}
                          rows={2}
                        />
                      </div>
                      {isWorker ? null :  
                        <div className='margin' style={{ width: '99.5%', marginRight: '3px' }}>
                          <MyTextArea
                            label={<p style={{color : 'teal'}}>안전보건 요청 교육에 대한 안전보건 관리자 피드백 및 조치</p> }
                            name='hopeEducationSafetyFeedBack'
                            rows={1}
                            autoComplete='off'
                          />
                        </div>
                      }
                    </div>
                  </>
                }
                <br />
                <Checkbox 
                  label="3. 안전보건 관련 본인의 고충사항"
                  onChange={() => setOpenGrievance(!openGrievance)} 
                  type='checkbox' 
                />
                {openGrievance && 
                  <>
                    <hr />
                    <div style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'flex-start', alignItems: 'center', alignContent: 'flex-end' }}>
                      <div className='margin' style={{ width: '99.5%', marginRight: '3px' }}>
                        <MyTextArea
                          label='안전보건 관련 고충사항 내용(When, Where, Who, What, How, Why 등 자세한 내용 기재요망) '
                          placeholder='해당 사항이 없는 경우 없음으로 적어주세요'
                          name='grievanceSafety'
                          disabled={!isWorker}
                          rows={2}
                        />
                      </div>
                    {isWorker ? null :  
                      <div className='margin' style={{ width: '99.5%', marginRight: '3px' }}>
                        <MyTextArea
                          label={<p style={{color : 'teal'}}>안전보건 고충사항에 대한 안전보건 관리자 피드백 및 조치</p> }
                          name='grievanceSafetyFeedBack'
                          rows={1}
                          autoComplete='off'
                        />
                      </div>
                    }
                    </div>
                  </>
                }
                <br />
                <Checkbox 
                  label="4. 안전보건관련 컴플라이언스(규정 및 법규) 보고 사항"
                  onChange={() => setOpenCompliance(!openCompliance)} 
                  type='checkbox' 
                />
                {openCompliance && 
                  <>
                    <hr />
                    <div style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'flex-start', alignItems: 'center', alignContent: 'flex-end' }}>
                      <div className='margin' style={{ width: '99.5%', marginRight: '3px' }}>
                        <MyTextArea
                          label='안전보건 관련 컴플라이언스(회사규칙이나 법률 위반사항) 보고 내용(When, Where, Who, What, How, Why 등 자세한 내용 기재요망) '
                          placeholder='해당 사항이 없는 경우 없음으로 적어주세요'
                          name='complianceSafety'
                          disabled={!isWorker}
                          rows={2}
                        />
                      </div>
                      {isWorker ? null :  
                        <div className='margin' style={{ width: '99.5%', marginRight: '3px' }}>
                          <MyTextArea
                            label={<p style={{color : 'teal'}}>안전보건 컴플라이언스 보고에 대한 안전보건 관리자 피드백 및 조치</p> }
                            name='complianceSafetyFeedBack'
                            rows={1}
                            autoComplete='off'
                          />
                        </div>
                      }
                    </div>
                  </>
                }
              </>
              <br />
              <br />
              <Button
                loading={isSubmitting}
                disabled={!isValid || !dirty || isSubmitting}
                type='submit'
                floated='right'
                positive
                content='Submit'
              />
              <Button
                disabled={isSubmitting}
                as={Link} to='/riskSuspectReports'
                type='submit'
                floated='right'
                content='Cancel'
              />
            </Form>
          )}
        </Formik>
      </Segment>
    </>
  )
}
