import React from 'react'

export default function ClientDash2024NewLaw({setOpenWhatsNew2024, openWhatsNew2024}) {
  return (
    <>
      <br />
      <div  onClick={() => setOpenWhatsNew2024(!openWhatsNew2024)} style={{cursor:'pointer'}}>
        <h3>시행시기 2024년 1월 1일 : 최저임금 9,860원(주휴 포함시 11,832원 - (주 40시간 기준))</h3>
        <p>* 업종에 상관없이 시급 9,860원, 일급(8시간 기준) 78,880원(주휴 포함시 94,656원 - (주 40시간 기준)), 월급(주40시간 기준) 2,060,740원, 년봉(주40시간 기준) 24,728,880원.</p>
        <p>* 식대등 복리후생비와 정기상여금도 모두 최저임금에 포함됨.</p>

        <h3>시행시기 2024년 1월 1일 : 4대보험 중 장기요양보험 요율 인상</h3>
        <p>* 4대보험 중 장기요양보험요율만 건강보험료액 대비 2023년 12.81%에서 2024년 12.95%로 인상</p>
        
        <h3>시행시기 2024년 1월 1일 : 2024년 변경 육아휴직 제도 </h3>
        <p>*  6 + 6 육아휴직 제도 생후 18개월 이내 자녀에 대해 부모 모두 육아휴직 사용 시 첫 "6개월"의 육아휴직급여는 통상임금의 100% 지원.</p>
        <p>* 육아휴직 급여는 통상임금의 80%가 상한액인데, 2023년도에는 상한액 내에서 150만원까지였으나, 2024년은 최저임금 수준 즉, 약 206만원으로 상향됨.</p>

        <h3>시행시기 2024년 1월 1일 : 2024년 변경 육아수당 비과세 한도 200,000원으로 상향</h3>
        <p>*  근로자 본인 또는 배우자의 출산, 6세 이하 자녀의 보육과 관련하여 사용자로부터 지급받는 급여가 2024년 1월 1일 과세분부터 월 200,000원으로 비과세 한도가 상향됨.</p>

        <h3>시행시기 2024년 1월 1일 : 고용보험료 중도입사자 익월 공제</h3>
        <p>* 고용보험료도 건강보험이나 국민연금처럼 입사일이 해당월 1일이 아닌 경우 다음달부터 보험료를 공제하도록 개정됨. 다만, 다음해 정산을 하기 때문에 해당월 보험료는 납부하는 구조임.</p>
        
        <h3>시행시기 2024년 1월 1일 : 산재보험 적용 대상 노무 제공자 범위 확대</h3>
        <p>* 공제 모집인, 방과후학교 교사 추가</p>
        
        <h3>시행시기 2024년 1월 1일 : 노동조합 회계 공시</h3>
        <p>* 노조 대표자의 매 회계년도 종료 후 2~3개월 내 결산 결과와 운영사항 공표 의무화(회계감사원 자격 요건을 재무회계 관련 경력 또는 전문지식 보유자, 일정 요건 충족시 조합원 아닌 공인회계사가 회계감사 가능).</p>

        <h3>시행시기 2024년 1월 27일 : 중대재해 처벌 등에 관한 법률</h3>
        <p>* 50인미만 사업 또는 사업장(건설업의 경우 공사금액 50억 미만 공사)에도 중대재해 처벌 등에 관한 법률이 적용됨.</p>

        <h3>시행시기 2024년 2월 9일 : 유족보상연금 수급자격 조정</h3>
        <p>* 손자녀 19세 미만에서 25세 미만으로 상향 조정</p>

        <h3>시행시기 미정 : 내국인 구인 노력 기간 단축 면제(입법절차 증, 공표한 날부터 시행)</h3>
        <p>* 제조업, 건설업, 서비스업 사업주의 내국인 구인 노력 7일로 단축, 외국인 근로자가 입국 6개월 이내 사용자 책임이 아닌 사유로 사업장을 변경하는 경우 내국인 구인 노력 면제</p>

        <h3>기타 수시로 변경되는 법령을 추가로 올리겠습니다.</h3>

        <br />
        <button style={{fontSize : '15px', color: 'black', height: '30px', width: '200px', background: 'white'}} onClick={() => setOpenWhatsNew2024(!openWhatsNew2024)}>내용 닫기</button>
        <hr />
      </div>
    </>
  )
}
