import React from 'react'
import { FormField, Label } from 'semantic-ui-react';
import { useField } from 'formik'

export default function MyTimeInput({label, ...props}) {
  const [field, meta] = useField(props);

  return (
    <FormField error={meta.touched && !!meta.error}>
      <label>{label}</label>
      <input 
        type='time'
        timeformat='hh:mm'
        {...field} 
        value={field.value ?? ''} // field.value가 undefined일 경우, 빈 문자열을 사용
        {...props} 
      />
      {meta.touched && meta.error ? (
        <Label basic color='red'>{meta.error}</Label>
      ) : null}
    </FormField>
  )
}
