import React, { Fragment, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
// import { Link } from 'react-router-dom';
import { useTable, useSortBy, useGlobalFilter, useFilters, usePagination } from 'react-table';
import { Button, Table } from 'semantic-ui-react';
import { 
  clearEventsTable, 
  deleteEvent, 
  fectchEventsTable } from '../eventActions';
import ColumnFilter from './ColumnFilter';
import { COLUMNS } from './eventColumns';
import GlobalFilter from './GlobalFilter';
import './table.css';
import {deleteEventInFirestore} from '../../../app/firestore/firestoreService'
import { asyncActionError } from '../../../app/async/asyncReducer';
import * as XLSX from 'xlsx';
// import EventDetailedChat from '../eventDetailed/EventDetailedChat';
import { CLEAR_COMMENTS } from '../eventConstants';

export default function EventsTable() {

  const dispatch = useDispatch();
  const { eventsTable } = useSelector(state => state.eventStore);
  const { currentUser } = useSelector(state => state.auth);
  const [trOpen, setTrOpen] = useState(false)

  useEffect(() => {
    dispatch(fectchEventsTable())
    return () => {
      dispatch(clearEventsTable());
      dispatch({type: CLEAR_COMMENTS});
    };
  }, [dispatch]);

  const columns = useMemo(() => COLUMNS, []);
  const data = useMemo(() => eventsTable, [eventsTable]);
  const defaultColumn = useMemo(() => {
    return {
      Filter: ColumnFilter
    }
  }, []);

  const exportToExcel = () => {
    const table = document.getElementById('table-to-xls1');
    const workbook = XLSX.utils.table_to_book(table, { sheet: '업무List' });
    XLSX.writeFile(workbook, 'excelFilegood.xlsx');
  };

  const useInstance = useTable({
    columns: columns,
    data: data,
    defaultColumn: defaultColumn,
    initialState: { pageIndex: 0 }
  },
    useFilters,
    useGlobalFilter,
    useSortBy,
    usePagination,
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    setPageSize,
    gotoPage,
    pageCount,
    nextPage,
    previousPage,
    canNextPage,
    canPreviousPage,
    pageOptions,
    prepareRow,
    state,
    setGlobalFilter,
  } = useInstance;

  const { globalFilter, pageIndex, pageSize } = state;


  return (
    <>
      <GlobalFilter filter={globalFilter} setFilter={setGlobalFilter} />
      <div>
        <span>
          Page{'  '}
          <strong>
            {pageIndex + 1} of {pageOptions.length}
          </strong>{'  '}
        </span>
        <span>
          | Go to page : {' '}
          <input
            type='number'
            defaultValue={pageIndex + 1}
            onChange={(e) => {
              const pageNumber = e.target.value ? Number(e.target.value) - 1 : 0
              gotoPage(pageNumber)
            }}
            style={{ width: '50px' }}
          />
        </span>
        <div>
          <select
            value={pageSize}
            onChange={e => setPageSize(Number(e.target.value))}
          >
            {
              [ 10, 25, 50 ].map((pageSize) => (
                <option key={pageSize} value={pageSize}>
                  Show {pageSize}
                </option>
              ))
            }
          </select>
          <Button onClick={exportToExcel} color='green'>
            엑셀로 추출하기
          </Button>
          <Button onClick={() => gotoPage(0)} disabled={!canPreviousPage}>{'<<'}</Button>
          <Button onClick={() => previousPage()} disabled={!canPreviousPage}>Previous</Button>
          <Button onClick={() => nextPage()} disabled={!canNextPage}>Next</Button>
          <Button onClick={() => gotoPage(pageCount - 1)} disabled={!canNextPage}>{'>>'}</Button>
        </div>

      </div>

      <Table {...getTableProps()} id='table-to-xls1'>
        <Table.Header>
          {
            headerGroups.map(headerGroup => (
              <Table.Row {...headerGroup.getHeaderGroupProps()}>
                {
                  headerGroup.headers.map(column => (
                    <Table.HeaderCell {...column.getHeaderProps(column.getSortByToggleProps())}>
                      {column.render('Header')}
                      <div>
                        {column.canFilter ? column.render('Filter') : null}
                      </div>
                    </Table.HeaderCell>
                  ))
                }
              </Table.Row>
            ))
          }
        </Table.Header>
        <Table.Body onClick={() => setTrOpen(!trOpen)} {...getTableBodyProps()}>
          {
            page.map(row => {
              prepareRow(row)
              return (
                <Fragment key={row.id}>
                  {/* <Table.Row  {...row.getRowProps()} onClick={() => goUrl(`/manage/${row.original.id}`)}> */}
                  <Table.Row  {...row.getRowProps()}>

                    {
                      row.cells.map(cell => {
                        return <Table.Cell  {...cell.getCellProps()}>{cell.render('Cell')}</Table.Cell>
                      })
                    }
                  </Table.Row>
                  <Table.Row>
                    <Table.Cell colSpan={columns.length}>
                      질문 : {row.original.question}
                    </Table.Cell>
                  </Table.Row>
                  <Table.Row>
                    <Table.Cell colSpan={columns.length}>
                      답변 : {row.original.answer}
                    </Table.Cell>
                  </Table.Row>
                  {trOpen &&
                  <Table.Row>
                    <Table.Cell colSpan={columns.length}>
                    {currentUser.companyName === "김경모" &&
                      <Button 
                        onClick={async () => {
                          try {
                            await deleteEventInFirestore(row.original.id);
                            await dispatch(deleteEvent(row.original.id));
                          } catch (error) {
                            dispatch(asyncActionError());
                          }
                        } } 
                        color='red'
                        floated='right' 
                        content='Delete'
                      />}
                    {currentUser.companyName === "김경모" &&
                      <Button 
                        as={Link} to={`/events/${row.original.id}`}
                        color='teal'
                        floated='right' 
                        content='의견나누기'
                      />
                    }
                    <Button as={Link} to={`/manage/${row.original.id}`} color='orange' floated='right'>
                      수정하기
                    </Button>
                    </Table.Cell>
                  </Table.Row>}
                  <Table.Row>
                    <Table.Cell colSpan={columns.length}>
                        {/* {row.original?.government && 
                          `담당행정기관 : ${row.original.government  || null}`  `담당자 : ${row.original.governmentPerson || null}` `연락처 : ${row.original.governmentPhone || null}`
                        } */}
                    </Table.Cell>
                  </Table.Row>
                </Fragment>
              )
            })
          }
        </Table.Body>
      </Table>

    </>
  )
}
