import React from 'react';
import { personalEvaluationUseViewType } from '../../../../../app/api/dropdownOption';
import MySelectInput from '../../../../../app/common/form/MySelectInput';
import { useHistory } from 'react-router-dom';
import { Button } from 'semantic-ui-react';
import { useSelector } from 'react-redux';

export default function WorkerFormStep1Hr({
	MyTextInput,
	setPerEvalOrNot,
	perEvalOrNot,
	personalEvaluation,
	authLevel,
	values,
	currentUserProfile,
}) {
	const history = useHistory(); // useHistory 훅 사용
	const { clientInfo } = useSelector((state) => state.companyReducer);

	const companyId = clientInfo?.clientUid
		? clientInfo?.clientUid
		: clientInfo?.id
		? clientInfo?.id
		: currentUserProfile.id;

	const handleGoToCompanyInfo = () => {
		// 여기서는 예시로 '/manageCompany/:id' 경로를 사용합니다.
		// 실제 사용 시 ':id'를 해당 회사의 실제 ID로 교체해야 합니다.
		history.push(`/manageCompany/${companyId}`); // 또는 새 회사를 위한 '/createCompany'
	};

	const personalEvaluationUseTypeIndiUseOption = [
		{
			key: 'use',
			text: `${values?.worker?.name} 직원 BSC 기반 인사고과 기능 사용`,
			value: `${values?.worker?.name} 직원 BSC 기반 인사고과 기능 사용`,
		},
		{
			key: 'noUse',
			text: `${values?.worker?.name} 직원 BSC 기반 인사고과 기능 사용 안함`,
			value: `${values?.worker?.name} 직원 BSC 기반 인사고과 기능 사용 안함`,
		},
	];

	return (
		<div
			style={{
				display: 'flex',
				flexWrap: 'wrap',
				justifyContent: 'flex-start',
				alignItems: 'center',
				alignContent: 'flex-end',
			}}>
			<>
				{personalEvaluation && authLevel > 30 ? (
					<>
						<div
							className='margin'
							style={{ width: '33%', marginRight: '3px' }}>
							<MySelectInput
								onChangeCallback={setPerEvalOrNot}
								label='BSC 기반 인사고과'
								name='workerPersonalEvaluation'
								placeholder='모든 직원 고과안하는 경우는 회사정보에서 수정'
								options={personalEvaluationUseTypeIndiUseOption}
								autoComplete='off'
							/>
						</div>
						{perEvalOrNot ===
						`${values.worker.name} 직원 BSC 기반 인사고과 기능 사용 안함` ? (
							<div
								className='margin'
								style={{ width: '66%', marginRight: '3px' }}>
								<MyTextInput
									label='[주의] 취업규칙 등에 근거 없이 특정 직원만 고과하거나 안할 경우 직원 전체 고과가 부인될 수 있음(구체적 근거 명기)'
									name='workerPersonalEvaluationNoReason'
									placeholder='임원 등 취업규칙상 고과 대상자 아님.'
									autoComplete='off'
								/>
							</div>
						) : (
							<div
								className='margin'
								style={{ width: '33%', marginRight: '3px' }}>
								<MySelectInput
									label='임금명세서에 역량모델링 내역 표시'
									name='workerPersonalEvaluationView'
									placeholder='모든 직원 고과안하는 경우는 회사정보에서 수정'
									options={personalEvaluationUseViewType}
									autoComplete='off'
								/>
							</div>
						)}
					</>
				) : (
					<div
						style={{
							width: '100%',
							marginBottom: '10px',
							marginTop: '5px',
							fontSize: '13px',
						}}>
						<span style={{ color: 'teal' }}>
							회사정보에서 "BSC기반 인사고과 기능 사용" 선택 후 사용하세요.{' '}
						</span>
						<br />
						<br />
						<Button color='green' onClick={handleGoToCompanyInfo}>
							회사정보수정 바로 가기
						</Button>
					</div>
				)}
			</>
		</div>
	);
}
