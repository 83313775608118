import React from 'react'
import { Table } from 'semantic-ui-react'

export default function ClientReqDLCWageTableDetailMonthlyBonus({
  weekLBTime,
  gumakStyle,
  cOWMonthlyBonus,
  cOWMonthlyBonusComment,
  isExceptTimeLawWorker
}) {

  return (
    <>
      <Table.Cell className='gubun'>통상임금 {(weekLBTime < 15 || isExceptTimeLawWorker) ? null : <> <br /> (주휴포함)</>}</Table.Cell>
      <Table.Cell className='hangmok'>월정기상여금</Table.Cell>
      <Table.Cell className='gumak' style={gumakStyle}>{cOWMonthlyBonus}</Table.Cell>
      <Table.Cell textAlign='left' colSpan='6' className='sulmung'>{ cOWMonthlyBonusComment }</Table.Cell>
    </>
  )
}
