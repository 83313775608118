import { format } from 'date-fns';
import React from 'react';
import { getAgeFromSocialNumber, josaUnType, josaWaType, netAge15To18F, workerStatus } from '../../../../../app/common/util/util';
import { decrypto } from '../../../../../app/firestore/firestoreService';

export default function ClientReqDLCHeadWageCon({selectedClientReq4In, companyName, worker, weekLBTime, isExceptTimeLawWorker, isSkipWeekedWorker, contractOldWorker}) {
  
    
  //계약종료일
  const cEDate = selectedClientReq4In?.contractEndDate ? format(selectedClientReq4In.contractEndDate, 'yyyy-MM-dd'): null;

  const workerSocialNumberFront = decrypto(selectedClientReq4In?.workerSocialNumberFront,  String(process.env.CRYPTO_KEY))
  const netAge = getAgeFromSocialNumber(workerSocialNumberFront);
  const netAge15To18 = netAge15To18F(netAge)
  //회사(와/과)

  const companyWa = josaWaType(companyName);
  const companyUn = josaUnType(companyName);

  //직원(와/과)
  const workerUn = josaUnType(worker);

  return (
    <>
      <table width ="100%" align = "center">
        <tbody>
          <tr>{companyName === '세무회계 정연' ? 
            <td align = "center"><h1>정규직 임금계약서</h1></td>
          :
            <td align = "center"><h1>{` ${workerStatus(cEDate, weekLBTime, contractOldWorker, isExceptTimeLawWorker, netAge15To18)} 임금계약서 `}</h1>{isSkipWeekedWorker ? '(2주단위 탄력적 근로시간제)' : ''}</td>
          }</tr>
        </tbody>
      </table>
      <hr />
      {companyName === '세무회계 정연' ? 
        <div className="jau__contents">
          {`
            ${companyWa} 
            ${workerUn}
            근로기준법 제17조 및 관련 기타 노동법에 따라
            양 당사자간 서명 날인한 근로계약서 제7조(임금 및 퇴직금) 조항에 의거 아래의 임금계약을 체결하고 성실하게 이행할 것을 합의하며 ${companyUn} ${worker}에게
            본 임금 계약서를 서면(「전자문서 및 전자거래 기본법」 제2조제1호에 따른 전자문서를 포함한다.)으로 교부하되, 두 당사자의 서명날인이 모두 있어야 근로계약의 효력이 발생된다.
          `}
        </div>
      :
      <>
        {isExceptTimeLawWorker 
          ? 
            <div className="jau__contents">
              {`
                ${companyWa} 
                ${workerUn}
                ${(cEDate || weekLBTime < 40 || contractOldWorker) ? '근로기준법 및 관련 기타 노동관계 법령에 따라 ' : '근로기준법 제17조에 따라 '}  
                양 당사자간 서명 날인한 근로계약서 제7조(임금 및 퇴직금) 조항에 의거 아래의 임금계약(근기법 제63조 적용 대상자로 주휴, 연장, 휴일관련 임금 제외)을 체결하고 성실하게 이행할 것을 합의하며 ${companyUn} ${worker}에게
                본 임금 계약서를 서면(「전자문서 및 전자거래 기본법」 제2조제1호에 따른 전자문서를 포함한다.)으로 교부하되, 두 당사자의 서명날인이 모두 있어야 근로계약의 효력이 발생된다.
              `}
            </div>
          : 
            <div className="jau__contents">
              {`
                ${companyWa} 
                ${workerUn}
                ${(cEDate | weekLBTime < 40 || contractOldWorker) ? '근로기준법 및 관련 기타 노동관계 법령에 따라 ' : '근로기준법 제17조에 따라 '}  
                양 당사자간 서명 날인한 근로계약서 제7조(임금 및 퇴직금) 조항에 의거 아래의 임금계약을 체결하고 성실하게 이행할 것을 합의하며 ${companyUn} ${worker}에게
                본 임금 계약서를 서면(「전자문서 및 전자거래 기본법」 제2조제1호에 따른 전자문서를 포함한다.)으로 교부하되, 두 당사자의 서명날인이 모두 있어야 근로계약의 효력이 발생된다.
              `}
            </div>
        }
      </>
      }
    </>
  )
}
