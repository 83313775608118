import { CLEAR_SELECTED_PAYMENTS_INFO, LISTEN_SELECTED_TO_PAYMENTS_INFO, SIGN_IN_USER, SIGN_OUT_USER } from "./authConstants";
import { onAuthStateChanged } from "firebase/auth";
import {  onSnapshot } from "firebase/firestore";
import { APP_LOADED } from "../../app/async/asyncReducer";
import { dataFromSnapshotIdDate, getUserProfile } from "../../app/firestore/firestoreService";
import { listenToCurrentUserProfile } from "../profiles/profileActions";
import { auth } from "../../app/config/firebase";  // Assuming db is your Firestore instance

export function signInUser(user) {
  return {
    type: SIGN_IN_USER,
    payload: user
  }
}
export function verifyAuth() {
  return function (dispatch) {
    return onAuthStateChanged(auth, user => {
      if (user) {
        dispatch(signInUser(user));
        const profileDocRef = getUserProfile(user.uid);
        onSnapshot(profileDocRef, (snapshot) => {
          if (snapshot.exists()) {
            const userProfile = dataFromSnapshotIdDate(snapshot);
            dispatch(listenToCurrentUserProfile(userProfile));
            dispatch({type: APP_LOADED})
          }
        });
      } else {
        dispatch(signOutUser());
        dispatch({type: APP_LOADED})
      }
    })
  }
}


export function signOutUser(payload) {
  return {
    type: SIGN_OUT_USER,
    payload
  }
}

export function clearSelectedPaymentsInfo() {
  return {
    type: CLEAR_SELECTED_PAYMENTS_INFO,
  }
}

// 변경전 ListenToEvents
export function listenToSelectedPaymentsInfo(paymentsInfo) {
  return {
    type: LISTEN_SELECTED_TO_PAYMENTS_INFO,
    payload: paymentsInfo
  }
}
