import React from 'react';
import ModalWrapper from '../../../app/common/modals/ModalWrapper';
import YouTube from 'react-youtube';

export default function MaternityLeave() {

  const opts = {
    // height: '300%',
    width: '100%',
    playVars: {
      autoplay: 1
    }
  };

  return (
    <ModalWrapper size='large' header="AI 노무사 출산휴가 등록">
      <YouTube videoId='e5GN5GzUJMw' opts={opts} />
    </ModalWrapper>
  )
}
