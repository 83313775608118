import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
	dataFromSnapshotIdDate,
	getWorkerUserProfile,
} from '../../../app/firestore/firestoreService4In';
import { listenToWorkerUsers } from '../../profiles/profileActions';
import {
	clearPaydocu,
	fetchPaydocusFormFirestoreService4In,
} from '../companyActions';
import PaydocuCopy from './PaydocuCopy';
import PaydocuShortCut from './PaydocuShortCut';
import {
	clearClientReqs4In,
	fetchClientReqs4In,
} from '../../clientsRequest/clientReq4In/clientReqActions4In';

export default function PaydocuCopyDashboard() {
	const dispatch = useDispatch();
	const { filter, yearMonth, clientInfo } = useSelector(
		(state) => state.companyReducer
	);
	const { currentUserProfile } = useSelector((state) => state.profile);
	const { clientReqs4In } = useSelector((state) => state.clientReq4InStore);

	const paydocuWorkerUid = currentUserProfile?.paydocuWorkerUid;
	const isWorker = currentUserProfile?.isWorker;

	const companyId = clientInfo?.clientUid
		? clientInfo?.clientUid
		: clientInfo?.id
		? clientInfo?.id
		: currentUserProfile?.id;

	useEffect(() => {
		const unSubscribe = getWorkerUserProfile({
			next: (snapshot) =>
				dispatch(
					listenToWorkerUsers(
						snapshot.docs.map((docSnapshot) =>
							dataFromSnapshotIdDate(docSnapshot)
						)
					)
				),
			error: (error) => console.log(error),
		});
		return () => {
			unSubscribe();
		};
	}, [dispatch]);

	useEffect(() => {
		dispatch(
			fetchPaydocusFormFirestoreService4In(
				filter,
				yearMonth,
				paydocuWorkerUid,
				clientInfo
			)
		).then(() => {});
		return () => {
			dispatch(clearPaydocu());
		};
	}, [dispatch, filter, yearMonth, paydocuWorkerUid, clientInfo]);

	useEffect(() => {
		dispatch(fetchClientReqs4In(filter)).then(() => {});
		return () => {
			dispatch(clearClientReqs4In());
		};
	}, [dispatch, filter]);

	return (
		<>
			{isWorker ? null : (
				<>
					<PaydocuShortCut filter={filter} clientReqs4In={clientReqs4In} />
					<br />
					<PaydocuCopy companyId={companyId} />
				</>
			)}
		</>
	);
}
