import { formatDistance } from 'date-fns';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { Comment, Header, Segment } from 'semantic-ui-react';
import { createDataTree } from '../../../app/common/util/util';
import { firebaseObjectToArray, getEventChatRef } from '../../../app/firestore/firebaseService';
import { listenToEventChat } from '../eventActions';
import { CLEAR_COMMENTS } from '../eventConstants';
import EventDetailedChatForm from './EventDetailedChatForm';

export default function EventDetailedChat({eventId}) {

const dispatch = useDispatch();
const {comments} = useSelector(state => state.eventStore);
const {authenticated} = useSelector(state => state.auth)
const [showReplayForm, setShowReplayForm] = useState({open: false, commentId: null});

function handleCloseReplyForm() {
  setShowReplayForm({open: false, commentId: null});
}

useEffect(() => {
  getEventChatRef(eventId).on('value', snapshot => {
    if(!snapshot.exists()) return;
    dispatch(listenToEventChat(firebaseObjectToArray(snapshot.val()).reverse()));
  });
  return () => {
    dispatch({type: CLEAR_COMMENTS});
    getEventChatRef().off();
  }
}, [eventId, dispatch])

return (
  <>
    <Segment
        textAlign="center"
        attached="top"
        inverted
        color="teal"
        style={{border: 'none'}}
    >
        <Header>{ authenticated ? 'Chat about this event' : 'Sign in to view and comment'}</Header>
    </Segment>
    { authenticated &&
    <Segment attached>
    <EventDetailedChatForm eventId={eventId} parentId={0} closeForm={setShowReplayForm} />
        <Comment.Group>
            {createDataTree(comments).map(comment => (
                <Comment key={comment.id}>
                    <Comment.Avatar src={comment.photoURL || '/assets/user.png'} />
                    <Comment.Content>
                      <Comment.Author as={Link} to={`/profile/${comment.uid}`}>{comment.displayName}</Comment.Author>
                        <Comment.Metadata>
                          <div>{formatDistance(comment.date, new Date())}</div>
                        </Comment.Metadata>
                        <Comment.Text>{comment.text.split('\n').map((text, i) => (
                          <span key={i}>
                            {text}
                            <br />
                          </span>
                        ))}</Comment.Text>
                        <Comment.Actions>
                            <Comment.Action onClick={() => setShowReplayForm({open: true, commentId: comment.id})}>Reply</Comment.Action>
                            {showReplayForm.open && showReplayForm.commentId === comment.id && (
                              <EventDetailedChatForm 
                                eventId={eventId} 
                                parentId={comment.id}
                                closeForm={handleCloseReplyForm}
                              />
                            )}
                        </Comment.Actions>
                            </Comment.Content>
                            {comment.childNodes.length > 0 && (
                              <Comment.Group>
                                {comment.childNodes.reverse().map(child => (
                                  <Comment key={child.id}>
                                    <Comment.Avatar src={child.photoURL || '/assets/user.png'} />
                            <Comment.Content>
                              <Comment.Author as={Link} to={`/profile/${child.uid}`}>{child.displayName}</Comment.Author>
                                <Comment.Metadata>
                                  <div>{formatDistance(child.date, new Date())}</div>
                                </Comment.Metadata>
                                <Comment.Text>{child.text.split('\n').map((text, i) => (
                                  <span key={i}>
                                    {text}
                                    <br />
                                  </span>
                                ))}</Comment.Text>
                                <Comment.Actions>
                                    <Comment.Action 
                                      onClick={() => 
                                        setShowReplayForm({
                                          open: true, 
                                          commentId: child.id
                                        })
                                      }
                                    >
                                      Reply
                                    </Comment.Action>
                                    {showReplayForm.open && 
                                      showReplayForm.commentId === child.id && (
                                      <EventDetailedChatForm 
                                        eventId={eventId} 
                                        parentId={child.parentId}
                                        closeForm={handleCloseReplyForm}
                                      />
                                    )}
                                </Comment.Actions>
                            </Comment.Content>
                          </Comment>
                        ))}
                      </Comment.Group>
                    )}

                </Comment>
            ))
          }
        </Comment.Group>
    </Segment> }
  </>
  )
}
