import React, { useState } from 'react';
import { altWorkerOption, workerYouthTypeOption } from '../../../../../app/api/dropdownOption';

import { getAgeFromSocialNumber, netAge15To34F } from '../../../../../app/common/util/util';
import MyDateInput from '../../../../../app/common/form/MyDateInput';
import moment from 'moment';
import { Button } from 'semantic-ui-react';
import { Link } from 'react-router-dom';
import MySelectInput from '../../../../../app/common/form/MySelectInput';

export default function CrwfWorkerBasicInfoSupportFund({
    setWorkerYouth,
    workerYouthYes,
    values,
    setAltWorker,
    altWorker,
    authLevel
  }) {
    const [upsale, setUpsale] = useState(false);

    const netAge = getAgeFromSocialNumber(values?.workerSocialNumberFront, values?.workerSocialNumberBack)
    const netAge15To34 = netAge15To34F(netAge)
    
    const altWorkerYes = altWorker === '1. 출산전후휴가 대체인력(지원금 대상)' || altWorker === '2. 육아기 근로시간단축 대체인력(지원금 대상자)' ? true : false
    const originalWorkerLeaveStartDateM = values?.originalWorkerLeaveStartDate ? moment(values?.originalWorkerLeaveStartDate) : ''
    const originalWorkerLeaveEndDateM = values?.originalWorkerLeaveEndDate ? moment(values?.originalWorkerLeaveEndDate) : ''
    const over30daysYes = (originalWorkerLeaveStartDateM && originalWorkerLeaveEndDateM) 
      ? (originalWorkerLeaveEndDateM.diff(originalWorkerLeaveStartDateM, "days") >= 30 ? true : false) 
      : false

  return (
    <>
      {authLevel > 30 ?
        <>
          <div 
            style={{display: 'flex', alignItems : 'center', alignContent : 'flex-end', width : '100%', marginBottom : "30px", marginTop : "10px", fontSize: '13px'}}>
             <span style={{color : 'red', fontSize : '1.2rem', position: "absolute", right: 0, marginRight:'10px'}}>* 기존 직원 수정 등록한 경우 반드시 지원금 해당여부를 체크하세요.</span>
          </div>
            <>
              <div style={{ display : 'flex', flexWrap : 'wrap', justifyContent: 'flex-start', alignItems : 'center', alignContent : 'flex-end'}}>
                {netAge15To34 
                  ? 
                    <div className='margin' style={{width : '99.5%', marginRight : '3px'}}>
                      <MySelectInput
                        onChangeCallback={setWorkerYouth} 
                        label={<span style={{color: 'teal'}}>* 청년일자리도약장려금 대상자 여부(만 34세 미만-군필자의 경우 복무기간에 따라 39세까지)</span>}                
                        name='workerYouthType' 
                        placeholder='2024년 청년일자리도약장려금 대상자일 수 있습니다.' 
                        options={workerYouthTypeOption} 
                      />
                    </div>
                  :
                    <h5>* 만 34세 미만자 입사자일 경우 <span style={{color : 'teal'}}> 자동으로 청년일자리도약장려금 관련 설정 기능이 활성화</span>됩니다.</h5>
                }
              </div>
              <br />
              <div style={{ display : 'flex', flexWrap : 'wrap', justifyContent: 'flex-start', alignItems : 'center', alignContent : 'flex-end'}}>

                <div className='margin' style={{width : '32.5%'}}>
                  <MySelectInput 
                    label={<span style={{color: 'teal'}}>* '직원이 출산휴가, 육아기근로시간단축 등의 대체 인력인지 여부'</span>}                
                    onChangeCallback={setAltWorker} 
                    placeholder='대체인력 지원금 판단' 
                    name ='altWorker'
                    options={altWorkerOption}
                    />
                </div>
                {
                  altWorkerYes ?
                    <>
                      <div className='margin' style={{width : '33%'}}>
                        <MyDateInput
                          label='기존인력 출산전후휴가 또는 육아기 근로시간 단축 시작일' 
                          name='originalWorkerLeaveStartDate' 
                          placeholderText='달력 사용( 또는 0000-00-00  형식으로 입력요망)'
                          timeFormat='HH:mm'
                          timeCaption='time'
                          dateFormat='yyyy-MM-dd'
                          autoComplete='off'
                          />
                      </div>
                      <div className='margin' style={{width : '33%'}}>
                        <MyDateInput
                          label='기존인력 출산전후휴가 또는 육아기 근로시간 단축 종료(예정)일' 
                          name='originalWorkerLeaveEndDate' 
                          placeholderText='달력 사용( 또는 0000-00-00  형식으로 입력요망)'
                          timeFormat='HH:mm'
                          timeCaption='time'
                          dateFormat='yyyy-MM-dd'
                          autoComplete='off'
                          />
                      </div>
                    </>
                  : null
                }
              </div>
            </>
          {workerYouthYes
            ?
              <p>* <span style={{fontSize:'1.1rem'}}>위 직원은 2024년 청년일자리 도약 장려금 대상자일 수 있습니다. </span></p>
            : 
              null
          }
          {((altWorker === '1. 출산전후휴가 대체인력(지원금 대상)' || altWorker === '2. 육아기 근로시간단축 대체인력(지원금 대상자)') && over30daysYes)
            ? 
              <p>* <span style={{fontSize:'1.1rem'}}>위 직원은 2024년 기준 육아관련 대체인력 지원금 대상자일 수 있습니다. </span></p>  
            : 
              null
          }
          {((workerYouthYes || altWorker === '1. 출산전후휴가 대체인력(지원금 대상)' || altWorker === '2. 육아기 근로시간단축 대체인력(지원금 대상자)') && over30daysYes) ?
            <p style={{color:'red'}}>* 실제 지원금 수령은 직원 최저임금, 회사요건, 감원방지기간 등 추가 검토할 내용이 많습니다. 고용안정센터 또는 자문노무사에게 반드시 검토를 받고 신청하시기 권장드립니다</p>
          :
            null
          }
        </>
      :
        <>
          <div onClick={() => setUpsale(!upsale)}  
            style={{display: 'flex', alignItems : 'center', alignContent : 'flex-end', width : '100%', marginBottom : "30px", marginTop : "30px", fontSize: '13px'}}>
            <span style={{color : 'teal', cursor:'pointer'}}>Step 1-1. 신규채용 고용안정 지원금 검토</span>
          </div>
          {upsale &&
            <>
              <h3>* 고용안정지원금 대상자 진단 기능은 상위 서비스인 <span style={{color : 'teal'}}>PREMIUM</span>에서 제공되는 서비스입니다.</h3>
              <Button as={Link} to={`/pricePolicyPaper`} color='facebook'>서비스 종류 보러가기.</Button>
            </>
          }
        </>
      }
    </>
  )
}