import React from 'react'
import { Table } from 'semantic-ui-react'

export default function InsuranceListItem4In({
  viewLevel,
  decryptoClientReq,
  Item,
  currentUserProfile,
  numberToCommaString,
  InsureInReportButton,
  clientReq4In,
  workerId,
  formatDateWithMoment,
  InsureOutReportButton,
}) {
  return (
    <>
      {(viewLevel >= 10 && (decryptoClientReq?.pensionIn || decryptoClientReq?.healthIn)) &&
            <>
              <Item.Description>
                <strong>4대보험 취득 신고 요청 정보</strong>
              </Item.Description>
              <Item.Description>
                <Table>
                  <Table.Header>
                    <Table.Row>
                      <Table.HeaderCell>보수총액(비과세제외)</Table.HeaderCell>
                      <Table.HeaderCell>연금취득부호</Table.HeaderCell>
                      <Table.HeaderCell>건강취득부호</Table.HeaderCell>
                      <Table.HeaderCell>일자리안정</Table.HeaderCell>
                      <Table.HeaderCell>연금특수직종부호</Table.HeaderCell>
                      <Table.HeaderCell>건보감면부호</Table.HeaderCell>
                      <Table.HeaderCell>외국인국적</Table.HeaderCell>
                      <Table.HeaderCell>외국인체류자격</Table.HeaderCell>
                      {(currentUserProfile?.agentType === 'bs노무사' || currentUserProfile?.agentType === 'ps노무사') &&
                        <Table.HeaderCell>노무사 취득신고</Table.HeaderCell>
                      }
                      <Table.HeaderCell>취득신고 <br /> 시간</Table.HeaderCell>
                    </Table.Row>
                  </Table.Header>
                  <Table.Body>
                    <Table.Row>
                      <Table.Cell>{numberToCommaString(decryptoClientReq?.calWorkWage?.taxWageSum)}</Table.Cell>
                      <Table.Cell>{decryptoClientReq.pensionIn ? decryptoClientReq?.pensionIn : null}</Table.Cell>
                      <Table.Cell>{decryptoClientReq.healthIn ? decryptoClientReq?.healthIn : null}</Table.Cell>
                      <Table.Cell>{decryptoClientReq.workerSupportFund ? decryptoClientReq?.workerSupportFund : null}</Table.Cell>
                      <Table.Cell>{decryptoClientReq.pensionIn4PublicJob  ? decryptoClientReq?.pensionIn4PublicJob : ''}</Table.Cell>
                      <Table.Cell>{decryptoClientReq.healthIn4Discount ? decryptoClientReq?.healthIn4Discount : ''}</Table.Cell>
                      <Table.Cell>{decryptoClientReq.foreigner?.nationality ? decryptoClientReq?.foreigner?.nationality : ''}</Table.Cell>
                      <Table.Cell>{decryptoClientReq.foreigner?.visaType ? decryptoClientReq?.foreigner?.visaType : ''}</Table.Cell>
                      {(currentUserProfile?.agentType === 'bs노무사' || currentUserProfile?.agentType === 'ps노무사') &&
                        <Table.Cell><InsureInReportButton clientReq4In={clientReq4In} workerId={workerId}/></Table.Cell>
                      }
                      <Table.Cell>{decryptoClientReq?.insureInReportTime ? formatDateWithMoment(decryptoClientReq?.insureInReportTime) : null}</Table.Cell>
                    </Table.Row>
                  </Table.Body>
                </Table>
              </Item.Description>
              <Item.Description>
                피부양자 : 
                {decryptoClientReq?.healthCarePeople?.familyRelation ? ` ${decryptoClientReq?.healthCarePeople?.familyRelation}` : ''}
                {decryptoClientReq?.healthCarePeople?.familyName ? ` , ${decryptoClientReq?.healthCarePeople?.familyName}` : ''}
                {decryptoClientReq?.familySocialNumber ? ` , ${decryptoClientReq?.familySocialNumber}` : ''}
                {decryptoClientReq?.healthCarePeople?.handyCapFamily ? ` , ${decryptoClientReq?.healthCarePeople?.handyCapFamily}` : ''}
                {decryptoClientReq?.healthCarePeople?.ForeignerFamily ? ` , ${decryptoClientReq?.healthCarePeople?.ForeignerFamily}` : ''}
              </Item.Description>
              <Item.Description>
                피부양자 : 
                {decryptoClientReq?.healthCarePeopleComments ? ` ${decryptoClientReq?.healthCarePeopleComments}` : ''}
              </Item.Description>
            </>
          }
          {(viewLevel >= 10 && decryptoClientReq?.retiredDate) &&
            <>
              <hr />
              <Item.Description>
                <strong>4대보험 상실 신고 요청 정보</strong>
              </Item.Description>
              <Item.Description>
                <Table>
                  <Table.Header>
                    <Table.Row>
                      <Table.HeaderCell>상실일</Table.HeaderCell>
                      <Table.HeaderCell>고용코드</Table.HeaderCell>
                      <Table.HeaderCell>연금코드</Table.HeaderCell>
                      <Table.HeaderCell>건보코드</Table.HeaderCell>
                      <Table.HeaderCell>보수총액</Table.HeaderCell>
                      <Table.HeaderCell>근무월수</Table.HeaderCell>
                      <Table.HeaderCell>전년보수</Table.HeaderCell>
                      <Table.HeaderCell>전년월수</Table.HeaderCell>
                      {(currentUserProfile?.agentType === 'bs노무사' || currentUserProfile?.agentType === 'ps노무사') &&
                        <Table.HeaderCell>노무사 상실신고</Table.HeaderCell>
                      }
                      <Table.HeaderCell>상실신고 <br /> 시간</Table.HeaderCell>
                    </Table.Row>
                  </Table.Header>
                  <Table.Body>
                    <Table.Row>
                      <Table.Cell>{decryptoClientReq?.retiredDate ? formatDateWithMoment(decryptoClientReq?.retiredDate) : ''}</Table.Cell>
                      <Table.Cell>{decryptoClientReq.eiOutType ? decryptoClientReq.eiOutType : null}</Table.Cell>
                      <Table.Cell>{decryptoClientReq.pensionOutType ? decryptoClientReq.pensionOutType : null}</Table.Cell>
                      <Table.Cell>{decryptoClientReq.healthOutType ? decryptoClientReq.healthOutType : null}</Table.Cell>
                      <Table.Cell>{decryptoClientReq.healthOut?.taxWageThisYear  ? decryptoClientReq?.healthOut?.taxWageThisYear : ''}</Table.Cell>
                      <Table.Cell>{decryptoClientReq.healthOut?.workedMonthThisYear ? decryptoClientReq?.healthOut?.workedMonthThisYear : ''}</Table.Cell>
                      <Table.Cell>{decryptoClientReq.healthOut?.taxWageLastYear ? decryptoClientReq?.healthOut?.taxWageLastYear : ''}</Table.Cell>
                      <Table.Cell>{decryptoClientReq.healthOut?.workedMonthLastYear ? decryptoClientReq?.healthOut?.workedMonthLastYear : ''}</Table.Cell>
                      <Table.Cell><InsureOutReportButton clientReq4In={clientReq4In} workerId={workerId}/></Table.Cell>
                      {(currentUserProfile?.agentType === 'bs노무사' || currentUserProfile?.agentType === 'ps노무사') &&
                        <Table.Cell>{decryptoClientReq?.insureOutReportTime ? formatDateWithMoment(decryptoClientReq?.insureOutReportTime) : null}</Table.Cell>
                      }
                    </Table.Row>                          
                  </Table.Body>
                </Table>
              </Item.Description>
            </>
          }
          <hr />
    </>
  )
}
