// import { format } from 'date-fns';
import React from 'react';
import { format } from 'date-fns/esm';
import * as moment from 'moment';
import { Button, Table } from 'semantic-ui-react';
import {
	calculatePackagePrice,
	calculateTotalPrice,
	getSystemPrice,
	numberToCommaString,
	printCmsContract,
} from '../../../app/common/util/util';
import { useSelector } from 'react-redux';
import PaydocuShortCut from '../paydocuDashboard/PaydocuShortCut';

export default function CmsProgramContractContents() {
	const { currentUserProfile } = useSelector((state) => state.profile);
	const { clientReqs4In } = useSelector((state) => state.clientReq4InStore);

	const { clientInfo, filter } = useSelector((state) => state.companyReducer);

	let today = new Date();
	//자동이체일자
	const isWorker = currentUserProfile?.isWorker;
	const companyInfo = clientInfo.length > 0 ? clientInfo : currentUserProfile;
	const numOfWorkers = companyInfo?.numOfWorkers;
	const deleyPayDay1 = moment().add(4, 'days').get('date');
	const deleyPayDay2 = moment().add(9, 'days').get('date');
	const companyName = companyInfo?.companyName;
	const companyBizNumber = companyInfo?.companyBizNumber;
	const companyPhone = companyInfo?.companyPhone;
	const companyBankAccountOwner = companyInfo?.companyBankAccountOwner;
	const cellPhone = companyInfo?.cellPhone;

	const nomuService = companyInfo?.nomuService || [];
	const nomuPackageService = companyInfo?.nomuPackageService || [];

	const companyRegion = companyInfo?.companyRegion;
	const payDay = companyInfo?.payDay;
	const companyBankName = companyInfo?.companyBankName;
	const companyBankAccount = companyInfo?.companyBankAccount;
	const ceoBirthNumber = companyInfo?.ceoBirthNumber;

	const inup =
		companyRegion === '서울'
			? '노무법인 최상인업'
			: '노무법인 최상인업 세종지사';
	const inupBizNumber =
		companyRegion === '서울' ? '220-86-77219' : '413-85-02763';
	const inupAddress =
		companyRegion === '서울'
			? '서울특별시 강남구 선릉로 93길 54, 6층'
			: '세종특별자치시 갈매로 351 4115호';

	//패키지 서비스는 단일선택이므로 하나의 값
	const calculatePackagePriceV = calculatePackagePrice(
		numOfWorkers,
		nomuPackageService
	);
	//개별적 노무서비스 중복선택 가능하여 합계로 계산
	const calculateTotalPriceV = calculateTotalPrice(numOfWorkers, nomuService);
	//세팅비
	const getSystemPriceV = getSystemPrice(numOfWorkers);
	//시스템 사용료
	const minPrice =
		calculatePackagePriceV[0] !== 0 && calculateTotalPriceV[0] !== 0
			? Math.min(calculatePackagePriceV[0], calculateTotalPriceV[0])
			: calculatePackagePriceV[0] || calculateTotalPriceV[0];

	const totalPrice = minPrice + getSystemPriceV;

	const discountRate = companyInfo?.discountRate;
	const discountRateNum = discountRate ? parseFloat(discountRate) / 100 : 0; // "5%" -> 0.05 변환
	const monthDiscount = totalPrice * 1.1 * discountRateNum;
	const discountedPrice = totalPrice * 1.1 - monthDiscount;

	return (
		<>
			{isWorker ? null : (
				<PaydocuShortCut filter={filter} clientReqs4In={clientReqs4In} />
			)}

			<Button
				color='blue'
				style={{ display: 'inline', color: 'black', margin: '5px' }}
				onClick={() => printCmsContract()}>
				전산이용 CMS 자동이체 신청서 출력
			</Button>
			<div id='cmsContract'>
				<Table className='title' width='100%' align='center'>
					<Table.Body>
						<Table.Row>
							<Table.Cell align='center'>
								<h1
									style={{
										textAlign: 'center',
										margin: '20px',
										fontSize: '30px',
									}}>{` 효성 CMS 자동이체 신청서`}</h1>
							</Table.Cell>
						</Table.Row>
					</Table.Body>
				</Table>
				<div style={{ textAlign: 'right' }}>
					금융기관 및 결제대행사(효성에프엠에스(주)) 제출용
				</div>
				<h3>[수납업체 및 목적]</h3>
				<Table
					border='1px solid black'
					style={{ width: '100%', height: '150', textAlign: 'center' }}>
					<Table.Body>
						<Table.Row>
							<Table.Cell width={3}>수납업체</Table.Cell>
							<Table.Cell width={3}>{inup}</Table.Cell>
							<Table.Cell width={3}>수납목적</Table.Cell>
							<Table.Cell width={3}>
								AI노무사 시스템 및 서비스 구독료 납부
							</Table.Cell>
						</Table.Row>
						<Table.Row>
							<Table.Cell width={3}>대표자</Table.Cell>
							<Table.Cell width={3}>김경모</Table.Cell>
							<Table.Cell width={3}>사업자등록번호</Table.Cell>
							<Table.Cell width={3}>{inupBizNumber}</Table.Cell>
						</Table.Row>
						<Table.Row>
							<Table.Cell width={3}>주소</Table.Cell>
							<Table.Cell colSpan={3} width={3}>
								{inupAddress}
							</Table.Cell>
						</Table.Row>
					</Table.Body>
				</Table>
				<h3>[자동이체 신청내용]</h3>
				<h5>[신청정보]</h5>
				<Table
					border='1px solid black'
					style={{ width: '100%', height: '150', textAlign: 'center' }}>
					<Table.Body>
						<Table.Row>
							<Table.Cell width={3}>신청인</Table.Cell>
							<Table.Cell width={3}>{companyName}</Table.Cell>
							<Table.Cell width={3}>연락처</Table.Cell>
							<Table.Cell width={3}>{companyPhone}</Table.Cell>
						</Table.Row>
						<Table.Row>
							<Table.Cell width={3}>
								{` AI NOMUSA`}
								<br />
								(회사기능) <br />
								납부금액
							</Table.Cell>
							<Table.Cell width={3}>
								<strong>고정금액</strong>
								<br />
								{`월 ${numberToCommaString(discountedPrice)}원(부가세포함)`}
								<br />
							</Table.Cell>
							<Table.Cell width={3}>납부일</Table.Cell>
							<Table.Cell width={3}>
								매월 {payDay}일 <br />
								미납시 {deleyPayDay1}일, {deleyPayDay2}일 자동출금
							</Table.Cell>
						</Table.Row>
					</Table.Body>
				</Table>
				<h5>[금융거래정보]</h5>
				<Table
					border='1px solid black'
					style={{ width: '100%', height: '150', textAlign: 'center' }}>
					<Table.Body>
						<Table.Row>
							<Table.Cell width={3}>은행명</Table.Cell>
							<Table.Cell width={3}>{companyBankName}</Table.Cell>
							<Table.Cell width={3}>예금주</Table.Cell>
							<Table.Cell width={3}>{companyBankAccountOwner}</Table.Cell>
							<Table.Cell width={3}>휴대전화</Table.Cell>
							<Table.Cell width={3}>{cellPhone}</Table.Cell>
						</Table.Row>
						<Table.Row>
							<Table.Cell width={3}>계좌번호</Table.Cell>
							<Table.Cell colSpan={2} width={3}>
								{companyBankAccount}
							</Table.Cell>
							{ceoBirthNumber ? (
								<>
									<Table.Cell width={3}>대표자 생년월일</Table.Cell>
									<Table.Cell colSpan={2} width={3}>
										{ceoBirthNumber}
									</Table.Cell>
								</>
							) : (
								<>
									<Table.Cell width={3}>사업자 등록번호</Table.Cell>
									<Table.Cell colSpan={2} width={3}>
										{companyBizNumber}
									</Table.Cell>
								</>
							)}
						</Table.Row>
					</Table.Body>
				</Table>
				<h5>[개인정보활용동의]</h5>
				<Table
					border='1px solid black'
					style={{ width: '100%', height: '150', textAlign: 'center' }}>
					<Table.Body>
						<Table.Row>
							<Table.Cell rowSpan='5' style={{ width: '60px' }}>
								개인정보 수집 및 이용동의
							</Table.Cell>
							<Table.Cell className='body' style={{ textAlign: 'left' }}>
								수집 및 이용목적 : 효성CMS 자동이체를 통한 요금 수납
							</Table.Cell>
						</Table.Row>
						<Table.Row>
							<Table.Cell className='body' style={{ textAlign: 'left' }}>
								수집항목 : 성명, 생년월일, 연락처, 은행명, 예금주명, 계좌번호,
								예금주 휴대전화번호
							</Table.Cell>
						</Table.Row>
						<Table.Row>
							<Table.Cell className='body' style={{ textAlign: 'left' }}>
								보유 및 이용기간 : 수집/이용 동의일부터 자동이체
								종료일(해지일)까지
							</Table.Cell>
						</Table.Row>
						<Table.Row>
							<Table.Cell className='body' style={{ textAlign: 'left' }}>
								신청자는 개인정보의 수집 및 이용을 거부할 수 있습니다. 단, 거부
								시 자동이체 신청이 처리되지 않습니다.
							</Table.Cell>
						</Table.Row>
						<Table.Row colSpan={2}>
							<Table.Cell align='center'>
								( □ 동의함, □ 동의하지 않음 )
							</Table.Cell>
						</Table.Row>
					</Table.Body>
				</Table>
				<br />
				<Table
					border='1px solid black'
					style={{ width: '100%', height: '150', textAlign: 'center' }}>
					<Table.Body>
						<Table.Row>
							<Table.Cell rowSpan='6' style={{ width: '60px' }}>
								개인정보 제3자 제공동의
							</Table.Cell>
							<Table.Cell className='body' style={{ textAlign: 'left' }}>
								개인정보를 제공받는 자: 효성에프엠에스㈜, 금융기관(하단
								신청가능은행 참조), 통신사(SKT, KT LGU+, CJ헬로비전)등, 자세한
								내용은 홈페이지 게시 <br />
								(www.efnc.co.kr / 제휴사 소개 메뉴 내)
							</Table.Cell>
						</Table.Row>
						<Table.Row>
							<Table.Cell className='body' style={{ textAlign: 'left' }}>
								개인정보를 제공받는 자의 이용 목적: 자동이체서비스 제공 및
								자동이체 동의 사실 통지
							</Table.Cell>
						</Table.Row>
						<Table.Row>
							<Table.Cell className='body' style={{ textAlign: 'left' }}>
								제공하는 개인정보의 항목: 성명, 생년월일, 연락처, 은행명,
								예금주명, 계좌번호, 예금주 휴대전화번호
							</Table.Cell>
						</Table.Row>
						<Table.Row>
							<Table.Cell className='body' style={{ textAlign: 'left' }}>
								개인정보를 제공받는자의 개인정보 보유 및 이용기간: 동의일부터
								자동이체의 종료일(해지일)까지. 단, 관계 법령에 의거 일정기간
								동안 보관
							</Table.Cell>
						</Table.Row>
						<Table.Row>
							<Table.Cell className='body' style={{ textAlign: 'left' }}>
								신청자는 개인정보에 대해 수납업체가 제 3자에 제공하는 것을
								거부할 수 있습니다. 단, 거부 시 자동이체 신청이 처리되지
								않습니다
							</Table.Cell>
						</Table.Row>
						<Table.Row colSpan={2}>
							<Table.Cell align='center'>
								( □ 동의함, □ 동의하지 않음 )
							</Table.Cell>
						</Table.Row>
					</Table.Body>
				</Table>
				<Table width='100%' align='center'>
					<Table.Body>
						<Table.Row>
							<Table.Cell className='body'>
								# 자동이체 동의여부 통지 안내 : 효성에프엠에스㈜ 및 금융기관은
								안전한 서비스의 제공을 위하여 예금주 휴대전화번호로 자동이체
								동의 사실을 SMS(또는 LMS)로 통지합니다.
							</Table.Cell>
						</Table.Row>
					</Table.Body>
				</Table>
				<br />
				<Table width='100%' align='center'>
					<Table.Body>
						<Table.Row>
							<Table.Cell align='center'>
								{format(today, 'yyyy-MM-dd')}
							</Table.Cell>
						</Table.Row>
					</Table.Body>
				</Table>
				<h3>[위임인]</h3>
				<Table
					border='1px solid black'
					style={{ width: '100%', height: '150', textAlign: 'center' }}>
					<Table.Body>
						<Table.Row>
							<Table.Cell className='body'>
								신청인(예금주)은 신청정보, 금융거래정보 등 개인정보의 수집·이용
								및 제 3자 제공에 동의하며 상기와 같이 효성CMS 자동이체를
								신청합니다.
							</Table.Cell>
						</Table.Row>
						<Table.Row>
							<Table.Cell
								className='stamp'
								style={{ width: '20%', textAlign: 'right' }}>
								신청인 : {companyName} (인) 또는 서명
							</Table.Cell>
						</Table.Row>
						<Table.Row>
							<Table.Cell
								className='stamp'
								style={{ width: '20%', textAlign: 'right' }}>
								<span style={{ fontSize: '7px' }}>
									(신청인과 예금주가 다른 경우)
								</span>
								예금주 : {companyBankAccountOwner} (인) 또는 서명
							</Table.Cell>
						</Table.Row>
					</Table.Body>
				</Table>
				<div>
					1. 신청인과 예금주가 다른 경우 반드시 예금주의 별도 서명을 받아야
					합니다.
				</div>
				<div>
					2. 인감 또는 서명은 출금통장의 사용인감 또는 서명을 사용해야 합니다.
				</div>
				<div>
					3. 기존 신청내용을 변경하고자 하는 경우에는 자동이체신청서를 신규로
					작성하셔야 합니다.
				</div>
				<div>
					4. 신청가능은행 : 국민, 우리, 신한, 농협, 하나, SC, 기업, 외환, 씨티,
					산업, 새마을, 부산, 대구, 경남, 광주, 전북, 제주, 수협, 신협, 우체국,
					동양증권, 삼성증권
				</div>
			</div>
		</>
	);
}
