import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Message from './Message';
import { listenToConversations } from '../../eventActions';
import LoadingSpinner from '../LoadingSpinner';

function Messages() {
  const dispatch = useDispatch();
  const scrollRef = useRef();
  const { selectedConversationId, conversations } = useSelector(state => state.eventStore);
  const { currentUserProfile } = useSelector(state => state.profile);
  const [messages, setMessages] = useState([]);

  useEffect(() => {
    dispatch(listenToConversations(currentUserProfile.id));
  }, [dispatch, currentUserProfile]);

  useEffect(() => {
    if (selectedConversationId === "new" || !conversations[selectedConversationId]) {
      // 대화가 없거나 "new"일 때 메시지 목록을 비움
      setMessages([]);
    } else {
      const conversation = conversations[selectedConversationId];
      if (conversation) {
        const newMessages = Object.entries(conversation).map(([messageId, messageData]) => ({
          ...messageData,
          messageId,
        }));
        setMessages(newMessages);
      }
    }
  }, [selectedConversationId, conversations]);
  
  
  useEffect(() => {
    if (messages.length > 0) {
      scrollRef.current?.scrollIntoView({ behavior: 'smooth' });
    }
  }, [messages]);

  return (
    <div className='chat_messages_container'>
      {messages.map((message, index) => (
        message.aiMessage === undefined ? 
        <LoadingSpinner key={`loading-${message.messageId}`} /> :
        <Message 
          key={message.messageId}
          userMessage={message.userMessage} 
          aiMessage={message.aiMessage}
          isLastMessage={index === messages.length - 1} // 마지막 메시지인지 확인
          ref={index === messages.length - 1 ? scrollRef : null}
        />
      ))}
    </div>
  );
}

export default Messages;
