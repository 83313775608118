import { Form, Formik } from 'formik';
import React, { useState } from 'react';
import ModalWrapper from '../../app/common/modals/ModalWrapper';
import { Button, Label } from 'semantic-ui-react';
import { useDispatch, useSelector } from 'react-redux';
import { closeModal } from '../../app/common/modals/modalReducer';
import { priceType } from '../../app/api/dropdownOption';
import { monthPriceF, numberToCommaString } from '../../app/common/util/util';
// import { addPaymentsInfoToFirestore } from '../../app/firestore/firestoreService4In';
import * as Yup from 'yup';
import { addPaymentsInfoToFirestore } from '../../app/firestore/firestoreService4In';
import MySelectInput from '../../app/common/form/MySelectInput';
// import MyTextInput from '../../app/common/form/MyTextInput';
// import { Button, Divider, Label } from 'semantic-ui-react';
// import MySelectInputCardOwner from '../../app/common/form/MySelectInputCardOwner';
// import { cardOwnerType, priceType } from '../../app/api/dropdownOption';
// import { loadTossPayments } from '@tosspayments/sdk';


export default function PriceForm() {
  const dispatch = useDispatch();

  const [price, setPrice] = useState('');
  const {currentUserProfile} = useSelector(state => state.profile);
  const profile = currentUserProfile;
  const numOfWorkers = currentUserProfile?.numOfWorkers;
  // const authLevel = currentUserProfile?.authLevel;

  function companyNameForTitle () {
    return profile.companyName + ' 신용카드 결제 테스트'
  }

  return (
    <ModalWrapper size='small' header={companyNameForTitle()}>
      <Formik
        initialValues={{
          companyId: profile.id,
          price: profile?.price ||'',
          // payTerm: profile?.payTerm || '',
          companyName: profile?.companyName || '',
          numOfWorkers: profile?.numOfWorkers,
          cardPayDate: new Date(),
          }}
        validationSchema={Yup.object({
          price: Yup.string().required(),
        })}
        onSubmit={ async (values, {setSubmitting, setErrors}) => {
          try {

            await addPaymentsInfoToFirestore(values);
            // await requestPayments(values);
            //토스 결제창 함수 util에 있음.
            setSubmitting(false);
            dispatch(closeModal());

          } catch (error) {
            setErrors({auth: '카드 결제 페이지 호출 실패'})
            setSubmitting(false);
          }
        }}
      >
        {({isSubmitting, isValid, dirty, errors}) => (
          <Form className='ui form'>
            <MySelectInput
              // value = {price ? price : '기본형'} //선택하지 않으면 validation에 걸려서 안씀
              onChangeCallback={setPrice} 
              label='이용가격(매월 정기결제)'
              name='price' 
              placeholder='Basic, Premium, Customized 선택' 
              options={priceType} 
            />
            {/* <MyNumberInputPayTerm 
              value = {payTerm ? payTerm : "1"}
              type='Number'
              setPayTerm={setPayTerm}
              label='이용개월'
              name='payTerm' 
              min="1"
            /> */}
            <hr />
            <h5>정상 결제 금액</h5>
            {/* <Label>{payTerm} 개월간 총 결제금액 : {numberToCommaString(monthPriceF(price, numOfWorkers))}원(부가세 포함)</Label>
            <br /> */}
      
            <Label>{price} 매월 결제금액 : {numberToCommaString(monthPriceF(price, numOfWorkers))}원(부가세 포함)</Label>
            {/* {payTerm > 12 &&
              <>
                <h5>장기 할인 결제  금액 (할인률({roundUp(customerDCRate(payTerm))} % ))</h5>
                <Label>장기 할일 총 결제금액({payTerm}개월 : {numberToCommaString(monthPriceF(price, numOfWorkers)*payTermDCRate(payTerm)*payTerm)}원(부가세 포함)</Label>
                <br />
                <Label>매월 할인 결제금액 : {numberToCommaString(monthPriceF(price, numOfWorkers)*payTermDCRate(payTerm))}원(부가세 포함)</Label>
                <hr />
              </>
            } */}
            <br />
            <br />

          {errors.auth && <Label basic color='red' style={{marginBottom: 10}} content={errors.auth} />}
            <Button 
              loading={isSubmitting}
              disabled={!isValid || !dirty || isSubmitting}
              type='submit'
              fluid
              size='large'
              content='결제하기'
              // onClick={()=> dispatch(openModal({modalType: 'PaymentsSuccess'}))}
            />
          </Form>
        )}
      </Formik>
    </ModalWrapper>
  )
}
