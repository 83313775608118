import React from 'react';
import { Table } from 'semantic-ui-react';

export default function ClientReqDLCDayWorkerWageTableTimeDetail({
	numOfWorkers,
	timeWorkerPlusMonthOWT,
	timeWorkerPlusMonthNWT,
	timeWorkerPlusMonthHoliBasicTime,
	timeWorkerPlusMonthOWTHoli,
	timeWorkerPlusMonthOffBasicTime,
	timeWorkerPlusMonthOWTOff,
	timeWorkerSpecialBonus,
	handelInputChange,
	centerStyle,
	inputTimeStyle,
	dayWorkerPlusMonthOWT,
	dayWorkerPlusMonthNWT,
	dayWorkerPlusMonthHoliBasicTime,
	dayWorkerPlusMonthOWTHoli,
	dayWorkerPlusMonthNWTHoli,
	dayWorkerAbsentTimes,
	daylyTimePay,
	dayWorkerSpecialBonus,
	dayWorkerPlusDeductDuru,
	dayWorkerPlusMinus4Ins,
}) {
	return (
		<>
			{daylyTimePay > 0 ? (
				<>
					{numOfWorkers >= 5 ? (
						<Table.Row>
							<Table.Cell style={centerStyle}>
								<input
									style={inputTimeStyle}
									name='timeWorkerPlusMonthOWT'
									value={timeWorkerPlusMonthOWT}
									onChange={(e) => handelInputChange(e)}
									autoComplete='off'
								/>
							</Table.Cell>
							<Table.Cell style={centerStyle}>
								<input
									style={inputTimeStyle}
									name='timeWorkerPlusMonthOffBasicTime'
									value={timeWorkerPlusMonthOffBasicTime}
									onChange={(e) => handelInputChange(e)}
									autoComplete='off'
								/>
							</Table.Cell>
							<Table.Cell style={centerStyle}>
								<input
									style={inputTimeStyle}
									name='timeWorkerPlusMonthOWTOff'
									value={timeWorkerPlusMonthOWTOff}
									onChange={(e) => handelInputChange(e)}
									autoComplete='off'
								/>
							</Table.Cell>
							<Table.Cell style={centerStyle}>
								<input
									style={inputTimeStyle}
									name='timeWorkerPlusMonthHoliBasicTime'
									value={timeWorkerPlusMonthHoliBasicTime}
									onChange={(e) => handelInputChange(e)}
									autoComplete='off'
								/>
							</Table.Cell>
							<Table.Cell style={centerStyle}>
								<input
									style={inputTimeStyle}
									name='timeWorkerPlusMonthOWTHoli'
									value={timeWorkerPlusMonthOWTHoli}
									onChange={(e) => handelInputChange(e)}
									autoComplete='off'
								/>
							</Table.Cell>

							<Table.Cell style={centerStyle}>
								<input
									style={inputTimeStyle}
									name='timeWorkerPlusMonthNWT'
									value={timeWorkerPlusMonthNWT}
									onChange={(e) => handelInputChange(e)}
									autoComplete='off'
								/>
							</Table.Cell>
							<Table.Cell style={centerStyle}>
								<input
									style={inputTimeStyle}
									name='dayWorkerPlusDeductDuru'
									value={dayWorkerPlusDeductDuru}
									onChange={(e) => handelInputChange(e)}
									autoComplete='off'
								/>
							</Table.Cell>
							<Table.Cell style={centerStyle}>
								<input
									style={inputTimeStyle}
									name='dayWorkerPlusMinus4Ins'
									value={dayWorkerPlusMinus4Ins}
									onChange={(e) => handelInputChange(e)}
									autoComplete='off'
								/>
							</Table.Cell>
							{/* <Table.Cell></Table.Cell> */}
							<Table.Cell style={centerStyle}>
								<input
									style={inputTimeStyle}
									name='timeWorkerSpecialBonus'
									value={timeWorkerSpecialBonus}
									onChange={(e) => handelInputChange(e)}
									autoComplete='off'
								/>
							</Table.Cell>
						</Table.Row>
					) : (
						<Table.Row>
							<Table.Cell style={centerStyle}>
								<input
									style={inputTimeStyle}
									name='timeWorkerPlusMonthOWT'
									value={timeWorkerPlusMonthOWT}
									onChange={(e) => handelInputChange(e)}
									autoComplete='off'
								/>
							</Table.Cell>
							<Table.Cell style={centerStyle}>
								<input
									style={inputTimeStyle}
									name='timeWorkerPlusMonthNWT'
									value={timeWorkerPlusMonthNWT}
									onChange={(e) => handelInputChange(e)}
									autoComplete='off'
								/>
							</Table.Cell>
							<Table.Cell></Table.Cell>
							<Table.Cell></Table.Cell>
							<Table.Cell></Table.Cell>
							<Table.Cell></Table.Cell>
							<Table.Cell style={centerStyle}>
								<input
									style={inputTimeStyle}
									name='dayWorkerPlusMinus4Ins'
									value={dayWorkerPlusMinus4Ins}
									onChange={(e) => handelInputChange(e)}
									autoComplete='off'
								/>
							</Table.Cell>
							<Table.Cell style={centerStyle}>
								<input
									style={inputTimeStyle}
									name='dayWorkerPlusDeductDuru'
									value={dayWorkerPlusDeductDuru}
									onChange={(e) => handelInputChange(e)}
									autoComplete='off'
								/>
							</Table.Cell>

							<Table.Cell style={centerStyle}>
								<input
									style={inputTimeStyle}
									name='timeWorkerSpecialBonus'
									value={timeWorkerSpecialBonus}
									onChange={(e) => handelInputChange(e)}
									autoComplete='off'
								/>
							</Table.Cell>
						</Table.Row>
					)}
				</>
			) : (
				<Table.Row>
					<Table.Cell style={centerStyle}>
						<input
							style={inputTimeStyle}
							name='dayWorkerPlusMonthOWT'
							value={dayWorkerPlusMonthOWT}
							onChange={(e) => handelInputChange(e)}
							autoComplete='off'
						/>
					</Table.Cell>
					<Table.Cell style={centerStyle}>
						<input
							style={inputTimeStyle}
							name='dayWorkerPlusMonthNWT'
							value={dayWorkerPlusMonthNWT}
							onChange={(e) => handelInputChange(e)}
							autoComplete='off'
						/>
					</Table.Cell>
					<Table.Cell style={centerStyle}>
						<input
							style={inputTimeStyle}
							name='dayWorkerPlusMonthHoliBasicTime'
							value={dayWorkerPlusMonthHoliBasicTime}
							onChange={(e) => handelInputChange(e)}
							autoComplete='off'
						/>
					</Table.Cell>
					<Table.Cell style={centerStyle}>
						<input
							style={inputTimeStyle}
							name='dayWorkerPlusMonthOWTHoli'
							value={dayWorkerPlusMonthOWTHoli}
							onChange={(e) => handelInputChange(e)}
							autoComplete='off'
						/>
					</Table.Cell>
					<Table.Cell style={centerStyle}>
						<input
							style={inputTimeStyle}
							name='dayWorkerPlusMonthNWTHoli'
							value={dayWorkerPlusMonthNWTHoli}
							onChange={(e) => handelInputChange(e)}
							autoComplete='off'
						/>
					</Table.Cell>
					<Table.Cell style={centerStyle}>
						<input
							style={inputTimeStyle}
							name='dayWorkerAbsentTimes'
							value={dayWorkerAbsentTimes}
							onChange={(e) => handelInputChange(e)}
							autoComplete='off'
						/>
					</Table.Cell>
					<Table.Cell style={centerStyle}>
						<input
							style={inputTimeStyle}
							name='dayWorkerPlusDeductDuru'
							value={dayWorkerPlusDeductDuru}
							onChange={(e) => handelInputChange(e)}
							autoComplete='off'
						/>
					</Table.Cell>
					<Table.Cell style={centerStyle}>
						<input
							style={inputTimeStyle}
							name='dayWorkerPlusMinus4Ins'
							value={dayWorkerPlusMinus4Ins}
							onChange={(e) => handelInputChange(e)}
							autoComplete='off'
						/>
					</Table.Cell>
					<Table.Cell style={centerStyle}>
						<input
							style={inputTimeStyle}
							name='dayWorkerSpecialBonus'
							value={dayWorkerSpecialBonus}
							onChange={(e) => handelInputChange(e)}
							autoComplete='off'
						/>
					</Table.Cell>
				</Table.Row>
			)}
		</>
	);
}
