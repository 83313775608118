import React, { useEffect, useRef, useState } from 'react';
import ClientReqListItem4InMonth from '../clientsReqDashboard4In/dashboardListItemDetail/ClientReqListItem4InMonth';
import ClientReqListItem4InDay from '../clientsReqDashboard4In/dashboardListItemDetail/ClientReqListItem4InDay';
import { HiInformationCircle } from 'react-icons/hi';
import { Button } from 'semantic-ui-react';
import ClientReqListItem4InBiz from './dashboardListItemDetail/ClientReqListItem4InBiz';

export default function ClientReqList4In({
	authLevel,
	lastPreventData,
	clientReqs4In,
	searchResults,
	setSearchResults,
	searchTerm,
	companyName,
}) {
	const scrollContainerRef = useRef(null);

	const [showMonthWorkerNow, setShowMonthWorkerNow] = useState(true);
	const [showDayWorkerNow, setShowDayWorkerNow] = useState(true);

	useEffect(() => {
		const searchedClientReqs4In = clientReqs4In?.filter((clientReq4In) => {
			return (
				clientReq4In?.companyName?.includes(searchTerm) ||
				companyName?.includes(searchTerm) ||
				clientReq4In?.worker?.name?.includes(searchTerm)
			);
		});

		setSearchResults(searchedClientReqs4In);
	}, [searchTerm, setSearchResults, clientReqs4In, companyName]);

	const monthWorerDataNow = (searchResults) => {
		return searchResults.filter(
			(row) => !row.dayWorker && !row.isBizWorker && !row?.retiredDate
		);
	};

	const monthWorerDataRetired = (searchResults) => {
		// 필터링: 퇴사한 월간 근무자만 선택
		const filteredResults = searchResults.filter(
			(row) => !row.dayWorker && !row.isBizWorker && row?.retiredDate
		);
		// 정렬: 퇴사일 순으로 정렬
		filteredResults.sort(
			(a, b) => new Date(b.retiredDate) - new Date(a.retiredDate)
		);
		return filteredResults;
	};

	const bizWorerData = (searchResults) => {
		return searchResults.filter((row) => row.isBizWorker);
	};

	const dayWorerData = (searchResults) => {
		return searchResults.filter((row) => row.dayWorker);
	};

	const dayWorerDataNow = (searchResults) => {
		return searchResults.filter((row) => row.dayWorker && !row?.retiredDate);
	};

	const dayWorerDataRetired = (searchResults) => {
		const filteredResults = searchResults.filter(
			(row) => row.dayWorker && row?.retiredDate
		);
		filteredResults.sort(
			(a, b) => new Date(b.retiredDate) - new Date(a.retiredDate)
		);
		return filteredResults;
	};

	// 맨 위로 스크롤하는 함수
	const scrollToTop = () => {
		if (scrollContainerRef.current) {
			scrollContainerRef.current.scrollTop = 0;
		}
	};

	// 맨 아래로 스크롤하는 함수
	const scrollToBottom = () => {
		if (scrollContainerRef.current) {
			scrollContainerRef.current.scrollTop =
				scrollContainerRef.current.scrollHeight;
		}
	};

	return (
		<>
			{clientReqs4In?.length !== 0 && (
				<>
					<h4>월급직(정규/계약)</h4>
					<Button
						primary
						onClick={() => setShowMonthWorkerNow(!showMonthWorkerNow)}
						content={
							!showMonthWorkerNow ? '월급직 재직자 보기' : '월급직 퇴사자 보기'
						}
					/>
					<hr />
					<Button primary onClick={scrollToTop}>
						맨 위로
					</Button>
					<Button secondary onClick={scrollToBottom}>
						맨 아래로
					</Button>
					<div
						ref={scrollContainerRef}
						style={{ overflowY: 'auto', maxHeight: '1000px' }}>
						<br />
						<div style={{ color: 'blue' }}>
							{' '}
							<HiInformationCircle /> 전월 급여 복사 및 전체 급여작업은 좌측
							사이드바의 "급여작업"을 이용하세요.
						</div>
						<div style={{ color: 'blue' }}>
							{' '}
							<HiInformationCircle /> 개인급여작업 버튼은{' '}
							<span style={{ color: 'red' }}>
								개인별 입/퇴사월과 산전후휴가, 임금인상 등 자동일할 계산이 필요
							</span>{' '}
							할 때 이용하세요.
						</div>
						{showMonthWorkerNow && (
							<>
								{monthWorerDataNow(searchResults).map((searchResult, index) => (
									<ClientReqListItem4InMonth
										key={`${searchResult.id}-${index}`} // 고유한 key를 생성
										lastPreventData={lastPreventData}
										clientReq4In={searchResult}
										index={index}
										clientReqs4In={clientReqs4In}
										authLevel={authLevel}
										companyName={companyName}
									/>
								))}
							</>
						)}
						{!showMonthWorkerNow && (
							<>
								{monthWorerDataRetired(searchResults).length > 0 ? (
									<>
										{monthWorerDataRetired(searchResults).map(
											(searchResult, index) => (
												<ClientReqListItem4InMonth
													key={`${searchResult.id}-${index}`} // 고유한 key를 생성
													lastPreventData={lastPreventData}
													clientReq4In={searchResult}
													index={index}
													clientReqs4In={clientReqs4In}
													authLevel={authLevel}
													companyName={companyName}
												/>
											)
										)}
									</>
								) : (
									<h1>퇴사자는 없습니다.</h1>
								)}
							</>
						)}
						{dayWorerData(searchResults).length > 0 ? (
							<>
								<br />
								<h4 style={{ marginTop: '50px' }}>
									단시간(일용/시급)
									<span style={{ fontSize: '0.8rem', color: 'blue' }}>
										{' '}
										- 비정규직 급여작업은 마감전 수정만 가능하고 이월복사는 되지
										않습니다.
									</span>
								</h4>
								<Button
									secondary
									onClick={() => setShowDayWorkerNow(!showDayWorkerNow)}
									content={
										!showDayWorkerNow
											? '일용/시급직 재직자 보기'
											: '일용/시급직 퇴사자 보기'
									}
								/>
								{showDayWorkerNow && (
									<>
										{dayWorerDataNow(searchResults).map(
											(searchResult, index) => (
												<ClientReqListItem4InDay
													key={`${searchResult.id}-${index}`} // 고유한 key를 생성
													clientReq4In={searchResult}
													index={index}
													clientReqs4In={clientReqs4In}
													authLevel={authLevel}
													companyName={companyName}
												/>
											)
										)}
									</>
								)}
								{!showDayWorkerNow && (
									<>
										{dayWorerDataRetired(searchResults).length > 0 ? (
											<>
												{dayWorerDataRetired(searchResults).map(
													(searchResult, index) => (
														<ClientReqListItem4InDay
															key={`${searchResult.id}-${index}`} // 고유한 key를 생성
															clientReq4In={searchResult}
															index={index}
															clientReqs4In={clientReqs4In}
															authLevel={authLevel}
															companyName={companyName}
														/>
													)
												)}
											</>
										) : (
											<h1>퇴사자는 없습니다.</h1>
										)}
									</>
								)}
							</>
						) : null}
						{bizWorerData(searchResults).length > 0 && (
							<>
								<br />
								<h4 style={{ marginTop: '50px' }}>
									사업소득직
									<span style={{ fontSize: '0.8rem', color: 'blue' }}>
										{' '}
										- 사업소득직직 급여작업은 마감전 수정만 가능하고 이월복사는
										되지 않습니다.
									</span>
								</h4>
								{bizWorerData(searchResults).map((searchResult, index) => (
									<ClientReqListItem4InBiz
										key={`${searchResult.id}-${index}`} // 고유한 key를 생성
										clientReq4In={searchResult}
										index={index}
										clientReqs4In={clientReqs4In}
										authLevel={authLevel}
										companyName={companyName}
										bizIncomeWorker={searchResult.isBizWorker}
									/>
								))}
							</>
						)}
					</div>
				</>
			)}
		</>
	);
}
