import React, { useEffect, useState } from 'react'
import moment from 'moment'
import { Table } from 'semantic-ui-react'
import { calALFinTodBagicYear, calALFinTodPlusYear, calALFinTodRatioYear } from '../../../../annualLeave-libs/finALTod-lib'
import { calALMonth } from '../../../../annualLeave-libs/lawALSpec-lib'
import { calALTodBagicYear, calALTodMonth, calALTodPlusYear, calALTodTotal } from '../../../../annualLeave-libs/lawALTod-lib'
import { usedAnnualLeavesInThisYearSumF, sumAnnualLeaveDaysForYear } from '../../../../app/common/util/util'
import OpenDetailAnnualLeavesListItem4InPastYear from './OpenDetailAnnualLeavesListItem4InPastYear'
import { apiService } from '../../../../annualLeave-libs/calculateAnnualLeaveCalALFinMonthApiService'
import { useDispatch, useSelector } from 'react-redux'
import { clearPaydocu, fetchPaydocusFormFirestoreService4In } from '../../../companyManage/companyActions'

export default function OpenDetailAnnualLeavesListItem4In({
  afterRetireAgainContractStartDate,
  overFiveStartDay,
  daylyPay,
  daylyTimePay,
  numOfWorkers,
  Item,
  isExceptTimeLawWorker,
  decryptoClientReq,
  finBaseDate,
  annualLeaves,
  finBaseMonthDay,
  anuualLeaveEnterDate,
  underOneYearWorkPeriod,
  absentDaysYearCntForALF,
  enterDate,
  workerId,
  secondYearForAnnualLeave,
  usedAnnualLeavesInLastYearSumF,
  thisYear,
  clientInfo
}) {
	const dispatch = useDispatch();
  const { paydocus  } = useSelector(state => state.companyReducer);

  const [calALFinTodMonthV, setCalALFinTodMonthV] = useState(0);

  useEffect(() => {
    const fetchAllPaydocus = async () => {
      try {
        await dispatch(fetchPaydocusFormFirestoreService4In('isWorker', '', decryptoClientReq?.id, clientInfo));
      } catch (error) {
        console.error("Error fetching paydocus:", error);
      }
    };

    fetchAllPaydocus();

    return () => {
      dispatch(clearPaydocu());
    };
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    const fetchData = async () => {
      try {
        const data = await apiService.calculateAnnualLeaveCalALFinMonth({
          enterDate: enterDate,
          finBaseDate: finBaseDate,
          workersNumber: numOfWorkers,
          specificDate: ''
        });
        setCalALFinTodMonthV(data[0]); // API의 응답 구조에 따라 조정 필요
      } catch (error) {
        console.error('Error fetching data: ', error);
      }
    };

    fetchData();
  }, [enterDate, finBaseDate, numOfWorkers, ]);

  // const calALFinTodMonthV = calculateAnnualLeaveCalALFinMonth(enterDate, finBaseDate, numOfWorkers, '')[0]
  const lastYear = +thisYear - 1;
  const lastTwoYear = +thisYear - 2;
  const lastThreeYear = +thisYear - 3;


  const calALFinTodRatioYearV = calALFinTodRatioYear(anuualLeaveEnterDate, finBaseDate, numOfWorkers)
  const calALFinTodBagicYearV = calALFinTodBagicYear(anuualLeaveEnterDate, finBaseDate, numOfWorkers)
  const calALFinTodPlusYearV = calALFinTodPlusYear(anuualLeaveEnterDate, finBaseDate, numOfWorkers)
  const sumCalALFinTod = calALFinTodMonthV + calALFinTodRatioYearV + calALFinTodBagicYearV + calALFinTodPlusYearV

  const calALTodMonthThisYearV = calALMonth(anuualLeaveEnterDate, numOfWorkers)[0]
  const calALTodMonthV = calALTodMonth(anuualLeaveEnterDate, numOfWorkers)
  const calALTodBagicYearV = calALTodBagicYear(anuualLeaveEnterDate, numOfWorkers)
  const calALTodPlusYearV = calALTodPlusYear(anuualLeaveEnterDate, numOfWorkers)
  const secondYearForAnnualLeaveValue = secondYearForAnnualLeave(enterDate, thisYear)
  const sumCalALTod = secondYearForAnnualLeaveValue ? calALTodMonthThisYearV + calALTodBagicYearV + calALTodPlusYearV : calALTodMonthV + calALTodBagicYearV + calALTodPlusYearV 
  
  const enterWorkerThisOneYearBeforeYes = moment(anuualLeaveEnterDate).format('yyyy') > {lastYear} ? true : false

  let dayWorkerText = daylyPay ? '* 장기간 근무하는 일급직 직원 관리용 연차 발생내용입니다. 일급직의 사용연차는 별도로 관리하세요. ' : null;
  let timeWorkerText = daylyTimePay ? '장기간 근무하는 시급직 직원 관리용 연차 발생내용입니다. 시급직의 사용연차는 별도로 관리하세요.' : null;

  let annualLeavesApplyDayComment;
  
  if (overFiveStartDay >= (enterDate || afterRetireAgainContractStartDate)) {
      annualLeavesApplyDayComment = <span style={{ color: 'red' }}>5인이상 연차적용일</span>;
  } else if (afterRetireAgainContractStartDate && afterRetireAgainContractStartDate >= enterDate) {
      annualLeavesApplyDayComment = <span style={{ color: 'red' }}>정년이후 촉탁입사일</span>;
  } else {
      annualLeavesApplyDayComment = "최초입사일";
  }

  const absentDaysYearCntForALFValue = absentDaysYearCntForALF(enterDate, paydocus, workerId)[2]
  const usedAnnualLeavesInThisYearSumFValue = usedAnnualLeavesInThisYearSumF(paydocus, workerId)
  const calALTodTotalValue = calALTodTotal(anuualLeaveEnterDate, numOfWorkers)

  return (
    <>
    { 
      <>
        {numOfWorkers < 5
          ?
            <>
              <hr />
              <Item.Description>
                <div><strong>연차 관리 대장</strong></div>
                <div style={{color : 'purple'}}>* 5인미만 사업장으로 연차가 발생되지 않습니다.</div>
              </Item.Description>
            </>
          :
            <>
              {!isExceptTimeLawWorker && decryptoClientReq?.lawBase?.weekLBTime < 15 ?
                <Item.Description>
                  <div><strong>1주 소정근로 15시간 미만 직원</strong></div>
                  <div style={{color : 'purple'}}>* 1주 소정근로시간이 15시간 미만인 직원은 연차가 발생하지 않습니다.</div>
                </Item.Description>
              :
                <>
                  <hr />
                    {(finBaseDate && (annualLeaves === '회계년도기준' || annualLeaves === '특정일(회계년도 등)기준'))
                      ?
                        <>
                          <Item.Description>
                            <div><strong>올해 오늘날짜 연차현황[회계기준({finBaseMonthDay})] [{annualLeavesApplyDayComment} : {anuualLeaveEnterDate}]</strong></div>
                          </Item.Description>
                          <Item.Description>
                            <Table textAlign = 'center'>
                              <Table.Header>
                                <Table.Row>
                                  <Table.HeaderCell>구분</Table.HeaderCell>
                                  <Table.HeaderCell>월 단위</Table.HeaderCell>
                                  <Table.HeaderCell>비례</Table.HeaderCell>
                                  <Table.HeaderCell>년기본</Table.HeaderCell>
                                  <Table.HeaderCell>년가산</Table.HeaderCell>
                                  <Table.HeaderCell>합계</Table.HeaderCell>
                                  {(underOneYearWorkPeriod && absentDaysYearCntForALFValue) || secondYearForAnnualLeaveValue?
                                    <Table.HeaderCell>월 단위<br />결근공제</Table.HeaderCell> : null
                                  }
                                  <Table.HeaderCell>{thisYear}년 <br /> 사용연차</Table.HeaderCell>
                                  <Table.HeaderCell>{thisYear}년 <br /> 수당지급연차</Table.HeaderCell>
                                  <Table.HeaderCell>{thisYear}년 현재 <br />잔여연차</Table.HeaderCell>
                                </Table.Row>
                              </Table.Header>
                              <Table.Body>
                                <Table.Row>
                                  <Table.Cell><strong>회계기준</strong></Table.Cell>
                                  <Table.Cell>{calALFinTodMonthV}</Table.Cell>
                                  <Table.Cell>{calALFinTodRatioYearV}</Table.Cell>
                                  <Table.Cell>{calALFinTodBagicYearV}</Table.Cell>
                                  <Table.Cell>{calALFinTodPlusYearV}</Table.Cell>
                                  <Table.Cell>{sumCalALFinTod}</Table.Cell>
                                  {((underOneYearWorkPeriod && absentDaysYearCntForALFValue)) || secondYearForAnnualLeaveValue ? <Table.Cell>{absentDaysYearCntForALFValue * -1}</Table.Cell> : null}
                                  <Table.Cell>{usedAnnualLeavesInThisYearSumFValue * -1}</Table.Cell>
                                  <Table.Cell>{sumAnnualLeaveDaysForYear(paydocus, thisYear) * -1}</Table.Cell>

                                  {(underOneYearWorkPeriod && absentDaysYearCntForALFValue) || secondYearForAnnualLeaveValue 
                                    ?
                                      <Table.Cell>{sumCalALFinTod-usedAnnualLeavesInThisYearSumFValue  - absentDaysYearCntForALFValue - sumAnnualLeaveDaysForYear(paydocus, thisYear)}</Table.Cell>
                                    : 
                                      <Table.Cell>{sumCalALFinTod-usedAnnualLeavesInThisYearSumFValue - sumAnnualLeaveDaysForYear(paydocus, thisYear)}</Table.Cell>}
                                </Table.Row>
                                <Table.Row>
                                  <Table.Cell><strong>법정기준</strong></Table.Cell>
                                  {secondYearForAnnualLeaveValue ? <Table.Cell>{calALTodMonthThisYearV}</Table.Cell> : <Table.Cell>{calALTodMonthV}</Table.Cell>}
                                  <Table.Cell>'해당없음'</Table.Cell>
                                  <Table.Cell>{calALTodBagicYearV}</Table.Cell>
                                  <Table.Cell>{calALTodPlusYearV}</Table.Cell>
                                  <Table.Cell>{sumCalALTod}</Table.Cell>
                                  {((underOneYearWorkPeriod && absentDaysYearCntForALFValue) || secondYearForAnnualLeaveValue) ?
                                  <Table.Cell>{absentDaysYearCntForALFValue * -1}</Table.Cell> : null}
                                  <Table.Cell>{usedAnnualLeavesInThisYearSumFValue  * -1}</Table.Cell>
                                  <Table.Cell>{sumAnnualLeaveDaysForYear(paydocus, thisYear) * -1}</Table.Cell>

                                  {(underOneYearWorkPeriod && absentDaysYearCntForALFValue) || secondYearForAnnualLeaveValue  
                                    ?
                                      <Table.Cell>{sumCalALTod-usedAnnualLeavesInThisYearSumFValue - absentDaysYearCntForALFValue  - sumAnnualLeaveDaysForYear(paydocus, thisYear)}</Table.Cell>
                                    : <Table.Cell>{sumCalALTod-usedAnnualLeavesInThisYearSumFValue - sumAnnualLeaveDaysForYear(paydocus, thisYear)}</Table.Cell>}
                                </Table.Row>

                              </Table.Body>
                            </Table>
                            {daylyPay ? <p style={{color:'red'}}>{dayWorkerText}</p> :
                              daylyTimePay ? <p style={{color:'red'}}>{timeWorkerText}</p> : null  }
                            {!enterWorkerThisOneYearBeforeYes ? <p style={{marginTop : '5px', color : 'blue'}}>*잔여연차는 {thisYear}년도에 사용한 연차만 차감한 것으로 전체 차감연차는 {lastYear}년도 사용연차를 고려하시기 바랍니다.</p> : null}
                          </Item.Description>
                        </>
                      :
                        <>
                          <Item.Description>
                            <div><strong>올해 오늘날짜 연차현황 [{annualLeavesApplyDayComment} : {anuualLeaveEnterDate}]</strong></div>
                          </Item.Description>
                          <Item.Description>
                            <Table  style={{textAlign : 'center'}}>
                              <Table.Header>
                                <Table.Row>
                                  <Table.HeaderCell>구분</Table.HeaderCell>
                                  <Table.HeaderCell>월 단위</Table.HeaderCell>
                                  <Table.HeaderCell>년기본</Table.HeaderCell>
                                  <Table.HeaderCell>년가산</Table.HeaderCell>
                                  {(underOneYearWorkPeriod && absentDaysYearCntForALFValue) ?
                                  <Table.HeaderCell>월 단위<br />결근공제</Table.HeaderCell> : null}
                                  <Table.HeaderCell>합계</Table.HeaderCell>
                                  <Table.HeaderCell>{thisYear}년 <br /> 사용연차</Table.HeaderCell>
                                  <Table.HeaderCell>{thisYear}년 <br /> 수당지급연차</Table.HeaderCell>
                                  <Table.HeaderCell>*잔여연차</Table.HeaderCell>
                                  <Table.HeaderCell>{lastYear}년 <br /> 사용연차 <br />(입사기준 <br /> 참고용)</Table.HeaderCell>
                                </Table.Row>
                              </Table.Header>
                              <Table.Body>
                                <Table.Row>
                                  <Table.Cell>법정기준</Table.Cell>
                                  <Table.Cell>{calALTodMonthV}</Table.Cell>
                                  <Table.Cell>{calALTodBagicYearV}</Table.Cell>
                                  <Table.Cell>{calALTodPlusYearV}</Table.Cell>
                                  {(underOneYearWorkPeriod && absentDaysYearCntForALFValue) ?
                                  <Table.Cell>{absentDaysYearCntForALFValue * -1}</Table.Cell> : null}
                                  {(underOneYearWorkPeriod && absentDaysYearCntForALFValue) ?
                                  <Table.Cell>{calALTodTotalValue-absentDaysYearCntForALFValue}</Table.Cell>
                                  : <Table.Cell>{calALTodTotalValue}</Table.Cell>}
                                  <Table.Cell>{usedAnnualLeavesInThisYearSumFValue}</Table.Cell>
                                  <Table.Cell>{sumAnnualLeaveDaysForYear(paydocus, thisYear) * -1}</Table.Cell>
                                  {(underOneYearWorkPeriod && absentDaysYearCntForALFValue) ?
                                  <Table.Cell>{calALTodTotalValue-usedAnnualLeavesInThisYearSumFValue-absentDaysYearCntForALFValue}</Table.Cell>
                                  : <Table.Cell>{calALTodTotalValue-usedAnnualLeavesInThisYearSumFValue}</Table.Cell>}
                                  <Table.Cell>{usedAnnualLeavesInLastYearSumF(paydocus, workerId)}</Table.Cell>
                                </Table.Row>
                              </Table.Body>
                            </Table>
                            {daylyPay ? <p style={{color:'red'}}>{dayWorkerText}</p> :
                              daylyTimePay ? <p style={{color:'red'}}>{timeWorkerText}</p> : null  }
                            {!enterWorkerThisOneYearBeforeYes ? <p style={{marginTop : '5px', color : 'blue'}}>*잔여연차는 {thisYear}년도에 사용한 연차만 차감한 것으로 전체 차감연차는 {lastYear}년도 사용연차를 고려하시기 바랍니다.</p> : null}
                          </Item.Description>
                        </>
                    }
                    <OpenDetailAnnualLeavesListItem4InPastYear 
                      numOfWorkers={numOfWorkers}
                      Item={Item}
                      finBaseDate={finBaseDate}
                      annualLeaves={annualLeaves}
                      overFiveStartDay={overFiveStartDay}
                      finBaseMonthDay={finBaseMonthDay}
                      anuualLeaveEnterDate={anuualLeaveEnterDate}
                      underOneYearWorkPeriod={underOneYearWorkPeriod}
                      enterDate={enterDate}
                      paydocus={paydocus}
                      workerId={workerId}
                      secondYearForAnnualLeave={secondYearForAnnualLeave}
                      targetYear={lastYear}
                      usedAnnualLeavesInLastYearSumF={usedAnnualLeavesInLastYearSumF}
                    />

                    <OpenDetailAnnualLeavesListItem4InPastYear 
                      numOfWorkers={numOfWorkers}
                      Item={Item}
                      finBaseDate={finBaseDate}
                      annualLeaves={annualLeaves}
                      overFiveStartDay={overFiveStartDay}
                      finBaseMonthDay={finBaseMonthDay}
                      anuualLeaveEnterDate={anuualLeaveEnterDate}
                      underOneYearWorkPeriod={underOneYearWorkPeriod}
                      enterDate={enterDate}
                      paydocus={paydocus}
                      workerId={workerId}
                      secondYearForAnnualLeave={secondYearForAnnualLeave}
                      targetYear={lastTwoYear}
                      usedAnnualLeavesInLastYearSumF={usedAnnualLeavesInLastYearSumF}
                    />
        
                    <OpenDetailAnnualLeavesListItem4InPastYear 
                      numOfWorkers={numOfWorkers}
                      Item={Item}
                      finBaseDate={finBaseDate}
                      annualLeaves={annualLeaves}
                      overFiveStartDay={overFiveStartDay}
                      finBaseMonthDay={finBaseMonthDay}
                      anuualLeaveEnterDate={anuualLeaveEnterDate}
                      underOneYearWorkPeriod={underOneYearWorkPeriod}
                      enterDate={enterDate}
                      paydocus={paydocus}
                      workerId={workerId}
                      secondYearForAnnualLeave={secondYearForAnnualLeave}
                      targetYear={lastThreeYear}
                      usedAnnualLeavesInLastYearSumF={usedAnnualLeavesInLastYearSumF}
                    />
                </>
                }
            </>
        }
          </>
    }
      </>
  )
}
