import {format} from 'date-fns';

export const COLUMNS = [
  {
    Header:'회사명',
    accessor: 'clientName',
    disableFilters: true
  },
  {
    Header:'담당자',
    accessor: 'consultant',
    disableFilters: true
  },
  {
    Header:'지역',
    accessor: 'region',
    disableFilters: true
  },
  {
    Header:'최초영업일',
    Footer:'Date',
    accessor: 'date',
    Cell: ({value}) => {return format(new Date(value), 'yyyy-MM-dd')},
    disableFilters: true
  },
  {
    Header:'연락처',
    accessor: 'phone',
    disableFilters: true
  },
  {
    Header:'이메일',
    accessor: 'email',
    disableFilters: true
  },
  {
    Header:'1차결과',
    accessor: 'salesFailType',
    disableFilters: true
  },
  {
    Header:'인업접수',
    accessor: 'inupApplyOkNo',
    disableFilters: true
  },
  {
    Header:'인업선택',
    accessor: 'inupSelectOkNo',
    disableFilters: true
  },
];


export const GROUPED_COLUMNS = [
  {
    Header:'Id',
    Footer:'Id',
    accessor: 'id'
  },
  {
    Header:'Name',
    Footer:'Name',
    columns: [
      {
        Header:'ClientName',
        Footer:'ClientName',
        accessor: 'clientName'
      },
      {
        Header:'PersontName',
        Footer:'PersontName',
        accessor: 'personName'
      },
      {
        Header:'WorkerName',
        Footer:'WorkerName',
        accessor: 'workerName'
      },
      {
        Header:'Consultant',
        Footer:'Consultant',
        accessor: 'consultant'
      },
    ]
  },
  {
    Header:'Info',
    Footer:'Info',
    columns: [
      {
        Header:'Region',
        Footer:'Region',
        accessor: 'region'
      },
      {
        Header:'Date',
        Footer:'Date',
        accessor: 'date'
      },
    ]
  }
];