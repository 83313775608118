import React, { useRef, useEffect } from 'react';
import { GrUser } from 'react-icons/gr';
import { FcMindMap } from 'react-icons/fc';

const SlowText = ({ speed, text = "" }) => {
  const [placeholder, setPlaceholder] = React.useState(text[0] || "");
  const index = useRef(0);

  useEffect(() => {
    function tick() {
      index.current++;
      setPlaceholder(prev => prev + text[index.current]);
    }
    if (index.current < text.length - 1) {
      const addChar = setInterval(tick, speed);
      return () => clearInterval(addChar);
    }
  }, [placeholder, speed, text]);

  return (
    <span>{placeholder}</span>
  );
};

const Message = React.forwardRef(({ userMessage, aiMessage, isLastMessage }, ref) => {

  return (
    <div ref={ref}> {/* 최상위 컨테이너에 ref 할당 */}
      <div className='message_container'
        style={{background: aiMessage ? 'rgb(247, 247, 248)' : 'white',}}
      >
        <div className='message_avartar_container' >
          <GrUser />
        </div>
        <p className='message_text'>
          {userMessage}
        </p>
      </div>
      <div className='message_container'
        style={{background: aiMessage ? 'rgb(247, 247, 248)' : 'white',}}
      >
        <div className='message_avartar_container' >
          {aiMessage ? <FcMindMap /> : <GrUser />}
        </div>
        <p className='message_text'>
          {aiMessage && (
            isLastMessage ? <SlowText speed={20} text={aiMessage} /> : aiMessage
          )}
        </p>
      </div>
    </div>
  );
});

export default Message;
