import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { selectedWorkerRegistryType } from '../../clientReqActions4In';

function SingleSelectRadioButtonDayWorker({ values }) {
	const [options] = useState([
		{ id: 4, name: '일용' },
		{ id: 5, name: '시급' },
		{ id: 6, name: '사업소득' },
	]);

	const dispatch = useDispatch();
	const { workerRegistryType } = useSelector(
		(state) => state.clientReq4InStore
	);

	const handleChange = (event) => {
		dispatch(selectedWorkerRegistryType(event.target.value));
	};

	const workerRegistryTypeDb = values?.workerRegistryType;

	return (
		<div style={{ marginTop: '25' }}>
			<h2>일급 / 시급 / 사업소득 직원 제도 선택</h2>
			<div style={{ display: 'flex' }}>
				{options.map((option) => (
					<div
						key={option.id}
						style={{
							marginRight: '400px',
							marginLeft: '10px',
							marginTop: '30px',
						}}>
						<input
							type='radio'
							id={`option-${option.id}`}
							name='options'
							value={option.id}
							checked={
								workerRegistryTypeDb ||
								workerRegistryType === option.id.toString()
							}
							onChange={handleChange}
							style={{ transform: 'scale(2)', marginRight: '1.5em' }}
						/>
						<label
							htmlFor={`option-${option.id}`}
							style={{ fontSize: '1.3em' }}>
							{option.name}
						</label>
					</div>
				))}
			</div>
			<br />
		</div>
	);
}

export default SingleSelectRadioButtonDayWorker;
