import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Grid, Input, Loader } from 'semantic-ui-react';
import { clearPaydocu, fetchPaydocusWorkerFormFirestoreService4In } from '../companyActions';
import PaydocuWorkerFilters from './PaydocuWorkerFilters';
import PaydocuShortCut from './PaydocuShortCut';
import PaydocusTableWorkerHrList from './PaydocusTableWorkerHrList';
import { clearClientReqs4In, fetchClientReqs4In } from '../../clientsRequest/clientReq4In/clientReqActions4In';

export default function PaydocusTableWorkerHrDashboard() {
  const dispatch = useDispatch();

  const { paydocus, paydocuWorkerName, clientInfo, filter } = useSelector(state => state.companyReducer);
  const { currentUserProfile } = useSelector(state => state.profile);
  const { loading } = useSelector(state => state.async);
  const { authenticated } = useSelector(state => state.auth);
  const { clientReqs4In } = useSelector((state) => state.clientReq4InStore);

  const [searchTerm, setSearchTerm] = useState("");
  const [searchResults, setSearchResults] = useState([]);
  const [paydocuWorkerCode, setPydocuWorkerCode] = useState('');

  const handleChange = event => {
    setSearchTerm(event.target.value);
  };

  const isWorker = currentUserProfile?.isWorker;
  
  useEffect(() => {
    dispatch(fetchClientReqs4In(filter)).then(() => {
    });
    return () => {
      dispatch(clearClientReqs4In()); 
    };
  }, [dispatch, filter]);

  useEffect(() => {
    dispatch(fetchPaydocusWorkerFormFirestoreService4In(paydocuWorkerName, paydocuWorkerCode, clientInfo)).then(() => {
    });
    return () =>{
      dispatch(clearPaydocu());
    }
  }, [dispatch, paydocuWorkerName, clientInfo, paydocuWorkerCode]) 


  return (
    <>
      {isWorker ? null : <PaydocuShortCut filter={filter} clientReqs4In={clientReqs4In}/> }

      <h1 style={{textAlign: 'center', margin: '50px'}}>직원별 업무수행평가 내역</h1>
 
      <PaydocuWorkerFilters 
        loading={loading} 
        isWorker={isWorker} 
        filter={filter}
        setPydocuWorkerCode={setPydocuWorkerCode}

      />        <>
          년도
            : <Input
              type="text"
              placeholder="Search"
              value={searchTerm}
              onChange={handleChange}
            />
            <br />
            <br />
        </>
      <Grid>
        <Grid.Column width={16}>
          {authenticated &&
            (paydocuWorkerName ? 
              <PaydocusTableWorkerHrList
                paydocus={paydocus}
                loading={loading}
                searchResults={searchResults}
                setSearchResults={setSearchResults}
                searchTerm={searchTerm}
                paydocuWorkerName={paydocuWorkerName}
              />
              : 
              <div>데이터를 불러올 수 없습니다. 다시 시도해주세요.</div>
            )              
          }
        </Grid.Column>
        <Grid.Column width={16}>
          <Loader active={loading} />
        </Grid.Column>
      </Grid>
    </>
  );
};