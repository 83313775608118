import React from 'react';
import { Button, Table } from 'semantic-ui-react';

import { useDispatch, useSelector } from 'react-redux';
import PaydocuShortCut from '../paydocuDashboard/PaydocuShortCut';
import {
	calculatePackagePrice,
	calculateSettingPrice,
	calculateTotalPrice,
	commaStringToNumber,
	companyBankAccountSeajong,
	companyBankAccountSeoul,
	getSystemPrice,
	numberToCommaString,
	printUseContract,
} from '../../../app/common/util/util';
import { format } from 'date-fns';
import useFirestoreDoc from '../../../app/hooks/useFirestoreDoc';
import { getUserProfile } from '../../../app/firestore/firestoreService';
import { listenToSelectedUserProfile } from '../../profiles/profileActions';

export default function ProgramUseContractContents({ profile }) {
	const dispatch = useDispatch();
	const { clientReqs4In } = useSelector((state) => state.clientReq4InStore);

	const { clientInfo, filter } = useSelector((state) => state.companyReducer);

	useFirestoreDoc({
		query: () => getUserProfile(profile.id),
		data: (profile) => dispatch(listenToSelectedUserProfile(profile)),
		deps: [dispatch, profile],
	});

	const companyInfo = clientInfo.length > 0 ? clientInfo : profile;
	const numOfWorkers = companyInfo?.numOfWorkers;
	const isWorker = companyInfo?.isWorker;

	const specialContractMonthPrice = clientInfo?.specialContractMonthPrice
		? clientInfo?.specialContractMonthPrice
		: profile?.specialContractMonthPrice;
	const specialContractSettingPrice = clientInfo?.specialContractSettingPrice
		? clientInfo?.specialContractSettingPrice
		: profile?.specialContractSettingPrice;

	const nomuService = companyInfo?.nomuService || [];
	const nomuPackageService = companyInfo?.nomuPackageService || [];

	const companyRegion = companyInfo?.companyRegion;
	const payDay = companyInfo?.payDay;

	const startDateString = companyInfo?.serviceStartDate;
	const formattedStartDate =
		startDateString instanceof Date
			? startDateString.toISOString().split('T')[0]
			: null; // 날짜가 유효하지 않을 경우 null

	const endDateString = companyInfo?.serviceEndDate;
	const formattedEndDate =
		endDateString instanceof Date
			? endDateString.toISOString().split('T')[0]
			: null; // 기본값을 "N/A" 등으로 설정할 수 있습니다.

	function calculateMonthDifference(startDate, endDate) {
		if (startDate instanceof Date && endDate instanceof Date) {
			const startYear = startDate.getFullYear();
			const startMonth = startDate.getMonth();
			const endYear = endDate.getFullYear();
			const endMonth = endDate.getMonth();

			// 개월 수 계산: 연도 차이 * 12 + 월 차이
			return (endYear - startYear) * 12 + (endMonth - startMonth);
		}
	}

	const monthDifference = calculateMonthDifference(
		startDateString,
		endDateString
	);

	const companyProductEvent =
		companyInfo?.companyProductEvent === '참여' ? true : false;
	const eventDiscountRate = 0.4;
	const eventProductRate = 1 - eventDiscountRate;
	//패키지 서비스는 단일선택이므로 하나의 값
	const calculatePackagePriceV = calculatePackagePrice(
		numOfWorkers,
		nomuPackageService
	);
	//개별적 노무서비스 중복선택 가능하여 합계로 계산
	const calculateTotalPriceV = calculateTotalPrice(numOfWorkers, nomuService);
	//세팅비
	const calculateSettingPriceV = calculateSettingPrice(numOfWorkers);

	const discountedSettingPriceV =
		companyProductEvent === '참여'
			? calculateSettingPriceV === '별도 협의'
				? '별도 협의'
				: commaStringToNumber(calculateSettingPriceV) * eventDiscountRate
			: calculateSettingPriceV === '별도 협의'
			? '별도 협의'
			: commaStringToNumber(calculateSettingPriceV);

	const getSystemPriceV = getSystemPrice(numOfWorkers);
	//시스템 사용료
	const minPrice =
		calculatePackagePriceV[0] !== 0 && calculateTotalPriceV[0] !== 0
			? Math.min(calculatePackagePriceV[0], calculateTotalPriceV[0])
			: calculatePackagePriceV[0] || calculateTotalPriceV[0];

	const totalPrice = minPrice + getSystemPriceV;

	const discountRate = companyInfo?.discountRate;
	const discountRateNum = discountRate ? parseFloat(discountRate) / 100 : 0; // "5%" -> 0.05 변환
	const monthDiscount = totalPrice * 1.1 * discountRateNum;

	const startNumOfWorkers = `(월급직, 시급직, 일급직, 사업소득직 등 인원수 : ${numOfWorkers}명 기준)`;

	const plusWorkerPrice = Math.min(
		calculateTotalPriceV[1],
		calculatePackagePriceV[1]
	);

	const settingDC =
		commaStringToNumber(calculateSettingPriceV) * 1.1 -
		specialContractSettingPrice * 1.1;

	return (
		<>
			{isWorker ? null : (
				<PaydocuShortCut filter={filter} clientReqs4In={clientReqs4In} />
			)}
			<Button
				color='blue'
				style={{ display: 'inline', color: 'black', margin: '5px' }}
				onClick={() => printUseContract()}>
				AI노무사 서비스 계약서 출력
			</Button>
			<div style={{ display: 'flex', height: '100vh' }}>
				<div id='useContract'>
					<h1 style={{ textAlign: 'center', margin: '20px', fontSize: '30px' }}>
						AI NOMUSA 노동 법률 지원 서비스 계약서
					</h1>
					<h3>위임인 기본사항</h3>
					<Table celled style={{ width: '95%', lineHeight: '1' }}>
						<Table.Body>
							<Table.Row textAlign='center'>
								<Table.Cell>회사명</Table.Cell>
								<Table.Cell>{profile?.companyName}</Table.Cell>
								<Table.Cell>사업자등록번호</Table.Cell>
								<Table.Cell>{profile?.companyBizNumber}</Table.Cell>
								<Table.Cell>Email</Table.Cell>
								<Table.Cell>{profile?.email}</Table.Cell>
							</Table.Row>
							<Table.Row textAlign='center'>
								<Table.Cell>대표(이사)</Table.Cell>
								<Table.Cell>{profile?.ceoName}</Table.Cell>
								<Table.Cell>연락처</Table.Cell>
								<Table.Cell>{profile?.companyPhone}</Table.Cell>
								<Table.Cell>회사주소</Table.Cell>
								<Table.Cell>{profile?.companyAddress}</Table.Cell>
							</Table.Row>
						</Table.Body>
					</Table>

					<h3>제1조 계약의 성립</h3>
					<p>
						‘갑’은 ‘을’에게 AI NOMUSA를 통한 노동 법률 지원 서비스(이하 “서비스”
						라 함.)를 위임하고 ‘을’은 이를 신의와 성실로 공정하게 수행할 것을
						승낙함으로써 본 계약을 체결한다.
					</p>
					<br />
					<h3>제2조 기본 서비스 내용 및 보수</h3>
					<p>
						① ‘을’은 다음 각호의 서비스를 ‘갑’ 에게 제공하며, ‘갑’은 이에 대한
						보수를 지급한다.
						<span
							style={{
								color: 'blue',
								fontSize: '1.3em', // 1.3 times the current font size
							}}>
							{startNumOfWorkers}
						</span>
					</p>
					<br />
					<Table celled style={{ width: '95%', lineHeight: '1' }}>
						<Table.Header>
							<Table.Row textAlign='center'>
								<Table.HeaderCell style={{ width: '30%' }}>
									구분
								</Table.HeaderCell>
								<Table.HeaderCell style={{ width: '40%' }}>
									서비스 내용
								</Table.HeaderCell>
								<Table.HeaderCell style={{ width: '25%' }}>
									금액 (V.A.T. 별도)
								</Table.HeaderCell>
							</Table.Row>
						</Table.Header>
						<Table.Body>
							<Table.Row textAlign='center'>
								<Table.Cell style={{ width: '30%' }}>시스템</Table.Cell>
								<Table.Cell style={{ width: '40%' }}>
									<div style={{ margin: '1rem' }}>AI NOMUSA 시스템 사용</div>
								</Table.Cell>
								<Table.Cell style={{ width: '25%' }}>
									{numberToCommaString(getSystemPriceV)} 원
								</Table.Cell>
							</Table.Row>
							<Table.Row textAlign='center'>
								<Table.Cell style={{ width: '30%' }}>
									개별 노무 서비스
								</Table.Cell>
								<Table.Cell style={{ width: '40%' }}>
									{nomuService.length > 0
										? nomuService.map((category) => category).join(', ') // 배열을 쉼표로 구분된 문자열로 변환
										: '자체관리'}
								</Table.Cell>
								<Table.Cell style={{ width: '25%' }}>
									{calculateTotalPriceV[0]
										? `${numberToCommaString(calculateTotalPriceV[0])} 원`
										: '자체 관리'}
								</Table.Cell>
							</Table.Row>
							<Table.Row textAlign='center'>
								<Table.Cell style={{ width: '30%' }}>
									개별 노무 서비스
								</Table.Cell>
								<Table.Cell style={{ width: '40%' }}>
									{nomuService.length > 0
										? `${nomuService.map((category, index) => ({ category }))}`
										: '자체관리'}
								</Table.Cell>
								<Table.Cell style={{ width: '25%' }}>
									{calculateTotalPriceV[0]
										? `${numberToCommaString(calculateTotalPriceV[0])} 원`
										: '자체 관리'}
								</Table.Cell>
							</Table.Row>
							<Table.Row textAlign='center'>
								<Table.Cell colSpan='2' style={{ width: '95%' }}>
									월 서비스 구독료 합계
								</Table.Cell>
								<Table.Cell style={{ width: '25%' }}>
									{numberToCommaString(totalPrice)} 원
								</Table.Cell>
							</Table.Row>
						</Table.Body>
					</Table>
					<p>
						② '갑'은 "을"에게{' '}
						<span
							style={{
								color: 'blue',
								fontSize: '1.3em', // 1.3 times the current font size
							}}>
							매월 {payDay}
						</span>
						에 월 서비스 구독료 합계액
						<span
							style={{
								color: 'blue',
								fontSize: '1.3em', // 1.3 times the current font size
							}}>
							{' '}
							{numberToCommaString(totalPrice * 1.1)}원(V.A.T. 포함)
						</span>
						을 지급한다.
					</p>
					{companyProductEvent ? (
						<p>
							<>
								③ '갑'은 "을"에게 최초 임금의 구성항목, 계산방법, 소정근로시간
								등 노무제도 설계 및 직원 등록
								<span
									style={{
										color: 'blue',
										fontSize: '1.3em', // 1.3 times the current font size
									}}>
									Setting 비용{' '}
									{numberToCommaString(
										discountedSettingPriceV * 1.1 * eventDiscountRate
									)}
									원(V.A.T.포함){''}
								</span>
								<span
									style={{
										color: 'teal',
										fontSize: '1.1em', // 1.3 times the current font size
									}}>
									('갑' 상품 이벤트 적용[정상 세팅비(
									{numberToCommaString(discountedSettingPriceV * 1.1)}
									))원의 40%])를 계약과 동시에 지급하고, 나머지{' '}
									{numberToCommaString(
										discountedSettingPriceV * 1.1 * eventProductRate
									)}
									원은 "갑"에게 "을"의 상품 및 서비스를 현물로 제공한다.
								</span>
							</>
						</p>
					) : (
						<p>
							{specialContractSettingPrice ? (
								<>
									③ '갑'은 "을"에게 최초 임금의 구성항목, 계산방법, 소정근로시간{' '}
									<span
										style={{
											color: 'blue',
											fontSize: '1.3em', // 1.3 times the current font size
										}}>
										설계 및 Setting 비용{' '}
										{numberToCommaString(
											commaStringToNumber(specialContractSettingPrice) * 1.1
										)}
										원(V.A.T.포함)
									</span>
									을 계약과 동시에 지급한다.
								</>
							) : (
								<>
									③ '갑'은 "을"에게 최초 임금의 구성항목, 계산방법, 소정근로시간{' '}
									<span
										style={{
											color: 'blue',
											fontSize: '1.3em', // 1.3 times the current font size
										}}>
										설계 및 Setting 비용{' '}
										{numberToCommaString(
											commaStringToNumber(calculateSettingPriceV) * 1.1
										)}
										원(V.A.T.포함)
									</span>
									을 계약과 동시에 지급한다.
								</>
							)}
						</p>
					)}
					<br />
					{calculatePackagePriceV[0] > 0 || calculateTotalPriceV[0] > 0 ? (
						<p>
							④ '갑'은 "을"에게 급여작업 공제 확인 및 지급 확인 후에 수정할 경우
							작업 1회당
							<span
								style={{
									color: 'blue',
									fontSize: '1.3em', // 1.3 times the current font size
								}}>
								{' '}
								33,000원(V.A.T. 포함)
							</span>
							을 추가 지급한다.
						</p>
					) : null}

					{calculatePackagePriceV[0] > 0 || calculateTotalPriceV[0] > 0 ? (
						<p>
							⑤ '갑'은 "을"에게 제2항에도 불구하고 급여작업 인원이{' '}
							{startNumOfWorkers} 이상인 경우 추가{' '}
							<span
								style={{
									color: 'blue',
									fontSize: '1.3em', // 1.3 times the current font size
								}}>
								{' '}
								1인당 {numberToCommaString(plusWorkerPrice * 1.1)}원(V.A.T.
								포함)
							</span>
							원을 추가 지급한다.
						</p>
					) : null}
					{/* ①②③④⑤⑥⑦⑧⑨⑩ */}

					<h3>제 3 조 서비스 보수 지급 방법</h3>
					<p>
						① 전조의 보수는 “을”에게 “갑”이 시스템과 서비스를 이용할 권리를
						획득하는 것으로 노동법률 지식 서비스 특성상, 매달 선지급하며, 당
						계약체결과 동시에 아래 입금계좌로 당월 보수를 입금하고, “갑”과
						“을”이 합의한 경우, CMS 자동이체(붙임참조)를 통해 입금한다.
					</p>
					<br />
					<p>
						②
						<span
							style={{
								color: 'blue',
								fontSize: '1.3em', // 1.3 times the current font size
							}}>
							{' '}
							{companyRegion !== '서울'
								? companyBankAccountSeajong[0]
								: companyBankAccountSeoul[0]}
							,{' '}
							{companyRegion !== '서울'
								? companyBankAccountSeajong[1]
								: companyBankAccountSeoul[1]}
						</span>
					</p>
					<h3>제 4조 계약 기간</h3>
					<p>
						① 본 계약의 유효기간은
						<span
							style={{
								color: 'blue',
								fontSize: '1.3em', // 1.3 times the current font size
							}}>
							{' '}
							{formattedStartDate}부터 {formattedEndDate}
						</span>
						으로 하며, 계약종료일 30일전 까지 당사자 일방이 별다른 의사를
						표시하지 아니하는 경우 본 계약은 종료일로부터 동일기간으로
						자동갱신된 것으로 본다.
					</p>
					<br />
					<p>
						② 제1항의 자동 갱신될 때 기간은 동일한 기간으로 갱신하되, 제2조의
						서비스 비용은 인원수 및 이용하는 서비스 범위에 따라 변경된다.
					</p>
					<h3>제5조 정당한 계약 해지</h3>
					<p>
						① ‘을’이 다음 각 호의 사유가 발생하였을 경우에는 ‘갑’은 본 계약을
						해지할 수 있다. <br />
						1. ‘갑’의 경영 및 영업비밀을 ‘갑’의 동의 또는 협의 없이 무단으로
						누설하였을 때 <br />
						2. 제1조 제2항의 수임사무를 특별한 이유 없이 고의적으로 해태하거나
						이행하지 않았을 때 <br />
						3. ‘을’이 사업운영을 포기하여 폐업 등의 사유로 사업운영을 하지않는
						사유가 발생하였을 때
					</p>
					<p>
						② ‘갑’이 다음 각 호의 사유가 발생하였을 경우에는 ‘을’은 본 계약을
						해지할 수 있다. <br />
						1. ‘갑’이 제2조의 자문보수를 정당한 사유 없이 2개월 이상 납부하지
						않았을 때 <br />
						2. 공인노무사법에서 정하는 노무사의 업무영역을 벗어난 행위를
						요구하였을 때 <br />
						3. ‘을’이 수임사무를 처리하기 위하여 필요한 자료를 정당한 이유 없이
						제공하지 않아, 정상적인 수임사무를 처리 하는 것이 곤란하게 되었을 때
						<br />
						4. ‘갑’이 본 계약에서 정한 수임사무 이외의 사항을 요구하였을 때{' '}
						<br />
						5. ‘갑’이 사업운영을 포기하여 폐업 등의 사유로 사업운영을 하지않는
						사유가 발생하였을 때
					</p>
					<br />
					{discountRate && !specialContractMonthPrice && (
						<>
							<h3>제6조 할인 및 위약 예정 특약 사항</h3>
							<p>
								① ‘갑’이 매월 지급해야 하는 ￦
								{numberToCommaString(totalPrice * 1.1)}
								원(V.A.T. 포함)는
								<span
									style={{
										color: 'blue',
										fontSize: '1.3em', // 1.3 times the current font size
									}}>
									{' '}
									{profile?.recommenderPersonName}의 추천으로{' '}
									{profile?.discountRate}를 할인한 금액 ￦
									{numberToCommaString(totalPrice * 1.1 - monthDiscount)}으로
									할인
								</span>
								적용한다.
								{/* <br /> */}
								<span
									style={{
										color: 'blue',
										fontSize: '1.3em', // 1.3 times the current font size
									}}>
									{' '}
									(매월 {numberToCommaString(monthDiscount)}원 *{' '}
									{monthDifference}
									개월 총 {numberToCommaString(monthDiscount * monthDifference)}
									원 할인).
								</span>
							</p>
							<br />
							<p>
								② “갑”이 본 계약기간 내에 제5조의 정당한 해지사유가 아닌
								계약파기를 하는 경우 위 ①항의 할인은 무효가 되고, ‘갑’은
								계약해지와 동시에 ①항의 할인 금액을 “을”에게 지급한다.
							</p>
						</>
					)}
					{specialContractMonthPrice && (
						<>
							<h3>제6조 할인 및 위약 예정 특약 사항</h3>
							<p>
								① ‘갑’이 매월 지급해야 하는 ￦
								{numberToCommaString(totalPrice * 1.1)}
								원(V.A.T. 포함)는
								<span
									style={{
										color: 'blue',
										fontSize: '1.3em', // 1.3 times the current font size
									}}>
									{' '}
									{profile?.recommenderPersonName}의 추천으로 ￦
									{numberToCommaString(specialContractMonthPrice * 1.1)}으로
									할인
								</span>
								적용한다.
								<span
									style={{
										color: 'blue',
										fontSize: '1.3em', // 1.3 times the current font size
									}}>
									{' '}
									(매월{' '}
									{numberToCommaString(
										totalPrice * 1.1 - specialContractMonthPrice
									)}
									원 * {monthDifference}
									개월 총{' '}
									{numberToCommaString(
										(totalPrice * 1.1 - specialContractMonthPrice) *
											monthDifference
									)}
									원 할인).
								</span>
							</p>
							<br />
							{specialContractSettingPrice && (
								<p>
									② ‘갑’이 지급해야 하는 세팅비 ￦
									{numberToCommaString(
										commaStringToNumber(calculateSettingPriceV) * 1.1
									)}
									원(V.A.T. 포함)는
									<span
										style={{
											color: 'blue',
											fontSize: '1.3em', // 1.3 times the current font size
										}}>
										{' '}
										{profile?.recommenderPersonName}의 추천으로 ￦
										{numberToCommaString(specialContractSettingPrice * 1.1)}으로
										할인
									</span>
									적용한다.
									<span
										style={{
											color: 'blue',
											fontSize: '1.3em', // 1.3 times the current font size
										}}>
										{' '}
										(세팅비 : ￦{numberToCommaString(settingDC)}원 할인).
									</span>
								</p>
							)}
							<br />
							{specialContractSettingPrice ? (
								<p>
									③ “갑”이 본 계약기간 내에 제5조의 정당한 해지사유가 아닌
									계약파기를 하는 경우 위 ①, ②항의 할인은 무효가 되고, ‘갑’은
									계약해지와 동시에 ①, ②항의 할인 금액을 “을”에게 지급한다.
								</p>
							) : (
								<p>
									② “갑”이 본 계약기간 내에 제5조의 정당한 해지사유가 아닌
									계약파기를 하는 경우 위 ①항의 할인은 무효가 되고, ‘갑’은
									계약해지와 동시에 ①항의 할인 금액을 “을”에게 지급한다.
								</p>
							)}
						</>
					)}
					<h3>날짜</h3>
					<Table celled style={{ width: '95%', lineHeight: '1' }}>
						{' '}
						{/* 테이블 줄 간격 조정 */}
						<Table.Body>
							<Table.Row textAlign='center'>
								<Table.Cell style={{ textAlign: 'center' }}>
									{format(new Date(), 'yyyy-MM-dd')}
								</Table.Cell>
							</Table.Row>
						</Table.Body>
					</Table>
					<h3>서명</h3>
					<Table celled style={{ width: '95%', lineHeight: '1' }}>
						<Table.Body>
							<Table.Row textAlign='center'>
								<Table.Cell>'갑'</Table.Cell>
								<Table.Cell>{profile?.companyName}</Table.Cell>
								<Table.Cell>'을'</Table.Cell>
								<Table.Cell>
									{companyRegion === '서울'
										? '노무법인 최상인업'
										: '노무법인 최상인업 세종본부'}
								</Table.Cell>
							</Table.Row>
							<Table.Row textAlign='center'>
								<Table.Cell>대표자</Table.Cell>
								<Table.Cell>{profile?.ceoName} (인)</Table.Cell>
								<Table.Cell>대표자</Table.Cell>
								<Table.Cell>김 경 모 (인)</Table.Cell>
							</Table.Row>
						</Table.Body>
					</Table>
				</div>
			</div>
		</>
	);
}
