import React from 'react';
import NewChatButton from './NewChatButton';
import ListItem from './ListItem';
import { useDispatch, useSelector } from 'react-redux';
import { setSelectedConversationId } from '../../eventActions';

function formatDateLabel(dateString) {
  const date = new Date(dateString);
  const today = new Date();
  const yesterday = new Date(today);
  yesterday.setDate(yesterday.getDate() - 1);

  if (date.toDateString() === today.toDateString()) {
    return "오늘";
  } else if (date.toDateString() === yesterday.toDateString()) {
    return "어제";
  } else {
    return date.toLocaleDateString(); // "그 이전" 날짜는 기본 날짜 형식으로 표시
  }
}

function Sidebar() {
  const dispatch = useDispatch();
  const conversations = useSelector(state => state.eventStore.conversations) || {};

  const handleSetSelectedChat = (id) => {
    dispatch(setSelectedConversationId(id));
  };

  // 대화 목록을 날짜순으로 정렬
  const sortedConversationIds = Object.keys(conversations).sort((a, b) => {
    const lastMessageA = conversations[a][Object.keys(conversations[a]).pop()].timestamp;
    const lastMessageB = conversations[b][Object.keys(conversations[b]).pop()].timestamp;
    return new Date(lastMessageB) - new Date(lastMessageA);
  });

  let lastDisplayedDate = '';

  return (
    <div className='sidebar_container'>
      <NewChatButton handleSetSelectedChat={handleSetSelectedChat} />
      {sortedConversationIds.map((id, index) => {
        const firstMessage = conversations[id][Object.keys(conversations[id])[0]];
        const title = firstMessage.userMessage || "Conversation";
        const timestamp = firstMessage.timestamp;

        let displayDateSection = null;
        if (timestamp) {
          const currentDate = new Date(timestamp).toISOString().split('T')[0];
          const displayLabel = formatDateLabel(timestamp);

          if (currentDate !== lastDisplayedDate) {
            lastDisplayedDate = currentDate;
            displayDateSection = <div className="date_section">{displayLabel}</div>;
          }
        }

        return (
          <React.Fragment key={id}>
            {displayDateSection}
            <ListItem 
              title={title}
              conversationId={id}
              handleSetSelectedChat={handleSetSelectedChat}
            />
          </React.Fragment>
        );
      })}
    </div>
  );
}

export default Sidebar;
