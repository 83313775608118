import React, { useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Redirect, useHistory } from 'react-router-dom';
import useFirestoreDoc from '../../../app/hooks/useFirestoreDoc';
import LoadingComponent from '../../../app/layout/LoadingComponent';
import {
	listenToClientReq4InFromFirestore,
	listenToPaydocuFromFirestore,
} from '../../../app/firestore/firestoreService4In';
import { listenToSelectedPaydocu } from '../companyActions';
import PaydocuDetailedHeader from './PaydocuDetailedHeader';
import PaydocuDetailedInHead from './PaydocuDetailedInHead';
import { Button, Grid, Table } from 'semantic-ui-react';
import {
	dayOrdIncludeComment4,
	dayOrdIncludeComment5,
	numberToCommaString,
	printWageTable,
	roundUp,
} from '../../../app/common/util/util';
import { format } from 'date-fns';
import PaydocuPayDetail from './PaydocuPayDetail';
import { listenToSelectedClientReq4In } from '../../clientsRequest/clientReq4In/clientReqActions4In';
import PaydocuPrintOneTable from './PaydocuPrintOneTable';
import PaydocuDetailedPageBasicDayTimeWorker from './PaydocuDetailedPageBasicDayTimeWorker';
import PaydocuDetailedPageBasicRegularWorker from './PaydocuDetailedPageBasicRegularWorker';
import PaydocuDetailedPageBSC from './PaydocuDetailedPageBSC';
import PaydocuDetailedPageWorkDayPlusTime from './PaydocuDetailedPageWorkDayPlusTime';
import moment from 'moment';
import { extendMoment } from 'moment-range';

export default function PaydocuDetailedPage({ match }) {
	const htmlContentRef = useRef(null);

	const history = useHistory();
	const dispatch = useDispatch();
	const { selectedPaydocu, clientInfo, superAdminForFreeTrue } = useSelector(
		(state) => state.companyReducer
	);
	const { loading, error } = useSelector((state) => state.async);
	const { currentUserProfile } = useSelector((state) => state.profile);
	const { selectedClientReq4In } = useSelector(
		(state) => state.clientReq4InStore
	);

	const isBizWorker = selectedClientReq4In?.isBizWorker ? true : false;

	useFirestoreDoc({
		query: () => {
			const queryResult = listenToPaydocuFromFirestore(match.params.id);
			return queryResult;
		},
		data: (selectedPaydocu) =>
			dispatch(listenToSelectedPaydocu(selectedPaydocu)),
		deps: [match.params.id, dispatch],
	});

	useFirestoreDoc({
		query: () => {
			if (selectedPaydocu?.workerInfo?.workerId) {
				return listenToClientReq4InFromFirestore(
					selectedPaydocu.workerInfo?.workerId
				);
			} else {
				return null;
			}
		},
		data: (selectedClientReq4In) =>
			dispatch(listenToSelectedClientReq4In(selectedClientReq4In)),
		deps: [selectedPaydocu?.workerInfo?.workerId, dispatch],
	});

	const centerStyle = { textAlign: 'center', width: '8%', height: 'auto' };
	const inputWageStyle = {
		border: 'none',
		borderRight: '0px',
		borderTop: '0px',
		borderLeft: '0px',
		borderBottom: '0px',
		textAlign: 'right',
		width: '7rem',
	};
	const inputCommentStyle = {
		resize: 'none',
		height: '20px',
		border: 'none',
		borderRight: '0px',
		borderTop: '0px',
		borderLeft: '0px',
		borderBottom: '0px',
		textAlign: 'left',
		width: '50rem',
	};

	const inputCommentStyleWide = {
		resize: 'none',
		height: '70px',
		border: 'none',
		borderRight: '0px',
		borderTop: '0px',
		borderLeft: '0px',
		borderBottom: '0px',
		textAlign: 'left',
		width: '50rem',
	};

	const worker = selectedPaydocu?.workerInfo?.worker;
	const startToEndDate = selectedPaydocu?.workerInfo?.startToEndDate;
	const payDay = selectedPaydocu?.workerInfo?.payDay;
	const workerSocialNumberFront =
		selectedPaydocu?.workerInfo?.workerSocialNumberFront;

	const companyName = selectedPaydocu?.companyInfo?.companyName;
	const numOfWorkers = selectedPaydocu?.companyInfo?.numOfWorkers;

	const month = selectedPaydocu?.info?.month;
	const year = selectedPaydocu?.info?.year;

	const monthOWT = selectedPaydocu?.lawOverTimeWage?.fixedOTime;
	const monthNWT = selectedPaydocu?.lawOverTimeWage?.fixedNTime;
	const monthHoliBasicTime = selectedPaydocu?.lawOverTimeWage?.fixedHBTime;
	const monthOWTHoli = selectedPaydocu?.lawOverTimeWage?.fixedHOTime;
	const monthNWTHoli = selectedPaydocu?.lawOverTimeWage?.fixedHNTime;
	const plusMonthOWT = selectedPaydocu?.lawOverTimeWage?.plusOTime;
	const plusMonthNWT = selectedPaydocu?.lawOverTimeWage?.plusNTime;
	const plusMonthHoliBasicTime = selectedPaydocu?.lawOverTimeWage?.plusHBTime;
	const plusMonthOWTHoli = selectedPaydocu?.lawOverTimeWage?.plusHOTime;
	const plusMonthNWTHoli = selectedPaydocu?.lawOverTimeWage?.plusHNTime;

	const sumOfCOWDay = selectedPaydocu?.addDayOrdiWage?.sumOfCOWDay;
	const workingDays = selectedPaydocu?.addDayOrdiWage?.workingDays;
	const workDayOTimePaidWage =
		selectedPaydocu?.lawOverTimeWage?.workDayOTimePaidWage;
	const workDayNTimePaidWage =
		selectedPaydocu?.lawOverTimeWage?.workDayNTimePaidWage;
	const holiBTimePaidWage = selectedPaydocu?.lawOverTimeWage?.holiBTimePaidWage;
	const holiOTimePaidWage = selectedPaydocu?.lawOverTimeWage?.holiOTimePaidWage;
	const holiNTimePaidWage = selectedPaydocu?.lawOverTimeWage?.holiNTimePaidWage;

	const monthLBTime = selectedPaydocu?.fixedWage?.monthLBTime;
	const hourOrdinaryWage = selectedPaydocu?.fixedWage?.hourOrdinaryWage;
	const weekLBTime = selectedPaydocu?.fixedWage?.weekLBTime;
	const paidHolidayLBTime = selectedPaydocu?.fixedWage?.paidHolidayLBTime;
	const monthOrdinaryWage = selectedPaydocu?.fixedWage?.monthOrdinaryWage;
	const mTPCTime = selectedPaydocu?.fixedWage?.mTPCTime;
	const monthBasicWage = selectedPaydocu?.fixedWage?.basicWage;
	const monthBasicWageComment = selectedPaydocu?.fixedWage?.basicWageComment;
	const cTFMealWage = selectedPaydocu?.fixedWage?.mealWage;
	const cTFMealWageComment = selectedPaydocu?.fixedWage?.mealWageComment;
	const cTFCarWage = selectedPaydocu?.fixedWage?.carWage;
	const cTFCarWageComment = selectedPaydocu?.fixedWage?.carWageComment;

	const cTFChildWage = selectedPaydocu?.fixedWage?.childWage;
	const cTFStudyWage = selectedPaydocu?.fixedWage?.studyWage;
	const cTFEtc = selectedPaydocu?.fixedWage?.tFEtcWage;
	const cOWTitle = selectedPaydocu?.fixedWage?.titleWage;
	const cOWJob = selectedPaydocu?.fixedWage?.jobWage;
	const cOWLicense = selectedPaydocu?.fixedWage?.licenseWage;
	const cOWLongTerm = selectedPaydocu?.fixedWage?.longTermWage;
	const cOWMonthlyBonus = selectedPaydocu?.fixedWage?.monthlyBonusWage;
	const cOWEtc = selectedPaydocu?.fixedWage?.oWEtcWage;

	const cOWChildManageStudyWage =
		selectedPaydocu?.fixedWage?.cOWChildManageStudyWage;

	const cOWChildManageStudyWageComment =
		selectedPaydocu?.fixedWage?.cOWChildManageStudyComment;
	const cOWChildStudyWage = selectedPaydocu?.fixedWage?.cOWChildStudyWage;
	const cOWChildStudyWageComment =
		selectedPaydocu?.fixedWage?.cOWChildStudyComment;
	const cOWChildCareWage = selectedPaydocu?.fixedWage?.cOWChildCareWage;
	const cOWChildCareWageComment =
		selectedPaydocu?.fixedWage?.cOWChildCareComment;
	const cOWChildActivityWage = selectedPaydocu?.fixedWage?.cOWChildActivityWage;
	const cOWChildActivityWageComment =
		selectedPaydocu?.fixedWage?.cOWChildActivityComment;

	const cTFChildWageComment = selectedPaydocu?.fixedWage?.childWageComment;
	const cTFStudyWageComment = selectedPaydocu?.fixedWage?.studyWageComment;
	const cTFEtcComment = selectedPaydocu?.fixedWage?.tFEtcComment;
	const cOWTitleComment = selectedPaydocu?.fixedWage?.titleComment;
	const cOWJobComment = selectedPaydocu?.fixedWage?.jobComment;
	const cOWLicenseComment = selectedPaydocu?.fixedWage?.licenseComment;
	const cOWLongTermComment = selectedPaydocu?.fixedWage?.longTermComment;
	const cOWMonthlyBonusComment =
		selectedPaydocu?.fixedWage?.monthlyBonusComment;
	const cOWEtcComment = selectedPaydocu?.fixedWage?.oWEtcComment;

	const cAWage1 = selectedPaydocu?.fixedWage?.aWage1Wage;
	const cAWage2 = selectedPaydocu?.fixedWage?.aWage2Wage;
	const cAWage3 = selectedPaydocu?.fixedWage?.aWage3Wage;
	const cAWage4 = selectedPaydocu?.fixedWage?.aWage4Wage;
	const cAWEName1 = selectedPaydocu?.fixedWage?.aWEName1;
	const cAWEName2 = selectedPaydocu?.fixedWage?.aWEName2;
	const cAWEName3 = selectedPaydocu?.fixedWage?.aWEName3;
	const cAWEName4 = selectedPaydocu?.fixedWage?.aWEName4;
	const cAWage1Comment = selectedPaydocu?.fixedWage?.aWage1Comment;
	const cAWage2Comment = selectedPaydocu?.fixedWage?.aWage2Comment;
	const cAWage3Comment = selectedPaydocu?.fixedWage?.aWage3Comment;
	const cAWage4Comment = selectedPaydocu?.fixedWage?.aWage4Comment;

	const cAWageTF1 = selectedPaydocu?.fixedWage?.aWageTF1Wage;
	const cAWageTF2 = selectedPaydocu?.fixedWage?.aWageTF2Wage;
	const cAWageTF3 = selectedPaydocu?.fixedWage?.aWageTF3Wage;
	const cAWageTF4 = selectedPaydocu?.fixedWage?.aWageTF4Wage;
	const cAWENameTF1 = selectedPaydocu?.fixedWage?.aWENameTF1;
	const cAWENameTF2 = selectedPaydocu?.fixedWage?.aWENameTF2;
	const cAWENameTF3 = selectedPaydocu?.fixedWage?.aWENameTF3;
	const cAWENameTF4 = selectedPaydocu?.fixedWage?.aWENameTF4;
	const cAWageTF1Comment = selectedPaydocu?.fixedWage?.aWageTF1Comment;
	const cAWageTF2Comment = selectedPaydocu?.fixedWage?.aWageTF2Comment;
	const cAWageTF3Comment = selectedPaydocu?.fixedWage?.aWageTF3Comment;
	const cAWageTF4Comment = selectedPaydocu?.fixedWage?.aWageTF4Comment;

	const userFixedCAWageTF1 = selectedPaydocu?.fixedWage?.userFixedCAWageTF1;
	const userFixedCAWageTF2 = selectedPaydocu?.fixedWage?.userFixedCAWageTF2;
	const userFixedCAWageTF3 = selectedPaydocu?.fixedWage?.userFixedCAWageTF3;
	const userFixedCAWageTF4 = selectedPaydocu?.fixedWage?.userFixedCAWageTF4;
	const userFixedCAWageTF1Name =
		selectedPaydocu?.fixedWage?.userFixedCAWageTF1Name;
	const userFixedCAWageTF2Name =
		selectedPaydocu?.fixedWage?.userFixedCAWageTF2Name;
	const userFixedCAWageTF3Name =
		selectedPaydocu?.fixedWage?.userFixedCAWageTF3Name;
	const userFixedCAWageTF4Name =
		selectedPaydocu?.fixedWage?.userFixedCAWageTF4Name;
	const userFixedCAWageTF1Comment =
		selectedPaydocu?.fixedWage?.userFixedCAWageTF1Comment;
	const userFixedCAWageTF2Comment =
		selectedPaydocu?.fixedWage?.userFixedCAWageTF2Comment;
	const userFixedCAWageTF3Comment =
		selectedPaydocu?.fixedWage?.userFixedCAWageTF3Comment;
	const userFixedCAWageTF4Comment =
		selectedPaydocu?.fixedWage?.userFixedCAWageTF4Comment;

	const userFixedCAWage1 = selectedPaydocu?.fixedWage?.userFixedCAWage1;
	const userFixedCAWage2 = selectedPaydocu?.fixedWage?.userFixedCAWage2;
	const userFixedCAWage3 = selectedPaydocu?.fixedWage?.userFixedCAWage3;
	const userFixedCAWage4 = selectedPaydocu?.fixedWage?.userFixedCAWage4;
	const userFixedCAWage1Name = selectedPaydocu?.fixedWage?.userFixedCAWage1Name;
	const userFixedCAWage2Name = selectedPaydocu?.fixedWage?.userFixedCAWage2Name;
	const userFixedCAWage3Name = selectedPaydocu?.fixedWage?.userFixedCAWage3Name;
	const userFixedCAWage4Name = selectedPaydocu?.fixedWage?.userFixedCAWage4Name;
	const userFixedCAWage1Comment =
		selectedPaydocu?.fixedWage?.userFixedCAWage1Comment;
	const userFixedCAWage2Comment =
		selectedPaydocu?.fixedWage?.userFixedCAWage2Comment;
	const userFixedCAWage3Comment =
		selectedPaydocu?.fixedWage?.userFixedCAWage3Comment;
	const userFixedCAWage4Comment =
		selectedPaydocu?.fixedWage?.userFixedCAWage4Comment;
	const sumOfLawOverWage = selectedPaydocu?.fixedWage?.sumOfLawOverWage
		? selectedPaydocu?.fixedWage?.sumOfLawOverWage
		: '';

	const plusRegularBonus = selectedPaydocu?.addAverWage?.plusRegularBonus;
	const plusRegularBonusComment =
		selectedPaydocu?.addAverWage?.plusRegularBonusComment;
	const plusIrregularBonus = selectedPaydocu?.addAverWage?.plusIrregularBonus;
	const plusIrregularBonusComment =
		selectedPaydocu?.addAverWage?.plusIrregularBonusComment;
	const plusUnusedAnnualLeavesWage =
		selectedPaydocu?.addAverWage?.plusUnusedAnnualLeavesWage;
	const plusRetiredUnusedAnnualLeaves =
		selectedPaydocu?.plusRetiredUnusedAnnualLeaves;
	const plusSpecialRandomWage = selectedPaydocu?.plusSpecialRandomWage;

	const plusUnusedAnnualLeavesWageComment =
		selectedPaydocu?.addAverWage?.plusUnusedAnnualLeavesWageComment;
	const plusLastMonthAverWage =
		selectedPaydocu?.addAverWage?.plusLastMonthAverWage;
	const plusLastMonthAverWageComment =
		selectedPaydocu?.addAverWage?.plusLastMonthAverWageComment;
	const plusPersonalIncentive =
		selectedPaydocu?.addAverWage?.plusPersonalIncentive;
	const plusPersonalIncentiveComment =
		selectedPaydocu?.addAverWage?.plusPersonalIncentiveComment;

	const cAWage5 = selectedPaydocu?.addAverWage?.aWage5Wage;
	const cAWage6 = selectedPaydocu?.addAverWage?.aWage6Wage;
	const cAWage7 = selectedPaydocu?.addAverWage?.aWage7Wage;
	const cAWEName5 = selectedPaydocu?.addAverWage?.aWEName5;
	const cAWEName6 = selectedPaydocu?.addAverWage?.aWEName6;
	const cAWEName7 = selectedPaydocu?.addAverWage?.aWEName7;
	const cAWage5Comment = selectedPaydocu?.addAverWage?.aWage5Comment;
	const cAWage6Comment = selectedPaydocu?.addAverWage?.aWage6Comment;
	const cAWage7Comment = selectedPaydocu?.addAverWage?.aWage7Comment;

	const perDayMealTaxFree = selectedPaydocu?.addAverWage?.perDayMealTaxFree;
	const plusIrregularDayMealTFDays =
		selectedPaydocu?.addAverWage?.plusIrregularDayMealTFDays;
	const plusIrregularDayMealTFWage =
		selectedPaydocu?.addAverWage?.plusIrregularDayMealTFWage;

	const deductEtcAWage1 = selectedPaydocu?.deductTaxAnd4In?.numDeductEtcAWage1;
	const deductEtcAWage2 = selectedPaydocu?.deductTaxAnd4In?.numDeductEtcAWage2;
	const deductEtcAWage3 = selectedPaydocu?.deductTaxAnd4In?.numDeductEtcAWage3;
	const deductEtcName1 = selectedPaydocu?.deductTaxAnd4In?.deductEtcName1;
	const deductEtcName2 = selectedPaydocu?.deductTaxAnd4In?.deductEtcName2;
	const deductEtcName3 = selectedPaydocu?.deductTaxAnd4In?.deductEtcName3;
	const deductEtcWage1Comment =
		selectedPaydocu?.deductTaxAnd4In?.deductEtcWage1Comment;
	const deductEtcWage2Comment =
		selectedPaydocu?.deductTaxAnd4In?.deductEtcWage2Comment;
	const deductEtcWage3Comment =
		selectedPaydocu?.deductTaxAnd4In?.deductEtcWage3Comment;

	const userFixedTax = selectedPaydocu?.deductTaxAnd4In?.userFixedTax;
	const userFixedRegionTax =
		selectedPaydocu?.deductTaxAnd4In?.userFixedRegionTax;
	const userFixedHealthCare =
		selectedPaydocu?.deductTaxAnd4In?.userFixedHealthCare;
	const userFixedOldCare = selectedPaydocu?.deductTaxAnd4In?.userFixedOldCare;
	const userFixedPension = selectedPaydocu?.deductTaxAnd4In?.userFixedPension;
	const userFixedNoJobInsurance =
		selectedPaydocu?.deductTaxAnd4In?.userFixedNoJobInsurance;
	const userFixedTaxComment =
		selectedPaydocu?.deductTaxAnd4In?.userFixedTaxComment;
	const userFixedRegionTaxComment =
		selectedPaydocu?.deductTaxAnd4In?.userFixedRegionTaxComment;
	const userFixedHealthCareComment =
		selectedPaydocu?.deductTaxAnd4In?.userFixedHealthCareComment;
	const userFixedOldCareComment =
		selectedPaydocu?.deductTaxAnd4In?.userFixedOldCareComment;
	const userFixedPensionComment =
		selectedPaydocu?.deductTaxAnd4In?.userFixedPensionComment;
	const userFixedNoJobInsuranceComment =
		selectedPaydocu?.deductTaxAnd4In?.userFixedNoJobInsuranceComment;
	const usedAnnualLeaves = selectedPaydocu?.usedAnnualLeaves;
	const usedAnnualLeaveDates = selectedPaydocu?.usedAnnualLeaveDates;

	const daysOfInterInAbusent =
		selectedPaydocu?.absentMinusWage?.daysOfInterInAbusent;
	const daysOfInterOutAbusent =
		selectedPaydocu?.absentMinusWage?.daysOfInterOutAbusent;

	const daysOfInterInAbusentOrdinaryWage =
		selectedPaydocu?.absentMinusWage?.daysOfInterInAbusentOrdinaryWage;
	const daysOfInterInAbusentLawOverWage =
		selectedPaydocu?.absentMinusWage?.daysOfInterInAbusentLawOverWage;
	const daysOfInterOutAbusentOrdinaryWage =
		selectedPaydocu?.absentMinusWage?.daysOfInterOutAbusentOrdinaryWage;
	const daysOfInterOutAbusentLawOverWage =
		selectedPaydocu?.absentMinusWage?.daysOfInterOutAbusentLawOverWage;
	const daysInMonth = selectedPaydocu?.absentMinusWage?.daysInMonth;

	const absentDays = selectedPaydocu?.absentMinusWage?.absentDays;

	const absentPaidHoliDays =
		selectedPaydocu?.absentMinusWage?.absentPaidHoliDays;

	const absentTimes = selectedPaydocu?.absentMinusWage?.absentTimes;
	const absentDates = selectedPaydocu?.absentMinusWage?.absentDates;
	const absentDaysLawOverWage =
		selectedPaydocu?.absentMinusWage?.absentDaysLawOverWage;

	const absentPaidHoliDates =
		selectedPaydocu?.absentMinusWage?.absentPaidHoliDates;

	const absentTimeDates = selectedPaydocu?.absentMinusWage?.absentTimeDates;

	const maternityFinalDedectLawOverWage =
		selectedPaydocu?.absentMinusWage?.maternityFinalDedectLawOverWage;
	const maternityFinalDedectOrdWage =
		selectedPaydocu?.absentMinusWage?.maternityFinalDedectOrdWage;
	const maternityDeductWage =
		selectedPaydocu?.absentMinusWage?.maternityDeductWage;

	const enteredDate = selectedPaydocu?.workerInfo?.workerEnteredDate;
	const workerRetiredDate = selectedPaydocu?.workerInfo?.workerRetiredDate;

	const workerEnteredDate = enteredDate
		? format(new Date(enteredDate.toString()), 'yyyy-MM-dd')
		: '';
	const workerEnteredDateYear = enteredDate
		? format(new Date(enteredDate.toString()), 'yyyy')
		: '';
	const workerEnteredDateMonth = enteredDate
		? format(new Date(enteredDate.toString()), 'MM')
		: '';
	const workerEnteredDateDay = enteredDate
		? format(new Date(enteredDate.toString()), 'dd')
		: '';
	const isInterInWorker =
		workerEnteredDateMonth === month &&
		workerEnteredDateYear === year &&
		workerEnteredDateDay !== '01'
			? true
			: false;

	const calTax = selectedPaydocu?.deductTaxAnd4In?.calTax;
	const calRegionTax = selectedPaydocu?.deductTaxAnd4In?.calRegionTax;
	const calPension = selectedPaydocu?.deductTaxAnd4In?.calPension;
	const calHealthCare = selectedPaydocu?.deductTaxAnd4In?.calHealthCare;
	const calOldCare = selectedPaydocu?.deductTaxAnd4In?.calOldCare;
	const calNoJobInsurance = selectedPaydocu?.deductTaxAnd4In?.calNoJobInsurance;
	const dedectTaxEtc = selectedPaydocu?.deductTaxAnd4In?.dedectTaxEtc;
	const deductWage = selectedPaydocu?.info.deductWage;
	const totalMonthWageAmount = selectedPaydocu?.info.totalMonthWageAmount;
	const bizTotalIncome = selectedPaydocu?.bizTotalIncome;
	const bizDeductedTotal = selectedPaydocu?.bizDeductedTotal;
	const bizDeductionAfterTaxComment =
		selectedPaydocu?.bizDeductionAfterTaxComment;
	const bizDeductionAfterTax = selectedPaydocu?.bizDeductionAfterTax;
	const bizDeductWage = selectedPaydocu?.bizDeductWage;
	const bizIncomeTax = selectedPaydocu?.bizIncomeTax;
	const bizIncomeRegionTax = selectedPaydocu?.bizIncomeRegionTax;

	const taxText = selectedPaydocu?.deductTaxAnd4In?.taxText;
	const regionTaxText = selectedPaydocu?.deductTaxAnd4In?.regionTaxText;
	const noJobText = selectedPaydocu?.deductTaxAnd4In?.noJobText;
	const pensionText = selectedPaydocu?.deductTaxAnd4In?.pensionText;
	const oldCareText = selectedPaydocu?.deductTaxAnd4In?.oldCareText;
	const healthCareText = selectedPaydocu?.deductTaxAnd4In?.healthCareText;

	const taxPayType = selectedPaydocu?.deductTaxAnd4In?.taxPayType;
	const calTaxRate = selectedPaydocu?.deductTaxAnd4In?.calTaxRate;
	const pensionPayType = selectedPaydocu?.deductTaxAnd4In?.pensionPayType;
	const pensionBaseWage = selectedPaydocu?.deductTaxAnd4In?.pensionBaseWage;
	const healthCarePayType = selectedPaydocu?.deductTaxAnd4In?.healthCarePayType;
	const healthCareBaseWage =
		selectedPaydocu?.deductTaxAnd4In?.healthCareBaseWage;
	const oldCarePayType = selectedPaydocu?.deductTaxAnd4In?.oldCarePayType;
	const oldCareBaseWage = selectedPaydocu?.deductTaxAnd4In?.oldCareBaseWage;
	const noJobPayType = selectedPaydocu?.deductTaxAnd4In?.noJobPayType;
	const noJobBaseWage = selectedPaydocu?.deductTaxAnd4In?.noJobBaseWage;
	const taxFreeWageSum = selectedPaydocu?.deductTaxAnd4In?.taxFreeWageSum;
	const taxWageSum = selectedPaydocu?.deductTaxAnd4In?.taxWageSum;

	const plusDeductHealthCare =
		selectedPaydocu?.deductTaxAnd4In?.plusDeductHealthCare;
	const plusDeductHealthCareComment =
		selectedPaydocu?.deductTaxAnd4In?.plusDeductHealthCareComment;
	const plusDeductOldCare = selectedPaydocu?.deductTaxAnd4In?.plusDeductOldCare;
	const plusDeductOldCareComment =
		selectedPaydocu?.deductTaxAnd4In?.plusDeductOldCareComment;
	const plusDeductWorkTax = selectedPaydocu?.deductTaxAnd4In?.plusDeductWorkTax;
	const plusDeductWorkTaxComment =
		selectedPaydocu?.deductTaxAnd4In?.plusDeductWorkTaxComment;

	const plusDeductWorkRegionTax =
		selectedPaydocu?.deductTaxAnd4In?.plusDeductWorkRegionTax;
	const plusDeductWorkRegionTaxComment =
		selectedPaydocu?.deductTaxAnd4In?.plusDeductWorkRegionTaxComment;
	const plusDeductDuru = selectedPaydocu?.deductTaxAnd4In?.plusDeductDuru;
	const plusMinus4Ins = selectedPaydocu?.deductTaxAnd4In?.plusMinus4Ins;
	const plusDeductDuruComment =
		selectedPaydocu?.deductTaxAnd4In?.plusDeductDuruComment;
	const plusMinus4InsComment =
		selectedPaydocu?.deductTaxAnd4In?.plusMinus4InsComment;
	const plusDeductWorkerCompanyLoan =
		selectedPaydocu?.deductTaxAnd4In?.plusDeductWorkerCompanyLoan;
	const plusDeductWorkerCompanyLoanComment =
		selectedPaydocu?.deductTaxAnd4In?.plusDeductWorkerCompanyLoanComment;

	const numFinalRetireHealthCareRecalFee =
		selectedPaydocu?.deductTaxAnd4In?.finalRetireHealthCareRecalFee;
	const numFinalRetireOldCareRecalFee =
		selectedPaydocu?.deductTaxAnd4In?.finalRetireOldCareRecalFee;

	const numDeductEtcAWage1 =
		selectedPaydocu?.deductTaxAnd4In?.numDeductEtcAWage1;
	const numDeductEtcAWage2 =
		selectedPaydocu?.deductTaxAnd4In?.numDeductEtcAWage2;
	const numDeductEtcAWage3 =
		selectedPaydocu?.deductTaxAnd4In?.numDeductEtcAWage3;

	const dayWorker = selectedPaydocu?.dayWorkerInfo?.dayWorker;
	const dayWorkerWage = selectedPaydocu?.dayWorkerInfo?.dayWorkerWage;
	const dayWokerTimeWage = selectedPaydocu?.dayWorkerInfo?.dayWokerTimeWage;
	const dayWorkerWorkingTimeDayTotal =
		selectedPaydocu?.dayWorkerInfo?.dayWorkerWorkingTimeDayTotal;
	const dayWorkerDayTotalPaidConversedTime =
		selectedPaydocu?.dayWorkerInfo?.dayWorkerDayTotalPaidConversedTime;
	const dayWorkerPaidHolidayLBTime =
		selectedPaydocu?.dayWorkerInfo?.dayWorkerPaidHolidayLBTime;
	const dayWorkerWorkingTimeDayBasic =
		selectedPaydocu?.dayWorkerInfo?.dayWorkerWorkingTimeDayBasic;
	const dayWorkerWorkingTimeDayOver =
		selectedPaydocu?.dayWorkerInfo?.dayWorkerWorkingTimeDayOver;
	const dayWorkerWorkingTimeDayNight =
		selectedPaydocu?.dayWorkerInfo?.dayWorkerWorkingTimeDayNight;
	const dayWorkerHoliWage = selectedPaydocu?.dayWorkerInfo?.dayWorkerHoliWage;
	const dayWorkerHoliPaidTime =
		selectedPaydocu?.dayWorkerInfo?.dayWorkerHoliPaidTime;
	const dayWorkerOverWorkDayWage =
		selectedPaydocu?.dayWorkerInfo?.dayWorkerOverWorkDayWage;
	const dayWorkerOverWorkDayPaidTime =
		selectedPaydocu?.dayWorkerInfo?.dayWorkerOverWorkDayPaidTime;
	const countOfNomalDayWork =
		selectedPaydocu?.dayWorkerInfo?.countOfNomalDayWork;
	const countOfHoliDayWork = selectedPaydocu?.dayWorkerInfo?.countOfHoliDayWork;
	const countOfOverWorkingDayWork =
		selectedPaydocu?.dayWorkerInfo?.countOfOverWorkingDayWork;
	const sumOfCountWorkDays = selectedPaydocu?.dayWorkerInfo?.sumOfCountWorkDays;

	const totalDayWorkerWage = selectedPaydocu?.dayWorkerInfo?.totalDayWorkerWage;
	const totalDayWorkerHoliWage =
		selectedPaydocu?.dayWorkerInfo?.totalDayWorkerHoliWage;
	const totalDayWorkerOverWorkDayWage =
		selectedPaydocu?.dayWorkerInfo?.totalDayWorkerOverWorkDayWage;

	const workDatesPaydocu = selectedPaydocu?.workerInfo?.workDates;

	const dayWorkerPlusOTime = selectedPaydocu?.dayWorkerInfo?.dayWorkerPlusOTime;
	const dayWorkerPlusNTime = selectedPaydocu?.dayWorkerInfo?.dayWorkerPlusNTime;
	const dayWorkerPlusHBTime =
		selectedPaydocu?.dayWorkerInfo?.dayWorkerPlusHBTime;
	const dayWorkerPlusHOTime =
		selectedPaydocu?.dayWorkerInfo?.dayWorkerPlusHOTime;
	const dayWorkerPlusHNTime =
		selectedPaydocu?.dayWorkerInfo?.dayWorkerPlusHNTime;
	const dayWorkerPlusOverTimeDates =
		selectedPaydocu?.dayWorkerInfo?.dayWorkerPlusOverTimeDates;
	const dayWorkerPlusNightTimeDates =
		selectedPaydocu?.dayWorkerInfo?.dayWorkerPlusNightTimeDates;
	const dayWorkerPlusHoliBasicTimeDates =
		selectedPaydocu?.dayWorkerInfo?.dayWorkerPlusHoliBasicTimeDates;
	const dayWorkerPlusHoliOverTimeDates =
		selectedPaydocu?.dayWorkerInfo?.dayWorkerPlusHoliOverTimeDates;
	const dayWorkerPlusHoliNightTimeDates =
		selectedPaydocu?.dayWorkerInfo?.dayWorkerPlusHoliNightTimeDates;
	const dayWorkerAbsentTimes =
		selectedPaydocu?.dayWorkerInfo?.dayWorkerAbsentTimes;
	const dayWorkerAbsentTimeDates =
		selectedPaydocu?.dayWorkerInfo?.dayWorkerAbsentTimeDates;

	const timeWorkerPlusMonthOWT =
		selectedPaydocu?.dayWorkerInfo?.timeWorkerPlusMonthOWT;
	const timeWorkerPlusMonthNWT =
		selectedPaydocu?.dayWorkerInfo?.timeWorkerPlusMonthNWT;
	const timeWorkerPlusMonthHoliBasicTime =
		selectedPaydocu?.dayWorkerInfo?.timeWorkerPlusMonthHoliBasicTime;
	const timeWorkerPlusMonthOWTHoli =
		selectedPaydocu?.dayWorkerInfo?.timeWorkerPlusMonthOWTHoli;
	const timeWorkerPlusMonthOffBasicTime =
		selectedPaydocu?.dayWorkerInfo?.timeWorkerPlusMonthOffBasicTime;
	const timeWorkerPlusMonthOWTOff =
		selectedPaydocu?.dayWorkerInfo?.timeWorkerPlusMonthOWTOff;
	const timeWorkerPlusOverTimeDates =
		selectedPaydocu?.dayWorkerInfo?.timeWorkerPlusOverTimeDates;
	const timeWorkerPlusNightTimeDates =
		selectedPaydocu?.dayWorkerInfo?.timeWorkerPlusNightTimeDates;
	const timeWorkerPlusHoliBasicTimeDates =
		selectedPaydocu?.dayWorkerInfo?.timeWorkerPlusHoliBasicTimeDates;
	const timeWorkerPlusHoliOverTimeDates =
		selectedPaydocu?.dayWorkerInfo?.timeWorkerPlusHoliOverTimeDates;
	const timeWorkerPlusOffBasicTimeDates =
		selectedPaydocu?.dayWorkerInfo?.timeWorkerPlusOffBasicTimeDates;
	const timeWorkerPlusOffOverTimeDates =
		selectedPaydocu?.dayWorkerInfo?.timeWorkerPlusOffOverTimeDates;
	const timeWorkerSpecialBonus =
		selectedPaydocu?.dayWorkerInfo?.timeWorkerSpecialBonus;
	const numOWageForTW = selectedPaydocu?.dayWorkerInfo?.numOWageForTW;
	const numNWageForTW = selectedPaydocu?.dayWorkerInfo?.numNWageForTW;
	const numHBWageForTW = selectedPaydocu?.dayWorkerInfo?.numHBWageForTW;
	const numHOWageForTW = selectedPaydocu?.dayWorkerInfo?.numHOWageForTW;
	const numOffWageForTW = selectedPaydocu?.dayWorkerInfo?.numOffWageForTW;
	const numOffOWageForTW = selectedPaydocu?.dayWorkerInfo?.numOffOWageForTW;

	const totalTimeWorkerWage =
		selectedPaydocu?.dayWorkerInfo?.totalTimeWorkerWage;
	const totalTimeWorkerPaidHoliWage =
		selectedPaydocu?.dayWorkerInfo?.totalTimeWorkerPaidHoliWage;
	const timeWorkerTimeWage = selectedPaydocu?.dayWorkerInfo?.timeWorkerTimeWage;
	const timeWorkerTimePaidHoliWage =
		selectedPaydocu?.dayWorkerInfo?.timeWorkerTimePaidHoliWage;
	const week14OverBLTime = selectedPaydocu?.dayWorkerInfo?.week14OverBLTime;
	const numPaidHoliTimeW = selectedPaydocu?.dayWorkerInfo?.numPaidHoliTimeW;
	const numPaidHoliDayW = selectedPaydocu?.dayWorkerInfo?.numPaidHoliDayW;

	const finalPaidHoliWageTimeSum =
		selectedPaydocu?.dayWorkerInfo?.finalPaidHoliWageTimeSum;
	const monthTotalWorkTime = selectedPaydocu?.dayWorkerInfo?.monthTotalWorkTime;
	const finalPaidHoliWageTime =
		selectedPaydocu?.dayWorkerInfo?.finalPaidHoliWageTime;
	const normalWorkerWeekWorkDays =
		selectedPaydocu?.dayWorkerInfo?.normalWorkerWeekWorkDays;
	const paidHoliWageDays = selectedPaydocu?.dayWorkerInfo?.paidHoliWageDays;
	const paidHoliWageText = selectedPaydocu?.dayWorkerInfo?.paidHoliWageText;
	const daylyTimePay = selectedPaydocu?.dayWorkerInfo?.daylyTimePay;

	const dayWorkerPlusDeductHealthCare =
		selectedPaydocu?.dayWorkerInfo?.dayWorkerPlusDeductHealthCare;
	const dayWorkerPlusDeductHealthCareComment =
		selectedPaydocu?.dayWorkerInfo?.dayWorkerPlusDeductHealthCareComment;
	const dayWorkerPlusDeductOldCare =
		selectedPaydocu?.dayWorkerInfo?.dayWorkerPlusDeductOldCare;
	const dayWorkerPlusDeductOldCareComment =
		selectedPaydocu?.dayWorkerInfo?.dayWorkerPlusDeductOldCareComment;
	const dayWorkerPlusDeductWorkTax =
		selectedPaydocu?.dayWorkerInfo?.dayWorkerPlusDeductWorkTax;
	const dayWorkerPlusDeductWorkTaxComment =
		selectedPaydocu?.dayWorkerInfo?.dayWorkerPlusDeductWorkTaxComment;
	const dayWorkerPlusDeductWorkRegionTax =
		selectedPaydocu?.dayWorkerInfo?.dayWorkerPlusDeductWorkRegionTax;
	const dayWorkerPlusDeductWorkRegionTaxComment =
		selectedPaydocu?.dayWorkerInfo?.dayWorkerPlusDeductWorkRegionTaxComment;
	const dayWorkerPlusDeductDuru =
		selectedPaydocu?.dayWorkerInfo?.dayWorkerPlusDeductDuru;
	const dayWorkerPlusMinus4Ins =
		selectedPaydocu?.dayWorkerInfo?.dayWorkerPlusMinus4Ins;

	const dayWorkerPlusDeductDuruComment =
		selectedPaydocu?.dayWorkerInfo?.dayWorkerPlusDeductDuruComment;
	const dayWorkerPlusMinus4InsComment =
		selectedPaydocu?.dayWorkerInfo?.dayWorkerPlusMinus4InsComment;
	const dayWorkerPlusDeductWorkerCompanyLoan =
		selectedPaydocu?.dayWorkerInfo?.dayWorkerPlusDeductWorkerCompanyLoan;
	const dayWorkerPlusDeductWorkerCompanyLoanComment =
		selectedPaydocu?.dayWorkerInfo?.dayWorkerPlusDeductWorkerCompanyLoanComment;
	const dayWorkerDeductEtcWage1 =
		selectedPaydocu?.dayWorkerInfo?.dayWorkerDeductEtcWage1;
	const dayWorkerDeductEtcWage2 =
		selectedPaydocu?.dayWorkerInfo?.dayWorkerDeductEtcWage2;
	const dayWorkerDeductEtcWage3 =
		selectedPaydocu?.dayWorkerInfo?.dayWorkerDeductEtcWage3;
	const dayWorkerDeductEtcName1 =
		selectedPaydocu?.dayWorkerInfo?.dayWorkerDeductEtcName1;
	const dayWorkerDeductEtcWage1Comment =
		selectedPaydocu?.dayWorkerInfo?.dayWorkerDeductEtcWage1Comment;
	const dayWorkerDeductEtcName2 =
		selectedPaydocu?.dayWorkerInfo?.dayWorkerDeductEtcName2;
	const dayWorkerDeductEtcWage2Comment =
		selectedPaydocu?.dayWorkerInfo?.dayWorkerDeductEtcWage2Comment;
	const dayWorkerDeductEtcName3 =
		selectedPaydocu?.dayWorkerInfo?.dayWorkerDeductEtcName3;
	const dayWorkerDeductEtcWage3Comment =
		selectedPaydocu?.dayWorkerInfo?.dayWorkerDeductEtcWage3Comment;
	const dayWorkerUserFixedTaxComment =
		selectedPaydocu?.dayWorkerInfo?.dayWorkerUserFixedTaxComment;
	const dayWorkerUserFixedRegionTaxComment =
		selectedPaydocu?.dayWorkerInfo?.dayWorkerUserFixedRegionTaxComment;
	const dayWorkerUserFixedHealthCareComment =
		selectedPaydocu?.dayWorkerInfo?.dayWorkerUserFixedHealthCareComment;
	const dayWorkerUserFixedOldCareComment =
		selectedPaydocu?.dayWorkerInfo?.dayWorkerUserFixedOldCareComment;
	const dayWorkerUserFixedPensionComment =
		selectedPaydocu?.dayWorkerInfo?.dayWorkerUserFixedPensionComment;
	const dayWorkerUserFixedNoJobInsuranceComment =
		selectedPaydocu?.dayWorkerInfo?.dayWorkerUserFixedNoJobInsuranceComment;

	const dayWorkerCAWEName5 = selectedPaydocu?.dayWorkerInfo?.dayWorkerCAWEName5;
	const dayWorkerCAWage5 = selectedPaydocu?.dayWorkerInfo?.dayWorkerCAWage5;
	const dayWorkerCAWage5Comment =
		selectedPaydocu?.dayWorkerInfo?.dayWorkerCAWage5Comment;
	const dayWorkerCAWEName6 = selectedPaydocu?.dayWorkerInfo?.dayWorkerCAWEName6;
	const dayWorkerCAWage6 = selectedPaydocu?.dayWorkerInfo?.dayWorkerCAWage6;
	const dayWorkerCAWage6Comment =
		selectedPaydocu?.dayWorkerInfo?.dayWorkerCAWage6Comment;
	const dayWorkerCAWEName7 = selectedPaydocu?.dayWorkerInfo?.dayWorkerCAWEName7;
	const dayWorkerCAWage7 = selectedPaydocu?.dayWorkerInfo?.dayWorkerCAWage7;
	const dayWorkerCAWage7Comment =
		selectedPaydocu?.dayWorkerInfo?.dayWorkerCAWage7Comment;
	const dayWorkerSpecialBonus =
		selectedPaydocu?.dayWorkerInfo?.dayWorkerSpecialBonus;

	const dayWorkerTotalMonthWageAmount =
		selectedPaydocu?.dayWorkerInfo?.dayWorkerTotalMonthWageAmount;
	const dayWorkerUserFixedTax =
		selectedPaydocu?.dayWorkerInfo?.dayWorkerUserFixedTax;
	const dayWorkerUserFixedRegionTax =
		selectedPaydocu?.dayWorkerInfo?.dayWorkerUserFixedRegionTax;
	const dayWorkerUserFixedHealthCare =
		selectedPaydocu?.dayWorkerInfo?.dayWorkerUserFixedHealthCare;
	const dayWorkerUserFixedOldCare =
		selectedPaydocu?.dayWorkerInfo?.dayWorkerUserFixedOldCare;

	const dayWorkerUserFixedPension =
		selectedPaydocu?.dayWorkerInfo?.dayWorkerUserFixedPension;
	const dayWorkerUserFixedNoJobInsurance =
		selectedPaydocu?.dayWorkerInfo?.dayWorkerUserFixedNoJobInsurance;

	const dayWorkerDedectTaxEtc =
		selectedPaydocu?.dayWorkerInfo?.dayWorkerDedectTaxEtc;
	const dayWorkerDeductWage =
		selectedPaydocu?.dayWorkerInfo?.dayWorkerDeductWage;

	const dayWorkerCalTax = selectedPaydocu?.dayWorkerInfo?.dayWorkerCalTax;
	const dayWorkerCalRegionTax =
		selectedPaydocu?.dayWorkerInfo?.dayWorkerCalRegionTax;
	const dayWorkerCalHealthCare =
		selectedPaydocu?.dayWorkerInfo?.dayWorkerCalHealthCare;
	const dayWorkerCalOldCare =
		selectedPaydocu?.dayWorkerInfo?.dayWorkerCalOldCare;
	const dayWorkerCalPension =
		selectedPaydocu?.dayWorkerInfo?.dayWorkerCalPension;
	const dayWorkerCalNoJobInsurance =
		selectedPaydocu?.dayWorkerInfo?.dayWorkerCalNoJobInsurance;

	const dayWorkerHealthCareTextOption =
		selectedPaydocu?.dayWorkerInfo?.dayWorkerHealthCareTextOption;
	const dayWorkerNoJobTextOption =
		selectedPaydocu?.dayWorkerInfo?.dayWorkerNoJobTextOption;
	const dayWorkerPensionTextOption =
		selectedPaydocu?.dayWorkerInfo?.dayWorkerPensionTextOption;
	const dayWorkerOldCareTextOption =
		selectedPaydocu?.dayWorkerInfo?.dayWorkerOldCareTextOption;

	const dayWorkerTaxTextOption =
		selectedPaydocu?.dayWorkerInfo?.dayWorkerTaxTextOption;
	const dayWorkerRegionTaxTextOption =
		selectedPaydocu?.dayWorkerInfo?.dayWorkerRegionTaxTextOption;

	const numDayWorkerOWage = selectedPaydocu?.dayWorkerInfo?.numDayWorkerOWage;
	const numDayWorkerNWage = selectedPaydocu?.dayWorkerInfo?.numDayWorkerNWage;
	const numDayWorkerHBwage = selectedPaydocu?.dayWorkerInfo?.numDayWorkerHBwage;
	const numDayWorkerHOwage = selectedPaydocu?.dayWorkerInfo?.numDayWorkerHOwage;
	const numDayWorkerHNwage = selectedPaydocu?.dayWorkerInfo?.numDayWorkerHNwage;

	const isExceptTimeLawWorker = selectedPaydocu?.isExceptTimeLawWorker;
	const annualLeavesIncludeMonthWageTime =
		selectedPaydocu?.annualLeavesIncludeMonthWageTime;
	const annualLeavesIncludeMonthWage =
		selectedPaydocu?.annualLeavesIncludeMonthWage;

	const jobImproveProposal = selectedPaydocu?.hrEvaluationInfo
		?.jobImproveProposal
		? selectedPaydocu?.hrEvaluationInfo?.jobImproveProposal[1]
		: '';
	const qualityOfWork = selectedPaydocu?.hrEvaluationInfo?.qualityOfWork
		? selectedPaydocu?.hrEvaluationInfo?.qualityOfWork[1]
		: '';
	const amounOfWork = selectedPaydocu?.hrEvaluationInfo?.amounOfWork
		? selectedPaydocu?.hrEvaluationInfo?.amounOfWork[1]
		: '';
	const supportColleague = selectedPaydocu?.hrEvaluationInfo?.supportColleague
		? selectedPaydocu?.hrEvaluationInfo?.supportColleague[1]
		: '';
	const attenanceManage = selectedPaydocu?.hrEvaluationInfo?.attenanceManage
		? selectedPaydocu?.hrEvaluationInfo?.attenanceManage[1]
		: '';
	const supportCumstomer = selectedPaydocu?.hrEvaluationInfo?.supportCumstomer
		? selectedPaydocu?.hrEvaluationInfo?.supportCumstomer[1]
		: '';
	const selfStudy = selectedPaydocu?.hrEvaluationInfo?.selfStudy
		? selectedPaydocu?.hrEvaluationInfo?.selfStudy[1]
		: '';
	const reportAttitude = selectedPaydocu?.hrEvaluationInfo?.reportAttitude
		? selectedPaydocu?.hrEvaluationInfo?.reportAttitude[1]
		: '';

	const jobImproveProposalComments =
		selectedPaydocu?.hrEvaluationInfo?.jobImproveProposalComments;
	const qualityOfWorkComments =
		selectedPaydocu?.hrEvaluationInfo?.qualityOfWorkComments;
	const amounOfWorkComments =
		selectedPaydocu?.hrEvaluationInfo?.amounOfWorkComments;
	const supportColleagueComments =
		selectedPaydocu?.hrEvaluationInfo?.supportColleagueComments;
	const attenanceManageComments =
		selectedPaydocu?.hrEvaluationInfo?.attenanceManageComments;
	const supportCumstomerComments =
		selectedPaydocu?.hrEvaluationInfo?.supportCumstomerComments;
	const selfStudyComments =
		selectedPaydocu?.hrEvaluationInfo?.selfStudyComments;
	const reportAttitudeComments =
		selectedPaydocu?.hrEvaluationInfo?.reportAttitudeComments;

	//산전후휴가기간 표시
	const maternityLeaveStartDate = selectedClientReq4In?.maternityLeaveStartDate
		? moment(selectedClientReq4In?.maternityLeaveStartDate).format('YYYY-MM-DD')
		: null;
	const maternityLeaveEndDate = selectedClientReq4In?.maternityLeaveEndDate
		? moment(selectedClientReq4In?.maternityLeaveEndDate).format('YYYY-MM-DD')
		: null;
	const momentWithRange = extendMoment(moment);
	const start = momentWithRange(selectedClientReq4In?.maternityLeaveStartDate);
	const end = momentWithRange(
		selectedClientReq4In?.maternityLeaveEndDate
	).endOf('month');
	const range = momentWithRange.range(start, end);
	const maternityLeaveMonths = Array.from(range.by('month')).map((m) =>
		m.format('YYYY-MM')
	);

	// 연도와 월을 모두 고려하여 출산 휴가 기간에 포함되는지 확인
	function isThisMonthInMaternityLeavePeriod(
		currentMonth,
		currentYear,
		maternityLeaveMonths
	) {
		// 연도와 월을 'YYYY-MM' 형식으로 표현
		const currentPeriod = `${currentYear}-${currentMonth}`;
		// 출산 휴가 기간의 월들이 'YYYY-MM' 형식으로 변환됩니다.
		const formattedMaternityLeaveMonths = maternityLeaveMonths.map(
			(yearMonth) => {
				return `${yearMonth}`;
			}
		);
		// 현재 월/연도 조합이 출산 휴가 월 목록에 포함되는지 확인
		return formattedMaternityLeaveMonths?.includes(currentPeriod);
	}

	const isTodayInMaternityRange = isThisMonthInMaternityLeavePeriod(
		month,
		year,
		maternityLeaveMonths
	);
	const isTodayInMaternityRangePerson =
		isTodayInMaternityRange && maternityLeaveStartDate !== null
			? `출산전후휴가자 [${maternityLeaveStartDate}~${maternityLeaveEndDate}]`
			: null;

	const workerPersonalEvaluationView =
		selectedClientReq4In?.workerPersonalEvaluationView;

	function goBack() {
		history.goBack();
	}

	if (loading || (!selectedPaydocu && !error))
		return <LoadingComponent content='Loading 저장된 임금명세서....' />;
	if (error) return <Redirect to='/error' />;

	const isPayDocuDetailedPage = true;

	const printFunc = () => {
		printWageTable();
	};

	return (
		<Grid>
			<Grid.Column width={16}>
				<Button onClick={() => goBack()} color='teal'>
					이전페이지
				</Button>
				<PaydocuDetailedHeader
					selectedPaydocu={selectedPaydocu}
					isBizWorker={isBizWorker}
				/>

				<br />
				<PaydocuPrintOneTable
					companyName={companyName}
					worker={worker}
					year={year}
					month={month}
					htmlContentRef={htmlContentRef}
					isPayDocuDetailedPage={isPayDocuDetailedPage}
					clientInfo={clientInfo}
					currentUserProfile={currentUserProfile}
					superAdminForFreeTrue={superAdminForFreeTrue}
					isBizWorker={isBizWorker}
				/>
				<Button type='button' color='blue' onClick={() => printFunc()}>
					임금명세서 출력
				</Button>
				<div id='wageTable' ref={htmlContentRef}>
					<Table align='center' id='table-to-xls'>
						<Table.Body>
							{isBizWorker ? null : (
								<PaydocuDetailedInHead
									isExceptTimeLawWorker={isExceptTimeLawWorker}
									companyName={companyName}
									inputWageStyle={inputWageStyle}
									thisMonth={month}
									thisYear={year}
									worker={worker}
									isBizWorker={isBizWorker}
								/>
							)}
							{isBizWorker ? (
								<Table.Row>
									<Table.Cell colSpan='9'>
										<span
											style={{
												fontSize: '1.2rem',
												color: 'darkcyan',
												fontWeight: 'bold',
											}}>{`1. ${worker}의 인적사항 (최초 계약일 : ${workerEnteredDate})`}</span>
									</Table.Cell>
								</Table.Row>
							) : (
								<Table.Row>
									{workerRetiredDate ? (
										<Table.Cell colSpan='9'>
											<span
												style={{
													color: 'darkcyan',
													fontWeight: 'bold',
												}}>{`1. ${worker}의 인적사항 및 고정 월급 산정 기초 내역 (입사일 : ${workerEnteredDate}, 마지막근무일(퇴사일) : ${workerRetiredDate}, 임금기산일 : ${startToEndDate}, 임금지급일 : ${payDay})`}</span>
										</Table.Cell>
									) : (
										<Table.Cell colSpan='9'>
											<span
												style={{
													fontSize: '1.2rem',
													color: 'darkcyan',
													fontWeight: 'bold',
												}}>{`1. ${worker}의 인적사항 및 고정 월급 산정 기초 내역 (입사일 : ${workerEnteredDate}, 임금기산일 : ${startToEndDate}, 임금지급일 : ${payDay})`}</span>{' '}
											<span style={{ color: 'red' }}>
												{isTodayInMaternityRangePerson
													? isTodayInMaternityRangePerson
													: null}
											</span>
										</Table.Cell>
									)}
								</Table.Row>
							)}
							{dayWorker ? (
								<PaydocuDetailedPageBasicDayTimeWorker
									daylyTimePay={daylyTimePay}
									timeWorkerTimeWage={timeWorkerTimeWage}
									timeWorkerTimePaidHoliWage={timeWorkerTimePaidHoliWage}
									worker={worker}
									workerSocialNumberFront={workerSocialNumberFront}
									dayWorkerWage={dayWorkerWage}
									dayWokerTimeWage={dayWokerTimeWage}
									dayWorkerWorkingTimeDayTotal={dayWorkerWorkingTimeDayTotal}
									dayWorkerDayTotalPaidConversedTime={
										dayWorkerDayTotalPaidConversedTime
									}
									numOfWorkers={numOfWorkers}
									dayWorkerWorkingTimeDayBasic={dayWorkerWorkingTimeDayBasic}
									dayWorkerPaidHolidayLBTime={dayWorkerPaidHolidayLBTime}
									dayWorkerWorkingTimeDayOver={dayWorkerWorkingTimeDayOver}
									dayWorkerWorkingTimeDayNight={dayWorkerWorkingTimeDayNight}
									countOfHoliDayWork={countOfHoliDayWork}
									dayWorkerHoliWage={dayWorkerHoliWage}
									dayWorkerHoliPaidTime={dayWorkerHoliPaidTime}
									countOfOverWorkingDayWork={countOfOverWorkingDayWork}
									dayWorkerOverWorkDayWage={dayWorkerOverWorkDayWage}
									dayWorkerOverWorkDayPaidTime={dayWorkerOverWorkDayPaidTime}
								/>
							) : (
								<PaydocuDetailedPageBasicRegularWorker
									worker={worker}
									workerSocialNumberFront={workerSocialNumberFront}
									monthOWT={monthOWT}
									monthNWT={monthNWT}
									monthHoliBasicTime={monthHoliBasicTime}
									monthOWTHoli={monthOWTHoli}
									monthNWTHoli={monthNWTHoli}
									annualLeavesIncludeMonthWageTime={
										annualLeavesIncludeMonthWageTime
									}
									monthLBTime={monthLBTime}
									hourOrdinaryWage={hourOrdinaryWage}
									isBizWorker={isBizWorker}
								/>
							)}
							{isBizWorker ? null : (
								<>
									<Table.Row>
										<Table.Cell colSpan='9'>{` `}</Table.Cell>
									</Table.Row>
									<PaydocuDetailedPageBSC
										worker={worker}
										dayWorker={dayWorker}
										workerPersonalEvaluationView={workerPersonalEvaluationView}
										jobImproveProposal={jobImproveProposal}
										qualityOfWork={qualityOfWork}
										amounOfWork={amounOfWork}
										supportColleague={supportColleague}
										attenanceManage={attenanceManage}
										supportCumstomer={supportCumstomer}
										selfStudy={selfStudy}
										reportAttitude={reportAttitude}
										jobImproveProposalComments={jobImproveProposalComments}
										qualityOfWorkComments={qualityOfWorkComments}
										amounOfWorkComments={amounOfWorkComments}
										supportColleagueComments={supportColleagueComments}
										attenanceManageComments={attenanceManageComments}
										supportCumstomerComments={supportCumstomerComments}
										selfStudyComments={selfStudyComments}
										reportAttitudeComments={reportAttitudeComments}
									/>
									<Table.Row>
										<Table.Cell colSpan='9'>{` `}</Table.Cell>
									</Table.Row>
									<PaydocuDetailedPageWorkDayPlusTime
										daylyTimePay={daylyTimePay}
										worker={worker}
										dayWorker={dayWorker}
										countOfNomalDayWork={countOfNomalDayWork}
										numOfWorkers={numOfWorkers}
										countOfHoliDayWork={countOfHoliDayWork}
										countOfOverWorkingDayWork={countOfOverWorkingDayWork}
										sumOfCountWorkDays={sumOfCountWorkDays}
										workDatesPaydocu={workDatesPaydocu}
										timeWorkerPlusMonthOWT={timeWorkerPlusMonthOWT}
										timeWorkerPlusMonthNWT={timeWorkerPlusMonthNWT}
										timeWorkerPlusMonthHoliBasicTime={
											timeWorkerPlusMonthHoliBasicTime
										}
										timeWorkerPlusMonthOWTHoli={timeWorkerPlusMonthOWTHoli}
										timeWorkerPlusMonthOffBasicTime={
											timeWorkerPlusMonthOffBasicTime
										}
										timeWorkerPlusMonthOWTOff={timeWorkerPlusMonthOWTOff}
										timeWorkerPlusOverTimeDates={timeWorkerPlusOverTimeDates}
										timeWorkerPlusNightTimeDates={timeWorkerPlusNightTimeDates}
										timeWorkerPlusHoliBasicTimeDates={
											timeWorkerPlusHoliBasicTimeDates
										}
										timeWorkerPlusHoliOverTimeDates={
											timeWorkerPlusHoliOverTimeDates
										}
										timeWorkerPlusOffBasicTimeDates={
											timeWorkerPlusOffBasicTimeDates
										}
										timeWorkerPlusOffOverTimeDates={
											timeWorkerPlusOffOverTimeDates
										}
										dayWorkerPlusOTime={dayWorkerPlusOTime}
										dayWorkerPlusNTime={dayWorkerPlusNTime}
										dayWorkerPlusHBTime={dayWorkerPlusHBTime}
										dayWorkerPlusHOTime={dayWorkerPlusHOTime}
										dayWorkerPlusHNTime={dayWorkerPlusHNTime}
										dayWorkerAbsentTimes={dayWorkerAbsentTimes}
										dayWorkerPlusOverTimeDates={dayWorkerPlusOverTimeDates}
										dayWorkerPlusNightTimeDates={dayWorkerPlusNightTimeDates}
										dayWorkerPlusHoliBasicTimeDates={
											dayWorkerPlusHoliBasicTimeDates
										}
										dayWorkerPlusHoliOverTimeDates={
											dayWorkerPlusHoliOverTimeDates
										}
										dayWorkerPlusHoliNightTimeDates={
											dayWorkerPlusHoliNightTimeDates
										}
										dayWorkerAbsentTimeDates={dayWorkerAbsentTimeDates}
										plusMonthOWT={plusMonthOWT}
										plusMonthNWT={plusMonthNWT}
										plusMonthHoliBasicTime={plusMonthHoliBasicTime}
										plusMonthOWTHoli={plusMonthOWTHoli}
										plusMonthNWTHoli={plusMonthNWTHoli}
										absentDays={absentDays}
										absentPaidHoliDays={absentPaidHoliDays}
										absentTimes={absentTimes}
										usedAnnualLeaves={usedAnnualLeaves}
										absentDates={absentDates}
										absentPaidHoliDates={absentPaidHoliDates}
										absentTimeDates={absentTimeDates}
										usedAnnualLeaveDates={usedAnnualLeaveDates}
									/>
									{numOfWorkers < 5 ? (
										<Table.Row>
											<Table.Cell colSpan='9'>
												* 5인미만 사업장은 근로기준법에 따라 소정근로시간이
												없으므로 연장근로가 아닌 초과근로로 표시되고, 초과된
												근로에 대한 1배만 지급되며, 연장근로, 야간근로, 휴일근로
												가산수당이 적용되지 않습니다.
											</Table.Cell>
										</Table.Row>
									) : null}
								</>
							)}
							<PaydocuPayDetail
								isBizWorker={isBizWorker}
								bizTotalIncome={bizTotalIncome}
								bizDeductedTotal={bizDeductedTotal}
								bizDeductionAfterTaxComment={bizDeductionAfterTaxComment}
								bizDeductWage={bizDeductWage}
								bizIncomeTax={bizIncomeTax}
								bizIncomeRegionTax={bizIncomeRegionTax}
								bizDeductionAfterTax={bizDeductionAfterTax}
								workerRetiredDate={workerRetiredDate}
								totalTimeWorkerWage={totalTimeWorkerWage}
								totalTimeWorkerPaidHoliWage={totalTimeWorkerPaidHoliWage}
								timeWorkerTimeWage={timeWorkerTimeWage}
								timeWorkerTimePaidHoliWage={timeWorkerTimePaidHoliWage}
								week14OverBLTime={week14OverBLTime}
								numPaidHoliTimeW={numPaidHoliTimeW}
								finalPaidHoliWageTimeSum={finalPaidHoliWageTimeSum}
								monthTotalWorkTime={monthTotalWorkTime}
								finalPaidHoliWageTime={finalPaidHoliWageTime}
								normalWorkerWeekWorkDays={normalWorkerWeekWorkDays}
								paidHoliWageDays={paidHoliWageDays}
								paidHoliWageText={paidHoliWageText}
								daylyTimePay={daylyTimePay}
								maternityDeductWage={maternityDeductWage}
								selectedClientReq4In={selectedClientReq4In}
								numFinalRetireHealthCareRecalFee={
									numFinalRetireHealthCareRecalFee
								}
								numFinalRetireOldCareRecalFee={numFinalRetireOldCareRecalFee}
								annualLeavesIncludeMonthWage={annualLeavesIncludeMonthWage}
								dayWorker={dayWorker}
								dayWorkerWage={dayWorkerWage}
								dayWorkerHoliWage={dayWorkerHoliWage}
								dayWorkerOverWorkDayWage={dayWorkerOverWorkDayWage}
								dayWokerTimeWage={dayWokerTimeWage}
								countOfNomalDayWork={countOfNomalDayWork}
								countOfHoliDayWork={countOfHoliDayWork}
								countOfOverWorkingDayWork={countOfOverWorkingDayWork}
								totalDayWorkerWage={totalDayWorkerWage}
								totalDayWorkerHoliWage={totalDayWorkerHoliWage}
								totalDayWorkerOverWorkDayWage={totalDayWorkerOverWorkDayWage}
								workDayOTimePaidWage={workDayOTimePaidWage}
								workDayNTimePaidWage={workDayNTimePaidWage}
								holiBTimePaidWage={holiBTimePaidWage}
								holiOTimePaidWage={holiOTimePaidWage}
								holiNTimePaidWage={holiNTimePaidWage}
								sumOfLawOverWage={sumOfLawOverWage}
								absentDaysLawOverWage={absentDaysLawOverWage}
								plusRegularBonus={plusRegularBonus}
								plusRegularBonusComment={plusRegularBonusComment}
								plusIrregularBonus={plusIrregularBonus}
								plusIrregularBonusComment={plusIrregularBonusComment}
								plusUnusedAnnualLeavesWage={plusUnusedAnnualLeavesWage}
								plusRetiredUnusedAnnualLeaves={plusRetiredUnusedAnnualLeaves}
								plusSpecialRandomWage={plusSpecialRandomWage}
								plusUnusedAnnualLeavesWageComment={
									plusUnusedAnnualLeavesWageComment
								}
								plusLastMonthAverWage={plusLastMonthAverWage}
								plusLastMonthAverWageComment={plusLastMonthAverWageComment}
								plusPersonalIncentive={plusPersonalIncentive}
								plusPersonalIncentiveComment={plusPersonalIncentiveComment}
								perDayMealTaxFree={perDayMealTaxFree}
								plusIrregularDayMealTFDays={plusIrregularDayMealTFDays}
								plusIrregularDayMealTFWage={plusIrregularDayMealTFWage}
								plusDeductHealthCare={plusDeductHealthCare}
								plusDeductHealthCareComment={plusDeductHealthCareComment}
								plusDeductOldCare={plusDeductOldCare}
								plusDeductOldCareComment={plusDeductOldCareComment}
								plusDeductWorkTax={plusDeductWorkTax}
								plusDeductWorkTaxComment={plusDeductWorkTaxComment}
								plusDeductWorkRegionTax={plusDeductWorkRegionTax}
								plusDeductWorkRegionTaxComment={plusDeductWorkRegionTaxComment}
								plusDeductDuru={plusDeductDuru}
								plusMinus4Ins={plusMinus4Ins}
								plusDeductDuruComment={plusDeductDuruComment}
								plusMinus4InsComment={plusMinus4InsComment}
								plusDeductWorkerCompanyLoan={plusDeductWorkerCompanyLoan}
								plusDeductWorkerCompanyLoanComment={
									plusDeductWorkerCompanyLoanComment
								}
								isInterInWorker={isInterInWorker}
								year={year}
								selectedPaydocu={selectedPaydocu}
								numOfWorkers={numOfWorkers}
								weekLBTime={weekLBTime}
								hourOrdinaryWage={hourOrdinaryWage}
								inputWageStyle={inputWageStyle}
								centerStyle={centerStyle}
								inputCommentStyle={inputCommentStyle}
								inputCommentStyleWide={inputCommentStyleWide}
								currentUserProfile={currentUserProfile}
								usedAnnualLeaves={usedAnnualLeaves}
								usedAnnualLeaveDates={usedAnnualLeaveDates}
								monthOWT={monthOWT}
								monthNWT={monthNWT}
								monthHoliBasicTime={monthHoliBasicTime}
								monthOWTHoli={monthOWTHoli}
								monthNWTHoli={monthNWTHoli}
								plusMonthOWT={plusMonthOWT}
								plusMonthNWT={plusMonthNWT}
								plusMonthHoliBasicTime={plusMonthHoliBasicTime}
								plusMonthOWTHoli={plusMonthOWTHoli}
								plusMonthNWTHoli={plusMonthNWTHoli}
								monthBasicWage={monthBasicWage}
								cTFMealWage={cTFMealWage}
								cTFCarWage={cTFCarWage}
								cTFChildWage={cTFChildWage}
								cTFStudyWage={cTFStudyWage}
								cTFEtc={cTFEtc}
								cOWTitle={cOWTitle}
								cOWJob={cOWJob}
								cOWLicense={cOWLicense}
								cOWLongTerm={cOWLongTerm}
								cOWMonthlyBonus={cOWMonthlyBonus}
								cOWEtc={cOWEtc}
								cOWChildManageStudyWage={cOWChildManageStudyWage}
								cOWChildManageStudyWageComment={cOWChildManageStudyWageComment}
								cOWChildStudyWage={cOWChildStudyWage}
								cOWChildStudyWageComment={cOWChildStudyWageComment}
								cOWChildCareWage={cOWChildCareWage}
								cOWChildCareWageComment={cOWChildCareWageComment}
								cOWChildActivityWage={cOWChildActivityWage}
								cOWChildActivityWageComment={cOWChildActivityWageComment}
								workingDays={workingDays}
								cAWage1={cAWage1}
								cAWage2={cAWage2}
								cAWage3={cAWage3}
								cAWage4={cAWage4}
								cAWEName1={cAWEName1}
								cAWEName2={cAWEName2}
								cAWEName3={cAWEName3}
								cAWEName4={cAWEName4}
								cAWageTF1={cAWageTF1}
								cAWENameTF1={cAWENameTF1}
								cAWageTF1Comment={cAWageTF1Comment}
								cAWageTF2={cAWageTF2}
								cAWENameTF2={cAWENameTF2}
								cAWageTF2Comment={cAWageTF2Comment}
								cAWageTF3={cAWageTF3}
								cAWENameTF3={cAWENameTF3}
								cAWageTF3Comment={cAWageTF3Comment}
								cAWageTF4={cAWageTF4}
								cAWENameTF4={cAWENameTF4}
								cAWageTF4Comment={cAWageTF4Comment}
								userFixedCAWageTF1={userFixedCAWageTF1}
								userFixedCAWageTF1Name={userFixedCAWageTF1Name}
								userFixedCAWageTF1Comment={userFixedCAWageTF1Comment}
								userFixedCAWageTF2={userFixedCAWageTF2}
								userFixedCAWageTF2Name={userFixedCAWageTF2Name}
								userFixedCAWageTF2Comment={userFixedCAWageTF2Comment}
								userFixedCAWageTF3={userFixedCAWageTF3}
								userFixedCAWageTF3Name={userFixedCAWageTF3Name}
								userFixedCAWageTF3Comment={userFixedCAWageTF3Comment}
								userFixedCAWageTF4={userFixedCAWageTF4}
								userFixedCAWageTF4Name={userFixedCAWageTF4Name}
								userFixedCAWageTF4Comment={userFixedCAWageTF4Comment}
								userFixedCAWage1={userFixedCAWage1}
								userFixedCAWage1Name={userFixedCAWage1Name}
								userFixedCAWage1Comment={userFixedCAWage1Comment}
								userFixedCAWage2={userFixedCAWage2}
								userFixedCAWage2Name={userFixedCAWage2Name}
								userFixedCAWage2Comment={userFixedCAWage2Comment}
								userFixedCAWage3={userFixedCAWage3}
								userFixedCAWage3Name={userFixedCAWage3Name}
								userFixedCAWage3Comment={userFixedCAWage3Comment}
								userFixedCAWage4={userFixedCAWage4}
								userFixedCAWage4Name={userFixedCAWage4Name}
								userFixedCAWage4Comment={userFixedCAWage4Comment}
								cAWage5={cAWage5}
								cAWEName5={cAWEName5}
								cAWage5Comment={cAWage5Comment}
								cAWage6={cAWage6}
								cAWEName6={cAWEName6}
								cAWage6Comment={cAWage6Comment}
								cAWage7={cAWage7}
								cAWEName7={cAWEName7}
								cAWage7Comment={cAWage7Comment}
								deductEtcName1={deductEtcName1}
								deductEtcAWage1={deductEtcAWage1}
								deductEtcWage1Comment={deductEtcWage1Comment}
								deductEtcName2={deductEtcName2}
								deductEtcAWage2={deductEtcAWage2}
								deductEtcWage2Comment={deductEtcWage2Comment}
								deductEtcName3={deductEtcName3}
								deductEtcAWage3={deductEtcAWage3}
								deductEtcWage3Comment={deductEtcWage3Comment}
								monthBasicWageComment={monthBasicWageComment}
								cTFMealWageComment={cTFMealWageComment}
								cTFCarWageComment={cTFCarWageComment}
								cTFChildWageComment={cTFChildWageComment}
								cTFStudyWageComment={cTFStudyWageComment}
								cTFEtcComment={cTFEtcComment}
								cOWTitleComment={cOWTitleComment}
								cOWJobComment={cOWJobComment}
								cOWLicenseComment={cOWLicenseComment}
								cOWLongTermComment={cOWLongTermComment}
								cOWMonthlyBonusComment={cOWMonthlyBonusComment}
								cOWEtcComment={cOWEtcComment}
								cAWage1Comment={cAWage1Comment}
								cAWage2Comment={cAWage2Comment}
								cAWage3Comment={cAWage3Comment}
								cAWage4Comment={cAWage4Comment}
								userFixedTax={userFixedTax}
								userFixedRegionTax={userFixedRegionTax}
								userFixedHealthCare={userFixedHealthCare}
								userFixedOldCare={userFixedOldCare}
								userFixedPension={userFixedPension}
								userFixedNoJobInsurance={userFixedNoJobInsurance}
								userFixedTaxComment={userFixedTaxComment}
								userFixedRegionTaxComment={userFixedRegionTaxComment}
								userFixedHealthCareComment={userFixedHealthCareComment}
								userFixedOldCareComment={userFixedOldCareComment}
								userFixedPensionComment={userFixedPensionComment}
								userFixedNoJobInsuranceComment={userFixedNoJobInsuranceComment}
								daysOfInterInAbusent={daysOfInterInAbusent}
								daysOfInterOutAbusent={daysOfInterOutAbusent}
								daysOfInterInAbusentOrdinaryWage={
									daysOfInterInAbusentOrdinaryWage
								}
								daysOfInterInAbusentLawOverWage={
									daysOfInterInAbusentLawOverWage
								}
								daysOfInterOutAbusentOrdinaryWage={
									daysOfInterOutAbusentOrdinaryWage
								}
								daysOfInterOutAbusentLawOverWage={
									daysOfInterOutAbusentLawOverWage
								}
								absentDays={absentDays}
								absentPaidHoliDays={absentPaidHoliDays}
								absentTimes={absentTimes}
								daysInMonth={daysInMonth}
								maternityFinalDedectOrdWage={maternityFinalDedectOrdWage}
								maternityFinalDedectLawOverWage={
									maternityFinalDedectLawOverWage
								}
								paidHolidayLBTime={paidHolidayLBTime}
								monthOrdinaryWage={monthOrdinaryWage}
								calTax={calTax}
								calRegionTax={calRegionTax}
								calPension={calPension}
								calHealthCare={calHealthCare}
								calOldCare={calOldCare}
								calNoJobInsurance={calNoJobInsurance}
								dedectTaxEtc={dedectTaxEtc}
								deductWage={deductWage}
								totalMonthWageAmount={roundUp(totalMonthWageAmount)}
								taxText={taxText}
								regionTaxText={regionTaxText}
								noJobText={noJobText}
								pensionText={pensionText}
								oldCareText={oldCareText}
								healthCareText={healthCareText}
								taxPayType={taxPayType}
								calTaxRate={calTaxRate}
								pensionPayType={pensionPayType}
								pensionBaseWage={pensionBaseWage}
								healthCarePayType={healthCarePayType}
								healthCareBaseWage={healthCareBaseWage}
								oldCarePayType={oldCarePayType}
								oldCareBaseWage={oldCareBaseWage}
								noJobPayType={noJobPayType}
								noJobBaseWage={noJobBaseWage}
								taxFreeWageSum={taxFreeWageSum}
								taxWageSum={taxWageSum}
								numDeductEtcAWage1={numDeductEtcAWage1}
								numDeductEtcAWage2={numDeductEtcAWage2}
								numDeductEtcAWage3={numDeductEtcAWage3}
								dayWorkerPlusOTime={dayWorkerPlusOTime}
								dayWorkerPlusNTime={dayWorkerPlusNTime}
								dayWorkerPlusHBTime={dayWorkerPlusHBTime}
								dayWorkerPlusHOTime={dayWorkerPlusHOTime}
								dayWorkerPlusHNTime={dayWorkerPlusHNTime}
								dayWorkerAbsentTimes={dayWorkerAbsentTimes}
								timeWorkerPlusMonthOWT={timeWorkerPlusMonthOWT}
								timeWorkerPlusMonthNWT={timeWorkerPlusMonthNWT}
								timeWorkerPlusMonthHoliBasicTime={
									timeWorkerPlusMonthHoliBasicTime
								}
								timeWorkerPlusMonthOWTHoli={timeWorkerPlusMonthOWTHoli}
								timeWorkerPlusMonthOffBasicTime={
									timeWorkerPlusMonthOffBasicTime
								}
								timeWorkerPlusMonthOWTOff={timeWorkerPlusMonthOWTOff}
								numOWageForTW={numOWageForTW}
								numNWageForTW={numNWageForTW}
								numHBWageForTW={numHBWageForTW}
								numHOWageForTW={numHOWageForTW}
								numOffWageForTW={numOffWageForTW}
								numOffOWageForTW={numOffOWageForTW}
								dayWorkerCAWEName5={dayWorkerCAWEName5}
								dayWorkerCAWage5={dayWorkerCAWage5}
								dayWorkerCAWage5Comment={dayWorkerCAWage5Comment}
								dayWorkerCAWage6={dayWorkerCAWage6}
								dayWorkerCAWEName6={dayWorkerCAWEName6}
								dayWorkerCAWage6Comment={dayWorkerCAWage6Comment}
								dayWorkerCAWage7={dayWorkerCAWage7}
								dayWorkerCAWEName7={dayWorkerCAWEName7}
								dayWorkerCAWage7Comment={dayWorkerCAWage7Comment}
								dayWorkerTotalMonthWageAmount={dayWorkerTotalMonthWageAmount}
								dayWorkerUserFixedTax={dayWorkerUserFixedTax}
								dayWorkerUserFixedTaxComment={dayWorkerUserFixedTaxComment}
								dayWorkerCalTax={dayWorkerCalTax}
								dayWorkerUserFixedRegionTax={dayWorkerUserFixedRegionTax}
								dayWorkerUserFixedRegionTaxComment={
									dayWorkerUserFixedRegionTaxComment
								}
								dayWorkerCalRegionTax={dayWorkerCalRegionTax}
								dayWorkerUserFixedHealthCare={dayWorkerUserFixedHealthCare}
								dayWorkerUserFixedHealthCareComment={
									dayWorkerUserFixedHealthCareComment
								}
								dayWorkerCalHealthCare={dayWorkerCalHealthCare}
								dayWorkerUserFixedOldCare={dayWorkerUserFixedOldCare}
								dayWorkerUserFixedOldCareComment={
									dayWorkerUserFixedOldCareComment
								}
								dayWorkerCalOldCare={dayWorkerCalOldCare}
								dayWorkerUserFixedPension={dayWorkerUserFixedPension}
								dayWorkerUserFixedPensionComment={
									dayWorkerUserFixedPensionComment
								}
								dayWorkerCalPension={dayWorkerCalPension}
								dayWorkerUserFixedNoJobInsurance={
									dayWorkerUserFixedNoJobInsurance
								}
								dayWorkerUserFixedNoJobInsuranceComment={
									dayWorkerUserFixedNoJobInsuranceComment
								}
								dayWorkerCalNoJobInsurance={dayWorkerCalNoJobInsurance}
								dayWorkerHealthCareTextOption={dayWorkerHealthCareTextOption}
								dayWorkerNoJobTextOption={dayWorkerNoJobTextOption}
								dayWorkerPensionTextOption={dayWorkerPensionTextOption}
								dayWorkerOldCareTextOption={dayWorkerOldCareTextOption}
								dayWorkerTaxTextOption={dayWorkerTaxTextOption}
								dayWorkerRegionTaxTextOption={dayWorkerRegionTaxTextOption}
								dayWorkerDeductEtcWage1={dayWorkerDeductEtcWage1}
								dayWorkerDeductEtcName1={dayWorkerDeductEtcName1}
								dayWorkerDeductEtcWage1Comment={dayWorkerDeductEtcWage1Comment}
								dayWorkerDeductEtcWage2={dayWorkerDeductEtcWage2}
								dayWorkerDeductEtcName2={dayWorkerDeductEtcName2}
								dayWorkerDeductEtcWage2Comment={dayWorkerDeductEtcWage2Comment}
								dayWorkerDeductEtcWage3={dayWorkerDeductEtcWage3}
								dayWorkerDeductEtcName3={dayWorkerDeductEtcName3}
								dayWorkerDeductEtcWage3Comment={dayWorkerDeductEtcWage3Comment}
								dayWorkerDedectTaxEtc={dayWorkerDedectTaxEtc}
								dayWorkerDeductWage={dayWorkerDeductWage}
								dayWorkerPlusDeductHealthCare={dayWorkerPlusDeductHealthCare}
								dayWorkerPlusDeductHealthCareComment={
									dayWorkerPlusDeductHealthCareComment
								}
								dayWorkerPlusDeductOldCare={dayWorkerPlusDeductOldCare}
								dayWorkerPlusDeductOldCareComment={
									dayWorkerPlusDeductOldCareComment
								}
								dayWorkerPlusDeductWorkTax={dayWorkerPlusDeductWorkTax}
								dayWorkerPlusDeductWorkTaxComment={
									dayWorkerPlusDeductWorkTaxComment
								}
								dayWorkerPlusDeductWorkRegionTax={
									dayWorkerPlusDeductWorkRegionTax
								}
								dayWorkerPlusDeductWorkRegionTaxComment={
									dayWorkerPlusDeductWorkRegionTaxComment
								}
								dayWorkerPlusDeductDuru={dayWorkerPlusDeductDuru}
								dayWorkerPlusMinus4Ins={dayWorkerPlusMinus4Ins}
								dayWorkerPlusDeductDuruComment={dayWorkerPlusDeductDuruComment}
								dayWorkerPlusMinus4InsComment={dayWorkerPlusMinus4InsComment}
								dayWorkerPlusDeductWorkerCompanyLoan={
									dayWorkerPlusDeductWorkerCompanyLoan
								}
								dayWorkerPlusDeductWorkerCompanyLoanComment={
									dayWorkerPlusDeductWorkerCompanyLoanComment
								}
								numDayWorkerOWage={numDayWorkerOWage}
								numDayWorkerNWage={numDayWorkerNWage}
								numDayWorkerHBwage={numDayWorkerHBwage}
								numDayWorkerHOwage={numDayWorkerHOwage}
								numDayWorkerHNwage={numDayWorkerHNwage}
								dayWorkerSpecialBonus={dayWorkerSpecialBonus}
								timeWorkerSpecialBonus={timeWorkerSpecialBonus}
								numPaidHoliDayW={numPaidHoliDayW}
							/>
							{isBizWorker ? null : (
								<>
									{dayWorker ? null : (
										<>
											{numOfWorkers >= 5 ? (
												<>
													<Table.Row>
														{weekLBTime < 15 ? (
															<Table.Cell colSpan='9'>
																{`월 통상임금 산정기준 시간수 : ${numberToCommaString(
																	monthLBTime
																)}시간 
                                  [(1주 소정근로시간 ${numberToCommaString(
																		weekLBTime
																	)})] / 7일 * 365일 /12개월(초단시간 근로자로 유급주휴시간 없음.)`}
															</Table.Cell>
														) : (
															<>
																{isExceptTimeLawWorker ? (
																	<Table.Cell colSpan='9'>
																		{`월 통상임금 산정기준 시간수 : ${numberToCommaString(
																			monthLBTime
																		)}시간 
                                      [(1주 기본근로시간 ${numberToCommaString(
																				weekLBTime
																			)})] / 7일 * 365일 /12개월(근기법 제63조에 따라 유급주휴시간 없음.)
                                      `}{' '}
																		<br />
																		{monthLBTime === 209
																			? '(위 계산값은 약 208.56시간이지만 고용노동부 최저임금 고시에 의거 209로 계산함)'
																			: null}
																	</Table.Cell>
																) : (
																	<Table.Cell colSpan='9'>
																		{`월 통상임금 산정기준 시간수 : ${numberToCommaString(
																			monthLBTime
																		)}시간 
                                      [(1주 소정근로시간 ${numberToCommaString(
																				weekLBTime
																			)}) + (유급주휴시간 ${numberToCommaString(
																			paidHolidayLBTime
																		)})] / 7일 * 365일 /12개월
                                      `}{' '}
																		<br />
																		{monthLBTime === 209
																			? '(위 계산값은 약 208.56시간이지만 고용노동부 최저임금 고시에 의거 209로 계산함)'
																			: null}
																	</Table.Cell>
																)}
															</>
														)}
													</Table.Row>
													<Table.Row>
														<Table.Cell colSpan='9'>
															{`통상시급 : ${numberToCommaString(
																hourOrdinaryWage
															)}원`}
															{dayOrdIncludeComment5(
																sumOfCOWDay,
																monthOrdinaryWage,
																monthLBTime,
																paidHolidayLBTime
															)}
														</Table.Cell>
													</Table.Row>
													{(absentDays > 0 ||
														absentPaidHoliDays > 0 ||
														absentTimes > 0) && (
														<Table.Row>
															<Table.Cell colSpan='9'>
																* 공제일수 계산근거 : 임금계약서 제4조 (결근 등
																공제) <br />
																* 공제일수 계산방법 : 민법상 역월 기준으로
																중도에 입퇴사하는 경우와 결근시 해당 월 일수의
																다소를 불문하고, 월 통상임금을 해당 월의
																일수(예, 28일인 달은 28일, 29일인 달은 29일,
																30일인 달은 30일, 31일인 달은 31일)로 일할
																계산한 일급에 직원이 해당월에 재직한 일수를
																곱하는 방법으로 계산(실제 1일 소정근로시간에
																대한 일급(주휴수당)보다 작은 금액임). 단,
																전체월급에
																약정연장/약정야간/약정휴일근로수당(이하
																"약정초과수당"이라 한다.)이 있는 경우는 월
																통상임금에 약정초과수당을 더한 월 임금을 일할
																계산. <br />
																** 공제시간수 계산방법 : 소정근로시간 중 지각,
																조퇴, 외출 등이 있는 경우 해당 시간에 시급을
																곱하여 계산.
															</Table.Cell>
														</Table.Row>
													)}
												</>
											) : (
												<>
													<Table.Row>
														<Table.Cell colSpan='9'>
															{`
                              월 통상임금 산정기준 시간수 : 월 전체 ${numberToCommaString(
																roundUp(mTPCTime)
															)} 
                              시간(5인미만 사업장은 근로기준법 제50조 근로시간과, 동법 제56조 연장야간휴일근로 
                              가산임금이 적용되지 아니하므로 1개월의 총 시간을 뜻함.)
                            `}
														</Table.Cell>
													</Table.Row>
													<Table.Row>
														<Table.Cell colSpan='9'>
															{`통상시급 : ${numberToCommaString(
																hourOrdinaryWage
															)}원 `}
															{dayOrdIncludeComment4(
																sumOfCOWDay,
																monthOrdinaryWage,
																monthLBTime,
																paidHolidayLBTime
															)}
														</Table.Cell>
													</Table.Row>
													<Table.Row>
														<Table.Cell colSpan='9'>
															* 공제일수 계산근거 : 임금계약서 제4조 (결근 등
															공제) <br />
															* 공제일수 계산방법 : 민법상 역월 기준으로 중도에
															입퇴사하는 경우와 결근시 해당 월 일수의 다소를
															불문하고, 월 통상임금을 해당 월의 일수(예, 28일인
															달은 28일, 29일인 달은 29일, 30일인 달은 30일,
															31일인 달은 31일)로 일할 계산한 일급에 직원이
															해당월에 재직한 일수를 곱하는 방법으로 계산(실제
															1일 소정근로시간에 대한 일급(주휴수당)보다 작은
															금액임). <br />
															** 공제시간수 계산방법 : 소정근로시간 중 지각,
															조퇴, 외출 등이 있는 경우 해당 시간에 시급을
															곱하여 계산.
														</Table.Cell>
													</Table.Row>
												</>
											)}
										</>
									)}
								</>
							)}
							<Table.Row width='100%' align='center'>
								<Table.Cell colSpan='9' align='center'>
									{format(new Date(), 'yyyy-MM-dd')}
								</Table.Cell>
							</Table.Row>
							<Table.Row>
								{isBizWorker ? (
									<Table.Cell
										colSpan='9'
										align='center'>{`사업소득 명세서를 교부받았음을 확인합니다. : ${worker} (서명 또는 인)`}</Table.Cell>
								) : (
									<Table.Cell
										colSpan='9'
										align='center'>{`임금명세서를 교부받았음을 확인합니다. : ${worker} (서명 또는 인)`}</Table.Cell>
								)}
							</Table.Row>
							{isBizWorker ? null : (
								<Table.Row>
									<Table.Cell
										colSpan='9'
										align='left'>{`근로기준법 제48조 제2항 및 2021.11.19.시행 임금명세서 교부 의무 설명자료(2021.11 고용노동부)에 따라 본 명세서는 ${worker}에게 교부된 것으로 본다.`}</Table.Cell>
								</Table.Row>
							)}
						</Table.Body>
					</Table>
				</div>
			</Grid.Column>
		</Grid>
	);
}
