import React from 'react'
import { useSelector } from 'react-redux'
import Messages from './Messages'
import NewMessageInput from './NewMessageInput'

const ChatLogo = () => {
  return (
    <div className='chat_gpt_logo_container'>
      <p className='chat_gpt_logo'>PayDacGPT</p>
    </div>
  )
}

function Chat() {

  const selectedConversationId = useSelector(state => state.eventStore.selectedConversationId)

  return (
    <div className='chat_container'>

      {!selectedConversationId ? <ChatLogo /> : 
        (
          <div className='chat_selected_container'>
            <Messages />
            <NewMessageInput />
          </div>
        )
      }
    </div>
  )
}

export default Chat
