import React, { useEffect, useState } from 'react'
import { Link, useHistory } from 'react-router-dom'
import { Dropdown, Menu } from 'semantic-ui-react'

export default function CompetencyMenu({GrVulnerability}) {
  const history = useHistory();

  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [isMobile, setIsMobile] = useState(window.innerWidth < 1000);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 1000);
    };
    
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const handleMouseOver = () => {
    setDropdownOpen(true);
  };

  const handleMouseLeave = () => {
    setDropdownOpen(false);
  };

  const handleDropdownToggle = () => {
    setDropdownOpen(!dropdownOpen);
  };

  function reloadToMonthlyReports () {
    history.push('/monthlyReports');
  }

  return (
    <Menu.Item className="menu-item" onMouseOver={handleMouseOver} onMouseLeave={handleMouseLeave}>
      {!isMobile && <GrVulnerability className="icon-spacing" />}
      <Dropdown pointing='top left' text='역량모델링 구축' open={dropdownOpen} onClick={handleDropdownToggle}>
        <Dropdown.Menu>
          <Dropdown.Item as={Link} to='/createCompetencyMonthlyReport' text='역량모델링 입력' />
          <Dropdown.Item onClick={() => reloadToMonthlyReports()}  text='역량 REPORT' />
        </Dropdown.Menu>
      </Dropdown>
    </Menu.Item>
  )
}
