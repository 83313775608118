import React from 'react'
import { Table } from 'semantic-ui-react'

export default function ClientReqDLCWageTableDetailChildManageStudy({
  weekLBTime,
  gumakStyle,
  cOWChildManageStudyWage,
  cOWChildManageStudyWageComment,
  isExceptTimeLawWorker
}) {

  return (
    <>
      <Table.Cell className='gubun'>통상임금 {(weekLBTime < 15 || isExceptTimeLawWorker) ? null : <> <br /> (주휴포함)</>}</Table.Cell>
      <Table.Cell className='hangmok'>관리 및 연구수당</Table.Cell>
      <Table.Cell className='gumak' style={gumakStyle}>{cOWChildManageStudyWage}</Table.Cell>
      <Table.Cell textAlign='left' colSpan='6' className='sulmung'>{ cOWChildManageStudyWageComment }</Table.Cell>
    </>
  )
}
