import { 
  CREATE_CLIENTREQ_4IN, 
  UPDATE_CLIENTREQ_4IN, 
  DELETE_CLIENTREQ_4IN, 
  FETCH_CLIENTREQS_4IN, 
  LISTEN_TO_CLIENTREQ_CHAT_4IN, 
  LISTEN_TO_SELECTED_CLIENTREQ_4IN, 
  CLEAR_CLIENTREQS_4IN, 
  SET_START_DATE_CLIENTREQ_4IN, 
  CLEAR_SELECTED_CLIENTREQ_4IN, 
  CLEAR_CLIENTREQS_TABLE_4IN,
  SET_FILTER_CLIENTREQ_4IN,
  AFTER_TAX_WAGE,
  SUM_TAX_FREE_WAGE,
  AFTER_PENSION_BASE_WAGE,
  AFTER_HEALTHCARE_BASE_WAGE,
  AFTER_NOJOB_BASE_WAGE,
  AFTER_NUM_OF_TAX_FAMILY,
  AFTER_NUM_OF_TAX_FAMILY_7_TO_20,
  FINAL_RETIRE_FUND,
  RETIRE_AVERAGE_FINAL_DAY_WAGE,
  RETIRE_FUND,
  FINAL_RETIRED_FUND_TAX,
  FINAL_RETIRED_FUND_REGION_TAX,
  FETCH_WORKER_USERS,
  CLEAR_WORKER_USERS,
  SELECTED_WORKER_REGISTRY_TYPE,
  SET_ACTIVE_TAB
} from "./clientReqConstants4In";
import {
  asyncActionError, 
  asyncActionFinish,
  asyncActionStart} from '../../../app/async/asyncReducer';
import {
  dataFromSnapshotIdDate, 
  } from '../../../app/firestore/firestoreService';
import {
  fetchClientReqs4InFromFirestore, fetchWorkerUsersFromFirestore,
  } from '../../../app/firestore/firestoreService4In';

export function fetchClientReqs4In(filter) {
  return async function(dispatch) {
    dispatch(asyncActionStart());
    dispatch(clearClientReqs4In());
    try {
      const snapshot = await fetchClientReqs4InFromFirestore(filter);
      const clientReqs4In = snapshot.docs.map(doc => dataFromSnapshotIdDate(doc));
      dispatch({type: FETCH_CLIENTREQS_4IN, payload:{clientReqs4In}});
      dispatch(asyncActionFinish());
    } catch (error) {
      dispatch(asyncActionError());
    }
  }
}

export function fetchWorkerUsers(companyId) {
  return async function(dispatch) {
    dispatch(asyncActionStart());
    dispatch(clearWorkerUsers());
    try {
      const snapshot = await fetchWorkerUsersFromFirestore(companyId);
      const workerUsers = snapshot.docs.map(doc => dataFromSnapshotIdDate(doc));
      dispatch({type: FETCH_WORKER_USERS, payload:{workerUsers}});
      dispatch(asyncActionFinish());
    } catch (error) {
      dispatch(asyncActionError());
    }
  }
}


export function setFilterClientReq4In(value) {
  return function(dispatch) {
    // dispatch(clearClientReqs4In());
    dispatch({type: SET_FILTER_CLIENTREQ_4IN, payload: value});
  }
}

export function setStartDateClientReq4In(date) {
  return function(dispatch) {
    dispatch(clearClientReqs4In());
    dispatch({type: SET_START_DATE_CLIENTREQ_4IN, payload: date});
  }
}

// 변경전 ListenToEvents
export function listenToSelectedClientReq4In(clientReq4In) {
  return {
    type: LISTEN_TO_SELECTED_CLIENTREQ_4IN,
    payload: clientReq4In
  }
}

export function selectedWorkerRegistryType(workerRegistryType) {
  return {
    type: SELECTED_WORKER_REGISTRY_TYPE,
    payload: workerRegistryType
  }
}


export function clearSelectedClientReq4In() {
  return {
    type: CLEAR_SELECTED_CLIENTREQ_4IN,
  }
}
export function createClientReq4In(clientReq4In) {
  return {
    type: CREATE_CLIENTREQ_4IN,
    payload: clientReq4In
  }
}

export function updateClientReq4In(clientReq4In) {
  return {
    type: UPDATE_CLIENTREQ_4IN,
    payload: clientReq4In
  }
}

export function deleteClientReq4In(clientReq4InId) {
  return {
    type: DELETE_CLIENTREQ_4IN,
    payload: clientReq4InId
  }
}

export function listenToClientReqChat4In(comments4In) {
  return {
    type: LISTEN_TO_CLIENTREQ_CHAT_4IN,
    payload: comments4In
  }
}

export function clearClientReqs4In() {
  return {
    type: CLEAR_CLIENTREQS_4IN,
  }
}


export function clearWorkerUsers() {
  return {
    type: CLEAR_WORKER_USERS,
  }
}

export function clearClientReqsTable4In() {
  return {
    type: CLEAR_CLIENTREQS_TABLE_4IN,
  }
}

export function afterTaxWageAction(totalTaxWage) {
  return {
    type: AFTER_TAX_WAGE,
    payload: totalTaxWage
  }
}

export function afterPensionBaseWageAction(pensionBaseWage) {
  return {
    type: AFTER_PENSION_BASE_WAGE,
    payload: pensionBaseWage
  }
}

export function afterHealthCareBaseWageAction(healthCareBaseWage) {
  return {
    type: AFTER_HEALTHCARE_BASE_WAGE,
    payload: healthCareBaseWage
  }
}

export function afterNoJobBaseWageAction(noJobBaseWage) {
  return {
    type: AFTER_NOJOB_BASE_WAGE,
    payload: noJobBaseWage
  }
}

export function sumTaxFreeWageAction(sumTaxFreeWage) {
  return {
    type: SUM_TAX_FREE_WAGE,
    payload: sumTaxFreeWage
  }
}

export function afterNumOfTaxFamilyAction(numOfTaxFamily) {
  return {
    type: AFTER_NUM_OF_TAX_FAMILY,
    payload: numOfTaxFamily
  }
}


export function afterNumOfTaxFamily7To20Action(numOfTaxFamily7To20) {
  return {
    type: AFTER_NUM_OF_TAX_FAMILY_7_TO_20,
    payload: numOfTaxFamily7To20
  }
}

export function finalRetiredFundAction(finalRetiredFund) {
  return {
    type: FINAL_RETIRE_FUND,
    payload: finalRetiredFund
  }
}

export function retireAverageFinalDayWageAction(retireAverageFinalDayWage) {
  return {
    type: RETIRE_AVERAGE_FINAL_DAY_WAGE,
    payload: retireAverageFinalDayWage
  }
}

export function retireFundAction(retireFund) {
  return {
    type: RETIRE_FUND,
    payload: retireFund
  }
}

export function finalRetiredFundTaxAction(finalRetiredFundTax) {
  return {
    type: FINAL_RETIRED_FUND_TAX,
    payload: finalRetiredFundTax
  }
}

export function finalRetiredFundRegionTaxAction(finalRetiredFundRegionTax) {
  return {
    type: FINAL_RETIRED_FUND_REGION_TAX,
    payload: finalRetiredFundRegionTax
  }
}

export const setActiveTab = (index) => ({
  type: SET_ACTIVE_TAB,
  payload: index,
});