import React from 'react';
import ClientReqDLDCHoliUnder15 from './ClientReqDLDCHoliUnder15';
import ClientReqDLDCHoliUp15Worker5Under from './ClientReqDLDCHoliUp15Worker5Under';
import ClientReqDLDCHoliUp15Worker5Up from './ClientReqDLDCHoliUp15Worker5Up';
// import { format } from 'date-fns';

export default function ClientReqDLDCHoli({
	numOfWorkers,
	selectedClientReq4In,
	companyName,
	worker,
	isEtcWorker,
	isExceptTimeLawWorker,
	contractOldWorker,
	clientInfo,
	currentUserProfile,
}) {
	// 1주 소정근로시간
	const weekLBTime = selectedClientReq4In?.lawBase?.weekLBTime;

	return (
		<>
			{weekLBTime < 15 ? (
				<ClientReqDLDCHoliUnder15
					selectedClientReq4In={selectedClientReq4In}
					companyName={companyName}
					worker={worker}
					isExceptTimeLawWorker={isExceptTimeLawWorker}
				/>
			) : (
				<>
					{numOfWorkers >= 5 ? (
						<ClientReqDLDCHoliUp15Worker5Up
							contractOldWorker={contractOldWorker}
							selectedClientReq4In={selectedClientReq4In}
							companyName={companyName}
							worker={worker}
							isExceptTimeLawWorker={isExceptTimeLawWorker}
							isEtcWorker={isEtcWorker}
							currentUserProfile={currentUserProfile}
							clientInfo={clientInfo}
						/>
					) : (
						<ClientReqDLDCHoliUp15Worker5Under
							selectedClientReq4In={selectedClientReq4In}
							companyName={companyName}
							worker={worker}
							isEtcWorker={isEtcWorker}
							isExceptTimeLawWorker={isExceptTimeLawWorker}
						/>
					)}
				</>
			)}
		</>
	);
}
