import React from 'react'
import { Table } from 'semantic-ui-react'

export default function ClientReqDLCWageTableDetailMeal({
  cTFMealWage,
  cTFMealWageComment,
  weekLBTime,
  gumakStyle,
  isExceptTimeLawWorker
}) {

  return (
    <>
      <Table.Cell textAlign='center' className='gubun'>통상임금 {(weekLBTime < 15 || isExceptTimeLawWorker) ? null : <> <br /> (주휴포함)</>}</Table.Cell>
      <Table.Cell textAlign='center' className='hangmok'>식대</Table.Cell>
      <Table.Cell textAlign='center' className='gumak' style={gumakStyle}>{cTFMealWage}</Table.Cell>
      <Table.Cell textAlign='left' colSpan='6' className='sulmung'>{ cTFMealWageComment }</Table.Cell>
    </>
  )
}
