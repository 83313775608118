import React from 'react';

export default function ClientReqDLCHeadLawBaseWorkerInfo({worker, laborContractStyle}) {


  return (
    <>
      <table width ="100%" align = "center" >
        <tbody>
          <tr>
            <td align = "center"><h1 style={{textAlign : 'center', margin : '30px', fontSize : '30px'}}>근로자 명부</h1></td>
          </tr>
        </tbody>
      </table>
      <hr />
        <div style={laborContractStyle} >
          {`${worker}의 근로자 명부로 근로기준법 시행규칙 제16호 제1항 제1호에 의거한 법정 서식입니다.`}
        </div>
    </>
  )
}
