import { auth, db } from '../config/firebase';
import {
	doc,
	getDoc,
	query,
	collection,
	orderBy,
	startAfter,
	limit,
	where,
	getDocs,
	arrayUnion,
	addDoc,
	updateDoc,
	deleteDoc,
	setDoc,
	serverTimestamp,
	writeBatch,
	arrayRemove,
	increment,
	Timestamp,
	runTransaction,
} from 'firebase/firestore';

import CryptoJS from 'crypto-js';
import {
	psNomusaAddress,
	psNomusaCeoName,
	psNomusaCompanyName,
	psNomusaCompanyUid,
	psNomusaName,
	psNomusaPhone,
	psSemusaAddress,
	psSemusaCeoName,
	psSemusaCompanyName,
	psSemusaCompanyUid,
	psSemusaName,
	psSemusaPhone,
} from '../common/util/util';

import { updateProfile } from 'firebase/auth';

export function encrypto(data, key) {
	return CryptoJS.AES.encrypt(JSON.stringify(data), key).toString();
}

export function decrypto(text, key) {
	try {
		if (typeof text !== 'string' || text.trim() === '') {
			// 입력이 유효하지 않은 경우, undefined 반환
			return undefined;
		}

		// AES 복호화
		const bytes = CryptoJS.AES.decrypt(text, key);
		const decryptedText = bytes.toString(CryptoJS.enc.Utf8);

		// JSON 파싱을 시도하기 전에, 문자열이 JSON 형식인지 확인
		try {
			return JSON.parse(decryptedText);
		} catch (jsonErr) {
			// JSON 형식이 아닌 경우, 문자열 그대로 반환
			return decryptedText;
		}
	} catch (err) {
		console.error('Decryption error:', err);
		return undefined;
	}
}

// snapshot.id를 가져오고  날짜를 자바스크립트형식으로 변경하는 함수
export function dataFromSnapshotIdDate(docSnapshot) {
	const data = docSnapshot.data();
	for (const prop in data) {
		if (data.hasOwnProperty(prop)) {
			if (data[prop] instanceof Timestamp) {
				data[prop] = data[prop].toDate();
			}
		}
	}
	return {
		...data,
		id: docSnapshot.id,
	};
}

//기존 업무처리와 비대면 테이블에 쓰이는 놈
export async function dataFromSnapshotIdDateTableSample(docRef) {
	const docSnapshot = await getDoc(docRef);
	if (!docSnapshot.exists()) return undefined;
	const data = docSnapshot.data();
	for (const prop in data) {
		if (data.hasOwnProperty(prop)) {
			if (data[prop] instanceof Timestamp) {
				data[prop] = data[prop].toDate().toString();
			}
		}
	}
	return {
		...data,
		id: docSnapshot.id,
	};
}

//4입사자용 이후 쓰이는 용----다시 분리 여부 판단필요
export async function dataFromSnapshotIdDateTable(docRef) {
	const docSnapshot = await getDoc(docRef);
	if (!docSnapshot.exists()) return undefined;
	const data = docSnapshot.data();
	for (const prop in data) {
		if (data.hasOwnProperty(prop)) {
			if (data[prop] instanceof Timestamp) {
				data[prop] = data[prop].toDate().toString();
			}
		}
	}
	return {
		...data,
		id: docSnapshot.id,
	};
}

export async function fetchEventsFromFirestore(
	filter,
	startDate,
	limit,
	lastDocSnapshot = null
) {
	const user = auth.currentUser;
	let q = query(
		collection(db, 'events'),
		orderBy('date'),
		startAfter(lastDocSnapshot),
		limit(limit)
	);

	switch (filter) {
		case 'isGoing':
			q = query(
				q,
				where('attendeeIds', 'array-contains', user?.uid),
				where('date', '>=', startDate)
			);
			break;
		case 'isHosting':
			q = query(
				q,
				where('hostUid', '==', user?.uid),
				where('date', '>=', startDate)
			);
			break;
		default:
			q = query(q, where('date', '>=', startDate));
	}
	const querySnapshot = await getDocs(q);
	return querySnapshot.docs.map((doc) => doc.data());
}

export function fetchEventsFromFirestoreId(filter, id) {
	const user = auth.currentUser;
	let eventsRef = collection(db, 'events');

	let q;

	switch (filter) {
		case 'isGoing':
			q = query(
				eventsRef,
				where('attendeeIds', 'array-contains', user?.uid),
				where('id', '==', id)
			);
			break;
		case 'isHosting':
			q = query(
				eventsRef,
				where('hostUid', '==', user?.uid),
				where('id', '==', id)
			);
			break;
		default:
			q = query(eventsRef, where('id', '==', id));
	}

	return q;
}

export async function fetchUntactsFromFirestore(
	filter,
	startDate,
	limit,
	lastDocSnapshot = null
) {
	const user = auth.currentUser;
	let untactsRef = query(
		collection(db, 'untacts'),
		orderBy('date'),
		startAfter(lastDocSnapshot),
		limit(limit)
	);

	switch (filter) {
		case 'isGoing':
			untactsRef = query(
				untactsRef,
				where('attendeeIds', 'array-contains', user?.uid),
				where('date', '>=', startDate)
			);
			break;
		case 'isHosting':
			untactsRef = query(
				untactsRef,
				where('hostUid', '==', user?.uid),
				where('date', '>=', startDate)
			);
			break;
		default:
			untactsRef = query(untactsRef, where('date', '>=', startDate));
	}

	return untactsRef;
}

//비대면
export function fetchUntactsTableSampleFromFirestore() {
	let untactsRef = query(collection(db, 'untacts'), orderBy('date'));
	return untactsRef;
}

//
export function fetchEventsTableFromFirestore() {
	let eventsRef = query(
		collection(db, 'events'),
		orderBy('date', 'desc'),
		limit(100)
	);
	return eventsRef;
}

export function listenToEventFromFirestore(eventId) {
	return query(collection('events'), eventId);
}

export function listenToUntactFromFirestore(untactId) {
	return query(collection('untacts'), untactId);
}

export async function addEventToFirestore(event) {
	const user = auth.currentUser;

	return await addDoc(collection(db, 'events'), {
		...event,
		hostUid: user.uid,
		hostedBy: user.displayName,
		hostPhotoURL: user.photoURL || null,
		attendees: arrayUnion({
			id: user.uid,
			displayName: user.displayName,
			photoURL: user.photoURL || null,
		}),
		attendeeIds: arrayUnion(user.uid),
	});
}

export async function addUntactToFirestore(untact) {
	const user = auth.currentUser;
	return await addDoc(collection(db, 'untacts'), {
		...untact,
		hostUid: user.uid,
		hostedBy: user.displayName,
	});
}

export async function updateEventInFirestore(event) {
	const eventDocRef = doc(db, 'events', event.id);

	return await updateDoc(eventDocRef, event);
}

export async function updateUntactInFirestore(untact) {
	const untactDocRef = doc(db, 'untacts', untact.id);

	return await updateDoc(untactDocRef, untact);
}

export async function deleteEventInFirestore(eventId) {
	const eventDocRef = doc(db, 'events', eventId);

	return await deleteDoc(eventDocRef);
}

export async function deleteUntactInFirestore(untactId) {
	const untactDocRef = doc(db, 'untacts', untactId);

	return await deleteDoc(untactDocRef);
}

export async function setUserProfileData(user) {
	const userDocRef = doc(db, 'users', user.uid);

	await setDoc(userDocRef, {
		authLevel: 10,
		companyName: user.displayName,
		// staffName: user?.staffName,
		// companyPhone: user.companyPhone,
		email: user.email,
		photoURL: user.photoURL || null,
		createdAt: serverTimestamp(),
		id: user.uid,
	});
}

export function getUserProfile(userId) {
	return doc(db, 'users', userId);
}

///////////////////////////////////
// 직원 문서의 특정 필드를 업데이트하는 함수
async function updateWorkerFields(updates, companyId) {
	try {
		const workersCollectionRef = collection(db, 'workers');

		// 특정 companyId가 있는 모든 문서를 가져오기 위한 쿼리
		const queryAllWorkers = query(
			workersCollectionRef,
			where('hostUid', '==', companyId)
		);

		const querySnapshotAllWorkers = await getDocs(queryAllWorkers);

		// 트랜잭션을 사용하여 문서들을 업데이트
		await runTransaction(db, async (transaction) => {
			querySnapshotAllWorkers.docs.forEach((document) => {
				const workerDocRef = doc(db, 'workers', document.id);
				transaction.update(workerDocRef, updates);
			});
		});
	} catch (error) {
		console.error('필드 업데이트 중 오류 발생:', error);
	}
}

// 사용자 프로필을 업데이트하는 함수
export async function updateUserProfile(profile) {
	const referralAgentRef = doc(collection(db, 'users'), profile.id);
	const docSnap = await getDoc(referralAgentRef);

	const referralAgentType = docSnap?.data()?.agentType;
	const referralAgentRegion = docSnap?.data()?.companyRegion;
	const referralAgentCompanyName = docSnap?.data()?.companyName;
	const referralAgentCompanyPhone = docSnap?.data()?.companyPhone;
	const referralAgentStaffName = docSnap?.data()?.staffName;

	const encryptoUser = {};
	for (const prop in profile) {
		encryptoUser[prop] =
			prop === 'ceoSocialNumberFront' || prop === 'ceoSocialNumberBack'
				? encrypto(profile[prop], String(process.env.CRYPTO_KEY))
				: profile[prop];
	}

	const userDocRef = doc(collection(db, 'users'), profile.id);

	const updates = {};
	if (profile?.insureManageType)
		updates.insureManageType = profile?.insureManageType;
	if (profile?.companyBizNumber)
		updates.companyBizNumber = profile?.companyBizNumber;
	if (profile?.ruleLaborCouncilConsulting)
		updates.ruleLaborCouncilConsulting = profile?.ruleLaborCouncilConsulting;
	if (profile?.companyName) updates.companyName = profile?.companyName;
	if (profile?.companyAddress) updates.companyAddress = profile?.companyAddress;
	if (profile?.numOfWorkers) updates.numOfWorkers = profile?.numOfWorkers;
	if (profile?.ceoName) updates.ceoName = profile?.ceoName;
	if (profile?.roeConsulting) updates.roeConsulting = profile?.roeConsulting;
	if (profile?.companyRegion) updates.companyRegion = profile?.companyRegion;

	// 직원 문서 필드 업데이트
	await updateWorkerFields(updates, profile?.id);

	return await updateDoc(userDocRef, {
		...encryptoUser,

		referralAgentType: referralAgentType || '',
		referralAgentRegion: referralAgentRegion || '',
		referralAgentCompanyName: referralAgentCompanyName || '',
		referralAgentCompanyPhone: referralAgentCompanyPhone || '',
		referralAgentStaffName: referralAgentStaffName || '',

		psNomusaCompanyUid: psNomusaCompanyUid(profile.companyRegion),
		psNomusaCompanyName: psNomusaCompanyName(profile.companyRegion),
		psNomusaCeoName: psNomusaCeoName(profile.companyRegion),
		psNomusaName: psNomusaName(profile.companyRegion),
		psNomusaAddress: psNomusaAddress(profile.companyRegion),
		psNomusaPhone: psNomusaPhone(profile.companyRegion),
		psSemusaCompanyUid: psSemusaCompanyUid(profile.companyRegion),
		psSemusaCompanyName: psSemusaCompanyName(profile.companyRegion),
		psSemusaCeoName: psSemusaCeoName(profile.companyRegion),
		psSemusaName: psSemusaName(profile.companyRegion),
		psSemusaPhone: psSemusaPhone(profile.companyRegion),
		psSemusaAddress: psSemusaAddress(profile.companyRegion),
		updateDate: serverTimestamp(),
	});
}

export async function updateUserProfilePhoto(downloadURL, filename) {
	const user = auth.currentUser;
	const userDocRef = doc(collection(db, 'users'), user.uid);
	try {
		const userDoc = await getDoc(userDocRef);
		if (!userDoc.data().photoURL) {
			await updateDoc(userDocRef, {
				photoURL: downloadURL,
			});
			await updateProfile(user, {
				photoURL: downloadURL,
			});
		}
		return await addDoc(collection(userDocRef, 'photos'), {
			name: filename,
			url: downloadURL,
		});
	} catch (error) {
		throw error;
	}
}

export function getUserPhotos(userUid) {
	return collection(doc(collection(db, 'users'), userUid), 'photos');
}

export async function setMainPhoto(photo) {
	const user = auth.currentUser;
	const today = new Date();
	const eventDocQuery = query(
		collection(db, 'events'),
		where('attendeeIds', 'array-contains', user.uid),
		where('date', '>=', today)
	);
	const userFollowingRef = collection(
		doc(collection(db, 'following'), user.uid),
		'userFollowing'
	);

	const batch = writeBatch(db);

	batch.update(doc(collection(db, 'users'), user.uid), {
		photoURL: photo.url,
	});

	try {
		const eventsQuerySnap = await getDocs(eventDocQuery);
		for (let i = 0; i < eventsQuerySnap.docs.length; i++) {
			let eventDoc = eventsQuerySnap.docs[i];
			if (eventDoc.data().hostUid === user.uid) {
				batch.update(eventDoc.ref, {
					hostPhotoURL: photo.url,
				});
			}
			batch.update(eventDoc.ref, {
				attendees: eventDoc.data().attendees.map((attendee) => {
					if (attendee.id === user.uid) {
						attendee.photoURL = photo.url;
					}
					return attendee;
				}),
			});
		}

		const userFollowingSnap = await getDocs(userFollowingRef);
		userFollowingSnap.docs.forEach((docRef) => {
			let followingDocRef = doc(
				collection(
					doc(collection(db, 'following'), docRef.id),
					'userFollowers'
				),
				user.uid
			);
			batch.update(followingDocRef, {
				photoURL: photo.url,
			});
		});

		await batch.commit();

		return await updateProfile(user, {
			photoURL: photo.url,
		});
	} catch (error) {
		throw error;
	}
}

export async function deletePhotoFromCollection(photoId) {
	const userUid = auth.currentUser.uid;
	return await deleteDoc(
		doc(collection(doc(collection(db, 'users'), userUid), 'photos'), photoId)
	);
}

export async function addUserAttendance(event) {
	const user = auth.currentUser;
	return await updateDoc(doc(db, 'events', event.id), {
		attendees: arrayUnion({
			id: user.uid,
			companyName: user.displayName,
			photoURL: user.photoURL || null,
		}),
		attendeeIds: arrayUnion(user.uid),
	});
}

export async function cancelUserAttendance(event) {
	const user = auth.currentUser;
	try {
		const eventDoc = await getDoc(doc(db, 'events', event.id));
		return await updateDoc(doc(db, 'events', event.id), {
			attendeeIds: arrayRemove(user.uid),
			attendees: eventDoc
				.data()
				.attendees.filter((attendee) => attendee.id !== user.uid),
		});
	} catch (error) {
		throw error;
	}
}

export function getUserEventsQuery(activeTab, userUid) {
	let eventsRef = collection(db, 'events');
	const today = new Date();

	switch (activeTab) {
		case 1: //past events
			return query(
				eventsRef,
				where('attendeeIds', 'array-contains', userUid),
				where('date', '<=', today),
				orderBy('date', 'desc')
			);
		case 2: //hosting
			return query(eventsRef, where('hostUid', '==', userUid), orderBy('date'));
		default:
			return query(
				eventsRef,
				where('attendeeIds', 'array-contains', userUid),
				where('date', '>=', today),
				orderBy('date')
			);
	}
}

export async function followUser(profile) {
	const user = auth.currentUser;
	const batch = writeBatch(db);

	try {
		batch.set(
			doc(
				collection(doc(collection(db, 'following'), user.uid), 'userFollowing'),
				profile.id
			),
			{
				companyName: profile.displayName,
				photoURL: profile.photoURL,
				uid: profile.id,
			}
		);
		batch.update(doc(db, 'users', user.uid), {
			followingCount: increment(1),
		});
		return await batch.commit();
	} catch (error) {
		throw error;
	}
}

export async function unfollowUser(profile) {
	const user = auth.currentUser;
	const batch = writeBatch(db);

	try {
		batch.delete(
			doc(
				collection(doc(collection(db, 'following'), user.uid), 'userFollowing'),
				profile.id
			)
		);
		batch.update(doc(db, 'users', user.uid), {
			followingCount: increment(-1),
		});
		return await batch.commit();
	} catch (error) {
		throw error;
	}
}

export function getFollowersCollection(profileId) {
	return collection(
		doc(collection(db, 'following'), profileId),
		'userFollowers'
	);
}

export function getFollowingCollection(profileId) {
	return collection(
		doc(collection(db, 'following'), profileId),
		'userFollowing'
	);
}

export async function getFollowingDoc(profileId) {
	const userId = auth.currentUser.uid;
	return doc(
		collection(doc(collection(db, 'following'), userId), 'userFollowing'),
		profileId
	);
}
