import {format} from 'date-fns';

export const COLUMNS = [
  {
    Header:'일시',
    accessor: 'date',
    Cell: ({value}) => {return format(new Date(value), 'yyyy-MM-dd h:mm')},
    disableFilters: true,
    sortType: (({a, b}) => {
      const a1 = new Date(a).getTime();
      const b1 = new Date(b).getTime();
      if(a1>b1) {
        return -1;
      } else if(a1===b1) {
        return 0;
      } else {
        return 1;
      }
    })
  },
  {
    Header:'질문구분',
    accessor: 'counselingCategory',
    disableFilters: true,
  },
  {
    Header:'소요(분)',
    accessor: 'billableHour',
    disableFilters: true,
    style: {
      textAlign: 'center'
    }
  },
  {
    Header: '고객구분',
    accessor: 'customerType',
    disableFilters: true
  },
  {
    Header:'회사명',
    accessor: 'clientName',
    disableFilters: true
  },
  {
    Header: '요청자',
    accessor: 'personName',
    disableFilters: true
  },
  {
    Header:'분야',
    accessor: 'workType',
  //   accessor : workType => {
  //     let output = [];
  //     workType.map(workType => {
  //       return output.push(workType);
  //     });
  //     return output.join(', ');
  // },
    disableFilters: true
  },

  {
    Header:'형식',
    accessor: 'counselingType',
    disableFilters: true
  },

  {
    Header:'지역',
    accessor: 'region',
    disableFilters: true,
    style: {
      textAlign: 'center',
    },
  },  
  {
    Header:'작성자',
    accessor: 'hostedBy',
    disableFilters: true
  },
  {
    Header:'업무근거',
    accessor: 'counselingBasis',
    disableFilters: true
  },
  {
    Header:'id',
    accessor: "id",
    disableFilters: true
  },
];