import { Formik, Form } from 'formik';
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { Link, Redirect } from 'react-router-dom';
import { Button, Checkbox, Segment } from 'semantic-ui-react'
import * as Yup from 'yup';
import useFirestoreDoc from '../../../app/hooks/useFirestoreDoc';
import LoadingComponent from '../../../app/layout/LoadingComponent';
import { toast } from 'react-toastify';
import { clearSelectedMonthlyReport, listenToSelectedMonthlyReport } from '../eventActions';
import {
  addMonthlyCompetencyReportsToFirestore,
  listenToMonthlyCompetencyReportsFromFirestore,
  updateMonthlyCompetencyReportsInFirestore
} from '../../../app/firestore/firestoreService4In';
import { weekNumberByMonth } from '../../../app/common/util/util'
import MyTextArea from '../../../app/common/form/MyTextArea';
// import MyNumberInput from '../../../app/common/form/MyNumberInput';

export default function MonthlyCompetencyReportForm({ match, history, location }) {
  const dispatch = useDispatch();
  const { selectedMonthlyReport } = useSelector(state => state.eventStore)
  const { loading, error } = useSelector(state => state.async);
  const { currentUserProfile} = useSelector(state => state.profile);

  const companyId = selectedMonthlyReport?.companyId ? selectedMonthlyReport?.companyId : currentUserProfile?.companyId 
  const workerId = selectedMonthlyReport?.workerId ? selectedMonthlyReport?.workerId : currentUserProfile?.paydocuWorkerUid;
  const isWorker = currentUserProfile?.isWorker;

  const [openToCustomer, setOpenToCustomer] = useState(false);
  const [openToCoWorker, setOpenToCoWorker] = useState(false);
  const [openToCommunication, setOpenToCommunication] = useState(false);
  const [openToWorkRecord, setOpenToWorkRecord] = useState(false);
  const [openToWorkAnalysis, setOpenToWorkAnalysis] = useState(false);
  const [openToStudy, setOpenToStudy] = useState(false);
  const [openProposal, setOpenProposal] = useState(false);
  const [openHopeEducation, setOpenHopeEducation] = useState(false);
  const [openGrievance, setOpenGrievance] = useState(false);
  const [openCompliance, setOpenCompliance] = useState(false);

  useEffect(() => {
    if (location.pathname !== '/createCompetencyMonthlyReport') return;
    dispatch(clearSelectedMonthlyReport());
  }, [location.pathname, dispatch])

  const initialValues = selectedMonthlyReport ?? {
    mission: '',
    vision: '',
    toCustomer : {
      context : '',
      reason : ''
    },
    toCustomerFeedBack : '',
    toCoWorker : {
      name: '',
      context : '',
      reason : '',
    },
    fromCoWorker : {
      name: '',
      context : '',
      reason : '',
    },
    toCoWorkerFeedBack : '',

    toCommunication : {
      name: '',
      context : '',
      reason : '',
      noHowName: '',
      noHowContext : '',
      noHowReason : '',
    },
    toCommunicationFeedBack : '',

    toWorkRecord : {
      proudJob: '',
      selfPlanedJob : '',
      unSupportedJob: '',
    },
    toWorkRecordFeedBack : '',

    toWorkAnalysis : {
      happyJob: '',
      happyJobReason : '',
      sadJob : '',
      sadJobReason: '',
    },
    toWorkAnalysisFeedBack : '',

    toStudy : {
      job: '',
      personnal : '',
    },
    toStudyFeedBack : '',

    proposal: '',
    proposalFeedBack : '',

    hopeEducation : {
      job: '',
      personnal : '',
    },
    hopeEducationFeedBack : '',

    grievance: '',
    grievanceFeedBack : '',

    compliance: '',
    complianceFeedBack : '',

  };

  const validationSchema = Yup.object({
    // date: Yup.string().required('일시를 입력하세요.'),
    // billableHour: Yup.string().required('소요시간은 분단위로 숫자입력'),
    // customerType: Yup.string().required('고객유형을 선택하세요.'),
    // answerType: Yup.string().required('처리형식을 선택하세요.'),
    // counselingType: Yup.string().required('형식을 선택하세요.'),
    // region: Yup.string().required('지역을 입력하세요.'),
    // clientName: Yup.string().required('기관명은 필수입니다.'),
    // personName: Yup.string().required('요청자명은 필수입니다.'),
    // counselingCategory: Yup.string().required('상담 분야는 필수 입니다.'),
    // question: Yup.string().required('요청사항 또는 질문을 입력하세요.'),
    // workType: Yup.array().of(Yup.string()).required('업무/사건/상담'),
    // counselingBasis: Yup.string().required('답변 근거를 입력하세요.'),
    // answer: Yup.string().required('답변을 입력하세요.'),
  });

  useFirestoreDoc({
    query: () => match.params.id ? listenToMonthlyCompetencyReportsFromFirestore(match.params.id) : null,
    data: report => dispatch(listenToSelectedMonthlyReport(report)),
    deps: [match.params.id, dispatch],
    shouldExcute: match.params.id !== selectedMonthlyReport?.id && location.pathname !== '/createMonthlyReport',
  })

  if (loading)
    return <LoadingComponent content='Loading report ...' />;
  if (error) return <Redirect to='/monthlyReports' />;

  const getWeekJS = weekNumberByMonth(new Date());

  const weekNo = getWeekJS.weekNo;
  const monthNo = getWeekJS.month;


  return (
    <>
      <Segment clearing>
        <Formik
          enableReinitialize
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={async (values, { setSubmitting }) => {
            try {
              selectedMonthlyReport
                ? await updateMonthlyCompetencyReportsInFirestore(values)
                : await companyId ? addMonthlyCompetencyReportsToFirestore(values, companyId, workerId, weekNo, monthNo) : alert('직원만 입력가능합니다.')
              setSubmitting(false);
              history.push('/monthlyReports');
            } catch (error) {
              console.log(error.message);
              toast.error(error.message);
              setSubmitting(false);
            }
          }}
        >
          {({ isSubmitting, dirty, isValid, values }) => (
            <Form className='ui form'>
              <h1 style={{marginTop : '1em'}}>{getWeekJS.month} 월 {getWeekJS.weekNo} 주차 역량모델링, 건의사항 등 WEEKLY REPORT</h1>
              {/* <h1 style={{ marginTop: '1em' }}>{getWeekJS.month}월 역량모델링, 건의사항 등 MONTHLY REPORT</h1> */}
              <p>본 REPORT는 근로계약서 제8조 직무관리에 근거하며, 고성과자의 행동패턴을 찾고 분석하는 역량모델링 및 업무분석하고 건의사항, 애로사항 등을 수집하여 귀하와 회사 그리고 고객 모두의 가치 추구를 위한 것이므로 성실히 작성해 주시기 바랍니다. </p>
              <br />
              <hr />
                  <div style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'flex-start', alignItems: 'center', alignContent: 'flex-end' }}>
                    <div className='margin' style={{ width: '99.5%', marginRight: '3px', fontSize: '20px' }}>
                      <MyTextArea
                        disabled={!isWorker}
                        label={<span>우리의 <span style={{ color: 'blue' }}>미션(Mission)</span>은? </span>}
                        name='mission'
                        rows={1}
                        autoComplete='off'
                      />
                    </div>
                    <div className='margin' style={{ width: '99.5%', marginRight: '3px', fontSize: '20px' }}>
                      <MyTextArea
                        disabled={!isWorker}
                        label={<span>우리의 <span style={{ color: 'blue' }}>비전(Vision)</span>은? </span>}
                        name='vision'
                        rows={1}
                        autoComplete='off'
                      />
                    </div>
                  </div>
                <hr />
              <p style={{ color: 'blue', fontSize: '1.3rem' }}>* 역량 모델링 기록하기<span style={{color:'purple', fontSize:'1rem'}}> (항목을 클릭하면 문항이 나옵니다.)</span> </p>
              <>
                <Checkbox 
                  label="1. 고객(내부, 외부) 지향 역량 기록하기"
                  onChange={() => setOpenToCustomer(!openToCustomer)} 
                  type='checkbox' 
                />
                {openToCustomer &&
                <>
                  <hr />
                  <div style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'flex-start', alignItems: 'center', alignContent: 'flex-end' }}>
                    <div className='margin' style={{ width: '99.5%', marginRight: '3px' }}>
                      <MyTextArea
                        disabled={!isWorker}
                        label={<span>고객으로부터 <span style={{ color: 'red' }}>칭찬받은 업무</span> 내용은?</span>}
                        placeholder='해당 사항이 없는 경우 "없음"으로 적어주세요'
                        name='toCustomer.context'
                        rows={1}
                        autoComplete='off'
                      />
                    </div>
                    <div className='margin' style={{ width: '99.5%', marginRight: '3px' }}>
                      <MyTextArea
                        label={<span>고객이 <span style={{ color: 'red' }}>칭찬한 이유</span>는 무엇인가?</span>}
                        placeholder='해당 사항이 없는 경우 없음으로 적어주세요'
                        name='toCustomer.reason'
                        rows={1}
                        autoComplete='off'
                        disabled={!isWorker}
                      />
                    </div>
                    {isWorker ? null :  
                      <div className='margin' style={{ width: '99.5%', marginRight: '3px' }}>
                        <MyTextArea
                          label={<p style={{color : 'teal'}}>동료 지향 역량에 대한 관리자 피드백</p> }
                          name='toCustomerFeedBack'
                          rows={1}
                          autoComplete='off'
                        />
                      </div>
                    }
                  </div>
                  </>
                }
                <br />
                <Checkbox 
                  label="2. 같은 부서 동료 지향 역량 기록하기"
                  onChange={() => setOpenToCoWorker(!openToCoWorker)} 
                  type='checkbox' 
                />
                {openToCoWorker && 
                  <>
                    <hr />
                    <div style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'flex-start', alignItems: 'center', alignContent: 'flex-end' }}>
                      <div className='margin' style={{ width: '49.5%', marginRight: '3px' }}>
                        <MyTextArea
                          label={<span><span style={{ color: 'red' }}>귀하가 도움을 준 </span> 동료(선후배) 또는 동료들의 <span style={{color: "red"}}>이름</span>을 작성하세요.</span> }
                          name='toCoWorker.name'
                          placeholder='해당 사항이 없는 경우 "없음"으로 적어주세요'
                          rows={1}
                          disabled={!isWorker}
                          autoComplete='off'
                        />
                      </div>
                      <div className='margin' style={{ width: '99.5%', marginRight: '3px' }}>
                        <MyTextArea
                          label={<span>귀하가 위 동료별로 도와준 <span style={{color: "red"}}>업무 내용</span>은 무엇인지요?</span> }
                          placeholder='해당 사항이 없는 경우 "없음"으로 적어주세요'
                          name='toCoWorker.context'
                          rows={1}
                          disabled={!isWorker}
                          autoComplete='off'
                        />
                      </div>
                      <div className='margin' style={{ width: '99.5%', marginRight: '3px' }}>
                        <MyTextArea
                          label={<span>귀하가 위 동료별로 도와준 <span style={{color: "red"}}>이유</span>는 무엇인지요?</span> }
                          placeholder='해당 사항이 없는 경우 "없음"으로 적어주세요'
                          name='toCoWorker.reason'
                          rows={1}
                          disabled={!isWorker}
                          autoComplete='off'
                        />
                      </div>
                    </div>
                    <br />
                    <br />
                    <div style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'flex-start', alignItems: 'center', alignContent: 'flex-end' }}>
                      <div className='margin' style={{ width: '49.5%', marginRight: '3px' }}>
                        <MyTextArea
                          label={<span><span style={{ color: 'red' }}>귀하를 도와준 </span> 동료(선후배) 또는 동료들의 <span style={{color: "red"}}>이름</span>을 작성하세요.</span>}
                          name='fromCoWorker.name'
                          placeholder='해당 사항이 없는 경우 "없음"으로 적어주세요'
                          rows={1}
                          disabled={!isWorker}
                          autoComplete='off'
                        />
                      </div>
                      <div className='margin' style={{ width: '99.5%', marginRight: '3px' }}>
                        <MyTextArea
                          label={<span>동료(선후배) 또는 동료들이 귀하를 도와준 <span style={{color: "red"}}>업무 내용</span>를 작성하세요.</span>}
                          placeholder='해당 사항이 없는 경우 "없음"으로 적어주세요'
                          name='fromCoWorker.context'
                          rows={1}
                          disabled={!isWorker}
                          autoComplete='off'
                        />
                      </div>
                      <div className='margin' style={{ width: '99.5%', marginRight: '3px' }}>
                        <MyTextArea
                          label={<span>동료(선후배) 또는 동료들이 귀하를 <span style={{color: "red"}}>도와준 이유</span>를 작성하세요.</span>}
                          placeholder='해당 사항이 없는 경우 "없음"으로 적어주세요'
                          name='fromCoWorker.reason'
                          rows={1}
                          disabled={!isWorker}
                          autoComplete='off'
                        />
                      </div>
                      {isWorker ? null :  
                        <div className='margin' style={{ width: '99.5%', marginRight: '3px' }}>
                          <MyTextArea
                            label={<p style={{color : 'teal'}}>동료 지향 역량에 대한 관리자 피드백</p> }
                            name='toCoWorkerFeedBack'
                            rows={1}
                            autoComplete='off'
                          />
                        </div>
                      }
                    </div>
                  </>
                  }
                <br />
                <Checkbox 
                  label="3. 타부서 커뮤니케이션 지향 역량 기록하기"
                  onChange={() => setOpenToCommunication(!openToCommunication)} 
                  type='checkbox' 
                />
                {openToCommunication &&
                <>
                <hr />
                <div style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'flex-start', alignItems: 'center', alignContent: 'flex-end' }}>
                  <div className='margin' style={{ width: '49.5%', marginRight: '3px' }}>
                    <MyTextArea
                      label={<span>귀하가 <span style={{color: "red"}}>업무를 함께한 타부서 대상자</span>는 누구인가요?</span>}
                      placeholder='해당 사항이 없는 경우 "없음"으로 적어주세요'
                      name='toCommunication.name'
                      rows={1}
                      disabled={!isWorker}
                      autoComplete='off'
                    />
                  </div>
                  <div className='margin' style={{ width: '99.5%', marginRight: '3px' }}>
                    <MyTextArea
                      label={<span>함께한 <span style={{color: "red"}}>업무 내용</span>은 무엇인가요?</span>}
                      placeholder='해당 사항이 없는 경우 "없음"으로 적어주세요'
                      name='toCommunication.context'
                      rows={1}
                      disabled={!isWorker}
                      autoComplete='off'
                    />
                  </div>
                  <div className='margin' style={{ width: '99.5%', marginRight: '3px' }}>
                    <MyTextArea
                      label={<span>함께한 <span style={{color: "red"}}>이유</span>는 무엇인가요?</span>}
                      placeholder='해당 사항이 없는 경우 "없음"으로 적어주세요'
                      name='toCommunication.reason'
                      rows={1}
                      disabled={!isWorker}
                      autoComplete='off'
                    />
                  </div>
                </div>
                <br />
                <br />
                <div style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'flex-start', alignItems: 'center', alignContent: 'flex-end' }}>
                  <div className='margin' style={{ width: '49.5%', marginRight: '3px' }}>
                    <MyTextArea
                      label={<span>귀하가 자발적으로 공유한 <span style={{color: "red"}}>지식 및 노하우를 공유한 대상자</span>는 누구인가요?</span>}
                      placeholder='해당 사항이 없는 경우 "없음"으로 적어주세요'
                      name='toCommunication.noHowName'
                      rows={1}
                      disabled={!isWorker}
                      autoComplete='off'
                    />
                  </div>
                  <div className='margin' style={{ width: '100%', marginRight: '3px' }}>
                    <MyTextArea
                      label={<span>귀하가 자발적으로 공유한 <span style={{color: "red"}}>지식 및 노하우 내용</span>은 무엇인가요?</span>}
                      placeholder='해당 사항이 없는 경우 "없음"으로 적어주세요'
                      name='toCommunication.noHowContext'
                      rows={1}
                      disabled={!isWorker}
                      autoComplete='off'
                    />
                  </div>
                  <div className='margin' style={{ width: '100%', marginRight: '3px' }}>
                    <MyTextArea
                      label={<span>귀하가 자발적으로 공유한 <span style={{color: "red"}}>지식 및 노하우 공유 이유</span>는 무엇인가요?</span>}
                      placeholder='해당 사항이 없는 경우 "없음"으로 적어주세요'
                      name='toCommunication.noHowReason'
                      rows={1}
                      disabled={!isWorker}
                      autoComplete='off'
                    />
                  </div>
                  {isWorker ? null :  
                    <div className='margin' style={{ width: '99.5%', marginRight: '3px' }}>
                      <MyTextArea
                        label={<p style={{color : 'teal'}}>커뮤니케이션 지향 역량에 대한 관리자 피드백</p> }
                        name='toCommunicationFeedBack'
                        rows={1}
                        autoComplete='off'
                      />
                    </div>
                  }
                </div>
                </>
                }
                <br />
                <Checkbox 
                  label="4. 업무 수행 역량 기록하기"
                  onChange={() => setOpenToWorkRecord(!openToWorkRecord)} 
                  type='checkbox' 
                />
                {openToWorkRecord &&
                <>
                <hr />
                <div style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'flex-start', alignItems: 'center', alignContent: 'flex-end' }}>
                  <div className='margin' style={{ width: '99.5%', marginRight: '3px' }}>
                    <MyTextArea
                      label={<span>귀하가 수행한 업무 중 동료들에게 <span style={{color: "red"}}>모범이 되는 일</span>은 무엇인가요?</span>}
                      placeholder='해당 사항이 없는 경우 없음으로 적어주세요'
                      name='toWorkRecord.proudJob'
                      rows={1}
                      disabled={!isWorker}
                      autoComplete='off'
                    />
                  </div>
                  <div className='margin' style={{ width: '99.5%', marginRight: '3px' }}>
                    <MyTextArea
                      label={<span>귀하가 수행한 업무 중 상사 등의 지시없이 <span style={{color: "red"}}>스스로 추친한 일</span>은 무엇인가요?</span>}

                      placeholder='해당 사항이 없는 경우 없음으로 적어주세요'
                      name='toWorkRecord.selfPlanedJob'
                      rows={1}
                      disabled={!isWorker}
                      autoComplete='off'
                    />
                  </div>
                  <div className='margin' style={{ width: '99.5%', marginRight: '3px' }}>
                    <MyTextArea
                      label={<span>귀하가 수행한 업무 중 동료들에게 <span style={{color: "red"}}>도움이 필요했던 일</span>은 무엇인가요?</span>}
                      placeholder='해당 사항이 없는 경우 없음으로 적어주세요'
                      name='toWorkRecord.unSupportedJob'
                      rows={1}
                      disabled={!isWorker}
                      autoComplete='off'
                    />
                  </div>
                  {isWorker ? null :  
                    <div className='margin' style={{ width: '99.5%', marginRight: '3px' }}>
                      <MyTextArea
                        label={<p style={{color : 'teal'}}>업무수행 역량에 대한 관리자 피드백</p> }
                        name='toWorkRecordFeedBack'
                        rows={1}
                        autoComplete='off'
                      />
                    </div>
                  }
                </div>
                </>}
                <br />
                <Checkbox 
                  label="5. 업무 분석 역량 기록하기"
                  onChange={() => setOpenToWorkAnalysis(!openToWorkAnalysis)} 
                  type='checkbox' 
                />
                {openToWorkAnalysis &&
                <>
                <hr />
                <div style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'flex-start', alignItems: 'center', alignContent: 'flex-end' }}>
                  <div className='margin' style={{ width: '49.5%', marginRight: '3px' }}>
                    <MyTextArea
                      label={<span>귀하가 수행한 업무 중 본인이 <span style={{color: "red"}}>가장 즐겁게 수행한 일</span>은 무엇인가요?</span>}
                      placeholder='해당 사항이 없는 경우 "없음"으로 적어주세요'
                      name='toWorkAnalysis.happyJob'
                      rows={1}
                      disabled={!isWorker}
                      autoComplete='off'
                    />
                  </div>
                  <div className='margin' style={{ width: '99.5%', marginRight: '3px' }}>
                    <MyTextArea
                      label={<span>위 업무를 <span style={{color: "red"}}>가장 즐겁게 수행한 이유</span>는 무엇인가요?</span>}
                      placeholder='해당 사항이 없는 경우 "없음"으로 적어주세요'
                      name='toWorkAnalysis.happyJobReason'
                      rows={1}
                      disabled={!isWorker}
                      autoComplete='off'
                    />
                  </div>
                </div>
                <br />
                <div style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'flex-start', alignItems: 'center', alignContent: 'flex-end' }}>
                  <div className='margin' style={{ width: '49.5%', marginRight: '3px' }}>
                    <MyTextArea
                      label={<span>귀하가 수행한 업무 중 본인이 <span style={{color: "red"}}>가장 힘들게 수행한 일</span>은 무엇인가요?</span>}
                      placeholder='해당 사항이 없는 경우 "없음"으로 적어주세요'
                      name='toWorkAnalysis.sadJob'
                      rows={1}
                      disabled={!isWorker}
                      autoComplete='off'
                    />
                  </div>
                  <div className='margin' style={{ width: '99.5%', marginRight: '3px' }}>
                    <MyTextArea
                      label={<span>위 업무를 <span style={{color: "red"}}>가장 힘들게 수행한 이유</span>는 무엇인가요?</span>}
                      placeholder='해당 사항이 없는 경우 "없음"으로 적어주세요'
                      name='toWorkAnalysis.sadJobReason'
                      rows={1}
                      disabled={!isWorker}
                      autoComplete='off'
                    />
                  </div>
                  {isWorker ? null :  
                    <div className='margin' style={{ width: '99.5%', marginRight: '3px' }}>
                      <MyTextArea
                        label={<p style={{color : 'teal'}}>업무분석 역량에 대한 관리자 피드백</p> }
                        name='toWorkAnalysisFeedBack'
                        rows={1}
                        autoComplete='off'
                      />
                    </div>
                  }
                </div>

                </>}
                <br />
                <Checkbox 
                  label="6. 자기 개발 역량 기록하기"
                  onChange={() => setOpenToStudy(!openToStudy)} 
                  type='checkbox' 
                />
                {openToStudy && 
                <>
                <hr />
                <div style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'flex-start', alignItems: 'center', alignContent: 'flex-end' }}>
                  <div className='margin' style={{ width: '99.5%', marginRight: '3px' }}>
                    <MyTextArea
                      label={<span><span style={{color:'red'}}>업무관련 학습</span>(책 읽기, 강좌 듣기 등) 내용과 <span style={{color: "red"}}>동료들과 공유하고 싶은 내용</span>은 무엇인가요?</span>}
                      placeholder='해당 사항이 없는 경우 "없음"으로 적어주세요'
                      name='toStudy.job'
                      rows={1}
                      disabled={!isWorker}
                      autoComplete='off'
                    />
                  </div>
                  <div className='margin' style={{ width: '99.5%', marginRight: '3px' }}>
                    <MyTextArea
                      label={<span><span style={{color:'red'}}>본인의 발전을 위한 학습</span>(책 읽기, 강좌 듣기 등) 내용과 <span style={{color: "red"}}>동료들과 공유하고 싶은 내용</span>은 무엇인가요?</span>}
                      placeholder='해당 사항이 없는 경우 "없음"으로 적어주세요'
                      name='toStudy.personnal'
                      rows={1}
                      disabled={!isWorker}
                      autoComplete='off'
                    />
                  </div>
                  {isWorker ? null :  
                    <div className='margin' style={{ width: '99.5%', marginRight: '3px' }}>
                      <MyTextArea
                        label={<p style={{color : 'teal'}}>자기 개발 역량에 대한 관리자 피드백</p> }
                        name='toStudyFeedBack'
                        rows={1}
                        autoComplete='off'
                      />
                    </div>
                  }
                </div>
                </>}
                <br />
                <p style={{ color: 'blue', fontSize: '1.3rem' }}>* 건의사항 등 기록하기</p>
                <Checkbox 
                  label="1. 업무 개선을 위한 제안사항(해결하고자 하는 문제점을 함께 기재요망)"
                  onChange={() => setOpenProposal(!openProposal)} 
                  type='checkbox' 
                />
                {openProposal && 
                  <>
                    <hr />
                    <div style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'flex-start', alignItems: 'center', alignContent: 'flex-end' }}>
                      <div className='margin' style={{ width: '99.5%', marginRight: '3px' }}>
                        <MyTextArea
                          label={<span>업무 개선을 위한 <span style={{color: "red"}}>제안사항</span>은 무엇인가요?</span>}
                          placeholder='해당 사항이 없는 경우 "없음"으로 적어주세요'
                          name='proposal'
                          disabled={!isWorker}
                          rows={2}
                        />
                      </div>
                      {isWorker ? null :  
                        <div className='margin' style={{ width: '99.5%', marginRight: '3px' }}>
                          <MyTextArea
                            label={<p style={{color : 'teal'}}>제안사항에 대한 관리자 피드백</p> }
                            name='proposalFeedBack'
                            rows={1}
                            autoComplete='off'
                          />
                        </div>
                      }
                    </div>
                  </>
                }
                <br />
                <Checkbox 
                  label="2. 요청하는 교육?"
                  onChange={() => setOpenHopeEducation(!openHopeEducation)} 
                  type='checkbox' 
                />
                {openHopeEducation && 
                  <>
                    <hr />
                    <div style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'flex-start', alignItems: 'center', alignContent: 'flex-end' }}>
                      <div className='margin' style={{ width: '99.5%', marginRight: '3px' }}>
                        <MyTextArea
                          label={<span><span style={{color:'red'}}>업무 개선</span>을 위해 요청하는 <span style={{color: "red"}}>교육</span>은 무엇인가요?</span>}
                          placeholder='해당 사항이 없는 경우 "없음"으로 적어주세요'
                          name='hopeEducation.job'
                          disabled={!isWorker}
                          rows={2}
                        />
                      </div>
                      <div className='margin' style={{ width: '99.5%', marginRight: '3px' }}>
                        <MyTextArea
                          label={<span><span style={{color:'red'}}>본인의 개인적인 개발</span>을 위해 요청하는 <span style={{color: "red"}}>교육</span>은 무엇인가요?</span>}
                          placeholder='해당 사항이 없는 경우 "없음"으로 적어주세요'
                          name='hopeEducation.personnal'
                          disabled={!isWorker}
                          rows={2}
                        />
                      </div>
                      {isWorker ? null :  
                        <div className='margin' style={{ width: '99.5%', marginRight: '3px' }}>
                          <MyTextArea
                            label={<p style={{color : 'teal'}}>요청한 교육에 대한 관리자 피드백</p> }
                            name='hopeEducationFeedBack'
                            rows={1}
                            autoComplete='off'
                          />
                        </div>
                      }
                    </div>
                  </>
                }
                <br />
                <Checkbox 
                  label="3. 본인의 고충사항"
                  onChange={() => setOpenGrievance(!openGrievance)} 
                  type='checkbox' 
                />
                {openGrievance && 
                  <>
                    <hr />
                    <div style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'flex-start', alignItems: 'center', alignContent: 'flex-end' }}>
                      <div className='margin' style={{ width: '99.5%', marginRight: '3px' }}>
                        <MyTextArea
                          label={<span>본인의 <span style={{color: "red"}}>고충사항</span>(When, Where, Who, What, How, Why 등 자세한 내용 기재요망)은 무엇인가요?</span>}
                          placeholder='해당 사항이 없는 경우 "없음"으로 적어주세요'
                          name='grievance'
                          disabled={!isWorker}
                          rows={2}
                        />
                      </div>
                    {isWorker ? null :  
                      <div className='margin' style={{ width: '99.5%', marginRight: '3px' }}>
                        <MyTextArea
                          label={<p style={{color : 'teal'}}>고충사항에 대한 관리자 피드백</p> }
                          name='grievanceFeedBack'
                          rows={1}
                          autoComplete='off'
                        />
                      </div>
                    }
                    </div>
                  </>
                }
                <br />
                <Checkbox 
                  label="4. 컴플라이언스(compliance) 보고 사항"
                  onChange={() => setOpenCompliance(!openCompliance)} 
                  type='checkbox' 
                />
                {openCompliance && 
                  <>
                    <hr />
                    <div style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'flex-start', alignItems: 'center', alignContent: 'flex-end' }}>
                      <div className='margin' style={{ width: '99.5%', marginRight: '3px' }}>
                        <MyTextArea
                          label={<span><span style={{color: "red"}}>컴플라이언스</span>(회사규칙이나 법률 위반사항) 보고 내용(When, Where, Who, What, How, Why 등 자세한 내용 기재요망)은 무엇인가요?</span>} 
                          placeholder='해당 사항이 없는 경우 "없음"으로 적어주세요'
                          name='compliance'
                          disabled={!isWorker}
                          rows={2}
                        />
                      </div>
                      {isWorker ? null :  
                        <div className='margin' style={{ width: '99.5%', marginRight: '3px' }}>
                          <MyTextArea
                            label={<p style={{color : 'teal'}}>컴플라이언스 보고에 대한 관리자 피드백</p> }
                            name='complianceFeedBack'
                            rows={1}
                            autoComplete='off'
                          />
                        </div>
                      }
                    </div>
                  </>
                }
        
                
              </>
              
              <br />
              <br />
              <Button
                loading={isSubmitting}
                disabled={!isValid || !dirty || isSubmitting}
                type='submit'
                floated='right'
                positive
                content='Submit'
              />
              <Button
                disabled={isSubmitting}
                as={Link} to='/monthlyReports'
                type='submit'
                floated='right'
                content='Cancel'
              />
            </Form>
          )}
        </Formik>
      </Segment>
    </>
  )
}
