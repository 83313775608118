import React from 'react';
import { useHistory } from 'react-router-dom';
import { Button, Grid } from 'semantic-ui-react';
import { addUserContractAgreementToFirestore } from '../../app/firestore/firestoreService4In';
import ClientUseContractPaperContents from './ClientUseContractPaperContents';

export default function ClientUseContractPaper() {
  const history = useHistory();
  async function userContractAgreementComfirmF () {
    history.goBack()
    await addUserContractAgreementToFirestore();
  };

  return (
    <>
      <Button color='facebook' onClick={() => userContractAgreementComfirmF()}>이용약관에 동의합니다.</Button>
      <Grid>
        <Grid.Column width={16}>
          <ClientUseContractPaperContents />
        </Grid.Column>
      </Grid>
      <Button color='facebook' onClick={() => userContractAgreementComfirmF()}>상기 이용약관에 동의합니다.</Button>
    </>
  )
}