import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { Grid } from 'semantic-ui-react';
import { numStringToNumber } from '../../../../app/common/util/util';
import { listenToClientReq4InFromFirestore } from '../../../../app/firestore/firestoreService4In';
import useFirestoreDoc from '../../../../app/hooks/useFirestoreDoc';
import LoadingComponent from '../../../../app/layout/LoadingComponent';
import {
	clearClientReqs4In,
	fetchClientReqs4In,
	listenToSelectedClientReq4In,
} from '../clientReqActions4In';
import ClientReqDetailedHeader4In from './ClientReqDetailedHeader4In';
import ClientReqDLCDWageDay from './clientReqDetailedLCDetail/ClientReqDLCDWageDay';
import ClientReqDLCDWageDayPrint from './clientReqDetailedLCDetail/ClientReqDLCDWageDayPrint';
import PaydocuShortCut from '../../../companyManage/paydocuDashboard/PaydocuShortCut';
import ClientReqDLCDWageBiz from './clientReqDetailedLCDetail/ClientReqDLCDWageBiz';

export default function ClientReqDaylyPayContract({ match }) {
	const dispatch = useDispatch();
	const { selectedClientReq4In, clientReqs4In } = useSelector(
		(state) => state.clientReq4InStore
	);
	const { filter } = useSelector((state) => state.companyReducer);

	const { loading, error } = useSelector((state) => state.async);
	const { currentUserProfile } = useSelector((state) => state.profile);

	// 일용직 일당
	const daylyPay = selectedClientReq4In?.daylyPay?.wage
		? numStringToNumber(selectedClientReq4In?.daylyPay?.wage)
		: 0;
	const daylyTimePay = selectedClientReq4In?.daylyPay?.timeWage
		? numStringToNumber(selectedClientReq4In?.daylyPay?.timeWage)
		: 0;

	//노무사는 노무사가 입력한 회사로 회사이름 설정 가능하고, 유저는 본인 회원가입한 상호로만 회사이름 설정됨.
	const isWorker = currentUserProfile?.isWorker;

	const companyName = isWorker
		? currentUserProfile?.workerInputCompanyName
			? currentUserProfile?.workerInputCompanyName
			: selectedClientReq4In?.companyName
		: selectedClientReq4In?.companyName;

	const isBizWorker = selectedClientReq4In?.isBizWorker;

	useFirestoreDoc({
		shouldExcute:
			match.params.id !== selectedClientReq4In?.id &&
			Location.pathname !== '/createClientReq4In',
		query: () => listenToClientReq4InFromFirestore(match.params.id),
		data: (clientReq4In) =>
			dispatch(listenToSelectedClientReq4In(clientReq4In)),
		deps: [match.params.id, dispatch],
	});

	useEffect(() => {
		dispatch(fetchClientReqs4In(filter)).then(() => {});
		return () => {
			dispatch(clearClientReqs4In());
		};
	}, [dispatch, filter]);

	const isDayOrTimeWorker =
		selectedClientReq4In?.dayWorker?.daylyPay || daylyTimePay ? true : false;

	if (loading || (!selectedClientReq4In && !error))
		return <LoadingComponent content='일용직 근로계약서 불러오는 중  ...' />;
	if (error) return <Redirect to='/error' />;

	return (
		<>
			{isWorker ? null : (
				<PaydocuShortCut filter={filter} clientReqs4In={clientReqs4In} />
			)}

			<Grid>
				<Grid.Column width={16}>
					<ClientReqDetailedHeader4In
						selectedClientReq4In={selectedClientReq4In}
						companyName={companyName}
						clientReqs4In={clientReqs4In}
						daylyTimePay={daylyTimePay}
						isDayOrTimeWorker={isDayOrTimeWorker}
						isBizWorker={isBizWorker}
					/>
					<ClientReqDLCDWageDayPrint
						selectedClientReq4In={selectedClientReq4In}
						workerId={selectedClientReq4In.id}
						daylyTimePay={daylyTimePay}
						isBizWorker={isBizWorker}
					/>
					<div id='dayWorkerContract'>
						{isBizWorker ? (
							<ClientReqDLCDWageBiz
								daylyPay={daylyPay}
								currentUserProfile={currentUserProfile}
								selectedClientReq4In={selectedClientReq4In}
								isBizWorker={isBizWorker}
							/>
						) : (
							<ClientReqDLCDWageDay
								daylyPay={daylyPay}
								currentUserProfile={currentUserProfile}
								selectedClientReq4In={selectedClientReq4In}
								daylyTimePay={daylyTimePay}
							/>
						)}
					</div>
				</Grid.Column>
			</Grid>
		</>
	);
}
