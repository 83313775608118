import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Dropdown, Menu } from 'semantic-ui-react';

export default function PaydocuMenu({ FaWonSign, authLevel }) {
	const history = useHistory();

	const [dropdownOpen, setDropdownOpen] = useState(false);
	const [isMobile, setIsMobile] = useState(window.innerWidth < 1000);

	useEffect(() => {
		const handleResize = () => {
			setIsMobile(window.innerWidth < 1000);
		};

		window.addEventListener('resize', handleResize);
		return () => window.removeEventListener('resize', handleResize);
	}, []);

	const handleMouseOver = () => {
		setDropdownOpen(true);
	};

	const handleMouseLeave = () => {
		setDropdownOpen(false);
	};

	const handleDropdownToggle = () => {
		setDropdownOpen(!dropdownOpen);
	};

	function reloadToPaydocus() {
		history.push('/paydocus');
	}

	function reloadToPaydocusCopy() {
		history.push('/paydocusCopy');
	}

	return (
		<>
			<Menu.Item
				className='menu-item'
				onMouseOver={handleMouseOver}
				onMouseLeave={handleMouseLeave}>
				{!isMobile && <FaWonSign className='icon-spacing' />}
				<Dropdown
					style={{ width: '160px' }}
					pointing='top left'
					text={'급여작업 / 명세서보기'}
					open={dropdownOpen}
					onClick={handleDropdownToggle}>
					<Dropdown.Menu>
						<Dropdown.Item
							onClick={() => reloadToPaydocusCopy()}
							text='전체 급여 복사(월)'
							disabled={authLevel <= 10}
						/>
						<Dropdown.Item
							onClick={() => reloadToPaydocus()}
							text='급여작업/명세서보기'
						/>
					</Dropdown.Menu>
				</Dropdown>
			</Menu.Item>
		</>
	);
}
