import React from 'react'
import { Table } from 'semantic-ui-react'
import { decrypto } from '../../../app/firestore/firestoreService'
import { numberToCommaString, roundUp, roundUp2 } from '../../../app/common/util/util'

export default function PaydocuDetailedPageBasicDayTimeWorker({
  daylyTimePay,
  timeWorkerTimeWage,
  timeWorkerTimePaidHoliWage,
  worker,
  workerSocialNumberFront,
  dayWorkerWage,
  dayWokerTimeWage,
  dayWorkerWorkingTimeDayTotal,
  dayWorkerDayTotalPaidConversedTime,
  numOfWorkers,
  dayWorkerWorkingTimeDayBasic,
  dayWorkerPaidHolidayLBTime,
  dayWorkerWorkingTimeDayOver,
  dayWorkerWorkingTimeDayNight,
  countOfHoliDayWork,
  dayWorkerHoliWage,
  dayWorkerHoliPaidTime,
  countOfOverWorkingDayWork,
  dayWorkerOverWorkDayWage,
  dayWorkerOverWorkDayPaidTime
}) {
  return (
    <>
      {daylyTimePay > 0 
        ?
          <>
            <Table.Row>
              <Table.Cell
                style={{ width: '11.11%', textAlign: 'center' }}>성명</Table.Cell>
              <Table.Cell
                style={{ width: '11.11%', textAlign: 'center' }}>생년월일</Table.Cell>
              <Table.Cell
                style={{ width: '11.11%', textAlign: 'center' }}>책정시급</Table.Cell>
              <Table.Cell
                style={{ width: '11.11%', textAlign: 'center' }}>통상임금시급</Table.Cell>
              <Table.Cell
                style={{ width: '11.11%', textAlign: 'center' }}>주휴시급</Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell
                style={{ width: '11.11%', textAlign: 'center' }}>{worker}</Table.Cell>
              <Table.Cell
                style={{ width: '11.11%', textAlign: 'center' }}>{workerSocialNumberFront.length > 6 ? decrypto(workerSocialNumberFront, String(process.env.CRYPTO_KEY)) : workerSocialNumberFront}</Table.Cell>
              <Table.Cell
                style={{ width: '11.11%', textAlign: 'center' }}>{numberToCommaString(roundUp(daylyTimePay))}</Table.Cell>
              <Table.Cell
                style={{ width: '11.11%', textAlign: 'center' }}>{numberToCommaString(roundUp2(timeWorkerTimeWage))}</Table.Cell>                    
              <Table.Cell
                style={{ width: '11.11%', textAlign: 'center' }}>{numberToCommaString(roundUp2(timeWorkerTimePaidHoliWage))}</Table.Cell>
            </Table.Row>
          </>
        :
          <>
            <Table.Row>
              <Table.Cell
                style={{ width: '11.11%', textAlign: 'center' }}>성명 <br /> (생년월일)</Table.Cell>
              <Table.Cell
                style={{ width: '11.11%', textAlign: 'center' }}>평일 일당</Table.Cell>
              <Table.Cell
                style={{ width: '11.11%', textAlign: 'center' }}>통상시급</Table.Cell>
              <Table.Cell
                style={{ width: '11.11%', textAlign: 'center' }}>1일 근로시간 <br /> (전체)</Table.Cell>
              <Table.Cell
                style={{ width: '11.11%', textAlign: 'center' }}>1일 유급시간 <br />(전체)</Table.Cell>
              <Table.Cell
                style={{ width: '11.11%', textAlign: 'center' }}>1일 기본급시간</Table.Cell>
              <Table.Cell
                style={{ width: '11.11%', textAlign: 'center' }}>1일 주휴수당</Table.Cell>
              <Table.Cell
                style={{ width: '11.11%', textAlign: 'center' }}>1일 연장시간</Table.Cell>
              <Table.Cell
                style={{ width: '11.11%', textAlign: 'center' }}>1일 야간시간</Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell
                style={{ width: '11.11%', textAlign: 'center' }}>{worker} <br /> ({workerSocialNumberFront.length > 6 ? decrypto(workerSocialNumberFront, String(process.env.CRYPTO_KEY)) : workerSocialNumberFront})</Table.Cell>
              <Table.Cell
                style={{ width: '11.11%', textAlign: 'center' }}>{numberToCommaString(roundUp(dayWorkerWage))}</Table.Cell>
              <Table.Cell
                style={{ width: '11.11%', textAlign: 'center' }}>{numberToCommaString(roundUp(dayWokerTimeWage))}</Table.Cell>
              <Table.Cell
                style={{ width: '11.11%', textAlign: 'center' }}>{roundUp2(dayWorkerWorkingTimeDayTotal)}</Table.Cell>                    
              <Table.Cell
                style={{ width: '11.11%', textAlign: 'center' }}>{roundUp2(dayWorkerDayTotalPaidConversedTime)}</Table.Cell>
              <Table.Cell
                style={{ width: '11.11%', textAlign: 'center' }}>{roundUp2(numOfWorkers >= 5 ? dayWorkerWorkingTimeDayBasic : dayWorkerWorkingTimeDayTotal)}</Table.Cell>                    
              <Table.Cell
                style={{ width: '11.11%', textAlign: 'center' }}>{roundUp2(dayWorkerPaidHolidayLBTime)}</Table.Cell>
              <Table.Cell
                style={{ width: '11.11%', textAlign: 'center' }}>{roundUp2(dayWorkerWorkingTimeDayOver)} <br /> 연장환산 ({roundUp2(dayWorkerWorkingTimeDayOver * 1.5)})</Table.Cell>
              <Table.Cell
                style={{ width: '11.11%', textAlign: 'center' }}>{roundUp2(dayWorkerWorkingTimeDayNight)} <br /> 야간환산 ({roundUp2(dayWorkerWorkingTimeDayNight * 0.5)})</Table.Cell>
            </Table.Row>
            {countOfHoliDayWork ? 
              <>
                <Table.Row>
                  <Table.Cell
                    style={{ width: '11.11%', textAlign: 'center' }}></Table.Cell>
                  <Table.Cell
                    style={{ width: '11.11%', textAlign: 'center' }}>휴일 일당</Table.Cell>
                  <Table.Cell
                    style={{ width: '11.11%', textAlign: 'center' }}>통상시급</Table.Cell>
                  <Table.Cell
                    style={{ width: '11.11%', textAlign: 'center' }}>1일 근로시간 <br /> (전체)</Table.Cell>
                  <Table.Cell
                    style={{ width: '11.11%', textAlign: 'center' }}>1일 유급시간 <br />(전체)</Table.Cell>
                  <Table.Cell
                    style={{ width: '11.11%', textAlign: 'center' }}>1일 기본급시간</Table.Cell>
                  {numOfWorkers < 5 ? 
                  <Table.Cell
                    style={{ width: '11.11%', textAlign: 'center' }}>1일 휴일보존 수당</Table.Cell> : null}
                  <Table.Cell
                    style={{ width: '11.11%', textAlign: 'center' }}>1일 연장시간</Table.Cell>
                  <Table.Cell
                    style={{ width: '11.11%', textAlign: 'center' }}>1일 야간시간</Table.Cell>
                  {numOfWorkers >= 5 ? 
                  <Table.Cell
                    style={{ width: '11.11%', textAlign: 'center' }}></Table.Cell> : null}
                </Table.Row>
                <Table.Row>
                  <Table.Cell
                    style={{ width: '11.11%', textAlign: 'center' }}></Table.Cell>
                  <Table.Cell
                    style={{ width: '11.11%', textAlign: 'center' }}>{numberToCommaString(roundUp(dayWorkerHoliWage))}</Table.Cell>
                  <Table.Cell
                    style={{ width: '11.11%', textAlign: 'center' }}>{numberToCommaString(roundUp(dayWokerTimeWage))}</Table.Cell>
                  <Table.Cell
                    style={{ width: '11.11%', textAlign: 'center' }}>{roundUp2(dayWorkerWorkingTimeDayTotal)}</Table.Cell>                    
                  <Table.Cell
                    style={{ width: '11.11%', textAlign: 'center' }}>{roundUp2(numOfWorkers >= 5 ? dayWorkerHoliPaidTime : dayWorkerDayTotalPaidConversedTime)}</Table.Cell>
                  <Table.Cell
                    style={{ width: '11.11%', textAlign: 'center' }}>{roundUp2(numOfWorkers >= 5 ? dayWorkerWorkingTimeDayBasic : dayWorkerWorkingTimeDayTotal)} <br /> 휴일기본환산({roundUp2(dayWorkerWorkingTimeDayBasic * 1.5)})</Table.Cell> 
                  {numOfWorkers < 5 ? 
                  <Table.Cell
                    style={{ width: '11.11%', textAlign: 'center' }}>{roundUp2(dayWorkerPaidHolidayLBTime)}</Table.Cell> : null}                   
                  <Table.Cell
                    style={{ width: '11.11%', textAlign: 'center' }}>{roundUp2(dayWorkerWorkingTimeDayOver)} <br /> 휴일연장환산({roundUp2(dayWorkerWorkingTimeDayOver * 2)})</Table.Cell>
                  <Table.Cell
                    style={{ width: '11.11%', textAlign: 'center' }}>{roundUp2(dayWorkerWorkingTimeDayNight)} <br /> 야간환산 ({roundUp2(dayWorkerWorkingTimeDayNight * 0.5)})</Table.Cell>
                  {numOfWorkers >= 5 ? 
                  <Table.Cell
                    style={{ width: '11.11%', textAlign: 'center' }}></Table.Cell> : null}                    
                </Table.Row>
              </>
              : null
              }
              {countOfOverWorkingDayWork ? 
                <>
                  <Table.Row>
                    <Table.Cell
                      style={{ width: '11.11%', textAlign: 'center' }}></Table.Cell>
                    <Table.Cell
                      style={{ width: '11.11%', textAlign: 'center' }}>휴무일 일당</Table.Cell>
                    <Table.Cell
                      style={{ width: '11.11%', textAlign: 'center' }}>통상시급</Table.Cell>
                    <Table.Cell
                      style={{ width: '11.11%', textAlign: 'center' }}>1일 근로시간 <br /> (전체)</Table.Cell>
                    <Table.Cell
                      style={{ width: '11.11%', textAlign: 'center' }}>1일 유급시간 <br />(전체)</Table.Cell>
                    <Table.Cell
                      style={{ width: '11.11%', textAlign: 'center' }}>1일 기본급시간</Table.Cell>
                    {numOfWorkers < 5 ? 
                    <Table.Cell
                      style={{ width: '11.11%', textAlign: 'center' }}>1일 휴무일보존 수당</Table.Cell> : null}
                    <Table.Cell
                      style={{ width: '11.11%', textAlign: 'center' }}>1일 연장시간</Table.Cell>
                    <Table.Cell
                      style={{ width: '11.11%', textAlign: 'center' }}>1일 야간시간</Table.Cell>
                    {numOfWorkers >= 5 ? 
                    <Table.Cell
                      style={{ width: '11.11%', textAlign: 'center' }}></Table.Cell> : null}                        
                  </Table.Row>
                  <Table.Row>
                    <Table.Cell
                      style={{ width: '11.11%', textAlign: 'center' }}></Table.Cell>
                    <Table.Cell
                      style={{ width: '11.11%', textAlign: 'center' }}>{numberToCommaString(roundUp(dayWorkerOverWorkDayWage))}</Table.Cell>
                    <Table.Cell
                      style={{ width: '11.11%', textAlign: 'center' }}>{numberToCommaString(roundUp(dayWokerTimeWage))}</Table.Cell>
                    <Table.Cell
                      style={{ width: '11.11%', textAlign: 'center' }}>{roundUp2(dayWorkerWorkingTimeDayTotal)}</Table.Cell>                    
                    <Table.Cell
                      style={{ width: '11.11%', textAlign: 'center' }}>{roundUp2(numOfWorkers >= 5 ? dayWorkerOverWorkDayPaidTime : dayWorkerDayTotalPaidConversedTime)}</Table.Cell>
                    <Table.Cell
                      style={{ width: '11.11%', textAlign: 'center' }}>{roundUp2(numOfWorkers >= 5 ? dayWorkerWorkingTimeDayBasic : dayWorkerWorkingTimeDayTotal)} <br /> 연장환산({roundUp2(dayWorkerWorkingTimeDayBasic * 1.5)})</Table.Cell>
                    {numOfWorkers < 5 ? 
                    <Table.Cell
                      style={{ width: '11.11%', textAlign: 'center' }}>{roundUp2(dayWorkerPaidHolidayLBTime)}</Table.Cell> : null}                      
                    <Table.Cell
                      style={{ width: '11.11%', textAlign: 'center' }}>{roundUp2(dayWorkerWorkingTimeDayOver)} <br /> 연장환산({roundUp2(dayWorkerWorkingTimeDayOver * 1.5)})</Table.Cell>
                    <Table.Cell
                      style={{ width: '11.11%', textAlign: 'center' }}>{roundUp2(dayWorkerWorkingTimeDayNight)} <br /> 야간환산 ({roundUp2(dayWorkerWorkingTimeDayNight * 0.5)})</Table.Cell>
                    {numOfWorkers >= 5 ? 
                    <Table.Cell
                      style={{ width: '11.11%', textAlign: 'center' }}></Table.Cell> : null}                     
                  </Table.Row>
                </>
                : null
              }
            </>
      }
    </>
  )
}
