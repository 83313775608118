import React, { useEffect } from 'react';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import useFirestoreDoc from '../../../../app/hooks/useFirestoreDoc';
import {
	javascriptDate,
	listenToClientReq4InFromFirestore,
} from '../../../../app/firestore/firestoreService4In';
import LoadingComponent from '../../../../app/layout/LoadingComponent';
import { toast } from 'react-toastify';
import { decrypto } from '../../../../app/firestore/firestoreService';
import {
	clearSelectedClientReq4In,
	listenToSelectedClientReq4In,
} from '../clientReqActions4In';
import { Button, Segment } from 'semantic-ui-react';
import { Link, Redirect } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import {
	calculateEmploymentMonths,
	commaStringToNumber,
	healthCare,
	numberToCommaString,
	oldCare,
} from '../../../../app/common/util/util';
import {
	clearPaydocu,
	fetchPaydocusFormFirestoreService4In,
} from '../../../companyManage/companyActions';
import moment from 'moment';
import MyNumberInput from '../../../../app/common/form/MyNumberInput';
import MyDateInput from '../../../../app/common/form/MyDateInput';
import HealthCareRecalFormItems from './HealthCareRecalFormItems';
import MyTextInput from '../../../../app/common/form/MyTextInput';
import axios from 'axios';
const API_BASE_URL = process.env.REACT_APP_API_SERVER_URL; // 실제 서버 URL로 변경하세요.
// const API_BASE_URL = 'http://localhost:5000'; // 실제 서버 URL로 변경하세요.

export default function HealthCareRecalForm({ match, history, location }) {
	const dispatch = useDispatch();
	const { selectedClientReq4In } = useSelector(
		(state) => state.clientReq4InStore
	);
	const { currentUserProfile } = useSelector((state) => state.profile);
	const { yearMonth, paydocus, clientInfo } = useSelector(
		(state) => state.companyReducer
	);
	const { loading, error } = useSelector((state) => state.async);

	useEffect(() => {
		if (location.pathname !== '/createClientReq4In') return;
		dispatch(clearSelectedClientReq4In());
	}, [location.pathname, dispatch]);

	useEffect(() => {
		if (selectedClientReq4In?.id) {
			dispatch(
				fetchPaydocusFormFirestoreService4In(
					'isWorker',
					yearMonth,
					selectedClientReq4In?.id,
					clientInfo
				)
			).then(() => {});
		}
		return () => {
			dispatch(clearPaydocu());
		};
	}, [dispatch, yearMonth, selectedClientReq4In?.id, clientInfo]);

	const firstMonthTaxWageSum = paydocus[0]?.deductTaxAnd4In.taxWageSum;

	const data = selectedClientReq4In;
	const decryptoData = {};
	for (const prop in data) {
		decryptoData[prop] =
			prop === 'workerSocialNumberFront' || prop === 'workerSocialNumberBack'
				? decrypto(data[prop], String(process.env.CRYPTO_KEY))
				: data[prop];
	}

	const noHealthCare = selectedClientReq4In?.calHealthCare === 0 ? true : false;
	const retiredDate = moment(selectedClientReq4In?.retiredDate);
	const thisYear = retiredDate.year();

	const lastYear = thisYear - 1;
	const thisYearDate = moment().set({ year: thisYear, month: 0, date: 1 });
	const lastYearEndDate = moment().set({ year: lastYear, month: 11, date: 31 });
	const enteredYearDate = moment(selectedClientReq4In?.workerEnteredDate); // 2021-01-06
	// const retiredYearMonth = retiredDate.format('YYYYMM')

	const lastYear01 = lastYear + '01';
	const lastYear12 = lastYear + '12';
	const lastYear07 = lastYear + '07'; //고용보험 퇴직신고 전년7월 전후 보수총액 계산용

	const thisYear01 = thisYear + '01';
	const thisYear04 = thisYear + '04'; //건보 작년 정산금 올 4월이후 납부분 계산용

	function checkYearAndMonth(enteredYearDate) {
		const enteredDate = moment(enteredYearDate); // 입력된 날짜를 moment 객체로 변환
		const currentYear = moment().year(); // 현재 년도
		const enteredYear = enteredDate.year(); // 입력된 년도
		const enteredDay = enteredDate.date(); // 입력된 일

		if (enteredYear !== currentYear) {
			return 0;
		}

		return enteredDay === 1 ? 0 : -1;
	}
	// 정산월 구하기 : 입사월이 올해이고 1일입사자 아니면 재직월수에서 1개월 빼기
	const isNot1day = checkYearAndMonth(enteredYearDate);

	const thisYearStartDate =
		enteredYearDate > thisYearDate ? enteredYearDate : thisYearDate;

	const calThisYearWorkedMonth = calculateEmploymentMonths(
		thisYearStartDate,
		retiredDate
	);

	const lastYearMorkedMonthTemp =
		+enteredYearDate.format('YYYY') === thisYear
			? 0
			: calculateEmploymentMonths(enteredYearDate, lastYearEndDate);
	const calLastYearWorkedMonth =
		lastYearMorkedMonthTemp >= 12
			? 12
			: lastYearMorkedMonthTemp <= 0
			? 0
			: lastYearMorkedMonthTemp;

	const thisYearTaxWagePaydocu = selectedClientReq4In?.healthCareRecal
		?.taxWageThisYear
		? commaStringToNumber(
				selectedClientReq4In?.healthCareRecal?.taxWageThisYear
		  )
		: paydocus
				.map((paydocu) =>
					paydocu.info.yearMonth >= thisYear01
						? paydocu.deductTaxAnd4In.taxWageSum
						: ''
				)
				.reduce(
					(prev, curr) =>
						(prev === undefined ? 0 : +prev) + (curr === undefined ? 0 : +curr),
					0
				);

	const thisYearHealthCarePaydocu = paydocus
		.map((paydocu) =>
			paydocu.info.yearMonth >= thisYear01
				? paydocu.deductTaxAnd4In.calHealthCare
				: ''
		)
		.reduce(
			(prev, curr) =>
				(prev === undefined ? 0 : +prev) + (curr === undefined ? 0 : +curr),
			0
		);

	const thisYearOldCarePaydocu = paydocus
		.map((paydocu) =>
			paydocu.info.yearMonth >= thisYear01
				? paydocu.deductTaxAnd4In.calOldCare
				: ''
		)
		.reduce(
			(prev, curr) =>
				(prev === undefined ? 0 : +prev) + (curr === undefined ? 0 : +curr),
			0
		);

	const lastYearTaxWagePaydocu = selectedClientReq4In?.healthCareRecal
		?.taxWageLastYear
		? commaStringToNumber(
				selectedClientReq4In?.healthCareRecal?.taxWageLastYear
		  )
		: paydocus
				.map((paydocu) =>
					paydocu.info.yearMonth >= lastYear01 &&
					paydocu.info.yearMonth <= lastYear12
						? paydocu.deductTaxAnd4In.taxWageSum
						: ''
				)
				.reduce(
					(prev, curr) =>
						(prev === undefined ? 0 : +prev) + (curr === undefined ? 0 : +curr),
					0
				);

	const lastYearHealthCarePaydocu = paydocus
		.map((paydocu) =>
			paydocu.info.yearMonth >= lastYear01 &&
			paydocu.info.yearMonth <= lastYear12
				? paydocu.deductTaxAnd4In.calHealthCare
				: ''
		)
		.reduce(
			(prev, curr) =>
				(prev === undefined ? 0 : +prev) + (curr === undefined ? 0 : +curr),
			0
		);

	const lastYearOldCarePaydocu = paydocus
		.map((paydocu) =>
			paydocu.info.yearMonth >= lastYear01 &&
			paydocu.info.yearMonth <= lastYear12
				? paydocu.deductTaxAnd4In.calOldCare
				: ''
		)
		.reduce(
			(prev, curr) =>
				(prev === undefined ? 0 : +prev) + (curr === undefined ? 0 : +curr),
			0
		);

	const lastYearHealthCareRecalPaidSum = paydocus
		.map((paydocu) =>
			paydocu.info.yearMonth >= thisYear04
				? paydocu.deductTaxAnd4In.plusDeductHealthCare
				: ''
		)
		.reduce(
			(prev, curr) =>
				(prev === undefined ? 0 : +prev) + (curr === undefined ? 0 : +curr),
			0
		);

	const lastYearOldCareRecalPaidSum = paydocus
		.map((paydocu) =>
			paydocu.info.yearMonth >= thisYear04
				? paydocu.deductTaxAnd4In.plusDeductOldCare
				: ''
		)
		.reduce(
			(prev, curr) =>
				(prev === undefined ? 0 : +prev) + (curr === undefined ? 0 : +curr),
			0
		);

	const lastYearTaxWageOver7Paydocu = paydocus
		.map((paydocu) =>
			(
				+enteredYearDate.format('YYYY') === thisYear
					? 0
					: paydocu.info.yearMonth >= lastYear07 &&
					  paydocu.info.yearMonth <= lastYear12
			)
				? paydocu.deductTaxAnd4In.taxWageSum
				: ''
		)
		.reduce(
			(prev, curr) =>
				(prev === undefined ? 0 : +prev) + (curr === undefined ? 0 : +curr),
			0
		);

	const lastYearTaxWageUnder7Paydocu = paydocus
		.map((paydocu) =>
			paydocu.info.yearMonth < lastYear07
				? paydocu.deductTaxAnd4In.taxWageSum
				: ''
		)
		.reduce(
			(prev, curr) =>
				(prev === undefined ? 0 : +prev) + (curr === undefined ? 0 : +curr),
			0
		);

	const emptyObject = {
		healthCareRecal: {
			lastYearHealthCareRecalPaid: '',
			lastYearOldCareRecalPaid: '',
			paidHealthFeeTotalLastYear: '',
			paidHealthFeeTotalThisYear: '',
			paidOldFeeTotalLastYear: '',
			paidOldFeeTotalThisYear: '',
			retiredDate: '',
			taxWageLastYear: '',
			taxWageThisYear: '',
			updatedDate: '',
			workedMonthLastYear: '',
			workedMonthThisYear: '',
			workerEnteredDate: '',
			workerName: '',
		},
	};

	const healthCareRecalDB =
		selectedClientReq4In?.healthCareRecal?.taxWageThisYear !==
		thisYearTaxWagePaydocu
			? javascriptDate(emptyObject.healthCareRecal)
			: javascriptDate(selectedClientReq4In?.healthCareRecal);

	const taxWageThisYearDB = healthCareRecalDB.taxWageThisYear;
	const workedMonthThisYearDB = healthCareRecalDB.workedMonthThisYear;
	const paidHealthFeeTotalThisYearDB =
		healthCareRecalDB.paidHealthFeeTotalThisYear;
	const paidOldFeeTotalThisYearDB = healthCareRecalDB.paidOldFeeTotalThisYear;
	const taxWageLastYearDB = healthCareRecalDB.taxWageLastYear;
	const workedMonthLastYearDB = healthCareRecalDB.workedMonthLastYear;
	const paidHealthFeeTotalLastYearDB =
		healthCareRecalDB.paidHealthFeeTotalLastYear;
	const paidOldFeeTotalLastYearDB = healthCareRecalDB.paidOldFeeTotalLastYear;
	const lastYearHealthCareRecalPaidDB =
		healthCareRecalDB.lastYearHealthCareRecalPaid;
	const lastYearOldCareRecalPaidDB = healthCareRecalDB.lastYearOldCareRecalPaid;

	const initialValues = {
		workerName: selectedClientReq4In?.worker?.name,
		workerEnteredDate: selectedClientReq4In?.workerEnteredDate,
		retiredDate: selectedClientReq4In?.retiredDate,
		taxWageThisYear: taxWageThisYearDB || thisYearTaxWagePaydocu,
		workedMonthThisYear: workedMonthThisYearDB || calThisYearWorkedMonth,
		paidHealthFeeTotalThisYear:
			paidHealthFeeTotalThisYearDB || thisYearHealthCarePaydocu,
		paidOldFeeTotalThisYear:
			paidOldFeeTotalThisYearDB || thisYearOldCarePaydocu,
		taxWageLastYear: taxWageLastYearDB || lastYearTaxWagePaydocu,
		workedMonthLastYear: workedMonthLastYearDB || calLastYearWorkedMonth,
		paidHealthFeeTotalLastYear:
			paidHealthFeeTotalLastYearDB || lastYearHealthCarePaydocu,
		paidOldFeeTotalLastYear:
			paidOldFeeTotalLastYearDB || lastYearOldCarePaydocu,
		lastYearHealthCareRecalPaid: lastYearHealthCareRecalPaidDB || '',
		lastYearOldCareRecalPaid: lastYearOldCareRecalPaidDB || '',
		lastYearTaxWageUnder7Paydocu: lastYearTaxWageUnder7Paydocu || '',
		lastYearTaxWageOver7Paydocu: lastYearTaxWageOver7Paydocu || '',
	};

	const validationSchemaEmployee4In = Yup.object({
		worker: Yup.object({}),
	});

	useFirestoreDoc({
		shouldExcute:
			match.params.id !== selectedClientReq4In?.id &&
			location.pathname !== '/createClientReq4In',
		query: () => listenToClientReq4InFromFirestore(match.params.id),
		data: (clientReq4In) =>
			dispatch(listenToSelectedClientReq4In(clientReq4In)),
		deps: [match.params.id, dispatch],
	});

	if (loading)
		return <LoadingComponent content='Loading Worker Info edit ...' />;
	if (error) return <Redirect to='/error' />;

	return (
		<Formik
			initialValues={initialValues}
			validationSchema={validationSchemaEmployee4In}
			onSubmit={async (values, { setSubmitting }) => {
				try {
					// 서버에 보낼 데이터를 구성합니다.
					const postData = {
						workerId: selectedClientReq4In?.id,
						values: values,
					};

					// axios를 사용해 POST 요청을 보냅니다.
					axios
						.post(`${API_BASE_URL}/healthcare-recal`, postData)
						.then((response) => {
							// 성공적으로 데이터를 받은 경우 처리
							history.push(
								`/manageClientRDWageTable/${selectedClientReq4In?.id}`
							);
						})
						.catch((error) => {
							// 에러 처리
							console.error('Error:', error);
							toast.error(error.message);
						})
						.finally(() => {
							setSubmitting(false);
						});
				} catch (error) {
					console.log(error.message);
					toast.error(error.message);
					setSubmitting(false);
				}
			}}
			enableReinitialize>
			{({ isSubmitting, dirty, isValid, values }) => (
				<Form className='ui form'>
					<Segment clearing>
						{currentUserProfile?.authLevel >= 10 && (
							<HealthCareRecalFormItems
								noHealthCare={noHealthCare}
								MyDateInput={MyDateInput}
								MyTextInput={MyTextInput}
								selectedClientReq4In={selectedClientReq4In}
								values={values}
								MyNumberInput={MyNumberInput}
								thisYearTaxWagePaydocu={thisYearTaxWagePaydocu}
								calThisYearWorkedMonth={calThisYearWorkedMonth}
								thisYearHealthCarePaydocu={thisYearHealthCarePaydocu}
								numberToCommaString={numberToCommaString}
								thisYearOldCarePaydocu={thisYearOldCarePaydocu}
								lastYearTaxWagePaydocu={lastYearTaxWagePaydocu}
								calLastYearWorkedMonth={calLastYearWorkedMonth}
								lastYearHealthCarePaydocu={lastYearHealthCarePaydocu}
								lastYearOldCarePaydocu={lastYearOldCarePaydocu}
								healthCare={healthCare}
								thisYear={thisYear}
								oldCare={oldCare}
								lastYear={lastYear}
								lastYearHealthCareRecalPaidSum={lastYearHealthCareRecalPaidSum}
								lastYearOldCareRecalPaidSum={lastYearOldCareRecalPaidSum}
								Button={Button}
								isSubmitting={isSubmitting}
								currentUserProfile={currentUserProfile}
								isValid={isValid}
								dirty={dirty}
								Link={Link}
								dispatch={dispatch}
								lastYearTaxWageUnder7Paydocu={lastYearTaxWageUnder7Paydocu}
								lastYearTaxWageOver7Paydocu={lastYearTaxWageOver7Paydocu}
								paydocus={paydocus}
								isNot1day={isNot1day}
								firstMonthTaxWageSum={firstMonthTaxWageSum}
							/>
						)}
					</Segment>
				</Form>
			)}
		</Formik>
	);
}
