import React from 'react';
import { Table } from 'semantic-ui-react';
import { handleNaN } from '../../../../app/common/util/util';

export default function ClientRTimeWorkerMonthWageTableBasicInfo({
	centerStyle,
	worker,
	decryptoData,
	numberToCommaString,
	roundUp,
	daylyTimePay,
	timeWorkerTimeWage,
	timeWorkerTimePaidHoliWage,
}) {
	return (
		<>
			{daylyTimePay > 0 && (
				<>
					<Table.Row>
						<Table.Cell style={centerStyle}>성명</Table.Cell>
						<Table.Cell style={centerStyle}>생년월일</Table.Cell>
						<Table.Cell style={centerStyle}>책정시급</Table.Cell>
						<Table.Cell style={centerStyle}>통상임금시급</Table.Cell>
						<Table.Cell style={centerStyle}>주휴시급</Table.Cell>
					</Table.Row>
					<Table.Row>
						<Table.Cell style={centerStyle}>{worker}</Table.Cell>
						<Table.Cell style={centerStyle}>
							{decryptoData?.workerSocialNumberFront}
						</Table.Cell>
						<Table.Cell style={centerStyle}>
							{numberToCommaString(roundUp(handleNaN(daylyTimePay)))}
						</Table.Cell>
						<Table.Cell style={centerStyle}>
							{numberToCommaString(roundUp(handleNaN(timeWorkerTimeWage)))}
						</Table.Cell>
						<Table.Cell style={centerStyle}>
							{numberToCommaString(
								roundUp(handleNaN(timeWorkerTimePaidHoliWage))
							)}
						</Table.Cell>
					</Table.Row>
				</>
			)}
		</>
	);
}
