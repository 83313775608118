import React from 'react'
import { Table } from 'semantic-ui-react'

export default function ClientReqDLCWageTableDayWorkerDetailAWage6({
  gumakStyle,
  centerStyle,
  inputWageStyle,
  inputCommentStyle,
  dayWorkerCAWage6,
  dayWorkerCAWEName6,
  dayWorkerCAWage6Comment,
  handelInputChange
}) {

  return (
    <>
      <Table.Cell className='gubun' style={centerStyle}>평균임금</Table.Cell>
      <Table.Cell className='gumak' style={gumakStyle}><input style={inputWageStyle} name='dayWorkerCAWEName6' value = {dayWorkerCAWEName6} onChange= {(e) => handelInputChange(e)} /></Table.Cell>
      <Table.Cell className='gumak' style={gumakStyle}><input style={inputWageStyle} name='dayWorkerCAWage6' value = {dayWorkerCAWage6} onChange= {(e) => handelInputChange(e)} /></Table.Cell>
      <Table.Cell colSpan='6' className='sulmung'><textarea style={inputCommentStyle} name='dayWorkerCAWage6Comment' value = { dayWorkerCAWage6Comment } onChange = {(e) => handelInputChange(e)}/></Table.Cell>
    </>
  )
}
