import React from 'react'
import { Formik, Form } from 'formik'
import * as Yup from 'yup'
import { updateInsureInReportInFirestore } from '../../../../app/firestore/firestoreService4In'

function InsureReportButton ({workerId, clientReq4In}) {
  const initialValues = {
    insureInReport: true,
    insureInReportTime: new Date(),
    workerId: workerId,
  }

  const validationSchema = Yup.object({
    // email: Yup.string()
    //   .email('Invalid email format')
    //   .required('Required'),
    // password: Yup.string().required('Required')
  })

  const onSubmit = values => {
    updateInsureInReportInFirestore(values)
  }

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={onSubmit}
    >
      {formik => {
        return (
          <Form>
            <button type='submit' disabled={clientReq4In?.insureInReport}>취득신고완료</button>
          </Form>
        )
      }}
    </Formik>
  )
}

export default InsureReportButton