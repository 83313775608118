import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { Button, Grid, Input, Loader } from 'semantic-ui-react';
import { delay } from '../../../app/common/util/util';
import { clickConfirmedTimeOfLaborContract } from '../../../app/firestore/firestoreService4In';
import { clearMonthlyReports, fetchMonthlyCompetencyReports } from '../eventActions';
import MonthlyReportsFilters from './MonthlyReportsFilters';
import MonthlyReportsList from './MonthlyReportsList';
import PaydocuShortCut from '../../companyManage/paydocuDashboard/PaydocuShortCut';
import { clearClientReqs4In, fetchClientReqs4In } from '../../clientsRequest/clientReq4In/clientReqActions4In';

export default function MonthlyReportsDashboard() {
  const history = useHistory();
  const dispatch = useDispatch();
  const { monthlyReports, yearMonth } = useSelector(state => state.eventStore);
  const { clientInfo } = useSelector(state => state.companyReducer);

  const { currentUserProfile} = useSelector(state => state.profile); //workerUsers는 그냥 users(회원)
  const { loading } = useSelector(state => state.async);
  const { authenticated,  } = useSelector(state => state.auth);
  const { clientReqs4In } = useSelector((state) => state.clientReq4InStore);

  const [searchTerm, setSearchTerm] = useState("");
  const [searchResults, setSearchResults] = useState([]);


  const handleChange = event => {
    setSearchTerm(event.target.value);
  };

  const isWorker = currentUserProfile?.isWorker;

  let filter = isWorker ? 'isWorker' : 'isCompany';
  
  useEffect(() => {
    const fetchClientReqs = async () => {
      try {
        await dispatch(fetchClientReqs4In(filter));
      } catch (error) {
        console.error("Error fetching client requests:", error);
      }
    };
  
    fetchClientReqs();
  
    return () => {
      dispatch(clearClientReqs4In());
    };
  }, [dispatch, filter]);
  
  useEffect(() => {
    const fetchMonthlyReports = async () => {
      try {
        await dispatch(fetchMonthlyCompetencyReports(filter, yearMonth));
      } catch (error) {
        console.error("Error fetching monthly competency reports:", error);
      }
    };
  
    fetchMonthlyReports();
  
    return () => {
      dispatch(clearMonthlyReports());
    };
  }, [dispatch, filter, yearMonth]);
  


  function reloadToMonthlyReports () {
    history.push('/monthlyReports');
  }

  const laborContractId = currentUserProfile?.paydocuWorkerUid;

  function viewLaborContractHandlerWorker (laborContractId) {
    delay(1000)
    history.push(`/clientReqs4In/${laborContractId}`)
    clickConfirmedTimeOfLaborContract(laborContractId)
  }

	const agentType = clientInfo?.agentType ? clientInfo?.agentType : currentUserProfile?.agentType;
  
  return (
    <>
      {isWorker 
        &&
          <>
            <Button style={{ color: 'blue', background: 'yellow' }} onClick={() => viewLaborContractHandlerWorker(laborContractId)}>
              근로계약서 등 보러가기
            </Button>
            <br />
            <br />
            <hr />
            <Button onClick={()=>reloadToMonthlyReports() } color='teal'>새로고침</Button><span>* 리스트가 사라진 경우 <span style={{color: 'teal'}}>새로고침 버튼 클릭 후</span> 아래 "업무일지 불러오기" 클릭</span>
          </>
      }
      <>
      {isWorker ? null : <PaydocuShortCut filter={filter} clientReqs4In={clientReqs4In}/> }

        <br />
        <MonthlyReportsFilters 
          loading={loading} 
          isWorker={isWorker} 
          agentType={agentType}
        />
        <br />
          {isWorker ? null
            :
            <>
              성명
                : <Input
                  type="text"
                  placeholder="Search"
                  value={searchTerm}
                  onChange={handleChange}
                />
                <br />
                <br />
                {/* <p style={{color: 'red', textAlign : 'right'}}>* 직원이 명세서를 열람한 경우 삭제가 되지 않습니다. 직원열람 전에 삭제버튼 클릭시 해당 임금명세서 Data가 즉시 삭제(임금대장에서도 삭제됨.)되므로 신중히 결정하세요.</p> */}
            </>
          }
      </>
      <Grid>
        <Grid.Column width={16}>
          <>
            <hr />
            {authenticated &&
            <MonthlyReportsList 
              monthlyReports={monthlyReports}
              loading={loading}
              searchResults = {searchResults}
              setSearchResults = {setSearchResults}
              searchTerm = {searchTerm}
              isWorker={isWorker}
            />}
            {/* <Grid>
              <Grid.Column width={16}>
                <MonthlyReportContent 
                  monthlyReport={monthlyReports}
                />
              </Grid.Column>
            </Grid> */}
          </>
        </Grid.Column>
        <Grid.Column width={16}>
          <Loader active={loading} />
        </Grid.Column>
      </Grid>
    </>
  );
};