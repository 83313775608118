import axios from 'axios';

// const API_BASE_URL = 'http://localhost:5000';
const API_BASE_URL = process.env.REACT_APP_API_SERVER_URL;

export const apiService = {
  calculateAnnualLeaveCalALFinMonth(data) {
    return axios.post(`${API_BASE_URL}/calculateAnnualLeaveCalALFinMonth`, data)
      .then(response => response.data)
      .catch(error => {
        console.error("API call failed:", error);
        throw error; // 에러를 다시 던져서 호출한 곳에서 처리할 수 있도록 함
      });
  }
};

