import React from 'react';
import PaydocuPayDetailTimeWorker from './PaydocuPayDetailTimeWorker'
import PaydocuPayDetailDayWorker from './PaydocuPayDetailDayWorker'

export default function PaydocuPayDetailDayTimeWorker({
  daylyTimePay,
  isExceptTimeLawWorker,
  gumakStyle,
  weekLBTime,
  numOfWorkers,
  centerStyle,
  totalDayWorkerWage,
  dayWorkerWage,
  countOfNomalDayWork,
  countOfHoliDayWork,
  totalDayWorkerHoliWage,
  dayWorkerHoliWage,
  totalDayWorkerOverWorkDayWage,
  countOfOverWorkingDayWork,
  dayWorkerOverWorkDayWage,
  dayWorkerCAWage5,
  dayWorkerCAWEName5,
  dayWorkerCAWage5Comment,
  dayWorkerCAWage6,
  dayWorkerCAWEName6,
  dayWorkerCAWage6Comment,
  dayWorkerCAWage7,
  dayWorkerCAWEName7,
  dayWorkerCAWage7Comment,
  dayWorkerPlusOTime,
  numDayWorkerOWage,
  dayWokerTimeWage,
  dayWorkerPlusNTime,
  numDayWorkerNWage,
  dayWorkerPlusHBTime,
  numDayWorkerHBwage,
  dayWorkerPlusHOTime,
  numDayWorkerHOwage,
  dayWorkerPlusHNTime,
  numDayWorkerHNwage,
  dayWorkerAbsentTimes,
  dayWorkerAbsentTimeWage,

  timeWorkerPlusMonthOWT,
  timeWorkerPlusMonthNWT,
  timeWorkerPlusMonthHoliBasicTime,
  timeWorkerPlusMonthOWTHoli,
  timeWorkerPlusMonthOffBasicTime,
  timeWorkerPlusMonthOWTOff,
  numOWageForTW,
  numNWageForTW,
  numHBWageForTW,
  numHOWageForTW,
  numOffWageForTW,
  numOffOWageForTW,

  totalTimeWorkerWage,
  totalTimeWorkerPaidHoliWage,
  timeWorkerTimeWage,
  timeWorkerTimePaidHoliWage,
  week14OverBLTime,
  numPaidHoliTimeW,
  finalPaidHoliWageTimeSum,
  monthTotalWorkTime,
  finalPaidHoliWageTime,
  normalWorkerWeekWorkDays,
  paidHoliWageDays,
  paidHoliWageText,

  dayWorkerSpecialBonus,
  timeWorkerSpecialBonus,
  numPaidHoliDayW

}) {

  return (
    <>
      {daylyTimePay 
        ?
          <PaydocuPayDetailTimeWorker
            totalTimeWorkerWage = {totalTimeWorkerWage}
            totalTimeWorkerPaidHoliWage = {totalTimeWorkerPaidHoliWage}
            timeWorkerTimeWage = {timeWorkerTimeWage}
            timeWorkerTimePaidHoliWage = {timeWorkerTimePaidHoliWage}
            week14OverBLTime = {week14OverBLTime}
            numPaidHoliTimeW = {numPaidHoliTimeW}
            finalPaidHoliWageTimeSum = {finalPaidHoliWageTimeSum}
            monthTotalWorkTime = {monthTotalWorkTime}
            finalPaidHoliWageTime = {finalPaidHoliWageTime}
            normalWorkerWeekWorkDays = {normalWorkerWeekWorkDays}
            paidHoliWageDays = {paidHoliWageDays}
            paidHoliWageText = {paidHoliWageText}
            numOfWorkers={numOfWorkers}
            timeWorkerPlusMonthOWT={timeWorkerPlusMonthOWT}
            timeWorkerPlusMonthNWT={timeWorkerPlusMonthNWT}
            timeWorkerPlusMonthHoliBasicTime={timeWorkerPlusMonthHoliBasicTime}
            timeWorkerPlusMonthOWTHoli={timeWorkerPlusMonthOWTHoli}
            timeWorkerPlusMonthOffBasicTime={timeWorkerPlusMonthOffBasicTime}
            timeWorkerPlusMonthOWTOff={timeWorkerPlusMonthOWTOff}
            numOWageForTW={numOWageForTW}
            numNWageForTW={numNWageForTW}
            numHBWageForTW={numHBWageForTW}
            numHOWageForTW={numHOWageForTW}
            numOffWageForTW={numOffWageForTW}
            numOffOWageForTW={numOffOWageForTW}

            dayWorkerCAWage5={dayWorkerCAWage5}
            dayWorkerCAWEName5={dayWorkerCAWEName5}
            dayWorkerCAWage5Comment={dayWorkerCAWage5Comment}
            dayWorkerCAWage6={dayWorkerCAWage6}
            dayWorkerCAWEName6={dayWorkerCAWEName6}
            dayWorkerCAWage6Comment={dayWorkerCAWage6Comment}
            dayWorkerCAWage7={dayWorkerCAWage7}
            dayWorkerCAWEName7={dayWorkerCAWEName7}
            dayWorkerCAWage7Comment={dayWorkerCAWage7Comment}

            timeWorkerSpecialBonus={timeWorkerSpecialBonus}

          />
        :
          <PaydocuPayDetailDayWorker
            isExceptTimeLawWorker={isExceptTimeLawWorker}
            gumakStyle={gumakStyle}
            weekLBTime={weekLBTime}
            numOfWorkers={numOfWorkers}
            centerStyle={centerStyle}
            totalDayWorkerWage={totalDayWorkerWage}
            dayWorkerWage={dayWorkerWage}
            countOfNomalDayWork={countOfNomalDayWork}
            countOfHoliDayWork={countOfHoliDayWork}
            totalDayWorkerHoliWage={totalDayWorkerHoliWage}
            dayWorkerHoliWage={dayWorkerHoliWage}
            totalDayWorkerOverWorkDayWage={totalDayWorkerOverWorkDayWage}
            countOfOverWorkingDayWork={countOfOverWorkingDayWork}
            dayWorkerOverWorkDayWage={dayWorkerOverWorkDayWage}
            dayWorkerCAWage5={dayWorkerCAWage5}
            dayWorkerCAWEName5={dayWorkerCAWEName5}
            dayWorkerCAWage5Comment={dayWorkerCAWage5Comment}
            dayWorkerCAWage6={dayWorkerCAWage6}
            dayWorkerCAWEName6={dayWorkerCAWEName6}
            dayWorkerCAWage6Comment={dayWorkerCAWage6Comment}
            dayWorkerCAWage7={dayWorkerCAWage7}
            dayWorkerCAWEName7={dayWorkerCAWEName7}
            dayWorkerCAWage7Comment={dayWorkerCAWage7Comment}
            dayWorkerPlusOTime={dayWorkerPlusOTime}
            numDayWorkerOWage={numDayWorkerOWage}
            dayWokerTimeWage={dayWokerTimeWage}
            dayWorkerPlusNTime={dayWorkerPlusNTime}
            numDayWorkerNWage={numDayWorkerNWage}
            dayWorkerPlusHBTime={dayWorkerPlusHBTime}
            numDayWorkerHBwage={numDayWorkerHBwage}
            dayWorkerPlusHOTime={dayWorkerPlusHOTime}
            numDayWorkerHOwage={numDayWorkerHOwage}
            dayWorkerPlusHNTime={dayWorkerPlusHNTime}
            numDayWorkerHNwage={numDayWorkerHNwage}
            dayWorkerAbsentTimes={dayWorkerAbsentTimes}
            dayWorkerAbsentTimeWage={dayWorkerAbsentTimeWage}

            dayWorkerSpecialBonus={dayWorkerSpecialBonus}
            week14OverBLTime={week14OverBLTime}
            numPaidHoliDayW={numPaidHoliDayW}
            finalPaidHoliWageTime={finalPaidHoliWageTime}
            finalPaidHoliWageTimeSum={finalPaidHoliWageTimeSum}
            normalWorkerWeekWorkDays={normalWorkerWeekWorkDays}
            paidHoliWageDays={paidHoliWageDays}
            paidHoliWageText={paidHoliWageText}
          />
      }
    </>
  )
}
