import React from 'react';
import { Route, useLocation } from 'react-router-dom';
import { Container } from 'semantic-ui-react';
import EventDashboardForNomusa from '../../features/events/eventDashboard/EventDashboardForNomusa';
import EventDetailedPage from '../../features/events/eventDetailed/EventDetailedPage';
import EventForm from '../../features/events/eventForm/EventForm';
import EventTable from '../../features/events/eventsTableComponent/EventTable';
import HomePage from '../../features/home/HomePage';
import ModalManager from '../common/modals/ModalManager';
import { ToastContainer } from 'react-toastify';
import AccountPage from '../../features/auth/AccountPage';
import { useSelector } from 'react-redux';
import LoadingComponent from './LoadingComponent';
import ProfilePage from '../../features/profiles/profilePage/ProfilePage';
import PrivateRoute from './PrivateRoute';
import UntactForm from '../../features/untacts/untactForm/UntactForm';
import UntactDashboard from '../../features/untacts/untactDashboard/UntactDashboard';
import ClientReqDashboard4In from '../../features/clientsRequest/clientReq4In/clientsReqDashboard4In/ClientReqDashboard4In';
import ClientReqDetailedPage4In from '../../features/clientsRequest/clientReq4In/clientReqDetailed4In/ClientReqDetailedPage4In';
import ClientReqWorkerForm from '../../features/clientsRequest/clientReq4In/realForms/ClientReqWorkerForm';
import ClientRDLWageContract from '../../features/clientsRequest/clientReq4In/clientReqDetailed4In/ClientRDLWageContract';
import ClientRDPersonalInfoAgree from '../../features/clientsRequest/clientReq4In/clientReqDetailed4In/ClientRDPersonalInfoAgree';
import ClientRDRuleObeyAgree from '../../features/clientsRequest/clientReq4In/clientReqDetailed4In/ClientRDRuleObeyAgree';
import ClientReqDaylyPayContract from '../../features/clientsRequest/clientReq4In/clientReqDetailed4In/ClientReqDaylyPayContract';
import ClientReqDayWorkerForm from '../../features/clientsRequest/clientReq4In/realForms/ClientReqDayWorkerForm';

import CompanyDashboard from '../../features/companyManage/companyDashboard/CompanyDashboard';
import ClientUseContractPaper from '../../features/auth/ClientUseContractPaper';
import ClientPrivateProtectPaper from '../../features/auth/ClientPrivateProtectPaper';

// import CmsContract from '../../features/companyManage/companyContract/CmsContract';
import YouthSFCeoConfirm from '../../features/companyManage/supportFund/YouthSFCeoConfirm';
import YouthSFSystemAgree from '../../features/companyManage/supportFund/YouthSFSystemAgree';
import YouthSFPersonalInfoAgree from '../../features/companyManage/supportFund/YouthSFPersonalInfoAgree';
import RuleOfEmployee from '../../features/companyManage/ruleOfEmployee/RuleOfEmployee';
import RuleOfEmployeeBizSecret from '../../features/companyManage/ruleOfEmployee/RuleOfEmployeeBizSecret';
import RuleOfLaborCouncil from '../../features/companyManage/ruleOfEmployee/RuleOfLaborCouncil';
import RepresentativesAgreement from '../../features/companyManage/ruleOfEmployee/RepresentativesAgreement';
import ProgramUseContract from '../../features/companyManage/companyContract/ProgramUseContract';
// import CmsProgramContract from '../../features/companyManage/companyContract/CmsProgramContract';
import PricePolicyPaper from '../../features/auth/PricePolicyPaper';
import PriceForm from '../../features/auth/PriceForm';
import PaymentsSuccess from '../../features/auth/PaymentsSuccess';
import PaymentsFail from '../../features/auth/PaymentsFail';
import ClientRDWageTable from '../../features/clientsRequest/clientReq4In/clientReqDetailed4In/ClientRDWageTable';
import PaymentsInfoDashboard from '../../features/companyManage/paymentsInfoDashboard/PaymentsInfoDashboard';
import PaydocuDashboard from '../../features/companyManage/paydocuDashboard/PaydocuDashboard';

import PaydocuDetailedPage from '../../features/companyManage/paydocuDashboard/PaydocuDetailedPage';
import WorkerUserPage from '../../features/profiles/profilePage/workerUserPage';
import PaydocusTableDashboard from '../../features/companyManage/paydocuDashboard/PaydocusTableDashboard';
import PaydocuWorkerCodeDashboard from '../../features/companyManage/paydocuDashboard/PaydocuWorkerCodeDashboard';
import MonthlyReportsDashboard from '../../features/events/monthlyCompetencyReportPage/MonthlyReportsDashBoard';
import MonthlyCompetencyReportForm from '../../features/events/monthlyCompetencyReportPage/MonthlyCompetencyReportForm';
import AnnualLeavesTableDashboard from '../../features/companyManage/annaulLeavesTableDashboard/AnnualLeavesTableDashboard';
import AnnualLeavesTableWorkerDashboard from '../../features/companyManage/annaulLeavesTableDashboard/AnnualLeavesTableWorkerDashboard';

import AnnualLeavesWorkerDashboard from '../../features/companyManage/annaulLeavesTableDashboard/AnnualLeavesWorkerDashboard';
import RiskSuspectReportForm from '../../features/events/riskSuspectBicAccidentLaw/RiskSuspectReportForm';
import RiskSuspectReportsDashboard from '../../features/events/riskSuspectBicAccidentLaw/RiskSuspectReportsDashBoard';

import ClientRDaylyWorkerMonthWageTable from '../../features/clientsRequest/clientReq4In/clientReqDetailed4In/ClientRDaylyWorkerMonthWageTable';
import PaydocusDayWorkerTableDashboard from '../../features/companyManage/paydocuDashboard/PaydocusDayWorkerTableDashboard';

import HealthCareRecalForm from '../../features/clientsRequest/clientReq4In/realForms/HealthCareRecalForm';
import RetireWageCalForm from '../../features/clientsRequest/clientReq4In/realForms/RetireWageCalForm';
import RetireWageCalcForm from '../../features/clientsRequest/clientReq4In/realForms/RetireWageCalcForm';
import NumOfWorkersJudgeCalc from '../../features/clientsRequest/clientReq4In/realForms/NumOfWorkersJudgeCalc';

import ClientRetireFundPage from '../../features/clientsRequest/clientReq4In/clientReqDetailed4In/ClientRetireFundPage';
import PaydocusTableWorkerDashboard from '../../features/companyManage/paydocuDashboard/PaydocusTableWorkerDashboard';
import PaydocusTableDayWorkerDashboard from '../../features/companyManage/paydocuDashboard/PaydocusTableDayWorkerDashboard';

// import IntroPaydac from '../../features/home/IntroPaydac';
import PaydocusTableBankDashboard from '../../features/companyManage/paydocuDashboard/PaydocusTableBankDashboard';
import PaydocusTableOverTimeDashboard from '../../features/companyManage/paydocuDashboard/PaydocusTableOverTimeDashboard';

import PaydocusTableLawDashboard from '../../features/companyManage/paydocuDashboard/PaydocusTableLawDashboard';
import PaydocusTableWorkerHrDashboard from '../../features/companyManage/paydocuDashboard/PaydocusTableWorkerHrDashboard';
import PaydocusTableHrDashboard from '../../features/companyManage/paydocuDashboard/PaydocusTableHrDashboard';
import InsureManageDashboard from '../../features/companyManage/insureManageDashboard/InsureManageDashboard';
import CompetencyManageDashboard from '../../features/companyManage/competencyManageDashboard/CompetencyManageDashboard';
import CmsProgramContractContents from '../../features/companyManage/companyContract/CmsProgramContractContents';
import ChatBotGpt from '../../features/events/chatbotGPT/ChatBotGpt';
import MySidebarWorker from '../../features/nav/MySidebarWorker';

import PaydocuCopyDashboard from '../../features/companyManage/paydocuDashboard/PaydocuCopyDashboard';
import ClientReqDashboard4InInfo from '../../features/clientsRequest/clientReq4In/clientsReqDashboard4In/ClientReqDashboard4InInfo';
import ClientReqDashboard4InLaborContractsInfo from '../../features/clientsRequest/clientReq4In/clientsReqDashboard4In/ClientReqDashboard4InLaborContractsInfo';
import ClientReqDashboard4InNewYearLawInfo from '../../features/clientsRequest/clientReq4In/clientsReqDashboard4In/ClientReqDashboard4InNewYearLawInfo';
import NetWageCal from '../../features/clientsRequest/clientReq4In/clientsReqDashboard4In/NetWageCal';
import AnnualLeavesCal from '../../features/clientsRequest/clientReq4In/clientsReqDashboard4In/AnnualLeavesCal';
import MySidebar from '../../features/nav/MySidebar';
import UpdateLastSignIn from '../../features/auth/UpdateLastSignIn';
import ErrorComponent from './ErrorComponent';
import ClientRDLawBaseWorkerInfo from '../../features/clientsRequest/clientReq4In/clientReqDetailed4In/ClientRDLawBaseWorkerInfo';
import PaydocusTableBizWorkerDashboard from '../../features/companyManage/paydocuDashboard/PaydocusTableBizWorkerDashboard';
import PaydocusTableDashboardBiz from '../../features/companyManage/paydocuDashboard/PaydocusTableDashboardBiz';
import UpdateCompanyInfo from '../../features/auth/updateCompanyInfo';
import PaydocusDayWorkerTableDashboardReport from '../../features/companyManage/insureManageDashboard/PaydocusDayWorkerTableDashboardReport';
import PaydocusDayWorkerTableDashboardReportHP from '../../features/companyManage/insureManageDashboard/PaydocusDayWorkerTableDashboardReportHP';

export default function App() {
	const { key } = useLocation();
	const { initialized } = useSelector((state) => state.async);
	const { currentUserProfile } = useSelector((state) => state.profile);

	if (!initialized) return <LoadingComponent content='Loading app...' />;

	const auhtLevel = currentUserProfile?.authLevel;
	const isWorker = currentUserProfile?.isWorker;

	return (
		<>
			<UpdateLastSignIn />
			<ModalManager />
			<ToastContainer position='bottom-right' hideProgressBar />
			<Route exact path='/' component={HomePage} />
			<Route
				path={'/(.+)'}
				render={() => (
					<div style={{ display: 'flex', height: '100vh' }}>
						{' '}
						{/* Flexbox 컨테이너 */}
						<div style={{ width: '15%' }}>
							{isWorker ? (
								<MySidebarWorker isWorker={isWorker} />
							) : (
								<MySidebar />
							)}
						</div>
						<Container
							fluid
							style={{
								flex: '1',
								overflow: 'auto',
								paddingLeft: 20,
								paddingRight: 20,
								paddingTop: 20,
								marginLeft: '85%',
							}}>
							{' '}
							{/* 나머지 공간을 차지하는 Container */}
							{/* 현재 인트라넷 중 노무법인 업무 부분 */}
							<PrivateRoute
								exact
								path='/events'
								component={EventDashboardForNomusa}
							/>
							<PrivateRoute path='/events/:id' component={EventDetailedPage} />
							{/* 현재 인트라넷 중 비대면 업무 부분 */}
							<PrivateRoute exact path='/untacts' component={UntactDashboard} />
							{auhtLevel >= 100 && (
								<>
									<PrivateRoute
										path={['/createEvent', '/manage/:id']}
										component={EventForm}
										key={key}
									/>
									<PrivateRoute
										path={['/createUntact', '/manageUntact/:id']}
										component={UntactForm}
										key={key + 1}
									/>
									<PrivateRoute
										exact
										path='/companies'
										component={CompanyDashboard}
									/>
									<PrivateRoute
										exact
										path='/competencyManage'
										component={CompetencyManageDashboard}
									/>
								</>
							)}
							<PrivateRoute
								exact
								path='/insureManage'
								component={InsureManageDashboard}
							/>
							{auhtLevel >= 10 && (
								<PrivateRoute
									path={['/createClientReq4In', '/manageClientReq4In/:id']}
									component={ClientReqWorkerForm}
									key={key + 2}
								/>
							)}
							{auhtLevel >= 10 && (
								<>
									<PrivateRoute
										path={[
											'/createClientReqDayWorker',
											'/manageClientReqDayWorker/:id',
										]}
										component={ClientReqDayWorkerForm}
										key={key + 3}
									/>
								</>
							)}
							<PrivateRoute
								path={['/createCompany', '/manageCompany/:id']}
								component={ProfilePage}
								key={key + 4}
							/>
							<PrivateRoute
								path={[
									'/createCompetencyMonthlyReport',
									'/manageCompetencyMonthlyReport/:id',
								]}
								component={MonthlyCompetencyReportForm}
								key={key + 55}
							/>
							<PrivateRoute
								path={[
									'/clientRDWageTable/:id',
									'/manageClientRDWageTable/:id',
								]}
								component={ClientRDWageTable}
								key={key + 66}
							/>
							<PrivateRoute
								path={[
									'/createRiskSuspectReport',
									'/manageRiskSuspectReport/:id',
								]}
								component={RiskSuspectReportForm}
								key={key + 77}
							/>
							<PrivateRoute
								path={[
									'/clientRDaylyWorkerMonthWageTable/:id',
									'/manageClientRDaylyWorkerMonthWageTable/:id',
								]}
								component={ClientRDaylyWorkerMonthWageTable}
								key={key + 88}
							/>
							<PrivateRoute
								path={['/healthCareRecal/:id']}
								component={HealthCareRecalForm}
								key={key + 101}
							/>
							<PrivateRoute
								path={['/retireWageCal/:id']}
								component={RetireWageCalForm}
								key={key + 102}
							/>
							<PrivateRoute
								path={['/retireFundCalc']}
								component={RetireWageCalcForm}
								key={key + 103}
							/>
							<PrivateRoute
								path={['/numOfWorkersJudgeCalc']}
								component={NumOfWorkersJudgeCalc}
								key={key + 104}
							/>
							<Route
								exact
								path='/clientReqs4InInfo'
								component={ClientReqDashboard4InInfo}
							/>
							<Route
								exact
								path='/clientReqs4InNewYearLawInfo'
								component={ClientReqDashboard4InNewYearLawInfo}
							/>
							<Route
								exact
								path='/clientReqs4InLaborContractsInfo'
								component={ClientReqDashboard4InLaborContractsInfo}
							/>
							<Route exact path='/netCalc' component={NetWageCal} />
							<Route
								exact
								path='/annualLeavesCalc'
								component={AnnualLeavesCal}
							/>
							{/* 4대보험 가입부분으로 기업과 노무법인이 분리되어야 함. 
            현 상태에서는 기존의 필터로 내 이벤트 보기는 기업이 보고, 모두보기는 노무법인이 볼 예정*/}
							<Route
								exact
								path='/clientReqs4In'
								component={ClientReqDashboard4In}
							/>
							{/* 근로계약서 */}
							<PrivateRoute
								path='/clientReqs4In/:id'
								component={ClientReqDetailedPage4In}
							/>
							{/* 임금계약서 */}
							<PrivateRoute
								path='/clientRDLaborWageContract/:id'
								component={ClientRDLWageContract}
							/>
							{/* 개인정보동의서 */}
							<PrivateRoute
								path='/clientRDPersonalInfoAgree/:id'
								component={ClientRDPersonalInfoAgree}
							/>
							{/* 복무서약서 */}
							<PrivateRoute
								path='/clientRDRuleObeyAgree/:id'
								component={ClientRDRuleObeyAgree}
							/>
							{/* 근로자명부 */}
							<PrivateRoute
								path='/clientRDLawBaseWorkerInfo/:id'
								component={ClientRDLawBaseWorkerInfo}
							/>
							{/* 일용직 */}
							<PrivateRoute
								path='/clientReqDaylyPayContract/:id'
								component={ClientReqDaylyPayContract}
							/>
							<PrivateRoute exact path='/eventsTable' component={EventTable} />
							{/* 이용약관 */}
							<Route
								exact
								path='/clientUseContractPaper'
								component={ClientUseContractPaper}
							/>
							{/* 개인정보처리방침 */}
							<Route
								exact
								path='/clientPrivateProtectPaper'
								component={ClientPrivateProtectPaper}
							/>
							{/* 가격정책  */}
							<Route path='/pricePolicyPaper' component={PricePolicyPaper} />
							{/* 결제하기  */}
							<Route path='/priceForm' component={PriceForm} />
							{/* 결제하기  */}
							<Route path='/updateCompanyInfo' component={UpdateCompanyInfo} />
							{/* 결제성공  */}
							<PrivateRoute
								path='/success'
								component={PaymentsSuccess}
								key={key + 5}
							/>
							{/* 결제실패  */}
							<Route path='/fail' component={PaymentsFail} key={key + 6} />
							<PrivateRoute exact path='/account' component={AccountPage} />
							{/* 회사정보  */}
							<PrivateRoute path='/profile/:id' component={ProfilePage} />
							{/* 전산이용 계약서 */}
							<PrivateRoute
								exact
								path='/programUseContract/:id'
								component={ProgramUseContract}
							/>
							{/* 고용안정지원금 계약서 */}
							{/* 고용안정지원금 위임장
            <PrivateRoute exact path='/supportFundWarrant/:id' component={SupportFundWarrant} /> */}
							{/* CMS 자동이체 신청서
            <PrivateRoute exact path='/cmsContract/:id' component={CmsContract} /> */}
							{/* CMS 프로그램 자동이체 신청서 */}
							<PrivateRoute
								exact
								path='/cmsProgramContract/:id'
								component={CmsProgramContractContents}
							/>
							{/* 청년채용특별장려금 사업주 확인서 */}
							<PrivateRoute
								exact
								path='/youthSFCeoConfirm/:id'
								component={YouthSFCeoConfirm}
							/>
							{/* 종소기업 지원사업 통합관리시스템 정보 활용을 위한 동의서*/}
							<PrivateRoute
								exact
								path='/youthSFSystemAgree/:id'
								component={YouthSFSystemAgree}
							/>
							{/* 청년 개인정보 수집이용에 대한 동의서(사업주용)*/}
							<PrivateRoute
								exact
								path='/youthSFPersonalInfoAgree/:id'
								component={YouthSFPersonalInfoAgree}
							/>
							{/* 취업규칙*/}
							<PrivateRoute
								exact
								path='/ruleOfEmployee/:id'
								component={RuleOfEmployee}
							/>
							{/* 취업규칙(영업비밀보호 규정)*/}
							<PrivateRoute
								exact
								path='/ruleOfEmployeeBizSecret/:id'
								component={RuleOfEmployeeBizSecret}
							/>
							<PrivateRoute
								exact
								path='/ruleOfLaborCouncil/:id'
								component={RuleOfLaborCouncil}
							/>
							{/* 근로자대표서면합의서*/}
							<PrivateRoute
								exact
								path='/representativesAgreement/:id'
								component={RepresentativesAgreement}
							/>
							{/*  임금명세서 관리 부분 */}
							<PrivateRoute
								exact
								path='/paydocus'
								component={PaydocuDashboard}
							/>
							{/*  임금 월 복사 부분 */}
							<PrivateRoute
								exact
								path='/paydocusCopy'
								component={PaydocuCopyDashboard}
							/>
							{/*  역량관리 List 부분 */}
							<PrivateRoute
								exact
								path='/monthlyReports'
								component={MonthlyReportsDashboard}
							/>
							{/*  임금명세서 관리 부분 */}
							<PrivateRoute
								exact
								path='/paydocuDetailedPage/:id'
								component={PaydocuDetailedPage}
							/>
							{/*  퇴직금 정산서 */}
							<PrivateRoute
								exact
								path='/clientRetireFundPage/:id'
								component={ClientRetireFundPage}
							/>
							{/*  임금명세서 직원코드 관리 부분 */}
							<PrivateRoute
								exact
								path='/paydocuWorkerCode'
								component={PaydocuWorkerCodeDashboard}
							/>
							{/*  연차사용개인내역 */}
							<PrivateRoute
								exact
								path='/annualLeavesWorker'
								component={AnnualLeavesWorkerDashboard}
							/>
							{/*  연차사용대장 관리 부분 */}
							<PrivateRoute
								exact
								path='/annualLeavesTable'
								component={AnnualLeavesTableDashboard}
							/>
							{/*  연차사용대장 개인별 관리 부분 */}
							<PrivateRoute
								exact
								path='/annualLeavesWorkerTable'
								component={AnnualLeavesTableWorkerDashboard}
							/>
							{/*  결재 관리 부분 */}
							<PrivateRoute
								exact
								path='/paymentsInfo'
								component={PaymentsInfoDashboard}
							/>
							{/* 회사가 등록한 직원 정보  */}
							<PrivateRoute path='/workerUser/:id' component={WorkerUserPage} />
							{/*  역량관리 List 부분 */}
							<PrivateRoute
								exact
								path='/riskSuspectReports'
								component={RiskSuspectReportsDashboard}
							/>
							{/*  임금대장 관리 부분(가로) */}
							<PrivateRoute
								exact
								path='/paydocusTable'
								component={PaydocusTableDashboard}
							/>
							{/*  임금대장 관리 부분(일용직 가로) */}
							<PrivateRoute
								exact
								path='/paydocusDayWorkerTable'
								component={PaydocusDayWorkerTableDashboard}
							/>
							{/*  임금대장 관리 부분(사업소득직 가로) */}
							<PrivateRoute
								exact
								path='/paydocusTableBiz'
								component={PaydocusTableDashboardBiz}
							/>
							{/*  임금대장 은행 부분(계좌) */}
							<PrivateRoute
								exact
								path='/paydocusTableBank'
								component={PaydocusTableBankDashboard}
							/>
							{/*  연장근로 은행 부분(계좌) */}
							<PrivateRoute
								exact
								path='/paydocusTableOverTime'
								component={PaydocusTableOverTimeDashboard}
							/>
							{/*  임금대장 관리 부분(직원별) */}
							<PrivateRoute
								exact
								path='/paydocusTableWorker'
								component={PaydocusTableWorkerDashboard}
							/>
							{/*  임금대장 관리 부분(일용직 직원별) */}
							<PrivateRoute
								exact
								path='/paydocusTableDayWorker'
								component={PaydocusTableDayWorkerDashboard}
							/>
							{/*  임금대장 관리 부분(사업소득직 직원별) */}
							<PrivateRoute
								exact
								path='/paydocusTableBizWorker'
								component={PaydocusTableBizWorkerDashboard}
							/>
							{/*  임금대장 관리 부분(48조1항) */}
							<PrivateRoute
								exact
								path='/paydocusTableLaw'
								component={PaydocusTableLawDashboard}
							/>
							{/*  BSC역량모델링 관리 부분(직원별) */}
							<PrivateRoute
								exact
								path='/paydocusTableWorkerHr'
								component={PaydocusTableWorkerHrDashboard}
							/>
							{/*  BSC역량모델링 관리 부분(가로) */}
							<PrivateRoute
								exact
								path='/paydocusTableHr'
								component={PaydocusTableHrDashboard}
							/>
							{/*  일용직 임금대장 (고용보험신고) */}
							<PrivateRoute
								exact
								path='/paydocusDayWorkerTableReport'
								component={PaydocusDayWorkerTableDashboardReport}
							/>
							<PrivateRoute
								exact
								path='/paydocusDayWorkerTableReportHP'
								component={PaydocusDayWorkerTableDashboardReportHP}
							/>
							{/*  챗봇 */}
							<PrivateRoute exact path='/chatBotGpt' component={ChatBotGpt} />
							<PrivateRoute exact path='/error' component={ErrorComponent} />
						</Container>
					</div>
				)}
			/>
		</>
	);
}
