import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Grid, Input, Loader } from 'semantic-ui-react';
import { clearPaydocu, fetchPaydocusWorkerFormFirestoreService4In } from '../companyActions';
import PaydocuWorkerFilters from './PaydocuWorkerFilters';
import PaydocuShortCut from './PaydocuShortCut';
import { clearClientReqs4In, fetchClientReqs4In } from '../../clientsRequest/clientReq4In/clientReqActions4In';
import PaydocusTableBizWorkerList from './PaydocusTableBizWorkerList';

export default function PaydocusTableBizWorkerDashboard() {

  const dispatch = useDispatch();
  const { paydocus, paydocuWorkerName, filter, clientInfo } = useSelector(state => state.companyReducer);
  const { currentUserProfile } = useSelector(state => state.profile);
  const { loading } = useSelector(state => state.async);
  const { authenticated } = useSelector(state => state.auth);
  const { clientReqs4In } = useSelector((state) => state.clientReq4InStore);

  const [searchTerm, setSearchTerm] = useState("");
  const [searchResults, setSearchResults] = useState([]);
  const [paydocuWorkerCode, setPydocuWorkerCode] = useState('');

  const handleChange = event => {
    setSearchTerm(event.target.value);
  };

  const isWorker = currentUserProfile?.isWorker;

  useEffect(() => {
    dispatch(fetchClientReqs4In(filter)).then(() => {
    });
    return () => {
      dispatch(clearClientReqs4In()); 
    };
  }, [dispatch, filter]);

  useEffect(() => {
    dispatch(fetchPaydocusWorkerFormFirestoreService4In(paydocuWorkerName, paydocuWorkerCode, clientInfo)).then(() => {
    });
    return () =>{
      dispatch(clearPaydocu());
    }
  }, [dispatch, paydocuWorkerName, clientInfo, paydocuWorkerCode]) 

  const paydocusBizWorker = useMemo(() => {
    return paydocus.filter(doc => doc?.isBizWorker === true);
  }, [paydocus]);

  return (
    <>
      {isWorker ? null : <PaydocuShortCut filter={filter} clientReqs4In={clientReqs4In}/> }

      <h1 style={{textAlign: 'center', margin: '50px'}}>사업소득직 개인별 임금대장</h1>

      <PaydocuWorkerFilters 
        loading={loading} 
        isWorker={isWorker} 
        filter={filter}
        setPydocuWorkerCode={setPydocuWorkerCode}
      />      <>
        년도
          : <Input
            type="text"
            placeholder="Search"
            value={searchTerm}
            onChange={handleChange}
          />
          <br />
          <br />
      </>
      <Grid>
        <Grid.Column width={16}>
          <>
            {authenticated && 
              <PaydocusTableBizWorkerList
                paydocus={paydocusBizWorker}
                loading={loading}
                searchResults = {searchResults}
                setSearchResults = {setSearchResults}
                searchTerm = {searchTerm}
                paydocuWorkerName={paydocuWorkerName}
              />              
            }
          </>
        </Grid.Column>
        <Grid.Column width={16}>
          <Loader active={loading} />
        </Grid.Column>
      </Grid>
    </>
  );
};