import React from 'react'
import { useSelector } from 'react-redux'
import Sidebar from './sidebar/Sidebar'
import Chat from './chat/Chat'
import LoadingSpinner from './LoadingSpinner'

import "./dashboard.css"

function BotDashboard() {

  const sessionEstablished = useSelector(state => state.eventStore.sessionEstablished)

  return (
    <div className='dashboard_container'>
      <Sidebar />
      <Chat />
      {!sessionEstablished && <LoadingSpinner />}
    </div>
  )
}

export default BotDashboard
