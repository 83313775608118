import React from 'react';
import queryString from 'query-string'

export default function PaymentsFail() {
  const parsed = queryString.parse(document.location.search);

console.log(parsed);

  return (
    <>
      <br />
      <h1>결제실패</h1>

      <h1>{parsed.amount}</h1>
      <h1>{parsed.orderId}</h1>
      <h1>{parsed.paymentKey}</h1>

    </>
  )
}
