import React from 'react'
import MyNumberInput from '../../../../../app/common/form/MyNumberInput'
import MyTextInput from '../../../../../app/common/form/MyTextInput'
import MyTimeInput from '../../../../../app/common/form/MyTimeInput'
import MySelectInput from '../../../../../app/common/form/MySelectInput'
import { dayType } from '../../../../../app/api/dropdownOption'
import { toast } from 'react-toastify'

export default function WorkerFormTimeWhole() {

  function expectHoliWorksHandler () {
    toast('본 시스템의 주52시간제 판단은 귀사에서 입력하시려는 예정된 휴일근로의 주평균 근로시간을 포함한 결과입니다. 즉, 연간휴일근로시간을 주평균으로 나눈 값으로 주52시간제 준수로 계산되었더라도 실제 휴일근로일이 속한 달의 특정주는 1주 52시간제를 위반할 수 있음을 안내하오니 주의하시기 바랍니다.')
  }

  function expectOverWorksHandler () {
    toast(`요일별 근무시간이 특정되지 않은 연장근로는 소정근로 이외의 근로이므로 통상임금에서 제외되는 것이 원칙이나 일정한 조건하에서는 동 금액의 실질이 정기성, 일률성, 고정성 이라는 통상임금 요건을 총족하여 통상임금으로 해석되어 시급이 높아지는 경우가 있으니 전문가와 상의하여 선택적근로시간제 등 유연근무제로 변경하는 것을 권고합니다.`)
  }

  return (
    <div style={{ display : 'flex', flexWrap : 'wrap', justifyContent: 'flex-start', alignItems : 'center', alignContent : 'flex-end'}}>
      {/* 포괄역산 연장, 야간, 휴일 시간 설정 */}
      <div style={{width : '100%', marginBottom : "5px", marginTop : "5px", fontSize: '13px', color: 'green'}}>
        ●  '위 출퇴근시간이 정해진 근무시간외에 사무직 등 업무 특성상 근무일이 불특정한 연장, 야간 근로가 일정하게 있는 경우(1주일 단위)'
      </div>
      <div className='margin' style={{width : '49%', marginRight : '3px'}}>
        <MyNumberInput
          onClick={expectOverWorksHandler}
          label='(10-1) 주40시간 이상인 주간 불특정 고정연장'
          name='expectWeeklyOverWorks' 
          placeholder='예) 1주간 12시간' 
          autoComplete='off'
          min = "0"
          max = "12"
        />
      </div>
      <div className='margin' style={{width : '49%', marginRight : '3px'}}>
        <MyNumberInput
          label='(10-2) 1주간 고정야간근로시간(근무일 불특정) '
          name='expectWeeklyNightWorks' 
          placeholder='예) 1주간 00시간' 
          autoComplete='off'
          min = "0"
          max = "56"
        />
      </div>
      {/* 포괄역산 휴일근로 시간 설정 */}
      <div style={{width : '100%', marginBottom : "5px", marginTop : "50px", fontSize: '13px',  color: 'green'}}>
        ●  '1년간 52일의 주휴일(약정휴일이 있는 경우 104일) 중 일부라도 근무가 예정된 경우 휴일 출퇴근 근로시간 설정시' 
      </div>
      <div className='margin' style={{width : '25%', marginRight : '3px'}}>
        <MyNumberInput
          onClick={expectHoliWorksHandler}
          type = 'number'
          label='(9-1) 1년간 근무예정 휴일갯수'
          name='expectYearlyHolidayWorks' 
          placeholder='예) 년 12회 등' 
          autoComplete='off'
          min = "0"
          max = "52"
        />
      </div>
      <div className='margin' style={{width : '24%', marginRight : '3px'}}>
        <MyTimeInput 
          label='(9-2) 출근시간'
          name='expectYearlyHolidayWorksInfo.workingStartTime' 
          autoComplete='off'
        />
      </div>
      <div className='margin' style={{width : '24%', marginRight : '3px'}}>
        <MySelectInput 
          label='(9-3) 퇴근일(당일/익일)' 
          placeholder='당일' 
          name='expectYearlyHolidayWorksInfo.workingEndTimeNextDayOrNot' 
          options={dayType} 
        />
      </div>
      <div className='margin' style={{width : '25%', marginRight : '3px'}}>
        <MyTimeInput 
          label='(9-4) 퇴근시간'
          name='expectYearlyHolidayWorksInfo.workingEndTime' 
          autoComplete='off'
        />
      </div>
      <div className='margin' style={{width : '33%', marginRight : '3px'}}>
        <MyNumberInput 
          label='(9-5) 22시 이전 휴게시간 합계(분)'
          name='expectYearlyHolidayWorksInfo.dayBreakTimes' 
          placeholder='없으면 "0"'
          autoComplete='off'
        />
      </div>
      <div className='margin' style={{width : '33%', marginRight : '3px'}}>
        <MyNumberInput 
          label='(9-6) 22시~익일06시 휴게시간 합계(분)'
          name='expectYearlyHolidayWorksInfo.nightBreakTimes' 
          placeholder='없으면 "0"'
          autoComplete='off'
        />
      </div>
      <div className='margin' style={{width : '33%', marginRight : '3px'}}>
        <MyTextInput 
          label='(9-7) 휴게시작 및 종료(근로계약서용)'
          name='expectYearlyHolidayWorksInfo.breakTimeContents' 
          placeholder='12:00~13:00, 14:00~14:20, 23:00~ 02:00 등,'
          autoComplete='off'
        />
      </div>
    </div> 
  )
}
