import React from 'react';
import { Table } from 'semantic-ui-react';

export default function ClientReqDLCWageTableDetailLongTerm({
	weekLBTime,
	gumakStyle,
	cOWLongTerm,
	cOWLongTermComment,
	isExceptTimeLawWorker,
}) {
	return (
		<>
			<Table.Cell className='gubun'>
				통상임금{' '}
				{weekLBTime < 15 || isExceptTimeLawWorker ? null : (
					<>
						{' '}
						<br /> (주휴포함)
					</>
				)}
			</Table.Cell>
			<Table.Cell className='hangmok'>근속수당</Table.Cell>
			<Table.Cell className='gumak' style={gumakStyle}>
				{cOWLongTerm}
			</Table.Cell>
			<Table.Cell textAlign='left' colSpan='6' className='sulmung'>
				{cOWLongTermComment}
			</Table.Cell>
		</>
	);
}
