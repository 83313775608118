import React, { useState } from 'react';
import { Button, Form } from 'semantic-ui-react';
import { sendPasswordResetEmail } from "firebase/auth";
import { auth } from '../../../src/app/config/firebase';

const PasswordReset = () => {
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');

  const sendResetEmail = async (event) => {
    event.preventDefault();
    try {
      await sendPasswordResetEmail(auth, email);
      setMessage(`비밀번호 재설정 이메일이 ${email}로 발송되었습니다.`);
    } catch (error) {
      setMessage(`에러: ${error.message}`);
    }
  };

  return (
    <div>
      <h3>비밀번호 재설정</h3>
      <Form onSubmit={sendResetEmail}>
        <input
          type="email"
          placeholder="이메일 주소"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          required
        />
        <br />
        <br />
        <Button type="submit">비밀번호 재설정 이메일 보내기</Button>
        <p>* Google에서 자동으로 발생하는 재설정 메일입니다. 스펨메일로 들어가는 경우가 있으니 참고하세요.</p>
      </Form>
      <hr />
      {message && <p>{message}</p>}
    </div>
    
  );
};

export default PasswordReset;
