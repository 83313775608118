import React from 'react';
import WorkerUserForm from './WorkerUserForm';

export default function ProfileFormWorker({ profile }) {
	return (
		<>
			<WorkerUserForm profile={profile} />
		</>
	);
}
