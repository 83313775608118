import React, { useState, useMemo, useEffect } from 'react';
import '../Roe1GeneralRules.css'; // CSS 파일 불러오기

const RuleSection = ({
	number,
	title,
	content,
	isOpen,
	toggleOpen,
	amended,
}) => {
	const formatContent = (content) => {
		if (!content) return null; // content가 undefined일 경우 null 반환

		let mainCount = 0; // 0부터 시작하여 CSS에서 올바르게 매핑될 수 있도록 설정

		return content.map((item, index) => {
			if (Array.isArray(item)) {
				return (
					<ol key={index} type='1'>
						{item.map((subItem, subIndex) => (
							<li
								key={subIndex}
								className='rule-sub-item'
								data-index={subIndex}>
								{subItem}
							</li>
						))}
					</ol>
				);
			} else {
				const formattedItem = item;
				return (
					<li key={index} className='rule-item' data-index={mainCount++}>
						{formattedItem}
					</li>
				);
			}
		});
	};

	return (
		<div>
			<div className='jau__title sectionup' onClick={toggleOpen}>
				{`제${number}조 ${title}`}
			</div>
			{isOpen && (
				<div className='jau__contents'>
					<ul>{formatContent(content)}</ul>
					{amended && (
						<div className='no-print'>
							<ul>
								{amended.map((amendment, i) => (
									<li
										key={i}
										style={{
											color: amendment?.includes('개정') ? 'red' : 'black',
										}}>
										{amendment}
									</li>
								))}
							</ul>
						</div>
					)}
				</div>
			)}
		</div>
	);
};

export default function RoeBS3ManageRules({
	serviceStartDate,
	profile,
	authLevel,
	company,
	companyUn,
	companyGa,
	allOpen,
	previousSectionCount1,
	previousSectionCount2,
	setSectionCount,
}) {
	const sections = useMemo(() => {
		let baseSections = [
			{
				title: `비밀구분`,
				content: [
					`영업비밀은 그 중요성과 가치의 정도에 따라 다음과 같이 구분한다.`,
					[`제1급 영업비밀`, `제2급 영업비밀`, `제3급 영업비밀`, `대외비`],
					`기타 필요한 사항은 별도로 정할 수 있다.`,
				],
			},
			{
				title: `제1급 영업비밀`,
				content: [
					`제1급 영업비밀이란 ${companyGa} 비밀로 보호하면 반영구적으로 사용이 가능하거나 경영에 절대적인 영향을 미치는 다음 기준의 정보이다.`,
					[
						`원료 원천 공급사와 관련한 일체의 정보`,
						`원료의 구성과 배합비율`,
						`제품의 제조비법`,
						`원료의 supply chain`,
						'Film 구성비율',
						`원료 Compound의 구성비율`,
						`대표이사가 제1급 영업비밀로 분류한 정보`,
					],
					`제1급 영업비밀은 문서관리규정상 영구보관 분서로 분류한다.`,
					`기타 필요한 사항은 별도로 정할 수 있다.`,
				],
			},
			{
				title: `제2급 영업비밀`,
				content: [
					`제2급 영업비밀이란 일반적으로 공개되지 아니한 정보로서 기업활동에 유용한 주요 기술상, 경영상 또는 기타 주요정보이고 비밀로 관리할 가치가 있는 다음 기준의 정보이다.`,
					[
						`국내 최초이고 유일한 미공개 기술 및 신제품 관련 정보`,
						`동종업계 우위를 확보할 수 있는 기술 및 경영정보`,
						`${company} 운영상 절대적 영향을 미치는 경영전략과 영업에 관한 정보`,
						`유출될 경우 경쟁업체 등에 큰 이익이 되는 주요 정보`,
						`새로운 연구내용, 신규사업 및 주요분석에 관한 정보`,
						`공개를 전제로 한 특허, 실용신안, 의장 등을 출원하기 전의 자료`,
						`${company}의 기본적인 계획과 경영정책에 관한 기본문서`,
						`${company} 규정의 제정, 개폐에 관한 원문서`,
						`${company} 관련 소송 관계 문서`,
						`중요 재산 관계 기본 문서`,
						`인사 발령 문서`,
						`대표이사 또는 임원이 제2급 영업비밀로 분류한 정보`,
					],
					`제2급 영업비밀은 문서관리규정상 영구보관 문서로 분류한다.`,
					`기타 필요한 사항은 별도로 정할 수 있다.`,
				],
			},
			{
				title: `제3급 영업비밀`,
				content: [
					`제3급 영업비밀이란 누설될 경우 ${company}의 손실을 초래하거나 경쟁회사에 이익이 될 수 있는 다음 기준의 정보이다.`,
					[
						`동종업계에 상대적 우위를 확보할 수 있는 기술 및 경영정보`,
						`원가분석, 경영진단, 분석자료, 부분적 연구자료`,
						`경쟁업체에 누설되어서는 안될 정보`,
						`${company}의 인사, 재무, 환경 등 주요 관리정보`,
						`중장기 경영정책 및 사업계획에 관한 기본문서`,
						`재산권, 채권 등 권리, 의무에 관한 사항 중 10년간 보관할 필요가 있는 문서 및 대장`,
						`중요제도 개선에 관한 조사·연구·보고의 기본 문서`,
						`사업의 중요방침의 결재 서류`,
						`대표이사, 임원 또는 담당부서의 장이 제3급 영업비밀로 분류한 정보`,
					],
					`제3급 영업비밀은 문서관리규정상 10년 보관 문서로 분류한다.`,
					`기타 필요한 사항은 별도로 정할 수 있다.`,
				],
			},
			{
				title: `대외비`,
				content: [
					`대외비란 누설될 경우 ${company}의 불이익이 되거나 경쟁회사에 도움이 될 수 있는 다음 기준의 정보이다.`,
					[
						`관계업체와 경쟁에 관련된 모든 정보`,
						`공개될 경우 경쟁회사에서 대응조치가 예상되는 정보`,
						`공개될 경우 업무의 혼란과 차질이 예상되는 정보`,
						`당기 경영계획 및 사업에 관한 기본문서`,
						`각종 허가 문서 및 대장 중 5년 보관이 필요한 문서`,
						`대표이사, 임원, 담당부서의 장, 2급 이상의 임원 및 사원이 대외비로 분류한 정보`,
					],
					`대외비는 문서관리규정상 5년 보관 문서로 분류한다.`,
					`기타 필요한 사항은 별도로 정할 수 있다.`,
				],
			},
			{
				title: `영업비밀의 생산`,
				content: [
					`영업비밀을 생산할 때에는 업무와 직접 관련이 있는 최소한의 인원만 참고한다.`,
					`영업비밀 생산 과정의 초안지, 파지 등 기초 자료는 즉시 완전 파기 또는 소각하여야 한다.`,
					`영업비밀 생산과 결재 및 열람 등으로 영업비밀을 지득한 사람은 영업비밀 열람기록전에 기록·유지하여야 한다. 단, 대외비는 생략할 수 있다.`,
					`기타 필요한 사항은 별도로 정할 수 있다.`,
				],
			},
			{
				title: `영업비밀의 귀속`,
				content: [
					`임원 및 사원이 직무와 관련하여 연구·개발한 영업비밀은 ${company}에 귀속한다.`,
					`임원 및 사원이 자신의 일반적 지식, 경험, 기술에 근거하여 창출한 영업비밀에 대해서는 특별한 약정이 있을 경우 그 약정에 따르고, 약정이 없을 경우 당해 사원의 귀속으로 한다.`,
					`기타 필요한 사항은 별도로 정할 수 있다.`,
				],
			},
			{
				title: `영업비밀 신고`,
				content: [
					`임원 및 사원이 재직 중 영업비밀을 창출한 경우에는 영업비밀 관리책임자에게 신고하여야 한다.`,
					`임원 및 사원이 본 규정의 적용을 받지 아니하는 타인과 공동으로 ${company}의 업무와 관련된 영업비밀을 창출한 경우에도 제1항의 규정에 따라 신고하여야 한다.`,
					`기타 필요한 사항은 별도로 정할 수 있다.`,
				],
			},
			{
				title: `보상`,
				content: [
					`임원 및 사원이 창출한 영업비밀 중 상당한 가치가 있는 영업비밀에 대해서는 보상금을 지급하여야 한다.`,
					`임원 및 사원이 창출한 대외비는 보상금을 지급하지 않는다.`,
					`기타 필요한 사항은 별도로 정할 수 있다.`,
				],
			},
			{
				title: `취득`,
				content: [
					`임원 및 사원이 영업비밀을 외부로부터 취득하였을 경우, 영업비밀 관리책임자에게 신고하고, 영업비밀 관리책임자는 이를 관리대장에 기재하여 임원 및 사원이 창출한 영업비밀과 같은 방법으로 관리한다.`,
					`기타 필요한 사항은 별도로 정할 수 있다.`,
				],
			},
			{
				title: `영업비밀 표시`,
				content: [
					`영업비밀은 그 표지에 각 등급에 따라 구분하여 표시하여야 한다.`,
					`단일문서(전자문서 포함) 또는 책자(전자책자 포함), 전자문서로서 매 면마다 영업비밀의 등급이 다른 때에는 매 면에 해당등급의 영업비밀을 표시하고, 표지에는 그 문서 또는 책자의 최고등급을 표시한다.`,
					`영업비밀문서의 등급표시는 문서의 표지 외에 매 면 중앙상단에 표시를 하며, 대외비 문서는 전면표지에만 표시할 수 있다.`,
					`도안, 도면, 상황판 등은 매면 상단 중앙에 영업비밀등급표시(문서표시와 동일)를 하고 접거나 말았을 때에는 영업비밀임을 알 수 있도록 그 이면 적절한 곳에 영업비밀을 표시한다.`,
					`녹음 및 구두 설명의 경우에는 처음과 끝날 때 영업비밀 사항임을 경고하여야 한다.`,
					`필름, 사진, CD, 디스켓 등 그 자체에 비밀표시가 곤란한 때에는 봉투 등에 비밀표시를 하고 그 안에 넣어 봉인하여 관리한다.`,
					`여러 면으로 이루어진 1건의 영업비밀 문서는 매면 중앙하단에 총 면수와 그 면의 일련번호를 매 면마다 기입하여 일부분의 분실을 방지한다.`,
					`기타 필요한 사항은 별도로 정할 수 있다.`,
				],
			},
			{
				title: `관리번호, 사본번호, 예고문`,
				content: [
					`영업비밀문서는 문서표시 상단좌측 또는 여백에 다음과 같이 관리번호, 사본번호, 파기 예고문을 기입하여 관리한다.`,
					`관리번호는 영업비밀문서를 자체 생산하여 보관하거나 접수하여 보관할 때에는 영업비밀 문서관리대장과 문서표시의 관리번호란에 년도와 일련번호를 동일하게 기입한다.`,
					`사본번호는 생산한 영업비밀 원본에는 원본이라 표시하고 복제, 복사, 유인 등으로 다수의 사본을 발행한 경우에는 사선하단에 총부수, 상단에는 발행부수의 일련번호를 기입하며 영업비밀문서관리대장에 배부처별로 사본번호를 지정 기입하고 배포문서에도 동일한 번호를 기입하여 배포하여야 한다.`,
					`파기 예고문은 영업비밀의 보호기간을 발행부서에서 정하되 접수부서에서는 발행부서의 허락을 받아 접수처 보호기간을 변경할 수 있다.`,
					`기타 필요한 사항은 별도로 정할 수 있다.`,
				],
			},
			{
				title: `영업비밀의 보관원칙`,
				content: [
					`영업비밀 보관은 파기, 도난, 분실, 화재 등으로부터 보호와 비인가자의 접근을 방지할 수 있도록 이중 캐비닛(전자적 문서 등의 경우 NAS(Network Attachsed Storage) 또는 보안 클라우드)는 에 보관하되 시건장치를 철저히 하고 영업비밀문서관리 수발대장을 함께 비치·보관한다.`,
					`제1급 영업비밀, 제2급 영업비밀, 제3급 영업비밀 및 대외비는 보관함 내에서도 각각 분리하여 보관하는 것을 원칙으로 한다.`,
					`영업비밀문서의 보관은 상기 방법을 원칙으로 하되 주요비밀을 취급하는 연구소 등은 특성에 따라 보관방법을 달리할 수 있다.`,
					`기타 필요한 사항은 별도로 정할 수 있다.`,
				],
			},
			{
				title: `영업비밀 보관장소`,
				content: [
					`제1급 영업비밀의 보관용기는 대표이사 직무실 또는 통제구역에 위치한다.`,
					`제2급 영업비밀의 보관용기는 임원 직무실 또는 통제구역에 위치한다.`,
					`제3급 영업비밀의 보관용기는 담당부서 또는 제한구역에 위치한다.`,
					`대외비의 보관용기는 담당직원 또는 제한지역에 위치한다.`,
					`영업비밀이 화체된 서류 등은 일반문서와 분리하여 보관함, 금고 등 보안장치를 구비하고 있는 용기에 넣어 특별히 관리해야 한다.`,
					`전자적 문서는 보안 클라우드 또는 NAS에 보관하고, 보안 클라우드에 접근할 수 있는 권한은 영업비밀 관리책임자가 관리한다.`,
					`기타 필요한 사항은 별도로 정할 수 있다.`,
				],
			},
			{
				title: `영업비밀 보호구역 설정`,
				content: [
					`영업비밀의 보호와 중요시설장비 및 자재의 보호를 위하여 필요한 경우 다음 각 호와 같은 보호구역을 지정한다.`,
					[`통제구역`, `제한구역`, `제한지역`],
					`통제구역에는 “통제구역”임을 표시하고 제1급과 제2급 영업비밀당연 취급자 이외의 출입을 통제하여야 하며, 출입자 명부를 비치하여 출입자를 기록·보존하여야 하고, 필요할 경우 영업비밀 준수에 관한 각서 또는 서약서를 징구해야 한다.`,
					`제한구역에는 “제한구역”임을 표시하고 제3급 영업비밀 당연 취급자와 제3급 영업비밀 승인 취급자 이외의 출입을 통제하여야 하며, 출입자 명부를 비치하여 출입자를 기록·보존하여야 하고, 필요할 경우 영업비밀 준수에 관한 각서 또는 서약서를 징구해야 한다.`,
					`제한지역에는 “제한지역”임을 표시하고 정규직원 이외의 출입을 통제하여야 한다.`,
					`전자적 문서의 경우에는 보안 클라우드 또는 NAS에 보관하고, 보안 클라우드에 접근할 수 있는 권한은 영업비밀 관리책임자가 관리한다.`,
					`기타 필요한 사항은 별도로 정할 수 있다.`,
				],
			},
			{
				title: `내방객 출입관리`,
				content: [
					`${company}의 내방객 출입관리는 그 목적과 출입구역에 따라 관리하되, 구체적인 출입관리는 사업장별로 별도 지침으로 정한다.`,
					// `임원과 사원과 내방객을 식별할 수 있도록 출입증의 색상과 규격을 임직원용, 장기출입자용, 일시 출입자용으로 구분하여야 한다.`,
					`개발, 연구실과 주요 사무실 등 통제구역에는 외부인 출입자대장과 면담요지를 기록하는 일지 등을 비치할 수 있다.`,
					`기타 필요한 사항은 별도로 정할 수 있다.`,
				],
			},
			{
				title: `비밀문서 관리대장`,
				content: [
					`영업비밀 관리책임자는 등급별로 영업비밀문서 관리대장을 비치하여 영업비밀의 생산, 파기, 재분류, 열람, 수발 등에 대한 기록을 유지·관리하여야 한다.`,
					`각 부서는 영업비밀 문서관리대장을 발송용과 접수용으로 구분하여 기록할 수 있다.`,
					`영업비밀 문서관리대장(접수용)에 영업비밀을 파기, 회송 등 비밀을 보관하지 아니하게 된 때에는 그 영업비밀 해당란의 관리번호란부터 제목란까지 적색 두 줄로 삭제하되 그 부분도 해독할 수 있어야 한다.`,
					`영업비밀 문서관리대장을 갱신하는 때에는 현재 보관하는 비밀만을 새로운 대장에 이기하되 관리번호 등을 그대로 이기하고, 신·구 대장에 다음과 같은 내용을 기재한다.`,
					`전자적 영업비밀 문서관리대장은 보안 클라우드 또는 NAS에 보관하고, 보안 클라우드에 접근할 수 있는 권한은 영업비밀 관리책임자가 관리한다.`,
					`기타 필요한 사항은 별도로 정할 수 있다.`,
				],
			},
			{
				title: `복구`,
				content: [
					`영업비밀 관리책임자는 영업비밀이 훼손된 것을 알았을 때에는 이를 지체없이 관련부서에 보고하고 즉시 필요한 조치를 취하여야 한다.`,
					`기타 필요한 사항은 별도로 정할 수 있다.`,
				],
			},
			{
				title: `수령증`,
				content: [
					`영업비밀을 주고받을 때에는 이를 대장에 기입하고 수령증을 교부받아야 한다.`,
					`기타 필요한 사항은 별도로 정할 수 있다.`,
				],
			},
		];

		// if (['대한민국', '한국', '일본']?.includes(company)) {
		// 	const additionalSection = {
		// 		title: `소정근로시간`,
		// 		content: [
		// 			`사원의 소정근로시간은 아래와 같다.`,
		// 			[
		// 				`1일 소정근로시간은 휴게시간을 제외하고 8시간을 원칙으로 하되, 단시간 사원의 경우 1일 8시간을 한도로 근로계약에 의거 소정근로시간을 달리 정할 수 있다.`,
		// 				`1주 소정근로시간은 40시간을 원칙으로 하되, 단시간 사원의 경우 1주 40시간 한도로 근로계약에 의거 소정근로시간을 달리 정할 수 있다.`,
		// 			],
		// 			`15세 이상 18세 미만인 사원의 소정근로시간은 아래와 같다.`,
		// 			[
		// 				`1일 소정근로시간은 휴게시간을 제외하고 7시간을 원칙으로 하되, 단시간 사원의 경우 1일 7시간을 한도로 근로계약에 의거 소정근로시간을 달리 정할 수 있다.`,
		// 				`1주 소정근로시간은 35시간을 원칙으로 하되, 단시간 사원의 경우 1주 35시간 한도로 근로계약에 의거 소정근로시간을 달리 정할 수 있다.`,
		// 			],
		// 			`1주 소정근로시간은 월요일의 시업시각부터 기산하고, 1월 소정근로시간은 매월 1일의 시업시각부터 기산한다.`,
		// 			`경영상 필요한 경우 근로계약서 또는 임금 계약서의 작성을 통하여 기존에 제1항 및 제2항의 규정의 적용받는 것과 달리 1일 또는 1주 소정근로시간을 약정할 수 있다.`,
		// 			`고용노동부장관의 승인을 받은 감시적, 단속적 근로에 종사하는 자(경비직, 시설관리직, 운전기사 등) 및 관리, 감독업무 또는 기밀 사무를 취급하는 자는 본 규칙에서 정한 근로시간, 휴게와 휴일에 관한 규정은 적용하지 아니한다.`,
		// 			`기타 필요한 사항은 별도로 정할 수 있다.`,
		// 		],
		// 	};
		// 	baseSections.splice(1, 0, additionalSection); // "목적" 다음에 "소정근로시간" 삽입
		// }

		const excludedCompanies = ['주식회사 허브601'];

		// 조건에 따라 섹션 제거
		if (excludedCompanies?.includes(company)) {
			// "title"이 여러 개일 때 제거할 섹션 목록
			const excludedTitles = ['영업비밀 관리위원회'];

			baseSections = baseSections.filter(
				(section) => !excludedTitles?.includes(section.title)
			);
		}

		setSectionCount(baseSections.length); // 조항 수 설정

		return baseSections;
	}, [company, setSectionCount]); // eslint-disable-line react-hooks/exhaustive-deps

	const [openSections, setOpenSections] = useState({});

	useEffect(() => {
		const newOpenSections = sections.reduce((acc, section) => {
			acc[section.title] = allOpen;
			return acc;
		}, {});
		setOpenSections(newOpenSections);
	}, [allOpen, sections]);

	const toggleSection = (section) => {
		setOpenSections((prevState) => ({
			...prevState,
			[section]: !prevState[section],
		}));
	};
	return (
		<>
			<div className='jang sectionup'>제3장 임금제도</div>
			{sections.map((section, index) => (
				<div key={index}>
					<RuleSection
						number={index + previousSectionCount1 + previousSectionCount2 + 1}
						title={section.title}
						content={section.content}
						isOpen={!!openSections[section.title]}
						toggleOpen={() => toggleSection(section.title)}
						amended={section.amended}
					/>
				</div>
			))}
		</>
	);
}
