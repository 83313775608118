import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { BsSend } from "react-icons/bs";
import { v4 as uuid } from "uuid";
import { addMessage, setSelectedConversationId } from "../../eventActions";
import { sendConversationMessage } from "../../chatbotSocketConnection/socketConn";

const NewMessageInput = () => {
  const [content, setContent] = useState("");
  const dispatch = useDispatch();

  const selectedConversationId = useSelector(
    (state) => state.eventStore?.selectedConversationId || "new"
  );

  const currentUserProfile = useSelector((state) => state.profile?.currentUserProfile);
  
  const conversations = useSelector(
    (state) => state.eventStore?.conversations || {}
  );

  const selectedConversation = selectedConversationId !== "new"
    ? Object.values(conversations).find(
        (conversation) => conversation.id === selectedConversationId
      )
    : null;

  const proceedMessage = () => {
    const message = {
      aiMessage: false,
      content,
      id: uuid(),
      animate: false,
    };

    const conversationId =
      selectedConversationId === "new" ? uuid() : selectedConversationId;

    dispatch(addMessage(message, conversationId));
    dispatch(setSelectedConversationId(conversationId));
    sendConversationMessage(message, conversationId, currentUserProfile);
    setContent("");
  };

  const handleSendMessage = () => {
    if (content.length > 0) {
      proceedMessage();
    }
  };

  const handleKeyPressed = (event) => {
    if (event.key === "Enter" && content.length > 0) {
      event.preventDefault();
      proceedMessage();
    }
  };

  const isInputDisabled = selectedConversation ? 
    !selectedConversation.messages?.[selectedConversation.messages.length - 1]?.aiMessage :
    false;

  return (
    <div className="new_message_input_container">
      <input
        className="new_message_input"
        placeholder="Send a message ..."
        value={content}
        onChange={(e) => setContent(e.target.value)}
        onKeyDown={handleKeyPressed}
        disabled={isInputDisabled}
      />
      <div className="new_message_icon_container" onClick={handleSendMessage}>
        <BsSend color="grey" />
      </div>
    </div>
  );
};

export default NewMessageInput;
