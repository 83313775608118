export const LISTEN_TO_CURRENT_USER_PROFILE = 'LISTEN_TO_CURRENT_USER_PROFILE';
export const LISTEN_TO_SELECTED_USER_PROFILE = 'LISTEN_TO_SELECTED_USER_PROFILE';

export const LISTEN_TO_CURRENT_WORKER_USER = 'LISTEN_TO_CURRENT_WORKER_USER';
export const LISTEN_TO_SELECTED_WORKER_USER = 'LISTEN_TO_SELECTED_WORKER_USER';
export const LISTEN_TO_WORKER_USERS = 'LISTEN_TO_WORKER_USERS';

export const LISTEN_TO_USER_PHOTOS = 'LISTEN_TO_USER_PHOTOS';
export const LISTEN_TO_USER_EVENTS = 'LISTEN_TO_USER_EVENTS';
export const LISTEN_TO_FOLLOWERS = 'LISTEN_TO_FOLLOWERS';
export const LISTEN_TO_FOLLOWINGS = 'LISTEN_TO_FOLLOWINGS';
export const SET_FOLLOW_USER = 'SET_FOLLOW_USER';
export const SET_UNFOLLOW_USER = 'SET_UNFOLLOW_USER';
export const CLEAR_FOLLOWINGS = 'CLEAR_FOLLOWINGS';
export const LISTEN_TO_FEED = 'LISTEN_TO_FEED';


