import React from 'react'
import { Table } from 'semantic-ui-react'

export default function PaydocuDetailedPageDayPlusForTimeWorker({
  numOfWorkers,
  timeWorkerPlusMonthOWT,
  timeWorkerPlusMonthNWT,
  timeWorkerPlusMonthHoliBasicTime,
  timeWorkerPlusMonthOWTHoli,
  timeWorkerPlusMonthOffBasicTime,
  timeWorkerPlusMonthOWTOff,
  timeWorkerPlusOverTimeDates,
  timeWorkerPlusNightTimeDates,
  timeWorkerPlusHoliBasicTimeDates,
  timeWorkerPlusHoliOverTimeDates,
  timeWorkerPlusOffBasicTimeDates,
  timeWorkerPlusOffOverTimeDates,
}) {
  return (
    <>
      <Table.Row>
        {timeWorkerPlusMonthOWT && 
          <>
            {numOfWorkers >= 5 ? 
              <Table.Cell
                style={{ width: '11.11%', textAlign: 'center' }}>추가연장<br />시간수</Table.Cell>
              : 
              <Table.Cell
                style={{ width: '11.11%', textAlign: 'center' }}>추가초과<br />시간수</Table.Cell>
            }
          </>
        }
        {timeWorkerPlusMonthNWT && <Table.Cell
          style={{ width: '11.11%', textAlign: 'center' }}>추가야간<br />시간수</Table.Cell>}
        {timeWorkerPlusMonthHoliBasicTime && <Table.Cell
          style={{ width: '11.11%', textAlign: 'center' }}>추가휴일<br />기본시간수</Table.Cell>}
        {timeWorkerPlusMonthOWTHoli && 
          <>
            {numOfWorkers >= 5 ? 
              <Table.Cell
                style={{ width: '11.11%', textAlign: 'center' }}>추가휴일<br/>연장시간수</Table.Cell>
              :
              <Table.Cell
                style={{ width: '11.11%', textAlign: 'center' }}>추가휴일<br />초과시간수</Table.Cell>
            }
          </>
        }
        {timeWorkerPlusMonthOffBasicTime && <Table.Cell
          style={{ width: '11.11%', textAlign: 'center' }}>추가휴무일<br />기본시간수</Table.Cell>}
        {timeWorkerPlusMonthOWTOff && <Table.Cell
          style={{ width: '11.11%', textAlign: 'center' }}>{timeWorkerPlusMonthOWTOff ? <span style={{color: 'red'}}>추가휴무일<br />연장시간수</span> : null}</Table.Cell>}
        {!timeWorkerPlusMonthOWT && <Table.Cell></Table.Cell>}
        {!timeWorkerPlusMonthNWT && <Table.Cell></Table.Cell>}
        {!timeWorkerPlusMonthHoliBasicTime && <Table.Cell></Table.Cell>}
        {!timeWorkerPlusMonthOWTHoli && <Table.Cell></Table.Cell>}
        {!timeWorkerPlusMonthOffBasicTime && <Table.Cell></Table.Cell>}
        {!timeWorkerPlusMonthOWTOff && <Table.Cell></Table.Cell>}
        <Table.Cell></Table.Cell>
        <Table.Cell></Table.Cell>
        <Table.Cell></Table.Cell>
      </Table.Row>
      <Table.Row>
        {timeWorkerPlusMonthOWT && <Table.Cell
          style={{ width: '11.11%', textAlign: 'center' }}>{timeWorkerPlusMonthOWT}</Table.Cell>}
        {timeWorkerPlusMonthNWT && <Table.Cell
          style={{ width: '11.11%', textAlign: 'center' }}>{timeWorkerPlusMonthNWT}</Table.Cell>}
        {timeWorkerPlusMonthHoliBasicTime && <Table.Cell
          style={{ width: '11.11%', textAlign: 'center' }}>{timeWorkerPlusMonthHoliBasicTime}</Table.Cell>}
        {timeWorkerPlusMonthOWTHoli && <Table.Cell
          style={{ width: '11.11%', textAlign: 'center' }}>{timeWorkerPlusMonthOWTHoli}</Table.Cell>}
        {timeWorkerPlusMonthOffBasicTime && <Table.Cell
          style={{ width: '11.11%', textAlign: 'center' }}>{timeWorkerPlusMonthOffBasicTime}</Table.Cell>}
        {timeWorkerPlusMonthOWTOff && <Table.Cell
          style={{ width: '11.11%', textAlign: 'center' }}>{timeWorkerPlusMonthOWTOff}</Table.Cell> }
        {!timeWorkerPlusMonthOWT && <Table.Cell></Table.Cell>}
        {!timeWorkerPlusMonthNWT && <Table.Cell></Table.Cell>}
        {!timeWorkerPlusMonthHoliBasicTime && <Table.Cell></Table.Cell>}
        {!timeWorkerPlusMonthOWTHoli && <Table.Cell></Table.Cell>}
        {!timeWorkerPlusMonthOffBasicTime && <Table.Cell></Table.Cell>}
        {!timeWorkerPlusMonthOWTOff && <Table.Cell></Table.Cell>}
        <Table.Cell></Table.Cell>
        <Table.Cell></Table.Cell>
        <Table.Cell></Table.Cell>
      </Table.Row>
      <Table.Row>
        {timeWorkerPlusOverTimeDates &&
          <>
            <Table.Cell
              style={{ width: '11.11%', textAlign: 'center' }}>추가연장 : </Table.Cell>
            <Table.Cell
              colSpan='2' style={{ width: '11.11%', textAlign: 'center' }}>{timeWorkerPlusOverTimeDates}</Table.Cell>
          </>    
        }
        {timeWorkerPlusNightTimeDates &&
          <>
            <Table.Cell
              style={{ width: '11.11%', textAlign: 'center' }}>추가야간 : </Table.Cell>
            <Table.Cell
              colSpan='2' style={{ width: '11.11%', textAlign: 'center' }}>{timeWorkerPlusNightTimeDates}</Table.Cell>
          </>    
        }
        {timeWorkerPlusHoliBasicTimeDates &&
          <>
            <Table.Cell
              style={{ width: '11.11%', textAlign: 'center' }}>추가휴일기본 : </Table.Cell>
            <Table.Cell
              colSpan='2' style={{ width: '11.11%', textAlign: 'center' }}>{timeWorkerPlusHoliBasicTimeDates}</Table.Cell>
          </>    
        }
      </Table.Row>
      <Table.Row>
        {timeWorkerPlusHoliOverTimeDates &&
          <>
            <Table.Cell
              style={{ width: '11.11%', textAlign: 'center' }}>추가휴일연장 : </Table.Cell>
            <Table.Cell
              colSpan='2' style={{ width: '11.11%', textAlign: 'center' }}>{timeWorkerPlusHoliOverTimeDates}</Table.Cell>
          </>    
        }
        {timeWorkerPlusOffBasicTimeDates &&
          <>
            <Table.Cell
              style={{ width: '11.11%', textAlign: 'center' }}>추가휴무일 기본: </Table.Cell>
            <Table.Cell
              colSpan='2' style={{ width: '11.11%', textAlign: 'center' }}>{timeWorkerPlusOffBasicTimeDates}</Table.Cell>
          </>    
        }    
        {timeWorkerPlusOffOverTimeDates &&
          <>
            <Table.Cell
              style={{ width: '11.11%', textAlign: 'center' }}>추가휴무일연장 : </Table.Cell>
            <Table.Cell
              colSpan='2' style={{ width: '11.11%', textAlign: 'center' }}>{timeWorkerPlusOffOverTimeDates}</Table.Cell>
          </>    
        }
      </Table.Row>
    </>
  )
}
