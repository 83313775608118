import React from 'react';
import MyTextInput from '../../../app/common/form/MyTextInput';
import MySelectInput from '../../../app/common/form/MySelectInput';
import {
	salesPath,
	recommendPath,
	// discountRateVale,
	// companyProductEventType,
	// nomuPackageServiceType,
	// nomuServiceType,
} from '../../../app/api/dropdownOption';

import MyDateInput from '../../../app/common/form/MyDateInput';

import MyNumberInputWorkers from '../../../app/common/form/MyNumberInputWorkers';
// import {
// 	calculatePackagePrice,
// 	calculateSettingPrice,
// 	calculateTotalPrice,
// 	getSystemPrice,
// 	numberToCommaString,
// } from '../../../app/common/util/util';

export default function CustomerContractForm({
	values,
	setNumOfWorkers,
	currentUserProfile,
}) {
	const authLevel = currentUserProfile?.authLevel;

	//자동이체일자
	// const numOfWorkers = values?.numOfWorkers;

	// const nomuService = values?.nomuService || [];
	// const nomuPackageService = values?.nomuPackageService || [];

	// //패키지 서비스는 단일선택이므로 하나의 값
	// const calculatePackagePriceV = calculatePackagePrice(
	// 	numOfWorkers,
	// 	nomuPackageService
	// );
	//개별적 노무서비스 중복선택 가능하여 합계로 계산
	// const calculateTotalPriceV = calculateTotalPrice(numOfWorkers, nomuService);
	// //세팅비
	// const getSystemPriceV = getSystemPrice(numOfWorkers);
	// //시스템 사용료

	// const minPrice =
	// 	calculatePackagePriceV[0] !== 0 && calculateTotalPriceV[0] !== 0
	// 		? Math.min(calculatePackagePriceV[0], calculateTotalPriceV[0])
	// 		: calculatePackagePriceV[0] || calculateTotalPriceV[0];

	// const totalPrice = minPrice + getSystemPriceV;
	// const discountRate = values?.discountRate;
	// const discountRateNum = discountRate ? parseFloat(discountRate) / 100 : 0; // "5%" -> 0.05 변환
	// const monthDiscount = totalPrice * 1.1 * discountRateNum;
	// const discountedPrice = totalPrice * 1.1 - monthDiscount;
	// const calculateSettingPriceV = calculateSettingPrice(numOfWorkers);
	// const companyProductEvent = values?.companyProductEvent;
	// const eventDiscountRate = 0.4;
	// const discountedSettingPriceV =
	// 	companyProductEvent === '참여'
	// 		? calculateSettingPriceV * eventDiscountRate
	// 		: calculateSettingPriceV;

	// const startDateString = values?.serviceStartDate;
	// const endDateString = values?.serviceEndDate;

	// function calculateMonthDifference(startDateString, endDateString) {
	// 	if (startDateString instanceof Date && endDateString instanceof Date) {
	// 		const startYear = startDateString.getFullYear();
	// 		const startMonth = startDateString.getMonth();
	// 		const endYear = endDateString.getFullYear();
	// 		const endMonth = endDateString.getMonth();

	// 		// 개월 수 계산: 연도 차이 * 12 + 월 차이
	// 		return (endYear - startYear) * 12 + (endMonth - startMonth);
	// 	}
	// }

	// const discountMonth = calculateMonthDifference(
	// 	startDateString,
	// 	endDateString
	// );

	return (
		<>
			<h3>계약 정보</h3>
			<div
				style={{
					display: 'flex',
					flexWrap: 'wrap',
					justifyContent: 'flex-start',
					alignItems: 'center',
					alignContent: 'flex-end',
				}}>
				<div className='margin' style={{ width: '33%', marginRight: '3px' }}>
					<MyDateInput
						label='서비스시작일'
						name='serviceStartDate'
						placeholderText='달력 사용( 또는 0000-00-00  형식으로 입력요망)'
						dateFormat='yyyy-MM-dd'
						autoComplete='off'
					/>
				</div>
				<div className='margin' style={{ width: '33%', marginRight: '3px' }}>
					<MyDateInput
						label='서비스종료일'
						name='serviceEndDate'
						placeholderText='달력 사용( 또는 0000-00-00  형식으로 입력요망)'
						dateFormat='yyyy-MM-dd'
						autoComplete='off'
					/>
				</div>
				<div className='margin' style={{ width: '33%', marginRight: '3px' }}>
					<MyNumberInputWorkers
						label='서비스 시작 직원수'
						name='numOfWorkers'
						placeholder='직원수'
						autoComplete='off'
						min='1'
						setNumOfWorkers={setNumOfWorkers}
					/>
				</div>
				<div className='margin' style={{ width: '33%', marginRight: '3px' }}>
					<MySelectInput
						label='담당 매니저'
						name='salesPath'
						placeholder='인터넷 검색 등'
						options={salesPath}
						autoComplete='off'
					/>
				</div>

				{/* <div className='margin' style={{ width: '33%', marginRight: '3px' }}>
					<MySelectInput
						multiple
						label='부가 이용 노무서비스'
						name='nomuService'
						placeholder='4대보험관리, 입퇴사관리 등'
						options={nomuServiceType}
						autoComplete='off'
					/>
				</div>
				{values?.nomuService?.length > 1 && (
					<div className='margin' style={{ width: '33%', marginRight: '3px' }}>
						<MySelectInput
							label='패키지 이용 노무서비스'
							name='nomuPackageService'
							placeholder='Basic : 4대보험관리+공제임금대장 등'
							options={nomuPackageServiceType}
							autoComplete='off'
						/>
					</div>
				)}
				<div className='margin' style={{ width: '33%', marginRight: '3px' }}>
					<MySelectInput
						label='회사 제품 이벤트 참여 여부'
						name='companyProductEvent'
						placeholder='회사 제품 이벤트 참여 여부'
						options={companyProductEventType}
						autoComplete='off'
					/>
				</div> */}
				<div className='margin' style={{ width: '33%', marginRight: '3px' }}>
					<MySelectInput
						label='가입경로'
						name='recommendPath'
						placeholder='인터넷 검색 등'
						options={recommendPath}
						autoComplete='off'
					/>
				</div>
				<div className='margin' style={{ width: '33%', marginRight: '3px' }}>
					<MyTextInput
						label='추천인 성명'
						name='recommenderPersonName'
						placeholder='홍길동'
					/>
				</div>
				{authLevel >= 50 ? (
					<div className='margin' style={{ width: '33%', marginRight: '3px' }}>
						<MyTextInput
							label='특별 월 구독료'
							name='specialContractMonthPrice'
							placeholder='특별 월 구독료'
						/>
					</div>
				) : null}
				{authLevel >= 50 ? (
					<div className='margin' style={{ width: '33%', marginRight: '3px' }}>
						<MyTextInput
							label='특별 세팅비'
							name='specialContractSettingPrice'
							placeholder='특별 세팅비'
						/>
					</div>
				) : null}

				{/* <div className='margin' style={{ width: '33%', marginRight: '3px' }}>
					<MySelectInput
						label='추천 할인률'
						name='discountRate'
						options={discountRateVale}
						placeholder='추천 할인률'
					/>
				</div> */}
				{/* <div
					className='margin'
					style={{
						width: '21%',
						marginBottom: '-3px',
						marginRight: '3px',
						display: 'flex',
						alignItems: 'center',
						justifyContent: 'center',
					}}>
					<span
						style={{
							textAlign: 'center',
							fontSize: '1.1rem',
							fontWeight: '600',
							color: '#333',
						}}>
						<label
							style={{
								padding: '8px 16px',
								borderRadius: '8px',
								backgroundColor: '#f4f6f8',
								boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)',
								color: '#4a90e2',
							}}>
							{discountMonth}개월 할인액 :{' '}
							{numberToCommaString(
								monthDiscount *
									calculateMonthDifference(startDateString, endDateString)
							)}
							원
						</label>
					</span>
				</div> */}
				{/* <div
					className='margin'
					style={{
						width: '21%',
						marginBottom: '-3px',
						marginRight: '3px',
						display: 'flex',
						alignItems: 'center',
						justifyContent: 'center',
					}}>
					<span
						style={{
							textAlign: 'center',
							fontSize: '1.1rem',
							fontWeight: '600',
							color: '#333',
						}}>
						<label
							style={{
								padding: '8px 16px',
								borderRadius: '8px',
								backgroundColor: '#f4f6f8',
								boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)',
								color: '#4a90e2',
							}}>
							월 구독료 : {numberToCommaString(discountedPrice)}원
						</label>
					</span>
				</div>
				<div
					className='margin'
					style={{
						width: '21%',
						marginBottom: '-3px',
						marginRight: '3px',
						display: 'flex',
						alignItems: 'center',
						justifyContent: 'center',
					}}>
					<span
						style={{
							textAlign: 'center',
							fontSize: '1.1rem',
							fontWeight: '600',
							color: '#333',
						}}>
						<label
							style={{
								padding: '8px 16px',
								borderRadius: '8px',
								backgroundColor: '#f4f6f8',
								boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)',
								color: '#4a90e2',
							}}>
							세팅비(1회) : {numberToCommaString(discountedSettingPriceV * 1.1)}
							원
						</label>
					</span>
				</div> */}
			</div>
		</>
	);
}
