import React from 'react';
import { Table } from 'semantic-ui-react';
import ClientReqDLCDayWorkerWageTableTimeDetail from './clientReqDetailedLCDetail/clientReqDLCWageTableDetail/timeDetail/ClientReqDLCDayWorkerWageTableTimeDetail';

export default function ClientRDaylyWorkerMonthWageTableAddWork({
	centerStyle,
	worker,
	numOfWorkers,
	daylyTimePay,
	handelInputChange,
	inputTimeStyle,
	values,
	timeWorkerPlusMonthOWT,
	timeWorkerPlusMonthNWT,
	timeWorkerPlusMonthHoliBasicTime,
	timeWorkerPlusMonthOWTHoli,
	timeWorkerPlusMonthOffBasicTime,
	timeWorkerPlusMonthOWTOff,
	timeWorkerPlusHoliBasicTimeDates,
	timeWorkerPlusOverTimeDates,
	timeWorkerPlusNightTimeDates,
	timeWorkerPlusHoliOverTimeDates,
	timeWorkerPlusOffBasicTimeDates,
	timeWorkerPlusOffOverTimeDates,
	dayWorkerPlusMinus4Ins,
}) {
	return (
		<>
			{daylyTimePay > 0 ? (
				<>
					<Table.Row>
						<Table.Cell textAlign='left' colSpan='9'>
							<span
								style={{
									fontSize: '1.2rem',
									color: 'darkcyan',
									fontWeight: 'bold',
								}}>{`2-1. ${worker}의 추가근무시간 및 공제내역(시간기준)`}</span>
						</Table.Cell>
					</Table.Row>
					<Table.Row>
						{numOfWorkers >= 5 ? (
							<>
								<Table.Cell style={centerStyle}>
									1. 소정근로일
									<br />
									(1일8시간초과)
									<br />
									시간수
								</Table.Cell>
								<Table.Cell style={centerStyle}>
									2. 주시작부터6일째날
									<br />
									(1주40시간초과)
									<br />
									(8시간한도)
									<br />
									시간수
								</Table.Cell>
								<Table.Cell style={centerStyle}>
									3. 주시작부터6일째날
									<br />
									(1일8시간초과)
									<br />
									시간수
								</Table.Cell>
								<Table.Cell style={centerStyle}>
									4. 휴일
									<br />
									(주시작부터7일째날)
									<br />
									(1일8시간시간이내)
									<br />
									시간수
								</Table.Cell>
								<Table.Cell style={centerStyle}>
									5. 휴일
									<br />
									(주시작부터7일째날)
									<br />
									(1일8시간시간초과)
									<br />
									시간수
								</Table.Cell>
								<Table.Cell style={centerStyle}>
									6. 야간시간수
									<br />
									(모든일자)
								</Table.Cell>
								<Table.Cell style={centerStyle}>
									<span style={{ color: 'red' }}>
										두루누리지원금
										<br />
										(공제)
									</span>
								</Table.Cell>
								<Table.Cell style={centerStyle}>
									<span style={{ color: 'red' }}>
										공제조정
										<br />
										(대여금 등)
										<br />
										(공제)
									</span>
								</Table.Cell>
								<Table.Cell style={centerStyle}>7. 특별상여금</Table.Cell>
							</>
						) : (
							<>
								<Table.Cell style={centerStyle}>
									1. 8시간 초과시간수 (모든일자)
								</Table.Cell>
								<Table.Cell style={centerStyle}>
									2. 야간시간수 (모든일자)
								</Table.Cell>
								<Table.Cell></Table.Cell>
								<Table.Cell></Table.Cell>
								<Table.Cell></Table.Cell>
								<Table.Cell></Table.Cell>
								<Table.Cell style={centerStyle}>
									<span style={{ color: 'red' }}>
										두루누리지원금
										<br />
										(공제)
									</span>
								</Table.Cell>
								<Table.Cell style={centerStyle}>
									<span style={{ color: 'red' }}>
										공제조정
										<br />
										(대여금 등)
										<br />
										(공제)
									</span>
								</Table.Cell>
								<Table.Cell style={centerStyle}>3. 특별상여금</Table.Cell>
							</>
						)}
					</Table.Row>
					{/* 평일 연장 : 1.5배, 평일 야간 0.5배, 휴일 기본 0.5배--기본시간에 1 포함, 휴일연장 : 2배, 휴일 야간 : 0.5배 */}
					<ClientReqDLCDayWorkerWageTableTimeDetail
						numOfWorkers={numOfWorkers}
						timeWorkerPlusMonthOWT={timeWorkerPlusMonthOWT}
						timeWorkerPlusMonthNWT={timeWorkerPlusMonthNWT}
						timeWorkerPlusMonthHoliBasicTime={timeWorkerPlusMonthHoliBasicTime}
						timeWorkerPlusMonthOWTHoli={timeWorkerPlusMonthOWTHoli}
						timeWorkerPlusMonthOffBasicTime={timeWorkerPlusMonthOffBasicTime}
						timeWorkerPlusMonthOWTOff={timeWorkerPlusMonthOWTOff}
						timeWorkerSpecialBonus={values?.timeWorkerSpecialBonus}
						handelInputChange={handelInputChange}
						centerStyle={centerStyle}
						inputTimeStyle={inputTimeStyle}
						dayWorkerPlusMonthOWT={values?.dayWorkerPlusMonthOWT}
						dayWorkerPlusMonthNWT={values?.dayWorkerPlusMonthNWT}
						dayWorkerPlusMonthHoliBasicTime={
							values?.dayWorkerPlusMonthHoliBasicTime
						}
						dayWorkerPlusMonthOWTHoli={values?.dayWorkerPlusMonthOWTHoli}
						dayWorkerPlusMonthNWTHoli={values?.dayWorkerPlusMonthNWTHoli}
						dayWorkerAbsentTimes={values?.dayWorkerAbsentTimes}
						dayWorkerPlusDeductDuru={values?.dayWorkerPlusDeductDuru}
						dayWorkerPlusMinus4Ins={dayWorkerPlusMinus4Ins}
						dayWorkerPlusMinus4InsComment={
							values?.dayWorkerPlusMinus4InsComment
						}
						dayWorkerSpecialBonus={values?.dayWorkerSpecialBonus}
						daylyTimePay={daylyTimePay}
					/>
					<Table.Row>
						{timeWorkerPlusMonthOWT || timeWorkerPlusOverTimeDates ? (
							<>
								<Table.Cell style={centerStyle}>추가연장근로 요일 :</Table.Cell>
								<Table.Cell colSpan='2' style={centerStyle}>
									<input
										style={{ width: '100%' }}
										name='timeWorkerPlusOverTimeDates'
										placeholder='예) 18일 연장2시간, 19일 1시간'
										value={timeWorkerPlusOverTimeDates}
										onChange={(e) => handelInputChange(e)}
										autoComplete='off'
									/>
								</Table.Cell>
							</>
						) : null}
						{timeWorkerPlusMonthNWT || timeWorkerPlusNightTimeDates ? (
							<>
								<Table.Cell style={centerStyle}>추가야간근로 요일 :</Table.Cell>
								<Table.Cell colSpan='2' style={centerStyle}>
									<input
										style={{ width: '100%' }}
										name='timeWorkerPlusNightTimeDates'
										placeholder='예) 18일 야간2시간, 19일 1시간'
										value={timeWorkerPlusNightTimeDates}
										onChange={(e) => handelInputChange(e)}
										autoComplete='off'
									/>
								</Table.Cell>
							</>
						) : null}
						{timeWorkerPlusMonthHoliBasicTime ||
						timeWorkerPlusHoliBasicTimeDates ? (
							<>
								<Table.Cell style={centerStyle}>
									추가휴일기본근로 요일 :
								</Table.Cell>
								<Table.Cell colSpan='2' style={centerStyle}>
									<input
										style={{ width: '100%' }}
										name='timeWorkerPlusHoliBasicTimeDates'
										placeholder='예) 18일 휴일2시간'
										value={timeWorkerPlusHoliBasicTimeDates}
										onChange={(e) => handelInputChange(e)}
										autoComplete='off'
									/>
								</Table.Cell>
							</>
						) : null}
					</Table.Row>
					<Table.Row>
						{timeWorkerPlusMonthOWTHoli || timeWorkerPlusHoliOverTimeDates ? (
							<>
								<Table.Cell style={centerStyle}>
									추가휴일연장근로 요일 :
								</Table.Cell>
								<Table.Cell colSpan='2' style={centerStyle}>
									<input
										style={{ width: '100%' }}
										name='timeWorkerPlusHoliOverTimeDates'
										placeholder='예) 18일 휴일연장 2시간, 19일 1시간'
										value={timeWorkerPlusHoliOverTimeDates}
										onChange={(e) => handelInputChange(e)}
										autoComplete='off'
									/>
								</Table.Cell>
							</>
						) : null}
						{timeWorkerPlusMonthOffBasicTime ||
						timeWorkerPlusOffBasicTimeDates ? (
							<>
								<Table.Cell style={centerStyle}>
									추가휴무일 기본근로 요일 :
								</Table.Cell>
								<Table.Cell colSpan='2' style={centerStyle}>
									<input
										style={{ width: '100%' }}
										name='timeWorkerPlusOffBasicTimeDates'
										placeholder='예) 휴무일 기본 8시간이내 날짜와 시간'
										value={timeWorkerPlusOffBasicTimeDates}
										onChange={(e) => handelInputChange(e)}
										autoComplete='off'
									/>
								</Table.Cell>
							</>
						) : null}
						{timeWorkerPlusMonthOWTOff || timeWorkerPlusOffOverTimeDates ? (
							<>
								<Table.Cell style={centerStyle}>
									추가휴무일 연장근로 요일 :
								</Table.Cell>
								<Table.Cell colSpan='2' style={centerStyle}>
									<input
										style={{ width: '100%' }}
										name='timeWorkerPlusOffOverTimeDates'
										placeholder='예) 휴무일 8시간 초과한 날짜와 시간'
										value={timeWorkerPlusOffOverTimeDates}
										onChange={(e) => handelInputChange(e)}
										autoComplete='off'
									/>
								</Table.Cell>
							</>
						) : null}
					</Table.Row>
					<Table.Row>
						{values?.dayWorkerPlusMinus4Ins ? (
							<>
								<Table.Cell style={centerStyle}>
									공제후 조정(대여금 등) 설명 :
								</Table.Cell>
								<Table.Cell colSpan='2' style={centerStyle}>
									<input
										style={{ width: '100%' }}
										name='dayWorkerPlusMinus4InsComment'
										placeholder='예) 대여금 반제'
										value={values?.dayWorkerPlusMinus4InsComment}
										onChange={(e) => handelInputChange(e)}
										autoComplete='off'
									/>
								</Table.Cell>
							</>
						) : null}
					</Table.Row>
				</>
			) : (
				<>
					<Table.Row>
						<Table.Cell textAlign='left' colSpan='9'>
							<span
								style={{
									fontSize: '1.2rem',
									color: 'darkcyan',
									fontWeight: 'bold',
								}}>{`2-1. ${worker}의 추가근무시간 및 공제내역(시간기준)`}</span>
						</Table.Cell>
					</Table.Row>
					<Table.Row>
						{numOfWorkers >= 5 ? (
							<Table.Cell style={centerStyle}>
								추가연장
								<br />
								시간수
							</Table.Cell>
						) : (
							<Table.Cell style={centerStyle}>
								추가초과
								<br />
								시간수
							</Table.Cell>
						)}
						<Table.Cell style={centerStyle}>
							추가야간
							<br />
							시간수
						</Table.Cell>
						<Table.Cell style={centerStyle}>
							추가휴일
							<br />
							기본시간수
						</Table.Cell>
						{numOfWorkers >= 5 ? (
							<Table.Cell style={centerStyle}>
								추가휴일
								<br />
								연장시간수
							</Table.Cell>
						) : (
							<Table.Cell style={centerStyle}>
								추가휴일
								<br />
								초과시간수
							</Table.Cell>
						)}
						<Table.Cell style={centerStyle}>
							추가휴일
							<br />
							야간시간수
						</Table.Cell>
						<Table.Cell style={centerStyle}>
							<span style={{ color: 'red' }}>
								지각, 조퇴 등<br />
								(공제시간)
							</span>
						</Table.Cell>
						<Table.Cell style={centerStyle}>
							<span style={{ color: 'red' }}>
								두루누리지원금
								<br />
								(공제)
							</span>
						</Table.Cell>
						<Table.Cell style={centerStyle}>
							<span style={{ color: 'red' }}>
								공제조정
								<br />
								(대여금 등)
								<br />
								(공제)
							</span>
						</Table.Cell>
						<Table.Cell>특별상여금</Table.Cell>
					</Table.Row>
					<ClientReqDLCDayWorkerWageTableTimeDetail
						numOfWorkers={numOfWorkers}
						timeWorkerPlusMonthOWT={timeWorkerPlusMonthOWT}
						timeWorkerPlusMonthNWT={timeWorkerPlusMonthNWT}
						timeWorkerPlusMonthHoliBasicTime={timeWorkerPlusMonthHoliBasicTime}
						timeWorkerPlusMonthOWTHoli={timeWorkerPlusMonthOWTHoli}
						timeWorkerPlusMonthOffBasicTime={timeWorkerPlusMonthOffBasicTime}
						timeWorkerPlusMonthOWTOff={timeWorkerPlusMonthOWTOff}
						timeWorkerSpecialBonus={values?.timeWorkerSpecialBonus}
						handelInputChange={handelInputChange}
						centerStyle={centerStyle}
						inputTimeStyle={inputTimeStyle}
						dayWorkerPlusMonthOWT={values?.dayWorkerPlusMonthOWT}
						dayWorkerPlusMonthNWT={values?.dayWorkerPlusMonthNWT}
						dayWorkerPlusMonthHoliBasicTime={
							values?.dayWorkerPlusMonthHoliBasicTime
						}
						dayWorkerPlusMonthOWTHoli={values?.dayWorkerPlusMonthOWTHoli}
						dayWorkerPlusMonthNWTHoli={values?.dayWorkerPlusMonthNWTHoli}
						dayWorkerAbsentTimes={values?.dayWorkerAbsentTimes}
						dayWorkerPlusDeductDuru={values?.dayWorkerPlusDeductDuru}
						dayWorkerPlusMinus4Ins={dayWorkerPlusMinus4Ins}
						dayWorkerSpecialBonus={values?.dayWorkerSpecialBonus}
					/>
					<Table.Row>
						{values?.dayWorkerPlusMonthOWT ||
						values?.dayWorkerPlusOverTimeDates ? (
							<>
								<Table.Cell style={centerStyle}>추가연장근로 요일 :</Table.Cell>
								<Table.Cell colSpan='2' style={centerStyle}>
									<input
										style={{ width: '100%' }}
										name='dayWorkerPlusOverTimeDates'
										placeholder='예) 18일 연장2시간, 19일 1시간'
										value={values?.dayWorkerPlusOverTimeDates}
										onChange={(e) => handelInputChange(e)}
										autoComplete='off'
									/>
								</Table.Cell>
							</>
						) : null}
						{values?.dayWorkerPlusMonthNWT ||
						values?.dayWorkerPlusNightTimeDates ? (
							<>
								<Table.Cell style={centerStyle}>추가야간근로 요일 :</Table.Cell>
								<Table.Cell colSpan='2' style={centerStyle}>
									<input
										style={{ width: '100%' }}
										name='dayWorkerPlusNightTimeDates'
										placeholder='예) 18일 야간2시간, 19일 1시간'
										value={values?.dayWorkerPlusNightTimeDates}
										onChange={(e) => handelInputChange(e)}
										autoComplete='off'
									/>
								</Table.Cell>
							</>
						) : null}
						{values?.dayWorkerPlusMonthHoliBasicTime ||
						values?.dayWorkerPlusHoliBasicTimeDates ? (
							<>
								<Table.Cell style={centerStyle}>
									추가휴일기본근로 요일 :
								</Table.Cell>
								<Table.Cell colSpan='2' style={centerStyle}>
									<input
										style={{ width: '100%' }}
										name='dayWorkerPlusHoliBasicTimeDates'
										placeholder='예) 18일 휴일2시간'
										value={values?.dayWorkerPlusHoliBasicTimeDates}
										onChange={(e) => handelInputChange(e)}
										autoComplete='off'
									/>
								</Table.Cell>
							</>
						) : null}
					</Table.Row>
					<Table.Row>
						{values?.dayWorkerPlusMonthOWTHoli ||
						values?.dayWorkerPlusHoliOverTimeDates ? (
							<>
								<Table.Cell style={centerStyle}>
									추가휴일연장근로 요일 :
								</Table.Cell>
								<Table.Cell colSpan='2' style={centerStyle}>
									<input
										style={{ width: '100%' }}
										name='dayWorkerPlusHoliOverTimeDates'
										placeholder='예) 18일 휴일연장 2시간, 19일 1시간'
										value={values?.dayWorkerPlusHoliOverTimeDates}
										onChange={(e) => handelInputChange(e)}
										autoComplete='off'
									/>
								</Table.Cell>
							</>
						) : null}
						{values?.dayWorkerPlusMonthNWTHoli ||
						values?.dayWorkerPlusHoliNightTimeDates ? (
							<>
								<Table.Cell style={centerStyle}>
									추가휴일야간근로 요일:
								</Table.Cell>
								<Table.Cell colSpan='2' style={centerStyle}>
									<input
										style={{ width: '100%' }}
										name='dayWorkerPlusHoliNightTimeDates'
										placeholder='예) 18일 휴일야간2시간, 19일 1시간'
										value={values?.dayWorkerPlusHoliNightTimeDates}
										onChange={(e) => handelInputChange(e)}
										autoComplete='off'
									/>
								</Table.Cell>
							</>
						) : null}
						{values?.dayWorkerAbsentTimes ||
						values?.dayWorkerAbsentTimeDates ? (
							<>
								<Table.Cell style={centerStyle}>공제시간설명 :</Table.Cell>
								<Table.Cell colSpan='2' style={centerStyle}>
									<input
										style={{ width: '100%' }}
										name='dayWorkerAbsentTimeDates'
										placeholder='예) 18일 지각1시간'
										value={values?.dayWorkerAbsentTimeDates}
										onChange={(e) => handelInputChange(e)}
										autoComplete='off'
									/>
								</Table.Cell>
							</>
						) : null}
					</Table.Row>
					<Table.Row>
						{values?.dayWorkerPlusMinus4Ins ? (
							<>
								<Table.Cell style={centerStyle}>공제후 조정 설명 :</Table.Cell>
								<Table.Cell colSpan='2' style={centerStyle}>
									<input
										style={{ width: '100%' }}
										name='dayWorkerPlusMinus4InsComment'
										placeholder='예) 대여금 반제'
										value={values?.dayWorkerPlusMinus4InsComment}
										onChange={(e) => handelInputChange(e)}
										autoComplete='off'
									/>
								</Table.Cell>
							</>
						) : null}
					</Table.Row>
					{values?.dayWorkerPlusMonthOWT ||
					values?.dayWorkerPlusMonthNWT ||
					values?.dayWorkerPlusMonthHoliBasicTime ||
					values?.dayWorkerPlusMonthOWTHoli ||
					values?.dayWorkerPlusMonthNWTHoli ? (
						<Table.Row>
							<Table.Cell colSpan='9'>
								<span>
									* 추가 근로에 대한 임금은 요일이 특정되지 않아 일용직 세금에
									반영되지 않았습니다. 세법 전문가인 세무사와 상의해서{' '}
									<span style={{ color: 'red' }}>
										추가 세금이 있는 경우 오른쪽 상단 붉은색 공제수기입력 버튼
									</span>
									을 통해 이를 공제하세요.{' '}
								</span>
							</Table.Cell>
						</Table.Row>
					) : null}
				</>
			)}
		</>
	);
}
