import { 
  CREATE_UNTACT, 
  UPDATE_UNTACT, 
  DELETE_UNTACT, 
  FETCH_UNTACTS, 
  LISTEN_TO_UNTACT_CHAT, 
  LISTEN_TO_SELECTED_UNTACT, 
  CLEAR_UNTACTS, 
  SET_START_DATE_UNTACT, 
  CLEAR_SELECTED_UNTACT, 
  CLEAR_UNTACTS_TABLE_SAMPLE,
  FETCH_UNTACTS_TABLE_SAMPLE,
  SET_FILTER_UNTACT} from "./untactConstants";
import {
  asyncActionError, 
  asyncActionFinish, 
  asyncActionStart} from '../../app/async/asyncReducer';
import {
  dataFromSnapshotIdDate, 
  dataFromSnapshotIdDateTableSample, 
  fetchUntactsFromFirestore,
  fetchUntactsTableSampleFromFirestore} from '../../app/firestore/firestoreService';

export function fetchUntacts(filter, startDate, limit, lastDocSnapshot) {
  return async function(dispatch) {
    dispatch(asyncActionStart());
    try {
      const snapshot = await fetchUntactsFromFirestore(filter, startDate, limit, lastDocSnapshot).get();
      //fireStoreService.js : fetchEventsFromFirestore : Data 추출시 limit를 고려하면서 filter랑 해서 화면에서 events를 보여주는 함수
      const lastVisible = snapshot.docs[snapshot.docs.length - 1];
      const moreUntacts = snapshot.docs.length >= limit;
      const untacts = snapshot.docs.map(doc => dataFromSnapshotIdDate(doc));
      //fireStoreService.js : doc을 돌면서 doc에 firestoreTimeStamp가 있으면 자바 날짜 형식으로 바꾸기 함수
      dispatch({type: FETCH_UNTACTS, payload:{untacts, moreUntacts, lastVisible}});
      dispatch(asyncActionFinish());
    } catch (error) {
      dispatch(asyncActionError());
    }
  }
}

export function fectchUntactsTableSample() {
  return async function(dispatch) {
    dispatch(asyncActionStart());
    try {
      const snapshot = await fetchUntactsTableSampleFromFirestore().get();
      const untactsSample = snapshot.docs.map(doc => dataFromSnapshotIdDateTableSample(doc));
      dispatch({type: FETCH_UNTACTS_TABLE_SAMPLE, payload:{untactsSample}});
      dispatch(asyncActionFinish());
    } catch (error) {
      dispatch(asyncActionError());
    }
  }
}

export function setFilterUntact(value) {
  return function(dispatch) {
    dispatch(clearUntacts());
    dispatch({type: SET_FILTER_UNTACT, payload: value});
  }
}

export function setStartDateUntact(date) {
  return function(dispatch) {
    dispatch(clearUntacts());
    dispatch({type: SET_START_DATE_UNTACT, payload: date});
  }
}

// 변경전 ListenToEvents
export function listenToSelectedUntact(untact) {
  return {
    type: LISTEN_TO_SELECTED_UNTACT,
    payload: untact
  }
}

export function clearSelectedUntact() {
  return {
    type: CLEAR_SELECTED_UNTACT,
  }
}

export function createUntact(untact) {
  return {
    type: CREATE_UNTACT,
    payload: untact
  }
}

export function updateUntact(untact) {
  return {
    type: UPDATE_UNTACT,
    payload: untact
  }
}

export function deleteUntact(untactId) {
  return {
    type: DELETE_UNTACT,
    payload: untactId
  }
}

export function listenToUntactChat(comments) {
  return {
    type: LISTEN_TO_UNTACT_CHAT,
    payload: comments
  }
}

export function clearUntacts() {
  return {
    type: CLEAR_UNTACTS,
  }
}

export function clearUntactsTableSample() {
  return {
    type: CLEAR_UNTACTS_TABLE_SAMPLE,
  }
}