import { format } from 'date-fns';
import React from 'react';
import { josaUnType, josaWaType, workerStatus } from '../../../../../app/common/util/util';

const ContractTitle = ({ companyName, cEDate, weekLBTime, contractOldWorker, isExceptTimeLawWorker, isSkipWeekedWorker, netAge15To18 }) => {
  if (companyName === '세무회계 정연') {
    return <h1 className='contract__type'>정규직 근로계약서</h1>;
  }
  return (
    <>
      <h1 className='contract__type'>
        {`${workerStatus(cEDate, weekLBTime, contractOldWorker, isExceptTimeLawWorker, netAge15To18)} 근로계약서`}
      </h1>
      <span>{isSkipWeekedWorker ? '(2주단위 탄력적 근로시간제)' : ''}</span>
    </>
  );
};

const ContractContent = ({ companyName, companyWa, companyUn, workerUn, worker, cEDate, weekLBTime, contractOldWorker, isExceptTimeLawWorker, netAge15To18 }) => {
  const isSeMuHoeGae = companyName === '세무회계 정연';
  const contractCondition = isExceptTimeLawWorker ?
    `${companyWa} ${workerUn} ${(cEDate || weekLBTime < 40 || contractOldWorker) ? '근로기준법 및 관련 기타 노동관계 법령에 따라 ' : '근로기준법 제17조에 따라 '} 다음과 같이 ${workerStatus(cEDate, weekLBTime, contractOldWorker, isExceptTimeLawWorker, netAge15To18)} 및 근로기준법 제63조의 적용을 받기 때문에 그에 따라 근로시간, 휴게, 휴일 규정 적용 제외 근로계약을 체결하고 성실하게 이행할 것을 확인하며, ${companyUn} ${worker}에게 본 근로계약서 및 별도의 임금의 구성항목, 계산방법, 지급방법이 서면(「전자문서 및 전자거래 기본법」 제2조제1호에 따른 전자문서를 포함한다)으로 명시된 임금계약서를 교부하되, 두 당사자의 서명날인이 모두 있어야 근로계약의 효력이 발생된다.` :
    `${companyWa} ${workerUn} ${(cEDate || weekLBTime < 40 || contractOldWorker) ? '근로기준법 및 관련 기타 노동관계 법령에 따라 ' : '근로기준법 제17조에 따라 '} 다음과 같이 ${workerStatus(cEDate, weekLBTime, contractOldWorker, isExceptTimeLawWorker, netAge15To18)} 근로계약을 체결하고 성실하게 이행할 것을 확인하며, ${companyUn} ${worker}에게 본 근로계약서 및 별도의 임금의 구성항목, 계산방법, 지급방법이 서면(「전자문서 및 전자거래 기본법」 제2조제1호에 따른 전자문서를 포함한다)으로 명시된 임금계약서를 교부하되, 두 당사자의 서명날인이 모두 있어야 근로계약의 효력이 발생된다.`;

  return (
    <ul>
      <li>
        {isSeMuHoeGae ? '정규직 근로계약 체결 내용' : contractCondition}
      </li>
    </ul>
  );
};

export default function ClientReqDLCHead({
  weekLBTime, 
  worker, 
  selectedClientReq4In, 
  companyName, 
  isExceptTimeLawWorker, 
  isSkipWeekedWorker,
  netAge15To18,
  netAge,
  contractOldWorker
}) {
  const cEDate = selectedClientReq4In?.contractEndDate ? format(selectedClientReq4In.contractEndDate, 'yyyy-MM-dd'): null;
  const companyWa = josaWaType(companyName);
  const companyUn = josaUnType(companyName);
  const workerUn = josaUnType(worker);

  return (
    <>
      <table border="0.1" width ="100%" align = "center" >
        <tbody>
          <tr>
            <td align = "center">
              <ContractTitle {...{ companyName, cEDate, weekLBTime, contractOldWorker, isExceptTimeLawWorker, isSkipWeekedWorker, netAge15To18 }} />
            </td>
          </tr>
        </tbody>
      </table>
      <div className="jau__title sectionup">
        <ContractContent {...{ companyName, companyWa, companyUn, workerUn, worker, cEDate, weekLBTime, contractOldWorker, isExceptTimeLawWorker, netAge, netAge15To18 }} />
      </div>
    </>
  );
}
