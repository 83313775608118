import React, { useEffect, useState } from 'react';
import { Button } from 'semantic-ui-react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { format } from 'date-fns';
import {
	numberToCommaString,
	sumOfAbsentDaysLawOverWagesCom,
	sumOfAbsentDaysOrdinaryWagesCom,
	sumOfAbsentPaidHoliDaysOrdinaryWagesCom,
	sumOfAbsentTimeWagesCom,
	sumOfAWage1WagesCom,
	sumOfAWage2WagesCom,
	sumOfAWage3WagesCom,
	sumOfAWage4WagesCom,
	sumOfAWage5WagesCom,
	sumOfAWage6WagesCom,
	sumOfAWage7WagesCom,
	sumOfAWageplusDeductDuruCom,
	sumOfAWageplusMinus4InssCom,
	sumOfAWageplusDeductHealthCareCom,
	sumOfAWageplusDeductOldCareCom,
	sumOfAWageplusDeductWorkerCompanyLoanCom,
	sumOfAWageplusDeductWorkRegionTaxCom,
	sumOfAWageplusDeductWorkTaxCom,
	sumOfAWageplusIrregularBonusCom,
	sumOfAWageplusLastMonthAverWageCom,
	sumOfAWageplusPersonalIncentiveCom,
	sumOfAWageplusRegularBonusCom,
	sumOfAWageplusRetiredUnusedAnnualLeavesWageCom,
	sumOfAWageplusUnusedAnnualLeavesWageCom,
	sumOfAWageTF1WagesCom,
	sumOfAWageTF2WagesCom,
	sumOfAWageTF3WagesCom,
	sumOfAWageTF4WagesCom,
	sumOfBasicWagesCom,
	sumOfCalHealthCaresSumCom,
	sumOfCalNoJobInsurancesSumCom,
	sumOfCalOldCaresSumCom,
	sumOfCalPensionsSumCom,
	sumOfCalRegionTaxsSumCom,
	sumOfCalTaxsSumCom,
	sumOfCarWagesCom,
	sumOfChildWagesCom,
	sumOfdaysOfInterInAbusentLawOverWagesCom,
	sumOfdaysOfInterInAbusentOrdinaryWagesCom,
	sumOfdaysOfInterOutAbusentLawOverWagesCom,
	sumOfdaysOfInterOutAbusentOrdinaryWagesCom,
	sumOfDedectTaxEtcsSumCom,
	sumOfFinalRetireHealthCareRecalFeeCom,
	sumOfFinalRetireOldCareRecalFeeCom,
	sumOfHoliBTimePaidWagesCom,
	sumOfHoliNTimePaidWagesCom,
	sumOfHoliOTimePaidWagesCom,
	sumOfJobWagesCom,
	sumOfLicenseWagesCom,
	sumOfLongTermWagesCom,
	sumOfMealWagesCom,
	sumOfMonthDeductedWagesCom,
	sumOfMonthDeductedWagesWorker,
	sumOfMonthlyBonusWagesCom,
	sumOfMonthWagesCom,
	sumOfMonthWagesWorker,
	sumOfNumDeductEtcAWage1sCom,
	sumOfNumDeductEtcAWage2sCom,
	sumOfNumDeductEtcAWage3sCom,
	sumOfOWEtcWagesCom,
	sumOfStudyWagesCom,
	sumOfTFEtcWagesCom,
	sumOfTitleWagesCom,
	sumOfUsedALsWorker,
	sumOfWorkDayNTimePaidWagesCom,
	sumOfWorkDayOTimePaidWagesCom,
	sumOfAWageplusSpecialRandomWageCom,
	roundUp,
	sumOfAnnualLeavesIncludeMonthWageCom,
	sumOfMaternityFinalDedectLawOverWagesCom,
	sumOfMaternityFinalDedectOrdWagesCom,
	safeStringCompare,
	sumOfAWageplusIrregularDayMealTFWageCom,
	childCompanyCOWTitleName,
	sumOfCOWChildManageStudyWagesCom,
	sumOfCOWChildStudyWagesCom,
	sumOfCOWChildCareWagesCom,
	sumOfCOWChildActivityWagesCom,
} from '../../../app/common/util/util';
import SortableTable from '../../../app/common/form/SortableTable';
import { decrypto } from '../../../app/firestore/firestoreService';
// import SortableTable3Dan from '../../../app/common/form/SortableTable3Dan';
export default function PaydocusTableList({
	paydocus,
	searchResults,
	setSearchResults,
	searchTerm,
	titleOfTable,
}) {
	const { currentUserProfile } = useSelector((state) => state.profile);
	const { clientInfo } = useSelector((state) => state.companyReducer);
	const [loading, setLoading] = useState(true);

	const id = clientInfo?.id
		? clientInfo?.id
		: clientInfo?.clientUid
		? clientInfo?.clientUid
		: currentUserProfile?.id;
	const companyName = clientInfo?.companyName
		? clientInfo?.companyName
		: currentUserProfile?.companyName;

	const authLevel = currentUserProfile?.authLevel;
	const isSuperAdmin = authLevel >= 100 ? true : false;

	// searchTerm 변경 시 searchResults 상태 업데이트
	useEffect(() => {
		setLoading(true);
		const filteredResults = searchTerm
			? paydocus.filter(
					(paydocu) =>
						paydocu?.workerInfo?.worker
							?.toLowerCase()
							?.includes(searchTerm.toLowerCase()) ||
						paydocu?.companyInfo?.companyName
							?.toLowerCase()
							?.includes(searchTerm.toLowerCase())
			  )
			: paydocus;
		setSearchResults(filteredResults);
		setLoading(false);
	}, [searchTerm, paydocus, setSearchResults]);

	const regularWorkers = searchResults.filter(
		(item) => item.dayWorker === '' && item.isBizWorker !== true
	);

	const mealWage = regularWorkers.map(
		(searchResult) => searchResult?.fixedWage?.mealWage
	);
	const mealWageValue = mealWage.find((a) => a);

	const carWage = regularWorkers.map(
		(searchResult) => searchResult?.fixedWage?.carWage
	);
	const carWageValue = carWage.find((a) => a);

	const childWage = regularWorkers.map(
		(searchResult) => searchResult?.fixedWage?.childWage
	);
	const childWageValue = childWage.find((a) => a);

	const studyWage = regularWorkers.map(
		(searchResult) => searchResult?.fixedWage?.studyWage
	);
	const studyWageValue = studyWage.find((a) => a);

	const tFEtcWage = regularWorkers.map(
		(searchResult) => searchResult?.fixedWage?.tFEtcWage
	);
	const tFEtcWageValue = tFEtcWage.find((a) => a);

	const jobWage = regularWorkers.map(
		(searchResult) => searchResult?.fixedWage?.jobWage
	);
	const jobWageValue = jobWage.find((a) => a);

	const titleWage = regularWorkers.map(
		(searchResult) => searchResult?.fixedWage?.titleWage
	);
	const titleWageValue = titleWage.find((a) => a);

	const licenseWage = regularWorkers.map(
		(searchResult) => searchResult?.fixedWage?.licenseWage
	);
	const licenseWageValue = licenseWage.find((a) => a);

	const longTermWage = regularWorkers.map(
		(searchResult) => searchResult?.fixedWage?.longTermWage
	);
	const longTermWageValue = longTermWage.find((a) => a);

	const monthlyBonusWage = regularWorkers.map(
		(searchResult) => searchResult?.fixedWage?.monthlyBonusWage
	);
	const monthlyBonusWageValue = monthlyBonusWage.find((a) => a);

	const oWEtcWage = regularWorkers.map(
		(searchResult) => searchResult?.fixedWage?.oWEtcWage
	);
	const oWEtcWageValue = oWEtcWage.find((a) => a);

	const cOWChildManageStudyWage = regularWorkers.map(
		(searchResult) => searchResult?.fixedWage?.cOWChildManageStudyWage
	);
	const cOWChildManageStudyWageValue = cOWChildManageStudyWage.find((a) => a);

	const cOWChildStudyWage = regularWorkers.map(
		(searchResult) => searchResult?.fixedWage?.cOWChildStudyWage
	);
	const cOWChildStudyWageValue = cOWChildStudyWage.find((a) => a);

	const cOWChildCareWage = regularWorkers.map(
		(searchResult) => searchResult?.fixedWage?.cOWChildCareWage
	);
	const cOWChildCareWageValue = cOWChildCareWage.find((a) => a);

	const cOWChildActivityWage = regularWorkers.map(
		(searchResult) => searchResult?.fixedWage?.cOWChildActivityWage
	);
	const cOWChildActivityWageValue = cOWChildActivityWage.find((a) => a);

	const workDayOTimePaidWage = regularWorkers.map(
		(searchResult) => searchResult?.lawOverTimeWage?.workDayOTimePaidWage
	);
	const workDayOTimePaidWageValue = workDayOTimePaidWage.find((a) => a);

	const workDayNTimePaidWage = regularWorkers.map(
		(searchResult) => searchResult?.lawOverTimeWage?.workDayNTimePaidWage
	);
	const workDayNTimePaidWageValue = workDayNTimePaidWage.find((a) => a);

	const holiBTimePaidWage = regularWorkers.map(
		(searchResult) => searchResult?.lawOverTimeWage?.holiBTimePaidWage
	);
	const holiBTimePaidWageValue = holiBTimePaidWage.find((a) => a);

	const holiOTimePaidWage = regularWorkers.map(
		(searchResult) => searchResult?.lawOverTimeWage?.holiOTimePaidWage
	);
	const holiOTimePaidWageValue = holiOTimePaidWage.find((a) => a);

	const holiNTimePaidWage = regularWorkers.map(
		(searchResult) => searchResult?.lawOverTimeWage?.holiNTimePaidWage
	);
	const holiNTimePaidWageValue = holiNTimePaidWage.find((a) => a);

	const aWENameTF1 = regularWorkers.map(
		(searchResult) => searchResult?.fixedWage?.aWENameTF1
	);
	const aWENameTF1Value = aWENameTF1.find((a) => a);

	const aWENameTF2 = regularWorkers.map(
		(searchResult) => searchResult?.fixedWage?.aWENameTF2
	);
	const aWENameTF2Value = aWENameTF2.find((a) => a);

	const aWENameTF3 = regularWorkers.map(
		(searchResult) => searchResult?.fixedWage?.aWENameTF3
	);
	const aWENameTF3Value = aWENameTF3.find((a) => a);

	const aWENameTF4 = regularWorkers.map(
		(searchResult) => searchResult?.fixedWage?.aWENameTF4
	);
	const aWENameTF4Value = aWENameTF4.find((a) => a);

	const aWEName1 = regularWorkers.map(
		(searchResult) => searchResult?.fixedWage?.aWEName1
	);
	const aWEName1Value = aWEName1.find((a) => a);

	const aWEName2 = regularWorkers.map(
		(searchResult) => searchResult?.fixedWage?.aWEName2
	);
	const aWEName2Value = aWEName2.find((a) => a);

	const aWEName3 = regularWorkers.map(
		(searchResult) => searchResult?.fixedWage?.aWEName3
	);
	const aWEName3Value = aWEName3.find((a) => a);

	const aWEName4 = regularWorkers.map(
		(searchResult) => searchResult?.fixedWage?.aWEName4
	);
	const aWEName4Value = aWEName4.find((a) => a);

	const plusRegularBonus = regularWorkers.map(
		(searchResult) => searchResult?.addAverWage?.plusRegularBonus
	);
	const plusRegularBonusValue = plusRegularBonus.find((a) => a);

	const plusIrregularBonus = regularWorkers.map(
		(searchResult) => searchResult?.addAverWage?.plusIrregularBonus
	);
	const plusIrregularBonusValue = plusIrregularBonus.find((a) => a);

	const plusUnusedAnnualLeavesWage = regularWorkers.map(
		(searchResult) => searchResult?.addAverWage?.plusUnusedAnnualLeavesWage
	);
	const plusUnusedAnnualLeavesWageValue = plusUnusedAnnualLeavesWage.find(
		(a) => a
	);

	const plusRetiredUnusedAnnualLeavesWage = regularWorkers.map(
		(searchResult) => searchResult?.plusRetiredUnusedAnnualLeavesWage
	);
	const plusRetiredUnusedAnnualLeavesWageValue =
		plusRetiredUnusedAnnualLeavesWage.find((a) => a);

	const plusSpecialRandomWage = regularWorkers.map(
		(searchResult) => searchResult?.plusSpecialRandomWage
	);
	const plusSpecialRandomWageValue = plusSpecialRandomWage.find((a) => a);

	const plusLastMonthAverWage = regularWorkers.map(
		(searchResult) => searchResult?.addAverWage?.plusLastMonthAverWage
	);
	const plusLastMonthAverWageValue = plusLastMonthAverWage.find((a) => a);

	const plusPersonalIncentive = regularWorkers.map(
		(searchResult) => searchResult?.addAverWage?.plusPersonalIncentive
	);
	const plusPersonalIncentiveValue = plusPersonalIncentive.find((a) => a);

	const aWEName5 = regularWorkers.map(
		(searchResult) => searchResult?.addAverWage?.aWEName5
	);
	const aWEName5Value = aWEName5.find((a) => a);

	const aWEName6 = regularWorkers.map(
		(searchResult) => searchResult?.addAverWage?.aWEName6
	);
	const aWEName6Value = aWEName6.find((a) => a);

	const aWEName7 = regularWorkers.map(
		(searchResult) => searchResult?.addAverWage?.aWEName7
	);
	const aWEName7Value = aWEName7.find((a) => a);

	const plusIrregularDayMealTFWage = regularWorkers.map(
		(searchResult) => searchResult?.addAverWage?.plusIrregularDayMealTFWage
	);
	const plusIrregularDayMealTFWageValue = plusIrregularDayMealTFWage.find(
		(a) => a
	);

	const absentDaysOrdinaryWage = regularWorkers.map(
		(searchResult) => searchResult?.absentMinusWage?.absentDaysOrdinaryWage
	);
	const absentDaysOrdinaryWageValue = absentDaysOrdinaryWage.find((a) => a);

	const absentPaidHoliDaysOrdinaryWage = regularWorkers.map(
		(searchResult) =>
			searchResult?.absentMinusWage?.absentPaidHoliDaysOrdinaryWage
	);
	const absentPaidHoliDaysOrdinaryWageValue =
		absentPaidHoliDaysOrdinaryWage.find((a) => a);

	const absentDaysLawOverWage = regularWorkers.map(
		(searchResult) => searchResult?.absentMinusWage?.absentDaysLawOverWage
	);
	const absentDaysLawOverWageValue = absentDaysLawOverWage.find((a) => a);

	const daysOfInterInAbusentOrdinaryWage = regularWorkers.map(
		(searchResult) =>
			searchResult?.absentMinusWage?.daysOfInterInAbusentOrdinaryWage
	);
	const daysOfInterInAbusentOrdinaryWageValue =
		daysOfInterInAbusentOrdinaryWage.find((a) => a);

	const daysOfInterInAbusentLawOverWage = regularWorkers.map(
		(searchResult) =>
			searchResult?.absentMinusWage?.daysOfInterInAbusentLawOverWage
	);
	const daysOfInterInAbusentLawOverWageValue =
		daysOfInterInAbusentLawOverWage.find((a) => a);

	const daysOfInterOutAbusentOrdinaryWage = regularWorkers.map(
		(searchResult) =>
			searchResult?.absentMinusWage?.daysOfInterOutAbusentOrdinaryWage
	);
	const daysOfInterOutAbusentOrdinaryWageValue =
		daysOfInterOutAbusentOrdinaryWage.find((a) => a);

	const daysOfInterOutAbusentLawOverWage = regularWorkers.map(
		(searchResult) =>
			searchResult?.absentMinusWage?.daysOfInterOutAbusentLawOverWage
	);
	const daysOfInterOutAbusentLawOverWageValue =
		daysOfInterOutAbusentLawOverWage.find((a) => a);

	const absentTimeWage = regularWorkers.map(
		(searchResult) => searchResult?.absentMinusWage?.absentTimeWage
	);
	const absentTimeWageValue = absentTimeWage.find((a) => a);

	const maternityFinalDedectLawOverWage = regularWorkers.map(
		(searchResult) =>
			searchResult?.absentMinusWage?.maternityFinalDedectLawOverWage
	);
	const maternityFinalDedectLawOverWageValue =
		maternityFinalDedectLawOverWage.find((a) => a);

	const maternityFinalDedectOrdWage = regularWorkers.map(
		(searchResult) => searchResult?.absentMinusWage?.maternityFinalDedectOrdWage
	);
	const maternityFinalDedectOrdWageValue = maternityFinalDedectOrdWage.find(
		(a) => a
	);

	const plusDeductHealthCare = regularWorkers.map(
		(searchResult) => searchResult?.deductTaxAnd4In?.plusDeductHealthCare
	);
	const plusDeductHealthCareValue = plusDeductHealthCare.find((a) => a);

	const plusDeductOldCare = regularWorkers.map(
		(searchResult) => searchResult?.deductTaxAnd4In?.plusDeductOldCare
	);
	const plusDeductOldCareValue = plusDeductOldCare.find((a) => a);

	const plusDeductWorkTax = regularWorkers.map(
		(searchResult) => searchResult?.deductTaxAnd4In?.plusDeductWorkTax
	);
	const plusDeductWorkTaxValue = plusDeductWorkTax.find((a) => a);

	const plusDeductWorkRegionTax = regularWorkers.map(
		(searchResult) => searchResult?.deductTaxAnd4In?.plusDeductWorkRegionTax
	);
	const plusDeductWorkRegionTaxValue = plusDeductWorkRegionTax.find((a) => a);

	const plusDeductDuru = regularWorkers.map(
		(searchResult) => searchResult?.deductTaxAnd4In?.plusDeductDuru
	);
	const plusDeductDuruValue = plusDeductDuru.find((a) => a);

	const plusMinus4Ins = regularWorkers.map(
		(searchResult) => searchResult?.deductTaxAnd4In?.plusMinus4Ins
	);
	const plusMinus4InsValue = plusMinus4Ins.find((a) => a);

	const plusDeductWorkerCompanyLoan = regularWorkers.map(
		(searchResult) => searchResult?.deductTaxAnd4In?.plusDeductWorkerCompanyLoan
	);
	const plusDeductWorkerCompanyLoanValue = plusDeductWorkerCompanyLoan.find(
		(a) => a
	);

	const deductEtcName1 = regularWorkers.map(
		(searchResult) => searchResult?.deductTaxAnd4In?.deductEtcName1
	);
	const deductEtcName1Value = deductEtcName1.find((a) => a);

	const deductEtcName2 = regularWorkers.map(
		(searchResult) => searchResult?.deductTaxAnd4In?.deductEtcName2
	);
	const deductEtcName2Value = deductEtcName2.find((a) => a);

	const deductEtcName3 = regularWorkers.map(
		(searchResult) => searchResult?.deductTaxAnd4In?.deductEtcName3
	);
	const deductEtcName3Value = deductEtcName3.find((a) => a);

	const finalRetireHealthCareRecalFee = regularWorkers.map(
		(searchResult) =>
			searchResult?.deductTaxAnd4In?.finalRetireHealthCareRecalFee
	);
	const finalRetireHealthCareRecalFeeValue = finalRetireHealthCareRecalFee.find(
		(a) => a
	);

	const finalRetireOldCareRecalFee = regularWorkers.map(
		(searchResult) => searchResult?.deductTaxAnd4In?.finalRetireOldCareRecalFee
	);
	const finalRetireOldCareRecalFeeValue = finalRetireOldCareRecalFee.find(
		(a) => a
	);

	const annualLeavesIncludeMonthWage = regularWorkers.map(
		(searchResult) => searchResult?.annualLeavesIncludeMonthWage
	);
	const annualLeavesIncludeMonthWageValue = annualLeavesIncludeMonthWage.find(
		(a) => a
	);

	const headersWorker = [
		{ key: 'yearMonth', label: '년월' },
		{ key: 'workerComNumber', label: '사번' },
		{ key: 'worker', label: '직원명' },
		{ key: 'totalMonthWageAmount', label: '공제 전 임금(월)' },
		{ key: 'deductWage', label: '공제 후 임금(원)' },
		{ key: 'usedAnnualLeaves', label: '사용연차(일)' },
		{ key: 'createdAt', label: '회사등록일' },
		{ key: 'details', label: '상세보기' },
	];

	const sortFunctionsWorker = {
		yearMonth: (a, b) => safeStringCompare(a.yearMonth, b.yearMonth),
		workerComNumber: (a, b) =>
			safeStringCompare(a.workerComNumber, b.workerComNumber),
		worker: (a, b) => safeStringCompare(a.workerName, b.workerName),
		totalMonthWageAmount: (a, b) =>
			safeStringCompare(a.totalMonthWageAmount, b.totalMonthWageAmount),
		deductWage: (a, b) => safeStringCompare(a.deductWage, b.deductWage),
		usedAnnualLeaves: (a, b) =>
			safeStringCompare(a.usedAnnualLeaves, b.usedAnnualLeaves),
		createdAt: (a, b) => safeStringCompare(a.createdAt, b.createdAt),
	};

	const formattedDataWorker = regularWorkers.map((result) => ({
		yearMonth: result?.info?.yearMonth,
		workerComNumber: result?.workerInfo?.workerComNumber,
		worker: result?.workerInfo?.worker,
		totalMonthWageAmount: numberToCommaString(
			result?.info?.totalMonthWageAmount
		),
		deductWage: numberToCommaString(result?.info?.deductWage),
		usedAnnualLeaves: result?.usedAnnualLeaves,
		createdAt: result.createdAt
			? format(new Date(result.createdAt), 'yyyy-MM-dd')
			: '',
		id: result.id,
	}));

	const customRenderers = {
		// 예: 'action'이라는 열 키에 대한 사용자 정의 렌더러 제공
		action: (row) => (
			<Button
				as={Link}
				to={`/paydocuDetailedPage/${row.id}`}
				color='orange'
				floated='right'>
				수정
			</Button>
		),
	};

	const footerDataWorker = {
		yearMonth: '합계',
		workerComNumber: '',
		worker: '',
		totalMonthWageAmount:
			numberToCommaString(sumOfMonthWagesWorker(paydocus)) + '원',
		deductWage:
			numberToCommaString(sumOfMonthDeductedWagesWorker(paydocus)) + '원',
		usedAnnualLeaves: sumOfUsedALsWorker(paydocus) + '일',
		createdAt: '',
		id: 'footer',
	};

	const conbinedDataWorker = [...formattedDataWorker, footerDataWorker];

	const headersCompany = [
		{ key: 'companyName', label: '회사명' },
		isSuperAdmin && { key: 'yearMonth', label: '년월' },
		isSuperAdmin && { key: 'workerSocialNumber', label: '주민등록번호' },
		{ key: 'year', label: '년' },
		{ key: 'month', label: '월' },
		{ key: 'workerComNumber', label: '사번' },
		{ key: 'workerDivision', label: '부서명' },
		{ key: 'workerTitle', label: '직위/직책' },
		{ key: 'worker', label: '직원명' },
		{ key: 'workerEnteredDate', label: '입사일' },
		{ key: 'workerRetiredDate', label: '마지막 근무일' },
		{ key: 'basicWage', label: '기본급' },
		mealWageValue && { key: 'mealWage', label: '식대' },
		carWageValue && { key: 'carWage', label: '차량유지비' },
		childWageValue && { key: 'childWage', label: '육아수당' },
		studyWageValue && { key: 'studyWage', label: '연구수당' },
		tFEtcWageValue && { key: 'tFEtcWage', label: '기타비과세' },
		jobWageValue && { key: 'jobWage', label: '직무수당' },
		titleWageValue && {
			key: 'titleWage',
			label: childCompanyCOWTitleName(companyName),
		},
		licenseWageValue && { key: 'licenseWage', label: '자격/면허 수당' },
		longTermWageValue && { key: 'longTermWage', label: '장기근속수당' },
		monthlyBonusWageValue && { key: 'monthlyBonusWage', label: '월상여금' },
		oWEtcWageValue && { key: 'oWEtcWage', label: '기타과세 통상임금' },
		cOWChildManageStudyWageValue && {
			key: 'cOWChildManageStudyWage',
			label: '관리 및 연구수당',
		},
		cOWChildStudyWageValue && { key: 'cOWChildStudyWage', label: '연구수당' },
		cOWChildCareWageValue && { key: 'cOWChildCareWage', label: '취약보육수당' },
		cOWChildActivityWageValue && {
			key: 'cOWChildActivityWage',
			label: '활동보조수당',
		},

		annualLeavesIncludeMonthWageValue && {
			key: 'annualLeavesIncludeMonthWage',
			label: '유급연차수당',
		},
		workDayOTimePaidWageValue && {
			key: 'workDayOTimePaidWage',
			label: '연장수당',
		},
		workDayNTimePaidWageValue && {
			key: 'workDayNTimePaidWage',
			label: '야간수당',
		},
		holiBTimePaidWageValue && {
			key: 'holiBTimePaidWage',
			label: '휴일기본수당',
		},
		holiOTimePaidWageValue && {
			key: 'holiOTimePaidWage',
			label: '휴일연장수당',
		},
		holiNTimePaidWageValue && {
			key: 'holiNTimePaidWage',
			label: '휴일야간수당',
		},
		aWENameTF1Value && { key: 'aWENameTF1', label: `${aWENameTF1Value}` },
		aWENameTF2Value && { key: 'aWENameTF2', label: `${aWENameTF2Value}` },
		aWENameTF3Value && { key: 'aWENameTF3', label: `${aWENameTF3Value}` },
		aWENameTF4Value && { key: 'aWENameTF4', label: `${aWENameTF4Value}` },
		aWEName1Value && { key: 'aWEName1', label: `${aWEName1Value}` },
		aWEName2Value && { key: 'aWEName2', label: `${aWEName2Value}` },
		aWEName3Value && { key: 'aWEName3', label: `${aWEName3Value}` },
		aWEName4Value && { key: 'aWEName4', label: `${aWEName4Value}` },
		plusRegularBonusValue && { key: 'plusRegularBonus', label: '상여금' },
		plusIrregularBonusValue && {
			key: 'plusIrregularBonus',
			label: '불규칙상여금',
		},
		plusUnusedAnnualLeavesWageValue && {
			key: 'plusUnusedAnnualLeavesWage',
			label: '미사용연차수당',
		},
		plusLastMonthAverWageValue && {
			key: 'plusLastMonthAverWage',
			label: '과오납급여',
		},
		plusPersonalIncentiveValue && {
			key: 'plusPersonalIncentive',
			label: '개인성과금',
		},
		aWEName5Value && { key: 'aWEName5', label: `${aWEName5Value}` },
		aWEName6Value && { key: 'aWEName6', label: `${aWEName6Value}` },
		aWEName7Value && { key: 'aWEName7', label: `${aWEName7Value}` },
		plusIrregularDayMealTFWageValue && {
			key: 'plusIrregularDayMealTFWage',
			label: '출근식대',
		},
		plusSpecialRandomWageValue && {
			key: 'plusSpecialRandomWage',
			label: '기타금품(특별상여금)',
		},
		plusRetiredUnusedAnnualLeavesWageValue && {
			key: 'plusRetiredUnusedAnnualLeavesWage',
			label: '퇴직미사용연차수당',
		},
		daysOfInterInAbusentOrdinaryWageValue && {
			key: 'daysOfInterInAbusentOrdinaryWage',
			label: '중도입사공제',
		},
		daysOfInterInAbusentLawOverWageValue && {
			key: 'daysOfInterInAbusentLawOverWage',
			label: '중도입사연장공제',
		},
		daysOfInterOutAbusentOrdinaryWageValue && {
			key: 'daysOfInterOutAbusentOrdinaryWage',
			label: '중도퇴사공제',
		},
		daysOfInterOutAbusentLawOverWageValue && {
			key: 'daysOfInterOutAbusentLawOverWage',
			label: '중도퇴사연장공제',
		},
		absentDaysOrdinaryWageValue && {
			key: 'absentDaysOrdinaryWage',
			label: '결근공제(일반)',
		},
		absentPaidHoliDaysOrdinaryWageValue && {
			key: 'absentPaidHoliDaysOrdinaryWage',
			label: '결근공제(유급휴일)',
		},
		absentDaysLawOverWageValue && {
			key: 'absentDaysLawOverWage',
			label: '결근공제(연장)',
		},
		absentTimeWageValue && { key: 'absentTimeWage', label: '결근공제(시간)' },
		maternityFinalDedectLawOverWageValue && {
			key: 'maternityFinalDedectLawOverWage',
			label: '출산휴가연장공제',
		},
		maternityFinalDedectOrdWageValue && {
			key: 'maternityFinalDedectOrdWage',
			label: '출산휴가공제',
		},
		{ key: 'totalMonthWageAmount', label: '임금합계' },
		{ key: 'calTax', label: '소득세' },
		{ key: 'calRegionTax', label: '지방소득세' },
		{ key: 'calPension', label: '국민연금' },
		{ key: 'calHealthCare', label: '건강보험' },
		{ key: 'calOldCare', label: '장기요양' },
		{ key: 'calNoJobInsurance', label: '고용보험' },
		plusDeductHealthCareValue && {
			key: 'plusDeductHealthCare',
			label: '건보정산금',
		},
		plusDeductOldCareValue && {
			key: 'plusDeductOldCare',
			label: '장기요양정산금',
		},
		plusDeductWorkTaxValue && {
			key: 'plusDeductWorkTax',
			label: '근로소득세정산금',
		},
		plusDeductWorkRegionTaxValue && {
			key: 'plusDeductWorkRegionTax',
			label: '지방소득세정산금',
		},
		finalRetireHealthCareRecalFeeValue && {
			key: 'finalRetireHealthCareRecalFee',
			label: '건강보험퇴직정산금',
		},
		finalRetireOldCareRecalFeeValue && {
			key: 'finalRetireOldCareRecalFee',
			label: '장기요양퇴직정산금',
		},
		plusDeductWorkerCompanyLoanValue && {
			key: 'plusDeductWorkerCompanyLoan',
			label: '대여금상환금',
		},
		plusDeductDuruValue && { key: 'plusDeductDuru', label: '두루누리정산금' },
		plusMinus4InsValue && { key: 'plusMinus4Ins', label: '공제 조정' },
		deductEtcName1Value && {
			key: 'deductEtcName1',
			label: `${deductEtcName1Value}`,
		},
		deductEtcName2Value && {
			key: 'deductEtcName2',
			label: `${deductEtcName2Value}`,
		},
		deductEtcName3Value && {
			key: 'deductEtcName3',
			label: `${deductEtcName3Value}`,
		},
		{ key: 'dedectTaxEtc', label: '공제계' },
		{ key: 'deductWage', label: '실수령액' },

		// { key: 'dcRetireFund', label: 'DC형 퇴직연금' },
	].filter(Boolean);

	const sortFunctionsCompany = {
		companyName: (a, b) => safeStringCompare(a.companyName, b.companyName),
		yearMonth: (a, b) => safeStringCompare(a.yearMonth, b.yearMonth),
		workerSocialNumber: (a, b) =>
			safeStringCompare(a.workerSocialNumber, b.workerSocialNumber),
		year: (a, b) => safeStringCompare(a.year, b.year),
		month: (a, b) => safeStringCompare(a.month, b.month),
		workerComNumber: (a, b) =>
			safeStringCompare(a.workerComNumber, b.workerComNumber),
		workerDivision: (a, b) =>
			safeStringCompare(a.workerDivision, b.workerDivision),
		workerTitle: (a, b) => safeStringCompare(a.workerTitle, b.workerTitle),
		worker: (a, b) => safeStringCompare(a.worker, b.worker),
		workerEnteredDate: (a, b) =>
			safeStringCompare(a.workerEnteredDate, b.workerEnteredDate),
		workerRetiredDate: (a, b) =>
			safeStringCompare(a.workerRetiredDate, b.workerRetiredDate),

		basicWage: (a, b) => safeStringCompare(a.basicWage, b.basicWage),
		mealWage: mealWageValue
			? (a, b) => safeStringCompare(a.mealWage, b.mealWage)
			: undefined,
		carWage: carWageValue
			? (a, b) => safeStringCompare(a.carWage, b.carWage)
			: undefined,
		childWage: childWageValue
			? (a, b) => safeStringCompare(a.childWage, b.childWage)
			: undefined,
		studyWage: studyWageValue
			? (a, b) => safeStringCompare(a.studyWage, b.studyWage)
			: undefined,
		tFEtcWage: tFEtcWageValue
			? (a, b) => safeStringCompare(a.tFEtcWage, b.tFEtcWage)
			: undefined,
		jobWage: jobWageValue
			? (a, b) => safeStringCompare(a.jobWage, b.jobWage)
			: undefined,
		titleWage: titleWageValue
			? (a, b) => safeStringCompare(a.titleWage, b.titleWage)
			: undefined,
		licenseWage: licenseWageValue
			? (a, b) => safeStringCompare(a.licenseWage, b.licenseWage)
			: undefined,
		longTermWage: longTermWageValue
			? (a, b) => safeStringCompare(a.longTermWage, b.longTermWage)
			: undefined,
		monthlyBonusWage: monthlyBonusWageValue
			? (a, b) => safeStringCompare(a.monthlyBonusWage, b.monthlyBonusWage)
			: undefined,
		oWEtcWage: oWEtcWageValue
			? (a, b) => safeStringCompare(a.oWEtcWage, b.oWEtcWage)
			: undefined,
		cOWChildManageStudyWage: cOWChildManageStudyWageValue
			? (a, b) =>
					safeStringCompare(
						a.cOWChildManageStudyWage,
						b.cOWChildManageStudyWage
					)
			: undefined,
		cOWChildStudyWage: cOWChildStudyWageValue
			? (a, b) => safeStringCompare(a.cOWChildStudyWage, b.cOWChildStudyWage)
			: undefined,
		cOWChildCareWage: cOWChildCareWageValue
			? (a, b) => safeStringCompare(a.cOWChildCareWage, b.cOWChildCareWage)
			: undefined,
		cOWChildActivityWage: cOWChildActivityWageValue
			? (a, b) =>
					safeStringCompare(a.cOWChildActivityWage, b.cOWChildActivityWage)
			: undefined,

		annualLeavesIncludeMonthWage: annualLeavesIncludeMonthWageValue
			? (a, b) =>
					safeStringCompare(
						a.annualLeavesIncludeMonthWage,
						b.annualLeavesIncludeMonthWage
					)
			: undefined,
		workDayOTimePaidWage: workDayOTimePaidWageValue
			? (a, b) =>
					safeStringCompare(a.workDayOTimePaidWage, b.workDayOTimePaidWage)
			: undefined,
		workDayNTimePaidWage: workDayNTimePaidWageValue
			? (a, b) =>
					safeStringCompare(a.workDayNTimePaidWage, b.workDayNTimePaidWage)
			: undefined,
		holiBTimePaidWage: holiBTimePaidWageValue
			? (a, b) => safeStringCompare(a.holiBTimePaidWage, b.holiBTimePaidWage)
			: undefined,
		holiOTimePaidWage: holiOTimePaidWageValue
			? (a, b) => safeStringCompare(a.holiOTimePaidWage, b.holiOTimePaidWage)
			: undefined,
		holiNTimePaidWage: holiNTimePaidWageValue
			? (a, b) => safeStringCompare(a.holiNTimePaidWage, b.holiNTimePaidWage)
			: undefined,
		aWENameTF1: aWENameTF1Value
			? (a, b) => safeStringCompare(a.aWENameTF1, b.aWENameTF1)
			: undefined,
		aWENameTF2: aWENameTF2Value
			? (a, b) => safeStringCompare(a.aWENameTF2, b.aWENameTF2)
			: undefined,
		aWENameTF3: aWENameTF3Value
			? (a, b) => safeStringCompare(a.aWENameTF3, b.aWENameTF3)
			: undefined,
		aWENameTF4: aWENameTF4Value
			? (a, b) => safeStringCompare(a.aWENameTF4, b.aWENameTF4)
			: undefined,
		aWEName1: aWEName1Value
			? (a, b) => safeStringCompare(a.aWEName1, b.aWEName1)
			: undefined,
		aWEName2: aWEName2Value
			? (a, b) => safeStringCompare(a.aWEName2, b.aWEName2)
			: undefined,
		aWEName3: aWEName3Value
			? (a, b) => safeStringCompare(a.aWEName3, b.aWEName3)
			: undefined,
		aWEName4: aWEName4Value
			? (a, b) => safeStringCompare(a.aWEName4, b.aWEName4)
			: undefined,
		plusRegularBonus: plusRegularBonusValue
			? (a, b) => safeStringCompare(a.plusRegularBonus, b.plusRegularBonus)
			: undefined,
		plusIrregularBonus: plusIrregularBonusValue
			? (a, b) => safeStringCompare(a.plusIrregularBonus, b.plusIrregularBonus)
			: undefined,
		plusUnusedAnnualLeavesWage: plusUnusedAnnualLeavesWageValue
			? (a, b) =>
					safeStringCompare(
						a.plusUnusedAnnualLeavesWage,
						b.plusUnusedAnnualLeavesWage
					)
			: undefined,
		plusLastMonthAverWage: plusLastMonthAverWageValue
			? (a, b) =>
					safeStringCompare(a.plusLastMonthAverWage, b.plusLastMonthAverWage)
			: undefined,
		plusPersonalIncentive: plusPersonalIncentiveValue
			? (a, b) =>
					safeStringCompare(a.plusPersonalIncentive, b.plusPersonalIncentive)
			: undefined,
		aWEName5: aWEName5Value
			? (a, b) => safeStringCompare(a.aWEName5, b.aWEName5)
			: undefined,
		aWEName6: aWEName6Value
			? (a, b) => safeStringCompare(a.aWEName6, b.aWEName6)
			: undefined,
		aWEName7: aWEName7Value
			? (a, b) => safeStringCompare(a.aWEName7, b.aWEName7)
			: undefined,
		plusIrregularDayMealTFWage: plusIrregularDayMealTFWageValue
			? (a, b) =>
					safeStringCompare(
						a.plusIrregularDayMealTFWage,
						b.plusIrregularDayMealTFWage
					)
			: undefined,
		plusSpecialRandomWage: plusSpecialRandomWageValue
			? (a, b) =>
					safeStringCompare(a.plusSpecialRandomWage, b.plusSpecialRandomWage)
			: undefined,
		plusRetiredUnusedAnnualLeavesWage: plusRetiredUnusedAnnualLeavesWageValue
			? (a, b) =>
					safeStringCompare(
						a.plusRetiredUnusedAnnualLeavesWage,
						b.plusRetiredUnusedAnnualLeavesWage
					)
			: undefined,
		daysOfInterInAbusentOrdinaryWage: daysOfInterInAbusentOrdinaryWageValue
			? (a, b) =>
					safeStringCompare(
						a.daysOfInterInAbusentOrdinaryWage,
						b.daysOfInterInAbusentOrdinaryWage
					)
			: undefined,
		daysOfInterInAbusentLawOverWage: daysOfInterInAbusentLawOverWageValue
			? (a, b) =>
					safeStringCompare(
						a.daysOfInterInAbusentLawOverWage,
						b.daysOfInterInAbusentLawOverWage
					)
			: undefined,
		daysOfInterOutAbusentOrdinaryWage: daysOfInterOutAbusentOrdinaryWageValue
			? (a, b) =>
					safeStringCompare(
						a.daysOfInterOutAbusentOrdinaryWage,
						b.daysOfInterOutAbusentOrdinaryWage
					)
			: undefined,
		daysOfInterOutAbusentLawOverWage: daysOfInterOutAbusentLawOverWageValue
			? (a, b) =>
					safeStringCompare(
						a.daysOfInterOutAbusentLawOverWage,
						b.daysOfInterOutAbusentLawOverWage
					)
			: undefined,
		absentDaysOrdinaryWage: absentDaysOrdinaryWageValue
			? (a, b) =>
					safeStringCompare(a.absentDaysOrdinaryWage, b.absentDaysOrdinaryWage)
			: undefined,
		absentPaidHoliDaysOrdinaryWage: absentPaidHoliDaysOrdinaryWageValue
			? (a, b) =>
					safeStringCompare(
						a.absentPaidHoliDaysOrdinaryWage,
						b.absentPaidHoliDaysOrdinaryWage
					)
			: undefined,
		absentDaysLawOverWage: absentDaysLawOverWageValue
			? (a, b) =>
					safeStringCompare(a.absentDaysLawOverWage, b.absentDaysLawOverWage)
			: undefined,
		absentTimeWage: absentTimeWageValue
			? (a, b) => safeStringCompare(a.absentTimeWage, b.absentTimeWage)
			: undefined,
		maternityFinalDedectLawOverWage: maternityFinalDedectLawOverWageValue
			? (a, b) =>
					safeStringCompare(
						a.maternityFinalDedectLawOverWage,
						b.maternityFinalDedectLawOverWage
					)
			: undefined,
		maternityFinalDedectOrdWage: maternityFinalDedectOrdWageValue
			? (a, b) =>
					safeStringCompare(
						a.maternityFinalDedectOrdWage,
						b.maternityFinalDedectOrdWage
					)
			: undefined,
		totalMonthWageAmount: (a, b) =>
			safeStringCompare(a.totalMonthWageAmount, b.totalMonthWageAmount),
		calTax: (a, b) => safeStringCompare(a.calTax, b.calTax),
		calRegionTax: (a, b) => safeStringCompare(a.calRegionTax, b.calRegionTax),
		calPension: (a, b) => safeStringCompare(a.calPension, b.calPension),
		calHealthCare: (a, b) =>
			safeStringCompare(a.calHealthCare, b.calHealthCare),
		calOldCare: (a, b) => safeStringCompare(a.calOldCare, b.calOldCare),
		calNoJobInsurance: (a, b) =>
			safeStringCompare(a.calNoJobInsurance, b.calNoJobInsurance),
		plusDeductHealthCare: plusDeductHealthCareValue
			? (a, b) =>
					safeStringCompare(a.plusDeductHealthCare, b.plusDeductHealthCare)
			: undefined,
		plusDeductOldCare: plusDeductOldCareValue
			? (a, b) => safeStringCompare(a.plusDeductOldCare, b.plusDeductOldCare)
			: undefined,
		plusDeductWorkTax: plusDeductWorkTaxValue
			? (a, b) => safeStringCompare(a.plusDeductWorkTax, b.plusDeductWorkTax)
			: undefined,
		plusDeductWorkRegionTax: plusDeductWorkRegionTaxValue
			? (a, b) =>
					safeStringCompare(
						a.plusDeductWorkRegionTax,
						b.plusDeductWorkRegionTax
					)
			: undefined,
		finalRetireHealthCareRecalFee: finalRetireHealthCareRecalFeeValue
			? (a, b) =>
					safeStringCompare(
						a.finalRetireHealthCareRecalFee,
						b.finalRetireHealthCareRecalFee
					)
			: undefined,
		finalRetireOldCareRecalFee: finalRetireOldCareRecalFeeValue
			? (a, b) =>
					safeStringCompare(
						a.finalRetireOldCareRecalFee,
						b.finalRetireOldCareRecalFee
					)
			: undefined,
		plusDeductWorkerCompanyLoan: plusDeductWorkerCompanyLoanValue
			? (a, b) =>
					safeStringCompare(
						a.plusDeductWorkerCompanyLoan,
						b.plusDeductWorkerCompanyLoan
					)
			: undefined,
		plusDeductDuru: plusDeductDuruValue
			? (a, b) => safeStringCompare(a.plusDeductDuru, b.plusDeductDuru)
			: undefined,
		plusMinus4Ins: plusMinus4InsValue
			? (a, b) => safeStringCompare(a.plusMinus4Ins, b.plusMinus4Ins)
			: undefined,
		deductEtcName1: deductEtcName1Value
			? (a, b) => safeStringCompare(a.deductEtcName1, b.deductEtcName1)
			: undefined,
		deductEtcName2: deductEtcName2Value
			? (a, b) => safeStringCompare(a.deductEtcName2, b.deductEtcName2)
			: undefined,
		deductEtcName3: deductEtcName3Value
			? (a, b) => safeStringCompare(a.deductEtcName3, b.deductEtcName3)
			: undefined,
		dedectTaxEtc: (a, b) => safeStringCompare(a.dedectTaxEtc, b.dedectTaxEtc),
		deductWage: (a, b) => safeStringCompare(a.deductWage, b.deductWage),

		// dcRetirefund: (a, b) => safeStringCompare(a.dcRetirefund, b.dcRetirefund),
	};

	const formattedDataCompany = regularWorkers
		.filter(
			(result) =>
				result.companyInfo.companyId === id ||
				result?.companyInfo?.agentUid === id ||
				currentUserProfile?.authLevel >= 100
		)
		.filter((result) => result?.workerInfo?.dayWorker !== true)
		.map((result) => ({
			companyName: result?.companyInfo?.companyName,
			yearMonth: result?.info?.yearMonth,
			workerSocialNumber: `${decrypto(
				result?.workerInfo?.workerSocialNumberFront,
				String(process.env.CRYPTO_KEY)
			)}-${decrypto(
				result?.workerInfo?.workerSocialNumberBack,
				String(process.env.CRYPTO_KEY)
			)}`,
			year: result?.info?.year,
			month: result?.info?.month,
			workerComNumber: result?.workerInfo?.workerComNumber,
			workerDivision: result?.workerInfo?.workerDivisions,
			workerTitle: result?.workerInfo?.workerTitles,
			worker: result?.workerInfo?.worker,
			workerEnteredDate: result?.workerInfo?.workerEnteredDate,
			workerRetiredDate: result?.workerInfo?.workerRetiredDate,
			basicWage: numberToCommaString(result?.fixedWage?.basicWage),
			mealWage: mealWageValue
				? numberToCommaString(result?.fixedWage?.mealWage)
				: '',
			carWage: carWageValue
				? numberToCommaString(result?.fixedWage?.carWage)
				: '',
			childWage: childWageValue
				? numberToCommaString(result?.fixedWage?.childWage)
				: '',
			studyWage: studyWageValue
				? numberToCommaString(result?.fixedWage?.studyWage)
				: '',
			tFEtcWage: tFEtcWageValue
				? numberToCommaString(result?.fixedWage?.tFEtcWage)
				: '',
			jobWage: jobWageValue
				? numberToCommaString(result?.fixedWage?.jobWage)
				: '',
			titleWage: titleWageValue
				? numberToCommaString(result?.fixedWage?.titleWage)
				: '',
			licenseWage: licenseWageValue
				? numberToCommaString(result?.fixedWage?.licenseWage)
				: '',
			longTermWage: longTermWageValue
				? numberToCommaString(result?.fixedWage?.longTermWage)
				: '',
			monthlyBonusWage: monthlyBonusWageValue
				? numberToCommaString(result?.fixedWage?.monthlyBonusWage)
				: '',
			oWEtcWage: oWEtcWageValue
				? numberToCommaString(result?.fixedWage?.oWEtcWage)
				: '',
			cOWChildManageStudyWage: cOWChildManageStudyWageValue
				? numberToCommaString(result?.fixedWage?.cOWChildManageStudyWage)
				: '',
			cOWChildStudyWage: cOWChildStudyWageValue
				? numberToCommaString(result?.fixedWage?.cOWChildStudyWage)
				: '',
			cOWChildCareWage: cOWChildCareWageValue
				? numberToCommaString(result?.fixedWage?.cOWChildCareWage)
				: '',
			cOWChildActivityWage: cOWChildActivityWageValue
				? numberToCommaString(result?.fixedWage?.cOWChildActivityWage)
				: '',

			annualLeavesIncludeMonthWage: annualLeavesIncludeMonthWageValue
				? numberToCommaString(result?.annualLeavesIncludeMonthWage)
				: '',
			workDayOTimePaidWage: workDayOTimePaidWageValue
				? numberToCommaString(result?.lawOverTimeWage?.workDayOTimePaidWage)
				: '',
			workDayNTimePaidWage: workDayNTimePaidWageValue
				? numberToCommaString(result?.lawOverTimeWage?.workDayNTimePaidWage)
				: '',
			holiBTimePaidWage: holiBTimePaidWageValue
				? numberToCommaString(result?.lawOverTimeWage?.holiBTimePaidWage)
				: '',
			holiOTimePaidWage: holiOTimePaidWageValue
				? numberToCommaString(result?.lawOverTimeWage?.holiOTimePaidWage)
				: '',
			holiNTimePaidWage: holiNTimePaidWageValue
				? numberToCommaString(result?.lawOverTimeWage?.holiNTimePaidWage)
				: '',
			aWENameTF1: aWENameTF1Value
				? numberToCommaString(
						result?.fixedWage?.userFixedCAWageTF1
							? result?.fixedWage?.userFixedCAWageTF1
							: result?.fixedWage?.aWageTF1Wage
				  )
				: '',
			aWENameTF2: aWENameTF2Value
				? numberToCommaString(
						result?.fixedWage?.userFixedCAWageTF2
							? result?.fixedWage?.userFixedCAWageTF2
							: result?.fixedWage?.aWageTF2Wage
				  )
				: '',
			aWENameTF3: aWENameTF3Value
				? numberToCommaString(
						result?.fixedWage?.userFixedCAWageTF3
							? result?.fixedWage?.userFixedCAWageTF3
							: result?.fixedWage?.aWageTF3Wage
				  )
				: '',
			aWENameTF4: aWENameTF4Value
				? numberToCommaString(
						result?.fixedWage?.userFixedCAWageTF4
							? result?.fixedWage?.userFixedCAWageTF4
							: result?.fixedWage?.aWageTF4Wage
				  )
				: '',
			aWEName1: aWEName1Value
				? numberToCommaString(
						result?.fixedWage?.userFixedCAWage1
							? result?.fixedWage?.userFixedCAWage1
							: result?.fixedWage?.aWage1Wage
				  )
				: '',
			aWEName2: aWEName2Value
				? numberToCommaString(
						result?.fixedWage?.userFixedCAWage2
							? result?.fixedWage?.userFixedCAWage2
							: result?.fixedWage?.aWage2Wage
				  )
				: '',
			aWEName3: aWEName3Value
				? numberToCommaString(
						result?.fixedWage?.userFixedCAWage3
							? result?.fixedWage?.userFixedCAWage3
							: result?.fixedWage?.aWage3Wage
				  )
				: '',
			aWEName4: aWEName4Value
				? numberToCommaString(
						result?.fixedWage?.userFixedCAWage4
							? result?.fixedWage?.userFixedCAWage4
							: result?.fixedWage?.aWage4Wage
				  )
				: '',
			plusRegularBonus: plusRegularBonusValue
				? numberToCommaString(result?.addAverWage?.plusRegularBonus)
				: '',
			plusIrregularBonus: plusIrregularBonusValue
				? numberToCommaString(result?.addAverWage?.plusIrregularBonus)
				: '',
			plusUnusedAnnualLeavesWage: plusUnusedAnnualLeavesWageValue
				? numberToCommaString(result?.addAverWage?.plusUnusedAnnualLeavesWage)
				: '',
			plusLastMonthAverWage: plusLastMonthAverWageValue
				? numberToCommaString(result?.addAverWage?.plusLastMonthAverWage)
				: '',
			plusPersonalIncentive: plusPersonalIncentiveValue
				? numberToCommaString(result?.addAverWage?.plusPersonalIncentive)
				: '',
			aWEName5: aWEName5Value
				? numberToCommaString(result?.addAverWage?.aWage5Wage)
				: '',
			aWEName6: aWEName6Value
				? numberToCommaString(result?.addAverWage?.aWage6Wage)
				: '',
			aWEName7: aWEName7Value
				? numberToCommaString(result?.addAverWage?.aWage7Wage)
				: '',
			plusIrregularDayMealTFWage: plusIrregularDayMealTFWageValue
				? numberToCommaString(result?.addAverWage?.plusIrregularDayMealTFWage)
				: '',
			plusSpecialRandomWage: plusSpecialRandomWageValue
				? numberToCommaString(result?.plusSpecialRandomWage)
				: '',
			plusRetiredUnusedAnnualLeavesWage: plusRetiredUnusedAnnualLeavesWageValue
				? numberToCommaString(result?.plusRetiredUnusedAnnualLeavesWage)
				: '',
			daysOfInterInAbusentOrdinaryWage: daysOfInterInAbusentOrdinaryWageValue
				? numberToCommaString(
						result?.absentMinusWage?.daysOfInterInAbusentOrdinaryWage ===
							(0 || undefined)
							? 0
							: result?.absentMinusWage?.daysOfInterInAbusentOrdinaryWage * -1
				  )
				: '',
			daysOfInterInAbusentLawOverWage: daysOfInterInAbusentLawOverWageValue
				? numberToCommaString(
						result?.absentMinusWage?.daysOfInterInAbusentLawOverWage ===
							(0 || undefined)
							? 0
							: result?.absentMinusWage?.daysOfInterInAbusentLawOverWage * -1
				  )
				: '',
			daysOfInterOutAbusentOrdinaryWage: daysOfInterOutAbusentOrdinaryWageValue
				? numberToCommaString(
						result?.absentMinusWage?.daysOfInterOutAbusentOrdinaryWage ===
							(0 || undefined)
							? 0
							: result?.absentMinusWage?.daysOfInterOutAbusentOrdinaryWage * -1
				  )
				: '',
			daysOfInterOutAbusentLawOverWage: daysOfInterOutAbusentLawOverWageValue
				? numberToCommaString(
						result?.absentMinusWage?.daysOfInterOutAbusentLawOverWage ===
							(0 || undefined)
							? 0
							: result?.absentMinusWage?.daysOfInterOutAbusentLawOverWage * -1
				  )
				: '',
			absentDaysOrdinaryWage: absentDaysOrdinaryWageValue
				? numberToCommaString(
						result?.absentMinusWage?.absentDaysOrdinaryWage === (0 || undefined)
							? 0
							: result?.absentMinusWage?.absentDaysOrdinaryWage * -1
				  )
				: '',
			absentPaidHoliDaysOrdinaryWage: absentPaidHoliDaysOrdinaryWageValue
				? numberToCommaString(
						result?.absentMinusWage?.absentPaidHoliDaysOrdinaryWage ===
							(0 || undefined)
							? 0
							: result?.absentMinusWage?.absentPaidHoliDaysOrdinaryWage * -1
				  )
				: '',
			absentDaysLawOverWage: absentDaysLawOverWageValue
				? numberToCommaString(
						result?.absentMinusWage?.absentDaysLawOverWage === (0 || undefined)
							? 0
							: result?.absentMinusWage?.absentDaysLawOverWage * -1
				  )
				: '',
			absentTimeWage: absentTimeWageValue
				? numberToCommaString(
						result?.absentMinusWage?.absentTimeWage === (0 || undefined)
							? 0
							: result?.absentMinusWage?.absentTimeWage * -1
				  )
				: '',
			maternityFinalDedectLawOverWage: maternityFinalDedectLawOverWageValue
				? numberToCommaString(
						result?.absentMinusWage?.maternityFinalDedectLawOverWage ===
							(0 || undefined)
							? 0
							: result?.absentMinusWage?.maternityFinalDedectLawOverWage * -1
				  )
				: '',
			maternityFinalDedectOrdWage: maternityFinalDedectOrdWageValue
				? numberToCommaString(
						result?.absentMinusWage?.maternityFinalDedectOrdWage ===
							(0 || undefined)
							? 0
							: result?.absentMinusWage?.maternityFinalDedectOrdWage * -1
				  )
				: '',
			totalMonthWageAmount: numberToCommaString(
				result?.info?.totalMonthWageAmount
			),
			calTax: numberToCommaString(
				result?.deductTaxAnd4In?.userFixedTax !== '' &&
					!isNaN(result?.deductTaxAnd4In?.userFixedTax)
					? result?.deductTaxAnd4In?.userFixedTax
					: result?.deductTaxAnd4In?.calTax
			),
			calRegionTax: numberToCommaString(
				result?.deductTaxAnd4In?.userFixedRegionTax !== '' &&
					!isNaN(result?.deductTaxAnd4In?.userFixedRegionTax)
					? result?.deductTaxAnd4In?.userFixedRegionTax
					: result?.deductTaxAnd4In?.calRegionTax
			),
			calPension: numberToCommaString(
				result?.deductTaxAnd4In?.userFixedPension !== '' &&
					!isNaN(result?.deductTaxAnd4In?.userFixedPension)
					? result?.deductTaxAnd4In?.userFixedPension
					: result?.deductTaxAnd4In?.calPension
			),
			calHealthCare: numberToCommaString(
				result?.deductTaxAnd4In?.userFixedHealthCare !== '' &&
					!isNaN(result?.deductTaxAnd4In?.userFixedHealthCare)
					? result?.deductTaxAnd4In?.userFixedHealthCare
					: result?.deductTaxAnd4In?.calHealthCare
			),
			calOldCare: numberToCommaString(
				result?.deductTaxAnd4In?.userFixedOldCare !== '' &&
					!isNaN(result?.deductTaxAnd4In?.userFixedOldCare)
					? result?.deductTaxAnd4In?.userFixedOldCare
					: result?.deductTaxAnd4In?.calOldCare
			),
			calNoJobInsurance: numberToCommaString(
				result?.deductTaxAnd4In?.userFixedNoJobInsurance !== '' &&
					!isNaN(result?.deductTaxAnd4In?.userFixedNoJobInsurance)
					? result?.deductTaxAnd4In?.userFixedNoJobInsurance
					: result?.deductTaxAnd4In?.calNoJobInsurance
			),
			plusDeductHealthCare: plusDeductHealthCareValue
				? numberToCommaString(result?.deductTaxAnd4In?.plusDeductHealthCare)
				: '',
			plusDeductOldCare: plusDeductOldCareValue
				? numberToCommaString(result?.deductTaxAnd4In?.plusDeductOldCare)
				: '',
			plusDeductWorkTax: plusDeductWorkTaxValue
				? numberToCommaString(result?.deductTaxAnd4In?.plusDeductWorkTax)
				: '',
			plusDeductWorkRegionTax: plusDeductWorkRegionTaxValue
				? numberToCommaString(result?.deductTaxAnd4In?.plusDeductWorkRegionTax)
				: '',
			finalRetireHealthCareRecalFee: finalRetireHealthCareRecalFeeValue
				? numberToCommaString(
						result?.deductTaxAnd4In?.finalRetireHealthCareRecalFee
				  )
				: '',
			finalRetireOldCareRecalFee: finalRetireOldCareRecalFeeValue
				? numberToCommaString(
						result?.deductTaxAnd4In?.finalRetireOldCareRecalFee
				  )
				: '',
			plusDeductWorkerCompanyLoan: plusDeductWorkerCompanyLoanValue
				? numberToCommaString(
						result?.deductTaxAnd4In?.plusDeductWorkerCompanyLoan
				  )
				: '',
			plusDeductDuru: plusDeductDuruValue
				? numberToCommaString(result?.deductTaxAnd4In?.plusDeductDuru)
				: '',
			plusMinus4Ins: plusMinus4InsValue
				? numberToCommaString(result?.deductTaxAnd4In?.plusMinus4Ins)
				: '',
			deductEtcName1: deductEtcName1Value
				? numberToCommaString(result?.deductTaxAnd4In?.numDeductEtcAWage1)
				: '',
			deductEtcName2: deductEtcName2Value
				? numberToCommaString(result?.deductTaxAnd4In?.numDeductEtcAWage2)
				: '',
			deductEtcName3: deductEtcName3Value
				? numberToCommaString(result?.deductTaxAnd4In?.numDeductEtcAWage3)
				: '',
			dedectTaxEtc: numberToCommaString(result?.deductTaxAnd4In?.dedectTaxEtc),
			deductWage: numberToCommaString(result?.info?.deductWage),
			// dcRetirefund: numberToCommaString(result?.info?.totalMonthWageAmount / 12),
		}));

	const footerDataCompany = {
		companyName: '',
		yearMonth: '',
		workerSocialNumber: '',
		year: '',
		month: '',
		workerComNumber: '',
		workerDivision: '',
		workerTitle: '',
		worker: '',
		workerEnteredDate: '',
		workerRetiredDate: '합계',
		basicWage: numberToCommaString(sumOfBasicWagesCom(regularWorkers)),
		mealWage: mealWageValue
			? numberToCommaString(sumOfMealWagesCom(regularWorkers))
			: '',
		carWage: carWageValue
			? numberToCommaString(sumOfCarWagesCom(regularWorkers))
			: '',
		childWage: childWageValue
			? numberToCommaString(sumOfChildWagesCom(regularWorkers))
			: '',
		studyWage: studyWageValue
			? numberToCommaString(sumOfStudyWagesCom(regularWorkers))
			: '',
		tFEtcWage: tFEtcWageValue
			? numberToCommaString(sumOfTFEtcWagesCom(regularWorkers))
			: '',
		jobWage: jobWageValue
			? numberToCommaString(sumOfJobWagesCom(regularWorkers))
			: '',
		titleWage: titleWageValue
			? numberToCommaString(sumOfTitleWagesCom(regularWorkers))
			: '',
		licenseWage: licenseWageValue
			? numberToCommaString(sumOfLicenseWagesCom(regularWorkers))
			: '',
		longTermWage: longTermWageValue
			? numberToCommaString(sumOfLongTermWagesCom(regularWorkers))
			: '',
		monthlyBonusWage: monthlyBonusWageValue
			? numberToCommaString(sumOfMonthlyBonusWagesCom(regularWorkers))
			: '',
		oWEtcWage: oWEtcWageValue
			? numberToCommaString(sumOfOWEtcWagesCom(regularWorkers))
			: '',

		cOWChildManageStudyWage: cOWChildManageStudyWageValue
			? numberToCommaString(sumOfCOWChildManageStudyWagesCom(regularWorkers))
			: '',
		cOWChildStudyWage: cOWChildStudyWageValue
			? numberToCommaString(sumOfCOWChildStudyWagesCom(regularWorkers))
			: '',
		cOWChildCareWage: cOWChildCareWageValue
			? numberToCommaString(sumOfCOWChildCareWagesCom(regularWorkers))
			: '',
		cOWChildActivityWage: cOWChildActivityWageValue
			? numberToCommaString(sumOfCOWChildActivityWagesCom(regularWorkers))
			: '',

		annualLeavesIncludeMonthWage: annualLeavesIncludeMonthWageValue
			? numberToCommaString(
					sumOfAnnualLeavesIncludeMonthWageCom(regularWorkers)
			  )
			: '',
		workDayOTimePaidWage: workDayOTimePaidWageValue
			? numberToCommaString(sumOfWorkDayOTimePaidWagesCom(regularWorkers))
			: '',
		workDayNTimePaidWage: workDayNTimePaidWageValue
			? numberToCommaString(sumOfWorkDayNTimePaidWagesCom(regularWorkers))
			: '',
		holiBTimePaidWage: holiBTimePaidWageValue
			? numberToCommaString(sumOfHoliBTimePaidWagesCom(regularWorkers))
			: '',
		holiOTimePaidWage: holiOTimePaidWageValue
			? numberToCommaString(sumOfHoliOTimePaidWagesCom(regularWorkers))
			: '',
		holiNTimePaidWage: holiNTimePaidWageValue
			? numberToCommaString(sumOfHoliNTimePaidWagesCom(regularWorkers))
			: '',
		aWENameTF1: aWENameTF1Value
			? numberToCommaString(sumOfAWageTF1WagesCom(regularWorkers))
			: '',
		aWENameTF2: aWENameTF2Value
			? numberToCommaString(sumOfAWageTF2WagesCom(regularWorkers))
			: '',
		aWENameTF3: aWENameTF3Value
			? numberToCommaString(sumOfAWageTF3WagesCom(regularWorkers))
			: '',
		aWENameTF4: aWENameTF4Value
			? numberToCommaString(sumOfAWageTF4WagesCom(regularWorkers))
			: '',
		aWEName1: aWEName1Value
			? numberToCommaString(sumOfAWage1WagesCom(regularWorkers))
			: '',
		aWEName2: aWEName2Value
			? numberToCommaString(sumOfAWage2WagesCom(regularWorkers))
			: '',
		aWEName3: aWEName3Value
			? numberToCommaString(sumOfAWage3WagesCom(regularWorkers))
			: '',
		aWEName4: aWEName4Value
			? numberToCommaString(sumOfAWage4WagesCom(regularWorkers))
			: '',
		plusRegularBonus: plusRegularBonusValue
			? numberToCommaString(sumOfAWageplusRegularBonusCom(regularWorkers))
			: '',
		plusIrregularBonus: plusIrregularBonusValue
			? numberToCommaString(sumOfAWageplusIrregularBonusCom(regularWorkers))
			: '',
		plusUnusedAnnualLeavesWage: plusUnusedAnnualLeavesWageValue
			? numberToCommaString(
					sumOfAWageplusUnusedAnnualLeavesWageCom(regularWorkers)
			  )
			: '',
		plusLastMonthAverWage: plusLastMonthAverWageValue
			? numberToCommaString(sumOfAWageplusLastMonthAverWageCom(regularWorkers))
			: '',
		plusPersonalIncentive: plusPersonalIncentiveValue
			? numberToCommaString(sumOfAWageplusPersonalIncentiveCom(regularWorkers))
			: '',
		aWEName5: aWEName5Value
			? numberToCommaString(sumOfAWage5WagesCom(regularWorkers))
			: '',
		aWEName6: aWEName6Value
			? numberToCommaString(sumOfAWage6WagesCom(regularWorkers))
			: '',
		aWEName7: aWEName7Value
			? numberToCommaString(sumOfAWage7WagesCom(regularWorkers))
			: '',
		plusIrregularDayMealTFWage: plusIrregularDayMealTFWageValue
			? numberToCommaString(
					sumOfAWageplusIrregularDayMealTFWageCom(regularWorkers)
			  )
			: '',
		plusSpecialRandomWage: plusSpecialRandomWageValue
			? numberToCommaString(sumOfAWageplusSpecialRandomWageCom(regularWorkers))
			: '',
		plusRetiredUnusedAnnualLeavesWage: plusRetiredUnusedAnnualLeavesWageValue
			? numberToCommaString(
					sumOfAWageplusRetiredUnusedAnnualLeavesWageCom(regularWorkers)
			  )
			: '',
		daysOfInterInAbusentOrdinaryWage: daysOfInterInAbusentOrdinaryWageValue
			? numberToCommaString(
					sumOfdaysOfInterInAbusentOrdinaryWagesCom(regularWorkers) * -1
			  )
			: '',
		daysOfInterInAbusentLawOverWage: daysOfInterInAbusentLawOverWageValue
			? numberToCommaString(
					sumOfdaysOfInterInAbusentLawOverWagesCom(regularWorkers) * -1
			  )
			: '',
		daysOfInterOutAbusentOrdinaryWage: daysOfInterOutAbusentOrdinaryWageValue
			? numberToCommaString(
					sumOfdaysOfInterOutAbusentOrdinaryWagesCom(regularWorkers) * -1
			  )
			: '',
		daysOfInterOutAbusentLawOverWage: daysOfInterOutAbusentLawOverWageValue
			? numberToCommaString(
					sumOfdaysOfInterOutAbusentLawOverWagesCom(regularWorkers) * -1
			  )
			: '',
		absentDaysOrdinaryWage: absentDaysOrdinaryWageValue
			? numberToCommaString(
					sumOfAbsentDaysOrdinaryWagesCom(regularWorkers) * -1
			  )
			: '',
		absentPaidHoliDaysOrdinaryWage: absentPaidHoliDaysOrdinaryWageValue
			? numberToCommaString(
					sumOfAbsentPaidHoliDaysOrdinaryWagesCom(regularWorkers) * -1
			  )
			: '',
		absentDaysLawOverWage: absentDaysLawOverWageValue
			? numberToCommaString(sumOfAbsentDaysLawOverWagesCom(regularWorkers) * -1)
			: '',
		absentTimeWage: absentTimeWageValue
			? numberToCommaString(sumOfAbsentTimeWagesCom(regularWorkers) * -1)
			: '',
		maternityFinalDedectLawOverWage: maternityFinalDedectLawOverWageValue
			? numberToCommaString(
					roundUp(sumOfMaternityFinalDedectLawOverWagesCom(regularWorkers)) * -1
			  )
			: '',
		maternityFinalDedectOrdWage: maternityFinalDedectOrdWageValue
			? numberToCommaString(
					roundUp(sumOfMaternityFinalDedectOrdWagesCom(regularWorkers)) * -1
			  )
			: '',
		totalMonthWageAmount: numberToCommaString(
			sumOfMonthWagesCom(regularWorkers)
		),
		calTax: numberToCommaString(sumOfCalTaxsSumCom(regularWorkers)),
		calRegionTax: numberToCommaString(sumOfCalRegionTaxsSumCom(regularWorkers)),
		calPension: numberToCommaString(sumOfCalPensionsSumCom(regularWorkers)),
		calHealthCare: numberToCommaString(
			sumOfCalHealthCaresSumCom(regularWorkers)
		),
		calOldCare: numberToCommaString(sumOfCalOldCaresSumCom(regularWorkers)),
		calNoJobInsurance: numberToCommaString(
			sumOfCalNoJobInsurancesSumCom(regularWorkers)
		),
		plusDeductHealthCare: plusDeductHealthCareValue
			? numberToCommaString(sumOfAWageplusDeductHealthCareCom(regularWorkers))
			: '',
		plusDeductOldCare: plusDeductOldCareValue
			? numberToCommaString(sumOfAWageplusDeductOldCareCom(regularWorkers))
			: '',
		plusDeductWorkTax: plusDeductWorkTaxValue
			? numberToCommaString(sumOfAWageplusDeductWorkTaxCom(regularWorkers))
			: '',
		plusDeductWorkRegionTax: plusDeductWorkRegionTaxValue
			? numberToCommaString(
					sumOfAWageplusDeductWorkRegionTaxCom(regularWorkers)
			  )
			: '',
		finalRetireHealthCareRecalFee: finalRetireHealthCareRecalFeeValue
			? numberToCommaString(
					sumOfFinalRetireHealthCareRecalFeeCom(regularWorkers)
			  )
			: '',
		finalRetireOldCareRecalFee: finalRetireOldCareRecalFeeValue
			? numberToCommaString(sumOfFinalRetireOldCareRecalFeeCom(regularWorkers))
			: '',
		plusDeductWorkerCompanyLoan: plusDeductWorkerCompanyLoanValue
			? numberToCommaString(
					sumOfAWageplusDeductWorkerCompanyLoanCom(regularWorkers)
			  )
			: '',
		plusDeductDuru: plusDeductDuruValue
			? numberToCommaString(sumOfAWageplusDeductDuruCom(regularWorkers))
			: '',
		plusMinus4Ins: plusMinus4InsValue
			? numberToCommaString(sumOfAWageplusMinus4InssCom(regularWorkers))
			: '',
		deductEtcName1: deductEtcName1Value
			? numberToCommaString(sumOfNumDeductEtcAWage1sCom(regularWorkers))
			: '',
		deductEtcName2: deductEtcName2Value
			? numberToCommaString(sumOfNumDeductEtcAWage2sCom(regularWorkers))
			: '',
		deductEtcName3: deductEtcName3Value
			? numberToCommaString(sumOfNumDeductEtcAWage3sCom(regularWorkers))
			: '',
		dedectTaxEtc: numberToCommaString(sumOfDedectTaxEtcsSumCom(regularWorkers)),
		deductWage: numberToCommaString(sumOfMonthDeductedWagesCom(regularWorkers)),
	};

	const conbinedDataCompany = [...formattedDataCompany, footerDataCompany];

	if (loading) {
		return <div>Loading...</div>;
	}

	return (
		<>
			{paydocus.length !== 0 && (
				<>
					{!currentUserProfile.ceoName ? (
						<SortableTable
							headers={headersWorker}
							data={conbinedDataWorker}
							sortFunctions={sortFunctionsWorker}
							title={titleOfTable}
							customRenderers={customRenderers}
						/>
					) : (
						// <SortableTable3Dan headers={headersCompany} data={conbinedDataCompany} sortFunctions={sortFunctionsCompany}  title={titleOfTable}/>
						<SortableTable
							headers={headersCompany}
							data={conbinedDataCompany}
							sortFunctions={sortFunctionsCompany}
							title={titleOfTable}
						/>
					)}
				</>
			)}
		</>
	);
}
