import React, { useEffect } from 'react';
import { Button, Table } from 'semantic-ui-react';
import { useSelector } from 'react-redux';
import { format } from 'date-fns';
import * as XLSX from 'xlsx';
import {
	numberToCommaString,
	roundUp,
	sumOfBizIncomeCom,
	sumOfBizTotalIncomeCom,
	sumOfBizIncomeTaxCom,
	sumOfBizIncomeRegionTaxCom,
	sumOfBizDeductAfterTaxCom,
	sumOfBizDeductWageCom,
} from '../../../app/common/util/util';

export default function PaydocusTableBizWorkerList({
	paydocus,
	searchResults,
	setSearchResults,
	searchTerm,
	paydocuWorkerName,
}) {
	const { currentUserProfile } = useSelector((state) => state.profile);
	const { clientInfo } = useSelector((state) => state.companyReducer);
	const id = clientInfo?.clientUid
		? clientInfo?.clientUid
		: clientInfo?.id
		? clientInfo?.id
		: currentUserProfile?.id;

	useEffect(() => {
		const searchedPaydocus = paydocus.filter((paydocu) => {
			return (
				paydocu?.workerInfo?.worker?.includes(searchTerm) ||
				paydocu?.info?.year?.includes(searchTerm)
			);
		});
		setSearchResults(searchedPaydocus);
	}, [searchTerm, setSearchResults, paydocus]);

	function exportTableToExcel(tableId, fileName) {
		let table = document.getElementById(tableId);
		let workbook = XLSX.utils.table_to_book(table, { sheet: fileName });
		XLSX.writeFile(workbook, `${fileName}.xlsx`);
	}

	const authLevel = clientInfo?.authLevel
		? clientInfo?.authLevel
		: currentUserProfile?.authLevel;

	const bizDeductionAfterTaxComment = searchResults.filter(
		(a) => a.bizDeductionAfterTaxComment
	)[0]?.bizDeductionAfterTaxComment;

	return (
		<>
			{paydocus.length !== 0 && (
				<>
					<Button
						onClick={() =>
							exportTableToExcel(
								'table-to-xls',
								`${paydocuWorkerName} 사업소득 명세 개인별 대장`
							)
						}
						color='green'>
						{`${paydocuWorkerName}의 사업소득 명세 개인별 대장 엑셀파일 전환`}
					</Button>
					<br />
					<br />

					<div
						style={{
							overflowX: 'auto',
							width: '100%',
							whiteSpace: 'nowrap',
							borderCollapse: 'collapse',
						}}>
						<Table
							style={{ textAlign: 'center', width: '100%', border: '1' }}
							id='table-to-xls'>
							<Table.Header>
								<Table.Row>
									{authLevel < 100 ? null : (
										<Table.HeaderCell>회사명</Table.HeaderCell>
									)}
									<Table.HeaderCell>년월</Table.HeaderCell>
									<Table.HeaderCell>년</Table.HeaderCell>
									<Table.HeaderCell>월</Table.HeaderCell>
									<Table.HeaderCell>사번</Table.HeaderCell>
									<Table.HeaderCell>부서명</Table.HeaderCell>
									<Table.HeaderCell>직위/직책</Table.HeaderCell>
									<Table.HeaderCell>직원명</Table.HeaderCell>
									<Table.HeaderCell>입사일</Table.HeaderCell>
									<Table.HeaderCell>
										마지막 <br /> 근무일
									</Table.HeaderCell>
									<Table.HeaderCell>사업소득</Table.HeaderCell>

									<Table.HeaderCell>사업소득 합계</Table.HeaderCell>
									<Table.HeaderCell>사업소득세</Table.HeaderCell>
									<Table.HeaderCell>지방소득세</Table.HeaderCell>

									<Table.HeaderCell>
										{bizDeductionAfterTaxComment}
									</Table.HeaderCell>
									<Table.HeaderCell>실수령액</Table.HeaderCell>
								</Table.Row>
							</Table.Header>
							{searchResults.map(
								(searchResult, index) =>
									(searchResult?.companyInfo?.agentUid === id ||
										searchResult.companyInfo.companyId === id ||
										currentUserProfile?.authLevel >= 100) && (
										<React.Fragment key={searchResult.id + index}>
											<Table.Body>
												<Table.Row>
													{authLevel < 100 ? null : (
														<Table.Cell>
															{searchResult?.companyInfo?.companyName}
														</Table.Cell>
													)}
													<Table.Cell>
														{searchResult?.info?.yearMonth}
													</Table.Cell>
													<Table.Cell>{searchResult?.info?.year}</Table.Cell>
													<Table.Cell>{searchResult?.info?.month}</Table.Cell>
													<Table.Cell>
														{searchResult?.workerInfo?.workerComNumber}
													</Table.Cell>
													<Table.Cell>
														{searchResult?.workerInfo?.workerDivisions}
													</Table.Cell>
													<Table.Cell>
														{searchResult?.workerInfo?.workerTitles}
													</Table.Cell>
													<Table.Cell>
														{searchResult?.workerInfo?.worker}
													</Table.Cell>
													<Table.Cell>
														{searchResult?.workerInfo?.workerEnteredDate
															? format(
																	new Date(
																		searchResult?.workerInfo?.workerEnteredDate
																	),
																	'yyyy-MM-dd'
															  )
															: ''}
													</Table.Cell>
													<Table.Cell>
														{searchResult?.workerInfo?.workerRetiredDate
															? format(
																	new Date(
																		searchResult?.workerInfo?.workerRetiredDate
																	),
																	'yyyy-MM-dd'
															  )
															: ''}
													</Table.Cell>
													<Table.Cell>
														{numberToCommaString(
															roundUp(searchResult?.bizIncome)
														)}
													</Table.Cell>

													<Table.Cell>
														{numberToCommaString(
															roundUp(searchResult?.bizTotalIncome)
														)}
													</Table.Cell>
													<Table.Cell>
														{numberToCommaString(
															searchResult?.deductTaxAnd4In?.userFixedTax !==
																'' &&
																!isNaN(
																	searchResult?.deductTaxAnd4In?.userFixedTax
																)
																? searchResult?.deductTaxAnd4In?.userFixedTax
																: searchResult?.bizIncomeTax
														)}
													</Table.Cell>
													<Table.Cell>
														{numberToCommaString(
															searchResult?.deductTaxAnd4In
																?.userFixedRegionTax !== '' &&
																!isNaN(
																	searchResult?.deductTaxAnd4In
																		?.userFixedRegionTax
																)
																? searchResult?.deductTaxAnd4In
																		?.userFixedRegionTax
																: searchResult?.bizIncomeRegionTax
														)}
													</Table.Cell>
													<Table.Cell>
														{numberToCommaString(
															searchResult?.bizDeductionAfterTax
														)}
													</Table.Cell>
													<Table.Cell>
														{numberToCommaString(searchResult?.bizDeductWage)}
													</Table.Cell>
												</Table.Row>
											</Table.Body>
										</React.Fragment>
									)
							)}
							<Table.Body>
								<Table.Row>
									{authLevel < 100 ? (
										<Table.Cell colSpan='9'>합계</Table.Cell>
									) : (
										<Table.Cell colSpan='10'>합계</Table.Cell>
									)}
									<Table.Cell>
										{numberToCommaString(sumOfBizIncomeCom(searchResults))}
									</Table.Cell>

									<Table.Cell>
										{numberToCommaString(sumOfBizTotalIncomeCom(searchResults))}
									</Table.Cell>
									<Table.Cell>
										{numberToCommaString(sumOfBizIncomeTaxCom(searchResults))}
									</Table.Cell>
									<Table.Cell>
										{numberToCommaString(
											sumOfBizIncomeRegionTaxCom(searchResults)
										)}
									</Table.Cell>

									<Table.Cell>
										{numberToCommaString(
											sumOfBizDeductAfterTaxCom(searchResults)
										)}
									</Table.Cell>
									<Table.Cell>
										{numberToCommaString(sumOfBizDeductWageCom(searchResults))}
									</Table.Cell>
								</Table.Row>
							</Table.Body>
						</Table>
					</div>
				</>
			)}
		</>
	);
}
