import React from 'react';
import { Form, Formik } from 'formik';
import MyTextInput from '../../../app/common/form/MyTextInput';
import { Button } from 'semantic-ui-react';
import * as Yup from 'yup';
import {
	addWorkerUsersToFirestore,
	updateWorkerUsersToFirestore,
} from '../../../app/firestore/firestoreService4In';
import { useHistory } from 'react-router';
import MySelectInput from '../../../app/common/form/MySelectInput';
import { agentType, regionCode } from '../../../app/api/dropdownOption';
import { useSelector } from 'react-redux';
import CustomerServiceForm from './CustomerServiceForm';
import CustomerPromotionForm from './CustomerPromotionForm';

export default function WorkerUserForm({ profile }) {
	const history = useHistory();
	const { currentUserProfile } = useSelector((state) => state.profile);

	const initialValues = {
		id: profile.id || '',
		comAuthLevel: profile.authLevel || 10,
		workerAuthLevel: 5,
		workerInputCompanyName: profile.workerInputCompanyName || '',
		isWorker: true,
		workerName: profile.workerName || '',
		paydocuWorkerUid: profile.paydocuWorkerUid || '',
		companyId: profile.companyId || '',
		companyRegion: profile.companyRegion || '',

		agentUid: profile.agentUid || '',
		agentName: profile.agentName || '',
		agentType: profile.agentType || '',
		agentWorkerName: profile.agentWorkerName || '',
		agentWorkerPhone: profile.agentWorkerPhone || '',
	};

	return (
		<>
			<h1>Information of Worker</h1>
			<Formik
				initialValues={initialValues}
				validationSchema={Yup.object({
					companyId: Yup.string()
						.required('회사코드')
						.min(15, '회사에서 부여한 "회사코드"를 입력하세요.'),
					workerName: Yup.string().required('성명'),
					workerInputCompanyName:
						Yup.string().required('회사명을 정확히 입력하세요'),
					paydocuWorkerUid: Yup.string()
						.required('직원코드')
						.min(15, '회사에서 부여한 "직원코드"를 입력하세요.'),
				})}
				onSubmit={async (values, { setSubmitting }) => {
					try {
						//일반 users 컬렉션
						await updateWorkerUsersToFirestore(values);
						//근로자 별도 컬렉션
						await addWorkerUsersToFirestore(values);
						history.push('/paydocus');
					} catch (error) {
						console.log(error.message);
					} finally {
						setSubmitting(false);
					}
				}}>
				{({ isSubmitting, isValid, dirty }) => (
					<Form className='ui form'>
						{currentUserProfile.authLevel >= 100 && (
							<>
								<hr />
								<CustomerServiceForm />
								<hr />
								<CustomerPromotionForm
									authLevel={currentUserProfile.authLevel}
								/>
								<hr />
							</>
						)}
						<h3>직원 코드</h3>
						<div
							style={{
								display: 'flex',
								flexWrap: 'wrap',
								justifyContent: 'flex-start',
								alignItems: 'center',
								alignContent: 'flex-end',
							}}>
							<div
								className='margin'
								style={{ width: '33%', marginRight: '3px' }}>
								<MyTextInput
									label='회사 상호'
									name='workerInputCompanyName'
									placeholder='정확한 회사명칭 (예,(주) 세종기술개발)'
									autoComplete='off'
								/>
							</div>
							<div
								className='margin'
								style={{ width: '33%', marginRight: '3px' }}>
								<MyTextInput
									label='직원 본인 이름'
									name='workerName'
									placeholder='실명입력'
									autoComplete='off'
								/>
							</div>
							<div
								className='margin'
								style={{ width: '33%', marginRight: '3px' }}>
								<MyTextInput
									label='회사 코드'
									name='companyId'
									placeholder='회사에서 부여한 "회사코드"를 입력하세요.'
									autoComplete='off'
								/>
							</div>
							<div
								className='margin'
								style={{ width: '33%', marginRight: '3px' }}>
								<MyTextInput
									label='직원 코드'
									name='paydocuWorkerUid'
									placeholder='인사담당자에게 문의하세요.'
									autoComplete='off'
								/>
							</div>
						</div>
						{currentUserProfile.authLevel >= 100 && (
							<>
								<hr />
								<h3>Sponsor 정보</h3>
								<div
									style={{
										display: 'flex',
										flexWrap: 'wrap',
										justifyContent: 'flex-start',
										alignItems: 'center',
										alignContent: 'flex-end',
									}}>
									<div
										className='margin'
										style={{ width: '33%', marginRight: '3px' }}>
										<MyTextInput
											label='Sponosor 이름'
											name='agentName'
											placeholder='예) 노무법인 최상인업'
										/>
									</div>
									<div
										className='margin'
										style={{ width: '33%', marginRight: '3px' }}>
										<MyTextInput
											label='Sponosor 코드'
											name='agentUid'
											placeholder='Sponsor에게 문의하세요.'
										/>
									</div>
									<div
										className='margin'
										style={{ width: '33%', marginRight: '3px' }}>
										<MySelectInput
											label='sponsor 종류'
											name='agentType'
											options={agentType}
										/>
									</div>
									<div
										className='margin'
										style={{ width: '33%', marginRight: '3px' }}>
										<MyTextInput
											label='Sponosor 담당자명'
											name='agentWorkerName'
											placeholder='Sponsor 담당자'
										/>
									</div>
									<div
										className='margin'
										style={{ width: '33%', marginRight: '3px' }}>
										<MyTextInput
											label='Sponosor 연락처'
											name='agentWorkerPhone'
											placeholder='Sponsor 담당자 연락처'
										/>
									</div>
									<div
										className='margin'
										style={{ width: '33%', marginRight: '3px' }}>
										<MySelectInput
											label='sponsor 지역'
											placeholder='지역구분'
											name='agentRegion'
											options={regionCode}
										/>
									</div>
								</div>
							</>
						)}
						<Button
							loading={isSubmitting}
							disabled={isSubmitting || !isValid || !dirty}
							floated='right'
							type='submit'
							size='large'
							positive
							content='직원 코드 저장'
						/>
					</Form>
				)}
			</Formik>
		</>
	);
}
