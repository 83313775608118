import React from 'react';
import queryString from 'query-string';
import { Button, Label } from 'semantic-ui-react';
import { useHistory } from 'react-router';
import { addPaymentsInfoToFirestore } from '../../app/firestore/firestoreService4In';
import { useSelector } from 'react-redux';


export default function PaymentsSuccess() {
  const {currentUserProfile} = useSelector(state => state.profile);
  const profile = currentUserProfile;
  const history= useHistory()
  const parsed = queryString.parse(document.location.search);
  const initialValues = {
    numOfWorkers: profile.numOfWorkers,
    customerKey: parsed.customerKey,
    authKey: parsed.authKey,
    companyName: profile.companyName,
  }
  const clickHandler = () => {
    addPaymentsInfoToFirestore(initialValues)
    history.push('/')
  }
  return (
    <>
      <Label>{parsed.customKey}</Label>
      <Button onClick={clickHandler}></Button>
    </>
  )
}


// import React, { useEffect } from 'react';
// import { useDispatch, useSelector } from 'react-redux';
// import queryString from 'query-string';
// import { Button } from 'semantic-ui-react';
// import { addPaymentsInfoToFirestore, listenToPaymentsInfoFromFirestore, updatePaymentsInfoToFirestore } from '../../app/firestore/firestoreService4In';
// import {clearSelectedPaymentsInfo, listenToSelectedPaymentsInfo} from '../auth/authActions'
// import useFirestoreDoc from '../../app/hooks/useFirestoreDoc';
// import LoadingComponent from '../../app/layout/LoadingComponent';
// import { Redirect } from 'react-router';
// import { Form, Formik } from 'formik';
// import MyTextInput from '../../app/common/form/MyTextInput';
// // import * as Yup from 'yup';

// export default function PaymentsSuccess({match, history, location}) {
//   const dispatch = useDispatch();
//   const { selectedPaymentsInfo} = useSelector(state => state.auth)
//   const { loading, error } = useSelector(state => state.async);

//   const parsed = queryString.parse(document.location.search);

//   const {currentUserProfile} = useSelector(state => state.profile);
//   const profile = currentUserProfile;

//   useEffect(() => {
//     if (location.pathname !== '/success') return;
//     dispatch(clearSelectedPaymentsInfo());
//   }, [location.pathname, dispatch])

//   const initialValues = {
//     numOfWorkers: profile.numOfWorkers,
//     customerKey: parsed.customerKey,
//     authKey: parsed.authKey,
//   }

//   // const clickHandler = () => {
//   //   history.push('/')
//   // }
// console.log("match.params.id", match.params.id)
//   useFirestoreDoc({
//     shouldExcute: match.params.id !== selectedPaymentsInfo?.id && location.pathname !== '/success',
//     query: () => listenToPaymentsInfoFromFirestore(match.params.id),
//     data: clientReq4In => dispatch(listenToSelectedPaymentsInfo(clientReq4In)),
//     deps: [match.params.id, dispatch]
//   });

//   if (loading)
//   return <LoadingComponent content='Loading Payments lists ...' />;
//   if (error) return <Redirect to='/error' />;

//   return (
//     <Formik
//     initialValues={initialValues}
//     // validationSchema={validationSchemaEmployee4In}
//     onSubmit={async (values, { setSubmitting }) => {
//       try {
//         console.log(selectedPaymentsInfo?.companyName)
//         console.log(values?.companyName)

//         selectedPaymentsInfo && 
//           (selectedPaymentsInfo?.companyName === values?.companyName )
//         ? await updatePaymentsInfoToFirestore(values)
//         : await addPaymentsInfoToFirestore(values);
//           setSubmitting(false);
//           history.push('/clientReqs4In');
//         } catch (error) {
//           console.log(error.message);
//           setSubmitting(false);
//         }
//       }}
//         enableReinitialize
//         >
//       {({ isSubmitting, dirty, isValid, values }) => (
//         <Form className='ui form'>
//           <div className='margin' style={{width : '33%', marginRight : '3px'}}>
//             <MyTextInput
//               label='회사명' 
//               value={profile?.companyName}
//               placeholder='노무법인 최상인업' 
//               name='companyName' 
//               autoComplete='off' 
//             />
//           </div>
//             <br />
//             <Button
//               loading={isSubmitting}
//               type='submit'
//               floated='right'
//               positive
//               content='저장'
//               />
//         </Form>
//       )}
//     </Formik>
//     // <>
//     //   <hi>결제성공</hi>
//     //   <Button onClick={() => clickHandler()}>새로운 출발</Button>
//     // </>
//   )
// }


