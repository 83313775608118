import React from 'react';
import { Table } from 'semantic-ui-react';
import { josaUnType, josaWaType } from '../../../../../app/common/util/util';

export default function ClientReqDLCHeadPerInfoAgree({ worker, companyName }) {
	const workerUn = josaUnType(worker);
	const companyWa = josaWaType(companyName);

	return (
		<>
			<Table width='100%' textAlign='center'>
				<Table.Body>
					<Table.Row>
						<Table.Cell textAlign='center'>
							<h1 className='jang'>{`개인정보 제공 • 수집 • 이용 • 활용 동의서`}</h1>
						</Table.Cell>
					</Table.Row>
				</Table.Body>
			</Table>
			<hr />
			<div className='laborContractStyle'>
				<p>{`${workerUn} ${companyWa} 근로 관계를 형성함에 있어 개인정보보호법 제15조(개인정보의 수집, 이용) 및 제17조(개인정보의 제공), 제18조(개인정보이용, 제공 제한) 등 관련 법규에 의거 다음과 같이 개인정보 제공 및 수집 • 이용 • 활용에 동의합니다.`}</p>
			</div>
		</>
	);
}
