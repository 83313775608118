import React, { useEffect, useState } from 'react';
import { Button, Table } from 'semantic-ui-react';
// import {delay} from '../../../app/common/util/util'
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import * as XLSX from 'xlsx';
import { deleteRiskSuspectReportsInFirestore } from '../../../app/firestore/firestoreService4In';
import { asyncActionError } from '../../../app/async/asyncReducer';
import { sendRiskSuspectReportId } from '../eventActions';

export default function RiskSuspectReportsList({
	riskSuspectReports,
	searchResults,
	setSearchResults,
	searchTerm,
	isWorker,
}) {
	const history = useHistory();
	const dispatch = useDispatch();
	const [loadingDelete, setLoadingDelete] = useState(false);
	const { currentUserProfile } = useSelector((state) => state.profile);
	const { clientInfo } = useSelector((state) => state.companyReducer);
	const id = clientInfo?.id
		? clientInfo?.id
		: clientInfo?.clientUid
		? clientInfo?.clientUid
		: currentUserProfile?.id;

	useEffect(() => {
		const searchedMonthlyReports = riskSuspectReports.filter(
			(riskSuspectReport) => {
				return riskSuspectReport?.workerName?.includes(searchTerm);
			}
		);
		setSearchResults(searchedMonthlyReports);
	}, [searchTerm, setSearchResults, riskSuspectReports]);

	async function handleDeleteToggle(id) {
		setLoadingDelete(true);
		try {
			await deleteRiskSuspectReportsInFirestore(id);
			history.go(0);
			// history.push('/riskSuspectReports');
			setLoadingDelete(false);
		} catch (error) {
			setLoadingDelete(true);
			dispatch(asyncActionError());
		}
	}

	async function handleManageRiskSuspectReport(id) {
		history.push(`/manageRiskSuspectReport/${id}`);
		dispatch(sendRiskSuspectReportId(id));
	}

	const titleColor = 'teal';

	const exportToExcel = () => {
		const table = document.getElementById('table-to-xls');
		const workbook = XLSX.utils.table_to_book(table, { sheet: 'SafetyReport' });
		XLSX.writeFile(workbook, '안전보건활동 REPORT list.xlsx');
	};

	return (
		<>
			{riskSuspectReports.length !== 0 && (
				<>
					<Button onClick={exportToExcel} color='green'>
						안전보건활동 정보 엑셀파일 전환
					</Button>
					<br />
					<br />
					<>
						<p style={{ color: 'red' }}>
							* 회사의 피드백이 있는 안전보건활동 자료는 수정되지 않습니다.
							주의하세요.
						</p>
						<Table
							style={{ border: '1', textAlign: 'center' }}
							id='table-to-xls'>
							<Table.Header>
								<Table.Row>
									<Table.Cell style={{ textAlign: 'left' }} colSpan={'6'}>
										1. 일상 업무 중 안전보건 관련 내용 기록하기
									</Table.Cell>
								</Table.Row>
								<Table.Row>
									{isWorker ? (
										<Table.Cell style={{ color: titleColor }}>년월</Table.Cell>
									) : (
										<Table.Cell style={{ color: titleColor }}>
											작성자
										</Table.Cell>
									)}
									<Table.Cell colSpan={'4'}>
										1-1. 귀하 및 귀하의 부서원들이 이달에 수행한 매일 또는 매주
										등 정기적으로 수행한 일상 업무는?
									</Table.Cell>
									{currentUserProfile?.email === 'freya0207@gmail.com' ? (
										<Table.Cell>삭제</Table.Cell>
									) : null}
									{isWorker ? (
										<Table.Cell>수정</Table.Cell>
									) : (
										<Table.Cell>피드백</Table.Cell>
									)}
								</Table.Row>
							</Table.Header>
							{searchResults.map((workerData, index) => (
								<Table.Body key={`customer-oriented-${index}`}>
									<Table.Row key={`${workerData.id}-${index}-customer`}>
										{isWorker ? (
											<Table.Cell>{workerData?.yearMonth}</Table.Cell>
										) : (
											<Table.Cell>{workerData?.workerName}</Table.Cell>
										)}
										<Table.Cell colSpan={'4'} style={{ width: '100%' }}>
											{workerData?.nomalTask?.context}
										</Table.Cell>
										{currentUserProfile?.email === 'freya0207@gmail.com' ? (
											<Table.Cell>
												<Button
													loading={loadingDelete}
													onClick={() => handleDeleteToggle(workerData?.id)}
													color='red'
													floated='right'
													content='삭제'
												/>
											</Table.Cell>
										) : null}
										{workerData?.hostUid === id ? (
											<Table.Cell>
												<Button
													loading={loadingDelete}
													disabled={
														workerData?.nomalTaskFeedBack !== '' ||
														workerData?.riskSuspectFeedBack !== '' ||
														workerData?.proposalSafetyFeedBack !== '' ||
														workerData?.hopeEducationSafetyFeedBack !== '' ||
														workerData?.grievanceSafetyFeedBack !== '' ||
														workerData?.complianceSafetyFeedBack !== ''
													}
													onClick={() =>
														handleManageRiskSuspectReport(workerData?.id)
													}
													color='teal'
													floated='right'
													content={isWorker ? '수정' : '피드백'}
												/>
											</Table.Cell>
										) : (
											<Table.Cell>
												<Button
													loading={loadingDelete}
													onClick={() =>
														handleManageRiskSuspectReport(workerData?.id)
													}
													color='teal'
													floated='right'
													content={isWorker ? '수정' : '피드백'}
												/>
											</Table.Cell>
										)}
									</Table.Row>
								</Table.Body>
							))}

							<Table.Header>
								<Table.Row>
									{isWorker ? (
										<Table.HeaderCell
											style={{ width: '10%', color: titleColor }}>
											년월
										</Table.HeaderCell>
									) : (
										<Table.HeaderCell
											style={{ width: '10%', color: titleColor }}>
											작성자
										</Table.HeaderCell>
									)}
									<Table.HeaderCell colSpan={'5'} style={{ color: titleColor }}>
										1-2, 위 업무를 수행하면서 본인 또는 동료(회사직원 및
										협력업체 직원을 포함)가 사고났거나 사고날뻔했던 아차했던
										작업 및 그 원인은?
									</Table.HeaderCell>
								</Table.Row>
							</Table.Header>
							{searchResults.map((workerData, index) => (
								<Table.Body key={`customer-oriented1-${index}`}>
									<Table.Row key={`${workerData.id}-${index}-customer1`}>
										{isWorker ? (
											<Table.Cell>{workerData?.yearMonth}</Table.Cell>
										) : (
											<Table.Cell>{workerData?.workerName}</Table.Cell>
										)}
										<Table.Cell colSpan={'5'}>
											{workerData?.nomalTask?.achaAccident}
										</Table.Cell>
									</Table.Row>
									{!workerData?.nomalTaskFeedBack ? null : (
										<Table.Row>
											<Table.Cell style={{ color: 'blue' }}>피드백</Table.Cell>
											<Table.Cell style={{ color: 'blue' }} colSpan={'5'}>
												{workerData?.nomalTaskFeedBack}
											</Table.Cell>
										</Table.Row>
									)}
								</Table.Body>
							))}
							<Table.Body>
								<Table.Row>
									<Table.Cell colSpan={'6'}></Table.Cell>
								</Table.Row>
							</Table.Body>

							<Table.Header>
								<Table.Row>
									<Table.HeaderCell style={{ textAlign: 'left' }} colSpan={'6'}>
										2. 위험성 평가 활동 기록하기
									</Table.HeaderCell>
								</Table.Row>

								<Table.Row>
									{isWorker ? (
										<Table.HeaderCell
											style={{ width: '10%', color: titleColor }}>
											년월
										</Table.HeaderCell>
									) : (
										<Table.HeaderCell
											style={{ width: '10%', color: titleColor }}>
											작성자
										</Table.HeaderCell>
									)}
									<Table.HeaderCell
										colSpan={'5'}
										style={{ width: '10%', color: titleColor }}>
										2-1.위 1-4의 변경 작업시 전까지 위험성 평가를 실시했는지 ?
									</Table.HeaderCell>
								</Table.Row>
							</Table.Header>
							{searchResults.map((workerData, index) => (
								<Table.Body key={`customer-oriented2-${index}`}>
									<Table.Row key={`${workerData.id}-${index}-customer2`}>
										{isWorker ? (
											<Table.Cell>{workerData?.yearMonth}</Table.Cell>
										) : (
											<Table.Cell>{workerData?.workerName}</Table.Cell>
										)}
										<Table.Cell>
											{workerData?.riskSuspect?.planedChange}
										</Table.Cell>
									</Table.Row>
								</Table.Body>
							))}

							<Table.Header>
								<Table.Row>
									{isWorker ? (
										<Table.HeaderCell
											style={{ width: '10%', color: titleColor }}>
											년월
										</Table.HeaderCell>
									) : (
										<Table.HeaderCell
											style={{ width: '10%', color: titleColor }}>
											작성자
										</Table.HeaderCell>
									)}
									<Table.HeaderCell colSpan={'5'} style={{ color: titleColor }}>
										2-2. 위 업무 수행에 사용한 기계 또는 설비, 도구의 이상 유무
										및 이상시 조치사항은?
									</Table.HeaderCell>
								</Table.Row>
							</Table.Header>
							{searchResults.map((workerData, index) => (
								<Table.Body key={`customer-oriented3-${index}`}>
									<Table.Row key={`${workerData.id}-${index}-customer3`}>
										{isWorker ? (
											<Table.Cell>{workerData?.yearMonth}</Table.Cell>
										) : (
											<Table.Cell>{workerData?.workerName}</Table.Cell>
										)}
										<Table.Cell colSpan={'5'}>
											{workerData?.riskSuspect?.machine}
										</Table.Cell>
									</Table.Row>
									{!workerData?.nomalTaskFeedBack ? null : (
										<Table.Row>
											<Table.Cell style={{ color: 'blue' }}>피드백</Table.Cell>
											<Table.Cell style={{ color: 'blue' }} colSpan={'5'}>
												{workerData?.nomalTaskFeedBack}
											</Table.Cell>
										</Table.Row>
									)}
								</Table.Body>
							))}

							<Table.Header>
								<Table.Row>
									{isWorker ? (
										<Table.HeaderCell
											style={{ width: '10%', color: titleColor }}>
											년월
										</Table.HeaderCell>
									) : (
										<Table.HeaderCell
											style={{ width: '10%', color: titleColor }}>
											작성자
										</Table.HeaderCell>
									)}
									<Table.HeaderCell colSpan={'5'} style={{ color: titleColor }}>
										2-3. 위 업무를 수행하면서 느낀 작업 공간 위험 또는 위해 요인
										및 조치사항은 ?
									</Table.HeaderCell>
								</Table.Row>
							</Table.Header>
							{searchResults.map((workerData, index) => (
								<Table.Body key={`customer-oriented4-${index}`}>
									<Table.Row key={`${workerData.id}-${index}-customer4`}>
										{isWorker ? (
											<Table.Cell>{workerData?.yearMonth}</Table.Cell>
										) : (
											<Table.Cell>{workerData?.workerName}</Table.Cell>
										)}
										<Table.Cell colSpan={'5'}>
											{workerData?.riskSuspect?.space}
										</Table.Cell>
									</Table.Row>
									{!workerData?.nomalTaskFeedBack ? null : (
										<Table.Row>
											<Table.Cell style={{ color: 'blue' }}>피드백</Table.Cell>
											<Table.Cell style={{ color: 'blue' }} colSpan={'5'}>
												{workerData?.riskSuspectFeedBack}
											</Table.Cell>
										</Table.Row>
									)}
								</Table.Body>
							))}

							<Table.Body>
								<Table.Row>
									<Table.Cell colSpan={'6'}></Table.Cell>
								</Table.Row>
							</Table.Body>

							<Table.Header>
								<Table.Row>
									<Table.HeaderCell style={{ textAlign: 'left' }} colSpan={'6'}>
										안전보전 건의사항 등
									</Table.HeaderCell>
								</Table.Row>
								<Table.Row>
									<Table.HeaderCell style={{ textAlign: 'left' }} colSpan={'6'}>
										{' '}
										안전보건 업무 개선
									</Table.HeaderCell>
								</Table.Row>
								<Table.Row>
									{isWorker ? (
										<Table.HeaderCell
											style={{ width: '10%', color: titleColor }}>
											년월
										</Table.HeaderCell>
									) : (
										<Table.HeaderCell
											style={{ width: '10%', color: titleColor }}>
											작성자
										</Table.HeaderCell>
									)}
									<Table.HeaderCell colSpan={'5'} style={{ color: titleColor }}>
										안전보건 개선을 위한 제안사항(해결하고자 하는 문제점을 함께
										기재요망)
									</Table.HeaderCell>
								</Table.Row>
							</Table.Header>
							{searchResults.map((workerData, index) => (
								<Table.Body key={`customer-oriented5-${index}`}>
									<Table.Row key={`${workerData.id}-${index}-customer5`}>
										{isWorker ? (
											<Table.Cell>{workerData?.yearMonth}</Table.Cell>
										) : (
											<Table.Cell>{workerData?.workerName}</Table.Cell>
										)}
										<Table.Cell colSpan={'5'}>
											{workerData?.proposalSafety}
										</Table.Cell>
									</Table.Row>
									{!workerData?.proposalSafetyFeedBack ? null : (
										<Table.Row>
											<Table.Cell style={{ color: 'blue' }}>피드백</Table.Cell>
											<Table.Cell style={{ color: 'blue' }} colSpan={'5'}>
												{workerData?.proposalSafetyFeedBack}
											</Table.Cell>
										</Table.Row>
									)}
								</Table.Body>
							))}

							<Table.Header>
								<Table.Row>
									<Table.HeaderCell style={{ textAlign: 'left' }} colSpan={'6'}>
										안전보건 희망교육
									</Table.HeaderCell>
								</Table.Row>
								<Table.Row>
									{isWorker ? (
										<Table.HeaderCell
											style={{ width: '10%', color: titleColor }}>
											년월
										</Table.HeaderCell>
									) : (
										<Table.HeaderCell
											style={{ width: '10%', color: titleColor }}>
											작성자
										</Table.HeaderCell>
									)}
									<Table.HeaderCell colSpan={'5'} style={{ color: titleColor }}>
										안전보건 개선을 위해 회사에 요청하는 교육?
									</Table.HeaderCell>
								</Table.Row>
							</Table.Header>
							{searchResults.map((workerData, index) => (
								<Table.Body key={`customer-oriented6-${index}`}>
									<Table.Row key={`${workerData.id}-${index}-customer6`}>
										{isWorker ? (
											<Table.Cell>{workerData?.yearMonth}</Table.Cell>
										) : (
											<Table.Cell>{workerData?.workerName}</Table.Cell>
										)}
										<Table.Cell colSpan={'5'}>
											{workerData?.hopeEducationSafety}
										</Table.Cell>
									</Table.Row>
									{!workerData?.hopeEducationSafetyFeedBack ? null : (
										<Table.Row>
											<Table.Cell style={{ color: 'blue' }}>피드백</Table.Cell>
											<Table.Cell style={{ color: 'blue' }} colSpan={'5'}>
												{workerData?.hopeEducationSafetyFeedBack}
											</Table.Cell>
										</Table.Row>
									)}
								</Table.Body>
							))}

							<Table.Header>
								<Table.Row>
									<Table.HeaderCell style={{ textAlign: 'left' }} colSpan={'6'}>
										{' '}
										안전보건 고충사항
									</Table.HeaderCell>
								</Table.Row>
								<Table.Row>
									{isWorker ? (
										<Table.HeaderCell
											style={{ width: '10%', color: titleColor }}>
											년월
										</Table.HeaderCell>
									) : (
										<Table.HeaderCell
											style={{ width: '10%', color: titleColor }}>
											작성자
										</Table.HeaderCell>
									)}
									<Table.HeaderCell colSpan={'5'} style={{ color: titleColor }}>
										안전보건 관련 고충사항 내용(When, Where, Who, What, How, Why
										등 자세한 내용 기재요망)
									</Table.HeaderCell>
								</Table.Row>
							</Table.Header>
							{searchResults.map((workerData, index) => (
								<Table.Body key={`customer-oriented7-${index}`}>
									<Table.Row key={`${workerData.id}-${index}-customer7`}>
										{isWorker ? (
											<Table.Cell>{workerData?.yearMonth}</Table.Cell>
										) : (
											<Table.Cell>{workerData?.workerName}</Table.Cell>
										)}
										<Table.Cell colSpan={'5'}>
											{workerData?.grievanceSafety}
										</Table.Cell>
									</Table.Row>
									{!workerData?.grievanceSafetyFeedBack ? null : (
										<Table.Row>
											<Table.Cell style={{ color: 'blue' }}>피드백</Table.Cell>
											<Table.Cell style={{ color: 'blue' }} colSpan={'5'}>
												{workerData?.grievanceSafetyFeedBack}
											</Table.Cell>
										</Table.Row>
									)}
								</Table.Body>
							))}

							<Table.Header>
								<Table.Row>
									<Table.HeaderCell style={{ textAlign: 'left' }} colSpan={'6'}>
										안전보건 컴플라이언스
									</Table.HeaderCell>
								</Table.Row>
								<Table.Row>
									{isWorker ? (
										<Table.HeaderCell
											style={{ width: '10%', color: titleColor }}>
											년월
										</Table.HeaderCell>
									) : (
										<Table.HeaderCell
											style={{ width: '10%', color: titleColor }}>
											작성자
										</Table.HeaderCell>
									)}
									<Table.HeaderCell colSpan={'5'} style={{ color: titleColor }}>
										안전보건 관련 컴플라이언스(회사규칙이나 법률 위반사항) 보고
										내용(When, Where, Who, What, How, Why 등 자세한 내용
										기재요망)
									</Table.HeaderCell>
								</Table.Row>
							</Table.Header>
							{searchResults.map((workerData, index) => (
								<Table.Body key={`customer-oriented8-${index}`}>
									<Table.Row key={`${workerData.id}-${index}-customer8`}>
										{isWorker ? (
											<Table.Cell>{workerData?.yearMonth}</Table.Cell>
										) : (
											<Table.Cell>{workerData?.workerName}</Table.Cell>
										)}
										<Table.Cell colSpan={'5'}>
											{workerData?.complianceSafety}
										</Table.Cell>
									</Table.Row>
									{!workerData?.complianceSafetyFeedBack ? null : (
										<Table.Row>
											<Table.Cell style={{ color: 'blue' }}>피드백</Table.Cell>
											<Table.Cell style={{ color: 'blue' }} colSpan={'5'}>
												{workerData?.complianceSafetyFeedBack}
											</Table.Cell>
										</Table.Row>
									)}
								</Table.Body>
							))}
							<Table.Body>
								<Table.Row>
									<Table.Cell colSpan={'6'}></Table.Cell>
								</Table.Row>
							</Table.Body>
						</Table>
					</>
				</>
			)}
		</>
	);
}
