import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Grid, Input, Loader } from 'semantic-ui-react';
import {
	fetchInsureInCompanyFromFirestoreService4In,
	fetchInsureInFromFirestoreService4In,
	fetchInsureOutCompanyFromFirestoreService4In,
	fetchInsureOutFromFirestoreService4In,
} from '../companyActions';
import InsureManageList from './InsureManageList';

export default function InsureManageDashboard() {
	const dispatch = useDispatch();
	const { workersOut, workersIn, clientInfo } = useSelector(
		(state) => state.companyReducer
	);
	const { loading } = useSelector((state) => state.async);
	const { authenticated } = useSelector((state) => state.auth);
	const { currentUserProfile } = useSelector((state) => state.profile);

	const [searchTerm, setSearchTerm] = useState('');
	const [searchResults, setSearchResults] = useState([]);

	const authLevel = currentUserProfile?.authLevel;

	const insureManageType = currentUserProfile?.insureManageType;

	const companyName = clientInfo?.companyName
		? clientInfo?.companyName
		: currentUserProfile?.companyName;

	const companyId = currentUserProfile?.id;

	const handleChange = (event) => {
		setSearchTerm(event.target.value);
	};

	useEffect(() => {
		const fetchInsureOutData = async () => {
			try {
				if (authLevel >= 100) {
					await dispatch(fetchInsureOutFromFirestoreService4In());
				} else {
					await dispatch(
						fetchInsureOutCompanyFromFirestoreService4In(companyId)
					);
				}
			} catch (error) {
				console.error('Error fetching insurance out data:', error);
			}
		};

		fetchInsureOutData();
	}, [dispatch, companyId, insureManageType, authLevel]);

	useEffect(() => {
		const fetchInsureInData = async () => {
			try {
				if (authLevel >= 100) {
					await dispatch(fetchInsureInFromFirestoreService4In());
				} else {
					await dispatch(
						fetchInsureInCompanyFromFirestoreService4In(companyId)
					);
				}
			} catch (error) {
				console.error('Error fetching insurance in data:', error);
			}
		};

		fetchInsureInData();
	}, [dispatch, companyId, insureManageType, authLevel]);

	return (
		<>
			{authLevel >= 100 ? '회사명, 직원성명' : '직원성명'} :
			<Input
				type='text'
				placeholder='Search'
				value={searchTerm}
				onChange={handleChange}
			/>
			<Grid>
				<Grid.Column width={16}>
					<>
						<hr />
						{authenticated && (
							<InsureManageList
								workersOut={workersOut}
								workersIn={workersIn}
								loading={loading}
								searchResults={searchResults}
								setSearchResults={setSearchResults}
								searchTerm={searchTerm}
								insureManageType={insureManageType}
								companyName={companyName}
								authLevel={authLevel}
								currentUserProfile={currentUserProfile}
							/>
						)}
					</>
				</Grid.Column>
				<Grid.Column width={16}>
					<Loader active={loading} />
				</Grid.Column>
			</Grid>
		</>
	);
}
