import React from 'react'

export default function WorkerFormWageComments({
    MyTextArea,
}) {
  return (
    <div style={{ display : 'flex', flexWrap : 'wrap', justifyContent: 'flex-start', alignItems : 'center', alignContent : 'flex-end'}}>
      <div className='margin' style={{width : '99.5%', marginRight : '3px'}}> 
        <MyTextArea
          multiple
          label='(7). 월 고정급 외 상여금, 성과급, 임금 공제 등 임금 특약 사항' 
          placeholder='월고정급 외 임금 등에 대한 안내사항(임금계약서 제2조 "임금의 구성항목 및 계산방법" 하단에 표시됨' 
          name='wageComment' 
          rows='3'
        />
      </div>
    </div>    
  )
}
