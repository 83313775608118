import React from 'react'
import { Table } from 'semantic-ui-react'

export default function PaydocuDetailedPageDayPlusForDayWorker({
  dayWorkerPlusOTime,
  numOfWorkers,
  dayWorkerPlusNTime,
  dayWorkerPlusHBTime,
  dayWorkerPlusHOTime,
  dayWorkerPlusHNTime,
  dayWorkerAbsentTimes,
  dayWorkerPlusOverTimeDates,
  dayWorkerPlusNightTimeDates,
  dayWorkerPlusHoliBasicTimeDates,
  dayWorkerPlusHoliOverTimeDates,
  dayWorkerPlusHoliNightTimeDates,
  dayWorkerAbsentTimeDates,
}) {
  return (
    <>
      <Table.Row>
        {dayWorkerPlusOTime ? 
          <>
            {numOfWorkers >= 5 ? 
              <Table.Cell
                style={{ width: '11.11%', textAlign: 'center' }}>추가연장<br />시간수</Table.Cell>
              : 
              <Table.Cell
                style={{ width: '11.11%', textAlign: 'center' }}>추가초과<br />시간수</Table.Cell>
            }
          </>
          : null
        }
        {dayWorkerPlusNTime && <Table.Cell
          style={{ width: '11.11%', textAlign: 'center' }}>추가야간<br />시간수</Table.Cell>}
        {dayWorkerPlusHBTime && <Table.Cell
          style={{ width: '11.11%', textAlign: 'center' }}>추가휴일<br />기본시간수</Table.Cell>}
        {dayWorkerPlusHOTime ? 
          <>
            {numOfWorkers >= 5 ? 
              <Table.Cell
                style={{ width: '11.11%', textAlign: 'center' }}>추가휴일<br/>연장시간수</Table.Cell>
              :
              <Table.Cell
                style={{ width: '11.11%', textAlign: 'center' }}>추가휴일<br />초과시간수</Table.Cell>
            }
          </>
          : null
        }
        {dayWorkerPlusHNTime && <Table.Cell
          style={{ width: '11.11%', textAlign: 'center' }}>추가휴일<br />야간시간수</Table.Cell>}
        {dayWorkerAbsentTimes && <Table.Cell
          style={{ width: '11.11%', textAlign: 'center' }}>{dayWorkerAbsentTimes ? <span style={{color: 'red'}}>**공제시간수(지각/조퇴/외출)</span> : null}</Table.Cell>}
        {!dayWorkerPlusOTime && <Table.Cell></Table.Cell>}
        {!dayWorkerPlusNTime && <Table.Cell></Table.Cell>}
        {!dayWorkerPlusHBTime && <Table.Cell></Table.Cell>}
        {!dayWorkerPlusHOTime && <Table.Cell></Table.Cell>}
        {!dayWorkerPlusHNTime && <Table.Cell></Table.Cell>}
        {!dayWorkerAbsentTimes && <Table.Cell></Table.Cell>}
        <Table.Cell></Table.Cell>
        <Table.Cell></Table.Cell>
        <Table.Cell></Table.Cell>
      </Table.Row>
      <Table.Row>
        {dayWorkerPlusOTime && <Table.Cell
          style={{ width: '11.11%', textAlign: 'center' }}>{dayWorkerPlusOTime}</Table.Cell>}
        {dayWorkerPlusNTime && <Table.Cell
          style={{ width: '11.11%', textAlign: 'center' }}>{dayWorkerPlusNTime}</Table.Cell>}
        {dayWorkerPlusHBTime && <Table.Cell
          style={{ width: '11.11%', textAlign: 'center' }}>{dayWorkerPlusHBTime}</Table.Cell>}
        {dayWorkerPlusHOTime && <Table.Cell
          style={{ width: '11.11%', textAlign: 'center' }}>{dayWorkerPlusHOTime}</Table.Cell>}
        {dayWorkerPlusHNTime && <Table.Cell
          style={{ width: '11.11%', textAlign: 'center' }}>{dayWorkerPlusHNTime}</Table.Cell>}
        {dayWorkerAbsentTimes && <Table.Cell
          style={{ width: '11.11%', textAlign: 'center' }}>{dayWorkerAbsentTimes}</Table.Cell> }
        {!dayWorkerPlusOTime && <Table.Cell></Table.Cell>}
        {!dayWorkerPlusNTime && <Table.Cell></Table.Cell>}
        {!dayWorkerPlusHBTime && <Table.Cell></Table.Cell>}
        {!dayWorkerPlusHOTime && <Table.Cell></Table.Cell>}
        {!dayWorkerPlusHNTime && <Table.Cell></Table.Cell>}
        {!dayWorkerAbsentTimes && <Table.Cell></Table.Cell>}
        <Table.Cell></Table.Cell>
        <Table.Cell></Table.Cell>
        <Table.Cell></Table.Cell>
      </Table.Row>
      <Table.Row>
        {dayWorkerPlusOverTimeDates ?
          <>
            <Table.Cell
              style={{ width: '11.11%', textAlign: 'center' }}>추가연장 : </Table.Cell>
            <Table.Cell
              colSpan='2' style={{ width: '11.11%', textAlign: 'center' }}>{dayWorkerPlusOverTimeDates}</Table.Cell>
          </>
          : null 
        }
        {dayWorkerPlusNightTimeDates ?
          <>
            <Table.Cell
              style={{ width: '11.11%', textAlign: 'center' }}>추가야간 : </Table.Cell>
            <Table.Cell
              colSpan='2' style={{ width: '11.11%', textAlign: 'center' }}>{dayWorkerPlusNightTimeDates}</Table.Cell>
          </>
          : null  
        }
        {dayWorkerPlusHoliBasicTimeDates ?
          <>
            <Table.Cell
              style={{ width: '11.11%', textAlign: 'center' }}>추가휴일기본 : </Table.Cell>
            <Table.Cell
              colSpan='2' style={{ width: '11.11%', textAlign: 'center' }}>{dayWorkerPlusHoliBasicTimeDates}</Table.Cell>
          </>
          : null  
        }
      </Table.Row>
      <Table.Row>
        {dayWorkerPlusHoliOverTimeDates ?
          <>
            <Table.Cell
              style={{ width: '11.11%', textAlign: 'center' }}>추가휴일연장 : </Table.Cell>
            <Table.Cell
              colSpan='2' style={{ width: '11.11%', textAlign: 'center' }}>{dayWorkerPlusHoliOverTimeDates}</Table.Cell>
          </>
          : null  
        }
        {dayWorkerPlusHoliNightTimeDates ?
          <>
            <Table.Cell
              style={{ width: '11.11%', textAlign: 'center' }}>추가휴일야간 : </Table.Cell>
            <Table.Cell
              colSpan='2' style={{ width: '11.11%', textAlign: 'center' }}>{dayWorkerPlusHoliNightTimeDates}</Table.Cell>
          </>    
          : null   

        }    
        {dayWorkerAbsentTimeDates ?
          <>
            <Table.Cell
              style={{ width: '11.11%', textAlign: 'center' }}>공제시간일자 : </Table.Cell>
            <Table.Cell
              colSpan='2' style={{ width: '11.11%', textAlign: 'center' }}>{dayWorkerAbsentTimeDates}</Table.Cell>
          </> 
          : null   
        }
      </Table.Row>
    </>
  )
}
