// import { format } from 'date-fns';
import React, { useEffect, useState } from 'react';
import { Button, Grid, Tab, Table } from 'semantic-ui-react';
import RoeBS1GeneralRules from './ruleOfEmployeeBizSecretDetail/RoeBS1GeneralRules';
import RoeBS2SystemRules from './ruleOfEmployeeBizSecretDetail/RoeBS2SystemRules';
import RoeBS3ManageRules from './ruleOfEmployeeBizSecretDetail/RoeBS3ManageRules';
import RoeBS4UseRules from './ruleOfEmployeeBizSecretDetail/RoeBS4UseRules';
import RoeBS5WorkerManageRules from './ruleOfEmployeeBizSecretDetail/RoeBS5WorkerManageRules';
import RoeBS6CoPartnerRules from './ruleOfEmployeeBizSecretDetail/RoeBS6CoPartnerRules';
import RoeBS7ReviseRules from './ruleOfEmployeeBizSecretDetail/RoeBS7ReviseRules';
import {
	josaUnType,
	josaGaType,
	josaUlType,
	josaWaType,
	printRoe,
} from '../../../app/common/util/util';
import PaydocuShortCut from '../paydocuDashboard/PaydocuShortCut';
import { useDispatch, useSelector } from 'react-redux';
import {
	clearClientReqs4In,
	fetchClientReqs4In,
} from '../../clientsRequest/clientReq4In/clientReqActions4In';

export default function RuleOfEmployeeBizSecretContents({
	currentUserProfile,
	profile,
	filter,
	serviceStartDate,
	bizSecretExcuteDueDate,
	clientInfo,
}) {
	const dispatch = useDispatch();
	const { clientReqs4In } = useSelector((state) => state.clientReq4InStore);
	const [sectionCount1, setSectionCount1] = useState(0); // 조항 수를 관리
	const [sectionCount2, setSectionCount2] = useState(0); // 조항 수를 관리
	const [sectionCount3, setSectionCount3] = useState(0); // 조항 수를 관리
	const [sectionCount4, setSectionCount4] = useState(0); // 조항 수를 관리
	const [sectionCount5, setSectionCount5] = useState(0); // 조항 수를 관리
	const [sectionCount6, setSectionCount6] = useState(0); // 조항 수를 관리

	useEffect(() => {
		dispatch(fetchClientReqs4In(filter)).then(() => {});
		return () => {
			dispatch(clearClientReqs4In());
		};
	}, [dispatch, filter]);

	const isWorker = profile?.isWorker;
	const authLevel = clientInfo?.authLevel
		? clientInfo?.authLevel
		: currentUserProfile?.authLevel;
	const companyName = isWorker
		? profile?.workerInputCompanyName
			? profile?.workerInputCompanyName
			: profile?.companyName
		: profile?.companyName;

	const company = companyName; // 이 부분 수정
	const companyUn = josaUnType(company);
	const companyGa = josaGaType(company);
	const companyWa = josaWaType(company);
	const companyUl = josaUlType(company);

	const [allSectionsOpen, setAllSectionsOpen] = useState(false);

	const toggleAllSections = () => {
		setAllSectionsOpen(!allSectionsOpen);
	};

	const printFunc = () => {
		printRoe();
	};
	return (
		<>
			{isWorker ? null : (
				<PaydocuShortCut filter={filter} clientReqs4In={clientReqs4In} />
			)}
			<Tab.Pane>
				<Grid>
					<Grid.Column width={16}>
						<div style={{ marginBottom: '20px' }}>
							<Button type='button' color='blue' onClick={() => printFunc()}>
								영업비밀보호규정 출력
							</Button>
							<Button type='button' color='green' onClick={toggleAllSections}>
								{allSectionsOpen ? '모든 조항 닫기' : '모든 조항 열기'}
							</Button>
						</div>
						<>
							<div id='roeContents'>
								<Table width='100%' align='center'>
									<Table.Body>
										<Table.Row>
											<Table.Cell align='center'>
												<h1
													style={{
														textAlign: 'center',
														margin: '30px',
														fontSize: '30px',
													}}>{`${profile?.companyName} 영업비밀보호규정`}</h1>
											</Table.Cell>
										</Table.Row>
									</Table.Body>
								</Table>
								<>
									<RoeBS1GeneralRules
										serviceStartDate={serviceStartDate}
										company={company}
										companyGa={companyGa}
										allOpen={allSectionsOpen}
										setSectionCount={setSectionCount1}
									/>
									<RoeBS2SystemRules
										serviceStartDate={serviceStartDate}
										authLevel={authLevel}
										profile={profile}
										company={company} // 이 부분 수정
										companyUn={companyUn}
										companyGa={companyGa}
										companyWa={companyWa}
										allOpen={allSectionsOpen}
										setSectionCount={setSectionCount2}
										previousSectionCount1={sectionCount1}
									/>
									<RoeBS3ManageRules
										serviceStartDate={serviceStartDate}
										company={company} // 이 부분 수정
										companyUn={companyUn}
										companyGa={companyGa}
										allOpen={allSectionsOpen}
										setSectionCount={setSectionCount3}
										previousSectionCount2={sectionCount2}
										previousSectionCount1={sectionCount1}
									/>
									<RoeBS4UseRules
										serviceStartDate={serviceStartDate}
										profile={profile}
										company={company} // 이 부분 수정
										companyUn={companyUn}
										companyGa={companyGa}
										allOpen={allSectionsOpen}
										setSectionCount={setSectionCount4}
										previousSectionCount2={sectionCount2}
										previousSectionCount1={sectionCount1}
										previousSectionCount3={sectionCount3}
									/>
									<RoeBS5WorkerManageRules
										serviceStartDate={serviceStartDate}
										profile={profile}
										company={company} // 이 부분 수정
										companyUn={companyUn}
										companyGa={companyGa}
										companyWa={companyWa}
										allOpen={allSectionsOpen}
										setSectionCount={setSectionCount5}
										previousSectionCount2={sectionCount2}
										previousSectionCount1={sectionCount1}
										previousSectionCount3={sectionCount3}
										previousSectionCount4={sectionCount4}
									/>
									<RoeBS6CoPartnerRules
										serviceStartDate={serviceStartDate}
										company={company} // 이 부분 수정
										companyUn={companyUn}
										companyGa={companyGa}
										companyUl={companyUl}
										companyWa={companyWa}
										allOpen={allSectionsOpen}
										setSectionCount={setSectionCount6}
										previousSectionCount2={sectionCount2}
										previousSectionCount1={sectionCount1}
										previousSectionCount3={sectionCount3}
										previousSectionCount4={sectionCount4}
										previousSectionCount5={sectionCount5}
									/>
									<RoeBS7ReviseRules
										serviceStartDate={serviceStartDate}
										profile={profile}
										companyUn={companyUn}
										allOpen={allSectionsOpen}
										previousSectionCount2={sectionCount2}
										previousSectionCount1={sectionCount1}
										previousSectionCount3={sectionCount3}
										previousSectionCount4={sectionCount4}
										previousSectionCount5={sectionCount5}
										previousSectionCount6={sectionCount6}
										bizSecretExcuteDueDate={bizSecretExcuteDueDate}
									/>
								</>
								<footer>
									저작권 © SafeHR(주식회사 카이소프트). All rights reserved
									since 2006.
								</footer>
							</div>
						</>
					</Grid.Column>
				</Grid>
			</Tab.Pane>
		</>
	);
}
