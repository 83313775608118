import React from 'react'
import { Table } from 'semantic-ui-react'

export default function PaydocuDetailedPageBSC({
  worker,
  dayWorker,
  workerPersonalEvaluationView,
  jobImproveProposal,
  qualityOfWork,
  amounOfWork,
  supportColleague,
  attenanceManage,
  supportCumstomer,
  selfStudy,
  reportAttitude,
  jobImproveProposalComments,
  qualityOfWorkComments,
  amounOfWorkComments,
  supportColleagueComments,
  attenanceManageComments,
  supportCumstomerComments,
  selfStudyComments,
  reportAttitudeComments,
}) {
  return (
    <>
      {
        dayWorker ? null :
        <>
          {workerPersonalEvaluationView === '직원 임금명세서에 표시하지 않고 내부 의시결정에 활용'
            ? 
              null
            : 
              <>
                <Table.Row>
                  <Table.Cell colSpan='9'><span style={{color:'darkcyan', fontWeight:'bold'}}>{`1-1. ${worker} BSC 기반 업무 수행 기록`}</span></Table.Cell>
                </Table.Row>
                <Table.Row>
                  <Table.Cell style={{ width: '11.11%', textAlign: 'center' }}>업무개선/제안</Table.Cell>
                  <Table.Cell style={{ width: '11.11%', textAlign: 'center' }}>근무 자립도</Table.Cell>
                  <Table.Cell style={{ width: '11.11%', textAlign: 'center' }}>근무 수행도</Table.Cell>
                  <Table.Cell style={{ width: '11.11%', textAlign: 'center' }}>동료업무지원<br />(*선후배 포함)</Table.Cell>
                  <Table.Cell style={{ width: '11.11%', textAlign: 'center' }}>근태현황</Table.Cell>
                  <Table.Cell style={{ width: '11.11%', textAlign: 'center' }}>고객지원</Table.Cell>
                  <Table.Cell style={{ width: '11.11%', textAlign: 'center' }}>자기개발</Table.Cell>
                  <Table.Cell style={{ width: '11.11%', textAlign: 'center' }}>커뮤니케이션</Table.Cell>
                  <Table.Cell style={{ width: '11.11%', textAlign: 'center' }}></Table.Cell>
                </Table.Row>
                <Table.Row>
                  <Table.Cell style={{ width: '11.11%', textAlign: 'center' }}>{jobImproveProposal}</Table.Cell>
                  <Table.Cell style={{ width: '11.11%', textAlign: 'center' }}>{qualityOfWork}</Table.Cell>
                  <Table.Cell style={{ width: '11.11%', textAlign: 'center' }}>{amounOfWork}</Table.Cell>
                  <Table.Cell style={{ width: '11.11%', textAlign: 'center' }}>{supportColleague}</Table.Cell>
                  <Table.Cell style={{ width: '11.11%', textAlign: 'center' }}>{attenanceManage}</Table.Cell>
                  <Table.Cell style={{ width: '11.11%', textAlign: 'center' }}>{supportCumstomer}</Table.Cell>
                  <Table.Cell style={{ width: '11.11%', textAlign: 'center' }}>{selfStudy}</Table.Cell>
                  <Table.Cell style={{ width: '11.11%', textAlign: 'center' }}>{reportAttitude}</Table.Cell>
                  <Table.Cell style={{ width: '11.11%', textAlign: 'center' }}></Table.Cell>
                </Table.Row>
                {jobImproveProposalComments ?  
                  <Table.Row>
                    <Table.Cell colSpan='2'>
                      <label>업무개선제안 Comments</label>
                    </Table.Cell>
                    <Table.Cell textAlign='center' colSpan='7' >
                      {jobImproveProposalComments}
                    </Table.Cell>
                  </Table.Row>
                  : null
                }
                {qualityOfWorkComments ?  
                  <Table.Row>
                    <Table.Cell colSpan='2'>
                      <label>업무자립도 Comments</label>
                    </Table.Cell>
                    <Table.Cell textAlign='center' colSpan='7' >
                      {qualityOfWorkComments}
                    </Table.Cell>
                  </Table.Row>
                  : null
                }
                {amounOfWorkComments ?  
                  <Table.Row>
                    <Table.Cell colSpan='2'>
                      <label>업무수행률 Comments</label>
                    </Table.Cell>
                    <Table.Cell textAlign='center' colSpan='7' >
                      {amounOfWorkComments}
                    </Table.Cell>
                  </Table.Row>
                  : null
                }
                {supportColleagueComments ?  
                  <Table.Row>
                    <Table.Cell colSpan='2'>
                      <label>동료업무지원 Comments</label>
                    </Table.Cell>
                    <Table.Cell textAlign='center' colSpan='7' >
                      {supportColleagueComments}
                    </Table.Cell>
                  </Table.Row>
                  : null
                }
                {attenanceManageComments ?  
                  <Table.Row>
                    <Table.Cell colSpan='2'>
                      <label>근태현황 Comments</label>
                    </Table.Cell>
                    <Table.Cell textAlign='center' colSpan='7' >
                      {attenanceManageComments}
                    </Table.Cell>
                  </Table.Row>
                  : null
                }
                {supportCumstomerComments ?  
                  <Table.Row>
                    <Table.Cell colSpan='2'>
                      <label>고객/동료지향 Comments</label>
                    </Table.Cell>
                    <Table.Cell textAlign='center' colSpan='7' >
                      {supportCumstomerComments}
                    </Table.Cell>
                  </Table.Row>
                  : null
                }
                {selfStudyComments ?  
                  <Table.Row>
                    <Table.Cell colSpan='2'>
                      <label>자기개발 Comments</label>
                    </Table.Cell>
                    <Table.Cell textAlign='center' colSpan='7' >
                      {selfStudyComments}
                    </Table.Cell>
                  </Table.Row>
                  : null
                }
                {reportAttitudeComments ?  
                  <Table.Row>
                    <Table.Cell colSpan='2'>
                      <label>커뮤니케이션 Comments</label>
                    </Table.Cell>
                    <Table.Cell textAlign='center' colSpan='7' >
                      {reportAttitudeComments}
                    </Table.Cell>
                  </Table.Row>
                  : null
                }
              </>
          }
        </>
      }
    </>
  )
}
