import React from 'react'
import { Table } from 'semantic-ui-react'
import { decrypto } from '../../../app/firestore/firestoreService'
import { numberToCommaString, roundUp2 } from '../../../app/common/util/util'

export default function PaydocuDetailedPageBasicRegularWorker({
  isBizWorker,
  worker,
  workerSocialNumberFront,
  monthOWT,
  monthNWT,
  monthHoliBasicTime,
  monthOWTHoli,
  monthNWTHoli,
  annualLeavesIncludeMonthWageTime,
  monthLBTime,
  hourOrdinaryWage,
}) {

  return (
    <>
      <Table.Row>
        <Table.Cell style={{ width: '11.11%', textAlign: 'center' }}>성명(생년월일)</Table.Cell>
        {isBizWorker ? null :
          <>
            <Table.Cell style={{ width: '11.11%', textAlign: 'center' }}>기본근로시간</Table.Cell>
            <Table.Cell style={{ width: '11.11%', textAlign: 'center' }}>통상시급</Table.Cell>
            { monthOWT > 0 ? 
            <Table.Cell style={{ width: '11.11%', textAlign: 'center' }}>약정연장시간</Table.Cell> : null}
            { monthNWT > 0 ? 
            <Table.Cell style={{ width: '11.11%', textAlign: 'center' }}>약정야간시간</Table.Cell> : null}
            { monthHoliBasicTime > 0 ? 
            <Table.Cell style={{ width: '11.11%', textAlign: 'center' }}>약정휴일기본</Table.Cell> : null}
            { monthOWTHoli > 0 ? 
            <Table.Cell style={{ width: '11.11%', textAlign: 'center' }}>약정휴일연장</Table.Cell> : null}
            { monthNWTHoli > 0 ? 
            <Table.Cell style={{ width: '11.11%', textAlign: 'center' }}>약정휴일야간</Table.Cell> : null}
            {annualLeavesIncludeMonthWageTime ?
            <Table.Cell style={{ width: '11.11%', textAlign: 'center' }}>유급연차시간</Table.Cell> : null}
            {!monthOWT ? <Table.Cell></Table.Cell> : null }
            {!monthNWT ? <Table.Cell></Table.Cell> : null }
            {!monthHoliBasicTime ? <Table.Cell></Table.Cell> : null }
            {!monthOWTHoli ? <Table.Cell></Table.Cell> : null }
            {!monthNWTHoli ? <Table.Cell></Table.Cell> : null }
            {!annualLeavesIncludeMonthWageTime ? <Table.Cell></Table.Cell> : null }
          </>
        }
      </Table.Row>
      <Table.Row>
        <Table.Cell style={{ width: '11.11%', textAlign: 'center' }}>{worker} <br /> ({workerSocialNumberFront.length > 6 ? decrypto(workerSocialNumberFront, String(process.env.CRYPTO_KEY)) : workerSocialNumberFront})</Table.Cell>
        {isBizWorker ? null :
          <>
            <Table.Cell style={{ width: '11.11%', textAlign: 'center' }}>{monthLBTime}</Table.Cell>                    
            <Table.Cell style={{ width: '11.11%', textAlign: 'center' }}>{numberToCommaString(roundUp2(hourOrdinaryWage))}</Table.Cell>
            {monthOWT > 0 ? 
            <Table.Cell style={{ width: '11.11%', textAlign: 'center' }}>{numberToCommaString(roundUp2(monthOWT))}</Table.Cell> : null}
            {monthNWT > 0 ? 
            <Table.Cell style={{ width: '11.11%', textAlign: 'center' }}>{numberToCommaString(roundUp2(monthNWT))}</Table.Cell> : null}
            {monthHoliBasicTime > 0 ? 
            <Table.Cell style={{ width: '11.11%', textAlign: 'center' }}>{numberToCommaString(roundUp2(monthHoliBasicTime))}</Table.Cell> : null}
            {monthOWTHoli > 0 ? 
            <Table.Cell style={{ width: '11.11%', textAlign: 'center' }}>{numberToCommaString(roundUp2(monthOWTHoli))}</Table.Cell> : null}
            {monthNWTHoli > 0 ? 
            <Table.Cell style={{ width: '11.11%', textAlign: 'center' }}>{numberToCommaString(roundUp2(monthNWTHoli))}</Table.Cell> : null}
            {annualLeavesIncludeMonthWageTime ? 
            <Table.Cell style={{ width: '11.11%', textAlign: 'center' }}>{roundUp2(annualLeavesIncludeMonthWageTime)}</Table.Cell> : null}
            {!monthOWT ? <Table.Cell></Table.Cell> : null }
            {!monthNWT ? <Table.Cell></Table.Cell> : null }
            {!monthHoliBasicTime ? <Table.Cell></Table.Cell> : null }
            {!monthOWTHoli ? <Table.Cell></Table.Cell> : null }
            {!monthNWTHoli ? <Table.Cell></Table.Cell> : null }
            {!annualLeavesIncludeMonthWageTime ? <Table.Cell></Table.Cell> : null }
          </>
        }
      </Table.Row>
  </>
  )
}
