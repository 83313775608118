import React, { useEffect, useState } from 'react';
import { Button, Table } from 'semantic-ui-react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { format } from 'date-fns';
import * as XLSX from 'xlsx';
import {
	numberToCommaString,
	roundUp,
	sumOfMonthDeductedWagesCom,
	sumOfMonthDeductedWagesWorker,
	sumOfMonthWagesWorker,
	sumOfRetirementPensionDCMonthsCom,
	sumOfTotalMonthWageAmountsCom,
	sumOfUsedALsWorker,
} from '../../../app/common/util/util';

export default function PaydocusTableBankList({
	paydocus,
	searchResults,
	setSearchResults,
	searchTerm,
	year,
	month,
}) {
	const { currentUserProfile } = useSelector((state) => state.profile);
	const { clientInfo } = useSelector((state) => state.companyReducer);
	const [loading, setLoading] = useState(true);
	const id = clientInfo?.id
		? clientInfo?.id
		: clientInfo?.clientUid
		? clientInfo?.clientUid
		: currentUserProfile?.id;

	useEffect(() => {
		setLoading(true);
		const searchedPaydocus = paydocus.filter((paydocu) => {
			return (
				paydocu?.workerInfo?.worker?.includes(searchTerm) ||
				paydocu?.companyInfo?.companyName?.includes(searchTerm)
			);
			// || paydocu?.createdAt?.includes(searchTerm)
		});
		setSearchResults(searchedPaydocus);
		setLoading(false);
	}, [searchTerm, setSearchResults, paydocus]);

	const exportToExcel = () => {
		const title = retirementPayDC
			? `${companyName} ${year}년 ${month}월 급여이체, DC 퇴직연금 대장`
			: `${companyName} ${year}년 ${month}월 급여이체 대장`;

		// Shorten sheet name if it's too long
		const sheetName = title.length > 31 ? title.slice(0, 31) : title;

		// Create a new worksheet with the title
		const workSheet = XLSX.utils.aoa_to_sheet([[title]]);
		const tableSheet = XLSX.utils.table_to_sheet(
			document.getElementById('table-to-xls')
		);

		// Determine the number of columns in the table
		const ref = tableSheet['!ref'];
		const totalColumns = XLSX.utils.decode_range(ref).e.c + 1;

		// Merge the title cell across the width of the table
		workSheet['!merges'] = [
			{ s: { r: 0, c: 0 }, e: { r: 0, c: totalColumns - 1 } },
		];

		// Style the title cell
		const range = XLSX.utils.decode_range(workSheet['!ref']);
		for (let C = range.s.c; C <= range.e.c; ++C) {
			const address = XLSX.utils.encode_col(C) + '1';
			if (!workSheet[address]) workSheet[address] = { t: 's', v: title };
			workSheet[address].s = {
				font: { bold: true, sz: 14 },
				alignment: { vertical: 'center', horizontal: 'center' },
			};
		}

		// Append the table data below the title
		XLSX.utils.sheet_add_aoa(
			workSheet,
			XLSX.utils.sheet_to_json(tableSheet, { header: 1 }),
			{ origin: 'A2' }
		);

		// Create a new workbook and append the worksheet
		const workBook = XLSX.utils.book_new();
		XLSX.utils.book_append_sheet(workBook, workSheet, sheetName);

		// Write the workbook to file
		XLSX.writeFile(workBook, `${title}.xlsx`);
	};

	const authLevel = clientInfo?.authLevel
		? clientInfo?.authLevel
		: currentUserProfile?.authLevel;
	const companyName = clientInfo?.companyName
		? clientInfo?.companyName
		: currentUserProfile?.companyName;

	const retirementPayDC =
		clientInfo?.retirementPay === 'DC형 퇴직연금'
			? true
			: currentUserProfile?.retirementPay === 'DC형 퇴직연금'
			? true
			: false;

	if (loading) {
		return <div>Loading...</div>;
	}

	const title = retirementPayDC
		? `${companyName} ${year}년 ${month}월 급여이체, DC 퇴직연금 대장 Excel`
		: `${companyName} ${year}년 ${month}월 급여이체 대장 Excel`;

	return (
		<>
			{paydocus.length !== 0 && (
				<>
					<Button onClick={exportToExcel} color='blue'>
						{title}
					</Button>
					<br />
					<br />
					{!currentUserProfile.ceoName ? (
						<Table border='1' width='100%' align='center' id='table-to-xls'>
							<Table.Header>
								<Table.Row>
									<Table.HeaderCell>년월</Table.HeaderCell>
									<Table.HeaderCell>사번</Table.HeaderCell>
									<Table.HeaderCell>직원명</Table.HeaderCell>
									<Table.HeaderCell>공제 전 임금(월)</Table.HeaderCell>
									<Table.HeaderCell>공제 후 임금(원)</Table.HeaderCell>
									<Table.HeaderCell>사용연차(일)</Table.HeaderCell>
									<Table.HeaderCell>회사등록일</Table.HeaderCell>
									<Table.HeaderCell>상세보기</Table.HeaderCell>
								</Table.Row>
							</Table.Header>
							<Table.Body>
								{paydocus.map((workerData) => (
									<Table.Row key={workerData.id}>
										<Table.Cell>{workerData?.info?.yearMonth}</Table.Cell>
										<Table.Cell>
											{workerData?.workerInfo?.workerComNumber}
										</Table.Cell>
										<Table.Cell>{workerData?.workerInfo?.worker}</Table.Cell>
										<Table.Cell>
											{numberToCommaString(
												workerData?.info?.totalMonthWageAmount
											)}
										</Table.Cell>
										<Table.Cell>
											{numberToCommaString(workerData?.info?.deductWage)}
										</Table.Cell>
										<Table.Cell>{workerData?.usedAnnualLeaves}</Table.Cell>
										<Table.Cell>
											{workerData?.createdAt
												? format(
														new Date(workerData?.createdAt),
														'yyyy-MM-dd h:mm a'
												  )
												: null}
										</Table.Cell>
										<Table.Cell>
											<Button
												as={Link}
												to={`/paydocuDetailedPage/${workerData?.id}`}
												color='orange'
												floated='right'>
												임금명세서 보기
											</Button>
										</Table.Cell>
									</Table.Row>
								))}
							</Table.Body>
							<Table.Footer>
								<Table.Row>
									<Table.Cell>합계</Table.Cell>
									<Table.Cell></Table.Cell>
									<Table.Cell>
										{numberToCommaString(sumOfMonthWagesWorker(paydocus))}원
									</Table.Cell>
									<Table.Cell>
										{numberToCommaString(
											sumOfMonthDeductedWagesWorker(paydocus)
										)}
										원
									</Table.Cell>
									<Table.Cell>{sumOfUsedALsWorker(paydocus)}일</Table.Cell>
									<Table.Cell></Table.Cell>
									<Table.Cell></Table.Cell>
									<Table.Cell></Table.Cell>
								</Table.Row>
							</Table.Footer>
						</Table>
					) : (
						<div
							style={{
								overflowX: 'auto',
								width: '100%',
								whiteSpace: 'nowrap',
								borderCollapse: 'collapse',
							}}>
							<Table
								style={{ textAlign: 'center', width: '100%', border: '1' }}
								id='table-to-xls'>
								<Table.Header>
									<Table.Row>
										{authLevel < 100 ? null : (
											<Table.HeaderCell>회사명</Table.HeaderCell>
										)}
										{/* <Table.HeaderCell>년월</Table.HeaderCell> */}
										<Table.HeaderCell>년</Table.HeaderCell>
										<Table.HeaderCell>월</Table.HeaderCell>
										<Table.HeaderCell>사번</Table.HeaderCell>
										{/* <Table.HeaderCell>부서명</Table.HeaderCell>
                    <Table.HeaderCell>직위/직책</Table.HeaderCell> */}
										<Table.HeaderCell>직원명</Table.HeaderCell>
										<Table.HeaderCell>은행명</Table.HeaderCell>
										<Table.HeaderCell>계좌번호</Table.HeaderCell>
										{/* <Table.HeaderCell>입사일</Table.HeaderCell>
                    <Table.HeaderCell>마지막 <br /> 근무일</Table.HeaderCell> */}
										<Table.HeaderCell>실수령액</Table.HeaderCell>
										{retirementPayDC ? (
											<Table.HeaderCell>DC형 퇴직연금액</Table.HeaderCell>
										) : null}
										<Table.HeaderCell>수령확인</Table.HeaderCell>
									</Table.Row>
								</Table.Header>
								<Table.Body>
									{searchResults.map(
										(searchResult, index) =>
											(searchResult?.companyInfo?.agentUid === id ||
												searchResult.companyInfo.companyId === id ||
												currentUserProfile?.authLevel >= 100) && (
												<React.Fragment key={searchResult.id}>
													{searchResult?.workerInfo?.dayWorker ===
													true ? null : (
														<Table.Row key={searchResult.id + '_row'}>
															{authLevel < 100 ? null : (
																<Table.Cell>
																	{searchResult?.companyInfo?.companyName}
																</Table.Cell>
															)}
															{/* <Table.Cell>{searchResult?.info?.yearMonth}</Table.Cell> */}
															<Table.Cell>
																{searchResult?.info?.year}
															</Table.Cell>
															<Table.Cell>
																{searchResult?.info?.month}
															</Table.Cell>
															<Table.Cell>
																{searchResult?.workerInfo?.workerComNumber}
															</Table.Cell>
															{/* <Table.Cell>{searchResult?.workerInfo?.workerDivisions}</Table.Cell>
                          <Table.Cell>{searchResult?.workerInfo?.workerTitles}</Table.Cell> */}
															<Table.Cell>
																{searchResult?.workerInfo?.worker}
															</Table.Cell>
															<Table.Cell>
																{searchResult?.workerInfo?.workerBank}
															</Table.Cell>
															<Table.Cell>
																{searchResult?.workerInfo?.workerBankAccount}
															</Table.Cell>

															{/* <Table.Cell>{searchtotalMonthWageAmountMinusPlusSpecialRandomWageResult?.workerInfo?.workerEnteredDate ? format(new Date(searchResult?.workerInfo?.workerEnteredDate), 'yyyy-MM-dd') : ''}</Table.Cell>
                          <Table.Cell>{searchResult?.workerInfo?.workerRetiredDate ? format(new Date(searchResult?.workerInfo?.workerRetiredDate), 'yyyy-MM-dd') : ''}</Table.Cell> */}
															<Table.Cell>
																{numberToCommaString(
																	searchResult?.info?.deductWage
																)}
															</Table.Cell>
															{retirementPayDC ? (
																<Table.Cell>
																	{numberToCommaString(
																		roundUp(
																			searchResult?.fixedWage
																				?.retirementPensionDCMonth
																				? searchResult?.fixedWage
																						?.retirementPensionDCMonth
																				: searchResult?.info
																						?.totalMonthWageAmountMinusPlusSpecialRandomWage /
																						12
																		)
																	)}
																</Table.Cell>
															) : null}
															<Table.Cell></Table.Cell>
														</Table.Row>
													)}
												</React.Fragment>
											)
									)}
								</Table.Body>
								<Table.Body>
									<Table.Row>
										{authLevel < 100 ? (
											<Table.Cell colSpan='6'>합계</Table.Cell>
										) : (
											<Table.Cell colSpan='7'>합계</Table.Cell>
										)}
										<Table.Cell>
											{numberToCommaString(
												sumOfMonthDeductedWagesCom(searchResults)
											)}
										</Table.Cell>
										{retirementPayDC ? (
											<Table.Cell>
												{numberToCommaString(
													roundUp(
														sumOfRetirementPensionDCMonthsCom(searchResults)
															? sumOfRetirementPensionDCMonthsCom(searchResults)
															: sumOfTotalMonthWageAmountsCom(searchResults) /
																	12
													)
												)}
											</Table.Cell>
										) : null}
									</Table.Row>
								</Table.Body>
							</Table>
						</div>
					)}
				</>
			)}
		</>
	);
}
