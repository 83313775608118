import React from 'react'
import { Table } from 'semantic-ui-react'

export default function ClientReqDLCWageTableDayWorkerDetailAWage7({
  gumakStyle,
  centerStyle,
  inputWageStyle,
  inputCommentStyle,
  dayWorkerCAWage7,
  dayWorkerCAWEName7,
  dayWorkerCAWage7Comment,
  handelInputChange
}) {

  return (
    <>
      <Table.Cell className='gubun' style={centerStyle}>평균임금</Table.Cell>
      <Table.Cell className='gumak' style={gumakStyle}><input style={inputWageStyle} name='dayWorkerCAWEName7' value = {dayWorkerCAWEName7} onChange= {(e) => handelInputChange(e)} /></Table.Cell>
      <Table.Cell className='gumak' style={gumakStyle}><input style={inputWageStyle} name='dayWorkerCAWage7' value = {dayWorkerCAWage7} onChange= {(e) => handelInputChange(e)} /></Table.Cell>
      <Table.Cell colSpan='7' className='sulmung'><textarea style={inputCommentStyle} name='dayWorkerCAWage7Comment' value = { dayWorkerCAWage7Comment } onChange = {(e) => handelInputChange(e)}/></Table.Cell>
    </>
  )
}
