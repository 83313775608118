import React from 'react';
import { Table } from 'semantic-ui-react';
import {
  holiBasicTimeText,
  holiNightTimeText,
  holiOverTimeText,
  nightTimeText,
  numberToCommaString,
  overTimeText,
  roundUp,
  roundUp2,
} from '../../../app/common/util/util';

export default function PaydocuPayDetailDayWorker({
  gumakStyle,
  numOfWorkers,
  centerStyle,
  totalDayWorkerWage,
  dayWorkerWage,
  countOfNomalDayWork,
  countOfHoliDayWork,
  totalDayWorkerHoliWage,
  dayWorkerHoliWage,
  totalDayWorkerOverWorkDayWage,
  countOfOverWorkingDayWork,
  dayWorkerOverWorkDayWage,
  dayWorkerCAWage5,
  dayWorkerCAWEName5,
  dayWorkerCAWage5Comment,
  dayWorkerCAWage6,
  dayWorkerCAWEName6,
  dayWorkerCAWage6Comment,
  dayWorkerCAWage7,
  dayWorkerCAWEName7,
  dayWorkerCAWage7Comment,
  dayWorkerPlusOTime,
  numDayWorkerOWage,
  dayWokerTimeWage,
  dayWorkerPlusNTime,
  numDayWorkerNWage,
  dayWorkerPlusHBTime,
  numDayWorkerHBwage,
  dayWorkerPlusHOTime,
  numDayWorkerHOwage,
  dayWorkerPlusHNTime,
  numDayWorkerHNwage,
  dayWorkerAbsentTimes,
  dayWorkerAbsentTimeWage,
  dayWorkerSpecialBonus,
  week14OverBLTime,
  numPaidHoliDayW,
  finalPaidHoliWageTime,
  finalPaidHoliWageTimeSum,
  normalWorkerWeekWorkDays,
  paidHoliWageDays,
  paidHoliWageText,
}) {
  return (
    <>
      {week14OverBLTime === '주휴수당 포함' ? (
        <Table.Row>
          <Table.Cell className="gubun" style={centerStyle}>평일</Table.Cell>
          <Table.Cell className="hangmok" style={centerStyle}>임금(주휴포함)</Table.Cell>
          <Table.Cell className="gumak" style={gumakStyle}>
            {numberToCommaString(roundUp(totalDayWorkerWage))}
          </Table.Cell>
          <Table.Cell colSpan="6" className="sulmung">
            {numberToCommaString(roundUp(dayWorkerWage))}원 * 평일 {countOfNomalDayWork} 일
          </Table.Cell>
        </Table.Row>
      ) : (
        <>
          <Table.Row>
            <Table.Cell className="gubun" style={centerStyle}>평일</Table.Cell>
            <Table.Cell className="hangmok" style={centerStyle}>임금</Table.Cell>
            <Table.Cell className="gumak" style={gumakStyle}>
              {numberToCommaString(roundUp(totalDayWorkerWage))}
            </Table.Cell>
            <Table.Cell colSpan="6" className="sulmung">
              {numberToCommaString(roundUp(dayWorkerWage))}원 * 평일 {countOfNomalDayWork} 일
            </Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell className="gubun" style={centerStyle}>주휴수당</Table.Cell>
            <Table.Cell className="hangmok" style={centerStyle}>임금</Table.Cell>
            <Table.Cell className="gumak" style={gumakStyle}>
              {numberToCommaString(roundUp(numPaidHoliDayW))}
            </Table.Cell>
            {numPaidHoliDayW > 0 ? (
              <Table.Cell colSpan="6" className="sulmung">
                통상시급{numberToCommaString(roundUp(dayWokerTimeWage))}원 * 총 주휴시간 {roundUp2(finalPaidHoliWageTime)}시간
                <br />
                {`[(일급제(단시간) 근로자 4주 소정근로시간 (${numberToCommaString(roundUp(finalPaidHoliWageTimeSum))})) / (통상근로자 4주 소정근무일(${normalWorkerWeekWorkDays ? normalWorkerWeekWorkDays : 5})) * 주휴일수 (${paidHoliWageDays})]`}
                <br />
                {paidHoliWageText}
              </Table.Cell>
            ) : (
              <Table.Cell colSpan="6">1주 15시간을 초과하지 않습니다.</Table.Cell>
            )}
          </Table.Row>
        </>
      )}
      {countOfHoliDayWork ? (
        <Table.Row>
          <Table.Cell style={{ width: '11.11%', textAlign: 'center' }}>휴일</Table.Cell>
          <Table.Cell style={{ width: '11.11%', textAlign: 'center' }}>임금</Table.Cell>
          <Table.Cell style={{ width: '11.11%', textAlign: 'right' }}>
            {numberToCommaString(roundUp(totalDayWorkerHoliWage))}
          </Table.Cell>
          <Table.Cell colSpan="6" className="sulmung">
            {numberToCommaString(roundUp(dayWorkerHoliWage))}원 * 휴일 {countOfHoliDayWork} 일
          </Table.Cell>
        </Table.Row>
      ) : null}
      {countOfOverWorkingDayWork ? (
        <Table.Row>
          <Table.Cell style={{ width: '11.11%', textAlign: 'center' }}>휴무일</Table.Cell>
          <Table.Cell style={{ width: '11.11%', textAlign: 'center' }}>임금</Table.Cell>
          <Table.Cell style={{ width: '11.11%', textAlign: 'right' }}>
            {numberToCommaString(roundUp(totalDayWorkerOverWorkDayWage))}
          </Table.Cell>
          <Table.Cell colSpan="6" className="sulmung">
            {numberToCommaString(roundUp(dayWorkerOverWorkDayWage))}원 * 휴무일 {countOfOverWorkingDayWork} 일
          </Table.Cell>
        </Table.Row>
      ) : null}
      {dayWorkerCAWage5 && (
        <Table.Row>
          <Table.Cell style={{ width: '11.11%', textAlign: 'center' }}>월정액<br />평균임금</Table.Cell>
          <Table.Cell style={{ width: '11.11%', textAlign: 'center' }}>{dayWorkerCAWEName5}</Table.Cell>
          <Table.Cell style={{ width: '11.11%', textAlign: 'right' }}>
            {numberToCommaString(dayWorkerCAWage5)}
          </Table.Cell>
          <Table.Cell colSpan="6" className="sulmung">{dayWorkerCAWage5Comment}</Table.Cell>
        </Table.Row>
      )}
      {dayWorkerCAWage6 && (
        <Table.Row>
          <Table.Cell style={{ width: '11.11%', textAlign: 'center' }}>월정액<br />평균임금</Table.Cell>
          <Table.Cell style={{ width: '11.11%', textAlign: 'center' }}>{dayWorkerCAWEName6}</Table.Cell>
          <Table.Cell style={{ width: '11.11%', textAlign: 'right' }}>
            {numberToCommaString(dayWorkerCAWage6)}
          </Table.Cell>
          <Table.Cell colSpan="6" className="sulmung">{dayWorkerCAWage6Comment}</Table.Cell>
        </Table.Row>
      )}
      {dayWorkerCAWage7 && (
        <Table.Row>
          <Table.Cell style={{ width: '11.11%', textAlign: 'center' }}>월정액<br />평균임금</Table.Cell>
          <Table.Cell style={{ width: '11.11%', textAlign: 'center' }}>{dayWorkerCAWEName7}</Table.Cell>
          <Table.Cell style={{ width: '11.11%', textAlign: 'right' }}>
            {numberToCommaString(dayWorkerCAWage7)}
          </Table.Cell>
          <Table.Cell colSpan="6" className="sulmung">{dayWorkerCAWage7Comment}</Table.Cell>
        </Table.Row>
      )}
      {dayWorkerPlusOTime && (
        <Table.Row>
          <Table.Cell style={{ width: '11.11%', textAlign: 'center' }}>평균임금</Table.Cell>
          <Table.Cell style={{ width: '11.11%', textAlign: 'center' }}>
            {numOfWorkers >= 5 ? '시간외수당' : '초과수당'}
          </Table.Cell>
          <Table.Cell style={{ width: '11.11%', textAlign: 'right' }}>{numDayWorkerOWage}</Table.Cell>
          <Table.Cell colSpan="6" className="sulmung">
            {overTimeText(0, dayWorkerPlusOTime, dayWokerTimeWage, numOfWorkers)}
          </Table.Cell>
        </Table.Row>
      )}
      {dayWorkerPlusNTime && (
        <Table.Row>
          <Table.Cell style={{ width: '11.11%', textAlign: 'center' }}>평균임금</Table.Cell>
          <Table.Cell style={{ width: '11.11%', textAlign: 'center' }}>야간수당</Table.Cell>
          <Table.Cell style={{ width: '11.11%', textAlign: 'right' }}>{numDayWorkerNWage}</Table.Cell>
          <Table.Cell colSpan="6" className="sulmung">
            {nightTimeText(0, dayWorkerPlusNTime, dayWokerTimeWage, numOfWorkers)}
          </Table.Cell>
        </Table.Row>
      )}
      {dayWorkerPlusHBTime && (
        <Table.Row>
          <Table.Cell style={{ width: '11.11%', textAlign: 'center' }}>평균임금</Table.Cell>
          <Table.Cell style={{ width: '11.11%', textAlign: 'center' }}>휴일 기본수당</Table.Cell>
          <Table.Cell style={{ width: '11.11%', textAlign: 'right' }}>{numDayWorkerHBwage}</Table.Cell>
          <Table.Cell colSpan="6" className="sulmung">
            {holiBasicTimeText(0, dayWorkerPlusHBTime, dayWokerTimeWage, numOfWorkers)}
          </Table.Cell>
        </Table.Row>
      )}
      {dayWorkerPlusHOTime && (
        <Table.Row>
          <Table.Cell style={{ width: '11.11%', textAlign: 'center' }}>평균임금</Table.Cell>
          <Table.Cell style={{ width: '11.11%', textAlign: 'center' }}>휴일 연장수당</Table.Cell>
          <Table.Cell style={{ width: '11.11%', textAlign: 'right' }}>{numDayWorkerHOwage}</Table.Cell>
          <Table.Cell colSpan="6" className="sulmung">
            {holiOverTimeText(0, dayWorkerPlusHOTime, dayWokerTimeWage, numOfWorkers)}
          </Table.Cell>
        </Table.Row>
      )}
      {dayWorkerPlusHNTime > 0 && (
        <Table.Row>
          <Table.Cell style={{ width: '11.11%', textAlign: 'center' }}>평균임금</Table.Cell>
          <Table.Cell style={{ width: '11.11%', textAlign: 'center' }}>휴일 야간수당</Table.Cell>
          <Table.Cell style={{ width: '11.11%', textAlign: 'right' }}>{numDayWorkerHNwage}</Table.Cell>
          <Table.Cell colSpan="6" className="sulmung">
            {holiNightTimeText(0, dayWorkerPlusHNTime, dayWokerTimeWage, numOfWorkers)}
          </Table.Cell>
        </Table.Row>
      )}
      {dayWorkerAbsentTimes && (
        <Table.Row>
          <Table.Cell style={{ width: '11.11%', textAlign: 'center' }}>통상임금</Table.Cell>
          <Table.Cell style={{ width: '11.11%', textAlign: 'center' }}>지각 등 <br /> 시간공제</Table.Cell>
          <Table.Cell style={{ width: '11.11%', textAlign: 'right' }}>
            <span style={{ color: 'red' }}>-{numberToCommaString(dayWorkerAbsentTimeWage)}</span>
          </Table.Cell>
          <Table.Cell colSpan="6" className="sulmung">
            {`지각, 조퇴, 외출 등 공제 ${numberToCommaString(dayWorkerAbsentTimeWage)}시간 * 시급 ${numberToCommaString(roundUp2(dayWokerTimeWage))}`}
          </Table.Cell>
        </Table.Row>
      )}
      {dayWorkerSpecialBonus && (
        <Table.Row>
          <Table.Cell style={{ width: '11.11%', textAlign: 'center' }}>특별금품</Table.Cell>
          <Table.Cell style={{ width: '11.11%', textAlign: 'center' }}>특별상여금</Table.Cell>
          <Table.Cell style={{ width: '11.11%', textAlign: 'right' }}>
            {numberToCommaString(dayWorkerSpecialBonus)}
          </Table.Cell>
          <Table.Cell colSpan="6" className="sulmung">
            근로의 댓가와 관계없는 은혜적 특별 상여금(평균임금에도 포함되지 않음)
          </Table.Cell>
        </Table.Row>
      )}
    </>
  );
}
