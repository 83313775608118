// Client side
export const CREATE_CLIENTREQ_4IN = 'CREATE_CLIENTREQ_4IN';
export const UPDATE_CLIENTREQ_4IN = 'UPDATE_CLIENTREQ_4IN';
export const DELETE_CLIENTREQ_4IN = 'DELETE_CLIENTREQ_4IN';
export const FETCH_CLIENTREQS_4IN = 'FETCH_CLIENTREQS_4IN';


export const LISTEN_TO_SELECTED_CLIENTREQ_4IN = 'LISTEN_TO_SELECTED_CLIENTREQ_4IN';
export const CLEAR_SELECTED_CLIENTREQ_4IN = 'CLEAR_SELECTED_CLIENTREQ_4IN';


export const LISTEN_TO_CLIENTREQ_CHAT_4IN = 'LISTEN_TO_CLIENTREQ_CHAT_4IN';
export const CLEAR_COMMENTS_4IN = 'CLEAR_COMMENTS_4IN';
export const CLEAR_CLIENTREQS_4IN = 'CLEAR_CLIENTREQS_4IN';
export const SET_FILTER_CLIENTREQ_4IN = 'SET_FILTER_CLIENTREQ_4IN'; 
export const SET_START_DATE_CLIENTREQ_4IN = 'SET_START_DATE_CLIENTREQ_4IN'; 
export const RETAIN_STATE_CLIENTREQ_4IN = 'RETAIN_STATE_CLIENTREQ_4IN'; 

//Table for Nomusa
export const FETCH_CLIENTREQS_TABLE_4IN = 'FETCH_CLIENTREQS_TABLE_4IN';
export const CLEAR_CLIENTREQS_TABLE_4IN = 'CLEAR_CLIENTREQS_TABLE_4IN';

//Net 계산기 (세후월급 총액)
export const AFTER_TAX_WAGE = 'AFTER_TAX_WAGE';
//Net 계산기 (비과세 합계)
export const SUM_TAX_FREE_WAGE = 'SUM_TAX_FREE_WAGE';

export const AFTER_PENSION_BASE_WAGE = 'AFTER_PENSION_BASE_WAGE'
export const AFTER_HEALTHCARE_BASE_WAGE = 'AFTER_HEALTHCARE_BASE_WAGE'
export const AFTER_NOJOB_BASE_WAGE = 'AFTER_NOJOB_BASE_WAGE'
export const AFTER_NUM_OF_TAX_FAMILY = 'AFTER_NUM_OF_TAX_FAMILY'

export const AFTER_NUM_OF_TAX_FAMILY_7_TO_20 = 'AFTER_NUM_OF_TAX_FAMILY_7_TO_20'


export const FINAL_RETIRE_FUND = 'FINAL_RETIRE_FUND'
export const RETIRE_AVERAGE_FINAL_DAY_WAGE = 'RETIRE_AVERAGE_FINAL_DAY_WAGE'
export const RETIRE_FUND = 'RETIRE_FUND'
export const FINAL_RETIRED_FUND_TAX = 'FINAL_RETIRED_FUND_TAX'
export const FINAL_RETIRED_FUND_REGION_TAX = 'FINAL_RETIRED_FUND_REGION_TAX'


export const FETCH_WORKER_USERS = 'FETCH_WORKER_USERS'
export const CLEAR_WORKER_USERS = 'CLEAR_WORKER_USERS'

export const SELECTED_WORKER_REGISTRY_TYPE = 'SELECTED_WORKER_REGISTRY_TYPE'


export const SET_ACTIVE_TAB = 'SET_ACTIVE_TAB';















//////////////////////////

// //1주 최저임금판단
// export const JUDGE_LOWEST_WAGE = 'JUDGE_LOWEST_WAGE';

// //1주 52시간제 판단
// export const JUDGE_OVER_52 = 'JUDGE_OVER_52';

// //1주 최저임금 부족 월급액 판단
// export const INSUFFICIENT_WAGE = 'INSUFFICIENT_WAGE';

// // 상시근로자수
// export const NUMBER_OF_WORKERS = 'NUMBER_OF_WORKERS';

