import React from 'react';
import { 
  roundUp2,
  days2LBTime,
  days3LBTime,
  days4LBTime,
  days5LBTime,
  days6LBTime,
  josaUnType,
  josaWaType,
  josaGaType,
} from '../../../../../app/common/util/util';

export default function ClientReqDLCDLawTime({
  netAge15To18,
  netAge,
  isExceptTimeLawWorker,
  isEtcWorker,
  weekLBTime,
  selectedClientReq4In,
  numOfWorkers, 
  companyName,
  worker,
  days1,
  days2,
  days3,
  days4,
  days5,
  days6,
  daysWT2,
  daysWT3,
  daysWT4,
  daysWT5,
  daysWT6,
  skipWeek1,
  skipWeek2,
  skipWeek3,
  skipWeek4,
  skipWeek5,
  skipWeek6,

}) {

    const isSkipWeekedWorker = (skipWeek1 || skipWeek2 || skipWeek3 || skipWeek4 || skipWeek5 || skipWeek6) ? true : false

    //첫번째 근로시간이 같은 날들의 1일 소정근로시간
    const days1LBTime = selectedClientReq4In?.realWorkTime?.days1LBTime;

    // 1일 소정근로시간
    const paidHolidayLBTime = selectedClientReq4In?.lawBase?.paidHolidayLBTime;
      // 입력된 근무일 갯수
    const days1Length = selectedClientReq4In?.realWorkTime?.days1Length;
    const days2Length = selectedClientReq4In?.realWorkTime?.days2Length;
    const days3Length = selectedClientReq4In?.realWorkTime?.days3Length;
    const days4Length = selectedClientReq4In?.realWorkTime?.days4Length;
    const days5Length = selectedClientReq4In?.realWorkTime?.days5Length;

      //회사 국어조사
    const companyUn = josaUnType(companyName);
    const companyWa = josaWaType(companyName);

    //직원 국어조사
    const workerGa = josaGaType(worker);
    const workerUn = josaUnType(worker)

  const exceptLawTimeText = '제4조 소정근로시간 적용제외'
  const lawTimeText = isSkipWeekedWorker ? '제4조 소정근로시간(2주단위 탄력적 근로시간제) 및 초과근무, 보상휴가' : '제4조 소정근로시간 및 초과근무, 보상휴가'

  return(
    <>
      {numOfWorkers >=5 
        ? 
          <div className="jau__contents">
            <span style={{fontSize : '1rem'}}><strong>{isExceptTimeLawWorker ? exceptLawTimeText : lawTimeText}</strong></span>
            <ul>
              {isEtcWorker 
                ? 
                  <>
                    <li>① 근로기준법 제50조 {isSkipWeekedWorker ? "및 제51조 제1항 2주단위 탄력적 근로시간제(2주평균)" : ''}에 의거 1주 소정근로시간 : {weekLBTime}시간, 1일 소정근로시간 : {paidHolidayLBTime}시간으로 한다.</li>
                    <li>② {companyUn} {workerGa} 제1항의 소정근로시간을 초과하여 근로한 경우 근로기준법 제56조의 연장근로 가산임금 지급하거나 또는 {companyWa} 근로자대표의 서면합의가 있는 경우에는 {worker}에게 가산임금에 갈음하여 동법 제57조 보상휴가를 부여할 수 있다.</li>
                  </>
                : 
                  <>
                    {isExceptTimeLawWorker ?
                      <li>①{companyWa} {workerUn} 근로기준법 제63조 조항에 적용됨에 따라 {companyUn} 소정로시간 관련 근로기준법 제50조(소정근로시간), 제51조(탄력적근로시간제), 제52조(선택적 근로시간제), 제53조(연장근로제한)을 적용하지 아니한다.</li>
                    :
                      <>
                        {netAge15To18 ? 
                          <li>
                              ① {workerUn} 만18세미만 연소근로자로 근로기준법 제69조  {isSkipWeekedWorker ? "및 제51조 제1항 2주단위 탄력적 근로시간제(2주평균)" : ''}에 의거 1주 소정근로시간 : {weekLBTime}시간, {days1 !== undefined && days1Length >=5 && (netAge15To18 ? days1LBTime * days1Length >= 35 : days1LBTime * days1Length >= 40)
                                ? "1일 소정근로시간 : " + days1LBTime +'시간' 
                                : days1 + "요일의 1일 소정근로시간 : " + roundUp2(days1LBTime) +'시간'
                              }
                          </li>
                        :
                          <li>
                              ① 근로기준법 제50조 {isSkipWeekedWorker ? "및 제51조 제1항 2주단위 탄력적 근로시간제(2주평균)" : ''}에 의거 1주 소정근로시간 : {weekLBTime}시간, {days1 !== undefined && days1Length >=5 && (netAge15To18 ? days1LBTime * days1Length >= 35 : days1LBTime * days1Length >= 40)
                                ? "1일 소정근로시간 : " + days1LBTime +'시간' + (skipWeek1 === true ? "(격주 근무일 기준)" : '')
                                : days1 + "요일의 1일 소정근로시간 : " + roundUp2(days1LBTime) +'시간' + (skipWeek1 === true ? "(격주 근무일 기준)" : '')
                              }
                          </li>
                        }
                        {(days2 !== undefined && days2LBTime(days1LBTime, days1Length, daysWT2) !== 0) 
                          ? <li>①-1 {days2}요일의 1일 소정근로시간 : {roundUp2(days2LBTime(days1LBTime, days1Length, daysWT2, netAge15To18))}시간{skipWeek2 === true ? "(격주 근무일 기준)" : ''}</li>
                          : null
                        }
                        {(days3 !== undefined && days3LBTime(days1LBTime, days1Length, daysWT2, days2Length, daysWT3, netAge15To18) !== 0)
                          ? <li>①-2 {days3}요일의 1일 소정근로시간 : {roundUp2(days3LBTime(days1LBTime, days1Length, daysWT2, days2Length, daysWT3, netAge15To18))}시간{skipWeek3 === true ? "(격주 근무일 기준)" : ''}</li>
                          : null
                        }
                        {(days4 !== undefined && days4LBTime(days1LBTime, days1Length, daysWT2, days2Length, daysWT3, days3Length, daysWT4, netAge15To18) !== 0)
                          ? <li>①-3 {days4}요일의 1일 소정근로시간 : {roundUp2(days4LBTime(days1LBTime, days1Length, daysWT2, days2Length, daysWT3, days3Length, daysWT4, netAge15To18))}시간{skipWeek4 === true ? "(격주 근무일 기준)" : ''}</li>
                          : null
                        }
                        {(days5 !== undefined && days5LBTime(days1LBTime, days1Length, daysWT2, days2Length, daysWT3, days3Length, daysWT4, days4Length, daysWT5, netAge15To18) !== 0)
                          ? <li>①-4 {days5}요일의 1일 소정근로시간 : {roundUp2(days5LBTime(days1LBTime, days1Length, daysWT2, days2Length, daysWT3, days3Length, daysWT4, days4Length, daysWT5, netAge15To18))}시간{skipWeek5 === true ? "(격주 근무일 기준)" : ''}</li>
                          : null
                        }
                        {(days6 !== undefined && days6LBTime(days1LBTime, days1Length, daysWT2, days2Length, daysWT3, days3Length, daysWT4, days4Length, daysWT5, days5Length, daysWT6, netAge15To18) !== 0)
                          ? <li>①-5 {days6}요일의 1일 소정근로시간 : {roundUp2(days6LBTime(days1LBTime, days1Length, daysWT2, days2Length, daysWT3, days3Length, daysWT4, days4Length, daysWT5, days5Length, daysWT6, netAge))}시간{skipWeek6 === true ? "(격주 근무일 기준)" : ''}</li>
                          : null
                        }
                        <li>② {companyUn} {workerGa} 제1항의 소정근로시간을 초과하여 근로한 경우 근로기준법 제56조의 연장근로 가산임금 지급하거나 또는 {companyWa} 근로자대표의 서면합의가 있는 경우에는 {worker}에게 가산임금에 갈음하여 동법 제57조 보상휴가를 부여할 수 있다.</li>
                        {isSkipWeekedWorker ? 
                        <li>③ {worker}는 
                          {skipWeek1 ? ' ' + days1+'요일 ':''} 
                          {skipWeek2 ? ' ' + days2+'요일 ':''} 
                          {skipWeek3 ? ' ' + days3 +'요일 ' : ''} 
                          {skipWeek4 ? ' ' + days4 +'요일 ' : ''} 
                          {skipWeek5 ? ' ' + days5 +'요일 ' : ''} 
                          {skipWeek6 ? ' ' + days6 +'요일 ' : ''} 
                          격주를 근무함에 따라 근로기준법 제51조 제1항의 2주단위 탄력적 근로시간제로 1주 소정근로시간(2주 평균)를 운영하되 1일의 소정근로시간은 연차부여 등을 위해 법정 소정근로시간을 운영한다.
                        </li>
                        : null}
                      </>
                    }
                  </>
              }
            </ul>
          </div>
        :
          <div className="jau__contents"> 
            <span style={{fontSize : '1rem'}}><strong>제4조 소정근로시간 적용제외</strong></span>
            <ul>
              <li>①{companyUn} 5인 미만 사업장으로 근로기준법 제11조 제2항, 동법 시행령 제7조에 의거 {worker}에게 소정로시간 관련 근로기준법 제50조(소정근로시간), 제51조(탄력적근로시간제), 제52조(선택적 근로시간제), 제53조(연장근로제한)을 적용하지 아니한다.</li>
              {isSkipWeekedWorker ? 
                <li>② {worker}는 
                  {skipWeek1 ? ' ' + days1+'요일 ':''} 
                    {skipWeek2 ? ' ' + days2+'요일 ':''} 
                    {skipWeek3 ? ' ' + days3 +'요일 ' : ''} 
                    {skipWeek4 ? ' ' + days4 +'요일 ' : ''} 
                    {skipWeek5 ? ' ' + days5 +'요일 ' : ''} 
                    {skipWeek6 ? ' ' + days6 +'요일 ' : ''} 
                    격주를 근무함에 1주 근로시간은 2주 평균으로 운영한다.
                </li>
                : null
              }
            </ul>
            
          </div>
      } 
    </>
    )
  }
