import React, { useEffect, useState } from 'react';
import { Button, Table } from 'semantic-ui-react';
// import {delay} from '../../../app/common/util/util'
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import * as XLSX from 'xlsx';
import { deleteMonthlyCompetencyReportsInFirestore } from '../../../app/firestore/firestoreService4In';
import { asyncActionError } from '../../../app/async/asyncReducer';
import { sendCompetencyReportId } from '../eventActions';

export default function MonthlyReportsList({
	monthlyReports,
	searchResults,
	setSearchResults,
	searchTerm,
	isWorker,
}) {
	const history = useHistory();
	const dispatch = useDispatch();
	const [loadingDelete, setLoadingDelete] = useState(false);
	// const [confirmOpen, setConfirmOpen] = useState(false);
	const { currentUserProfile } = useSelector((state) => state.profile);
	const { clientInfo } = useSelector((state) => state.companyReducer);
	const id = clientInfo?.id
		? clientInfo?.id
		: clientInfo?.clientUid
		? clientInfo?.clientUid
		: currentUserProfile?.id;

	const EMAIL_ADMIN = 'freya0207@gmail.com';

	useEffect(() => {
		const searchedMonthlyReports = monthlyReports.filter((monthlyReport) => {
			return monthlyReport?.workerName?.includes(searchTerm);
		});
		setSearchResults(searchedMonthlyReports);
	}, [searchTerm, setSearchResults, monthlyReports]);

	async function handleDeleteToggle(id) {
		setLoadingDelete(true);
		try {
			await deleteMonthlyCompetencyReportsInFirestore(id);
			history.go(0);
			// history.push('/monthlyReports');
			setLoadingDelete(false);
		} catch (error) {
			setLoadingDelete(true);
			dispatch(asyncActionError());
		}
	}

	async function handleManageCompetencyReport(id) {
		history.push(`/manageCompetencyMonthlyReport/${id}`);
		dispatch(sendCompetencyReportId(id));
	}

	const titleColor = 'teal';

	const exportToExcel = () => {
		const table = document.getElementById('table-to-xls');
		const workbook = XLSX.utils.table_to_book(table, {
			sheet: 'MONTHLY REPORT',
		});
		XLSX.writeFile(workbook, 'MONTHLY REPORT list.xlsx');
	};

	return (
		<>
			{monthlyReports.length !== 0 && (
				<>
					<Button onClick={exportToExcel} color='green'>
						MONTHLY 역량모델링 정보 엑셀파일 전환
					</Button>
					<br />
					<br />
					<>
						<p style={{ color: 'red' }}>
							* 회사의 피드백이 있는 역량 자료는 수정되지 않습니다. 주의하세요.
						</p>
						<Table
							style={{ border: '1', textAlign: 'center' }}
							id='table-to-xls'>
							<Table.Header>
								<Table.Row>
									<Table.HeaderCell style={{ textAlign: 'left' }} colSpan={'6'}>
										1. 고객 지향 역량
									</Table.HeaderCell>
								</Table.Row>
								<Table.Row>
									{isWorker ? (
										<Table.HeaderCell style={{ color: titleColor }}>
											년월
										</Table.HeaderCell>
									) : (
										<Table.HeaderCell style={{ color: titleColor }}>
											작성자
										</Table.HeaderCell>
									)}
									<Table.HeaderCell colSpan={'2'}>
										고객으로부터 칭찬받은 업무 내용은?
									</Table.HeaderCell>
									<Table.HeaderCell>
										본인 생각에 고객이 칭찬한 이유는?
									</Table.HeaderCell>
									{currentUserProfile?.email === EMAIL_ADMIN ? (
										<Table.HeaderCell>삭제</Table.HeaderCell>
									) : null}
									{isWorker ? (
										<Table.HeaderCell>수정</Table.HeaderCell>
									) : (
										<Table.HeaderCell>피드백</Table.HeaderCell>
									)}
								</Table.Row>
							</Table.Header>
							{searchResults.map((workerData, index) => (
								<Table.Body key={`customer-oriented-${index}`}>
									<Table.Row key={`${workerData.id}-${index}-customer`}>
										{isWorker ? (
											<Table.Cell>
												{workerData?.yearMonth}({workerData.weekNo}주)
											</Table.Cell>
										) : (
											<Table.Cell>{workerData?.workerName}</Table.Cell>
										)}
										<Table.Cell colSpan={'2'}>
											{workerData?.toCustomer?.context}
										</Table.Cell>
										<Table.Cell>{workerData?.toCustomer?.reason}</Table.Cell>
										{currentUserProfile?.email === EMAIL_ADMIN ? (
											<Table.Cell>
												<Button
													loading={loadingDelete}
													onClick={() => handleDeleteToggle(workerData?.id)}
													color='red'
													floated='right'
													content='삭제'
												/>
											</Table.Cell>
										) : null}
										{workerData?.hostUid === id ? (
											<Table.Cell>
												<Button
													loading={loadingDelete}
													disabled={
														workerData?.toCustomerFeedBack !== '' ||
														workerData?.toCoWorkerFeedBack !== '' ||
														workerData?.toCommunicationFeedBack !== '' ||
														workerData?.toWorkRecordFeedBack !== '' ||
														workerData?.toWorkAnalysisFeedBack !== '' ||
														workerData?.toStudyFeedBack !== '' ||
														workerData?.proposalFeedBack !== '' ||
														workerData?.hopeEducationFeedBack !== '' ||
														workerData?.grievanceFeedBack !== '' ||
														workerData?.complianceFeedBack !== ''
													}
													onClick={() =>
														handleManageCompetencyReport(workerData?.id)
													}
													color='teal'
													floated='right'
													content={isWorker ? '수정' : '피드백'}
												/>
											</Table.Cell>
										) : (
											<Table.Cell>
												<Button
													loading={loadingDelete}
													onClick={() =>
														handleManageCompetencyReport(workerData?.id)
													}
													color='teal'
													floated='right'
													content={isWorker ? '수정' : '피드백'}
												/>
											</Table.Cell>
										)}
									</Table.Row>
									{!workerData?.toCustomerFeedBack ? null : (
										<Table.Row>
											<Table.Cell style={{ color: 'blue' }}>피드백</Table.Cell>
											<Table.Cell style={{ color: 'blue' }} colSpan={'5'}>
												{workerData?.toCustomerFeedBack}
											</Table.Cell>
										</Table.Row>
									)}
								</Table.Body>
							))}
							<Table.Body>
								<Table.Row>
									<Table.Cell colSpan={'6'}></Table.Cell>
								</Table.Row>
							</Table.Body>

							<Table.Header>
								<Table.Row>
									<Table.HeaderCell style={{ textAlign: 'left' }} colSpan={'6'}>
										2. 동료 지향 역량
									</Table.HeaderCell>
								</Table.Row>
								<Table.Row>
									<Table.HeaderCell style={{ textAlign: 'left' }} colSpan={'6'}>
										2-1. 지원한 내용
									</Table.HeaderCell>
								</Table.Row>
								<Table.Row>
									{isWorker ? (
										<Table.HeaderCell
											style={{ width: '10%', color: titleColor }}>
											년월
										</Table.HeaderCell>
									) : (
										<Table.HeaderCell
											style={{ width: '10%', color: titleColor }}>
											작성자
										</Table.HeaderCell>
									)}
									<Table.HeaderCell style={{ width: '10%', color: titleColor }}>
										지원한 동료명
									</Table.HeaderCell>
									<Table.HeaderCell style={{ color: titleColor }}>
										지원한 내용
									</Table.HeaderCell>
									<Table.HeaderCell
										colSpan={'3'}
										style={{ width: '60%', color: titleColor }}>
										지원한 이유
									</Table.HeaderCell>
								</Table.Row>
							</Table.Header>
							{searchResults.map((workerData, index) => (
								<Table.Body>
									<Table.Row key={workerData.id || index}>
										{isWorker ? (
											<Table.Cell>
												{workerData?.yearMonth}({workerData.weekNo}주)
											</Table.Cell>
										) : (
											<Table.Cell>{workerData?.workerName}</Table.Cell>
										)}
										<Table.Cell>{workerData?.toCoWorker?.name}</Table.Cell>
										<Table.Cell>{workerData?.toCoWorker?.context}</Table.Cell>
										<Table.Cell colSpan={'3'}>
											{workerData?.toCoWorker?.reason}
										</Table.Cell>
									</Table.Row>
								</Table.Body>
							))}

							<Table.Header>
								<Table.Row>
									<Table.HeaderCell style={{ textAlign: 'left' }} colSpan={'6'}>
										2-2. 지원받은 내용
									</Table.HeaderCell>
								</Table.Row>
								<Table.Row>
									{isWorker ? (
										<Table.HeaderCell
											style={{ width: '10%', color: titleColor }}>
											년월
										</Table.HeaderCell>
									) : (
										<Table.HeaderCell
											style={{ width: '10%', color: titleColor }}>
											작성자
										</Table.HeaderCell>
									)}
									<Table.HeaderCell style={{ width: '10%', color: titleColor }}>
										지원받은 동료명
									</Table.HeaderCell>
									<Table.HeaderCell style={{ width: '40%', color: titleColor }}>
										지원받은 내용
									</Table.HeaderCell>
									<Table.HeaderCell
										colSpan={'3'}
										style={{ width: '40%', color: titleColor }}>
										지원받은 이유
									</Table.HeaderCell>
								</Table.Row>
							</Table.Header>
							{searchResults.map((workerData, index) => (
								<Table.Body key={`customer-oriented1-${index}`}>
									<Table.Row key={`${workerData.id}-${index}-customer1`}>
										{isWorker ? (
											<Table.Cell style={{ width: '10%' }}>
												{workerData?.yearMonth}({workerData.weekNo}주)
											</Table.Cell>
										) : (
											<Table.Cell style={{ width: '10%' }}>
												{workerData?.workerName}
											</Table.Cell>
										)}
										<Table.Cell style={{ width: '10%' }}>
											{workerData?.fromCoWorker?.name}
										</Table.Cell>
										<Table.Cell style={{ width: '40%' }}>
											{workerData?.fromCoWorker?.context}
										</Table.Cell>
										<Table.Cell colSpan={'3'} style={{ width: '40%' }}>
											{workerData?.fromCoWorker?.reason}
										</Table.Cell>
									</Table.Row>
									{!workerData?.toCoWorkerFeedBack ? null : (
										<Table.Row>
											<Table.Cell style={{ color: 'blue' }}>피드백</Table.Cell>
											<Table.Cell style={{ color: 'blue' }} colSpan={'5'}>
												{workerData?.toCoWorkerFeedBack}
											</Table.Cell>
										</Table.Row>
									)}
								</Table.Body>
							))}
							<Table.Body>
								<Table.Row>
									<Table.Cell colSpan={'6'}></Table.Cell>
								</Table.Row>
							</Table.Body>

							<Table.Header>
								<Table.Row>
									<Table.HeaderCell style={{ textAlign: 'left' }} colSpan={'6'}>
										3. 커뮤니케이션 지향 역량
									</Table.HeaderCell>
								</Table.Row>
								<Table.Row>
									<Table.HeaderCell style={{ textAlign: 'left' }} colSpan={'6'}>
										3-1. 업무공유
									</Table.HeaderCell>
								</Table.Row>
								<Table.Row>
									{isWorker ? (
										<Table.HeaderCell
											style={{ width: '10%', color: titleColor }}>
											년월
										</Table.HeaderCell>
									) : (
										<Table.HeaderCell
											style={{ width: '10%', color: titleColor }}>
											작성자
										</Table.HeaderCell>
									)}
									<Table.HeaderCell style={{ width: '10%', color: titleColor }}>
										업무공유 대상자명
									</Table.HeaderCell>
									<Table.HeaderCell style={{ width: '40%', color: titleColor }}>
										업무공유 내용
									</Table.HeaderCell>
									<Table.HeaderCell
										colSpan={'3'}
										style={{ width: '40%', color: titleColor }}>
										업무공유 이유
									</Table.HeaderCell>
								</Table.Row>
							</Table.Header>
							{searchResults.map((workerData, index) => (
								<Table.Body key={`customer-oriented2-${index}`}>
									<Table.Row key={`${workerData.id}-${index}-customer2`}>
										{isWorker ? (
											<Table.Cell style={{ width: '10%' }}>
												{workerData?.yearMonth}({workerData.weekNo}주)
											</Table.Cell>
										) : (
											<Table.Cell style={{ width: '10%' }}>
												{workerData?.workerName}
											</Table.Cell>
										)}
										<Table.Cell style={{ width: '10%' }}>
											{workerData?.toCommunication?.name}
										</Table.Cell>
										<Table.Cell style={{ width: '40%' }}>
											{workerData?.toCommunication?.context}
										</Table.Cell>
										<Table.Cell colSpan={'3'} style={{ width: '40%' }}>
											{workerData?.toCommunication?.reason}
										</Table.Cell>
									</Table.Row>
								</Table.Body>
							))}

							<Table.Header>
								<Table.Row>
									<Table.HeaderCell style={{ textAlign: 'left' }} colSpan={'6'}>
										3-2. 지식공유
									</Table.HeaderCell>
								</Table.Row>
								<Table.Row>
									{isWorker ? (
										<Table.HeaderCell
											style={{ width: '10%', color: titleColor }}>
											년월
										</Table.HeaderCell>
									) : (
										<Table.HeaderCell
											style={{ width: '10%', color: titleColor }}>
											작성자
										</Table.HeaderCell>
									)}
									<Table.HeaderCell style={{ width: '10%', color: titleColor }}>
										지식공유 대상자명
									</Table.HeaderCell>
									<Table.HeaderCell style={{ width: '40%', color: titleColor }}>
										지식공유 내용
									</Table.HeaderCell>
									<Table.HeaderCell
										colSpan={'3'}
										style={{ width: '40%', color: titleColor }}>
										지식공유 이유
									</Table.HeaderCell>
								</Table.Row>
							</Table.Header>
							{searchResults.map((workerData, index) => (
								<Table.Body key={`customer-oriented3-${index}`}>
									<Table.Row key={`${workerData.id}-${index}-customer3`}>
										{isWorker ? (
											<Table.Cell style={{ width: '10%' }}>
												{workerData?.yearMonth}({workerData.weekNo}주)
											</Table.Cell>
										) : (
											<Table.Cell style={{ width: '10%' }}>
												{workerData?.workerName}
											</Table.Cell>
										)}
										<Table.Cell style={{ width: '10%' }}>
											{workerData?.toCommunication?.noHowName}
										</Table.Cell>
										<Table.Cell style={{ width: '40%' }}>
											{workerData?.toCommunication?.noHowContext}
										</Table.Cell>
										<Table.Cell colSpan={'3'} style={{ width: '40%' }}>
											{workerData?.toCommunication?.noHowReason}
										</Table.Cell>
									</Table.Row>
									{!workerData?.toCommunicationFeedBack ? null : (
										<Table.Row>
											<Table.Cell style={{ color: 'blue' }}>피드백</Table.Cell>
											<Table.Cell style={{ color: 'blue' }} colSpan={'5'}>
												{workerData?.toCommunicationFeedBack}
											</Table.Cell>
										</Table.Row>
									)}
								</Table.Body>
							))}
							<Table.Body>
								<Table.Row>
									<Table.Cell colSpan={'6'}></Table.Cell>
								</Table.Row>
							</Table.Body>

							<Table.Header>
								<Table.Row>
									<Table.HeaderCell style={{ textAlign: 'left' }} colSpan={'6'}>
										4. 업무수행 역량
									</Table.HeaderCell>
								</Table.Row>
								<Table.Row>
									<Table.HeaderCell style={{ textAlign: 'left' }} colSpan={'6'}>
										4-1. 모범업무
									</Table.HeaderCell>
								</Table.Row>
								<Table.Row>
									{isWorker ? (
										<Table.HeaderCell
											style={{ width: '10%', color: titleColor }}>
											년월
										</Table.HeaderCell>
									) : (
										<Table.HeaderCell
											style={{ width: '10%', color: titleColor }}>
											작성자
										</Table.HeaderCell>
									)}
									<Table.HeaderCell colSpan={'5'} style={{ color: titleColor }}>
										수행한 업무 중 동료 및 선후배에게 모범이 되는 업무는?
									</Table.HeaderCell>
								</Table.Row>
							</Table.Header>
							{searchResults.map((workerData, index) => (
								<Table.Body key={`customer-oriented4-${index}`}>
									<Table.Row key={`${workerData.id}-${index}-customer4`}>
										{isWorker ? (
											<Table.Cell>
												{workerData?.yearMonth}({workerData.weekNo}주)
											</Table.Cell>
										) : (
											<Table.Cell>{workerData?.workerName}</Table.Cell>
										)}
										<Table.Cell colSpan={'5'}>
											{workerData?.toWorkRecord?.proudJob}
										</Table.Cell>
									</Table.Row>
								</Table.Body>
							))}

							<Table.Header>
								<Table.Row>
									<Table.HeaderCell style={{ textAlign: 'left' }} colSpan={'6'}>
										4-2. 자발적 업무
									</Table.HeaderCell>
								</Table.Row>
								<Table.Row>
									{isWorker ? (
										<Table.HeaderCell
											style={{ width: '10%', color: titleColor }}>
											년월
										</Table.HeaderCell>
									) : (
										<Table.HeaderCell
											style={{ width: '10%', color: titleColor }}>
											작성자
										</Table.HeaderCell>
									)}
									<Table.HeaderCell colSpan={'5'} style={{ color: titleColor }}>
										회사의 지시없이 자발적으로 추진한 업무 및 이유는?
									</Table.HeaderCell>
								</Table.Row>
							</Table.Header>
							{searchResults.map((workerData, index) => (
								<Table.Body key={`customer-oriented5-${index}`}>
									<Table.Row key={`${workerData.id}-${index}-customer5`}>
										{isWorker ? (
											<Table.Cell>
												{workerData?.yearMonth}({workerData.weekNo}주)
											</Table.Cell>
										) : (
											<Table.Cell>{workerData?.workerName}</Table.Cell>
										)}
										<Table.Cell colSpan={'5'}>
											{workerData?.toWorkRecord?.selfPlanedJob}
										</Table.Cell>
									</Table.Row>
								</Table.Body>
							))}

							<Table.Header>
								<Table.Row>
									<Table.HeaderCell style={{ textAlign: 'left' }} colSpan={'6'}>
										4-3. 도움이 필요한 업무
									</Table.HeaderCell>
								</Table.Row>
								<Table.Row>
									{isWorker ? (
										<Table.HeaderCell
											style={{ width: '10%', color: titleColor }}>
											년월
										</Table.HeaderCell>
									) : (
										<Table.HeaderCell
											style={{ width: '10%', color: titleColor }}>
											작성자
										</Table.HeaderCell>
									)}
									<Table.HeaderCell colSpan={'5'} style={{ color: titleColor }}>
										{' '}
										회사의 도움이 필요했었던 업무 및 이유는?
									</Table.HeaderCell>
								</Table.Row>
							</Table.Header>
							{searchResults.map((workerData, index) => (
								<Table.Body key={`customer-oriented6-${index}`}>
									<Table.Row key={`${workerData.id}-${index}-customer6`}>
										{isWorker ? (
											<Table.Cell>
												{workerData?.yearMonth}({workerData.weekNo}주)
											</Table.Cell>
										) : (
											<Table.Cell>{workerData?.workerName}</Table.Cell>
										)}
										<Table.Cell colSpan={'5'}>
											{workerData?.toWorkRecord?.unSupportedJob}
										</Table.Cell>
									</Table.Row>
									{!workerData?.toWorkRecordFeedBack ? null : (
										<Table.Row>
											<Table.Cell style={{ color: 'blue' }}>피드백</Table.Cell>
											<Table.Cell style={{ color: 'blue' }} colSpan={'5'}>
												{workerData?.toWorkRecordFeedBack}
											</Table.Cell>
										</Table.Row>
									)}
								</Table.Body>
							))}
							<Table.Body>
								<Table.Row>
									<Table.Cell colSpan={'6'}></Table.Cell>
								</Table.Row>
							</Table.Body>

							<Table.Header>
								<Table.Row>
									<Table.HeaderCell style={{ textAlign: 'left' }} colSpan={'6'}>
										5. 업무 분석 역량
									</Table.HeaderCell>
								</Table.Row>
								<Table.Row>
									<Table.HeaderCell style={{ textAlign: 'left' }} colSpan={'6'}>
										5-1. 즐거웠던 업무
									</Table.HeaderCell>
								</Table.Row>
								<Table.Row>
									{isWorker ? (
										<Table.HeaderCell
											style={{ width: '10%', color: titleColor }}>
											년월
										</Table.HeaderCell>
									) : (
										<Table.HeaderCell
											style={{ width: '10%', color: titleColor }}>
											작성자
										</Table.HeaderCell>
									)}
									<Table.HeaderCell colSpan={'2'} style={{ color: titleColor }}>
										수행 업무 중 가장 즐거웠던 업무은?
									</Table.HeaderCell>
									<Table.HeaderCell colSpan={'3'} style={{ color: titleColor }}>
										즐겁게 수행한 이유는?
									</Table.HeaderCell>
								</Table.Row>
							</Table.Header>
							{searchResults.map((workerData, index) => (
								<Table.Body key={`customer-oriented7-${index}`}>
									<Table.Row key={`${workerData.id}-${index}-customer7`}>
										{isWorker ? (
											<Table.Cell>
												{workerData?.yearMonth}({workerData.weekNo}주)
											</Table.Cell>
										) : (
											<Table.Cell>{workerData?.workerName}</Table.Cell>
										)}
										<Table.Cell colSpan={'2'}>
											{workerData?.toWorkAnalysis?.happyJob}
										</Table.Cell>
										<Table.Cell colSpan={'3'}>
											{workerData?.toWorkAnalysis?.happyJobReason}
										</Table.Cell>
									</Table.Row>
								</Table.Body>
							))}

							<Table.Header>
								<Table.Row>
									<Table.HeaderCell style={{ textAlign: 'left' }} colSpan={'6'}>
										5-2. 힘들었던 업무
									</Table.HeaderCell>
								</Table.Row>
								<Table.Row>
									{isWorker ? (
										<Table.HeaderCell
											style={{ width: '10%', color: titleColor }}>
											년월
										</Table.HeaderCell>
									) : (
										<Table.HeaderCell
											style={{ width: '10%', color: titleColor }}>
											작성자
										</Table.HeaderCell>
									)}
									<Table.HeaderCell colSpan={'2'} style={{ color: titleColor }}>
										수행 업무 중 가장 힘들었던 업무은?
									</Table.HeaderCell>
									<Table.HeaderCell colSpan={'3'} style={{ color: titleColor }}>
										힘들게 수행한 이유는?
									</Table.HeaderCell>
								</Table.Row>
							</Table.Header>
							{searchResults.map((workerData, index) => (
								<Table.Body key={`customer-oriented8-${index}`}>
									<Table.Row key={`${workerData.id}-${index}-customer8`}>
										{isWorker ? (
											<Table.Cell>
												{workerData?.yearMonth}({workerData.weekNo}주)
											</Table.Cell>
										) : (
											<Table.Cell>{workerData?.workerName}</Table.Cell>
										)}
										<Table.Cell colSpan={'2'}>
											{workerData?.toWorkAnalysis?.sadJob}
										</Table.Cell>
										<Table.Cell colSpan={'3'}>
											{workerData?.toWorkAnalysis?.sadJobReason}
										</Table.Cell>
									</Table.Row>
									{!workerData?.toWorkAnalysisFeedBack ? null : (
										<Table.Row>
											<Table.Cell style={{ color: 'blue' }}>피드백</Table.Cell>
											<Table.Cell style={{ color: 'blue' }} colSpan={'5'}>
												{workerData?.toWorkAnalysisFeedBack}
											</Table.Cell>
										</Table.Row>
									)}
								</Table.Body>
							))}
							<Table.Body>
								<Table.Row>
									<Table.Cell colSpan={'6'}></Table.Cell>
								</Table.Row>
							</Table.Body>

							<Table.Header>
								<Table.Row>
									<Table.HeaderCell style={{ textAlign: 'left' }} colSpan={'6'}>
										6. 자기개발 역량
									</Table.HeaderCell>
								</Table.Row>
								<Table.Row>
									<Table.HeaderCell style={{ textAlign: 'left' }} colSpan={'6'}>
										6-1. 업무학습
									</Table.HeaderCell>
								</Table.Row>
								<Table.Row>
									{isWorker ? (
										<Table.HeaderCell
											style={{ width: '10%', color: titleColor }}>
											년월
										</Table.HeaderCell>
									) : (
										<Table.HeaderCell
											style={{ width: '10%', color: titleColor }}>
											작성자
										</Table.HeaderCell>
									)}
									<Table.HeaderCell colSpan={'5'} style={{ color: titleColor }}>
										업무관련 학습(책 읽기, 강좌 듣기 등) 내용과 동료들과
										공유하고 싶은 내용은?
									</Table.HeaderCell>
								</Table.Row>
							</Table.Header>
							{searchResults.map((workerData, index) => (
								<Table.Body key={`customer-oriented9-${index}`}>
									<Table.Row key={`${workerData.id}-${index}-customer9`}>
										{isWorker ? (
											<Table.Cell>
												{workerData?.yearMonth}({workerData.weekNo}주)
											</Table.Cell>
										) : (
											<Table.Cell>{workerData?.workerName}</Table.Cell>
										)}
										<Table.Cell colSpan={'5'}>
											{workerData?.toStudy?.job}
										</Table.Cell>
									</Table.Row>
								</Table.Body>
							))}

							<Table.Header>
								<Table.Row>
									<Table.HeaderCell style={{ textAlign: 'left' }} colSpan={'6'}>
										6-2. 개인학습
									</Table.HeaderCell>
								</Table.Row>
								<Table.Row>
									{isWorker ? (
										<Table.HeaderCell
											style={{ width: '10%', color: titleColor }}>
											년월
										</Table.HeaderCell>
									) : (
										<Table.HeaderCell
											style={{ width: '10%', color: titleColor }}>
											작성자
										</Table.HeaderCell>
									)}
									<Table.HeaderCell colSpan={'5'} style={{ color: titleColor }}>
										본인 개인 발전 학습(책 읽기, 강좌 듣기, 운동 등) 내용과
										동료들과 공유하고 싶은 내용은?
									</Table.HeaderCell>
								</Table.Row>
							</Table.Header>
							{searchResults.map((workerData, index) => (
								<Table.Body key={`customer-oriented10-${index}`}>
									<Table.Row key={`${workerData.id}-${index}-customer10`}>
										{isWorker ? (
											<Table.Cell>
												{workerData?.yearMonth}({workerData.weekNo}주)
											</Table.Cell>
										) : (
											<Table.Cell>{workerData?.workerName}</Table.Cell>
										)}
										<Table.Cell colSpan={'5'}>
											{workerData?.toStudy?.personnal}
										</Table.Cell>
									</Table.Row>
									{!workerData?.toStudyFeedBack ? null : (
										<Table.Row>
											<Table.Cell style={{ color: 'blue' }}>피드백</Table.Cell>
											<Table.Cell style={{ color: 'blue' }} colSpan={'5'}>
												{workerData?.toStudyFeedBack}
											</Table.Cell>
										</Table.Row>
									)}
								</Table.Body>
							))}
							<Table.Body>
								<Table.Row>
									<Table.Cell colSpan={'6'}></Table.Cell>
								</Table.Row>
							</Table.Body>

							<Table.Header>
								<Table.Row>
									<Table.HeaderCell style={{ textAlign: 'left' }} colSpan={'6'}>
										7. 건의사항 등
									</Table.HeaderCell>
								</Table.Row>
								<Table.Row>
									<Table.HeaderCell style={{ textAlign: 'left' }} colSpan={'6'}>
										6-1. 업무개선
									</Table.HeaderCell>
								</Table.Row>
								<Table.Row>
									{isWorker ? (
										<Table.HeaderCell
											style={{ width: '10%', color: titleColor }}>
											년월
										</Table.HeaderCell>
									) : (
										<Table.HeaderCell
											style={{ width: '10%', color: titleColor }}>
											작성자
										</Table.HeaderCell>
									)}
									<Table.HeaderCell colSpan={'5'} style={{ color: titleColor }}>
										업무 개선을 위한 제안사항(해결하고자 하는 문제점을 함께
										기재요망)?
									</Table.HeaderCell>
								</Table.Row>
							</Table.Header>
							{searchResults.map((workerData, index) => (
								<Table.Body key={`customer-oriented11-${index}`}>
									<Table.Row key={`${workerData.id}-${index}-customer11`}>
										{isWorker ? (
											<Table.Cell>
												{workerData?.yearMonth}({workerData.weekNo}주)
											</Table.Cell>
										) : (
											<Table.Cell>{workerData?.workerName}</Table.Cell>
										)}
										<Table.Cell colSpan={'5'}>
											{workerData?.proposal}
										</Table.Cell>
									</Table.Row>
									{!workerData?.proposalFeedBack ? null : (
										<Table.Row>
											<Table.Cell style={{ color: 'blue' }}>피드백</Table.Cell>
											<Table.Cell style={{ color: 'blue' }} colSpan={'5'}>
												{workerData?.proposalFeedBack}
											</Table.Cell>
										</Table.Row>
									)}
								</Table.Body>
							))}

							<Table.Header>
								<Table.Row>
									<Table.HeaderCell style={{ textAlign: 'left' }} colSpan={'6'}>
										7-2. 희망교육
									</Table.HeaderCell>
								</Table.Row>
								<Table.Row>
									{isWorker ? (
										<Table.HeaderCell
											style={{ width: '10%', color: titleColor }}>
											년월
										</Table.HeaderCell>
									) : (
										<Table.HeaderCell
											style={{ width: '10%', color: titleColor }}>
											작성자
										</Table.HeaderCell>
									)}
									<Table.HeaderCell colSpan={'5'} style={{ color: titleColor }}>
										본인의 발전과 업무 개선을 위해 회사에 요청하는 교육은?
									</Table.HeaderCell>
								</Table.Row>
							</Table.Header>
							{searchResults.map((workerData, index) => (
								<Table.Body key={`customer-oriented12-${index}`}>
									<Table.Row key={`${workerData.id}-${index}-customer12`}>
										{isWorker ? (
											<Table.Cell>
												{workerData?.yearMonth}({workerData.weekNo}주)
											</Table.Cell>
										) : (
											<Table.Cell>{workerData?.workerName}</Table.Cell>
										)}
										<Table.Cell colSpan={'5'}>
											{workerData?.hopeEducation.job}
										</Table.Cell>
									</Table.Row>
									<Table.Row key={workerData.id}>
										{isWorker ? (
											<Table.Cell>
												{workerData?.yearMonth}({workerData.weekNo}주)
											</Table.Cell>
										) : (
											<Table.Cell>{workerData?.workerName}</Table.Cell>
										)}
										<Table.Cell colSpan={'5'}>
											{workerData?.hopeEducation.personnal}
										</Table.Cell>
									</Table.Row>
									{!workerData?.hopeEducationFeedBack ? null : (
										<Table.Row>
											<Table.Cell style={{ color: 'blue' }}>피드백</Table.Cell>
											<Table.Cell style={{ color: 'blue' }} colSpan={'5'}>
												{workerData?.hopeEducationFeedBack}
											</Table.Cell>
										</Table.Row>
									)}
								</Table.Body>
							))}

							<Table.Header>
								<Table.Row>
									<Table.HeaderCell style={{ textAlign: 'left' }} colSpan={'6'}>
										7-3. 고충사항
									</Table.HeaderCell>
								</Table.Row>
								<Table.Row>
									{isWorker ? (
										<Table.HeaderCell
											style={{ width: '10%', color: titleColor }}>
											년월
										</Table.HeaderCell>
									) : (
										<Table.HeaderCell
											style={{ width: '10%', color: titleColor }}>
											작성자
										</Table.HeaderCell>
									)}
									<Table.HeaderCell colSpan={'5'} style={{ color: titleColor }}>
										본인의 고충사항 내용은?
									</Table.HeaderCell>
								</Table.Row>
							</Table.Header>
							{searchResults.map((workerData, index) => (
								<Table.Body key={`customer-oriented13-${index}`}>
									<Table.Row key={`${workerData.id}-${index}-customer13`}>
										{isWorker ? (
											<Table.Cell>
												{workerData?.yearMonth}({workerData.weekNo}주)
											</Table.Cell>
										) : (
											<Table.Cell>{workerData?.workerName}</Table.Cell>
										)}
										<Table.Cell colSpan={'5'}>
											{workerData?.grievance}
										</Table.Cell>
									</Table.Row>
									{!workerData?.grievanceFeedBack ? null : (
										<Table.Row>
											<Table.Cell style={{ color: 'blue' }}>피드백</Table.Cell>
											<Table.Cell style={{ color: 'blue' }} colSpan={'5'}>
												{workerData?.grievanceFeedBack}
											</Table.Cell>
										</Table.Row>
									)}
								</Table.Body>
							))}

							<Table.Header>
								<Table.Row>
									<Table.HeaderCell style={{ textAlign: 'left' }} colSpan={'6'}>
										7-4. 컴플라이언스
									</Table.HeaderCell>
								</Table.Row>
								<Table.Row>
									{isWorker ? (
										<Table.HeaderCell
											style={{ width: '10%', color: titleColor }}>
											년월
										</Table.HeaderCell>
									) : (
										<Table.HeaderCell
											style={{ width: '10%', color: titleColor }}>
											작성자
										</Table.HeaderCell>
									)}
									<Table.HeaderCell colSpan={'5'} style={{ color: titleColor }}>
										컴플라이언스(회사규정 또는 법규 위반 사항) 보고 내용은?
									</Table.HeaderCell>
								</Table.Row>
							</Table.Header>
							{searchResults.map((workerData, index) => (
								<Table.Body key={`customer-oriented14-${index}`}>
									<Table.Row key={`${workerData.id}-${index}-customer14`}>
										{isWorker ? (
											<Table.Cell>
												{workerData?.yearMonth}({workerData.weekNo}주)
											</Table.Cell>
										) : (
											<Table.Cell>{workerData?.workerName}</Table.Cell>
										)}
										<Table.Cell colSpan={'5'}>
											{workerData?.compliance}
										</Table.Cell>
									</Table.Row>
									{!workerData?.complianceFeedBack ? null : (
										<Table.Row>
											<Table.Cell style={{ color: 'blue' }}>피드백</Table.Cell>
											<Table.Cell style={{ color: 'blue' }} colSpan={'5'}>
												{workerData?.complianceFeedBack}
											</Table.Cell>
										</Table.Row>
									)}
								</Table.Body>
							))}
							<Table.Body>
								<Table.Row>
									<Table.Cell colSpan={'6'}></Table.Cell>
								</Table.Row>
							</Table.Body>
						</Table>
					</>
				</>
			)}
		</>
	);
}
