import React from 'react';
import { over52Permission } from '../../../../../app/api/dropdownOption';



export default function WorkerFormCompanyInfo({
    currentUserProfile,
    MyTextInput,
    MySelectInput,
}) {
  return (
    <div style={{ display : 'flex', flexWrap : 'wrap', justifyContent: 'flex-start', alignItems : 'center', alignContent : 'flex-end'}}>
        {/* 11월21일(대리인들 안보이게-노무사는 볼지 고민중) */}
        {currentUserProfile?.authLevel >= 100 &&
          <>
            <div style={{width : '100%', marginBottom : "30px", marginTop : "30px", fontSize: '20px', cursor:'pointer'}}>✓ 노무사만 수정할 수 있는 회사 기초정보            
            </div>
              <>
                <div className='margin' style={{width : '33%', marginRight : '3px'}}>
                  <MyTextInput
                    label='회사명' 
                    placeholder='노무법인 인업 세종본부' 
                    name='company.companyName' 
                    autoComplete='off' 
                  />
                </div>
                <div className='margin' style={{width : '33%', marginRight : '3px'}}>
                  <MyTextInput
                    label='대표자 성명'         
                    placeholder='세종대왕' 
                    name='company.ceoName' 
                    autoComplete='off' 
                  />
                </div>
                <div className='margin' style={{width : '33%', marginRight : '3px'}}>
                  <MyTextInput
                    label='회사 주소'         
                    placeholder='세종특별시 갈매로 351 4115호' 
                    name='company.addr' 
                    autoComplete='off' 
                  />
                </div>
                <div className='margin' style={{width : '33%', marginRight : '3px'}}>
                  <MySelectInput 
                    label='52시간 연장 특별/특례 사업장' 
                    placeholder='특례사업장/일반사업장' 
                    name='over52permission' 
                    options={over52Permission} 
                  />
                </div>
              </>
          </>
        }
      </div>
  )
}
