import React from 'react';

// import React, { useEffect, useState } from 'react';
// import { useDispatch, useSelector } from 'react-redux';
// import { Grid, Loader } from 'semantic-ui-react';
// import { fetchUntacts } from '../untactActions';
// import { RETAIN_STATE } from '../untactConstants';
// import UntactFilters from './UntactFilters';
// import UntactList from './UntactList';
import SortingTable from '../../untacts/tableComponents/SortingTable'

export default function UntactDashboard() {
  // const limit = 20;
  // const dispatch = useDispatch();
  // const { 
  //   // untacts, 
  //   // moreUntacts,
  //   filter, 
  //   startDate, 
  //   // lastVisible, 
  //   retainState } = useSelector(state => state.untactStore);
  // const { loading } = useSelector(state => state.async);
  // const { authenticated } = useSelector(state => state.auth);

  // useEffect(() => {
  //   if (retainState) return;
  //   setLoadingInitial(true);
  //   dispatch(fetchUntacts(filter, startDate, limit)).then(() => {
  //     setLoadingInitial(false);
  //   });
  //   return () => {
  //     dispatch({type: RETAIN_STATE}); 
  //   };
  // }, [retainState, dispatch, filter, startDate]);

  // function handleFetchNextUntacts() {
  //   dispatch(fetchUntacts(filter, startDate, limit, lastVisible));
  // };

  return (
    <>
      <SortingTable />
      {/* <Grid>
        <Grid.Column width={10}>
          {loadingInitial && 
            <>
              <UntactListItemPlaceholder />
              <UntactListItemPlaceholder />
            </>
          }
          {authenticated &&
          <UntactList 
            untacts={untacts}
            getNextUntacts={handleFetchNextUntacts} 
            loading={loading}
            moreUntacts={moreUntacts}
          />}
        </Grid.Column>
        <Grid.Column width={6}>
          <UntactFilters loading={loading} />
        </Grid.Column>
        <Grid.Column width={10}>
          <Loader active={loading} />
        </Grid.Column>
      </Grid> */}
    </>
  );
};