import React from 'react';
import { Table } from 'semantic-ui-react';
import {
	numberToCommaString,
	roundUp2,
} from '../../../../app/common/util/util';

function ClientWageTableTop1BasicInfo({
	retiredDate,
	worker,
	isTodayInMaternityRangePerson,
	centerStyle,
	monthOWT,
	monthNWT,
	monthHoliBasicTime,
	monthOWTHoli,
	monthNWTHoli,
	annualLeavesIncludeMonthWageTime,
	decryptoData,
	monthLBTime,
	hourOrdinaryWage,
	workerEnteredDate,
	workerRetiredDate,
	startToEndDate,
	payDay,
}) {
	return (
		<>
			<Table.Row>
				{retiredDate ? (
					<Table.Cell colSpan='9'>
						<span
							style={{
								fontSize: '1.2rem',
								color: 'darkcyan',
								fontWeight: 'bold',
							}}>{`1. ${worker}의 인적사항 및 고정 월급 산정 기초 내역 (입사일 : ${workerEnteredDate}, 퇴사일 : ${workerRetiredDate}, 임금기산일 : ${startToEndDate}, 임금지급일 : ${payDay})`}</span>
					</Table.Cell>
				) : (
					<Table.Cell colSpan='9'>
						<span
							style={{
								fontSize: '1.2rem',
								color: 'darkcyan',
								fontWeight: 'bold',
							}}>{`1. ${worker}의 인적사항 및 고정 월급 산정 기초 내역 (입사일 : ${workerEnteredDate}, 임금기산일 : ${startToEndDate}, 임금지급일 : ${payDay})`}</span>{' '}
						<span style={{ color: 'red' }}>
							{isTodayInMaternityRangePerson
								? isTodayInMaternityRangePerson
								: null}
						</span>
					</Table.Cell>
				)}
			</Table.Row>
			<Table.Row>
				<Table.Cell textAlign='center' className='gubun' style={centerStyle}>
					성명(생년월일)
				</Table.Cell>
				<Table.Cell textAlign='center' className='gubun' style={centerStyle}>
					기본근로시간
				</Table.Cell>
				<Table.Cell textAlign='center' className='gubun' style={centerStyle}>
					통상시급
				</Table.Cell>
				{monthOWT > 0 ? (
					<Table.Cell textAlign='center' className='gubun' style={centerStyle}>
						약정연장시간
					</Table.Cell>
				) : null}
				{monthNWT > 0 ? (
					<Table.Cell textAlign='center' className='gubun' style={centerStyle}>
						약정야간시간
					</Table.Cell>
				) : null}
				{monthHoliBasicTime > 0 ? (
					<Table.Cell textAlign='center' className='gubun' style={centerStyle}>
						약정휴일기본
					</Table.Cell>
				) : null}
				{monthOWTHoli > 0 ? (
					<Table.Cell textAlign='center' className='gubun' style={centerStyle}>
						약정휴일연장
					</Table.Cell>
				) : null}
				{monthNWTHoli > 0 ? (
					<Table.Cell textAlign='center' className='gubun' style={centerStyle}>
						약정휴일야간
					</Table.Cell>
				) : null}
				{annualLeavesIncludeMonthWageTime ? (
					<Table.Cell textAlign='center' className='gubun' style={centerStyle}>
						유급연차시간
					</Table.Cell>
				) : null}
				{!monthOWT ? <Table.Cell textAlign='center'></Table.Cell> : null}
				{!monthNWT ? <Table.Cell textAlign='center'></Table.Cell> : null}
				{!monthHoliBasicTime ? (
					<Table.Cell textAlign='center'></Table.Cell>
				) : null}
				{!monthOWTHoli ? <Table.Cell textAlign='center'></Table.Cell> : null}
				{!monthNWTHoli ? <Table.Cell textAlign='center'></Table.Cell> : null}
				{!annualLeavesIncludeMonthWageTime ? (
					<Table.Cell textAlign='center'></Table.Cell>
				) : null}
			</Table.Row>
			<Table.Row>
				<Table.Cell
					textAlign='center'
					className='gubun'
					style={
						centerStyle
					}>{`${worker}(${decryptoData?.workerSocialNumberFront})`}</Table.Cell>
				<Table.Cell textAlign='center' className='gubun' style={centerStyle}>
					{monthLBTime}
				</Table.Cell>
				<Table.Cell textAlign='center' className='gubun' style={centerStyle}>
					{numberToCommaString(roundUp2(hourOrdinaryWage))}
				</Table.Cell>
				{monthOWT > 0 ? (
					<Table.Cell textAlign='center' className='gubun' style={centerStyle}>
						{roundUp2(monthOWT)}
					</Table.Cell>
				) : null}
				{monthNWT > 0 ? (
					<Table.Cell textAlign='center' className='gubun' style={centerStyle}>
						{roundUp2(monthNWT)}
					</Table.Cell>
				) : null}
				{monthHoliBasicTime > 0 ? (
					<Table.Cell textAlign='center' className='gubun' style={centerStyle}>
						{roundUp2(monthHoliBasicTime)}
					</Table.Cell>
				) : null}
				{monthOWTHoli > 0 ? (
					<Table.Cell textAlign='center' className='gubun' style={centerStyle}>
						{roundUp2(monthOWTHoli)}
					</Table.Cell>
				) : null}
				{monthNWTHoli > 0 ? (
					<Table.Cell textAlign='center' className='gubun' style={centerStyle}>
						{roundUp2(monthNWTHoli)}
					</Table.Cell>
				) : null}
				{annualLeavesIncludeMonthWageTime > 0 ? (
					<Table.Cell textAlign='center' className='gubun' style={centerStyle}>
						{roundUp2(annualLeavesIncludeMonthWageTime)}
					</Table.Cell>
				) : null}
				{!monthOWT ? <Table.Cell textAlign='center'></Table.Cell> : null}
				{!monthNWT ? <Table.Cell textAlign='center'></Table.Cell> : null}
				{!monthHoliBasicTime ? (
					<Table.Cell textAlign='center'></Table.Cell>
				) : null}
				{!monthOWTHoli ? <Table.Cell textAlign='center'></Table.Cell> : null}
				{!monthNWTHoli ? <Table.Cell textAlign='center'></Table.Cell> : null}
				{!annualLeavesIncludeMonthWageTime ? (
					<Table.Cell textAlign='center'></Table.Cell>
				) : null}
			</Table.Row>
			<Table.Row>
				<Table.Cell textAlign='center' colSpan='9'>{` `}</Table.Cell>
			</Table.Row>
		</>
	);
}

export default ClientWageTableTop1BasicInfo;
