import React, { useEffect } from 'react';
import { Button, Table } from 'semantic-ui-react';
import { useSelector } from 'react-redux';
import { format } from 'date-fns';
import * as XLSX from 'xlsx';
import {
	numberToCommaString,
	sumOfAmounOfWorkSumHr,
	sumOfAttenanceManageSumHr,
	sumOfJobImproveProposalSumHr,
	sumOfPersonnalEveRateSumHr,
	sumOfPersonnalEveTotalSumHr,
	sumOfQualityOfWorkSumHr,
	sumOfReportAttitudeSumHr,
	sumOfSelfStudySumHr,
	sumOfSupportColleagueSumHr,
	sumOfSupportCumstomerSumHr,
} from '../../../app/common/util/util';

export default function PaydocusTableHrList({
	paydocus,
	searchResults,
	setSearchResults,
	searchTerm,
	year,
	month,
}) {
	const { currentUserProfile } = useSelector((state) => state.profile);
	const { clientInfo } = useSelector((state) => state.companyReducer);

	const id = clientInfo?.id
		? clientInfo?.id
		: clientInfo?.clientUid
		? clientInfo?.clientUid
		: currentUserProfile?.id;

	useEffect(() => {
		const searchedPaydocus = paydocus.filter((paydocu) => {
			return (
				paydocu?.workerInfo?.worker?.includes(searchTerm) ||
				paydocu?.info?.workerDivisions?.includes(searchTerm) ||
				paydocu?.info?.workerTitles?.includes(searchTerm) ||
				paydocu?.companyInfo?.companyName?.includes(searchTerm)
			);
			// || paydocu?.createdAt?.includes(searchTerm)
			// || paydocu?.workerInfo?.workerEnteredDate?.includes(searchTerm)
			// || paydocu?.workerInfo?.workerRetiredDate?.includes(searchTerm)
		});
		setSearchResults(searchedPaydocus);
	}, [searchTerm, setSearchResults, paydocus]);

	// Function to export table data to Excel
	const exportToExcel = () => {
		const workSheet = XLSX.utils.table_to_sheet(
			document.getElementById('table-to-xls')
		);
		const workBook = XLSX.utils.book_new();
		XLSX.utils.book_append_sheet(
			workBook,
			workSheet,
			`${year}년 ${month}월 BSC 인사고과`
		);
		XLSX.writeFile(workBook, `${year}년 ${month}월 BSC 인사고과.xlsx`);
	};

	const authLevel = clientInfo?.authLevel
		? clientInfo?.authLevel
		: currentUserProfile?.authLevel;

	return (
		<>
			{paydocus.length !== 0 && (
				<>
					<Button
						onClick={exportToExcel}
						color='blue'>{`${year}년 ${month}월 BSC 인사고과 엑셀파일 전환`}</Button>

					<div
						style={{
							overflowX: 'auto',
							width: '100%',
							whiteSpace: 'nowrap',
							borderCollapse: 'collapse',
						}}>
						<Table
							style={{ textAlign: 'center', width: '100%', border: '1' }}
							id='table-to-xls'>
							<Table.Header>
								<Table.Row>
									{authLevel < 100 ? null : (
										<Table.HeaderCell>회사명</Table.HeaderCell>
									)}
									<Table.HeaderCell rowSpan={2}>년</Table.HeaderCell>
									<Table.HeaderCell rowSpan={2}>월</Table.HeaderCell>
									<Table.HeaderCell rowSpan={2}>사번</Table.HeaderCell>
									<Table.HeaderCell rowSpan={2}>부서명</Table.HeaderCell>
									<Table.HeaderCell rowSpan={2}>직위/직책</Table.HeaderCell>
									<Table.HeaderCell rowSpan={2}>직원명</Table.HeaderCell>
									<Table.HeaderCell rowSpan={2}>입사일</Table.HeaderCell>
									<Table.HeaderCell rowSpan={2}>
										마지막 <br /> 근무일
									</Table.HeaderCell>
									<Table.HeaderCell colSpan={2}>업무개선/제안</Table.HeaderCell>
									<Table.HeaderCell colSpan={2}>업무자립도</Table.HeaderCell>
									<Table.HeaderCell colSpan={2}>업무수행률</Table.HeaderCell>
									<Table.HeaderCell colSpan={2}>
										동료업무지원
										<br />
										(선후배 포함){' '}
									</Table.HeaderCell>
									<Table.HeaderCell colSpan={2}>근태현황</Table.HeaderCell>
									<Table.HeaderCell colSpan={2}>고객/동료지향</Table.HeaderCell>
									<Table.HeaderCell colSpan={2}>자기개발</Table.HeaderCell>
									<Table.HeaderCell colSpan={2}>커뮤니케이션</Table.HeaderCell>
									<Table.HeaderCell rowSpan={2}>점수합계</Table.HeaderCell>
									<Table.HeaderCell rowSpan={2}>비율(%)</Table.HeaderCell>
								</Table.Row>
								<Table.Row>
									{authLevel < 100 ? null : (
										<Table.HeaderCell>회사명</Table.HeaderCell>
									)}
									<Table.HeaderCell>점수</Table.HeaderCell>
									<Table.HeaderCell>내용</Table.HeaderCell>
									<Table.HeaderCell>점수</Table.HeaderCell>
									<Table.HeaderCell>내용</Table.HeaderCell>
									<Table.HeaderCell>점수</Table.HeaderCell>
									<Table.HeaderCell>내용</Table.HeaderCell>
									<Table.HeaderCell>점수</Table.HeaderCell>
									<Table.HeaderCell>내용</Table.HeaderCell>
									<Table.HeaderCell>점수</Table.HeaderCell>
									<Table.HeaderCell>내용</Table.HeaderCell>
									<Table.HeaderCell>점수</Table.HeaderCell>
									<Table.HeaderCell>내용</Table.HeaderCell>
									<Table.HeaderCell>점수</Table.HeaderCell>
									<Table.HeaderCell>내용</Table.HeaderCell>
									<Table.HeaderCell>점수</Table.HeaderCell>
									<Table.HeaderCell>내용</Table.HeaderCell>
								</Table.Row>
							</Table.Header>
							{searchResults.map(
								(searchResult, index) =>
									(searchResult?.companyInfo?.agentUid === id ||
										searchResult.companyInfo.companyId === id ||
										currentUserProfile?.authLevel >= 100) && (
										<React.Fragment key={searchResult.id}>
											{searchResult?.workerInfo?.dayWorker === true ? null : (
												<Table.Body>
													<Table.Row key={searchResult.id + '_row'}>
														{authLevel < 100 ? null : (
															<Table.Cell rowSpan={2}>
																{searchResult?.companyInfo?.companyName}
															</Table.Cell>
														)}
														<Table.Cell rowSpan={2}>
															{searchResult?.info?.year}
														</Table.Cell>
														<Table.Cell rowSpan={2}>
															{searchResult?.info?.month}
														</Table.Cell>
														<Table.Cell rowSpan={2}>
															{searchResult?.workerInfo?.workerComNumber}
														</Table.Cell>
														<Table.Cell rowSpan={2}>
															{searchResult?.workerInfo?.workerDivisions}
														</Table.Cell>
														<Table.Cell rowSpan={2}>
															{searchResult?.workerInfo?.workerTitles}
														</Table.Cell>
														<Table.Cell rowSpan={2}>
															{searchResult?.workerInfo?.worker}
														</Table.Cell>
														<Table.Cell rowSpan={2}>
															{searchResult?.workerInfo?.workerEnteredDate
																? format(
																		new Date(
																			searchResult?.workerInfo?.workerEnteredDate
																		),
																		'yyyy-MM-dd'
																  )
																: ''}
														</Table.Cell>
														<Table.Cell rowSpan={2}>
															{searchResult?.workerInfo?.workerRetiredDate
																? format(
																		new Date(
																			searchResult?.workerInfo?.workerRetiredDate
																		),
																		'yyyy-MM-dd'
																  )
																: ''}
														</Table.Cell>

														<Table.Cell>
															{
																searchResult?.hrEvaluationInfo
																	?.jobImproveProposal[0]
															}
														</Table.Cell>
														<Table.Cell>
															{
																searchResult?.hrEvaluationInfo
																	?.jobImproveProposal[1]
															}
														</Table.Cell>
														<Table.Cell>
															{searchResult?.hrEvaluationInfo?.qualityOfWork[0]}
														</Table.Cell>
														<Table.Cell>
															{searchResult?.hrEvaluationInfo?.qualityOfWork[1]}
														</Table.Cell>
														<Table.Cell>
															{searchResult?.hrEvaluationInfo?.amounOfWork[0]}
														</Table.Cell>
														<Table.Cell>
															{searchResult?.hrEvaluationInfo?.amounOfWork[1]}
														</Table.Cell>
														<Table.Cell>
															{
																searchResult?.hrEvaluationInfo
																	?.supportColleague[0]
															}
														</Table.Cell>
														<Table.Cell>
															{
																searchResult?.hrEvaluationInfo
																	?.supportColleague[1]
															}
														</Table.Cell>
														<Table.Cell>
															{
																searchResult?.hrEvaluationInfo
																	?.attenanceManage[0]
															}
														</Table.Cell>
														<Table.Cell>
															{
																searchResult?.hrEvaluationInfo
																	?.attenanceManage[1]
															}
														</Table.Cell>
														<Table.Cell>
															{
																searchResult?.hrEvaluationInfo
																	?.supportCumstomer[0]
															}
														</Table.Cell>
														<Table.Cell>
															{
																searchResult?.hrEvaluationInfo
																	?.supportCumstomer[1]
															}
														</Table.Cell>
														<Table.Cell>
															{searchResult?.hrEvaluationInfo?.selfStudy[0]}
														</Table.Cell>
														<Table.Cell>
															{searchResult?.hrEvaluationInfo?.selfStudy[1]}
														</Table.Cell>
														<Table.Cell>
															{
																searchResult?.hrEvaluationInfo
																	?.reportAttitude[0]
															}
														</Table.Cell>
														<Table.Cell>
															{
																searchResult?.hrEvaluationInfo
																	?.reportAttitude[1]
															}
														</Table.Cell>
														<Table.Cell rowSpan={2}>
															{searchResult?.hrEvaluationInfo?.personnalEveSum}
														</Table.Cell>
														<Table.Cell rowSpan={2}>
															{searchResult?.hrEvaluationInfo?.personnalEveRate}
														</Table.Cell>
													</Table.Row>
													<Table.Row>
														<Table.Cell colSpan={2}>
															{
																searchResult?.hrEvaluationInfo
																	?.jobImproveProposalComments
															}
														</Table.Cell>
														<Table.Cell colSpan={2}>
															{
																searchResult?.hrEvaluationInfo
																	?.qualityOfWorkComments
															}
														</Table.Cell>
														<Table.Cell colSpan={2}>
															{
																searchResult?.hrEvaluationInfo
																	?.amounOfWorkComments
															}
														</Table.Cell>
														<Table.Cell colSpan={2}>
															{
																searchResult?.hrEvaluationInfo
																	?.supportColleagueComments
															}
														</Table.Cell>
														<Table.Cell colSpan={2}>
															{
																searchResult?.hrEvaluationInfo
																	?.attenanceManageComments
															}
														</Table.Cell>
														<Table.Cell colSpan={2}>
															{
																searchResult?.hrEvaluationInfo
																	?.supportCumstomerComments
															}
														</Table.Cell>
														<Table.Cell colSpan={2}>
															{
																searchResult?.hrEvaluationInfo
																	?.selfStudyComments
															}
														</Table.Cell>
														<Table.Cell colSpan={2}>
															{
																searchResult?.hrEvaluationInfo
																	?.reportAttitudeComments
															}
														</Table.Cell>
													</Table.Row>
												</Table.Body>
											)}
										</React.Fragment>
									)
							)}
							<Table.Body>
								<Table.Row>
									{authLevel < 100 ? (
										<Table.Cell colSpan='8'>합계</Table.Cell>
									) : (
										<Table.Cell colSpan='9'>합계</Table.Cell>
									)}
									<Table.Cell colSpan={2}>
										{numberToCommaString(
											sumOfJobImproveProposalSumHr(searchResults)
										)}
									</Table.Cell>
									<Table.Cell colSpan={2}>
										{numberToCommaString(
											sumOfQualityOfWorkSumHr(searchResults)
										)}
									</Table.Cell>
									<Table.Cell colSpan={2}>
										{numberToCommaString(sumOfAmounOfWorkSumHr(searchResults))}
									</Table.Cell>
									<Table.Cell colSpan={2}>
										{numberToCommaString(
											sumOfSupportColleagueSumHr(searchResults)
										)}
									</Table.Cell>
									<Table.Cell colSpan={2}>
										{numberToCommaString(
											sumOfAttenanceManageSumHr(searchResults)
										)}
									</Table.Cell>
									<Table.Cell colSpan={2}>
										{numberToCommaString(
											sumOfSupportCumstomerSumHr(searchResults)
										)}
									</Table.Cell>
									<Table.Cell colSpan={2}>
										{numberToCommaString(sumOfSelfStudySumHr(searchResults))}
									</Table.Cell>
									<Table.Cell colSpan={2}>
										{numberToCommaString(
											sumOfReportAttitudeSumHr(searchResults)
										)}
									</Table.Cell>
									<Table.Cell>
										{numberToCommaString(
											sumOfPersonnalEveTotalSumHr(searchResults)
										)}
									</Table.Cell>
									<Table.Cell>
										{numberToCommaString(
											sumOfPersonnalEveRateSumHr(searchResults)
										)}
									</Table.Cell>
								</Table.Row>
							</Table.Body>
						</Table>
					</div>
				</>
			)}
		</>
	);
}
