import { 
  CREATE_UNTACT, 
  DELETE_UNTACT, 
  UPDATE_UNTACT, 
  FETCH_UNTACTS, 
  LISTEN_TO_UNTACT_CHAT, 
  CLEAR_COMMENTS, 
  LISTEN_TO_SELECTED_UNTACT, 
  CLEAR_UNTACTS, 
  SET_START_DATE_UNTACT, 
  RETAIN_STATE,
  CLEAR_SELECTED_UNTACT,
  CLEAR_UNTACTS_TABLE_SAMPLE,
  FETCH_UNTACTS_TABLE_SAMPLE,
  SET_FILTER_UNTACT,
  } from './untactConstants';

const initialState = {
  untacts: [],
  untactsSample: [],
  comments: [],
  moreUntacts: true,
  selectedUntact: null,
  lastVisible: null,
  filter: 'all',
  startDate: new Date(),
  retainState: false,
};

export default function untactReducer(state=initialState, {type, payload}) {
  switch (type) {
    case CREATE_UNTACT:
      return {
        ...state,
        untacts: [...state.untacts, payload]
      };
    case UPDATE_UNTACT:
      return {
        ...state,
        untacts: [...state.untacts.filter(evt => evt.id !== payload.id), payload]
      };
    case DELETE_UNTACT:
      return {
        ...state,
        untacts: [...state.untacts.filter(evt => evt.id !== payload)]
      };
    case FETCH_UNTACTS:
      return {
        ...state,
        untacts: [...state.untacts, ...payload.untacts],
        moreUntacts: payload.moreUntacts,
        lastVisible: payload.lastVisible,
      };
    case FETCH_UNTACTS_TABLE_SAMPLE:
      return {
        ...state,
        untactsSample: [...state.untactsSample, ...payload.untactsSample],
      };
    case LISTEN_TO_UNTACT_CHAT:
      return {
        ...state,
        comments: payload,
      };
    case CLEAR_COMMENTS:
      return {
        ...state,
        comments: [],
      };
    case LISTEN_TO_SELECTED_UNTACT:
      return {
        ...state,
        selectedUntact: payload
      };
    case CLEAR_SELECTED_UNTACT:
      return {
        ...state,
        selectedUntact: null,
      };
    case CLEAR_UNTACTS:
      return {
        ...state,
        untacts: [],
        moreUntacts: true,
        lastVisible: null,
      };
    case CLEAR_UNTACTS_TABLE_SAMPLE:
      return {
        ...state,
        untactsSample: [],
      };
    case SET_FILTER_UNTACT:
      return {
        ...state,
        retainState: false,
        moreUntacts: true,
        filter: payload,
      };
    case SET_START_DATE_UNTACT:
      return {
        ...state,
        retainState: false,
        moreUntacts: true,
        startDate: payload,
      };
    case RETAIN_STATE:
      return {
        ...state,
        retainState: true,
      };
    default:
      return state;
  }
}