import React from 'react';
import ModalWrapper from '../../../app/common/modals/ModalWrapper';
import YouTube from 'react-youtube';

export default function PayPaperAddMoney() {

  const opts = {
    // height: '300%',
    width: '100%',
    playVars: {
      autoplay: 1
    }
  };

  return (
    <ModalWrapper size='large' header="AI 노무사 임금명세서 상여금, 미사용연차수당, 기타금품 등록 설정">
      <YouTube videoId='g0-MNo_PUT4' opts={opts} />
    </ModalWrapper>
  )
}
