// import { format } from 'date-fns';
import React from 'react';
import { Grid, Tab } from 'semantic-ui-react'

// import { format } from 'date-fns/esm';
// import { Button, Grid, Tab } from 'semantic-ui-react'
// import { printSupportFundContract } from '../../../app/common/util/util';
// import { Link } from 'react-router-dom';

export default function RepresentativesAgreementContents({profile}) {
  // let today = new Date(); 
  // const styles = {
  //   position: 'absolute',
  //   // top: 0,
  //   bottom: "-150%",    // computed based on child and parent's height
  //   right: 0,   // computed based on child and parent's width
  // };

  return (
    <Tab.Pane>
      <Grid>
        <Grid.Column width={16}>
          <h1>Coming Soon!</h1>
        </Grid.Column>
      </Grid>
    </Tab.Pane>
  );
};
