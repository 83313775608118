import { ref, onValue } from "firebase/database";
import { realdb } from "../../app/config/firebase";
import { 
  CREATE_EVENT, 
  UPDATE_EVENT, 
  DELETE_EVENT, 
  FETCH_EVENTS, 
  LISTEN_TO_EVENT_CHAT, 
  LISTEN_TO_SELECTED_EVENT, 
  CLEAR_EVENTS, 
  SET_FILTER, 
  SET_START_DATE, 
  CLEAR_SELECTED_EVENT,
  
  FETCH_EVENTS_TABLE,
  CLEAR_EVENTS_TABLE,

  FETCH_MONTHLY_REPORTS,
  CLEAR_MONTHLY_REPORTS,
  CLEAR_SELECTED_MONTHLY_REPORT,
  LISTEN_TO_SELECTED_MONTHLY_REPORT,

  FETCH_COMPETENCIES,
  CLEAR_COMPETENCIES,
  SET_YEARMONTH_COMPETENCY,
  SEND_COMPETENCYREPORTID,

  LISTEN_TO_SELECTED_RISK_SUSPECT_REPORT,
  CLEAR_SELECTED_RISK_SUSPECT_REPORT,
  CLEAR_RISK_SUSPECT_REPORTS,
  SET_YEARMONTH_RISK_SUSPECT,
  SEND_RISK_SUSPECT_REPORT_ID,
  FETCH_RISK_SUSPECT_REPORTS,

  SET_SELECTED_CONVERSATION_ID,
  ADD_MESSAGE,
  SET_CONVERSATIONS,
  SET_CONVERSATION_HISTORY,
  DELETE_CONVERSATIONS,
  UPDATE_CONVERSATIONS,

} from "./eventConstants";

import {
  asyncActionError, 
  asyncActionFinish, 
  asyncActionStart} from '../../app/async/asyncReducer';

import {
  dataFromSnapshotIdDate, 
  dataFromSnapshotIdDateTableSample, 
  fetchEventsFromFirestore,
  fetchEventsFromFirestoreId,
  fetchEventsTableFromFirestore} from '../../app/firestore/firestoreService';

import { fetchMonthlyCompetencyReportsFromFirestore, fetchRiskSuspectReportsFromFirestore, getCompetenciesQuery } from '../../app/firestore/firestoreService4In';

export function fetchEvents(filter, startDate, limit, lastDocSnapshot) {
  return async function(dispatch) {
    dispatch(asyncActionStart());
    try {
      const snapshot = await fetchEventsFromFirestore(filter, startDate, limit, lastDocSnapshot).get();
      //fireStoreService.js : fetchEventsFromFirestore : Data 추출시 limit를 고려하면서 filter랑 해서 화면에서 events를 보여주는 함수
      const lastVisible = snapshot.docs[snapshot.docs.length - 1];
      const moreEvents = snapshot.docs.length >= limit;
      const events = snapshot.docs.map(doc => dataFromSnapshotIdDate(doc));
      //fireStoreService.js : doc을 돌면서 doc에 firestoreTimeStamp가 있으면 자바 날짜 형식으로 바꾸기 함수
      dispatch({type: FETCH_EVENTS, payload:{events, moreEvents, lastVisible}});
      dispatch(asyncActionFinish());
    } catch (error) {
      dispatch(asyncActionError());
    }
  }
}

export function fetchEventsId(filter, id) {
  return async function(dispatch) {
    dispatch(asyncActionStart());
    try {
      const snapshot = await fetchEventsFromFirestoreId(filter, id).get();
      //fireStoreService.js : fetchEventsFromFirestore : Data 추출시 limit를 고려하면서 filter랑 해서 화면에서 events를 보여주는 함수
      const events = snapshot.docs.map(doc => dataFromSnapshotIdDate(doc));
      //fireStoreService.js : doc을 돌면서 doc에 firestoreTimeStamp가 있으면 자바 날짜 형식으로 바꾸기 함수
      dispatch({type: FETCH_EVENTS, payload:{events}});
      dispatch(asyncActionFinish());
    } catch (error) {
      dispatch(asyncActionError());
    }
  }
}

export function fectchEventsTable() {
  return async function(dispatch) {
    dispatch(asyncActionStart());
    try {
      const snapshot = await fetchEventsTableFromFirestore().get();
      const eventsTable = snapshot.docs.map(doc => dataFromSnapshotIdDateTableSample(doc));
      dispatch({type: FETCH_EVENTS_TABLE, payload:{eventsTable}});
      dispatch(asyncActionFinish());
    } catch (error) {
      dispatch(asyncActionError());
    }
  }
}


export function fetchCompetencies() {
  return async function(dispatch) {
    dispatch(asyncActionStart());
    try {
      const snapshot = await getCompetenciesQuery().get();
      const competencies = snapshot.docs.map(doc => dataFromSnapshotIdDate(doc));
      dispatch({type: FETCH_COMPETENCIES, payload:{competencies}});
      dispatch(asyncActionFinish());
    } catch (error) {
      dispatch(asyncActionError());
    }
  }
}


export function setStartDate(date) {
  return function(dispatch) {
    dispatch(clearEvents());
    dispatch({type: SET_START_DATE, payload: date});
  }
}

// 변경전 ListenToEvents
export function listenToSelectedEvent(event) {
  return {
    type: LISTEN_TO_SELECTED_EVENT,
    payload: event
  }
}

export function clearSelectedEvent() {
  return {
    type: CLEAR_SELECTED_EVENT,
  }
}

export function createEvent(event) {
  return {
    type: CREATE_EVENT,
    payload: event
  }
}

export function updateEvent(event) {
  return {
    type: UPDATE_EVENT,
    payload: event
  }
}

export function deleteEvent(eventId) {
  return {
    type: DELETE_EVENT,
    payload: eventId
  }
}

export function listenToEventChat(comments) {
  return {
    type: LISTEN_TO_EVENT_CHAT,
    payload: comments
  }
}

export function clearEvents() {
  return {
    type: CLEAR_EVENTS,
  }
}

export function clearCompetencies() {
  return {
    type : CLEAR_COMPETENCIES,
  }
}

export function clearEventsTable() {
  return {
    type: CLEAR_EVENTS_TABLE,
  }
}

export function clearMonthlyReports() {
  return {
    type: CLEAR_MONTHLY_REPORTS,
  }
}

export function clearSelectedMonthlyReport() {
  return {
    type: CLEAR_SELECTED_MONTHLY_REPORT,
  }
}

export function listenToSelectedMonthlyReport(report) {
  return {
    type: LISTEN_TO_SELECTED_MONTHLY_REPORT,
    payload: report
  }
}

export function fetchMonthlyCompetencyReports(filter, yearMonth) {
  return async function(dispatch) {
    dispatch(asyncActionStart());
    try {
      const snapshot = await fetchMonthlyCompetencyReportsFromFirestore(filter, yearMonth);
      // const monthlyReports = snapshot.docs.map(doc => dataFromSnapshotIdDate(doc));
      const monthlyReports = snapshot;

      dispatch({type: FETCH_MONTHLY_REPORTS, payload:{monthlyReports}});
      dispatch(asyncActionFinish());
    } catch (error) {
      dispatch(asyncActionError());
    }
  }
}

export function setFilter(value) {
  return function(dispatch) {
    dispatch(clearEvents());
    dispatch({type: SET_FILTER, payload: value});
  }
}

export function setYearMonthCompetency(value) {
  return function(dispatch) {
    dispatch(clearEvents());
    dispatch({type: SET_YEARMONTH_COMPETENCY, payload: value});
  }
}

export function sendCompetencyReportId(id) {
  return {
    type: SEND_COMPETENCYREPORTID,
    payload: id
  }
}

export function listenToSelectedRiskSuspectReport(report) {
  return {
    type: LISTEN_TO_SELECTED_RISK_SUSPECT_REPORT,
    payload: report
  }
}

export function clearSelectedRiskSuspectReport() {
  return {
    type: CLEAR_SELECTED_RISK_SUSPECT_REPORT,
  }
}

export function fetchRiskSuspectReports(filter, yearMonth) {
  return async function(dispatch) {
    dispatch(asyncActionStart());
    try {
      const snapshot = await fetchRiskSuspectReportsFromFirestore(filter, yearMonth);
      const riskSuspectReports = snapshot;
      dispatch({type: FETCH_RISK_SUSPECT_REPORTS, payload:{riskSuspectReports}});
      dispatch(asyncActionFinish());
    } catch (error) {
      dispatch(asyncActionError());
    }
  }
}

export function clearRiskSuspectReports() {
  return {
    type: CLEAR_RISK_SUSPECT_REPORTS,
  }
}

export function setYearRiskSuspect(value) {
  return function(dispatch) {
    dispatch(clearEvents());
    dispatch({type: SET_YEARMONTH_RISK_SUSPECT, payload: value});
  }
}

export function sendRiskSuspectReportId(id) {
  return {
    type: SEND_RISK_SUSPECT_REPORT_ID,
    payload: id
  }
}

export function setSelectedConversationId(id) {
  return {
    type: SET_SELECTED_CONVERSATION_ID,
    payload: id
  }
}

export function addMessage(message, conversationId ) {

  return {
    type: ADD_MESSAGE,
    payload: {message, conversationId}
  }
}

export function setConversations(conversations) {
  return {
    type: SET_CONVERSATIONS,
    payload: conversations
  }
}

export function setConversationHistory(conversation) {
  return {
    type: SET_CONVERSATION_HISTORY,
    payload: conversation
  }
}

export function deleteConversations() {
  return {
    type: DELETE_CONVERSATIONS,
  }
}

export const updateConversations = (conversations) => ({
  type: UPDATE_CONVERSATIONS,
  payload: conversations,
});

export const listenToConversations = (userIdentifier) => {
  return (dispatch) => {
    // 특정 사용자의 대화만을 리스닝
    const userConversationsRef = ref(realdb, `conversations/${userIdentifier}`);
    
    onValue(userConversationsRef, (snapshot) => {
      const conversations = snapshot.val() || {};
      dispatch(updateConversations(conversations));
    });
  };
};
