import { format } from 'date-fns';
import { Form, Formik } from 'formik';
import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Button, Menu } from 'semantic-ui-react'
import { setFilter, setYearRiskSuspect } from '../eventActions';
import * as Yup from 'yup';
import { month, year } from '../../../app/api/dropdownOption';
import MySelectInput from '../../../app/common/form/MySelectInput';
import MyTextInput from '../../../app/common/form/MyTextInput';
import { toast } from 'react-toastify';

export default function RiskSuspectReportsFilters({loading, isWorker, agentType}) {
  const dispatch = useDispatch();
  const {filter} = useSelector(state => state.eventStore)  
  const {currentUserProfile} = useSelector(state => state.profile)
  const thisYear = format(new Date(), 'yyyy')
  const thisMonth = format(new Date(), 'MM')

  return (
    <>
      {isWorker ? 
          <>
            <Menu vertical size='large' style={{width: '100%'}}>
              <Menu.Item 
                content='안전보건 활동(위험성 관리 활동 포함) 정보 불러오기' 
                active={filter === 'isWorker'}
                onClick={() => dispatch(setFilter('isWorker'))}
                disabled={loading}
              />
            </Menu>
          </>
        :
          <>
            <hr />
            <Formik
              initialValues = {{
                companyName: currentUserProfile?.companyName,
                year: thisYear,
                month: thisMonth,
              }}
              validationSchema={
              Yup.object({
                year: Yup.string().required('필수입니다.'),
                month: Yup.string().required('필수입니다.'),
              })}
              onSubmit={(values, {setSubmitting}) => {

                const yearMonth = values.year + values.month;

                try {
                  dispatch(setFilter(values.companyName));
                  dispatch(setYearRiskSuspect(yearMonth));
                  setSubmitting(false);
                } catch (error) {
                  console.log(error.message);
                  toast.error(error.message);
                  setSubmitting(false);
                }
              }}
            >
            {({isSubmitting, isValid, dirty})=>(
              <Form className='ui form'>
                <div style={{ display : 'flex', flexWrap : 'wrap', justifyContent: 'flex-start', alignItems : 'center', alignContent : 'flex-end'}}>
                    
                {((currentUserProfile?.authLevel >= 21 && 
                  (agentType === 'bs노무사' || agentType === 'ps노무사')) || 
                  currentUserProfile?.authLevel >= 101) ?
                    <>
                      <div className='margin' style={{width : '19.5%', marginRight : '3px'}}>
                        <MyTextInput
                          label='회사명'
                          name='companyName' 
                          placeholder='회사명' 
                          autoComplete='off'
                        />
                      </div>
                      <div className='margin' style={{width : '19.5%', marginRight : '3px'}}>
                        <MySelectInput 
                          label='년도' 
                          name='year' 
                          options={year}
                        />
                      </div>
                      <div className='margin' style={{width : '19.5%', marginRight : '3px'}}>
                        <MySelectInput 
                          label='월' 
                          name='month' 
                          options={month}
                        />
                      </div>
                    </>
                  :
                    <>
                      <div className='margin' style={{width : '19.5%', marginRight : '3px'}}>
                        <MySelectInput 
                          label='년도' 
                          name='year' 
                          options={year}
                        />
                      </div>
                      <div className='margin' style={{width : '19.5%', marginRight : '3px'}}>
                        <MySelectInput 
                          label='월' 
                          name='month' 
                          options={month}
                        />
                      </div>
                    </>
                }
                  <Button
                    loading={isSubmitting}
                    disabled={isSubmitting || !isValid}
                    floated='left'
                    type='submit'
                    size='large'
                    positive
                    content='검색하기' 
                  />
                </div>
              </Form>
            )}
            </Formik>
            <hr />
          </>
      }
    </>
  )
}
