import React from 'react';
import { Grid, Table } from 'semantic-ui-react';

// import { Button, Grid, Icon, Table } from 'semantic-ui-react';
// import { openModal } from '../../app/common/modals/modalReducer';
// import { useDispatch } from 'react-redux';

export default function PriceLaborContractType({ setOpenFeaturesTable }) {
	// const dispatch = useDispatch();

	// function under5LCYTOpen() {
	// 	dispatch(openModal({ modalType: 'Under5LaborContract' }));
	// }

	// function regularLCYTOpen() {
	// 	dispatch(openModal({ modalType: 'RegularLaborContract' }));
	// }

	// function wholeWagesLCYTOpen() {
	// 	dispatch(openModal({ modalType: 'WholeWagesLaborContract' }));
	// }

	// function seeWorkerLCYTOpen() {
	// 	dispatch(openModal({ modalType: 'SeeWorkerLaborContract' }));
	// }

	// function outSideWorkerLCYTOpen() {
	// 	dispatch(openModal({ modalType: 'OutSideWorkerLaborContract' }));
	// }

	// function shortTimeWorkerLCYTOpen() {
	// 	dispatch(openModal({ modalType: 'ShortTimeWorkerLaborContract' }));
	// }

	// function under15ShortTimeWorkerLCYTOpen() {
	// 	dispatch(openModal({ modalType: 'Under15ShortTimeWorkerLaborContract' }));
	// }

	// function periodWorkerLCYTOpen() {
	// 	dispatch(openModal({ modalType: 'PeriodWorkerLaborContract' }));
	// }

	// function retiredPeriodWorkerLCYTOpen() {
	// 	dispatch(openModal({ modalType: 'RetiredPeriodWorkerLaborContract' }));
	// }

	// function traineeWorkerLCYTOpen() {
	// 	dispatch(openModal({ modalType: 'TraineeWorkerLaborContract' }));
	// }

	// function under18AgeWorkerLCYTOpen() {
	// 	dispatch(openModal({ modalType: 'Under18AgeWorkerLaborContract' }));
	// }
	return (
		<div style={{ cursor: 'pointer' }}>
			<Grid onClick={() => setOpenFeaturesTable(!setOpenFeaturesTable)}>
				<Grid.Column width={16}>
					<Table className='center'>
						<Table.Header>
							<Table.Row>
								<Table.HeaderCell>구분</Table.HeaderCell>
								<Table.HeaderCell>
									5인미만
									<br />
									근기법 §11
								</Table.HeaderCell>
								<Table.HeaderCell>
									정규직
									<br />
									근기법 전반
								</Table.HeaderCell>
								<Table.HeaderCell>
									포괄역산
									<br />
									근기법 §17
								</Table.HeaderCell>
								<Table.HeaderCell>
									감단직
									<br />
									근기법 §63
								</Table.HeaderCell>
								<Table.HeaderCell>
									간주직
									<br />
									근기법 §58
								</Table.HeaderCell>
								<Table.HeaderCell>
									단시간
									<br />
									근기법§ 2
								</Table.HeaderCell>
								<Table.HeaderCell>
									초단시간
									<br />
									근기법 §18
								</Table.HeaderCell>
								<Table.HeaderCell>
									계약직
									<br />
									기단법§ 2
								</Table.HeaderCell>
								<Table.HeaderCell>
									촉탁직
									<br />
									고촉법 §21
								</Table.HeaderCell>
								<Table.HeaderCell>
									수습직
									<br />
									근기법 전반
								</Table.HeaderCell>
								<Table.HeaderCell>
									연소근로자
									<br />
									근기법 5장
								</Table.HeaderCell>
							</Table.Row>
						</Table.Header>
						{/* <Table.Body style={{ textAlign: 'center' }}>
							<Table.Row>
								<Table.Cell>
									SafeHR 기능 / <br />
									노동법 설명
								</Table.Cell>
								<Table.Cell>
									<Button color='youtube' onClick={() => under5LCYTOpen()}>
										<Icon name='youtube' />
									</Button>
								</Table.Cell>
								<Table.Cell>
									<Button color='youtube' onClick={() => regularLCYTOpen()}>
										<Icon name='youtube' />
									</Button>
								</Table.Cell>
								<Table.Cell>
									<Button color='youtube' onClick={() => wholeWagesLCYTOpen()}>
										<Icon name='youtube' />
									</Button>
								</Table.Cell>
								<Table.Cell>
									<Button color='youtube' onClick={() => seeWorkerLCYTOpen()}>
										<Icon name='youtube' />
									</Button>
								</Table.Cell>
								<Table.Cell>
									<Button
										color='youtube'
										onClick={() => outSideWorkerLCYTOpen()}>
										<Icon name='youtube' />
									</Button>
								</Table.Cell>
								<Table.Cell>
									<Button
										color='youtube'
										onClick={() => shortTimeWorkerLCYTOpen()}>
										<Icon name='youtube' />
									</Button>
								</Table.Cell>
								<Table.Cell>
									<Button
										color='youtube'
										onClick={() => under15ShortTimeWorkerLCYTOpen()}>
										<Icon name='youtube' />
									</Button>
								</Table.Cell>
								<Table.Cell>
									<Button
										color='youtube'
										onClick={() => periodWorkerLCYTOpen()}>
										<Icon name='youtube' />
									</Button>
								</Table.Cell>
								<Table.Cell>
									<Button
										color='youtube'
										onClick={() => retiredPeriodWorkerLCYTOpen()}>
										<Icon name='youtube' />
									</Button>
								</Table.Cell>
								<Table.Cell>
									<Button
										color='youtube'
										onClick={() => traineeWorkerLCYTOpen()}>
										<Icon name='youtube' />
									</Button>
								</Table.Cell>
								<Table.Cell>
									<Button
										color='youtube'
										onClick={() => under18AgeWorkerLCYTOpen()}>
										<Icon name='youtube' />
									</Button>
								</Table.Cell>
							</Table.Row>
							<Table.Row>
								<Table.Cell>
									소정근로시간
									<br />
									근기법 §50
								</Table.Cell>
								<td style={{ color: 'red' }}>적용제외</td>
								<Table.Cell>적용</Table.Cell>
								<Table.Cell>적용</Table.Cell>
								<td style={{ color: 'red' }}>적용제외</td>
								<Table.Cell>적용</Table.Cell>
								<Table.Cell>적용</Table.Cell>
								<Table.Cell>적용</Table.Cell>
								<Table.Cell>적용</Table.Cell>
								<Table.Cell>적용</Table.Cell>
								<Table.Cell>적용</Table.Cell>
								<td style={{ color: 'red' }}>
									미적용
									<br />일 7시간 <br />주 35시간
								</td>
							</Table.Row>
							<Table.Row>
								<Table.Cell>
									주휴일
									<br />
									근기법 §55
								</Table.Cell>
								<Table.Cell>적용</Table.Cell>
								<Table.Cell>적용</Table.Cell>
								<Table.Cell>적용</Table.Cell>
								<td style={{ color: 'red' }}>적용제외</td>
								<Table.Cell>적용</Table.Cell>
								<Table.Cell>적용</Table.Cell>
								<td style={{ color: 'red' }}>적용제외</td>
								<Table.Cell>적용</Table.Cell>
								<Table.Cell>적용</Table.Cell>
								<Table.Cell>적용</Table.Cell>
								<Table.Cell>적용</Table.Cell>
							</Table.Row>
							<Table.Row>
								<Table.Cell>
									관공서휴일
									<br />
									근기법 §55
								</Table.Cell>
								<td style={{ color: 'red' }}>적용제외</td>
								<Table.Cell>적용</Table.Cell>
								<Table.Cell>적용</Table.Cell>
								<td style={{ color: 'red' }}>적용제외</td>
								<Table.Cell>적용</Table.Cell>
								<Table.Cell>적용</Table.Cell>
								<td style={{ color: 'red' }}>적용제외</td>
								<Table.Cell>적용</Table.Cell>
								<Table.Cell>적용</Table.Cell>
								<Table.Cell>적용</Table.Cell>
								<Table.Cell>적용</Table.Cell>
							</Table.Row>
							<Table.Row>
								<Table.Cell>
									휴게시간
									<br />
									근기법 §54
								</Table.Cell>
								<Table.Cell>적용</Table.Cell>
								<Table.Cell>적용</Table.Cell>
								<Table.Cell>적용</Table.Cell>
								<td style={{ color: 'red' }}>적용제외</td>
								<Table.Cell>적용</Table.Cell>
								<Table.Cell>적용</Table.Cell>
								<Table.Cell>적용</Table.Cell>
								<Table.Cell>적용</Table.Cell>
								<Table.Cell>적용</Table.Cell>
								<Table.Cell>적용</Table.Cell>
								<Table.Cell>적용</Table.Cell>
							</Table.Row>
							<Table.Row>
								<Table.Cell>
									연장수당
									<br />
									근기법 §56
								</Table.Cell>
								<td style={{ color: 'red' }}>적용제외</td>
								<Table.Cell>적용</Table.Cell>
								<Table.Cell>적용</Table.Cell>
								<td style={{ color: 'red' }}>적용제외</td>
								<td style={{ color: 'red' }}>적용제외</td>
								<Table.Cell>적용</Table.Cell>
								<Table.Cell>적용</Table.Cell>
								<Table.Cell>적용</Table.Cell>
								<Table.Cell>적용</Table.Cell>
								<Table.Cell>적용</Table.Cell>
								<td style={{ color: 'red' }}>
									일7시간
									<br />
									주35시간
									<br />
									이상시 적용
								</td>
							</Table.Row>
							<Table.Row>
								<Table.Cell>
									휴일수당
									<br />
									근기법 §56
								</Table.Cell>
								<td style={{ color: 'red' }}>적용제외</td>
								<Table.Cell>적용</Table.Cell>
								<Table.Cell>적용</Table.Cell>
								<td style={{ color: 'red' }}>적용제외</td>
								<Table.Cell>적용</Table.Cell>
								<Table.Cell>적용</Table.Cell>
								<Table.Cell>적용</Table.Cell>
								<Table.Cell>적용</Table.Cell>
								<Table.Cell>적용</Table.Cell>
								<Table.Cell>적용</Table.Cell>
								<td style={{ color: 'red' }}>
									노동부인가시 <br />
									적용
								</td>
							</Table.Row>
							<Table.Row>
								<Table.Cell>
									야간수당
									<br />
									근기법 §56
								</Table.Cell>
								<td style={{ color: 'red' }}>적용제외</td>
								<Table.Cell>적용</Table.Cell>
								<Table.Cell>적용</Table.Cell>
								<Table.Cell>적용</Table.Cell>
								<Table.Cell>적용</Table.Cell>
								<Table.Cell>적용</Table.Cell>
								<Table.Cell>적용</Table.Cell>
								<Table.Cell>적용</Table.Cell>
								<Table.Cell>적용</Table.Cell>
								<Table.Cell>적용</Table.Cell>
								<td style={{ color: 'red' }}>
									노동부인가시 <br />
									적용
								</td>
							</Table.Row>
							<Table.Row>
								<Table.Cell>
									연차휴가
									<br />
									근기법 §60
								</Table.Cell>
								<td style={{ color: 'red' }}>적용제외</td>
								<Table.Cell>적용</Table.Cell>
								<Table.Cell>적용</Table.Cell>
								<Table.Cell>적용</Table.Cell>
								<Table.Cell>적용</Table.Cell>
								<Table.Cell>적용</Table.Cell>
								<td style={{ color: 'red' }}>적용제외</td>
								<Table.Cell>적용</Table.Cell>
								<td style={{ color: 'red' }}>단절</td>
								<Table.Cell>적용</Table.Cell>
								<Table.Cell>적용</Table.Cell>
							</Table.Row>
							<Table.Row>
								<Table.Cell>
									해고
									<br />
									근기법 §23
								</Table.Cell>
								<td style={{ color: 'red' }}>적용제외</td>
								<Table.Cell>적용</Table.Cell>
								<Table.Cell>적용</Table.Cell>
								<Table.Cell>적용</Table.Cell>
								<Table.Cell>적용</Table.Cell>
								<Table.Cell>적용</Table.Cell>
								<Table.Cell>적용</Table.Cell>
								<Table.Cell>적용</Table.Cell>
								<Table.Cell>적용</Table.Cell>
								<Table.Cell>적용</Table.Cell>
								<Table.Cell>적용</Table.Cell>
							</Table.Row>
							<Table.Row>
								<Table.Cell>
									퇴직금
									<br />
									근퇴법 전반
								</Table.Cell>
								<Table.Cell>적용</Table.Cell>
								<Table.Cell>적용</Table.Cell>
								<Table.Cell>적용</Table.Cell>
								<Table.Cell>적용</Table.Cell>
								<Table.Cell>적용</Table.Cell>
								<Table.Cell>적용</Table.Cell>
								<td style={{ color: 'red' }}>적용제외</td>
								<Table.Cell>적용</Table.Cell>
								<td style={{ color: 'red' }}>단절</td>
								<Table.Cell>적용</Table.Cell>
								<Table.Cell>적용</Table.Cell>
							</Table.Row>
							<Table.Row>
								<Table.Cell>
									임금명세서
									<br />
									연동
								</Table.Cell>
								<Table.Cell>적용</Table.Cell>
								<Table.Cell>적용</Table.Cell>
								<Table.Cell>적용</Table.Cell>
								<Table.Cell>적용</Table.Cell>
								<Table.Cell>적용</Table.Cell>
								<Table.Cell>적용</Table.Cell>
								<Table.Cell>적용</Table.Cell>
								<Table.Cell>적용</Table.Cell>
								<Table.Cell>적용</Table.Cell>
								<Table.Cell>적용</Table.Cell>
								<Table.Cell>적용</Table.Cell>
							</Table.Row>
							<Table.Row>
								<Table.Cell>
									취업규칙
									<br />
									연동
								</Table.Cell>
								<td style={{ color: 'red' }}>적용제외</td>
								<Table.Cell>적용</Table.Cell>
								<Table.Cell>적용</Table.Cell>
								<Table.Cell>적용</Table.Cell>
								<Table.Cell>적용</Table.Cell>
								<Table.Cell>적용</Table.Cell>
								<Table.Cell>적용</Table.Cell>
								<Table.Cell>적용</Table.Cell>
								<Table.Cell>적용</Table.Cell>
								<Table.Cell>적용</Table.Cell>
								<Table.Cell>적용</Table.Cell>
							</Table.Row>
						</Table.Body> */}
					</Table>
				</Grid.Column>
			</Grid>
			<br />
		</div>
	);
}
