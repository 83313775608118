import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import WorkerRegisterListDashboard from '../../clientsRequest/clientReq4In/clientsReqDashboard4In/WorkerRegisterListDashboard';
import PaydocuShortCut from './PaydocuShortCut';
import { clearClientReqs4In, fetchClientReqs4In } from '../../clientsRequest/clientReq4In/clientReqActions4In';

export default function PaydocuWorkerCodeDashboard() {
  const dispatch = useDispatch();
  const { filter } = useSelector(state => state.companyReducer);
  const { currentUserProfile } = useSelector(state => state.profile);
  const { clientReqs4In } = useSelector((state) => state.clientReq4InStore);

  const isWorker = currentUserProfile?.isWorker;
  
  useEffect(() => {
    const fetchClientReqs = async () => {
      try {
        await dispatch(fetchClientReqs4In(filter));
      } catch (error) {
        console.error("Error fetching client requests:", error);
      }
    };
  
    fetchClientReqs();
  
    return () => {
      dispatch(clearClientReqs4In());
    };
  }, [dispatch, filter]);

  const title = '직원 등록 코드'

  return (
    <>
      {isWorker ? null :
      <>
        <PaydocuShortCut filter={filter} clientReqs4In={clientReqs4In}/>
        <hr />
        <div style={{ display: 'flex', alignItems: 'center', alignContent: 'center' }}>
          <span style={{ fontSize: '23px', color: 'darkBlue' }}>{title}</span>
        </div>
        <WorkerRegisterListDashboard title = {title} />
        <hr />
        <br />
      </>
      }
    </>
  );
};