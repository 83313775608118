import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Grid, Input, Loader } from 'semantic-ui-react';
import { clearPaymentsInfo , fetchPaymentsInfoFormFirestoreService4In } from '../companyActions';
import PaymentsInfoList from './PaymentsInfoList';

export default function PaymentsInfoDashboard() {
  const dispatch = useDispatch();
  const { paymentsInfo } = useSelector(state => state.companyReducer);

  const { loading } = useSelector(state => state.async);
  const { authenticated } = useSelector(state => state.auth);
  const authLevel   = useSelector(state => state.profile?.currentUserProfile?.authLevel);
  const [searchTerm, setSearchTerm] = useState("");
  const [searchResults, setSearchResults] = useState([]);
  const handleChange = event => {
    setSearchTerm(event.target.value);
  };

  useEffect(() => {
    const fetchPaymentsInfo = async () => {
      try {
        await dispatch(fetchPaymentsInfoFormFirestoreService4In());
      } catch (error) {
        console.error("Error fetching payments info:", error);
      }
    };
  
    fetchPaymentsInfo();
  
    return () => {
      dispatch(clearPaymentsInfo());
    };
  }, [dispatch]);
  

  return (
    <>
    {authLevel >= 100 ? "회사명, 영업자, 회원등급" : "직원성명"}
      : <Input
        type="text"
        placeholder="Search"
        value={searchTerm}
        onChange={handleChange}
      />
      <Grid>
        <Grid.Column width={16}>
          <>
            <hr />
            {authenticated &&
            <PaymentsInfoList 
              searchResults = {searchResults}
              setSearchResults = {setSearchResults}
              searchTerm = {searchTerm}
              paymentsInfo={paymentsInfo}
            />}
          </>
        </Grid.Column>
        <Grid.Column width={16}>
          <Loader active={loading} />
        </Grid.Column>
      </Grid>
    </>
  );
};