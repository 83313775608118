import React from 'react';
import { Table } from 'semantic-ui-react';

export default function ClientReqDLCWageTableAddDeductWageDetail({
	plusMinus4Ins,
	handelInputChange,
	centerStyle,
	inputTimeStyle,
	plusDeductHealthCare,
	plusDeductOldCare,
	plusDeductWorkTax,
	plusDeductWorkRegionTax,
	plusDeductDuru,
	plusDeductWorkerCompanyLoan,
	isRetireMonth,
	dataOfFinalRetireHealthCareRecalFee,
	dataOfFinalRetireOldCareRecalFee,
	savedFinalRetireHealthCareRecalFee,
	savedFinalRetireOldCareRecalFee,
}) {
	return (
		<Table.Row>
			<Table.Cell className='gubun' style={centerStyle}>
				<input
					style={inputTimeStyle}
					name='plusDeductHealthCare'
					value={plusDeductHealthCare}
					onChange={(e) => handelInputChange(e)}
					autoComplete='off'
				/>
			</Table.Cell>
			<Table.Cell className='gubun' style={centerStyle}>
				<input
					style={inputTimeStyle}
					name='plusDeductOldCare'
					value={plusDeductOldCare}
					onChange={(e) => handelInputChange(e)}
					autoComplete='off'
				/>
			</Table.Cell>
			<Table.Cell className='gubun' style={centerStyle}>
				<input
					style={inputTimeStyle}
					name='plusDeductWorkTax'
					value={plusDeductWorkTax}
					onChange={(e) => handelInputChange(e)}
					autoComplete='off'
				/>
			</Table.Cell>
			<Table.Cell className='gubun' style={centerStyle}>
				<input
					style={inputTimeStyle}
					name='plusDeductWorkRegionTax'
					value={plusDeductWorkRegionTax}
					onChange={(e) => handelInputChange(e)}
					autoComplete='off'
				/>
			</Table.Cell>
			<Table.Cell className='gubun' style={centerStyle}>
				<input
					style={inputTimeStyle}
					name='plusDeductWorkerCompanyLoan'
					value={plusDeductWorkerCompanyLoan}
					onChange={(e) => handelInputChange(e)}
					autoComplete='off'
				/>
			</Table.Cell>
			<Table.Cell className='gubun' style={centerStyle}>
				<input
					style={inputTimeStyle}
					name='plusDeductDuru'
					value={plusDeductDuru}
					onChange={(e) => handelInputChange(e)}
					autoComplete='off'
				/>
			</Table.Cell>
			<Table.Cell className='gubun' style={centerStyle}>
				<input
					style={inputTimeStyle}
					name='plusMinus4Ins'
					value={plusMinus4Ins}
					onChange={(e) => handelInputChange(e)}
					autoComplete='off'
				/>
			</Table.Cell>
			{isRetireMonth ? (
				<>
					<Table.Cell className='gubun' style={centerStyle}>
						<input
							style={inputTimeStyle}
							name='finalRetireHealthCareRecalFee'
							defaultValue={
								dataOfFinalRetireHealthCareRecalFee ||
								savedFinalRetireHealthCareRecalFee
							}
							onChange={(e) => handelInputChange(e)}
							autoComplete='off'
						/>
					</Table.Cell>
					<Table.Cell className='gubun' style={centerStyle}>
						<input
							style={inputTimeStyle}
							name='finalRetireOldCareRecalFee'
							defaultValue={
								dataOfFinalRetireOldCareRecalFee ||
								savedFinalRetireOldCareRecalFee
							}
							onChange={(e) => handelInputChange(e)}
							autoComplete='off'
						/>
					</Table.Cell>
				</>
			) : (
				<>
					<Table.Cell></Table.Cell>
					<Table.Cell></Table.Cell>
				</>
			)}
		</Table.Row>
	);
}
