import React from 'react'


export default function WorkerFormTimeComments({MyTextArea}) {


  return (
    <div style={{ display : 'flex', flexWrap : 'wrap', justifyContent: 'flex-start', alignItems : 'center', alignContent : 'flex-end'}}>
      <div className='margin' style={{width : '99%', marginRight : '3px', marginTop:'50px'}}>
        <span>
      ●  유연근무제 등 근로시간에 대한 특약 등 안내사항(근로계약서 제5조 "근무일, 시업종업시간, 휴게, 연장/야간/휴일근로" 조항 하단에 표시됨)
        </span>
      </div>
      <div className='margin' style={{width : '99.5%', marginRight : '3px'}}> 
        <MyTextArea
          multiple
          label='(10) 근무시간 특약사항 안내' 
          placeholder='예) 위 근무시간은 2주단위 탄력적 근로시간제가 적용된 근무시간임. ' 
          name='workTimeComment' 
          rows='2'
        />
      </div>
    </div> 
  )
}
