// import { format } from 'date-fns';
import React, { useEffect, useState } from 'react';
import { Button, Grid, Tab, Table } from 'semantic-ui-react';
import Roe1GeneralRules from './ruleOfLaborCouncilDetail/Roe1GeneralRules';
import Roe2Council from './ruleOfLaborCouncilDetail/Roe2Council';
import Roe3WorkerRules from './ruleOfLaborCouncilDetail/Roe3WorkerRules';
import Roe6SufferDealRules from './ruleOfLaborCouncilDetail/Roe6SufferDealRules';
import Roe7ReviseRules from './ruleOfLaborCouncilDetail/Roe7ReviseRules';
import {
	josaUnType,
	josaGaType,
	josaUlType,
	josaWaType,
	printRoe,
} from '../../../app/common/util/util';
import Roe4OperationRules from './ruleOfLaborCouncilDetail/Roe4OperationRules';
import Roe5CouncilDutyRules from './ruleOfLaborCouncilDetail/Roe5CouncilDutyRules';
import PaydocuShortCut from '../paydocuDashboard/PaydocuShortCut';
import { useDispatch, useSelector } from 'react-redux';
import {
	clearClientReqs4In,
	fetchClientReqs4In,
} from '../../clientsRequest/clientReq4In/clientReqActions4In';

export default function RuleOfLaborCouncilContents({
	profile,
	filter,
	clientInfo,
	serviceStartDate,
	rlcExcuteDueDate,
}) {
	const dispatch = useDispatch();

	const { clientReqs4In } = useSelector((state) => state.clientReq4InStore);
	const [sectionCount1, setSectionCount1] = useState(0); // 조항 수를 관리
	const [sectionCount2, setSectionCount2] = useState(0); // 조항 수를 관리
	const [sectionCount3, setSectionCount3] = useState(0); // 조항 수를 관리
	const [sectionCount4, setSectionCount4] = useState(0); // 조항 수를 관리
	const [sectionCount5, setSectionCount5] = useState(0); // 조항 수를 관리
	const [sectionCount6, setSectionCount6] = useState(0); // 조항 수를 관리

	useEffect(() => {
		dispatch(fetchClientReqs4In(filter)).then(() => {});
		return () => {
			dispatch(clearClientReqs4In());
		};
	}, [dispatch, filter]);

	//노무사는 노무사가 입력한 회사로 회사이름 설정 가능하고, 유저는 본인 회원가입한 상호로만 회사이름 설정됨.
	const isWorker = profile?.isWorker;
	const authLevel = clientInfo?.authLevel
		? clientInfo?.authLevel
		: profile?.authLevel;
	const companyName = isWorker
		? profile?.workerInputCompanyName
			? profile?.workerInputCompanyName
			: profile?.companyName
		: profile?.companyName;

	const company = companyName;
	const companyUn = josaUnType(company);
	const companyGa = josaGaType(company);
	const companyWa = josaWaType(company);
	const companyUl = josaUlType(company);

	const [allSectionsOpen, setAllSectionsOpen] = useState(false);

	const toggleAllSections = () => {
		setAllSectionsOpen(!allSectionsOpen);
	};

	const printFunc = () => {
		printRoe();
	};

	return (
		<>
			{isWorker ? null : (
				<PaydocuShortCut filter={filter} clientReqs4In={clientReqs4In} />
			)}

			<Tab.Pane>
				<Grid>
					<Grid.Column width={16}>
						<div style={{ marginBottom: '20px' }}>
							<Button type='button' color='blue' onClick={() => printFunc()}>
								노사협의회 출력
							</Button>
							<Button type='button' color='green' onClick={toggleAllSections}>
								{allSectionsOpen ? '모든 조항 닫기' : '모든 조항 열기'}
							</Button>
						</div>
						<>
							<div id='roeContents'>
								<Table width='100%' align='center'>
									<Table.Body>
										<Table.Row>
											<Table.Cell align='center'>
												<h1
													style={{
														textAlign: 'center',
														margin: '30px',
														fontSize: '30px',
													}}>{`${profile.companyName} 노사협의회 규정`}</h1>
											</Table.Cell>
										</Table.Row>
									</Table.Body>
								</Table>
								<>
									<Roe1GeneralRules
										company={company}
										companyUn={companyUn}
										allOpen={allSectionsOpen}
										setSectionCount={setSectionCount1}
									/>
									<Roe2Council
										serviceStartDate={serviceStartDate}
										authLevel={authLevel}
										profile={profile}
										company={company}
										companyUn={companyUn}
										companyGa={companyGa}
										companyWa={companyWa}
										companyUl={companyUl}
										allOpen={allSectionsOpen}
										setSectionCount={setSectionCount2}
										previousSectionCount1={sectionCount1}
									/>
									<Roe3WorkerRules
										serviceStartDate={serviceStartDate}
										allOpen={allSectionsOpen}
										setSectionCount={setSectionCount3}
										previousSectionCount2={sectionCount2}
										previousSectionCount1={sectionCount1}
									/>
									<Roe4OperationRules
										profile={profile}
										company={company}
										companyUn={companyUn}
										companyGa={companyGa}
										allOpen={allSectionsOpen}
										setSectionCount={setSectionCount4}
										previousSectionCount2={sectionCount2}
										previousSectionCount1={sectionCount1}
										previousSectionCount3={sectionCount3}
									/>
									<Roe5CouncilDutyRules
										company={company}
										companyUn={companyUn}
										companyGa={companyGa}
										companyWa={companyWa}
										allOpen={allSectionsOpen}
										setSectionCount={setSectionCount5}
										previousSectionCount2={sectionCount2}
										previousSectionCount1={sectionCount1}
										previousSectionCount3={sectionCount3}
										previousSectionCount4={sectionCount4}
									/>
									<Roe6SufferDealRules
										company={company}
										companyUn={companyUn}
										companyGa={companyGa}
										companyUl={companyUl}
										companyWa={companyWa}
										allOpen={allSectionsOpen}
										setSectionCount={setSectionCount6}
										previousSectionCount2={sectionCount2}
										previousSectionCount1={sectionCount1}
										previousSectionCount3={sectionCount3}
										previousSectionCount4={sectionCount4}
										previousSectionCount5={sectionCount5}
									/>
									<Roe7ReviseRules
										companyUn={companyUn}
										companyGa={companyGa}
										companyUl={companyUl}
										companyWa={companyWa}
										allOpen={allSectionsOpen}
										previousSectionCount2={sectionCount2}
										previousSectionCount1={sectionCount1}
										previousSectionCount3={sectionCount3}
										previousSectionCount4={sectionCount4}
										previousSectionCount5={sectionCount5}
										previousSectionCount6={sectionCount6}
										rlcExcuteDueDate={rlcExcuteDueDate}
									/>
								</>
								<footer>
									저작권 © SafeHR(주식회사 카이소프트). All rights reserved
									since 2006.
								</footer>
							</div>
						</>
					</Grid.Column>
				</Grid>
			</Tab.Pane>
		</>
	);
}
