import { useEffect, useRef } from 'react';
import { useDispatch } from 'react-redux';
import {
	asyncActionError,
	asyncActionFinish,
	asyncActionStart,
} from '../async/asyncReducer';
import { onSnapshot } from 'firebase/firestore';
import { dataFromSnapshotIdDate } from '../firestore/firestoreService';

export default function useFirestoreDoc({
	query,
	data,
	deps,
	shouldExecute = true,
}) {
	const dispatch = useDispatch();
	const isMounted = useRef(true);

	useEffect(() => {
		isMounted.current = true; // 마운트될 때 true로 설정

		if (!shouldExecute) return;

		const firestoreQuery = query();

		// 쿼리가 null이라면 실행을 건너뜁니다.
		if (firestoreQuery === null) return;

		dispatch(asyncActionStart());

		const unsubscribe = onSnapshot(
			firestoreQuery,
			(snapshot) => {
				if (!isMounted.current) return; // 언마운트되었으면 실행하지 않음

				if (!snapshot.exists()) {
					dispatch(
						asyncActionError({
							code: 'not-found',
							message: 'Could not find document',
						})
					);
					return;
				}
				data(dataFromSnapshotIdDate(snapshot));
				dispatch(asyncActionFinish());
			},
			(error) => {
				if (isMounted.current) {
					console.error('Firestore error:', error);
					dispatch(asyncActionError(error));
				}
			}
		);

		return () => {
			isMounted.current = false; // 언마운트 시 false로 설정
			unsubscribe();
		};
	}, deps); // eslint-disable-line react-hooks/exhaustive-deps
}
