import React from 'react'

function LoadingSpinner() {
  return (
    <div className='overlay'>
      <div className='spinner' />
    </div>
  )
}

export default LoadingSpinner
