import testReducer from '../../features/sandbox/testReducer';
import eventReducer from '../../features/events/eventReducer';
import untactReducer from '../../features/untacts/untactReducer';
import { combineReducers } from 'redux';
import modalReducer from '../common/modals/modalReducer';
import authReducer from '../../features/auth/authReducer';
import asyncReducer from '../async/asyncReducer';
import profileReducer from '../../features/profiles/profileReducer';
import {connectRouter} from 'connected-react-router';
import clientReqReducer4In from '../../features/clientsRequest/clientReq4In/clientReqReducer4In';
import companyReducer from '../../features/companyManage/companyReducer';

const rootReducer = (history) => combineReducers({
  router: connectRouter(history),
  test: testReducer,
  eventStore: eventReducer,
  untactStore: untactReducer,
  clientReq4InStore: clientReqReducer4In,
  companyReducer: companyReducer,
  modals: modalReducer,
  auth: authReducer,
  async: asyncReducer,
  profile: profileReducer,
})

export default rootReducer;