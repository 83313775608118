import React, { useState } from 'react';
import * as Yup from 'yup';
import { Form, Formik } from 'formik';

import { Item } from 'semantic-ui-react';
import { absentDaysYearCntForALF, usedAnnualLeavesInLastYearSumF} from '../../../../app/common/util/util';
import { format } from 'date-fns';
import OpenDetailAnnualLeavesCalc from './OpenDetailAnnualLeavesCalc';
import moment from 'moment';
import MyDateInputValue from '../../../../app/common/form/MyDateInputValue';
import MyNumberInputWorkers from '../../../../app/common/form/MyNumberInputWorkers';

export default function AnnualLeavesCal() {
  const [enterDateV, setEnterDate] = useState('');
  const [finBaseDateV, setFinBaseDate] = useState('');
  const [specificDateV, setSpecificDate] = useState('');
  const [numOfWorkersV, setNumOfWorkers] = useState(5);

  const enterDate = enterDateV ? moment(enterDateV).format("YYYY-MM-DD") : ''; 
  const finBaseDate = finBaseDateV ? moment(finBaseDateV).format("YYYY-MM-DD") : '';
  const specificDate = specificDateV ? moment(specificDateV).format("YYYY-MM-DD") : '';
	const finBaseMonthDay = finBaseDateV ? format(finBaseDateV, '년단위 연차 매년 MM월 dd일 시작') : '';
  const finBaseDay = finBaseDateV ? format(finBaseDateV, '월달위 연차 매월 dd일 시작') : '';
  const thisYear = specificDate ? format(new Date(specificDate), 'yyyy') : format(new Date(), 'yyyy');
	const workerPeriod = moment(new Date()).diff(moment(enterDate), 'month');
  const underOneYearWorkPeriod = workerPeriod < 12

  function secondYearForAnnualLeave(enterDate, thisYear) {
    const enterYear = +moment(enterDate).format('yyyy')
    if (enterYear+1 === +thisYear) {
      return true
    } else {
      return false
    }
  }

  return (
    <>
      <div style={{display: 'flex', alignItems: 'center', alignContent: 'center' }}>
        <span style={{fontSize : '20px', color : 'teal', cursor:'pointer'}}>연차계산기 계산기</span>
      </div>
      <br />
      <hr />
      <br />
      <>
        <Formik
          initialValues = {{
            numberOfWorkers:'',
            enterDateValue: '',
            finBaseDateValue : '',
            specificDateValue :'',
          }}
          validationSchema={Yup.object({
            enterDateValue: Yup.string().required('입사일은 필수입니다.'),
          })}
        >
        {({isSubmitting, isValid, dirty})=>(
          <Form className='ui form'>
            <div style={{ display : 'flex', flexWrap : 'wrap', justifyContent: 'flex-start', alignItems : 'center', alignContent : 'flex-end'}}>
              <div className='margin' style={{width : '9%', marginRight : '3px'}}>
                <MyNumberInputWorkers
                  label='근로자수(명)'
                  name='numberOfWorkers' 
                  placeholder='5인 미만은 연차가 발생되지 않습니다.' 
                  autoComplete='off'
                  min={1}
                  setNumOfWorkers={setNumOfWorkers}
                />
              </div>
              <div className='margin' style={{width : '30%', marginRight : '3px'}}>
                <MyDateInputValue
                  label={<span style={{color: 'blue'}}>* (1) 입사일</span>}                
                  name='enterDateValue' 
                  placeholderText='달력 사용(또는 0000-00-00  형식으로 입력요망)'
                  timeFormat='HH:mm'
                  timeCaption='time'
                  dateFormat='yyyy-MM-dd'
                  autoComplete='off'
                  setInputDate={setEnterDate}
                />
              </div>
              <div className='margin' style={{width : '30%', marginRight : '3px'}}>
                <MyDateInputValue
                  label={<span style={{color: 'blue'}}>* (2) 특정 마지막 근무일(예, 퇴직일 전일)</span>}                
                  name='specificDateValue' 
                  placeholderText='미선택시 오늘날짜로 계산됩니다. 달력 사용(또는 0000-00-00  형식으로 입력요망)'
                  timeFormat='HH:mm'
                  timeCaption='time'
                  dateFormat='yyyy-MM-dd'
                  autoComplete='off'
                  setInputDate={setSpecificDate}
                />
              </div>      
              <div className='margin' style={{width : '30%', marginRight : '3px'}}>
                <MyDateInputValue
                  label={<span style={{color: 'blue'}}>* (3) 회계기준일(해당시)</span>}                
                  name='finBaseDateValue' 
                  placeholderText='달력 사용(또는 0000-00-00  형식으로 입력요망)'
                  timeFormat='HH:mm'
                  timeCaption='time'
                  dateFormat='yyyy-MM-dd'
                  autoComplete='off'
                  setInputDate={setFinBaseDate}
                />
              </div>       
            </div>
            <br />
            </Form>
          )}
        </Formik>
        <br />
        <hr />
        <br />
        <OpenDetailAnnualLeavesCalc 
          specificDate={specificDate}
          thisYear={thisYear}
          Item={Item}
          finBaseDate={finBaseDate}
          finBaseMonthDay={finBaseMonthDay}
          finBaseDay={finBaseDay}
          enterDate={enterDate}
          underOneYearWorkPeriod={underOneYearWorkPeriod}
          absentDaysYearCntForALF={absentDaysYearCntForALF}
          secondYearForAnnualLeave={secondYearForAnnualLeave}
          usedAnnualLeavesInLastYearSumF={usedAnnualLeavesInLastYearSumF}
          numOfWorkers={numOfWorkersV}
        />
      </>
    </>
  )
}
