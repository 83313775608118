import React, { useState, useEffect } from 'react';
import { format, eachDayOfInterval, getDay, subMonths } from 'date-fns';

const NumOfWorkersJudgeCalc = () => {
	const [selectedDate, setSelectedDate] = useState(new Date());
	const [workersPerDay, setWorkersPerDay] = useState({});
	const [weekdayInputs, setWeekdayInputs] = useState({}); // 요일별 직원 수 입력값
	const [isLawApplied, setIsLawApplied] = useState(null); // 법 적용 여부
	const [averageWorkers, setAverageWorkers] = useState(null); // 평균 근로자 수
	const [operatingDays, setOperatingDays] = useState(null); // 가동일수
	const [totalWorkers, setTotalWorkers] = useState(0); // 전체 인원수
	const [belowLawStandardDays, setBelowLawStandardDays] = useState(null); // 5명 미만 일수
	const [belowLawPercentage, setBelowLawPercentage] = useState(null); // 5명 미만 퍼센티지
	const [lawApplicationDetail, setLawApplicationDetail] = useState(''); // 법 적용 상세 여부
	const [selectedCriterion, setSelectedCriterion] = useState(5); // 선택된 기준 (5인 또는 10인)

	// 선택한 날짜를 기준으로 1개월 기간을 계산 (두 달에 걸쳐 있을 수 있음)
	const generateCalendar = (date) => {
		const start = subMonths(date, 1); // 선택된 날짜의 한 달 전
		const startDate = new Date(
			start.getFullYear(),
			start.getMonth(),
			format(date, 'd')
		); // 이전 달의 선택된 일자
		const endDate = new Date(
			date.getFullYear(),
			date.getMonth(),
			format(date, 'd') - 1
		); // 선택된 날짜 바로 전날

		return eachDayOfInterval({ start: startDate, end: endDate });
	};

	const handleWorkerChange = (day, count) => {
		setWorkersPerDay((prev) => ({
			...prev,
			[day]: count ? Number(count) : 0, // 입력된 인원수 저장
		}));
	};

	// 요일별로 직원 수를 입력하면 그 요일의 모든 날짜에 자동으로 값을 채움
	const handleWeekdayInputChange = (weekdayIndex, count) => {
		const newCount = count ? Number(count) : 0;
		setWeekdayInputs((prev) => ({
			...prev,
			[weekdayIndex]: newCount, // 요일별 입력값 저장
		}));

		setWorkersPerDay((prev) => {
			const updatedWorkers = { ...prev };
			daysInMonth.forEach((day) => {
				if (getDay(day) === weekdayIndex) {
					updatedWorkers[day] = newCount;
				}
			});
			return updatedWorkers;
		});
	};

	// 날짜가 변경될 때 직원 수 초기화
	const handleDateChange = (newDate) => {
		setSelectedDate(newDate);
		setWorkersPerDay({}); // 직원 수 초기화
		setWeekdayInputs({}); // 요일별 입력값 초기화
		setLawApplicationDetail(''); // 법 적용 상세 내용 초기화
		setIsLawApplied(null); // 법 적용 여부 초기화
	};

	const handleCriterionChange = (newCriterion) => {
		setSelectedCriterion(newCriterion);
		setWorkersPerDay({}); // 직원 수 초기화
		setWeekdayInputs({}); // 요일별 입력값 초기화
		setLawApplicationDetail(''); // 법 적용 상세 내용 초기화
		setIsLawApplied(null); // 법 적용 여부 초기화
	};

	// 근로자 수 계산 및 법 적용 여부 판단
	const calculateLawApplication = () => {
		const dailyCounts = Object.values(workersPerDay);
		const totalWorkers = dailyCounts.reduce((acc, val) => acc + val, 0);
		const workingDays = dailyCounts.filter((count) => count > 0).length; // 인원 입력된 일수 (가동일수)
		const belowLawDays = dailyCounts.filter(
			(count) => count > 0 && count < selectedCriterion
		).length; // 5명 미만인 날

		if (workingDays > 0) {
			setAverageWorkers(totalWorkers / workingDays); // 평균 근로자 수
		} else {
			setAverageWorkers(0);
		}

		setOperatingDays(workingDays); // 가동일수 설정
		setTotalWorkers(totalWorkers); // 전체 근무 인원수 설정
		setBelowLawStandardDays(belowLawDays); // 5명 미만 일수 설정
		setBelowLawPercentage((belowLawDays / workingDays) * 100); // 5명 미만 퍼센티지 (가동일수 기준) 계산

		// 법 적용 여부 상세 조건
		if (
			totalWorkers / workingDays >= selectedCriterion &&
			belowLawDays / workingDays < 0.5
		) {
			setIsLawApplied(true);
			setLawApplicationDetail(
				`상시근로자수가 ${selectedCriterion}인 이상이므로 법 적용 대상입니다.`
			);
		} else if (
			totalWorkers / workingDays >= selectedCriterion &&
			belowLawDays / workingDays >= 0.5
		) {
			setIsLawApplied(false);
			setLawApplicationDetail(
				`상시근로자수가 ${selectedCriterion}인 이상이지만 ${selectedCriterion}인 미만인 가동일수가 50% 이상이므로 법 적용 대상이 아닙니다.`
			);
		} else if (
			totalWorkers / workingDays < selectedCriterion &&
			belowLawDays / workingDays < 0.5
		) {
			setIsLawApplied(true);
			setLawApplicationDetail(
				`상시근로자수가 ${selectedCriterion}인 미만이지만 ${selectedCriterion}인 미만인 일수가 50% 미만이므로 법 적용 대상입니다.`
			);
		} else if (totalWorkers / workingDays < selectedCriterion) {
			setIsLawApplied(false);
			setLawApplicationDetail(
				`상시근로자수가 ${selectedCriterion}인 미만이므로 법 적용 대상이 아닙니다.`
			);
		}
	};

	// 근로자 수 입력값이 변경될 때마다 자동으로 계산 수행
	useEffect(() => {
		calculateLawApplication();
	}, [workersPerDay, selectedCriterion]); // eslint-disable-line react-hooks/exhaustive-deps

	const daysInMonth = generateCalendar(selectedDate);

	// 날짜를 주별로 분할하는 함수
	const splitIntoWeeks = (days) => {
		const weeks = [];
		let currentWeek = [];

		// 첫 번째 주의 빈칸 채우기 (요일 맞추기)
		for (let i = 0; i < getDay(days[0]); i++) {
			currentWeek.push(null); // null로 빈칸 추가
		}

		days.forEach((day) => {
			currentWeek.push(day);
			if (currentWeek.length === 7) {
				weeks.push(currentWeek);
				currentWeek = [];
			}
		});

		// 마지막 주 남은 빈칸 채우기
		if (currentWeek.length > 0) {
			while (currentWeek.length < 7) {
				currentWeek.push(null);
			}
			weeks.push(currentWeek);
		}

		return weeks;
	};

	const weeksInMonth = splitIntoWeeks(daysInMonth);

	const headerStyle = {
		fontSize: '1.2rem',
		padding: '10px',
		border: '1px solid #ddd',
		backgroundColor: '#4a90e2', // 요일의 배경색을 파란색으로 설정
		color: '#ffffff', // 요일 텍스트 색상을 흰색으로 설정
		textAlign: 'center',
	};

	const resultStyle = {
		fontSize: '1.1rem',
		marginBottom: '10px',
	};

	const renderLawText = () => {
		return (
			<div>
				<h3 style={{ fontSize: '1.5rem', marginBottom: '15px', color: '#333' }}>
					근로기준법 시행령 제7조의2(상시 사용하는 근로자 수의 산정 방법)
				</h3>
				<p style={{ fontSize: '1.1rem', lineHeight: '1.6', color: '#333' }}>
					<strong>①</strong> 법 제11조제3항에 따른 “상시 사용하는 근로자 수”는
					해당 사업 또는 사업장에서 법 적용 사유(휴업수당 지급, 근로시간 적용 등
					법 또는 이 영의 적용 여부를 판단하여야 하는 사유를 말한다. 이하 이
					조에서 같다) 발생일 전 1개월(사업이 성립한 날부터 1개월 미만인
					경우에는 그 사업이 성립한 날 이후의 기간을 말한다. 이하 “산정기간”이라
					한다) 동안 사용한 근로자의 연인원을 같은 기간 중의 가동 일수로 나누어
					산정한다.
				</p>
				<p style={{ fontSize: '1.1rem', lineHeight: '1.6', color: '#333' }}>
					<strong>②</strong> 제1항에도 불구하고 다음 각 호의 구분에 따라 그 사업
					또는 사업장에 대하여 5명(법 제93조의 적용 여부를 판단하는 경우에는
					10명을 말한다. 이하 이 조에서 “법 적용 기준”이라 한다) 이상의 근로자를
					사용하는 사업 또는 사업장(이하 이 조에서 “법 적용 사업 또는
					사업장”이라 한다)으로 보거나 법 적용 사업 또는 사업장으로 보지 않는다.
				</p>
				<p
					style={{
						fontSize: '1.1rem',
						lineHeight: '1.6',
						color: '#333',
						paddingLeft: '20px',
					}}>
					<strong>1.</strong> 법 적용 사업 또는 사업장으로 보는 경우: 제1항에
					따라 해당 사업 또는 사업장의 근로자 수를 산정한 결과 법 적용 사업 또는
					사업장에 해당하지 않는 경우에도 산정기간에 속하는 일(日)별로 근로자
					수를 파악하였을 때 법 적용 기준에 미달한 일수(日數)가 2분의 1 미만인
					경우.
				</p>
				<p
					style={{
						fontSize: '1.1rem',
						lineHeight: '1.6',
						color: '#333',
						paddingLeft: '20px',
					}}>
					<strong>2.</strong> 법 적용 사업 또는 사업장으로 보지 않는 경우:
					제1항에 따라 해당 사업 또는 사업장의 근로자 수를 산정한 결과 법 적용
					사업 또는 사업장에 해당하는 경우에도 산정기간에 속하는 일별로 근로자
					수를 파악하였을 때 법 적용 기준에 미달한 일수가 2분의 1 이상인 경우.
				</p>
				<p style={{ fontSize: '1.1rem', lineHeight: '1.6', color: '#333' }}>
					<strong>③</strong> 법 제60조부터 제62조까지의 규정(제60조제2항에 따른
					연차유급휴가에 관한 부분은 제외한다)의 적용 여부를 판단하는 경우에
					해당 사업 또는 사업장에 대하여 제1항 및 제2항에 따라 월 단위로 근로자
					수를 산정한 결과 법 적용 사유 발생일 전 1년 동안 계속하여 5명 이상의
					근로자를 사용하는 사업 또는 사업장은 법 적용 사업 또는 사업장으로
					본다.
				</p>
				<p style={{ fontSize: '1.1rem', lineHeight: '1.6', color: '#333' }}>
					<strong>④</strong> 제1항의 연인원에는 「파견근로자보호 등에 관한
					법률」 제2조제5호에 따른 파견근로자를 제외한 다음 각 호의 근로자
					모두를 포함한다.
				</p>
				<p
					style={{
						fontSize: '1.1rem',
						lineHeight: '1.6',
						color: '#333',
						paddingLeft: '20px',
					}}>
					<strong>1.</strong> 해당 사업 또는 사업장에서 사용하는 통상 근로자,
					「기간제 및 단시간근로자 보호 등에 관한 법률」 제2조제1호에 따른
					기간제근로자, 단시간근로자 등 고용형태를 불문하고 하나의 사업 또는
					사업장에서 근로하는 모든 근로자.
				</p>
				<p
					style={{
						fontSize: '1.1rem',
						lineHeight: '1.6',
						color: '#333',
						paddingLeft: '20px',
					}}>
					<strong>2.</strong> 해당 사업 또는 사업장에 동거하는 친족과 함께
					제1호에 해당하는 근로자가 1명이라도 있으면 동거하는 친족인 근로자.
				</p>
			</div>
		);
	};

	return (
		<div style={{ padding: '20px', maxWidth: '1200px', margin: '0 auto' }}>
			<h1
				style={{ textAlign: 'center', marginBottom: '30px', fontSize: '2rem' }}>
				근로기준법에 따른 상시근로자수 산정 및 법 적용 판단기
			</h1>

			<div
				style={{
					display: 'flex',
					justifyContent: 'center',
					alignItems: 'center',
					marginBottom: '20px',
					gap: '20px', // 추가된 간격
				}}>
				<label
					style={{
						fontSize: '1.2rem',
						fontWeight: 'bold',
						marginRight: '10px',
					}}>
					적용 기준 선택:
				</label>
				<select
					value={selectedCriterion}
					onChange={(e) => handleCriterionChange(Number(e.target.value))} // 드롭다운 변경 핸들러 연결
					style={{
						padding: '10px',
						fontSize: '1rem',
						border: '1px solid #ccc',
						borderRadius: '5px',
						backgroundColor: '#f9f9f9',
						boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
					}}>
					<option value={5}>
						5인 기준 (해고, 근로시간, 연차휴가, 관공서 휴일 등)
					</option>
					<option value={10}>10인 기준 (취업규칙)</option>
				</select>
				<label
					style={{
						fontSize: '1.2rem',
						fontWeight: 'bold',
						marginRight: '10px',
					}}>
					사유발생일 선택:
				</label>
				<input
					type='date'
					value={format(selectedDate, 'yyyy-MM-dd')}
					onChange={(e) => handleDateChange(new Date(e.target.value))} // 날짜 변경 핸들러 연결
					style={{
						padding: '10px',
						fontSize: '1rem',
						border: '1px solid #ccc',
						borderRadius: '5px',
						backgroundColor: '#f9f9f9',
						boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
						width: '250px',
					}}
				/>
			</div>

			<div
				style={{
					display: 'flex',
					justifyContent: 'center',
					alignItems: 'center',
					marginBottom: '20px',
				}}></div>

			<table
				style={{
					width: '100%',
					borderCollapse: 'collapse',
					marginBottom: '30px',
					boxShadow: '0 2px 8px rgba(0, 0, 0, 0.1)',
				}}>
				<thead>
					<tr
						style={{
							backgroundColor: '#f1f1f1',
							borderBottom: '2px solid #ddd',
						}}>
						<th style={headerStyle}>일</th>
						<th style={headerStyle}>월</th>
						<th style={headerStyle}>화</th>
						<th style={headerStyle}>수</th>
						<th style={headerStyle}>목</th>
						<th style={headerStyle}>금</th>
						<th style={headerStyle}>토</th>
					</tr>

					<tr style={{ backgroundColor: '#fafafa' }}>
						{/* 요일별 직원 수 입력 필드 */}
						{Array.from({ length: 7 }, (_, index) => (
							<th key={`weekday-input-${index}`} style={headerStyle}>
								<input
									type='number'
									value={weekdayInputs[index] || ''}
									onChange={(e) =>
										handleWeekdayInputChange(index, e.target.value)
									}
									style={{
										width: '50px',
										padding: '5px',
										borderRadius: '3px',
										border: '1px solid #ccc',
									}}
									placeholder='인원'
								/>
							</th>
						))}
					</tr>
				</thead>
				<tbody>
					{weeksInMonth.map((week, weekIndex) => (
						<tr key={`week-${weekIndex}`}>
							{week.map((day, dayIndex) => (
								<td
									key={
										day
											? format(day, 'yyyy-MM-dd')
											: `empty-${weekIndex}-${dayIndex}`
									}
									style={{
										padding: '10px',
										border: '1px solid #ddd',
										backgroundColor: day ? '#fff' : '#f9f9f9',
										textAlign: 'center',
									}}>
									{day ? (
										<>
											<strong>{format(day, 'M월 d일')}</strong>
											<br />
											<input
												type='number'
												value={workersPerDay[day] || ''}
												onChange={(e) =>
													handleWorkerChange(day, e.target.value)
												}
												style={{
													width: '50px',
													padding: '5px',
													borderRadius: '3px',
													border: '1px solid #ccc',
												}}
												placeholder='인원'
											/>
										</>
									) : null}
								</td>
							))}
						</tr>
					))}
				</tbody>
			</table>

			{averageWorkers !== null && (
				<div
					style={{
						marginTop: '20px',
						padding: '20px',
						border: '1px solid #ddd',
						borderRadius: '8px',
						backgroundColor: '#f9f9f9',
						boxShadow: '0 2px 8px rgba(0,0,0,0.1)',
					}}>
					<h3
						style={{ fontSize: '1.5rem', marginBottom: '15px', color: '#333' }}>
						결과
					</h3>
					<p style={resultStyle}>
						<strong>가동일수:</strong> {operatingDays}일
					</p>
					<p style={resultStyle}>
						<strong>가동일수에 재직한 연인원수(전체명수)</strong> (정규직,
						일용직, 시급직, 동거하는 친족인 근로자 모두 포함): {totalWorkers}명
					</p>
					<p style={resultStyle}>
						<strong>상시근로자수</strong> (연인원수/가동일수):{' '}
						{averageWorkers.toFixed(2)}명
					</p>
					<p style={resultStyle}>
						<strong>가동일수 중 {selectedCriterion}명 미만 일수:</strong>{' '}
						{belowLawStandardDays}일
					</p>
					<p style={resultStyle}>
						<strong>가동일수 중 {selectedCriterion}명 미만(%):</strong>{' '}
						{isNaN(belowLawPercentage) ? '0.00' : belowLawPercentage.toFixed(2)}
						%
					</p>
					<p
						style={{
							...resultStyle,
							color:
								isLawApplied === null
									? 'black'
									: isLawApplied
									? 'green'
									: 'red',
						}}>
						<strong>법 적용 여부:</strong>{' '}
						{isLawApplied === null
							? ''
							: isLawApplied
							? '법 적용 대상'
							: '법 적용 대상 아님'}
					</p>

					<p
						style={{
							fontSize: '1.1rem',
							marginTop: '15px',
							lineHeight: '1.6',
							color: '#555',
						}}>
						<strong>법 적용 여부 설명:</strong> {lawApplicationDetail}
					</p>

					<hr
						style={{
							marginTop: '20px',
							marginBottom: '20px',
							border: '1px solid #ddd',
						}}
					/>
					{/* 법 조문 내용 */}
					{renderLawText()}
				</div>
			)}
		</div>
	);
};

export default NumOfWorkersJudgeCalc;
