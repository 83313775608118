import React from 'react'
import { Table } from 'semantic-ui-react'
import { childCompanyCOWTitleName } from '../../../../../../../app/common/util/util'

export default function ClientReqDLCWageTableDetailTitle({
  weekLBTime,
  gumakStyle,
  cOWTitle,
  cOWTitleComment,
  isExceptTimeLawWorker,
  companyName,
}) {

  return (
    <>
      <Table.Cell className='gubun'>통상임금 {(weekLBTime < 15 || isExceptTimeLawWorker) ? null : <> <br /> (주휴포함)</>}</Table.Cell>
      <Table.Cell className='hangmok'>{childCompanyCOWTitleName(companyName)}</Table.Cell>
      <Table.Cell className='gumak' style={gumakStyle}>{cOWTitle}</Table.Cell>
      <Table.Cell textAlign='left' colSpan='6' className='sulmung'>{ cOWTitleComment }</Table.Cell>
    </>
  )
}
