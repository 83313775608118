import React, { useEffect, useState } from 'react'
import { Table } from 'semantic-ui-react'
import { calALFinTodBagicYear, calALFinTodPlusYear, calALFinTodRatioYear } from '../../../../annualLeave-libs/finALTod-lib'
import { calALMonth, calALSpecificBagicYear, calALSpecificMonth, calALSpecificPlusYear } from '../../../../annualLeave-libs/lawALSpec-lib'
import { calALTodBagicYear, calALTodMonth, calALTodPlusYear } from '../../../../annualLeave-libs/lawALTod-lib'
import OpenDetailAnnualLeavesCalcPastYear from './OpenDetailAnnualLeavesCalcPastYear';
import { calALFinSpecificBagicYear, calALFinSpecificPlusYear, calALFinSpecificRatioYear } from '../../../../annualLeave-libs/finALSpec-lib';
import { apiService } from '../../../../annualLeave-libs/calculateAnnualLeaveCalALFinMonthApiService'

export default function OpenDetailAnnualLeavesCalc({
  specificDate,
  thisYear,
  Item,
  finBaseDate,
  finBaseMonthDay,
  finBaseDay,
  enterDate,
  underOneYearWorkPeriod,
  secondYearForAnnualLeave,
  usedAnnualLeavesInLastYearSumF,
  numOfWorkers
}) {

  const [calALFinMonthV, setCalALFinMonthV] = useState(0);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const data = await apiService.calculateAnnualLeaveCalALFinMonth({
          enterDate: enterDate,
          finBaseDate: finBaseDate,
          workersNumber: numOfWorkers,
          specificDate: specificDate
        });
        setCalALFinMonthV(data[0]); // API의 응답 구조에 따라 조정 필요
      } catch (error) {
        console.error('Error fetching data: ', error);
      }
    };

    fetchData();
  }, [enterDate, finBaseDate, numOfWorkers, specificDate]);
  
  const lastYear = +thisYear - 1;
  const lastTwoYear = +thisYear - 2;
  const lastThreeYear = +thisYear - 3;

  const calALFinRatioYearV = specificDate ? calALFinSpecificRatioYear(enterDate, finBaseDate, numOfWorkers, specificDate) : calALFinTodRatioYear(enterDate, finBaseDate, numOfWorkers)
  const calALFinBagicYearV = specificDate ? calALFinSpecificBagicYear(enterDate, finBaseDate, numOfWorkers, specificDate) : calALFinTodBagicYear(enterDate, finBaseDate, numOfWorkers)
  const calALFinPlusYearV = specificDate ? calALFinSpecificPlusYear(enterDate, finBaseDate, numOfWorkers, specificDate) : calALFinTodPlusYear(enterDate, finBaseDate, numOfWorkers)
  const sumCalALFin = 
    (calALFinMonthV ? calALFinMonthV : 0)
    + (calALFinRatioYearV ? calALFinRatioYearV : 0)
    + (calALFinBagicYearV ? calALFinBagicYearV : 0)
    + (calALFinPlusYearV ? calALFinPlusYearV : 0)

  secondYearForAnnualLeave(enterDate, thisYear)

  const calALMonthThisYearV = calALMonth(enterDate, numOfWorkers, specificDate)[0]
  const calALMonthV = specificDate ? calALSpecificMonth(enterDate, numOfWorkers, specificDate) : calALTodMonth(enterDate, numOfWorkers)
  const calALBagicYearV = specificDate ? calALSpecificBagicYear(enterDate, numOfWorkers, specificDate) : calALTodBagicYear(enterDate, numOfWorkers)
  const calALPlusYearV = specificDate ? calALSpecificPlusYear(enterDate, numOfWorkers, specificDate) : calALTodPlusYear(enterDate, numOfWorkers)


  const sumCalAL = 
    secondYearForAnnualLeave(enterDate, thisYear) ? 
      (calALMonthThisYearV ? calALMonthThisYearV : 0)
      + (calALBagicYearV ? calALBagicYearV : 0)
      + (calALPlusYearV ? calALPlusYearV : 0)
    : 
      (calALMonthV ? calALMonthV : 0)
      + (calALBagicYearV ? calALBagicYearV : 0)
      + (calALPlusYearV ? calALPlusYearV : 0)

  return (
    <>
      {numOfWorkers < 5 ? <Item.Description><div style={{color:'red'}}><strong>* 상시근로자수 5인 미만의 근로기준법상 연차조항이 적용되지 않습니다.</strong></div></Item.Description> : null}
      <br />
      {finBaseDate
        ?
          <>
            <Item.Description>
              <div><strong>* 연차현황[회계기준({finBaseMonthDay}, {finBaseDay})] [{secondYearForAnnualLeave(enterDate, thisYear) ? `${thisYear}-01-01` : enterDate}~ {specificDate ? specificDate : '오늘'}]</strong></div>
            </Item.Description>
            <Item.Description>
              <Table textAlign = 'center'>
                <Table.Header>
                  <Table.Row>
                    <Table.HeaderCell>구분</Table.HeaderCell>
                    <Table.HeaderCell>월 단위</Table.HeaderCell>
                    <Table.HeaderCell>비례</Table.HeaderCell>
                    <Table.HeaderCell>년기본</Table.HeaderCell>
                    <Table.HeaderCell>년가산</Table.HeaderCell>
                    <Table.HeaderCell>합계</Table.HeaderCell>
                  </Table.Row>
                </Table.Header>
                <Table.Body>
                  <Table.Row>
                    <Table.Cell><strong>회계기준</strong></Table.Cell>
                    <Table.Cell>{calALFinMonthV}</Table.Cell>
                    <Table.Cell>{calALFinRatioYearV}</Table.Cell>
                    <Table.Cell>{calALFinBagicYearV}</Table.Cell>
                    <Table.Cell>{calALFinPlusYearV}</Table.Cell>
                    <Table.Cell>{sumCalALFin}</Table.Cell>
                  </Table.Row>
                  <Table.Row>
                    <Table.Cell><strong>법정기준</strong></Table.Cell>
                    {secondYearForAnnualLeave(enterDate, thisYear) ? <Table.Cell>{calALMonthThisYearV}</Table.Cell> : <Table.Cell>{calALMonthV}</Table.Cell>}
                    <Table.Cell>'해당없음'</Table.Cell>
                    <Table.Cell>{calALBagicYearV}</Table.Cell>
                    <Table.Cell>{calALPlusYearV}</Table.Cell>
                    <Table.Cell>{sumCalAL}</Table.Cell>
                  </Table.Row>
                </Table.Body>
              </Table>

            </Item.Description>
          </>
        :
          <>
            <Item.Description>
              <div><strong>연차현황 [{enterDate} ~ {specificDate}]</strong></div>
            </Item.Description>
            <Item.Description>
              <Table  style={{textAlign : 'center'}}>
                <Table.Header>
                  <Table.Row>
                    <Table.HeaderCell>구분</Table.HeaderCell>
                    <Table.HeaderCell>월 단위</Table.HeaderCell>
                    <Table.HeaderCell>년기본</Table.HeaderCell>
                    <Table.HeaderCell>년가산</Table.HeaderCell>
                    <Table.HeaderCell>합계</Table.HeaderCell>
                  </Table.Row>
                </Table.Header>
                <Table.Body>
                  <Table.Row>
                    <Table.Cell>법정기준</Table.Cell>
                    {secondYearForAnnualLeave(enterDate, thisYear) ? <Table.Cell>{calALMonthThisYearV}</Table.Cell> : <Table.Cell>{calALMonthV}</Table.Cell>}
                    <Table.Cell>{calALBagicYearV}</Table.Cell>
                    <Table.Cell>{calALPlusYearV}</Table.Cell>
                    <Table.Cell>{sumCalAL}</Table.Cell>
                  </Table.Row>
                </Table.Body>
              </Table>
            </Item.Description>
          </>
      }
      <br />
      <OpenDetailAnnualLeavesCalcPastYear
        numOfWorkers={numOfWorkers}
        Item={Item}
        finBaseDate={finBaseDate}
        finBaseMonthDay={finBaseMonthDay}
        enterDate={enterDate}
        underOneYearWorkPeriod={underOneYearWorkPeriod}
        secondYearForAnnualLeave={secondYearForAnnualLeave}
        targetYear={lastYear}
        usedAnnualLeavesInLastYearSumF={usedAnnualLeavesInLastYearSumF}
      />

      <OpenDetailAnnualLeavesCalcPastYear 
        numOfWorkers={numOfWorkers}
        Item={Item}
        finBaseDate={finBaseDate}
        finBaseMonthDay={finBaseMonthDay}
        enterDate={enterDate}
        underOneYearWorkPeriod={underOneYearWorkPeriod}
        secondYearForAnnualLeave={secondYearForAnnualLeave}
        targetYear={lastTwoYear}
        usedAnnualLeavesInLastYearSumF={usedAnnualLeavesInLastYearSumF}
      />

      <OpenDetailAnnualLeavesCalcPastYear 
        numOfWorkers={numOfWorkers}
        Item={Item}
        finBaseDate={finBaseDate}
        finBaseMonthDay={finBaseMonthDay}
        enterDate={enterDate}
        underOneYearWorkPeriod={underOneYearWorkPeriod}
        secondYearForAnnualLeave={secondYearForAnnualLeave}
        targetYear={lastThreeYear}
        usedAnnualLeavesInLastYearSumF={usedAnnualLeavesInLastYearSumF}
      />
    </>

  )
}
