import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { Dropdown, Menu } from 'semantic-ui-react'


export default function PaydocuAnnualLeaveWorkerMenu({currentUserProfile, FcLeave}) {

  const history = useHistory();
  
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [isMobile, setIsMobile] = useState(window.innerWidth < 1000);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 1000);
    };
    
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const handleMouseOver = () => {
    setDropdownOpen(true);
  };

  const handleMouseLeave = () => {
    setDropdownOpen(false);
  };

  const numOfWorkers = +currentUserProfile?.numOfWorkers;

  function reloadToAnnualLeavesWorker () {
    history.push('/annualLeavesWorker');
  }

  return (
    <>
      <Menu.Item onMouseOver={handleMouseOver} onMouseLeave={handleMouseLeave}>
        {!isMobile && <FcLeave className="icon-spacing" />}
        <Dropdown style={{width:'160px'}} pointing='top left' text='연차대장' 
          open={dropdownOpen}
          onMouseOver={handleMouseOver}
          onMouseLeave={handleMouseLeave}
        >
          <Dropdown.Menu>
            {
              numOfWorkers >= 5 
                ?
                  <Dropdown.Item onClick={() => reloadToAnnualLeavesWorker()}  text='연차사용내역' />
                :
                  <Dropdown.Item text='연차미해당' />
            }
          </Dropdown.Menu>
        </Dropdown>
      </Menu.Item>
    </>
  )
}
