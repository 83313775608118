export const CREATE_EVENT = 'CREATE_EVENT';
export const UPDATE_EVENT = 'UPDATE_EVENT';
export const DELETE_EVENT = 'DELETE_EVENT';
export const FETCH_EVENTS = 'FETCH_EVENTS';
export const LISTEN_TO_EVENT_CHAT = 'LISTEN_TO_EVENT_CHAT';
export const CLEAR_COMMENTS = 'CLEAR_COMMENTS';
export const LISTEN_TO_SELECTED_EVENT = 'LISTEN_TO_SELECTED_EVENT';
export const CLEAR_SELECTED_EVENT = 'CLEAR_SELECTED_EVENT';
export const CLEAR_EVENTS = 'CLEAR_EVENTS';
export const SET_FILTER = 'SET_FILTER'; 
export const SET_START_DATE = 'SET_START_DATE'; 
export const RETAIN_STATE = 'RETAIN_STATE'; 

export const FETCH_EVENTS_TABLE= 'FETCH_EVENTS_TABLE';
export const CLEAR_EVENTS_TABLE = 'CLEAR_EVENTS_TABLE';

export const FETCH_MONTHLY_REPORTS= 'FETCH_MONTHLY_REPORTS';
export const CLEAR_MONTHLY_REPORTS = 'CLEAR_MONTHLY_REPORTS';
export const CLEAR_SELECTED_MONTHLY_REPORT ='CLEAR_SELECTED_MONTHLY_REPORT';
export const LISTEN_TO_SELECTED_MONTHLY_REPORT = 'LISTEN_TO_SELECTED_MONTHLY_REPORT';

export const FETCH_COMPETENCIES = 'FETCH_COMPETENCIES';
export const CLEAR_COMPETENCIES = 'CLEAR_COMPETENCIES';

export const SET_YEARMONTH_COMPETENCY = 'SET_YEARMONTH_COMPETENCY';
export const SEND_COMPETENCYREPORTID = 'SEND_COMPETENCYREPORTID';

export const LISTEN_TO_SELECTED_RISK_SUSPECT_REPORT = 'LISTEN_TO_SELECTED_RISK_SUSPECT_REPORT';
export const CLEAR_SELECTED_RISK_SUSPECT_REPORT = 'CLEAR_SELECTED_RISK_SUSPECT_REPORT';
export const CLEAR_RISK_SUSPECT_REPORTS = 'CLEAR_RISK_SUSPECT_REPORTS';
export const SET_YEARMONTH_RISK_SUSPECT = 'SET_YEARMONTH_RISK_SUSPECT';
export const SEND_RISK_SUSPECT_REPORT_ID = 'SEND_RISK_SUSPECT_REPORT_ID';
export const FETCH_RISK_SUSPECT_REPORTS = 'FETCH_RISK_SUSPECT_REPORTS';

export const SET_SELECTED_CONVERSATION_ID = 'SET_SELECTED_CONVERSATION_ID';
export const ADD_MESSAGE = 'ADD_MESSAGE';
export const SET_CONVERSATIONS = 'SET_CONVERSATIONS';
export const SET_CONVERSATION_HISTORY = 'SET_CONVERSATION_HISTORY';
export const DELETE_CONVERSATIONS = 'DELETE_CONVERSATIONS';

export const UPDATE_CONVERSATIONS = 'UPDATE_CONVERSATIONS';