import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { Dropdown, Menu } from 'semantic-ui-react'

export default function PaydocuAnnualLeaveMenu({currentUserProfile, clientInfo, FcLeave}) {

  const history = useHistory();
  
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [isMobile, setIsMobile] = useState(window.innerWidth < 1000);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 1000);
    };
    
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const handleMouseOver = () => {
    setDropdownOpen(true);
  };

  const handleMouseLeave = () => {
    setDropdownOpen(false);
  };

  const handleDropdownToggle = () => {
    setDropdownOpen(!dropdownOpen);
  };

  const numOfWorkers = clientInfo?.numOfWorkers ? clientInfo?.numOfWorkers : +currentUserProfile?.numOfWorkers;

  function reloadToAnnualLeavesTable () {
    history.push('/annualLeavesTable');
  }

  function reloadToAnnualLeavesWorkerTable () {
    history.push('/annualLeavesWorkerTable');
  }

  return (
    <>
      <Menu.Item className="menu-item" onMouseOver={handleMouseOver} onMouseLeave={handleMouseLeave}>
      {!isMobile && <FcLeave className="icon-spacing" />}
        <Dropdown style={{width:'160px'}} pointing='top left' text='연차/근태대장' open={dropdownOpen}  onMouseOver={handleMouseOver} onMouseLeave={handleMouseLeave} onClick={handleDropdownToggle}>
          <Dropdown.Menu>
            {
              <>
                {
                  numOfWorkers >= 5 
                    ?
                      <>
                        <Dropdown.Item onClick={() => reloadToAnnualLeavesTable()}  text='월별 연차/근태대장' />
                        <Dropdown.Item onClick={() => reloadToAnnualLeavesWorkerTable()}  text='개인별 연차/근태대장' />

                      </>
                    :
                      <Dropdown.Item text='연차미해당' />
                }
              </>
            }
          </Dropdown.Menu>
        </Dropdown>
      </Menu.Item>
    </>
  )
}
