// import { format } from 'date-fns';
import React from 'react';
import { format } from 'date-fns/esm';
import { Button, Grid, Tab, Table } from 'semantic-ui-react'
import { printYouthSFPersonalInfo } from '../../../app/common/util/util';
import { Link } from 'react-router-dom';
import { decrypto } from '../../../app/firestore/firestoreService';

export default function YouthSFPersonalInfoAgreeContents({profile}) {
  let today = new Date(); 

  const decryptoData = {};
  for (const prop in profile) {
    decryptoData[prop] =
      prop === "ceoSocialNumberFront"
        || prop === "ceoSocialNumberBack" 
        ? decrypto(profile[prop], String(process.env.CRYPTO_KEY))
        : profile[prop];
  }

  return (
    <Tab.Pane>
      <Grid>
        <Grid.Column width={16}>
          <>
            <Button.Group>
              <Button 
                color='yellow' 
                style={{display: "inline", color: 'black', margin: '5px'}}
                as={Link} to={`/clientReqs4In`} 
              >직원 List
              </Button>
              <Button 
                color='blue' 
                style={{display: "inline", color: 'black', margin: '5px',}}
                onClick={() => printYouthSFPersonalInfo()}
              >청년채용특별장려금 개인정보 동의서 출력
              </Button>
            </Button.Group>
            <div id='youthSFPersonalInfo'>
              <Table width ="100%" align = "center" border='1px solid black'>
                <Table.Body>
                  <Table.Row>
                    <Table.Cell align = "center"><h1 style={{textAlign : 'center', margin : '20px', fontSize : '30px'}}>{` 개인정보 수집 이용에 대한 동의서(사업주용) `}</h1></Table.Cell>
                  </Table.Row>
                </Table.Body>
              </Table>
              <table width ="100%" align = "center">
                <Table.Body>
                  <Table.Row>
                    <Table.Cell>
                      {`대표자 성명 : ${profile?.ceoName}`}
                      <br />
                      {/* {(decryptoData?.ceoSocialNumberFront && decryptoData?.ceoSocialNumberBack) !== undefined
                        ?
                          <span>{`대표자 주민등록번호 : `}</span>
                        : */}
                        <span>대표자 주민번호 : </span>
                      {/* } */}
                    </Table.Cell>
                  </Table.Row>
                  <Table.Row>
                    <Table.Cell>
                      1. “청년채용특별장려금” 사업 참여에 있어 개인(법인) 사업주를 고유하게 구별하기 위해 부여된 식별정보(주민등록번호 등)를 포함한 개인정보를 다음과 같이 “청년채용특별장려금” 전산망에서 수집･관리하고 있으며, 제공하신 정보는 청년채용특별장려금 사업의 신청 적격 여부 확인 등 원활한 운영을 위해 사용됩니다.
                    <br />
                    <br />
                      <table  border='1px solid black' style={{width : "100%", height : "150", textAlign : "center"}}>
                        <Table.Body>
                          <Table.Row>
                            <Table.Cell width ="16.6%">수집 &#183; 이용 항목</Table.Cell>
                            <Table.Cell width ="16.6%">수집 &#183; 이용 목적</Table.Cell>
                            <Table.Cell width ="16.6%">보유 &#183; 이용 기간</Table.Cell>
                          </Table.Row>
                          <Table.Row>
                            <Table.Cell width ="16.6%">성명 &#183; 주민번호</Table.Cell>
                            <Table.Cell width ="16.6%">본인 확인</Table.Cell>
                            <Table.Cell rowSpan={2} width ="16.6%">신청 시점 ~ 지원 종료 시점으로부터 만 5년</Table.Cell>
                          </Table.Row>
                          <Table.Row>
                            <Table.Cell width ="16.6%">2021년 이전 1년간(’19.12.1~’20.11.30) <br /> 임금체불(｢최저임금법｣ 제6조, <br /> ｢근로기준법｣ 제26조, 제36조, 제43조) 이력</Table.Cell>
                            <Table.Cell width ="16.6%">참여 적격요건 확인</Table.Cell>
                          </Table.Row>
                        </Table.Body>
                      </table>
                    {/* </Table.Cell>
                  </Table.Row>
                  <Table.Row>
                    <Table.Cell> */}
                      <br />
                      <br />
                      2. “청년채용특별장려금” 사업에 참여하기 위해서는 개인정보가 필요하며, 개인을 고유하게 구별하기 위해 부여된 식별정보인 주민등록번호는 ｢고용보험법 시행령｣ 제145조의2를 근거로 수집하며, 고용노동부는 ｢개인정보보호법｣에 따라 참여자로부터 제공받는 개인정보를 보호합니다.
                      <br />
                      <br />
                      3. 고용노동부는 개인정보를 처리 목적에 필요한 범위에서 적합하게 처리하고 그 목적 외의 용도로 사용하지 않으며 개인정보를 제공한 참여자는 언제나 자신이 입력한 개인정보의 열람･수정을 신청할 수 있습니다.
                      <br />
                      <br />
                      4. 신청자는 개인정보의 수집･이용･제공에 동의하지 아니할 권리가 있으며 만약 동의하지 않을 경우 본인이 직접 해당서류를 제출하여야 하며 사업 참여가 제한될 수 있습니다.
                      <br />
                      <br />
                      5.본인은 위 1~4의 내용에 따른 “청년채용특별장려금” 사업 참여･운영을 위해 개인정보를 제공할 것을 동의합니다.
                    </Table.Cell>
                  </Table.Row>

                  <Table.Row>
                    <Table.Cell align='center'>
                      ( □ 동의합니다. □ 동의하지 않습니다. )
                    </Table.Cell>
                  </Table.Row>
                  <br />
                  <br />
                  <Table.Row>
                    <Table.Cell align = "center">{format(today, 'yyyy-MM-dd')}</Table.Cell>
                  </Table.Row>
                </Table.Body>
              </table>
              <br />
              <br />
              <table  style={{width : "100%", height : "150", textAlign : "left"}}>
                <Table.Body>
                  <Table.Row>
                    <Table.Cell style={{width : "90%", textAlign : 'right'}}>
                      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                      동의자(회사) : {profile?.companyName} &nbsp;&nbsp;&nbsp;
                      <br />
                      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                      동의자(대표자) : {profile?.ceoName} &nbsp;&nbsp;&nbsp;
                    </Table.Cell>
                    <Table.Cell>
                      (인) 또는 서명
                    </Table.Cell>
                  </Table.Row>
                </Table.Body>
              </table>
            </div>
          </>
        </Grid.Column>
      </Grid>
    </Tab.Pane>
  );
};




// {/* <div>Member since: {format(profile?.createdAt, 'yyyy-MM-dd')}</div> */}
// <div style={{ marginBottom: 10 }}>
// <strong>
//   <br />
//   <div>대표자 성명 : {profile?.ceoName || null}</div>
//   <div>사업자등록번호 : {profile?.companyBizNumber || null}</div>
//   <div>업태 : {profile?.bizType || null}</div>
//   <div>종목 : {profile?.bizItem || null}</div>
// </strong>
// <br />
// <div>팩스번호 : {profile?.companyFax || null}</div>
// <div>주소 : {profile?.companyAddress || null}</div>
// <div>담당자 이름 : {profile?.staffName || null}</div>
// <div>담당자 이메일 : {profile?.staffEmail || null}</div>
// </div>