import React, { useEffect, useRef } from 'react';
import { Field, Form, Formik } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Menu } from 'semantic-ui-react';
import { setFilterPaydocu, setYearMonthPaydocu } from '../companyActions';
import * as Yup from 'yup';
import { toast } from 'react-toastify';
import { format } from 'date-fns';
import MySelectInput from '../../../app/common/form/MySelectInput';
import { month } from '../../../app/api/dropdownOption';

export default function PaydocuFilters({loading, isWorker, isAgent, clientInfo}) {
  const dispatch = useDispatch();
  const formikRef = useRef();
  const {filter, yearMonth} = useSelector(state => state.companyReducer)
  const {currentUserProfile} = useSelector(state => state.profile);
  const thisYear = format(new Date(), 'yyyy');
  const thisMonth = format(new Date(), 'MM');

  useEffect(() => {
    if (isWorker || isAgent) {
      const year = yearMonth.substring(0, 4) || thisYear;
      const month = yearMonth.substring(4) || thisMonth;
      formikRef.current?.setValues({
        companyName: currentUserProfile?.companyName || clientInfo?.companyName || '노무법인 최상인업', // 기본값 추가
        year: year || thisYear, // 기본값 보장
        month: month || thisMonth // 기본값 보장
      });
      formikRef.current?.submitForm();
    }
  }, [isWorker, isAgent, yearMonth, currentUserProfile, thisYear, thisMonth, clientInfo]);

  // 과거 10년을 포함한 연도 배열을 생성합니다.
  const years = Array.from({ length: 6 }, (_, index) => ({
    key: String(thisYear - index), // 숫자를 문자열로 변환하여 key에 할당
    text: `${thisYear - index}`,
    value: `${thisYear - index}`
  }));

  return (
    <>
      {isWorker ? (
        <Menu vertical size='large' style={{width: '100%'}}>
          <Menu.Item 
            content='임금명세서 불러오기' 
            active={filter === 'isWorker'}
            onClick={() => dispatch(setFilterPaydocu('isWorker'))}
            disabled={loading}
          />
        </Menu>
      ) : (
        <Formik
          innerRef={formikRef}
          initialValues={{
            companyName: currentUserProfile?.companyName,
            year: yearMonth.substring(0, 4) || thisYear,
            month: yearMonth.substring(4) || thisMonth,
            isAgent: false, // 체크박스의 초기값은 false로 설정합니다.
          }}
          validationSchema={Yup.object({
            year: Yup.string().required('필수입니다.'),
            month: Yup.string().required('필수입니다.'),
            isAgent: Yup.boolean(),
          })}
          onSubmit={(values, {setSubmitting}) => {
            const yearMonthValue = values.year + values.month;
            dispatch(setYearMonthPaydocu(yearMonthValue));

            try {
              setSubmitting(false);
            } catch (error) {
              console.log(error.message);
              toast.error(error.message);
              setSubmitting(false);
            }
          }}
        >
          {({isSubmitting, isValid, dirty, setFieldValue, values}) => (
            <Form className='ui form'>
              <div style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'flex-start', alignItems: 'center', alignContent: 'flex-end'}}>       
                <div className='margin' style={{width: '19.5%', marginRight: '3px'}}>
                  <MySelectInput 
                    label='년도' 
                    name='year' 
                    options={years}
                  />
                </div>
                <div className='margin' style={{width: '19.5%', marginRight: '3px'}}>
                  <MySelectInput 
                    label='월' 
                    name='month' 
                    options={month}
                  />
                </div>
                {isAgent && (
                  <div className='margin' style={{width: 'auto', marginRight: '3px'}}>
                    <label>
                      <Field 
                        type="checkbox" 
                        name="isAgent" 
                        checked={values.isAgent} 
                        onChange={(e) => {
                          const isChecked = e.target.checked;
                          setFieldValue('isAgent', isChecked);
                          // 체크박스 상태 변경 시 즉시 액션 디스패치
                          dispatch(setFilterPaydocu(isChecked ? 'isAgent' : (clientInfo?.companyName ? clientInfo.companyName : currentUserProfile?.companyName)));
                        }} 
                      />
                        대리인
                    </label>
                  </div>
                )}
                <Button
                  loading={isSubmitting}
                  // disabled={isSubmitting || !isValid || !dirty}
                  floated='left'
                  type='submit'
                  size='large'
                  positive
                  content='검색하기' 
                />
              </div>
            </Form>
          )}
        </Formik>
      )}
    </>
  );
}
