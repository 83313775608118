import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { selectedWorkerRegistryType } from '../../clientReqActions4In';

function SingleSelectRadioButton({ values }) {
	const [options] = useState([
		{ id: 1, name: '통상 월급직(1주 주기)' },
		{ id: 2, name: '근로시간특례(근기법 63조-1주 주기)' },
		{ id: 3, name: '교대제등 불규칙 주기 ' },
	]);

	// 컴포넌트 자체의 상태를 추가합니다.
	const [selectedOption, setSelectedOption] = useState(
		values?.workerRegistryType ? values?.workerRegistryType : '1'
	);

	const dispatch = useDispatch();
	const { workerRegistryType } = useSelector(
		(state) => state.clientReq4InStore
	);

	const handleChange = (event) => {
		setSelectedOption(event.target.value); // 로컬 상태 업데이트
		dispatch(selectedWorkerRegistryType(event.target.value)); // Redux 상태 업데이트
	};

	return (
		<div style={{ marginTop: '25' }}>
			<h2>월급직 직원 근로시간 제도 선택</h2>
			<div style={{ display: 'flex' }}>
				{options.map((option) => (
					<div key={option.id} style={{ marginRight: '20px' }}>
						<input
							type='radio'
							id={`option-${option.id}`}
							name='options'
							value={option.id}
							checked={selectedOption === option.id.toString()}
							onChange={handleChange}
							style={{ transform: 'scale(2)', marginRight: '1.5em' }}
						/>
						<label
							htmlFor={`option-${option.id}`}
							style={{ fontSize: '1.5em' }}>
							{option.name}
						</label>
					</div>
				))}
			</div>
			{workerRegistryType === '1' ? (
				<h4 style={{ color: 'teal' }}>
					* 월급제 중 1주일(근무시간이 요일별로 다른 경우도 설계함.) 주기{' '}
					<span style={{ color: 'blue' }}>
						정규직, 계약직, 수습직, 단시간, 초단시간
					</span>{' '}
					근로자 대상 근로시간 제도 설계용입니다
				</h4>
			) : workerRegistryType === '2' ? (
				<h4 style={{ color: 'teal' }}>
					월급제 중 1주일 주기{' '}
					<span style={{ color: 'blue' }}>
						농림수산업, 감단직 등 근기법 제63조에 따른 근로시간 적용 제외
					</span>
					(주휴제외, 연장근로제외 등) 근로자 대상 근로시간 제도 설계용입니다
				</h4>
			) : (
				<h4 style={{ color: 'teal' }}>
					월급제 중{' '}
					<span style={{ color: 'blue' }}>1주일 주기가 아닌 교대제</span> 등
					근로시간이 근로자 대상 근로시간 제도 설계용입니다{' '}
					<span style={{ color: 'red' }}>
						{' '}
						(근로시간 설계는 공인노무사의 조력받을실 것을 추천드립니다)
					</span>
				</h4>
			)}
			<br />
		</div>
	);
}

export default SingleSelectRadioButton;
