import React from 'react';
import { useSelector } from 'react-redux';
import TestModal from '../../../features/sandbox/TestModal';
import LoginForm from '../../../features/auth/LoginForm';
import RegisterForm from '../../../features/auth/RegisterForm';
import PriceForm from '../../../features/auth/PriceForm';
import PaymentsSuccess from '../../../features/auth/PaymentsSuccess';
import Under5LaborContract from '../../../features/auth/youtube/Under5LaborContract';
import RegularLaborContract from '../../../features/auth/youtube/RegularLaborContract';
import WholeWagesLaborContract from '../../../features/auth/youtube/WholeWagesLaborContract';
import SeeWorkerLaborContract from '../../../features/auth/youtube/SeeWorkerLaborContract';
import OutSideWorkerLaborContract from '../../../features/auth/youtube/OutSideWorkerLaborContract';
import ShortTimeWorkerLaborContract from '../../../features/auth/youtube/ShortTimeWorkerLaborContract';
import Under15ShortTimeWorkerLaborContract from '../../../features/auth/youtube/Under15ShortTimeWorkerLaborContract';
import PeriodWorkerLaborContract from '../../../features/auth/youtube/PeriodWorkerLaborContract';
import RetiredPeriodWorkerLaborContract from '../../../features/auth/youtube/RetiredPeriodWorkerLaborContract';
import TraineeWorkerLaborContract from '../../../features/auth/youtube/TraineeWorkerLaborContract';
import Under18AgeWorkerLaborContract from '../../../features/auth/youtube/Under18AgeWorkerLaborContract';
import WhatIsPayDac from '../../../features/auth/youtube/WhatIsPayDac';
import WorkerRegStep1 from '../../../features/auth/youtube/WorkerRegStep1';
import WorkerRegStep1_1 from '../../../features/auth/youtube/WorkerRegStep1_1';
import WorkerRegStep1_2 from '../../../features/auth/youtube/WorkerRegStep1_2';
import WorkerRegStep1_3 from '../../../features/auth/youtube/WorkerRegStep1_3';
import WorkerRegStep1_4 from '../../../features/auth/youtube/WorkerRegStep1_4';
import WorkerRegStep2 from '../../../features/auth/youtube/WorkerRegStep2';
import WorkerRegStep2_1 from '../../../features/auth/youtube/WorkerRegStep2_1';
import WorkerRegStep3 from '../../../features/auth/youtube/WorkerRegStep3';
import WorkerRegStep3_1 from '../../../features/auth/youtube/WorkerRegStep3_1';
import WorkerRegStep3_2 from '../../../features/auth/youtube/WorkerRegStep3_2';
import WorkerRegStep3_3 from '../../../features/auth/youtube/WorkerRegStep3_3';
import WorkerRegStep4 from '../../../features/auth/youtube/WorkerRegStep4';
import WorkerRegStep4_1 from '../../../features/auth/youtube/WorkerRegStep4_1';
import WorkerRegStep4_2 from '../../../features/auth/youtube/WorkerRegStep4_2';
import WorkerRegStep4_3 from '../../../features/auth/youtube/WorkerRegStep4_3';
import WorkerRegStep5 from '../../../features/auth/youtube/WorkerRegStep5';


import PayPaperLaborContract from '../../../features/auth/youtube/PayPaperLaborContract';
import PayPaperAddTime from '../../../features/auth/youtube/PayPaperAddTime';
import PayPaperAddMoney from '../../../features/auth/youtube/PayPaperAddMoney';
import PayPaperIntro from '../../../features/auth/youtube/PayPaperIntro';
import PayPaperRetire from '../../../features/auth/youtube/PayPaperRetire';
import PayPaperList from '../../../features/auth/youtube/PayPaperList';
import PaydacAnnualleaves from '../../../features/auth/youtube/PaydacAnnualleaves';
import PaydacBSC from '../../../features/auth/youtube/PaydacBSC';
import PayPaperNetCal from '../../../features/auth/youtube/PayPaperNetCal';
import MaternityLeave from '../../../features/auth/youtube/MaternityLeave';
import ChildLeave from '../../../features/auth/youtube/ChildLeave';
import ChildShortTime from '../../../features/auth/youtube/ChildShortTime';
import EtcLeave from '../../../features/auth/youtube/EtcLeave';
import PayPaperMinusTime from '../../../features/auth/youtube/PayPaperMinusTime';
import PayPaperMinusMoney from '../../../features/auth/youtube/PayPaperMinusMoney';

export default function ModalManager() {
  const modalLookup = {
    TestModal,
    LoginForm,
    RegisterForm,
    PriceForm,
    PaymentsSuccess,
    Under5LaborContract,
    RegularLaborContract,
    WholeWagesLaborContract,
    SeeWorkerLaborContract,
    OutSideWorkerLaborContract,
    ShortTimeWorkerLaborContract,
    Under15ShortTimeWorkerLaborContract,
    PeriodWorkerLaborContract,
    RetiredPeriodWorkerLaborContract,
    TraineeWorkerLaborContract,
    Under18AgeWorkerLaborContract,
    WhatIsPayDac,
    WorkerRegStep1,
    WorkerRegStep1_1,
    WorkerRegStep1_2,
    WorkerRegStep1_3,
    WorkerRegStep1_4,

    WorkerRegStep2,
    WorkerRegStep2_1,
    WorkerRegStep3,
    WorkerRegStep3_1,
    WorkerRegStep3_2,
    WorkerRegStep3_3,
    WorkerRegStep4,
    WorkerRegStep4_1,
    WorkerRegStep4_2,
    WorkerRegStep4_3,
    WorkerRegStep5,
    PayPaperLaborContract,
    PayPaperAddTime,
    PayPaperMinusTime,
    PayPaperAddMoney,
    PayPaperMinusMoney,
    PayPaperIntro,
    PayPaperRetire,
    PayPaperList,
    PaydacAnnualleaves,
    PaydacBSC,
    PayPaperNetCal,

    MaternityLeave,
    ChildLeave,
    ChildShortTime,
    EtcLeave,

  };
  
  const currentModal = useSelector(state => state.modals);
  let renderedModal;

  if (currentModal) {
    const {modalType, modalProps} = currentModal;
    const ModalComponet = modalLookup[modalType];
    renderedModal = <ModalComponet {...modalProps} />; 
  }

  return (
    <span>
      {renderedModal}
    </span>
  )
}
