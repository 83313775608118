// import { Field, FieldArray } from 'formik';
import React from 'react';
import { Tab } from 'semantic-ui-react';

import MyTextArea from '../../../../../app/common/form/MyTextArea';

import CrwfLaborContractTimeInfo from './CrwfLaborContractTimeInfo';

import WorkerFormTimeComments from './WorkerFormTimeComments';
import CrwfEtcLaborContractTimeInfo from './CrwfEtcLaborContractTimeInfo';
import CrwfExceptTimeLawLaborContractTimeInfo from './CrwfExceptTimeLawLaborContractTimeInfo';
import SingleSelectRadioButton from './SingleSelectRadioButton';
import WorkerFormTimeWhole from './WorkerFormTimeWhole';
import { useLocation } from 'react-router-dom/cjs/react-router-dom.min';

export default function CrwfWorkerBasicTimeInfo({
	isExceptTimeLawWorker,
	isEtcWorker,
	workerRegistryType,
	values,
	selectedClientReq4In,
	setSumOfWorkDays,
	authLevel,
}) {
	const location = useLocation();
	const paidOffdays = values?.paidOffdays === '' ? true : false; //정규직 주휴일 설정 여부
	const selectWorkDaysETL1InfoShiftWork =
		values?.selectWorkDaysETL1Info.shiftWork === '' ? true : false; //감단직 격주 설정 여부
	const etcPaidOffdays = values?.etc?.paidOffdays === '' ? true : false; //교대체 주휴일 설정 여부

	const MenuItemContent = () => (
		<>
			<span style={{ color: 'blue' }}>(2-1) 근로시간설정</span>
			<span style={{ color: 'red' }}>[필수]</span>
		</>
	);

	const panes = [];

	panes.push(
		{
			menuItem: {
				content: <MenuItemContent />,
				key: '근로시간설정[필수]',
			},
			render: () => (
				<Tab.Pane>
					{authLevel > 40 &&
					location.pathname.startsWith('/createClientReq4In') &&
					paidOffdays &&
					selectWorkDaysETL1InfoShiftWork &&
					etcPaidOffdays ? (
						<>
							<SingleSelectRadioButton values={values} />
						</>
					) : null}
					{!isExceptTimeLawWorker &&
						!isEtcWorker &&
						(!workerRegistryType || workerRegistryType === '1') && (
							<CrwfLaborContractTimeInfo
								setSumOfWorkDays={setSumOfWorkDays}
								selectedClientReq4In={selectedClientReq4In}
								values={values}
							/>
						)}
					{authLevel > 40 &&
						(isExceptTimeLawWorker || workerRegistryType === '2') && (
							<CrwfExceptTimeLawLaborContractTimeInfo
								setSumOfWorkDays={setSumOfWorkDays}
								selectedClientReq4In={selectedClientReq4In}
								values={values}
							/>
						)}
					{authLevel > 40 && (isEtcWorker || workerRegistryType === '3') && (
						<CrwfEtcLaborContractTimeInfo values={values} />
					)}
					<WorkerFormTimeComments MyTextArea={MyTextArea} />
				</Tab.Pane>
			),
		},
		{
			menuItem: '(2-2) 포괄근로시간설정',
			key: '포괄근로시간설정',
			render: () => (
				<Tab.Pane>
					<WorkerFormTimeWhole />
				</Tab.Pane>
			),
		}
	);

	return (
		<>
			<Tab panes={panes} />
		</>
	);
}
