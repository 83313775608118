import React from 'react'
import { Table } from 'semantic-ui-react'

export default function PaydocuDetailedPageWorkDayPlusTimeForRWorker({
  worker,
  numOfWorkers,
  plusMonthOWT,
  plusMonthNWT,
  plusMonthHoliBasicTime,
  plusMonthOWTHoli,
  plusMonthNWTHoli,
  absentDays,
  absentPaidHoliDays,
  absentTimes,
  usedAnnualLeaves,
  absentDates,
  absentPaidHoliDates,
  absentTimeDates,
  usedAnnualLeaveDates
}) {
  return (
    <>
      <Table.Row>
        <Table.Cell textAlign='left' colSpan='9'><span style={{color:'darkcyan', fontWeight:'bold'}}>{`2. ${worker}의 추가근무시간 및 공제내역`}</span></Table.Cell>
      </Table.Row>
      <Table.Row>
        {plusMonthOWT ? 
          <>     
            {numOfWorkers >= 5 ? 
              <Table.Cell style={{ width: '11.11%', textAlign: 'center' }}>추가연장<br />시간수</Table.Cell>
              : 
              <Table.Cell style={{ width: '11.11%', textAlign: 'center' }}>추가초과<br />시간수</Table.Cell>
            }
          </> : null
        }
        {plusMonthNWT ? <Table.Cell style={{ width: '11.11%', textAlign: 'center' }}>추가야간<br />시간수</Table.Cell> : null}
        {plusMonthHoliBasicTime ? <Table.Cell style={{ width: '11.11%', textAlign: 'center' }}>추가휴일<br />기본시간수</Table.Cell> : null}
        {plusMonthOWTHoli ?
          <>
            {numOfWorkers >= 5 ? 
              <Table.Cell style={{ width: '11.11%', textAlign: 'center' }}>추가휴일<br/>연장시간수</Table.Cell>
              :
              <Table.Cell style={{ width: '11.11%', textAlign: 'center' }}>추가휴일<br />초과시간수</Table.Cell>
            }
          </> : null
        }
        {plusMonthNWTHoli ? <Table.Cell style={{ width: '11.11%', textAlign: 'center' }}>추가휴일<br />야간시간수</Table.Cell> : null}
        {absentDays ? <Table.Cell style={{ width: '11.11%', textAlign: 'center' }}>{absentDays ?  <span style={{color: 'red'}}>*공제일수(결근)</span>: null}</Table.Cell>  : null }
        {absentPaidHoliDays ? <Table.Cell style={{ width: '11.11%', textAlign: 'center' }}>{absentPaidHoliDays ? <span style={{color: 'red'}}>*공제일수(주휴)</span> : null}</Table.Cell> : null}
        {absentTimes ? <Table.Cell style={{ width: '11.11%', textAlign: 'center' }}>{absentTimes ? <span style={{color: 'red'}}>**공제시간수(지각/조퇴/외출)</span> : null}</Table.Cell> : null}
        {usedAnnualLeaves ? <Table.Cell style={{ width: '11.11%', textAlign: 'center' }}>{usedAnnualLeaves ? <span style={{color: 'red'}}>사용연차수</span> : null}</Table.Cell> : null }
        {!plusMonthOWT ? <Table.Cell></Table.Cell> : null}
        {!plusMonthNWT ? <Table.Cell></Table.Cell> : null}
        {!plusMonthHoliBasicTime ? <Table.Cell></Table.Cell> : null}
        {!plusMonthOWTHoli ? <Table.Cell></Table.Cell> : null}
        {!plusMonthNWTHoli ? <Table.Cell></Table.Cell> : null}
        {!absentDays ? <Table.Cell></Table.Cell> : null}
        {!absentPaidHoliDays ? <Table.Cell></Table.Cell> : null}
        {!absentTimes ? <Table.Cell></Table.Cell> : null}
        {!usedAnnualLeaves ? <Table.Cell></Table.Cell> : null}
      </Table.Row>
      <Table.Row>
        {plusMonthOWT ? <Table.Cell style={{ width: '11.11%', textAlign: 'center' }}>{plusMonthOWT}</Table.Cell> : null}
        {plusMonthNWT ? <Table.Cell style={{ width: '11.11%', textAlign: 'center' }}>{plusMonthNWT}</Table.Cell> : null}
        {plusMonthHoliBasicTime ? <Table.Cell style={{ width: '11.11%', textAlign: 'center' }}>{plusMonthHoliBasicTime}</Table.Cell> : null}
        {plusMonthOWTHoli ? <Table.Cell style={{ width: '11.11%', textAlign: 'center' }}>{plusMonthOWTHoli}</Table.Cell> : null}
        {plusMonthNWTHoli ? <Table.Cell style={{ width: '11.11%', textAlign: 'center' }}>{plusMonthNWTHoli}</Table.Cell> : null}
        {absentDays ? <Table.Cell style={{ width: '11.11%', textAlign: 'center' }}>{absentDays}</Table.Cell> : null}
        {absentPaidHoliDays ? <Table.Cell style={{ width: '11.11%', textAlign: 'center' }}>{absentPaidHoliDays}</Table.Cell> : null}
        {absentTimes ? <Table.Cell style={{ width: '11.11%', textAlign: 'center' }}>{absentTimes}</Table.Cell> : null }
        {usedAnnualLeaves ? <Table.Cell style={{ width: '11.11%', textAlign: 'center' }}>{usedAnnualLeaves}</Table.Cell> : null}

        {!plusMonthOWT ? <Table.Cell></Table.Cell> : null}
        {!plusMonthNWT ? <Table.Cell></Table.Cell> : null}
        {!plusMonthHoliBasicTime ? <Table.Cell></Table.Cell> : null}
        {!plusMonthOWTHoli ? <Table.Cell></Table.Cell> : null}
        {!plusMonthNWTHoli ? <Table.Cell></Table.Cell> : null}
        {!absentDays ? <Table.Cell></Table.Cell> : null}
        {!absentPaidHoliDays ? <Table.Cell></Table.Cell> : null}
        {!absentTimes ? <Table.Cell></Table.Cell> : null}
        {!usedAnnualLeaves ? <Table.Cell></Table.Cell> : null}
      </Table.Row>    
      <Table.Row>
        {absentDates ?
          <>
            <Table.Cell style={{ width: '24%', textAlign: 'center' }}>결근일자 : </Table.Cell>
            <Table.Cell colSpan='2' style={{ width: '24%', textAlign: 'center' }}>{absentDates}</Table.Cell>
          </>
          : null
        }
        {absentPaidHoliDates ?
          <>
            <Table.Cell style={{ width: '24%', textAlign: 'center' }}>주휴공제일자 : </Table.Cell>
            <Table.Cell style={{ width: '24%', textAlign: 'center' }}>{absentPaidHoliDates}</Table.Cell>
          </>
          : null
        }
        {absentTimeDates ?
          <>
            <Table.Cell style={{ width: '24%', textAlign: 'center' }}>공제시간일자 : </Table.Cell>
            <Table.Cell  style={{ width: '24%', textAlign: 'center' }}>{absentTimeDates}</Table.Cell>
          </>
          : null
        }
        {usedAnnualLeaveDates ?
          <>
            <Table.Cell style={{ width: '24%', textAlign: 'center' }}>사용연차일자 : </Table.Cell>
            <Table.Cell colSpan='2' style={{ width: '24%', textAlign: 'center' }}>{usedAnnualLeaveDates}</Table.Cell>
          </>
          : null
        }
      </Table.Row>       
    </>
  )
}
