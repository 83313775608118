import React from 'react';
import {
	absentDaysLawOverWageF,
	absentDaysLawOverWageText,
	absentDaysOrdinaryWageF,
	absentDayWageText,
	commaStringToNumber,
	cTFCarWageLimit,
	cTFChildWageLimit,
	cTFMealWageLimit,
	cTFStudyWageLimit,
	daysOfInterInAbusentLawOverWageText,
	daysOfInterInAbusentOrdinaryWageText,
	daysOfInterOutAbusentLawOverWageText,
	daysOfInterOutAbusentOrdinaryWageText,
	holiBasicTimeText,
	holiBTimePaidWageF,
	holiNightTimeText,
	holiNTimePaidWageF,
	holiOTimePaidWageF,
	holiOverTimeText,
	isShortTimeRegularWorkerF,
	nightTimeText,
	numberToCommaString,
	numStringToNumber,
	overTimeText,
	roundDown,
	roundUp,
	workDayNTimePaidWageF,
	workDayOTimePaidWageF,
	workingDaysInWeekF,
} from '../../../../../../../app/common/util/util';

import ClientReqDLCWageTableDetailCar from './ClientReqDLCWageTableDetailCar';
import ClientReqDLCWageTableDetailChild from './ClientReqDLCWageTableDetailChild';
import ClientReqDLCWageTableDetailMeal from './ClientReqDLCWageTableDetailMeal';
import ClientReqDLCWageTableDetailStudy from './ClientReqDLCWageTableDetailStudy';
import ClientReqDLCWageTableDetailTFEtc from './ClientReqDLCWageTableDetailTFEtc';
import ClientReqDLCWageTableDetailTitle from './ClientReqDLCWageTableDetailTitle';
import ClientReqDLCWageTableDetailJob from './ClientReqDLCWageTableDetailJob';
import ClientReqDLCWageTableDetailLicense from './ClientReqDLCWageTableDetailLicense';
import ClientReqDLCWageTableDetailMonthlyBonus from './ClientReqDLCWageTableDetailMonthlyBonus';
import ClientReqDLCWageTableDetailMonthlyEtc from './ClientReqDLCWageTableDetailMonthlyEtc';
import ClientReqDLCWageTableDetailAWage5 from './ClientReqDLCWageTableDetailAWage5';
import ClientReqDLCWageTableDetailAWage6 from './ClientReqDLCWageTableDetailAWage6';
import ClientReqDLCWageTableDetailAWage7 from './ClientReqDLCWageTableDetailAWage7';
import ClientReqDLCWageTableDetailBasic from './ClientReqDLCWageTableDetailBasic';
import ClientReqDLCWageTableDeductDetail from '../deductDetail/ClientReqDLCWageTableDeductDetail';
import { Table } from 'semantic-ui-react';
import ClientReqDLCWageTableDetailChildManageStudy from './ClientReqDLCWageTableDetailChildManageStudy';
import ClientReqDLCWageTableDetailChildStudy from './ClientReqDLCWageTableDetailChildStudy';
import ClientReqDLCWageTableDetailChildCare from './ClientReqDLCWageTableDetailChildCare';
import ClientReqDLCWageTableDetailChildActivity from './ClientReqDLCWageTableDetailChildActivity';
import ClientReqDLCWageTableDetailLongTerm from './ClientReqDLCWageTableDetailLongTerm';

export default function ClientReqDLCWageTablePayDetail({
	perDayMealTaxFree,
	plusIrregularDayMealTFDays,
	retiredMonthYes,
	contractEndDate,
	workerEnteredDate,
	sumOfLawOverWage,

	maternityFinalDedectWage,

	selectedPaydocuFirst,
	healthInsureOver60,
	finalRetireHealthCareRecalFee,
	finalRetireOldCareRecalFee,
	values,
	annualLeavesIncludeMonthWage,
	isExceptTimeLawWorker,
	initialTax,
	isInterInWorker,
	thisYear,
	thisMonth,
	inputTax,
	handelInputChange,
	selectedClientReq4In,
	weekLBTime,
	numOfWorkers,
	hourOrdinaryWage,
	paidHolidayLBTime,
	inputWageStyle,
	centerStyle,
	inputCommentStyle,

	daysOfInterInAbusent,
	daysOfInterOutAbusent,

	plusMonthOWT,
	plusMonthNWT,
	plusMonthHoliBasicTime,
	plusMonthOWTHoli,
	plusMonthNWTHoli,

	// 11월24일-공통
	workingDays,
	// --------

	userFixedCAWageTF1,
	userFixedCAWageTF2,
	userFixedCAWageTF3,
	userFixedCAWageTF4,
	userFixedCAWage1,
	userFixedCAWage2,
	userFixedCAWage3,
	userFixedCAWage4,

	cAWage5,
	cAWEName5,
	cAWage5Comment,
	cAWage6,
	cAWEName6,
	cAWage6Comment,
	cAWage7,
	cAWEName7,
	cAWage7Comment,
	deductEtcName1,
	deductEtcAWage1,
	deductEtcWage1Comment,
	deductEtcName2,
	deductEtcAWage2,
	deductEtcWage2Comment,
	deductEtcName3,
	deductEtcAWage3,
	deductEtcWage3Comment,

	// 11월26일-공통
	userFixedTax,
	userFixedRegionTax,
	userFixedHealthCare,
	userFixedOldCare,
	userFixedPension,
	userFixedNoJobInsurance,

	absentDays,
	absentPaidHoliDays,
	absentTimes,
	daysInMonth,
	monthOrdinaryWage,

	plusRegularBonus,
	plusIrregularBonus,
	plusRetiredUnusedAnnualLeavesWage,
	plusSpecialRandomWage,
	plusLastMonthAverWage,
	plusPersonalIncentive,

	plusRegularBonusComment,
	plusIrregularBonusComment,
	plusLastMonthAverWageComment,
	plusUnusedAnnualLeavesDays,
	plusPersonalIncentiveComment,

	plusDeductHealthCare,
	plusDeductOldCare,
	plusDeductWorkTax,
	plusDeductWorkRegionTax,
	plusDeductDuru,
	plusMinus4Ins,
	plusDeductWorkerCompanyLoan,

	plusDeductHealthCareComment,
	plusDeductOldCareComment,
	plusDeductWorkTaxComment,
	plusDeductWorkRegionTaxComment,
	plusDeductDuruComment,
	plusMinus4InsComment,
	plusDeductWorkerCompanyLoanComment,
	setValueTaxWageSum,
}) {
	const monthOWT =
		selectedClientReq4In?.numOfWorkers < 5
			? 0
			: selectedClientReq4In?.realWorkTime?.monthOWT;
	const monthNWT = selectedClientReq4In?.realWorkTime?.monthNWT;
	const monthHoliBasicTime =
		selectedClientReq4In?.numOfWorkers < 5
			? 0
			: selectedClientReq4In?.realWorkTime?.monthHoliBasicTime;
	const monthOWTHoli =
		selectedClientReq4In?.numOfWorkers < 5
			? 0
			: selectedClientReq4In?.realWorkTime?.monthNOWTHoli;
	const monthNWTHoli = selectedClientReq4In?.realWorkTime?.monthNWTHoli;

	const selectedPBasicWage = selectedPaydocuFirst?.fixedWage?.basicWage;
	const contractMonthBasicWage =
		selectedClientReq4In?.calWorkWage?.monthBasicWage;
	const monthBasicWageJudge =
		selectedPBasicWage || contractMonthBasicWage || null;
	const monthBasicWage = numberToCommaString(roundDown(monthBasicWageJudge));
	const monthBasicWageComment = '통상임금 중 기본 근로(직무)에 대한 임금';

	const selectedPMealWage = selectedPaydocuFirst?.fixedWage?.mealWage;
	const contractMonthMealWage = selectedClientReq4In?.cTFMealWage;
	const cTFMealWage = numberToCommaString(
		selectedPMealWage || contractMonthMealWage || null
	);
	const cTFMealWageComment = `복리후생적으로 지급하는 식대보조비${cTFMealWageLimit}`;

	const selectedPCarWage = selectedPaydocuFirst?.fixedWage?.carWage;
	const contractMonthCarWage = selectedClientReq4In?.cTFCarWage;
	const cTFCarWage = numberToCommaString(
		selectedPCarWage || contractMonthCarWage || null
	);
	const cTFCarWageComment = `본인 소유 차량 회사 업무 이용 차량유지비${cTFCarWageLimit}`;

	const selectedPChildWage = selectedPaydocuFirst?.fixedWage?.childWage;
	const contractMonthChildWage = selectedClientReq4In?.cTFChildWage;
	const cTFChildWage = numberToCommaString(
		selectedPChildWage || contractMonthChildWage || null
	);
	const cTFChildWageComment = `만6세미만 자녀를 양육하는 직원에게 지급하는 육아수당${cTFChildWageLimit}`;

	const selectedPStudyWage = selectedPaydocuFirst?.fixedWage?.studyWage;
	const contractMonthStudyWage = selectedClientReq4In?.cTFStudyWage;
	const cTFStudyWage = numberToCommaString(
		selectedPStudyWage || contractMonthStudyWage || null
	);
	const cTFStudyWageComment = `기업부설 연구소 등 연구원의 연구수당${cTFStudyWageLimit}`;

	const selectedPTFetc = selectedPaydocuFirst?.fixedWage?.tFEtcWage;
	const contractMonthTFetc = selectedClientReq4In?.cTFEtc;
	const cTFEtc = numberToCommaString(
		selectedPTFetc || contractMonthTFetc || null
	);
	const cTFEtcComment =
		'기타 비과세 소계(식대 차량유지비 육아수당 연구수당 외 비과세 합계)';

	const selectedPTitleWage = selectedPaydocuFirst?.fixedWage?.titleWage;
	const contractMonthTitleWage = selectedClientReq4In?.cOWTitle;
	const cOWTitle = numberToCommaString(
		selectedPTitleWage || contractMonthTitleWage || null
	);
	const cOWTitleComment =
		'기밀취급자 또는 근태와 인사고과(전부/일부) 권한이 있는 관리감독자에게 지급하는 수당';

	const selectedPJobWage = selectedPaydocuFirst?.fixedWage?.jobWage;
	const contractMonthJobWage = selectedClientReq4In?.cOWJob;
	const cOWJob = numberToCommaString(
		selectedPJobWage || contractMonthJobWage || null
	);
	const cOWJobComment = '특정 직무에 따라 지급하는 직무수당';

	const selectedPLicenseWage = selectedPaydocuFirst?.fixedWage?.licenseWage;
	const contractMonthLicenseWage = selectedClientReq4In?.cOWLicense;
	const cOWLicense = numberToCommaString(
		selectedPLicenseWage || contractMonthLicenseWage || null
	);
	const cOWLicenseComment =
		'직무 수행에 필요한 자격(또는 면허)를 보유한 직원에게 지급하는 수당';

	const selectedPLongTermWage = selectedPaydocuFirst?.fixedWage?.longTerm;
	const contractMonthLongTermWage = selectedClientReq4In?.cOWLongTerm;
	const cOWLongTerm = numberToCommaString(
		selectedPLongTermWage || contractMonthLongTermWage || null
	);
	const cOWLongTermComment = '장기근속에 대한 보상으로지급하는 수당';

	const selectedPMonthlyBonusWage =
		selectedPaydocuFirst?.fixedWage?.monthlyBonusWage;
	const contractMonthMonthlyBonusWage = selectedClientReq4In?.cOWMonthlyBonus;
	const cOWMonthlyBonus = numberToCommaString(
		selectedPMonthlyBonusWage || contractMonthMonthlyBonusWage || null
	);
	const cOWMonthlyBonusComment = '월 고정 상여금';

	const selectedPOWEtcWage = selectedPaydocuFirst?.fixedWage?.oWEtcWage;
	const selectedPOWEtcComment = selectedPaydocuFirst?.fixedWage?.oWEtcComment;
	const contractMonthOWEtcWage = selectedClientReq4In?.ordinaryWage?.etc;
	const contractOWEtcComment = selectedClientReq4In?.ordinaryWage?.etcComment;
	const cOWEtc = numberToCommaString(
		selectedPOWEtcWage || contractMonthOWEtcWage || null
	);
	const cOWEtcComment = selectedPOWEtcComment || contractOWEtcComment || null;

	const cOWChildManageStudyWage =
		selectedClientReq4In?.ordinaryWage?.childManageStudy;
	const cOWChildManageStudyWageComment =
		selectedClientReq4In?.cOWChildManageStudyComment;
	const cOWChildStudyWage = selectedClientReq4In?.ordinaryWage?.childStudy;
	const cOWChildStudyWageComment = selectedClientReq4In?.cOWChildStudyComment;
	const cOWChildCareWage = selectedClientReq4In?.ordinaryWage?.childCare;
	const cOWChildCareWageComment = selectedClientReq4In?.cOWChildCareComment;
	const cOWChildActivityWage =
		selectedClientReq4In?.ordinaryWage?.childActivity;
	const cOWChildActivityWageComment =
		selectedClientReq4In?.cOWChildActivityComment;

	const selectedPAWage1Wage = selectedPaydocuFirst?.fixedWage?.aWage1Wage;
	const contractAWage1Wage = selectedClientReq4In?.averageWage?.irregularAWage1
		? commaStringToNumber(selectedClientReq4In?.averageWage?.irregularAWage1)
		: '';
	const selectedPAWage1Comment = selectedPaydocuFirst?.fixedWage?.aWage1Comment;
	const contractAWage1Comment =
		selectedClientReq4In?.averageWage?.irregularAWCondition1;
	const cAWage1 = selectedPAWage1Wage || contractAWage1Wage || null;
	const cAWage1Comment =
		selectedPAWage1Comment || contractAWage1Comment || null;

	const selectedPAWage2Wage = selectedPaydocuFirst?.fixedWage?.aWage2Wage;
	const contractAWage2Wage = selectedClientReq4In?.averageWage?.irregularAWage2
		? commaStringToNumber(selectedClientReq4In?.averageWage?.irregularAWage2)
		: '';
	const selectedPAWage2Comment = selectedPaydocuFirst?.fixedWage?.aWage2Comment;
	const contractAWage2Comment =
		selectedClientReq4In?.averageWage?.irregularAWCondition2;
	const cAWage2 = selectedPAWage2Wage || contractAWage2Wage || null;
	const cAWage2Comment =
		selectedPAWage2Comment || contractAWage2Comment || null;

	const selectedPAWage3Wage = selectedPaydocuFirst?.fixedWage?.aWage3Wage;
	const contractAWage3Wage = selectedClientReq4In?.averageWage?.irregularAWage3
		? commaStringToNumber(selectedClientReq4In?.averageWage?.irregularAWage3)
		: '';
	const selectedPAWage3Comment = selectedPaydocuFirst?.fixedWage?.aWage3Comment;
	const contractAWage3Comment =
		selectedClientReq4In?.averageWage?.irregularAWCondition3;
	const cAWage3 = selectedPAWage3Wage || contractAWage3Wage || null;
	const cAWage3Comment =
		selectedPAWage3Comment || contractAWage3Comment || null;

	const selectedPAWage4Wage = selectedPaydocuFirst?.fixedWage?.aWage4Wage;
	const contractAWage4Wage = selectedClientReq4In?.averageWage?.irregularAWage4
		? commaStringToNumber(selectedClientReq4In?.averageWage?.irregularAWage4)
		: '';
	const selectedPAWage4Comment = selectedPaydocuFirst?.fixedWage?.aWage4Comment;
	const contractAWage4Comment =
		selectedClientReq4In?.averageWage?.irregularAWCondition4;
	const cAWage4 = selectedPAWage4Wage || contractAWage4Wage || null;
	const cAWage4Comment =
		selectedPAWage4Comment || contractAWage4Comment || null;

	const selectedPAWageTF1Wage = selectedPaydocuFirst?.fixedWage?.aWageTF1Wage;
	const contractAWageTF1Wage = selectedClientReq4In?.averageWageTF
		?.irregularAWage1
		? commaStringToNumber(selectedClientReq4In?.averageWageTF?.irregularAWage1)
		: '';
	const selectedPAWageTF1Comment =
		selectedPaydocuFirst?.fixedWage?.aWageTF1Comment;
	const contractAWageTF1Comment =
		selectedClientReq4In?.averageWageTF?.irregularAWCondition1;
	const selectedPAWageTF1Name = selectedPaydocuFirst?.fixedWage?.aWENameTF1;
	const contractAWageTF1Name =
		selectedClientReq4In?.averageWageTF?.irregularAWName1;
	const cAWageTF1 = selectedPAWageTF1Wage || contractAWageTF1Wage || null;
	const cAWageTF1Comment =
		selectedPAWageTF1Comment || contractAWageTF1Comment || null;
	const cAWageTF1Name = selectedPAWageTF1Name || contractAWageTF1Name || null;

	const selectedPAWageTF2Wage = selectedPaydocuFirst?.fixedWage?.aWageTF2Wage;
	const contractAWageTF2Wage = selectedClientReq4In?.averageWageTF
		?.irregularAWage2
		? commaStringToNumber(selectedClientReq4In?.averageWageTF?.irregularAWage2)
		: '';
	const selectedPAWageTF2Comment =
		selectedPaydocuFirst?.fixedWage?.aWageTF2Comment;
	const contractAWageTF2Comment =
		selectedClientReq4In?.averageWageTF?.irregularAWCondition2;
	const selectedPAWageTF2Name = selectedPaydocuFirst?.fixedWage?.aWENameTF2;
	const contractAWageTF2Name =
		selectedClientReq4In?.averageWageTF?.irregularAWName2;
	const cAWageTF2 = selectedPAWageTF2Wage || contractAWageTF2Wage || null;
	const cAWageTF2Comment =
		selectedPAWageTF2Comment || contractAWageTF2Comment || null;
	const cAWageTF2Name = selectedPAWageTF2Name || contractAWageTF2Name || null;

	const selectedPAWageTF3Wage = selectedPaydocuFirst?.fixedWage?.aWageTF3Wage;
	const contractAWageTF3Wage = selectedClientReq4In?.averageWageTF
		?.irregularAWage3
		? commaStringToNumber(selectedClientReq4In?.averageWageTF?.irregularAWage3)
		: '';
	const selectedPAWageTF3Comment =
		selectedPaydocuFirst?.fixedWage?.aWageTF3Comment;
	const contractAWageTF3Comment =
		selectedClientReq4In?.averageWageTF?.irregularAWCondition3;
	const selectedPAWageTF3Name = selectedPaydocuFirst?.fixedWage?.aWENameTF3;
	const contractAWageTF3Name =
		selectedClientReq4In?.averageWageTF?.irregularAWName3;
	const cAWageTF3 = selectedPAWageTF3Wage || contractAWageTF3Wage || null;
	const cAWageTF3Comment =
		selectedPAWageTF3Comment || contractAWageTF3Comment || null;
	const cAWageTF3Name = selectedPAWageTF3Name || contractAWageTF3Name || null;

	const selectedPAWageTF4Wage = selectedPaydocuFirst?.fixedWage?.aWageTF4Wage;
	const contractAWageTF4Wage = selectedClientReq4In?.averageWageTF
		?.irregularAWage4
		? commaStringToNumber(selectedClientReq4In?.averageWageTF?.irregularAWage4)
		: '';
	const selectedPAWageTF4Comment =
		selectedPaydocuFirst?.fixedWage?.aWageTF4Comment;
	const contractAWageTF4Comment =
		selectedClientReq4In?.averageWageTF?.irregularAWCondition4;
	const selectedPAWageTF4Name = selectedPaydocuFirst?.fixedWage?.aWENameTF4;
	const contractAWageTF4Name =
		selectedClientReq4In?.averageWageTF?.irregularAWName4;
	const cAWageTF4 = selectedPAWageTF4Wage || contractAWageTF4Wage || null;
	const cAWageTF4Comment =
		selectedPAWageTF4Comment || contractAWageTF4Comment || null;
	const cAWageTF4Name = selectedPAWageTF4Name || contractAWageTF4Name || null;

	// 12월3일-공통
	// 결근일
	const numAbsentDays = numStringToNumber(absentDays);
	// 결근주휴일
	const numAbsentPaidHoliDays = numStringToNumber(absentPaidHoliDays);
	// 결근시간
	const numAbsentTimes = numStringToNumber(absentTimes);

	// 중도입사공제(통상임금 일할계산)
	const daysOfInterInAbusentOrdinaryWage = roundDown(
		(monthOrdinaryWage / daysInMonth) * daysOfInterInAbusent
	);

	// 중도입사공제(법정가산임금 일할계산)
	const daysOfInterInAbusentLawOverWage = roundDown(
		(sumOfLawOverWage / daysInMonth) * daysOfInterInAbusent
	);

	// 중도퇴사공제(통상임금 일할계산)
	const daysOfInterOutAbusentOrdinaryWage = roundDown(
		(monthOrdinaryWage / daysInMonth) * daysOfInterOutAbusent
	);

	// 중도퇴사공제(법정가산임금 일할계산)
	const daysOfInterOutAbusentLawOverWage = roundDown(
		(sumOfLawOverWage / daysInMonth) * daysOfInterOutAbusent
	);

	const workingDaysInWeek = workingDaysInWeekF(
		selectedClientReq4In?.selectWorkDays1?.length || 0,
		selectedClientReq4In?.selectWorkDays2?.length || 0,
		selectedClientReq4In?.selectWorkDays3?.length || 0,
		selectedClientReq4In?.selectWorkDays4?.length || 0,
		selectedClientReq4In?.selectWorkDays5?.length || 0,
		selectedClientReq4In?.selectWorkDays6?.length || 0,
		selectedClientReq4In?.selectWorkDays7?.length || 0
	);

	const isShortTimeRegularWorker = isShortTimeRegularWorkerF(workingDaysInWeek);

	const ordinaryDayWorkingTime =
		selectedClientReq4In?.lawBase?.weekLBTime / workingDaysInWeek;
	// 결근공제(통상임금 일할계산)
	const absentDaysOrdinaryWage = absentDaysOrdinaryWageF(
		monthOrdinaryWage,
		daysInMonth,
		numAbsentDays,
		isShortTimeRegularWorker,
		ordinaryDayWorkingTime,
		hourOrdinaryWage
	);

	// 주휴공제(통상임금 일할계산)
	const absentPaidHoliDaysOrdinaryWage = roundDown(
		paidHolidayLBTime * hourOrdinaryWage * numAbsentPaidHoliDays
	);
	// 결근공제(법정가산임금 일할계산)
	const absentDaysLawOverWage = absentDaysLawOverWageF(
		sumOfLawOverWage,
		daysInMonth,
		numAbsentDays,
		isShortTimeRegularWorker,
		workingDaysInWeek
	);

	// 결근시간공제
	const absentTimeWage = roundDown(hourOrdinaryWage * numAbsentTimes);

	const numBasicWage = numStringToNumber(monthBasicWage);
	const numMealWage = numStringToNumber(cTFMealWage);
	const numCarWage = numStringToNumber(cTFCarWage);
	const numChildWage = numStringToNumber(cTFChildWage);
	const numStudyWage = numStringToNumber(cTFStudyWage);
	const numTFEtcWage = numStringToNumber(cTFEtc);
	const numTitleWage = numStringToNumber(cOWTitle);
	const numJobWage = numStringToNumber(cOWJob);
	const numLicenseWage = numStringToNumber(cOWLicense);
	const numLongTermWage = numStringToNumber(cOWLongTerm);
	const numMonthlyBonusWage = numStringToNumber(cOWMonthlyBonus);
	const numcOWEtcWage = numStringToNumber(cOWEtc);

	//어린이집
	const numcOWChildManageStudyWage = numStringToNumber(cOWChildManageStudyWage);
	const numcOWChildStudyWage = numStringToNumber(cOWChildStudyWage);
	const numcOWChildCareWage = numStringToNumber(cOWChildCareWage);
	const numcOWChildActivityWage = numStringToNumber(cOWChildActivityWage);

	// 11월24일-공통
	const cOWDay1 = selectedClientReq4In?.ordinaryWage?.day1; //DB에 입력된 일당1 기준
	const cOWDay1Name = selectedClientReq4In?.ordinaryWage?.day1Name; //DB에 입력된 일당1 명칭

	const cOWDay2 = selectedClientReq4In?.ordinaryWage?.day2;
	const cOWDay2Name = selectedClientReq4In?.ordinaryWage?.day2Name;

	const cOWDay3 = selectedClientReq4In?.ordinaryWage?.day3;
	const cOWDay3Name = selectedClientReq4In?.ordinaryWage?.day3Name;

	const numCOWDay1Sum =
		(cOWDay1 ? numStringToNumber(cOWDay1) : 0) * workingDays; //임금명세서(회원이 일당*근무일수한거)
	const numCOWDay2Sum =
		(cOWDay2 ? numStringToNumber(cOWDay2) : 0) * workingDays;
	const numCOWDay3Sum =
		(cOWDay3 ? numStringToNumber(cOWDay3) : 0) * workingDays;
	// --------

	const numcAWage5Wage = cAWage5 ? numStringToNumber(cAWage5) : 0;
	const numcAWage6Wage = cAWage6 ? numStringToNumber(cAWage6) : 0;
	const numcAWage7Wage = cAWage7 ? numStringToNumber(cAWage7) : 0;

	const numPlusRegularBonus = numStringToNumber(plusRegularBonus);
	const numPlusIrregularBonus = numStringToNumber(plusIrregularBonus);
	const numPlusUnusedAnnualLeavesWage = numStringToNumber(
		roundUp(
			commaStringToNumber(plusUnusedAnnualLeavesDays) *
				paidHolidayLBTime *
				hourOrdinaryWage
		)
	);
	const numPlusRetiredUnusedAnnualLeavesWage = numStringToNumber(
		roundUp(
			commaStringToNumber(plusRetiredUnusedAnnualLeavesWage) *
				paidHolidayLBTime *
				hourOrdinaryWage
		)
	);
	const numPlusSpecialRandomWage = numStringToNumber(plusSpecialRandomWage);
	const numPlusPersonalIncentive = numStringToNumber(plusPersonalIncentive);
	const numPlusLastMonthAverWage = numStringToNumber(plusLastMonthAverWage);

	const gumakStyle = { textAlign: 'right' };

	const oWage = workDayOTimePaidWageF(
		monthOWT,
		plusMonthOWT,
		hourOrdinaryWage,
		numOfWorkers,
		isExceptTimeLawWorker
	);
	const nWage = workDayNTimePaidWageF(
		monthNWT,
		plusMonthNWT,
		hourOrdinaryWage,
		numOfWorkers,
		isExceptTimeLawWorker
	);
	const hBWage = holiBTimePaidWageF(
		monthHoliBasicTime,
		plusMonthHoliBasicTime,
		hourOrdinaryWage,
		numOfWorkers,
		isExceptTimeLawWorker
	);
	const hOWage = holiOTimePaidWageF(
		monthOWTHoli,
		plusMonthOWTHoli,
		hourOrdinaryWage,
		numOfWorkers,
		isExceptTimeLawWorker
	);
	const hNWage = holiNTimePaidWageF(
		monthNWTHoli,
		plusMonthNWTHoli,
		hourOrdinaryWage,
		numOfWorkers,
		isExceptTimeLawWorker
	);

	const numOWage = oWage ? numStringToNumber(oWage) : 0;
	const numNWage = nWage ? numStringToNumber(nWage) : 0;
	const numHBwage = hBWage ? numStringToNumber(hBWage) : 0;
	const numHOwage = hOWage ? numStringToNumber(hOWage) : 0;
	const numHNwage = hNWage ? numStringToNumber(hNWage) : 0;

	const numcAWage1Wage = numStringToNumber(cAWage1);
	const numcAWage2Wage = numStringToNumber(cAWage2);
	const numcAWage3Wage = numStringToNumber(cAWage3);
	const numcAWage4Wage = numStringToNumber(cAWage4);

	const cAWage1Name = selectedClientReq4In?.averageWage?.irregularAWName1
		? selectedClientReq4In?.averageWage.irregularAWName1
		: '';
	const cAWage2Name = selectedClientReq4In?.averageWage?.irregularAWName2
		? selectedClientReq4In?.averageWage.irregularAWName2
		: '';
	const cAWage3Name = selectedClientReq4In?.averageWage?.irregularAWName3
		? selectedClientReq4In?.averageWage.irregularAWName3
		: '';
	const cAWage4Name = selectedClientReq4In?.averageWage?.irregularAWName4
		? selectedClientReq4In?.averageWage.irregularAWName4
		: '';

	const numUserFixedCAWage1 =
		userFixedCAWage1 === 0
			? 0
			: userFixedCAWage1
			? numStringToNumber(userFixedCAWage1)
			: numcAWage1Wage;
	const numUserFixedCAWage2 =
		userFixedCAWage2 === 0
			? 0
			: userFixedCAWage2
			? numStringToNumber(userFixedCAWage2)
			: numcAWage2Wage;
	const numUserFixedCAWage3 =
		userFixedCAWage3 === 0
			? 0
			: userFixedCAWage3
			? numStringToNumber(userFixedCAWage3)
			: numcAWage3Wage;
	const numUserFixedCAWage4 =
		userFixedCAWage4 === 0
			? 0
			: userFixedCAWage4
			? numStringToNumber(userFixedCAWage4)
			: numcAWage4Wage;

	const numcAWageTF1Wage = !cAWageTF1 ? 0 : numStringToNumber(cAWageTF1);
	const numcAWageTF2Wage = !cAWageTF2 ? 0 : numStringToNumber(cAWageTF2);
	const numcAWageTF3Wage = !cAWageTF3 ? 0 : numStringToNumber(cAWageTF3);
	const numcAWageTF4Wage = !cAWageTF4 ? 0 : numStringToNumber(cAWageTF4);

	const numUserFixedCAWageTF1 =
		userFixedCAWageTF1 === 0
			? 0
			: userFixedCAWageTF1
			? numStringToNumber(userFixedCAWageTF1)
			: numcAWageTF1Wage;
	const numUserFixedCAWageTF2 =
		userFixedCAWageTF2 === 0
			? 0
			: userFixedCAWageTF2
			? numStringToNumber(userFixedCAWageTF2)
			: numcAWageTF2Wage;
	const numUserFixedCAWageTF3 =
		userFixedCAWageTF3 === 0
			? 0
			: userFixedCAWageTF3
			? numStringToNumber(userFixedCAWageTF3)
			: numcAWageTF3Wage;
	const numUserFixedCAWageTF4 =
		userFixedCAWageTF4 === 0
			? 0
			: userFixedCAWageTF4
			? numStringToNumber(userFixedCAWageTF4)
			: numcAWageTF4Wage;

	const plusIrregularDayMealTFWage =
		perDayMealTaxFree * plusIrregularDayMealTFDays;

	const pureTotalMonthWageAmount =
		(numBasicWage ? numBasicWage : 0) +
		(numMealWage ? numMealWage : 0) +
		(numCarWage ? numCarWage : 0) +
		(numChildWage ? numChildWage : 0) +
		(numStudyWage ? numStudyWage : 0) +
		(numTFEtcWage ? numTFEtcWage : 0) +
		(numTitleWage ? numTitleWage : 0) +
		(numJobWage ? numJobWage : 0) +
		(numLicenseWage ? numLicenseWage : 0) +
		(numLongTermWage ? numLongTermWage : 0) +
		(numMonthlyBonusWage ? numMonthlyBonusWage : 0) +
		(numcOWEtcWage ? numcOWEtcWage : 0) +
		//어린이집
		(numcOWChildManageStudyWage ? numcOWChildManageStudyWage : 0) +
		(numcOWChildStudyWage ? numcOWChildStudyWage : 0) +
		(numcOWChildCareWage ? numcOWChildCareWage : 0) +
		(numcOWChildActivityWage ? numcOWChildActivityWage : 0) +
		// 11월24일-공통
		(numCOWDay1Sum ? numCOWDay1Sum : 0) +
		(numCOWDay2Sum ? numCOWDay2Sum : 0) +
		(numCOWDay3Sum ? numCOWDay3Sum : 0) +
		// --------
		(numOWage ? numOWage : 0) +
		(numNWage ? numNWage : 0) +
		(numHBwage ? numHBwage : 0) +
		(numHOwage ? numHOwage : 0) +
		(numHNwage ? numHNwage : 0) +
		(numUserFixedCAWageTF1 ? roundUp(numUserFixedCAWageTF1) : 0) +
		(numUserFixedCAWageTF2 ? roundUp(numUserFixedCAWageTF2) : 0) +
		(numUserFixedCAWageTF3 ? roundUp(numUserFixedCAWageTF3) : 0) +
		(numUserFixedCAWageTF4 ? roundUp(numUserFixedCAWageTF4) : 0) +
		(numUserFixedCAWage1 ? roundUp(numUserFixedCAWage1) : 0) +
		(numUserFixedCAWage2 ? roundUp(numUserFixedCAWage2) : 0) +
		(numUserFixedCAWage3 ? roundUp(numUserFixedCAWage3) : 0) +
		(numUserFixedCAWage4 ? roundUp(numUserFixedCAWage4) : 0) +
		(numPlusRegularBonus ? numPlusRegularBonus : 0) +
		(numPlusIrregularBonus ? numPlusIrregularBonus : 0) +
		(numPlusRetiredUnusedAnnualLeavesWage
			? numPlusRetiredUnusedAnnualLeavesWage
			: 0) +
		(numPlusSpecialRandomWage ? numPlusSpecialRandomWage : 0) +
		(numPlusLastMonthAverWage ? numPlusLastMonthAverWage : 0) +
		(numPlusPersonalIncentive ? numPlusPersonalIncentive : 0) +
		(numcAWage5Wage ? numcAWage5Wage : 0) +
		(numcAWage6Wage ? numcAWage6Wage : 0) +
		(numcAWage7Wage ? numcAWage7Wage : 0) +
		(numPlusUnusedAnnualLeavesWage ? numPlusUnusedAnnualLeavesWage : 0) +
		(plusIrregularDayMealTFWage ? plusIrregularDayMealTFWage : 0);

	const pureTotalMonthWageAmountMinus =
		(annualLeavesIncludeMonthWage
			? Math.ceil(annualLeavesIncludeMonthWage)
			: 0) -
		(daysOfInterInAbusentOrdinaryWage ? daysOfInterInAbusentOrdinaryWage : 0) -
		(daysOfInterInAbusentLawOverWage ? daysOfInterInAbusentLawOverWage : 0) -
		(daysOfInterOutAbusentOrdinaryWage
			? daysOfInterOutAbusentOrdinaryWage
			: 0) -
		(daysOfInterOutAbusentLawOverWage ? daysOfInterOutAbusentLawOverWage : 0) -
		(absentDaysOrdinaryWage ? absentDaysOrdinaryWage : 0) -
		(absentPaidHoliDaysOrdinaryWage ? absentPaidHoliDaysOrdinaryWage : 0) -
		(absentDaysLawOverWage ? absentDaysLawOverWage : 0) -
		(absentTimeWage ? absentTimeWage : 0) -
		(maternityFinalDedectWage[0] ? roundUp(maternityFinalDedectWage[0]) : 0) -
		(maternityFinalDedectWage[1] ? roundUp(maternityFinalDedectWage[1]) : 0);

	const totalMonthWageAmount =
		pureTotalMonthWageAmount + pureTotalMonthWageAmountMinus;
	// const totalPlusMinusWageRate = ( (pureTotalMonthWageAmountMinus * -1) / pureTotalMonthWageAmount)

	// 11월24일-공통
	const cOWDay1SumString = numberToCommaString(numCOWDay1Sum);
	const cOWDay1SumComment = `근무일 ${workingDays}일 * ${cOWDay1Name} ${cOWDay1}원`;
	const cOWDay2SumString = numberToCommaString(numCOWDay2Sum);
	const cOWDay2SumComment = `근무일 ${workingDays}일 * ${cOWDay2Name} ${cOWDay2}원`;
	const cOWDay3SumString = numberToCommaString(numCOWDay3Sum);
	const cOWDay3SumComment = `근무일 ${workingDays}일 * ${cOWDay3Name} ${cOWDay3}원`;
	// --------

	function zeroInformHandler() {
		alert(
			'수당이 "0원"인 경우 반드시 "0"을 입력해야 정확한 계산이 됩니다. 주의하세요.'
		);
	}

	const absentDayWageTextValue = absentDayWageText(
		monthOrdinaryWage,
		daysInMonth,
		absentDays,
		isShortTimeRegularWorker,
		ordinaryDayWorkingTime,
		hourOrdinaryWage
	);

	const absentDaysLawOverWageTextValue = absentDaysLawOverWageText(
		sumOfLawOverWage,
		daysInMonth,
		absentDays,
		isShortTimeRegularWorker,
		workingDaysInWeek
	);

	const choDan = (
		<>
			<Table.Row textAlign='center'>
				{monthBasicWage !== undefined ? (
					<ClientReqDLCWageTableDetailBasic
						isExceptTimeLawWorker={isExceptTimeLawWorker}
						monthBasicWage={numberToCommaString(numBasicWage)}
						weekLBTime={weekLBTime}
						gumakStyle={gumakStyle}
						monthBasicWageComment={monthBasicWageComment}
					/>
				) : null}
			</Table.Row>
			<Table.Row textAlign='center'>
				{cTFMealWage !== undefined ? (
					<ClientReqDLCWageTableDetailMeal
						isExceptTimeLawWorker={isExceptTimeLawWorker}
						cTFMealWage={cTFMealWage}
						weekLBTime={weekLBTime}
						gumakStyle={gumakStyle}
						cTFMealWageComment={cTFMealWageComment}
					/>
				) : null}
			</Table.Row>
			<Table.Row textAlign='center'>
				{cTFCarWage !== undefined ? (
					<ClientReqDLCWageTableDetailCar
						isExceptTimeLawWorker={isExceptTimeLawWorker}
						weekLBTime={weekLBTime}
						gumakStyle={gumakStyle}
						cTFCarWage={cTFCarWage}
						cTFCarWageComment={cTFCarWageComment}
					/>
				) : null}
			</Table.Row>
			<Table.Row textAlign='center'>
				{cTFChildWage !== undefined ? (
					<ClientReqDLCWageTableDetailChild
						isExceptTimeLawWorker={isExceptTimeLawWorker}
						weekLBTime={weekLBTime}
						gumakStyle={gumakStyle}
						cTFChildWage={cTFChildWage}
						cTFChildWageComment={cTFChildWageComment}
					/>
				) : null}
			</Table.Row>
			<Table.Row textAlign='center'>
				{cTFStudyWage !== undefined ? (
					<ClientReqDLCWageTableDetailStudy
						isExceptTimeLawWorker={isExceptTimeLawWorker}
						weekLBTime={weekLBTime}
						gumakStyle={gumakStyle}
						cTFStudyWage={cTFStudyWage}
						cTFStudyWageComment={cTFStudyWageComment}
					/>
				) : null}
			</Table.Row>
			<Table.Row textAlign='center'>
				{cTFEtc !== undefined ? (
					<ClientReqDLCWageTableDetailTFEtc
						isExceptTimeLawWorker={isExceptTimeLawWorker}
						weekLBTime={weekLBTime}
						gumakStyle={gumakStyle}
						cTFEtc={cTFEtc}
						cTFEtcComment={cTFEtcComment}
					/>
				) : null}
			</Table.Row>
			<Table.Row textAlign='center'>
				{cOWTitle !== undefined ? (
					<ClientReqDLCWageTableDetailTitle
						isExceptTimeLawWorker={isExceptTimeLawWorker}
						weekLBTime={weekLBTime}
						gumakStyle={gumakStyle}
						cOWTitle={cOWTitle}
						cOWTitleComment={cOWTitleComment}
						companyName={selectedClientReq4In?.companyName}
					/>
				) : null}
			</Table.Row>
			<Table.Row textAlign='center'>
				{cOWJob !== undefined ? (
					<ClientReqDLCWageTableDetailJob
						isExceptTimeLawWorker={isExceptTimeLawWorker}
						weekLBTime={weekLBTime}
						gumakStyle={gumakStyle}
						cOWJob={cOWJob}
						cOWJobComment={cOWJobComment}
					/>
				) : null}
			</Table.Row>
			<Table.Row textAlign='center'>
				{cOWLongTerm !== undefined ? (
					<ClientReqDLCWageTableDetailLongTerm
						isExceptTimeLawWorker={isExceptTimeLawWorker}
						weekLBTime={weekLBTime}
						gumakStyle={gumakStyle}
						cOWLongTerm={cOWLongTerm}
						cOWLongTermComment={cOWLongTermComment}
					/>
				) : null}
			</Table.Row>

			<Table.Row textAlign='center'>
				{cOWLicense !== undefined ? (
					<ClientReqDLCWageTableDetailLicense
						isExceptTimeLawWorker={isExceptTimeLawWorker}
						weekLBTime={weekLBTime}
						gumakStyle={gumakStyle}
						cOWLicense={cOWLicense}
						cOWLicenseComment={cOWLicenseComment}
					/>
				) : null}
			</Table.Row>
			<Table.Row textAlign='center'>
				{cOWMonthlyBonus !== undefined ? (
					<ClientReqDLCWageTableDetailMonthlyBonus
						isExceptTimeLawWorker={isExceptTimeLawWorker}
						weekLBTime={weekLBTime}
						gumakStyle={gumakStyle}
						cOWMonthlyBonus={cOWMonthlyBonus}
						cOWMonthlyBonusComment={cOWMonthlyBonusComment}
					/>
				) : null}
			</Table.Row>
			<Table.Row textAlign='center'>
				{cOWEtc !== undefined ? (
					<ClientReqDLCWageTableDetailMonthlyEtc
						isExceptTimeLawWorker={isExceptTimeLawWorker}
						weekLBTime={weekLBTime}
						gumakStyle={gumakStyle}
						cOWEtc={cOWEtc}
						cOWEtcComment={cOWEtcComment}
					/>
				) : null}
			</Table.Row>
			<Table.Row textAlign='center'>
				{cOWChildManageStudyWage !== undefined &&
				cOWChildManageStudyWage !== '' ? (
					<ClientReqDLCWageTableDetailChildManageStudy
						isExceptTimeLawWorker={isExceptTimeLawWorker}
						weekLBTime={weekLBTime}
						gumakStyle={gumakStyle}
						cOWChildManageStudyWage={cOWChildManageStudyWage}
						cOWChildManageStudyWageComment={cOWChildManageStudyWageComment}
					/>
				) : null}
			</Table.Row>
			<Table.Row textAlign='center'>
				{cOWChildStudyWage !== undefined && cOWChildStudyWage !== '' ? (
					<ClientReqDLCWageTableDetailChildStudy
						isExceptTimeLawWorker={isExceptTimeLawWorker}
						weekLBTime={weekLBTime}
						gumakStyle={gumakStyle}
						cOWChildStudyWage={cOWChildStudyWage}
						cOWChildStudyWageComment={cOWChildStudyWageComment}
					/>
				) : null}
			</Table.Row>
			<Table.Row textAlign='center'>
				{cOWChildCareWage !== undefined && cOWChildCareWage !== '' ? (
					<ClientReqDLCWageTableDetailChildCare
						isExceptTimeLawWorker={isExceptTimeLawWorker}
						weekLBTime={weekLBTime}
						gumakStyle={gumakStyle}
						cOWChildCareWage={cOWChildCareWage}
						cOWChildCareWageComment={cOWChildCareWageComment}
					/>
				) : null}
			</Table.Row>
			<Table.Row textAlign='center'>
				{cOWChildActivityWage !== undefined && cOWChildActivityWage !== '' ? (
					<ClientReqDLCWageTableDetailChildActivity
						isExceptTimeLawWorker={isExceptTimeLawWorker}
						weekLBTime={weekLBTime}
						gumakStyle={gumakStyle}
						cOWChildActivityWage={cOWChildActivityWage}
						cOWChildActivityWageComment={cOWChildActivityWageComment}
					/>
				) : null}
			</Table.Row>

			{/* 12월3일-공통 */}
			<Table.Row textAlign='center'>
				{daysOfInterInAbusentOrdinaryWage ? (
					<>
						<Table.Cell className='gubun'>통상임금</Table.Cell>
						<Table.Cell className='hangmok'>중도입사공제</Table.Cell>
						<Table.Cell className='gumak' style={gumakStyle}>
							<span style={{ color: 'red' }}>
								-{numberToCommaString(daysOfInterInAbusentOrdinaryWage)}
							</span>
						</Table.Cell>
						<Table.Cell textAlign='left' colSpan='6' className='sulmung'>
							{daysOfInterInAbusentOrdinaryWageText(
								monthOrdinaryWage,
								daysInMonth,
								daysOfInterInAbusent
							)}
						</Table.Cell>
					</>
				) : null}
			</Table.Row>
			<Table.Row textAlign='center'>
				{daysOfInterOutAbusentOrdinaryWage ? (
					<>
						<Table.Cell className='gubun'>통상임금</Table.Cell>
						<Table.Cell className='hangmok'>중도퇴사공제</Table.Cell>
						<Table.Cell className='gumak' style={gumakStyle}>
							<span style={{ color: 'red' }}>
								-{numberToCommaString(daysOfInterOutAbusentOrdinaryWage)}
							</span>
						</Table.Cell>
						<Table.Cell textAlign='left' colSpan='6' className='sulmung'>
							{daysOfInterOutAbusentOrdinaryWageText(
								monthOrdinaryWage,
								daysInMonth,
								daysOfInterOutAbusent
							)}
						</Table.Cell>
					</>
				) : null}
			</Table.Row>
			<Table.Row textAlign='center'>
				{absentDaysOrdinaryWage ? (
					<>
						<Table.Cell className='gubun'>통상임금</Table.Cell>
						<Table.Cell className='hangmok'>결근공제</Table.Cell>
						<Table.Cell className='gumak' style={gumakStyle}>
							<span style={{ color: 'red' }}>
								-{numberToCommaString(absentDaysOrdinaryWage)}
							</span>
						</Table.Cell>
						<Table.Cell textAlign='left' colSpan='6' className='sulmung'>
							{absentDayWageTextValue}
						</Table.Cell>
					</>
				) : null}
			</Table.Row>
			<Table.Row textAlign='center'>
				{absentPaidHoliDaysOrdinaryWage ? (
					<>
						<Table.Cell className='gubun'>통상임금</Table.Cell>
						<Table.Cell className='hangmok'>주휴공제</Table.Cell>
						<Table.Cell className='gumak' style={gumakStyle}>
							<span style={{ color: 'red' }}>
								-{numberToCommaString(absentPaidHoliDaysOrdinaryWage)}
							</span>
						</Table.Cell>
						<Table.Cell
							textAlign='left'
							colSpan='6'
							className='sulmung'>{`1주 소정근로 ${numberToCommaString(
							paidHolidayLBTime
						)}시간 * 시간급 통상임금 ${numberToCommaString(
							hourOrdinaryWage
						)}원 * 주휴 공제 일수 ${absentPaidHoliDays}일`}</Table.Cell>
					</>
				) : null}
			</Table.Row>
			<Table.Row textAlign='center'>
				{absentTimeWage ? (
					<>
						<Table.Cell className='gubun'>통상임금</Table.Cell>
						<Table.Cell className='hangmok'>
							지각 등 <br /> 시간공제
						</Table.Cell>
						<Table.Cell className='gumak' style={gumakStyle}>
							<span style={{ color: 'red' }}>
								-{numberToCommaString(absentTimeWage)}
							</span>
						</Table.Cell>
						<Table.Cell
							textAlign='left'
							colSpan='6'
							className='sulmung'>{`지각, 조퇴, 외출 등 공제 ${numberToCommaString(
							numAbsentTimes
						)}시간 * 시급 ${numberToCommaString(
							hourOrdinaryWage
						)} `}</Table.Cell>
					</>
				) : null}
			</Table.Row>
			<Table.Row textAlign='center'>
				{maternityFinalDedectWage[0] ? (
					<>
						<Table.Cell className='gubun'>통상임금</Table.Cell>
						<Table.Cell className='hangmok'>
							출산전후휴가 <br />
							공제
						</Table.Cell>
						<Table.Cell className='gumak' style={gumakStyle}>
							<span style={{ color: 'red' }}>
								-{numberToCommaString(roundUp(maternityFinalDedectWage[0]))}
							</span>
						</Table.Cell>
						<Table.Cell textAlign='left' colSpan='6' className='sulmung'>
							{`출산전후 휴가 통상임금 공제분(회사에서 지급한 유급임금 : ${numberToCommaString(
								roundUp(maternityFinalDedectWage[8])
							)}원보다 많이 공제된 경우는 일할계산임) `}
						</Table.Cell>
					</>
				) : null}
			</Table.Row>
			{/* --------- */}
			{/* 11월24일-공통 */}
			<Table.Row textAlign='center'>
				{cOWDay1 ? (
					<>
						<Table.Cell className='gubun'>
							통상임금 <br />
							(일급기준){' '}
							{weekLBTime < 15 ? null : (
								<>
									{' '}
									<br /> (주휴포함)
								</>
							)}
						</Table.Cell>
						<Table.Cell className='hangmok'>{cOWDay1Name}</Table.Cell>
						<Table.Cell className='gumak' style={gumakStyle}>
							{cOWDay1SumString}
						</Table.Cell>
						<Table.Cell textAlign='left' colSpan='6' className='sulmung'>
							{cOWDay1SumComment}
						</Table.Cell>
					</>
				) : null}
			</Table.Row>
			<Table.Row textAlign='center'>
				{cOWDay2 ? (
					<>
						<Table.Cell className='gubun'>
							통상임금 <br />
							(일급기준){' '}
							{weekLBTime < 15 ? null : (
								<>
									{' '}
									<br /> (주휴포함)
								</>
							)}
						</Table.Cell>
						<Table.Cell className='hangmok'>{cOWDay2Name}</Table.Cell>
						<Table.Cell className='gumak' style={gumakStyle}>
							{cOWDay2SumString}
						</Table.Cell>
						<Table.Cell textAlign='left' colSpan='6' className='sulmung'>
							{cOWDay2SumComment}
						</Table.Cell>
					</>
				) : null}
			</Table.Row>
			<Table.Row textAlign='center'>
				{cOWDay3 ? (
					<>
						<Table.Cell className='gubun'>
							통상임금 <br />
							(일급기준){' '}
							{weekLBTime < 15 ? null : (
								<>
									{' '}
									<br /> (주휴포함)
								</>
							)}
						</Table.Cell>
						<Table.Cell className='hangmok'>{cOWDay3Name}</Table.Cell>
						<Table.Cell className='gumak' style={gumakStyle}>
							{cOWDay3SumString}
						</Table.Cell>
						<Table.Cell textAlign='left' colSpan='6' className='sulmung'>
							{cOWDay3SumComment}
						</Table.Cell>
					</>
				) : null}
			</Table.Row>
			{/* ---------- */}
			<Table.Row textAlign='center'>
				{(monthOWT || commaStringToNumber(plusMonthOWT)) > 0 ? (
					<>
						<Table.Cell className='gubun'>평균임금</Table.Cell>
						<Table.Cell className='hangmok'>
							{numOfWorkers >= 5 ? '시간외수당' : '초과수당'}
						</Table.Cell>
						<Table.Cell className='gumak' style={gumakStyle}>
							{numberToCommaString(numOWage)}
						</Table.Cell>
						<Table.Cell textAlign='left' colSpan='6' className='sulmung'>
							{overTimeText(
								monthOWT,
								commaStringToNumber(plusMonthOWT) || 0,
								hourOrdinaryWage,
								numOfWorkers,
								isExceptTimeLawWorker
							)}
						</Table.Cell>
					</>
				) : null}
			</Table.Row>
			<Table.Row textAlign='center'>
				{(monthNWT || commaStringToNumber(plusMonthNWT)) > 0 &&
				numOfWorkers >= 5 ? (
					<>
						<Table.Cell className='gubun'>평균임금</Table.Cell>
						<Table.Cell className='hangmok'>야간수당</Table.Cell>
						<Table.Cell className='gumak' style={gumakStyle}>
							{numberToCommaString(numNWage)}
						</Table.Cell>
						<Table.Cell textAlign='left' colSpan='6' className='sulmung'>
							{nightTimeText(
								commaStringToNumber(monthNWT),
								commaStringToNumber(plusMonthNWT) || 0,
								hourOrdinaryWage,
								numOfWorkers
							)}
						</Table.Cell>
					</>
				) : null}
			</Table.Row>
			<Table.Row textAlign='center'>
				{(monthHoliBasicTime || commaStringToNumber(plusMonthHoliBasicTime)) >
				0 ? (
					<>
						<Table.Cell className='gubun'>평균임금</Table.Cell>
						<Table.Cell className='hangmok'>휴일 기본수당</Table.Cell>
						<Table.Cell className='gumak' style={gumakStyle}>
							{numberToCommaString(numHBwage)}
						</Table.Cell>
						<Table.Cell textAlign='left' colSpan='6' className='sulmung'>
							{holiBasicTimeText(
								commaStringToNumber(monthHoliBasicTime),
								commaStringToNumber(plusMonthHoliBasicTime) || 0,
								hourOrdinaryWage,
								numOfWorkers
							)}
						</Table.Cell>
					</>
				) : null}
			</Table.Row>
			<Table.Row textAlign='center'>
				{(monthOWTHoli || commaStringToNumber(plusMonthOWTHoli)) > 0 ? (
					<>
						<Table.Cell className='gubun'>평균임금</Table.Cell>
						<Table.Cell className='hangmok'>휴일 연장수당</Table.Cell>
						<Table.Cell className='gumak' style={gumakStyle}>
							{numberToCommaString(numHOwage)}
						</Table.Cell>
						<Table.Cell textAlign='left' colSpan='6' className='sulmung'>
							{holiOverTimeText(
								commaStringToNumber(monthOWTHoli),
								commaStringToNumber(plusMonthOWTHoli) || 0,
								hourOrdinaryWage,
								numOfWorkers
							)}
						</Table.Cell>
					</>
				) : null}
			</Table.Row>
			<Table.Row textAlign='center'>
				{(monthNWTHoli || commaStringToNumber(plusMonthNWTHoli)) > 0 ? (
					<>
						<Table.Cell className='gubun'>평균임금</Table.Cell>
						<Table.Cell className='hangmok'>휴일 야간수당</Table.Cell>
						<Table.Cell className='gumak' style={gumakStyle}>
							{numberToCommaString(numHNwage)}
						</Table.Cell>
						<Table.Cell textAlign='left' colSpan='6' className='sulmung'>
							{holiNightTimeText(
								commaStringToNumber(monthNWTHoli),
								commaStringToNumber(plusMonthNWTHoli) || 0,
								hourOrdinaryWage,
								numOfWorkers
							)}
						</Table.Cell>
					</>
				) : null}
			</Table.Row>
			<Table.Row textAlign='center'>
				{commaStringToNumber(annualLeavesIncludeMonthWage) ? (
					<>
						<Table.Cell className='gubun'>평균임금</Table.Cell>
						<Table.Cell className='hangmok'>유급연차수당</Table.Cell>
						<Table.Cell className='gumak' style={gumakStyle}>
							{numberToCommaString(Math.ceil(annualLeavesIncludeMonthWage)) ||
								0}
						</Table.Cell>
						<Table.Cell textAlign='left' colSpan='6' className='sulmung'>
							년간 15일의 유급연차휴가수당을 12개월로 나눈 1달분의 연차수당
						</Table.Cell>
					</>
				) : null}
			</Table.Row>
			<Table.Row textAlign='center'>
				{daysOfInterInAbusentLawOverWage && numOfWorkers >= 5 ? (
					<>
						<Table.Cell className='gubun'>평균임금</Table.Cell>
						<Table.Cell className='hangmok'>중도입사공제</Table.Cell>
						<Table.Cell className='gumak' style={gumakStyle}>
							<span style={{ color: 'red' }}>
								-{numberToCommaString(daysOfInterInAbusentLawOverWage)}
							</span>
						</Table.Cell>
						<Table.Cell textAlign='left' colSpan='6' className='sulmung'>
							{daysOfInterInAbusentLawOverWageText(
								sumOfLawOverWage,
								daysInMonth,
								daysOfInterInAbusent
							)}
						</Table.Cell>
					</>
				) : null}
			</Table.Row>
			<Table.Row textAlign='center'>
				{daysOfInterOutAbusentLawOverWage && numOfWorkers >= 5 ? (
					<>
						<Table.Cell className='gubun'>평균임금</Table.Cell>
						<Table.Cell className='hangmok'>중도퇴사공제</Table.Cell>
						<Table.Cell className='gumak' style={gumakStyle}>
							<span style={{ color: 'red' }}>
								-{numberToCommaString(daysOfInterOutAbusentLawOverWage)}
							</span>
						</Table.Cell>
						<Table.Cell textAlign='left' colSpan='6' className='sulmung'>
							{daysOfInterOutAbusentLawOverWageText(
								sumOfLawOverWage,
								daysInMonth,
								daysOfInterOutAbusent
							)}
						</Table.Cell>
					</>
				) : null}
			</Table.Row>
			<Table.Row textAlign='center'>
				{absentDaysLawOverWage && numOfWorkers >= 5 ? (
					<>
						<Table.Cell className='gubun'>평균임금</Table.Cell>
						<Table.Cell className='hangmok'>결근공제</Table.Cell>
						<Table.Cell className='gumak' style={gumakStyle}>
							<span style={{ color: 'red' }}>
								-{numberToCommaString(absentDaysLawOverWage)}
							</span>
						</Table.Cell>
						<Table.Cell textAlign='left' colSpan='6' className='sulmung'>
							{absentDaysLawOverWageTextValue}
						</Table.Cell>
					</>
				) : null}
			</Table.Row>
			<Table.Row textAlign='center'>
				{maternityFinalDedectWage[1] && numOfWorkers >= 5 ? (
					<>
						<Table.Cell className='gubun'>평균임금</Table.Cell>
						<Table.Cell className='hangmok'>
							출산전후휴가 <br /> 약정법정수당공제
						</Table.Cell>
						<Table.Cell className='gumak' style={gumakStyle}>
							<span style={{ color: 'red' }}>
								-{numberToCommaString(roundDown(maternityFinalDedectWage[1]))}
							</span>
						</Table.Cell>
						<Table.Cell textAlign='left' colSpan='6' className='sulmung'>
							출산전후 휴가기간 중 법정 가산임금 일할 공제
						</Table.Cell>
					</>
				) : null}
			</Table.Row>
			<Table.Row textAlign='center'>
				{+plusIrregularDayMealTFDays > 0 ? (
					<>
						<Table.Cell className='gubun'>평균임금</Table.Cell>
						<Table.Cell className='hangmok'>출근식대</Table.Cell>
						<Table.Cell className='gumak' style={gumakStyle}>
							{numberToCommaString(+plusIrregularDayMealTFWage)}
						</Table.Cell>
						<Table.Cell textAlign='left' colSpan='6' className='sulmung'>
							{plusIrregularDayMealTFDays +
								'일 * ' +
								numberToCommaString(perDayMealTaxFree) +
								'원(출근일 소정근로시간의 80% 이상 근무시 해당일에 지급하는 식대-비과세 한도)'}
						</Table.Cell>
					</>
				) : null}
			</Table.Row>

			{/* 평균임금 비과세 */}
			<Table.Row textAlign='center'>
				{cAWageTF1 !== null ? (
					<>
						<Table.Cell className='gubun'>
							월정액
							<br />
							평균임금
						</Table.Cell>
						<Table.Cell className='hangmok'>
							<input
								autoComplete='off'
								style={{ width: '100%', textAlign: 'center' }}
								name='userFixedCAWageTF1Name'
								defaultValue={cAWageTF1Name}
								onChange={(e) => handelInputChange(e)}
							/>
						</Table.Cell>
						<Table.Cell className='gumak' style={gumakStyle}>
							<input
								autoComplete='off'
								style={inputWageStyle}
								name='userFixedCAWageTF1'
								defaultValue={numberToCommaString(
									roundUp(numUserFixedCAWageTF1)
								)}
								onClick={() => zeroInformHandler()}
								onChange={(e) => handelInputChange(e)}
							/>
						</Table.Cell>
						<Table.Cell textAlign='left' colSpan='6' className='sulmung'>
							<input
								autoComplete='off'
								style={{ width: '100%' }}
								name='userFixedCAWageTF1Comment'
								defaultValue={cAWageTF1Comment}
								onChange={(e) => handelInputChange(e)}
							/>
						</Table.Cell>
					</>
				) : null}
			</Table.Row>

			<Table.Row textAlign='center'>
				{cAWageTF2 !== null ? (
					<>
						<Table.Cell className='gubun'>
							월정액
							<br />
							평균임금
						</Table.Cell>
						<Table.Cell className='hangmok'>
							<input
								style={{ width: '100%' }}
								name='userFixedCAWageTF1Name'
								defaultValue={cAWageTF2Name}
								autoComplete='off'
								onChange={(e) => handelInputChange(e)}
							/>
						</Table.Cell>
						<Table.Cell className='gumak' style={gumakStyle}>
							<input
								style={inputWageStyle}
								name='userFixedCAWageTF2'
								defaultValue={numberToCommaString(
									roundUp(numUserFixedCAWageTF2)
								)}
								onClick={() => zeroInformHandler()}
								autoComplete='off'
								onChange={(e) => handelInputChange(e)}
							/>
						</Table.Cell>
						<Table.Cell textAlign='left' colSpan='6' className='sulmung'>
							<input
								style={{ width: '100%' }}
								name='userFixedCAWageTF2Comment'
								defaultValue={cAWageTF2Comment}
								autoComplete='off'
								onChange={(e) => handelInputChange(e)}
							/>
						</Table.Cell>
					</>
				) : null}
			</Table.Row>

			<Table.Row textAlign='center'>
				{cAWageTF3 !== null ? (
					<>
						<Table.Cell className='gubun'>
							월정액
							<br />
							평균임금
						</Table.Cell>
						<Table.Cell className='hangmok'>
							<input
								style={{ width: '100%' }}
								name='userFixedCAWageTF3Name'
								defaultValue={cAWageTF3Name}
								autoComplete='off'
								onChange={(e) => handelInputChange(e)}
							/>
						</Table.Cell>
						<Table.Cell className='gumak' style={gumakStyle}>
							<input
								style={inputWageStyle}
								name='userFixedCAWageTF3'
								defaultValue={numberToCommaString(
									roundUp(numUserFixedCAWageTF3)
								)}
								onClick={() => zeroInformHandler()}
								autoComplete='off'
								onChange={(e) => handelInputChange(e)}
							/>
						</Table.Cell>
						<Table.Cell textAlign='left' colSpan='6' className='sulmung'>
							<input
								style={{ width: '100%' }}
								name='userFixedCAWageTF3Comment'
								defaultValue={cAWageTF3Comment}
								autoComplete='off'
								onChange={(e) => handelInputChange(e)}
							/>
						</Table.Cell>
					</>
				) : null}
			</Table.Row>
			<Table.Row textAlign='center'>
				{cAWageTF4 !== null ? (
					<>
						<Table.Cell className='gubun'>
							월정액
							<br />
							평균임금
						</Table.Cell>
						<Table.Cell className='hangmok'>
							<input
								style={{ width: '100%' }}
								name='userFixedCAWageTF4Name'
								defaultValue={cAWageTF4Name}
								autoComplete='off'
								onChange={(e) => handelInputChange(e)}
							/>
						</Table.Cell>
						<Table.Cell className='gumak' style={gumakStyle}>
							<input
								style={inputWageStyle}
								name='userFixedCAWageTF4'
								autoComplete='off'
								defaultValue={numberToCommaString(
									roundUp(numUserFixedCAWageTF4)
								)}
								onClick={() => zeroInformHandler()}
								onChange={(e) => handelInputChange(e)}
							/>
						</Table.Cell>
						<Table.Cell textAlign='left' colSpan='6' className='sulmung'>
							<input
								style={{ width: '100%' }}
								name='userFixedCAWageTF4Comment'
								defaultValue={cAWageTF4Comment}
								autoComplete='off'
								onChange={(e) => handelInputChange(e)}
							/>
						</Table.Cell>
					</>
				) : null}
			</Table.Row>

			{/* 평균임금 과세 */}
			<Table.Row textAlign='center'>
				{cAWage1 !== null ? (
					<>
						<Table.Cell className='gubun'>
							월정액
							<br />
							평균임금
						</Table.Cell>
						<Table.Cell className='hangmok'>
							<input
								autoComplete='off'
								style={{ width: '100%', textAlign: 'center' }}
								name='userFixedCAWage1Name'
								defaultValue={cAWage1Name}
								onChange={(e) => handelInputChange(e)}
							/>
						</Table.Cell>
						<Table.Cell className='gumak' style={gumakStyle}>
							<input
								autoComplete='off'
								style={inputWageStyle}
								name='userFixedCAWage1'
								defaultValue={numberToCommaString(roundUp(numUserFixedCAWage1))}
								onClick={() => zeroInformHandler()}
								onChange={(e) => handelInputChange(e)}
							/>
						</Table.Cell>
						<Table.Cell textAlign='left' colSpan='6' className='sulmung'>
							<input
								autoComplete='off'
								style={{ width: '100%' }}
								name='userFixedCAWage1Comment'
								defaultValue={cAWage1Comment}
								onChange={(e) => handelInputChange(e)}
							/>
						</Table.Cell>
					</>
				) : null}
			</Table.Row>

			<Table.Row textAlign='center'>
				{cAWage2 !== null ? (
					<>
						<Table.Cell className='gubun'>
							월정액
							<br />
							평균임금
						</Table.Cell>
						<Table.Cell className='hangmok'>
							<input
								style={{ width: '100%' }}
								name='userFixedCAWage2Name'
								defaultValue={cAWage2Name}
								autoComplete='off'
								onChange={(e) => handelInputChange(e)}
							/>
						</Table.Cell>
						<Table.Cell className='gumak' style={gumakStyle}>
							<input
								style={inputWageStyle}
								name='userFixedCAWage2'
								defaultValue={numberToCommaString(roundUp(numUserFixedCAWage2))}
								onClick={() => zeroInformHandler()}
								autoComplete='off'
								onChange={(e) => handelInputChange(e)}
							/>
						</Table.Cell>
						<Table.Cell textAlign='left' colSpan='6' className='sulmung'>
							<input
								style={{ width: '100%' }}
								name='userFixedCAWage2Comment'
								defaultValue={cAWage2Comment}
								autoComplete='off'
								onChange={(e) => handelInputChange(e)}
							/>
						</Table.Cell>
					</>
				) : null}
			</Table.Row>
			<Table.Row textAlign='center'>
				{cAWage3 !== null ? (
					<>
						<Table.Cell className='gubun'>
							월정액
							<br />
							평균임금
						</Table.Cell>
						<Table.Cell className='hangmok'>
							<input
								style={{ width: '100%' }}
								name='userFixedCAWage3Name'
								defaultValue={cAWage3Name}
								autoComplete='off'
								onChange={(e) => handelInputChange(e)}
							/>
						</Table.Cell>
						<Table.Cell className='gumak' style={gumakStyle}>
							<input
								style={inputWageStyle}
								name='userFixedCAWage3'
								defaultValue={numberToCommaString(roundUp(numUserFixedCAWage3))}
								onClick={() => zeroInformHandler()}
								autoComplete='off'
								onChange={(e) => handelInputChange(e)}
							/>
						</Table.Cell>
						<Table.Cell textAlign='left' colSpan='6' className='sulmung'>
							<input
								style={{ width: '100%' }}
								name='userFixedCAWage3Comment'
								defaultValue={cAWage3Comment}
								autoComplete='off'
								onChange={(e) => handelInputChange(e)}
							/>
						</Table.Cell>
					</>
				) : null}
			</Table.Row>
			<Table.Row textAlign='center'>
				{cAWage4 !== null ? (
					<>
						<Table.Cell className='gubun'>
							월정액
							<br />
							평균임금
						</Table.Cell>
						<Table.Cell className='hangmok'>
							<input
								style={{ width: '100%' }}
								name='userFixedCAWage4Name'
								defaultValue={cAWage4Name}
								autoComplete='off'
								onChange={(e) => handelInputChange(e)}
							/>
						</Table.Cell>
						<Table.Cell className='gumak' style={gumakStyle}>
							<input
								style={inputWageStyle}
								name='userFixedCAWage4'
								autoComplete='off'
								defaultValue={numberToCommaString(roundUp(numUserFixedCAWage4))}
								onClick={() => zeroInformHandler()}
								onChange={(e) => handelInputChange(e)}
							/>
						</Table.Cell>
						<Table.Cell textAlign='left' colSpan='6' className='sulmung'>
							<input
								style={{ width: '100%' }}
								name='userFixedCAWage4Comment'
								defaultValue={cAWage4Comment}
								autoComplete='off'
								onChange={(e) => handelInputChange(e)}
							/>
						</Table.Cell>
					</>
				) : null}
			</Table.Row>
			<Table.Row textAlign='center'>
				{commaStringToNumber(plusRegularBonus) > 0 ? (
					<>
						<Table.Cell className='gubun'>평균임금</Table.Cell>
						<Table.Cell className='hangmok'>상여금</Table.Cell>
						<Table.Cell className='gumak' style={gumakStyle}>
							{plusRegularBonus}
						</Table.Cell>
						<Table.Cell textAlign='left' colSpan='6' className='sulmung'>
							{plusRegularBonusComment}
						</Table.Cell>
					</>
				) : null}
			</Table.Row>
			<Table.Row textAlign='center'>
				{commaStringToNumber(plusIrregularBonus) > 0 ? (
					<>
						<Table.Cell className='gubun'>평균임금</Table.Cell>
						<Table.Cell className='hangmok'>비정기상여금</Table.Cell>
						<Table.Cell className='gumak' style={gumakStyle}>
							{plusIrregularBonus}
						</Table.Cell>
						<Table.Cell textAlign='left' colSpan='6' className='sulmung'>
							{plusIrregularBonusComment}
						</Table.Cell>
					</>
				) : null}
			</Table.Row>

			<Table.Row textAlign='center'>
				{commaStringToNumber(plusUnusedAnnualLeavesDays) > 0 ? (
					<>
						<Table.Cell className='gubun'>평균임금</Table.Cell>
						<Table.Cell className='hangmok'>
							년간미사용 <br /> 연차수당
						</Table.Cell>

						<Table.Cell className='gumak' style={gumakStyle}>
							{numberToCommaString(numPlusUnusedAnnualLeavesWage)}
						</Table.Cell>
						<Table.Cell textAlign='left' colSpan='6' className='sulmung'>
							미사용 연차 {plusUnusedAnnualLeavesDays}일 * 1일 소정근로(
							{paidHolidayLBTime})시간 * 통상시급 {hourOrdinaryWage}원
						</Table.Cell>
					</>
				) : null}
			</Table.Row>
			<Table.Row textAlign='center'>
				{commaStringToNumber(plusPersonalIncentive) > 0 ? (
					<>
						<Table.Cell className='gubun'>평균임금</Table.Cell>
						<Table.Cell className='hangmok'>개인 실적인센티브</Table.Cell>
						<Table.Cell className='gumak' style={gumakStyle}>
							{plusPersonalIncentive}
						</Table.Cell>
						<Table.Cell textAlign='left' colSpan='6' className='sulmung'>
							{plusPersonalIncentiveComment}
						</Table.Cell>
					</>
				) : null}
			</Table.Row>
			<Table.Row textAlign='center'>
				{commaStringToNumber(plusLastMonthAverWage) ? (
					<>
						<Table.Cell className='gubun'>평균임금</Table.Cell>
						<Table.Cell className='hangmok'>
							급여 조정
							<br />
							(과오납 등)
						</Table.Cell>
						<Table.Cell className='gumak' style={gumakStyle}>
							{plusLastMonthAverWage}
						</Table.Cell>
						<Table.Cell textAlign='left' colSpan='6' className='sulmung'>
							{plusLastMonthAverWageComment}
						</Table.Cell>
					</>
				) : null}
			</Table.Row>

			<ClientReqDLCWageTableDetailAWage5
				gumakStyle={gumakStyle}
				inputWageStyle={inputWageStyle}
				inputCommentStyle={inputCommentStyle}
				cAWage5={cAWage5}
				cAWEName5={cAWEName5}
				cAWage5Comment={cAWage5Comment}
				handelInputChange={handelInputChange}
			/>
			<ClientReqDLCWageTableDetailAWage6
				gumakStyle={gumakStyle}
				inputWageStyle={inputWageStyle}
				inputCommentStyle={inputCommentStyle}
				cAWage6={cAWage6}
				cAWEName6={cAWEName6}
				cAWage6Comment={cAWage6Comment}
				handelInputChange={handelInputChange}
			/>
			<ClientReqDLCWageTableDetailAWage7
				gumakStyle={gumakStyle}
				inputWageStyle={inputWageStyle}
				inputCommentStyle={inputCommentStyle}
				cAWage7={cAWage7}
				cAWEName7={cAWEName7}
				cAWage7Comment={cAWage7Comment}
				handelInputChange={handelInputChange}
			/>
			{commaStringToNumber(plusSpecialRandomWage) > 0 ? (
				<Table.Row textAlign='center'>
					<Table.Cell className='gubun'>기타금품</Table.Cell>
					<Table.Cell className='hangmok'>
						특별상여금 <br /> PS 등
					</Table.Cell>
					<Table.Cell className='gumak' style={gumakStyle}>
						{plusSpecialRandomWage}
					</Table.Cell>
					<Table.Cell
						textAlign='left'
						colSpan='6'
						className='sulmung'>{`근로제공과 관련없는 경영성과급, 특별상여금, Profit Sharing, 기타 은혜적 금품(평균임금에서 제외)`}</Table.Cell>
				</Table.Row>
			) : null}
			<Table.Row textAlign='center'>
				{commaStringToNumber(plusRetiredUnusedAnnualLeavesWage) > 0 ? (
					<>
						<Table.Cell className='gubun'>기타금품</Table.Cell>
						<Table.Cell className='hangmok'>
							퇴직미사용 <br /> 연차수당
						</Table.Cell>
						<Table.Cell className='gumak' style={gumakStyle}>
							{numberToCommaString(numPlusRetiredUnusedAnnualLeavesWage)}
						</Table.Cell>
						<Table.Cell
							textAlign='left'
							colSpan='6'
							className='sulmung'>{`퇴직년도에 발생한 연차 중 퇴직일까지 사용하지 못한 연차 ${plusRetiredUnusedAnnualLeavesWage}일 * 1일 소정근로시간 (${paidHolidayLBTime})시간 * '통상시급 ${hourOrdinaryWage}원(평균임금에서 제외)`}</Table.Cell>
					</>
				) : null}
			</Table.Row>
		</>
	);

	return (
		<React.Fragment>
			<Table.Row textAlign='center'>
				<Table.Cell colSpan='9'>{` `}</Table.Cell>
			</Table.Row>
			<Table.Row textAlign='left'>
				<Table.Cell colSpan='9'>
					<span
						style={{
							fontSize: '1.2rem',
							color: 'darkcyan',
							fontWeight: 'bold',
						}}>
						4. 월급 구성항목 및 계산방법
					</span>
				</Table.Cell>
			</Table.Row>
			<Table.Row textAlign='center'>
				<Table.Cell className='gubun' style={{ textAlign: 'center' }}>
					구분
				</Table.Cell>
				<Table.Cell className='hangmok' style={{ textAlign: 'center' }}>
					항목
				</Table.Cell>
				<Table.Cell className='gumak' style={centerStyle}>
					금액[원]
				</Table.Cell>
				<Table.Cell
					colSpan='6'
					className='sulmung'
					style={{ textAlign: 'center' }}>
					설명 및 계산방법
				</Table.Cell>
			</Table.Row>
			{choDan}
			<Table.Row textAlign='center'>
				<Table.Cell className='gubun'>공제전</Table.Cell>
				<Table.Cell className='hangmok'>임금총액</Table.Cell>
				<Table.Cell className='gumak' style={gumakStyle}>
					{numberToCommaString(totalMonthWageAmount)}
				</Table.Cell>
				<Table.Cell textAlign='left' colSpan='6' className='sulmung'>
					세전 임금 총액
				</Table.Cell>
			</Table.Row>
			<ClientReqDLCWageTableDeductDetail
				retiredMonthYes={retiredMonthYes}
				contractEndDate={contractEndDate}
				workerEnteredDate={workerEnteredDate}
				daysInMonth={daysInMonth}
				daysOfInterInAbusent={daysOfInterInAbusent}
				daysOfInterOutAbusent={daysOfInterOutAbusent}
				healthInsureOver60={healthInsureOver60}
				// totalPlusMinusWageRate={totalPlusMinusWageRate}
				finalRetireHealthCareRecalFee={finalRetireHealthCareRecalFee}
				finalRetireOldCareRecalFee={finalRetireOldCareRecalFee}
				values={values}
				initialTax={initialTax}
				cAWageTF1={cAWageTF1}
				cAWageTF2={cAWageTF2}
				cAWageTF3={cAWageTF3}
				cAWageTF4={cAWageTF4}
				userFixedCAWageTF1={userFixedCAWageTF1}
				userFixedCAWageTF2={userFixedCAWageTF2}
				userFixedCAWageTF3={userFixedCAWageTF3}
				userFixedCAWageTF4={userFixedCAWageTF4}
				isInterInWorker={isInterInWorker}
				thisYear={thisYear}
				thisMonth={thisMonth}
				inputTax={inputTax}
				numMealWage={numMealWage}
				numCarWage={numCarWage}
				numChildWage={numChildWage}
				numStudyWage={numStudyWage}
				numTFEtcWage={numTFEtcWage}
				totalMonthWageAmount={totalMonthWageAmount}
				selectedClientReq4In={selectedClientReq4In}
				weekLBTime={weekLBTime}
				inputWageStyle={inputWageStyle}
				inputCommentStyle={inputCommentStyle}
				// 11월26일-공통
				userFixedTax={userFixedTax}
				userFixedRegionTax={userFixedRegionTax}
				userFixedHealthCare={userFixedHealthCare}
				userFixedOldCare={userFixedOldCare}
				userFixedPension={userFixedPension}
				userFixedNoJobInsurance={userFixedNoJobInsurance}
				plusDeductHealthCare={plusDeductHealthCare}
				plusDeductOldCare={plusDeductOldCare}
				plusDeductWorkTax={plusDeductWorkTax}
				plusDeductWorkRegionTax={plusDeductWorkRegionTax}
				plusDeductDuru={plusDeductDuru}
				plusMinus4Ins={plusMinus4Ins}
				plusDeductWorkerCompanyLoan={plusDeductWorkerCompanyLoan}
				plusDeductHealthCareComment={plusDeductHealthCareComment}
				plusDeductOldCareComment={plusDeductOldCareComment}
				plusDeductWorkTaxComment={plusDeductWorkTaxComment}
				plusDeductWorkRegionTaxComment={plusDeductWorkRegionTaxComment}
				plusDeductDuruComment={plusDeductDuruComment}
				plusMinus4InsComment={plusMinus4InsComment}
				plusDeductWorkerCompanyLoanComment={plusDeductWorkerCompanyLoanComment}
				deductEtcName1={deductEtcName1}
				deductEtcAWage1={deductEtcAWage1}
				deductEtcWage1Comment={deductEtcWage1Comment}
				deductEtcName2={deductEtcName2}
				deductEtcAWage2={deductEtcAWage2}
				deductEtcWage2Comment={deductEtcWage2Comment}
				deductEtcName3={deductEtcName3}
				deductEtcAWage3={deductEtcAWage3}
				deductEtcWage3Comment={deductEtcWage3Comment}
				handelInputChange={handelInputChange}
				setValueTaxWageSum={setValueTaxWageSum}
			/>
		</React.Fragment>
	);
}
