import { CLEAR_SELECTED_PAYMENTS_INFO, LISTEN_SELECTED_TO_PAYMENTS_INFO, SIGN_IN_USER, SIGN_OUT_USER } from "./authConstants";
import {LOCATION_CHANGE} from 'connected-react-router';

const initialState = {
  authenticated: false,
  authLevel: 10,
  currentUser: null,
  prevLocation: null,
  currentLocation: null,
  selectedPaymentsInfo: null,
}

export default function authReducer(state = initialState, { type, payload }) {
  switch (type) {
    case SIGN_IN_USER:
      return {
        ...state,
        authenticated: true,
        currentUser: {
          email: payload.email,
          photoURL : payload.photoURL,
          uid: payload.uid,
          companyName: payload.displayName,
          providerId: payload.providerData[0].providerId,
        },
      };
    case SIGN_OUT_USER:
      return {
        ...state,
        authenticated: false,
        currentUser: null,
      };
    case LOCATION_CHANGE:
      return {
        ...state,
        prevLocation: state.currentLocation,
        currentLocation: payload.location,
      }
      case LISTEN_SELECTED_TO_PAYMENTS_INFO:
        return {
          ...state,
          selectedPaymentsInfo: payload
        };
      case CLEAR_SELECTED_PAYMENTS_INFO:
        return {
          ...state,
          selectedPaymentsInfo: null,
        };
    default:
      return state;
  }
}

