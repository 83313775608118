import React, { useState, useMemo, useEffect } from 'react';
import '../Roe1GeneralRules.css'; // CSS 파일 불러오기

const RuleSection = ({
	number,
	title,
	content,
	isOpen,
	toggleOpen,
	amended,
}) => {
	const formatContent = (content) => {
		if (!content) return null; // content가 undefined일 경우 null 반환

		let mainCount = 0; // 0부터 시작하여 CSS에서 올바르게 매핑될 수 있도록 설정

		return content.map((item, index) => {
			if (Array.isArray(item)) {
				return (
					<ol key={index} type='1'>
						{item.map((subItem, subIndex) => (
							<li
								key={subIndex}
								className='rule-sub-item'
								data-index={subIndex}>
								{subItem}
							</li>
						))}
					</ol>
				);
			} else {
				const formattedItem = item;
				return (
					<li key={index} className='rule-item' data-index={mainCount++}>
						{formattedItem}
					</li>
				);
			}
		});
	};

	return (
		<div>
			<div className='jau__title sectionup' onClick={toggleOpen}>
				{`제${number}조 ${title}`}
			</div>
			{isOpen && (
				<div className='jau__contents'>
					<ul>{formatContent(content)}</ul>
					{amended && (
						<div className='no-print'>
							<ul>
								{amended.map((amendment, i) => (
									<li
										key={i}
										style={{
											color: amendment?.includes('개정') ? 'red' : 'black',
										}}>
										{amendment}
									</li>
								))}
							</ul>
						</div>
					)}
				</div>
			)}
		</div>
	);
};

export default function RoeBS4UseRules({
	serviceStartDate,
	// profile,
	// authLevel,
	company,
	companyUn,
	companyGa,
	allOpen,
	previousSectionCount1,
	previousSectionCount2,
	previousSectionCount3,
	setSectionCount,
}) {
	const sections = useMemo(() => {
		let baseSections = [
			{
				title: `사용`,
				content: [
					`${company}의 영업비밀은 영업비밀 총괄책임자와 관리책임자의 승인을 얻어 사용할 수 있다.`,
					`기타 필요한 사항은 별도로 정할 수 있다.`,
				],
			},
			{
				title: `부서간 사용`,
				content: [
					`타부서 관리의 영업비밀은 일정한 절차에 따라 이용할 수 있다.`,
					`부서간 영업비밀을 사용·대여할 때에는 각 부서 영업비밀 관리책임자간에 인계인수 절차를 거쳐야 한다.`,
					`부서간 영업비밀을 반환할 때에는 각 부서 영업비밀 관리책임자간에 인계인수 절차를 거쳐야 한다.`,
					`기타 필요한 사항은 별도로 정할 수 있다.`,
				],
			},
			{
				title: `영업비밀의 열람`,
				content: [
					`영업비밀 당연취급자는 영업비밀 인가취급자에게 업무상 필요한 경우 영업비밀을 열람시킬 수 있다.`,
					`영업비밀을 열람한 자는 열람 후 영업비밀 열람전에 기록하여야 한다.`,
					`기타 필요한 사항은 별도로 정할 수 있다.`,
				],
			},
			{
				title: `영업비밀의 대출`,
				content: [
					`영업비밀 당연취급자는 업무상 필요한 경우 동일 제한구역 내에 한하여 영업비밀 인가취급자에게 영업비밀을 대출할 수 있다.`,
					`영업비밀을 대출한 자는 영업비밀 대출부에 기록하여야 한다.`,
					`제1급 영업비밀은 어떠한 경우에도 대출할 수 없다.`,
					`기타 필요한 사항은 별도로 정할 수 있다.`,
				],
			},
			{
				title: `영업비밀의 반출`,
				content: [
					`부득이 영업비밀을 제한구역 밖으로 반출할 경우에는 영업비밀 보호대책을 세우고 영업비밀 반출신청서에 의거 영업비밀 총괄책임자의 승인을 얻어 영업비밀을 반출할 수 있다.`,
					`영업비밀을 반출한 자는 영업비밀 대출부에 기록하여야 한다.`,
					`영업비밀을 반출할 경우 영업비밀 총괄책임자는 보호대책에 대한 교육을 실시하여야 한다.`,
					`기술협약 등 타회사와 영업비밀을 수발할 경우 사용의 한계와 책임을 명확히 하기 위하여 영업비밀 유지관리협약서를 작성하여야 한다.`,
					`제1급 영업비밀과 제2급 영업비밀은 영업비밀 관리위원회의 승인 없이는 어떠한 경우에도 반출할 수 없다.`,
					`기타 필요한 사항은 별도로 정할 수 있다.`,
				],
			},
			{
				title: `영업비밀문서의 전달`,
				content: [
					`영업비밀의 수발방식과 개봉자격은 다음과 같다.`,
					[
						`제1급 영업비밀은 포장 후 제1급 영업비밀인가취급자의 접촉에 의하여 수발하고, 제1급 영업비밀 당연취급자가 직접 개봉하는 것을 원칙으로 한다.`,
						`제2급 영업비밀은 포장 후 제2급 영업비밀인가취급자의 접촉에 의하여 수발하고, 제2급 영업비밀 당연취급자가 직접 개봉하는 것을 원칙으로 한다.`,
						`제3급 영업비밀은 포장 후 제3급 영업비밀인가취급자의 접촉에 의하여 수발하고, 제3급 영업비밀 당연취급자가 직접 개봉하는 것을 원칙으로 한다.`,
					],
					`전자적 방법으로 영업비밀을 전달할 경우에는 전자문서 관리규정에 따라 전자문서를 등급별 영업비밀 당연취급자가 직접 발송하고 수령하여야 한다.`,
					`대외비는 우편으로 발송할 수 있다.`,
					// `사업장 밖으로 영업비밀을 전달할 경우에는 이중봉투를 사용하되 속봉투에 영업비밀을 넣고 비밀등급 표시와 봉인을 하고 겉봉투에는 비밀표시를 하여서는 아니된다.`,
					`영업비밀문서를 전달할 경우에는 비밀수령증을 속봉투와 겉봉투 사이에 넣어 보내고 수령자는 즉시 이를 작성하여 반송하여야 한다. 다만, 동일사업장 내에서는 영업비밀문서 관리대장(발송용)에 수령자 서명날인으로 생략할 수 있고 대외비 전달시에는 사용하지 않을 수 있다.`,
					`부득이 영업비밀을 통신수단에 의하여 이송할 때에는 음어를 사용하거나 주요내용 부분은 이를 분리하여 이송하여야 한다.`,
					`기타 필요한 사항은 별도로 정할 수 있다.`,
				],
			},
			{
				title: `양도`,
				content: [
					`영업비밀을 양도할 때에는 관련부서와 협의하고 영업비밀 관리위원회의 승인을 얻어야 한다.`,
					`${companyUn} 영업비밀을 양도한 후에도 필요에 따라 관계기록을 폐기하지 않고 영업비밀유지·관리를 수행해야 한다.`,
					`기타 필요한 사항은 별도로 정할 수 있다.`,
				],
			},
			{
				title: `폐기`,
				content: [
					`더 이상 활용가치가 없는 영업비밀은 일정한 절차에 의해 폐기할 수 있으며, 폐기 후에도 필요한 경우에는 계속하여 보호·관리한다.`,
					`기타 필요한 사항은 별도로 정할 수 있다.`,
				],
			},
		];

		// if (['대한민국', '한국', '일본']?.includes(company)) {
		// 	const additionalSection = {
		// 		title: `소정근로시간`,
		// 		content: [
		// 			`사원의 소정근로시간은 아래와 같다.`,
		// 			[
		// 				`1일 소정근로시간은 휴게시간을 제외하고 8시간을 원칙으로 하되, 단시간 사원의 경우 1일 8시간을 한도로 근로계약에 의거 소정근로시간을 달리 정할 수 있다.`,
		// 				`1주 소정근로시간은 40시간을 원칙으로 하되, 단시간 사원의 경우 1주 40시간 한도로 근로계약에 의거 소정근로시간을 달리 정할 수 있다.`,
		// 			],
		// 			`15세 이상 18세 미만인 사원의 소정근로시간은 아래와 같다.`,
		// 			[
		// 				`1일 소정근로시간은 휴게시간을 제외하고 7시간을 원칙으로 하되, 단시간 사원의 경우 1일 7시간을 한도로 근로계약에 의거 소정근로시간을 달리 정할 수 있다.`,
		// 				`1주 소정근로시간은 35시간을 원칙으로 하되, 단시간 사원의 경우 1주 35시간 한도로 근로계약에 의거 소정근로시간을 달리 정할 수 있다.`,
		// 			],
		// 			`1주 소정근로시간은 월요일의 시업시각부터 기산하고, 1월 소정근로시간은 매월 1일의 시업시각부터 기산한다.`,
		// 			`경영상 필요한 경우 근로계약서 또는 임금 계약서의 작성을 통하여 기존에 제1항 및 제2항의 규정의 적용받는 것과 달리 1일 또는 1주 소정근로시간을 약정할 수 있다.`,
		// 			`고용노동부장관의 승인을 받은 감시적, 단속적 근로에 종사하는 자(경비직, 시설관리직, 운전기사 등) 및 관리, 감독업무 또는 기밀 사무를 취급하는 자는 본 규칙에서 정한 근로시간, 휴게와 휴일에 관한 규정은 적용하지 아니한다.`,
		// 			`기타 필요한 사항은 별도로 정할 수 있다.`,
		// 		],
		// 	};
		// 	baseSections.splice(1, 0, additionalSection); // "목적" 다음에 "소정근로시간" 삽입
		// }

		const excludedCompanies = ['주식회사 허브601'];

		// 조건에 따라 섹션 제거
		if (excludedCompanies?.includes(company)) {
			// "title"이 여러 개일 때 제거할 섹션 목록
			const excludedTitles = ['영업비밀 관리위원회'];

			baseSections = baseSections.filter(
				(section) => !excludedTitles?.includes(section.title)
			);
		}

		setSectionCount(baseSections.length); // 조항 수 설정

		return baseSections;
	}, [serviceStartDate, company, companyUn, companyGa, setSectionCount]); // eslint-disable-line react-hooks/exhaustive-deps

	const [openSections, setOpenSections] = useState({});

	useEffect(() => {
		const newOpenSections = sections.reduce((acc, section) => {
			acc[section?.title] = allOpen;
			return acc;
		}, {});
		setOpenSections(newOpenSections);
	}, [allOpen, sections]);

	const toggleSection = (section) => {
		setOpenSections((prevState) => ({
			...prevState,
			[section]: !prevState[section],
		}));
	};

	return (
		<>
			<div className='jang sectionup'>제4장 복무제도</div>
			{sections.map((section, index) => (
				<div key={index}>
					<RuleSection
						number={
							index +
							1 +
							previousSectionCount1 +
							previousSectionCount2 +
							previousSectionCount3
						}
						title={section?.title}
						content={section?.content}
						isOpen={!!openSections[section?.title]}
						toggleOpen={() => toggleSection(section?.title)}
						amended={section?.amended}
					/>
				</div>
			))}
		</>
	);
}
