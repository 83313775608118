import React, {Fragment, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTable, useSortBy, useGlobalFilter, useFilters, usePagination } from 'react-table';
import { Button, Segment } from 'semantic-ui-react';
import { clearUntactsTableSample, fectchUntactsTableSample } from '../untactActions';
import ColumnFilter from './ColumnFilter';
import { COLUMNS } from './columns';
import GlobalFilter from './GlobalFilter';
import { Link } from 'react-router-dom';
import './table.css';
import * as XLSX from 'xlsx';

export default function SortingTable() {

  const dispatch = useDispatch();
  const { untactsSample } = useSelector(state => state.untactStore);
  const [trOpen, setTrOpen] = useState(true)

  useEffect(() => {
    dispatch(fectchUntactsTableSample())
    return () => {
      dispatch(clearUntactsTableSample()); 
    };
  }, [dispatch]);

  const columns = useMemo(() => COLUMNS, []);
  const data = useMemo(() => untactsSample, [untactsSample]);
  const defaultColumn = useMemo(() => {
    return {
      Filter: ColumnFilter
    }
  }, []);

  const useInstance = useTable({
    columns: columns,
    data:data,
    defaultColumn:defaultColumn,
    initialState : { pageIndex : 0} 
  },
  useFilters, 
  useGlobalFilter,
  useSortBy,
  usePagination,
  );

  const { 
    getTableProps, 
    getTableBodyProps, 
    headerGroups, 
    page,
    setPageSize,
    gotoPage,
    pageCount,
    nextPage,
    previousPage,
    canNextPage,
    canPreviousPage,
    pageOptions,
    prepareRow,
    state,
    setGlobalFilter,
  } = useInstance;

  const {globalFilter, pageIndex, pageSize} = state;

  const exportToExcel = () => {
    const table = document.getElementById('table-to-xls');
    const workbook = XLSX.utils.table_to_book(table, { sheet: '자문사관리' });
    XLSX.writeFile(workbook, 'excelFile.xlsx');
  };

  return (
    <>
      <GlobalFilter filter={globalFilter} setFilter={setGlobalFilter} />
      <div>
        <span>
          Page{'  '}
          <strong>
            {pageIndex + 1} of {pageOptions.length}
          </strong>{'  '}
        </span>
        <span>
          | Go to page : {' '}
          <input 
            type='number' 
            defaultValue={pageIndex + 1}
            onChange={(e) => {
              const pageNumber = e.target.value ? Number(e.target.value) -1 : 0
              gotoPage(pageNumber)
            }} 
            style={{width : '50px'}}
          />
        </span>
        <span>
          <select 
            value={pageSize}
            onChange={e => setPageSize(Number(e.target.value))}  
          >
            {
              [5, 10, 25, 50].map((pageSize) => (
                <option key={pageSize} value={pageSize}>
                  Show {pageSize}
                </option>
              ))
            }
          </select>
        </span>
        <Button onClick={() => gotoPage(0)} disabled={!canPreviousPage}>{'<<'}</Button>
        <Button onClick={() => previousPage()} disabled={!canPreviousPage}>Previous</Button>
        <Button onClick={() => nextPage()} disabled={!canNextPage}>Next</Button>
        <Button onClick={() => gotoPage(pageCount-1)} disabled={!canNextPage}>{'>>'}</Button>
      </div>
      <Button onClick={exportToExcel} color='green'>
        엑셀추출
      </Button>
      <table {...getTableProps()} id='table-to-xls'>
        <thead>
          {
            headerGroups.map(headerGroup => (
              <tr {...headerGroup.getHeaderGroupProps()}>
                {
                  headerGroup.headers.map(column =>(
                    <th {...column.getHeaderProps(column.getSortByToggleProps())}>
                      {column.render('Header')}
                      <div>
                        {column.canFilter ? column.render('Filter') : null }
                      </div> 
                    </th>
                  ))
                }
              </tr>
            ))
          }
        </thead>
        <tbody {...getTableBodyProps()}>
          {
            page.map(row => {
              prepareRow(row)
              return (
              <Fragment key={row.id}>
                <tr onClick={() => setTrOpen(!trOpen)} {...row.getRowProps()}>
                  {
                    row.cells.map(cell=>{
                      return  <td { ...cell.getCellProps()}> {cell.render('Cell')}</td>
                    })
                  }
                </tr>
                {trOpen &&
                  <tr>
                    <td colSpan={columns.length}>
                      <Segment.Group>
                        <Segment>
                          <span>
                            인업접수 : {row.original.inupApplyOkNo === ""  ? <strong>'연락필요'</strong> : row.original.inupApplyOkNo  }  
                          </span>
                          <span >
                          .  @중기부 바우처신청 : {row.original.vauchorApplyOkNo === ""  ? <strong>'연락필요'</strong> : row.original.vauchorApplyOkNo } 			
                          </span>
                          <span>
                          .  @중기부 수요기업선정 : {row.original.vauchorConfirmOkNo === ""  ? <strong>'연락필요'</strong> : row.original.vauchorConfirmOkNo } 			
                          </span>
                          <span>
                          .  @제로페이 구매 : {row.original.zeroPayOkNo === ""  ? <strong>'연락필요'</strong> : row.original.zeroPayOkNo }
                          </span>
                          <span>
                          .  @공급기업 인업선정 : {row.original.inupSelectOkNo === ""  ? <strong>'연락필요'</strong> : row.original.inupSelectOkNo } 			
                          </span>
                          <span>
                          .  @직무분석 여부 : {row.original.jobanalysisOkNo === ""  ? <strong>'연락필요'</strong> : row.original.jobanalysisOkNo } 			
                          </span>
                          <span>
                          .  @매뉴얼 납품 여부 : {row.original.manuallDoneOkNo === ""  ? <strong>'연락필요'</strong> :  row.original.manuallDoneOkNo } 			
                          </span>
                          <span>
                          .  @수요기업선정 타회사 비대면서비스 : {row.original.otherService !== "" ? row.original.otherService : '' } 			
                          </span>
                          <span>
                          .  @인업 추가 서비스 : {row.original.otherInupService !== "" ? row.original.otherInupService : '' } 			
                          </span>
                        </Segment>
                          <Button as={Link} to={`/manageUntact/${row.original.id}`} color='orange' floated='right'>
                            수정하기
                          </Button>
                      </Segment.Group>
                    </td>
                  </tr>}
                </Fragment>
              )
            })
          }
        </tbody>
      </table>
    </>
  )
}
