import React from 'react';
import { useHistory } from 'react-router-dom';
import { Button } from 'semantic-ui-react';
import {
	getAgeFromSocialNumber,
	getAgeFromSocialNumberEnteredDate,
} from '../../../../app/common/util/util';
import { decrypto } from '../../../../app/firestore/firestoreService';
import { useDispatch, useSelector } from 'react-redux';
import { clearPaydocu } from '../../../companyManage/companyActions';
import { Link } from 'react-router-dom/cjs/react-router-dom.min';

export default function BizWorkerListItem4InButton({
	clientReq4In,
	freeComClient,
	companyName,
	index,
	enterDate,
	loadingDelete,
	deleteDisable,
	setConfirmOpen,
	clientReqs4In,
	bizIncomeWorker,
}) {
	const agentWorker = clientReq4In;
	const dispatch = useDispatch();
	const { currentUserProfile } = useSelector((state) => state.profile);
	const { clientInfo, superAdminForFreeTrue } = useSelector(
		(state) => state.companyReducer
	);
	const authLevel = clientInfo?.authLevel
		? clientInfo?.authLevel
		: currentUserProfile?.authLevel;
	const ceoName = clientInfo?.ceoName
		? clientInfo?.ceoName
		: currentUserProfile?.ceoName;

	const decryptoClientReq = {};
	for (const prop in agentWorker) {
		decryptoClientReq[prop] =
			prop === 'workerSocialNumberFront' ||
			prop === 'workerSocialNumberBack' ||
			prop === 'familySocialNumber' ||
			prop === 'healthCarePeopleComments'
				? decrypto(agentWorker[prop], String(process.env.CRYPTO_KEY))
				: agentWorker[prop];
	}

	const history = useHistory();

	function manageClientReqDayWorkerHnadler(id) {
		history.push(`/manageClientReqDayWorker/${id}`);
	}

	function manageClientDayWorkerNewWageHnadler(id) {
		dispatch(clearPaydocu());
		history.push(`/clientRDaylyWorkerMonthWageTable/${id}`);
	}

	const netAge = getAgeFromSocialNumber(
		decryptoClientReq.workerSocialNumberFront
	);
	const netAgeEntered = getAgeFromSocialNumberEnteredDate(
		decryptoClientReq.workerSocialNumberFront,
		enterDate
	); //최초 계약일 만나이

	const before65EnteredWorker =
		netAgeEntered < 65 && netAge >= 65 ? true : false;

	const isBizWorkerText = bizIncomeWorker ? '사업소득직' : '';

	const freeOpenCopyYesOrNot = superAdminForFreeTrue
		? false
		: freeComClient(authLevel, ceoName) && clientReqs4In.length === 1
		? false
		: freeComClient(authLevel, ceoName)
		? true
		: false;
	const 더올림입시학원 =
		companyName === '도담더올림입시학원' ||
		companyName === '도담 더올림 입시학원' ||
		companyName === '더올림학원중등관' ||
		companyName === '더올림학원';

	return (
		<>
			{decryptoClientReq?.hostUid !== currentUserProfile?.id ? (
				<>
					<strong>
						<span style={{ color: 'blue', fontSize: '2' }}>[{index + 1}]</span>
						[회사명] : {companyName} , [직원명] :{' '}
						{decryptoClientReq.worker?.name} &nbsp; [최초 계약일]: {enterDate},
						&nbsp;<span>만 {netAge}세</span>, &nbsp;
						{before65EnteredWorker && (
							<span style={{ color: 'red' }}>
								{' '}
								만 {netAgeEntered}세[최초 계약일 기준],{' '}
							</span>
						)}
						<span style={{ color: 'blue' }}>{isBizWorkerText}</span>
					</strong>
				</>
			) : (
				<>
					<strong>
						<span style={{ color: 'blue', fontSize: '2' }}>[{index + 1}]</span>
						{decryptoClientReq.worker?.name}
						&nbsp; [최초 계약일]: {enterDate}, &nbsp;<span>만 {netAge}세</span>,
						&nbsp;
						{before65EnteredWorker && (
							<span style={{ color: 'red' }}>
								{' '}
								만 {netAgeEntered}세[최초 계약일 기준],{' '}
							</span>
						)}
						<span style={{ color: 'blue' }}>{isBizWorkerText}</span>
					</strong>
				</>
			)}
			<div style={{ marginTop: '10px', marginBottom: '30px' }}>
				<Button
					loading={loadingDelete}
					disabled={
						deleteDisable ||
						(!superAdminForFreeTrue && freeComClient(authLevel, ceoName))
					}
					onClick={() => setConfirmOpen(true)}
					color='red'
					floated='right'
					content='삭제'
				/>
				{더올림입시학원 && (
					<Button
						as={Link}
						to={`/clientReqDaylyPayContract/${clientReq4In?.id}`}
						color='teal'
						floated='right'
						content='위임 계약서'
					/>
				)}
				{/* <Button
          loading={loadingDelete}
          onClick={() => openDetailHandler()}
          color='teal'
          floated='right'
          content='상세보기'
        /> */}
				<>
					<Button
						disabled={freeOpenCopyYesOrNot}
						onClick={() => manageClientReqDayWorkerHnadler(clientReq4In?.id)}
						color='purple'
						floated='right'
						content='인사정보(수정/복사)'
					/>
					<Button
						onClick={() =>
							manageClientDayWorkerNewWageHnadler(clientReq4In?.id)
						}
						color='purple'
						floated='right'
						content='개인급여작업'
					/>
				</>
			</div>
		</>
	);
}
