import { useField } from 'formik'
import React from 'react'
import { FormField, Label } from 'semantic-ui-react';
import NumberFormat from 'react-number-format';

export default function MyPureNumberInput({label, ...props}) {
  const [field, meta] = useField(props);

  return (
    <FormField error={meta.touched && !!meta.error}>
      <label>{label}</label>
      <NumberFormat 
        {...field} 
        value={field.value ?? ''}
        {...props} 
      />
      {meta.touched && meta.error ? (
        <Label basic color='red'>{meta.error}</Label>
      ) : null}
    </FormField>
  )
}
