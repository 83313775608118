import React from 'react';
import { Button } from 'semantic-ui-react';

const ColorChangingButton = ({ onClick, viewLevel, searchResult, buttonState = { color: 'teal', text: '승인' }}) => {
  const isConfirmed = searchResult?.isCompanyComformedTime;
  const buttonText = isConfirmed ? '마감 해제' : '승인';
  const buttonColor = isConfirmed ? 'orange' : 'teal';

  return (
    <Button
      onClick={onClick}
      color={buttonColor}
      floated="right"
      content={buttonText}
      disabled={viewLevel === 10 ? true : false}
    />
  );
};


export default ColorChangingButton;