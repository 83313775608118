import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Grid, Input, Loader } from 'semantic-ui-react';
import { clearPaydocu, fetchPaydocusFormFirestoreService4In } from '../companyActions';
import PaydocuFilters from './PaydocuFilters';
import PaydocuShortCut from './PaydocuShortCut';
import PaydocusTableBankList from './PaydocusTableBankList';
import { isAgentF } from '../../../app/common/util/util';
import { clearClientReqs4In, fetchClientReqs4In } from '../../clientsRequest/clientReq4In/clientReqActions4In';

export default function PaydocusTableBankDashboard() {
  const dispatch = useDispatch();
  const { paydocus, filter, yearMonth, clientInfo } = useSelector(state => state.companyReducer);

  const { currentUserProfile } = useSelector(state => state.profile);
  const { loading } = useSelector(state => state.async);
  const { authenticated } = useSelector(state => state.auth);
  const { clientReqs4In } = useSelector((state) => state.clientReq4InStore);

  const [searchTerm, setSearchTerm] = useState("");
  const [searchResults, setSearchResults] = useState([]);
  const handleChange = event => {
    setSearchTerm(event.target.value);
  };

  const isWorker = currentUserProfile?.isWorker;
  const isAgent = isAgentF(currentUserProfile?.agentType, currentUserProfile?.authLevel);
  const paydocuWorkerUids = currentUserProfile?.paydocuWorkerUid

  useEffect(() => {
    const fetchClientReqs = async () => {
      try {
        await dispatch(fetchClientReqs4In(filter));
      } catch (error) {
        console.error("Error fetching client requests:", error);
      }
    };
  
    fetchClientReqs();
  
    return () => {
      dispatch(clearClientReqs4In());
    };
  }, [dispatch, filter]);
  
  useEffect(() => {
    const fetchPaydocus = async () => {
      try {
        await dispatch(fetchPaydocusFormFirestoreService4In(filter, yearMonth, paydocuWorkerUids, clientInfo));
      } catch (error) {
        console.error("Error fetching paydocus:", error);
      }
    };
  
    fetchPaydocus();
  
    return () => {
      dispatch(clearPaydocu());
    };
  }, [dispatch, filter, yearMonth, paydocuWorkerUids, clientInfo]);
  


  const year = yearMonth.substr(0, 4)
  const month = yearMonth.substr(4, 2)

  return (
    <>
      {isWorker ? null : <PaydocuShortCut filter={filter} clientReqs4In={clientReqs4In}/> }

      <h1 style={{textAlign: 'center', margin: '50px'}}>{year}년 {month}월 급여이체 대장</h1>
      <PaydocuFilters 
        loading={loading} 
        isWorker={isWorker} 
        isAgent={isAgent}
      />
        <>
          {isWorker ? null
          :
            <>
              직원성명 검색
                : <Input
                  type="text"
                  placeholder="Search"
                  value={searchTerm}
                  onChange={handleChange}
                />
                <br />
                <br />
            </>
          }
        </>
        <Grid>
          <Grid.Column width={16}>
            <>
      
              {authenticated &&
                <PaydocusTableBankList
                  paydocus={paydocus}
                  loading={loading}
                  searchResults = {searchResults}
                  setSearchResults = {setSearchResults}
                  searchTerm = {searchTerm}
                  year={year}
                  month={month}
                />              
              }
            </>
          </Grid.Column>
          <Grid.Column width={16}>
            <Loader active={loading} />
          </Grid.Column>
        </Grid>
    </>
  );
};