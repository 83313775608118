import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { Dropdown, Menu } from 'semantic-ui-react'

export default function PaydocuWorkerMenu({FaWonSign, isBizWorker}) {

  const history = useHistory();

  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [isMobile, setIsMobile] = useState(window.innerWidth < 1000);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 1000);
    };
    
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);  

  const handleMouseOver = () => {
    setDropdownOpen(true);
  };

  const handleMouseLeave = () => {
    setDropdownOpen(false);
  };

  function reloadToPaydocus () {
    history.push('/paydocus');
  }

  return (
    <>
      <Menu.Item onMouseOver={handleMouseOver} onMouseLeave={handleMouseLeave}>
        {!isMobile && <FaWonSign className="icon-spacing" />}
        <Dropdown style={{width:'160px'}} pointing='top left' text={isBizWorker ? "사업소득관리" : "급여관리"} open={dropdownOpen} onMouseOver={handleMouseOver}
          onMouseLeave={handleMouseLeave}>
          <Dropdown.Menu>
            <Dropdown.Item onClick={() => reloadToPaydocus()} text={isBizWorker ? "사업소득 명세서" : '임금명세서'} />
          </Dropdown.Menu>
        </Dropdown>
      </Menu.Item>
    </>
  )
}