import moment from 'moment';
import React, { useState, useEffect, useCallback } from 'react';
import {
	calculateWeeks,
	getWeek,
	roundUp2,
	sumOfWeek,
} from '../../../../../app/common/util/util';

export default function WeekNumberInputLastMonth({
	targetMonth,
	targetYear,
	paydocus,
	workerId,
	setPreviousWorkDates,
}) {
	const [month, setMonth] = useState('');
	const [year, setYear] = useState('');
	const [weeks, setWeeks] = useState([]);
	const [weekInputs, setWeekInputs] = useState([]);
	const [selectedDaysLastMonth, setSelectedDaysLastMonth] = useState([]);

	const getPreviousMonthWorkDates = useCallback(
		(targetYear, targetMonth, paydocus) => {
			let year = targetYear;
			let month = targetMonth;
			month -= 1;
			if (month < 1) {
				year -= 1;
				month = 12;
			}
			const lastYearMonth = `${year.toString()}${month
				.toString()
				.padStart(2, '0')}`;
			const lastMonthPayDocu = paydocus.filter(
				(paydocu) =>
					paydocu.workerInfo?.workerId === workerId &&
					paydocu.info.yearMonth === lastYearMonth
			);
			const lastMonthWorkDates =
				lastMonthPayDocu.length > 0
					? lastMonthPayDocu[0]?.workerInfo?.workDates
					: [];
			return [lastYearMonth, lastMonthWorkDates || []];
		},
		[workerId]
	);

	useEffect(() => {
		async function fetchData() {
			const result = await getPreviousMonthWorkDates(
				targetYear,
				targetMonth,
				paydocus
			);
			setSelectedDaysLastMonth(result[1]);
		}
		fetchData();
	}, [getPreviousMonthWorkDates, targetYear, targetMonth, paydocus]);

	useEffect(() => {
		setWeeks(calculateWeeks(month, year));
	}, [month, year]); // eslint-disable-line react-hooks/exhaustive-deps

	useEffect(() => {
		let isCancelled = false;
		async function fetchData() {
			const [lastYearMonth, lastMonthWorkDates] =
				await getPreviousMonthWorkDates(targetYear, targetMonth, paydocus);
			if (!isCancelled) {
				setMonth(lastYearMonth.substring(4, 6));
				setYear(lastYearMonth.substring(0, 4));
				setSelectedDaysLastMonth(lastMonthWorkDates);
			}
		}
		fetchData();
		return () => {
			isCancelled = true;
		};
	}, [targetYear, targetMonth, paydocus, getPreviousMonthWorkDates]);

	const handleInputChange = (event, weekIndex, dayIndex) => {
		const value = parseInt(event.target.value) || 0;
		const actualDate = moment(
			new Date(year, month - 1, weeks[weekIndex][dayIndex].id)
		).format('D');

		setWeekInputs((prevState = {}) => {
			const newState = { ...prevState };
			if (!newState[weekIndex]) newState[weekIndex] = {};
			newState[weekIndex][dayIndex] = value;

			// Calculate sumOfWorkHours after updating the state
			const sumOfWorkHours = Object.values(newState[weekIndex]).reduce(
				(a, b) => +a + +b,
				0
			);

			setSelectedDaysLastMonth((prevSelectedDays = []) => {
				const existingIndex = prevSelectedDays.findIndex(
					(day) => day.weekIndex === weekIndex && day.dayIndex === dayIndex
				);
				const weekNumber = getWeek(new Date(year, month - 1, actualDate));
				if (value >= 1) {
					if (existingIndex !== -1) {
						// If the day already exists in selectedDays, update its value
						const updatedSelectedDays = [...prevSelectedDays];
						updatedSelectedDays[existingIndex] = {
							weekNumber,
							sumOfWorkHours,
							weekIndex,
							dayIndex,
							value,
							date: actualDate,
							month,
							year,
						}; // Add actual date and weekNumber
						return updatedSelectedDays;
					} else {
						// If the day does not exist in selectedDays, add it
						return [
							...prevSelectedDays,
							{
								weekNumber,
								sumOfWorkHours,
								weekIndex,
								dayIndex,
								value,
								date: actualDate,
								month,
								year,
							},
						]; // Add actual date and weekNumber
					}
				} else if (existingIndex !== -1) {
					// If the value is less than 1 and the day is in selectedDays, remove it
					const updatedSelectedDays = [...prevSelectedDays];
					updatedSelectedDays.splice(existingIndex, 1);
					return updatedSelectedDays;
				}
				// If none of the above conditions are met, return the previous selectedDays
				return prevSelectedDays;
			});
			return newState;
		});
	};

	useEffect(() => {
		if (selectedDaysLastMonth) {
			const newWeekInputs = selectedDaysLastMonth.reduce(
				(result, { weekIndex, dayIndex, value }) => {
					if (!result[weekIndex]) result[weekIndex] = {};
					result[weekIndex][dayIndex] = value;
					return result;
				},
				{}
			);
			setWeekInputs(newWeekInputs);
			setPreviousWorkDates(selectedDaysLastMonth);
		}
	}, [selectedDaysLastMonth, setPreviousWorkDates]);

	const padding = '0.22rem';
	const tableLine = '1px solid rgba(0, 0, 0, 0.1)';
	const inputTrue = true;

	return (
		<div>
			<span style={{ color: 'blue', fontSize: '1rem', marginBottom: '10px' }}>
				주휴수당 등 계산을 위한 참고용 전월 근로내역입니다
			</span>
			<br />
			<br />
			<table style={{ textAlign: 'center' }}>
				<thead>
					<tr>
						<th>{month}월</th>
						<th>일</th>
						<th>월</th>
						<th>화</th>
						<th>수</th>
						<th>목</th>
						<th>금</th>
						<th>토</th>
						<th>계</th>
					</tr>
				</thead>
				<tbody>
					{weeks.map((week, weekIndex) => (
						<tr key={`week-${weekIndex}`}>
							<td>
								{getWeek(new Date(year, month - 1, week[0] ? week[0].id : 1))}주
							</td>
							{week.map((option, dayIndex) => (
								<td
									key={option ? option.id : `empty-${dayIndex}`}
									style={{ border: tableLine, padding: padding }}>
									{option ? (
										<>
											{dayIndex === 0 ? option.name : `${option.id}`}
											<br />
											<input
												type='number'
												disabled={inputTrue}
												style={{
													marginLeft: '5px',
													width: '40px',
													textAlign: 'right',
												}}
												value={
													weekInputs[weekIndex]
														? weekInputs[weekIndex][dayIndex] || ''
														: ''
												}
												onChange={(event) =>
													handleInputChange(event, weekIndex, dayIndex)
												}
											/>
										</>
									) : null}
								</td>
							))}
							{Array(7 - week.length)
								.fill()
								.map((_, i) => (
									<td
										key={`empty-${week.length + i}`}
										style={{ border: tableLine, padding: padding }}>
										&nbsp;
									</td>
								))}
							<td style={{ border: tableLine, padding: padding }}>
								{roundUp2(sumOfWeek(weekIndex, weekInputs))}
							</td>
						</tr>
					))}
				</tbody>
			</table>
		</div>
	);
}
