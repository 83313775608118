import React, { useState, useMemo, useEffect } from 'react';
import '../Roe1GeneralRules.css'; // CSS 파일 불러오기

const RuleSection = ({
	number,
	title,
	content,
	isOpen,
	toggleOpen,
	amended,
}) => {
	const formatContent = (content) => {
		if (!content) return null; // content가 undefined일 경우 null 반환

		let mainCount = 0; // 0부터 시작하여 CSS에서 올바르게 매핑될 수 있도록 설정

		return content.map((item, index) => {
			if (Array.isArray(item)) {
				return (
					<ol key={index} type='1'>
						{item.map((subItem, subIndex) => (
							<li
								key={subIndex}
								className='rule-sub-item'
								data-index={subIndex}>
								{subItem}
							</li>
						))}
					</ol>
				);
			} else {
				const formattedItem = item;
				return (
					<li key={index} className='rule-item' data-index={mainCount++}>
						{formattedItem}
					</li>
				);
			}
		});
	};

	return (
		<div>
			<div className='jau__title sectionup' onClick={toggleOpen}>
				{`제${number}조 ${title}`}
			</div>
			{isOpen && (
				<div className='jau__contents'>
					<ul>{formatContent(content)}</ul>
					{amended && (
						<div className='no-print'>
							<ul>
								{amended.map((amendment, i) => (
									<li
										key={i}
										style={{
											color: amendment?.includes('개정') ? 'red' : 'black',
										}}>
										{amendment}
									</li>
								))}
							</ul>
						</div>
					)}
				</div>
			)}
		</div>
	);
};

export default function Roe4ServiceRules({
	serviceStartDate,
	profile,
	// authLevel,
	company,
	companyUn,
	companyGa,
	allOpen,
	previousSectionCount1,
	previousSectionCount2,
	previousSectionCount3,
	setSectionCount,
}) {
	const sections = useMemo(() => {
		let baseSections = [
			{
				title: `인사권`,
				content: [
					`사원에 대한 인사처분의 권한은 ${company}의 대표자(이하 "대표자"라 함.)가 행한다.`,
					`인사권은 사원의 임면, 인사발령, 대기발령, 징계와 포상 등에 대한 처분의 권한을 포함한다.`,
					`기타 필요한 사항은 별도로 정할 수 있다.`,
				],
			},
			{
				title: `지원자 전형서류 및 신규채용`,
				content: [
					`사원은 공개경쟁에 의하여 채용한다. 다만, 별도의 정함이 있는 경우에는 특별채용할 수 있고, 채용의 최소한의 기준은 다음 각 호와 같다.`,
					[
						`사상이 건전하고 신원이 확실하며 신체 건강한 자`,
						`장래 우수한 사원이 될 수 있다고 인정되는 자`,
						`${companyGa} 지정한 자격증 소지자`,
						`만 18세 이상인 자`,
						`해외 근무에 결격 사유가 없는 자`,
					],
					`${companyUn} 사원의 모집 및 채용에 있어서 합리적인 이유 없이 성별, 연령, 신앙, 사회적 신분, 출신지역, 출신학교, 혼인∙임신 또는 병력(病歷) 등에 의한 차등을 두지 않는다.`,
					`${companyUn} 사원을 모집ㆍ채용할 때 그 직무의 수행에 필요하지 아니한 용모ㆍ키ㆍ체중 등의 신체적 조건, 미혼 조건, 그 밖에 고용노동부령으로 정하는 조건을 제시하거나 요구하여서는 아니 된다.`,
					`${company}에 입사를 지원하는 자는 다음 각 항의 전형 서류를 제출하여야 한다.`,
					[`입사원서(회사 지정한 소정양식)`, `이력서 및 자기소개서`],
					`1차 서류 및 면접에 합격한 자는 신체검사(${companyGa} 병원을 지정하는 경우 그 병원의 신체검사)에 합격한 경우 제3항의 결격사유가 없다면 채용이 확정되어 근로관계가 성립된다. 다만, ${companyUn} 신체검사를 생략할 수도 있다.`,
					`신체검사에 합격한 자는 다음 각 호의 서류를 최초 근로제공 예정일까지 허위 없이 제출하여야 하며, 부득이한 경우에 한해 ${companyGa} 지정하는 기일까지 연기하여 제출할 수 있다.`,
					[
						`고용보험가입 여력내역`,
						`경력증명서(경력자에 한함.)`,
						`자격 및 면허증 사본(해당자에 한함.)`,
						`주민등록등본 및 초본(초본은 남성의 경우에 한함 / 병역사항 기재 필수) 1부`,
						`사진 (반명함판 3개월 이내 촬영) 2매 또는 사진파일`,
						`통장사본`,
					],
					`사원의 채용이 결정되면 다음의 근로계약 서류는 최초 근로일까지 즉시 작성하되, 본 조항 제3항(신체검사 통과) 및 제4항(인사서류 제출)의 의무를 이행하지 아니할 경우 채용은 최초 입사일로 소급해서 종료되며, 업무수행기간(근로제공 아님)에 대한 보수(임금 아님)는 근로계약 및 임금계약에 정해진 기준에 준하여 일할 계산하여 지급한다.`,
					[
						`근로계약서`,
						`임금계약서`,
						`개인정보활용동의서`,
						`복무서약 및 정보보안 준수 서약서`,
					],
					`기타 필요한 사항은 별도로 정할 수 있다.`,
				],
			},
			{
				title: `채용 절차의 공정화에 관한 법률 이행`,
				content: [
					`${companyUn} 채용을 가장하여 아이디어를 수집하거나 사업장을 홍보하기 위한 목적 등으로 거짓의 채용광고를 내서는 아니 된다.`,
					`${companyUn} 정당한 사유 없이 채용광고의 내용을 구직자에게 불리하게 변경하여서는 아니 된다.`,
					`${companyUn} 구직자를 채용한 후에 정당한 사유 없이 채용광고에서 제시한 근로조건을 구직자에게 불리하게 변경하여서는 아니 된다.`,
					`${companyUn} 구직자에게 채용서류 및 이와 관련한 저작권 등의 지식재산권을 자신에게 귀속하도록 강요하여서는 아니 된다.`,
					`${companyUn} 구직자에게 그 직무의 수행에 필요하지 아니한 다음 각 호의 정보를 기초심사자료에 기재하도록 요구하거나 입증자료로 수집하여서는 아니 된다.`,
					[
						`구직자 본인의 용모ㆍ키ㆍ체중 등의 신체적 조건`,
						`구직자 본인의 출신지역ㆍ혼인여부ㆍ재산`,
						`구직자 본인의 직계 존비속 및 형제자매의 학력ㆍ직업ㆍ재산`,
					],
					`${companyGa} 채용서류를 전자우편 등으로 받은 경우에는 지체 없이 구직자에게 접수된 사실을 홈페이지 게시, 휴대전화에 의한 문자전송, 전자우편, 팩스, 전화 등으로 알려야 한다.`,
					`${companyUn} 채용심사를 목적으로 구직자에게 채용서류 제출에 드는 비용 이외의 어떠한 금전적 비용도 부담시키지 못한다. 다만, 사업장 및 직종의 특수성으로 인하여 불가피한 사정이 있는 경우 고용노동부장관의 승인을 받아 구직자에게 채용심사비용의 일부를 부담하게 할 수 있다.`,
					`${companyUn} 채용대상자를 확정한 경우에는 지체 없이 구직자에게 채용 여부를 홈페이지 게시, 휴대전화에 의한 문자전송, 전자우편, 팩스, 전화 등으로 알려야 한다.`,
					`${companyUn} 구직자의 채용 여부가 확정된 이후 구직자가 채용서류의 반환을 청구하는 경우 본인임을 확인한 후 다음 각 호로 정하는 바에 따라 반환하여야 한다. 다만, 홈페이지 또는 전자우편으로 제출된 경우나 구직자가 구인자의 요구 없이 자발적으로 제출한 경우에는 그러하지 아니하다.`,
					[
						`${companyUn} 구직자가 반환 청구를 한 날부터 14일 이내에 구직자에게 해당 채용서류를 발송하거나 전달하여야 한다.`,
						`구인자가 제1호에 따라 채용서류를 반환하는 때에는 해당 채용서류를 「우편법」 제14조제2항제3호 또는 제15조제2항제3호에 따른 특수취급우편물(이하 “특수취급우편물”이라 한다)을 이용하여야 한다. 다만, 구직자가 원하는 경우에는 구직자와 합의하는 방법으로 전달할 수 있다.`,
						`제2호에 따라 채용서류를 특수취급우편물로 반환하는 경우 채용서류의 반환 장소는 채용서류에 기재된 구직자의 주소지로 한다. 다만, 구직자가 제1호에 따라 채용서류의 반환을 청구할 때 반환 장소를 지정한 경우에는 그 장소로 한다.`,
					],
					`${companyUn} 제8항에 따른 구직자의 반환 청구에 대비하여 다음 각 호로 정하는 기간 동안 채용서류를 보관하여야 한다. 다만, 천재지변이나 그 밖에 구인자에게 책임 없는 사유로 채용서류가 멸실된 경우 ${companyUn} 제8항에 따른 채용서류의 반환 의무를 이행한 것으로 본다.`,
					[
						`구직자가 제8조에 따라 채용서류의 반환을 청구한 경우가 아닌 경우: 구직자의 반환 청구기간(구직자의 채용 여부가 확정된 날 이후 14일부터 180일까지의 기간의 범위에서 구인자가 정한 기간)`,
						`${companyGa} 특수취급우편물을 발송하거나 전달한 시점까지의 기간`,
					],
					`${companyUn} 제9항 각 호에서 정한 반환의 청구기간이 지난 경우 및 채용서류를 반환하지 아니한 경우에는 「개인정보 보호법」에 따라 채용서류를 파기하여야 한다.`,
					`채용서류의 반환에 소요되는 비용은 원칙적으로 회사가 부담한다.`,
					`${companyUn} 제8항부터 제11항까지의 규정을 채용 여부가 확정되기 전까지 구직자에게 알려야 한다.`,
					`${companyUn} 채용시험을 서류심사와 필기ㆍ면접 시험 등으로 구분하여 실시하는 경우 서류심사에 합격한 구직자에 한정하여 입증자료 및 심층심사자료를 제출하게 하도록 노력하여야 한다.`,
					`기타 필요한 사항은 별도로 정할 수 있다.`,
				],
			},
			{
				title: `일반 채용 제한`,
				content: [
					`다음 각 호에 해당하는 자는 사원으로 채용하지 않으며, 제38조에 의거 채용된 후라도 해고할 수 있다.`,
					[
						`피성년후견인, 피한정후견인 선고를 받은 자`,
						`신체 또는 정신상의 장애 및 전염병 보균자로 사회통념상 직무를 감당할 수 없다고 인정되는 자`,
						`병역의무자로서 기피 중에 있는 자`,
						`경력 또는 학력, 이력사항을 허위로 작성하여 채용된 자`,
						`법에 의하여 공민권이 정지 또는 박탈된 자`,
						`${companyGa} 지정한 병원 또는 의사의 신체검사 결과 직무수행에 지장이 있다고 판정된 자`,
						`${company} 사업과 관련한 법령에서 정하는 요건을 충족하지 못한 자`,
						`기타 정상적으로 업무수행이 불가능하다고 객관적으로 인정되는 자`,
					],
				],
			},
			{
				title: `범죄 관련 채용 제한`,
				content: [
					`다음 각 호에 해당하는 자는 사원으로 채용하지 않으며, 채용된 후라도 해고할 수 있다.`,
					[
						`${company}의 사업 및 본 규정에 있는 모든 내용(직장 내 괴롬힘, 성희롱 등)을 포괄)관련 금고 이상의 형을 받고 그 집행이 종료되거나 집행을 받지 아니하기로 확정된 후 5년이 경과하지 아니한 자`,
						`${company}의 사업 및 본 규정에 있는 모든 내용(직장 내 괴롬힘, 성희롱 등)을 포괄)관련 금고 이상의 형을 받고 그 집행유예의 기간이 완료된 날로부터 2년을 경과하지 아니한 자`,
						`${company}의 사업 및 본 규정에 있는 모든 내용(직장 내 괴롬힘, 성희롱 등)을 포괄)관련 금고 이상의 형의 선고유예를 받은 경우에 그 선고유예 기간 중에 있는 자`,
						`기타 위법사항으로 정상적인 업무수행이 불가능하다고 객관적으로 인정되는 자`,
					],
				],
			},
			{
				title: `근로조건의 명시`,
				content: [
					`${companyUn} 근로계약을 체결할 때에 사원에게 다음 각 호의 사항을 명시한다. 근로계약 체결 후 다음 각 호의 사항을 변경하는 경우에도 또한 같다.`,
					[
						`임금`,
						`소정근로시간`,
						`휴일`,
						`연차유급휴가`,
						`취업의 장소와 종사하여야 할 업무에 관한 사항`,
						`근로기준법 제93조 제1호부터 제12호까지의 규정에서 정한 사항`,
						`${company}의 기숙사에 기숙하는 사원에게는 기숙사 규칙에서 정한 사항(해당자에 한함.)`,
					],
					`${companyUn} 사원에게 제1항 제1호와 관련한 임금의 구성항목·계산방법·지급방법 및 제2호부터 제4호까지의 사항이 명시된 서면(전자문서 및 전자거래 기본법 제2조 제1호에 따른 전자문서를 포함한다)의 근로계약서 또는 임금계약서를 교부한다.`,
					`근로계약서 또는 임금계약서의 내용이 다음 각 호의 사유로 인하여 변경되는 경우에는 사원의 요구가 있으면 해당 사원에게 교부한다.`,
					[
						`${companyGa} 사원대표와의 서면 합의에 의하여 근로조건을 변경한 경우`,
						`취업규칙의 개정에 의하여 근로조건을 변경한 경우`,
					],
					`기타 필요한 사항은 별도로 정한다.`,
				],
			},
			{
				title: `근로계약`,
				content: [
					`사원으로 채용된 자는 전조의 근로조건이 명시된 소정양식의 근로계약서 및 임금계약서에 서명∙날인하여 근로계약을 체결한다.`,
					`${companyUn} 무기계약의 사원(정규직 사원)에게 임금의 구성항목∙계산방법∙지불방법, 소정근로시간, 주휴일, 연차유급휴가, 취업의 장소와 종사하여야 할 업무에 관한 사항을 명시한 근로계약서를 교부한다.`,
					`${companyUn} 유기계약의 사원(계약직 사원)에게 근로계약기간에 관한 사항, 임금의 구성항목∙계산방법∙지불방법, 소정근로시간, 주휴일, 연차유급휴가, 취업의 장소와 종사하여야 할 업무에 관한 사항을 명시한 근로계약서를 교부한다.`,
					`기타 필요한 사항은 별도로 정할 수 있다.`,
				],
			},
			{
				title: `수습기간`,
				content: [
					`${companyUn} 입사한 모든 사원에게 근무능력과 적응능력의 양성·교육 및 직무오리엔테이션에 의한 적재적소의 후보선발을 목적으로 3개월의 수습기간을 부여한다. 단, 직무에 필요한 KSA(Knowlege, Skill, Attitude)에 따라 수습기간은 6개월까지 부여할 수 있다.`,
					`수습기간은 근로관계의 성립일로부터 기산한다.`,
					`${companyUn} 필요하다고 인정되는 경우에는 수습기간을 단축할 수 있다.`,
					`경력 사원에게는 수습기간을 두지 않을 수 있다.`,
					`수습기간 중 또는 수습기간 만료 시에 능력∙적성∙근무태도 및 건강상태 등에 대한 수습기간평가보고서를 인사위원회가 심의하여 정규사원으로의 채용이 부적당하다고 인정되는 자에 대해서는 해고할 수 있다.`,
					`수습기간을 거친 후 채용된 자의 수습기간은 근속년수에 산입하다.`,
					`근로계약기간이 1년 이상인 사원의 수습근로계약기간 중 보수는 최저임금액의 90%이상을 지급한다. 단, 근로계약기간이 1년 미만이거나 단순 노무직인 사원의 수습근로계약기간 중 보수는 최저임금액의 100%이상을 지급한다.`,
					`${companyUn} 수습사원의 근무능력과 적응능력을 향상시키기 위하여 후견인 제도를 운영할 수 있다.`,
					`수습사원에 대한 처우와 평가 및 채용 판단기준은 별도로 정할 수 있다.`,
				],
			},
			{
				title: `인사의 원칙`,
				content: [
					`사원에 대한 인사는 KSA(Knowlege, Skill, Attitude를 뜻하는 약어로 이하 "KSA"라 함) 즉, 직책의 자격 요건과 개인의 기능, 적성 및 경력, 지식, 기술, 역량 등을 참작하여 적재적소에 배치함을 원칙으로 한다.`,
					`${companyUn} 업무상 필요에 따라 사원에 대하여 전근, 주재, 파견을 명하거나 부서이동, 직무변경 등의 인사명령을 명할 수 있으며, 이 경우 사원은 고용보험법 시행규칙 별표 2의 "수급자격이 제한되지 아니하는 정당한 이직 사유(제101조제2항 관련)"에 해당하거나 이에 준하는 정당한 이유가 아니면 이를 거부할 수 없다.`,
					`기타 필요한 사항은 별도로 정할 수 있다.`,
				],
			},
			{
				title: `직무관리`,
				content: [
					`${companyUn} 사원에게 KSA가 포함된 직무기술서 및 직무명세서, 직무설명서 등(이하 "직무기술서 등"이라 함)을 작성 제출하게 할 수 있고, 사원은 이에 따라야 한다.`,
					`${companyUn} 제44조 제1항의 인사원칙의 KSA 개발 및 유지, 보수를 위해 사원에게 업무일지를 작성하게 할 수 있고, 사원은 이에 따라야 한다.`,
					`제2항 업무일지는 BSC관점에 따라 작성해야 하며, 관리 감독자는 본인 및 부하사원의 직무기술서 등을 명확히 작성하면, 업무 일지 작성 의무를 면할 수 있다.`,
					`재량근로대상 사원은 본인의 직무기술서 등을 명확히 작성하면, 업무 일지 작성 의무를 면할 수 있다.`,
					`기타 필요한 사항은 별도로 정할 수 있다.`,
				],
			},
			{
				title: `직무평가`,
				content: [
					`${companyUn} 전조의 직무기술서와 경영환경분석, 동종업계의 인사고과 내용, 경영진의 고과 관점 등에 따른 KSA와 R&R(Role & Responsibility)을 기반으로 직무 및 업무, 성과평가를 위한 KPI(Key Performance Index)도출하여 사원을 평가를 실시할 수 있다.`,
					`${companyUn} CSF(Chief Success Factor)와 핵심역량을 기준으로 사원의 성과와 역량을 평가할 수 있다.`,
					`${companyUn} 부서 KPI, 부서 CSF(Chief Success Factor) 등으로 부서 또는 팀 평가를 할 수 있다.`,
					`${companyUn} 제1항 내지 제3항에 따른 평가로 사원의 임금인상, 성과급, 승진, 교육, 부서이동, 업무 재배치 등 전반적인 인사관리에 활용할 수 있다.`,
					`기타 필요한 사항은 별도로 정할 수 있다.`,
				],
				amended:
					serviceStartDate > `2021-11-6`
						? null
						: [
								`2021년 11월7일 개정`,
								`변경전 : ${companyUn} 전조의 직무기술서 등에 따른 KSA와 R&R(Role & Responsibility)을 기반으로 직무 및 업무, 성과평가를 위한 KPI(Key Performance Index)도출하여 사원을 평가를 실시할 수 있다.`,
								`변경후 : ${companyUn} 전조의 직무기술서와 경영환경분석, 동종업계의 인사고과 내용, 경영진의 고과 관점 등에 따른 KSA와 R&R(Role & Responsibility)을 기반으로 직무 및 업무, 성과평가를 위한 KPI(Key Performance Index)도출하여 사원을 평가를 실시할 수 있다.`,
						  ],
			},

			{
				title: `사무인계 의무`,
				content: [
					`사원이 전근, 휴직, 전보, 퇴직하는 경우에는 보관중인 금전, 서류 기타 일체의 물품을 반납하며, 업무에 관한 제반 사항을 후임자에게 정확히 인계하고 부서장에게 문서로서 보고하여야 한다.`,
				],
			},
			{
				title: `복무의무`,
				content: [
					`${companyUn} 사원은 다음 각 호의 권고의무를 성실히 준수하여야 한다.`,
					[
						`항상 건강에 유의하고 명랑·활발한 태도로 근무한다.`,
						`담당직무의 권한 외의 독단적인 행동을 하지 않는다.`,
						`${company}의 시설, 기구 기타 비품을 조심하여 다루며, 기타 소모품을 절약한다.`,
						`${company}의 청결에 힘쓰고 화재나 도난방지 및 안전사고의 방지에 적극 협조한다.`,
						`사원은 근무시간 중 그 품위를 유지할 수 있도록 단정한 복장을 착용하여야 한다.`,
						`사원은 동료사원 또는 고객 등 제3자에게 불쾌감을 줄 수 있는 염색, 문신, 피어싱, 네일 부착물 등은 착용하지 아니한다.`,
					],
					`${companyUn} 사원은 다음 각 호의 강행의무를 반드시 준수하여야 한다.`,
					[
						`${company}의 허락을 얻지 아니하고 근무장소를 이탈하거나 태만한 행위를 하여서는 안 된다.`,
						`사원은 근무시간 동안에는 담당업무 이외의 사유로 사원 개인의 휴대폰, 인터넷을 사용하지 않는다.`,
						`사원은 근무시간 동안에는 직무상 허용된 경우를 제외하고 주식관련 일체의 행위(주식 거래 및 주식 가격 모니터링 등)을 하지 아니한다.`,
						`사원은 근무시간 동안 온라인 쇼핑을 하지 아니한다.`,
						`사원은 휴게시간을 포함한 점심시간을 무단으로 연장하지 아니한다.`,
						`사원은 근무시간 동안 취침(책상에 엎드려 자는 행위 포함)을 하지 아니한다.`,
						`사원은 이력사항에 변경이 있을 때에는 그 사유 발생일로부터 일주일 이내에 신고하여야 한다.`,
						`${companyGa} 업무일지 작성 지시를 받는 경우 이를 이행해야 한다.`,
						`${company}의 본 복무규정을 포함하여 제반 규정을 준수하고 상사의 정당한 직무상 지시에 따라야 한다.`,
						`${company} 소속 사원으로서의 명예와 품위를 손상하는 행위를 하지 않는다.`,
						`사원은 재직 중은 물론 퇴직 후라도 직무상 취득한 영업비밀, 기업기밀, 대외비 등의 기밀사항을 누설하지 않는다.`,
						`${company}의 시설, 기계기구 기타 물품을 허가 없이 직무 이외의 목적으로 사용하지 않는다.`,
						`다른 사원의 작업을 방해하거나, 직장의 풍기와 질서를 문란시키는 행위를 하지 않는다.`,
						`사원은 청렴∙결백하여야 하며, 직무와 관련하여 직·간접으로 사례, 증여 또는 향응 등을 제공받지 않는다.`,
						`${company}의 허가 없이는 직무 이외의 영리를 목적으로 하는 다른 업무에 종사할 수 없으며, 다른 직무를 겸할 수 없다.`,
						`사원은 직무상의 직권을 이용하여 타인으로부터 부당한 금품을 차용하거나, 증여 또는 뇌물을 받아서는 아니 된다.`,
						`${company}의 허락없이 사업장 내에서 선동집회, 연설, 토론, 집단행위, 시위운동, 유인물의 배포, 게시 등을 하거나 정치활동을 하여서는 아니 된다.`,
					],
					`${companyUn} 사원은 다음 각 호의 금지행위를 하여서는 아니 된다.`,
					[
						`${company}의 허가 없이 휴대폰 등으로 음성녹음을 하거나 시도하는 행위`,
						`${company}의 허가 없이 휴대폰 등으로 영상촬영을 하거나 시도하는 행위`,
						`${company}의 허가 없이 영업비밀, 기업기밀, 대외비 등을 취득하거나 시도하는 행위`,
					],
					`기타 필요한 사항은 별도로 정할 수 있다.`,
				],
			},

			{
				title: `입출금지 및 퇴장명령`,
				content: [
					`사원이 다음 각 호의 하나에 해당할 경우에는 ${company} 내의 출입을 불허하며, 출근 후 발견 시에는 퇴장을 명할 수 있다.`,
					[
						`${company}의 허가 없이 벽보, 집회, 시위를 하거나 시도할 경우`,
						`음주 등으로 인하여 정상상태가 아닌 경우`,
						`업무상 필요치 않은 흉기 기타 위험물을 휴대하였을 경우`,
						`위생상 유해 또는 재해방지상 방해가 된다고 인정될 경우`,
						`법령 또는 ${company} 규정에 의하여 작업 또는 출근이 금지된 경우`,
						`정보자산의 유출에 사용될 수 있는 기구 또는 장비를 소지한 경우`,
						`정당한 이유없이 60분 이상 지각한 경우`,
						`근무의무가 없는 날에 임의로 출근하는 경우`,
						`전염병에 걸린 경우(독감환자를 포함한다)`,
						`해고된 자`,
						`사원이 그 밖에 위 각 호에 준하는 행위를 한 경우`,
					],
					`제1항 제1호 내지 제8호의 적용을 받은 사원은 결근으로 처리한다.`,
					`기타 필요한 사항은 별도로 정할 수 있다.`,
				],
			},
			{
				title: `결근계의 제출`,
				content: [
					`다음 각 호의 사유로 결근하고자 하는 사원은 결근계를 사전에 제출한다.`,
					[`업무 외 질병∙부상`, `기타 부득이한 사유`],
					`긴급한 사유로 결근하는 사원은 먼저 유선으로 결근을 신고하고 결근의 기산일로부터 2일 이내에 결근계를 제출하여야 한다.`,
					`업무외 부상 또는 질병으로 인하여 3일 이상 5일 이하 결근할 경우에는 ${companyGa} 인정하는 의료기관의 의사 진단서를 첨부하여 제출하여야 한다.`,
					`${companyGa} 인정하는 정당한 사유로 5일 이상 10일 이하 결근할 경우에는 ${companyGa} 인정하는 기관의 결근입증자료를 첨부하여 제출하여야 한다.`,
					`사원이 사전신고를 하지 않거나 정당한 사유없이 결근하는 경우에는 징계한다.`,
					`결근으로 인한 무노동시간은 무임금을 원칙으로 한다.`,
					`기타 필요한 사항은 별도로 정할 수 있다.`,
				],
			},

			{
				title: `지각계의 제출`,
				content: [
					`지각이라 함은 시업시각 이후의 출근을 말한다.`,
					`시업시각 이후 2시간 이내의 지각은 출근으로 간주하나, 그 이후의 지각은 법정제도 이외의 다음 각 호의 사항에서 결근으로 본다. 단, 근로를 제공한 시간은 유급으로 처리한다.`,
					[`징계`, `인사고과`],
					`다음 각 호의 사유로 지각하고자 하는 사원은 지각계를 사전에 제출한다.`,
					[
						`업무 외 질병∙부상`,
						`사전에 승인되지 않은 업무의 수행`,
						`기타 부득이한 사유`,
					],
					`천재지변 등의 불가피한 사유로 제3항에 의한 ${company}의 승인을 얻을 수 없는 사원은 직속상관에게 구두나 유선전화 또는 문자메세지 및 이메일로 연락을 취하고, 근무의 기산시점으로부터 1시간 이내에 지각계를 제출하여야 한다.`,
					`제4항의 사유가 정당하지 않거나 사실과 다름이 인정될 경우에는 무단지각으로 간주한다.`,
					`업무준비완료시각 이후 시업시각 이전의 출근은 지각으로 보지 않는다.`,
					`${companyUn} 무단으로 지각한 사원을 징계한다.`,
					`지각으로 인한 무노동시간은 무임금을 원칙으로 한다.`,
					`기타 필요한 사항은 별도로 정할 수 있다.`,
				],
			},
			{
				title: `조퇴계의 제출`,
				content: [
					`조퇴라 함은 시업시각으로부터 4시간 경과 이후 종업시각 이전에 퇴근함을 말한다.`,
					`4시간 근무 이전의 조퇴는 법정제도 이외의 다음 사항에서 유계결근으로 본다. 단, 근로를 제공한 시간은 유급으로 처리한다.`,
					[`징계`, `인사고과`],
					`다음 각 호의 사유로 조퇴하고자 하는 사원은 조퇴계를 사전에 제출한다.`,
					[`업무 외 질병∙부상`, `기타 부득이한 사유`],
					`조퇴를 사용한 사원은 사용된 조퇴의 정당성을 입증할 수 있는 자료를 2일 이내에 ${company}에 제출한다.`,
					`제4항의 사유가 정당하지 않거나 사실과 다름이 인정될 경우에는 무단조퇴로 간주한다.`,
					`${companyUn} 무단으로 조퇴한 사원을 징계한다.`,
					`조퇴로 인한 무노동시간은 무임금을 원칙으로 한다.`,
					`기타 필요한 사항은 별도로 정할 수 있다.`,
				],
			},
			{
				title: `외출계의 제출`,
				content: [
					`사원은 부득이한 경우를 제외하고는 사적 외출을 하지 않는다.`,
					`다음 각 호의 사유로 사적 외출을 하고자 하는 사원은 외출계를 사전에 제출한다.`,
					[`업무 외 질병∙부상`, `기타 부득이한 사유`],
					`외출을 사용한 사원은 사용된 외출의 정당성을 입증할 수 있는 자료를 2일 이내에 ${company}에 제출한다.`,
					`${companyUn} 무단으로 외출한 사원을 징계한다.`,
					`외출로 인한 무노동시간은 무임금을 원칙으로 한다.`,
					`기타 필요한 사항은 별도로 정할 수 있다.`,
				],
			},
			{
				title: `인사위원회의 구성`,
				content: [
					`사원 인사노무관리의 적정과 징계의 공정을 위하여 인사위원회를 둔다. 단, 사원의 징계를 위하여 구성하는 인사위원회는 징계위원회로 칭한다.`,
					`위원회는 대표자와 부서장 또는 그에 준하는 직급의 사원 중 대표자가 임명하는 자로 총 3명 이내로 구성한다.`,
					`위원회의 위원장은 대표자 또는 대표자의 위임한 자로 한다.`,
					`위원회는 인사(총무)담당자 1명을 간사로 둔다.`,
					`기타 필요한 사항은 별도로 정할 수 있다.`,
				],
			},
			{
				title: `표창`,
				content: [
					`사원이 다음 각 호의 하나에 해당하는 이를 심사 후에 표창할 수 있다.`,
					[
						`근무성적이 우수하고 성실하게 근무하여 타의 모범이 된 자`,
						`고객가치 창출, 제도개선, 창의적 제안 등으로 업무의 능률화 또는 예산절감에 크게 기여한 자`,
						`안전‧보건에 관한 제안이 채택된 자`,
						`안전업무처리에 공적이 현저한 자`,
						`산업재산권의 출원에 공헌한 자`,
						`경제적 가치가 있는 영업비밀의 생산 및 취득에 공헌한 자`,
						`${company} 이미지 개선에 크게 공헌한 자`,
						`기타 ${company}의 발전에 기여한 자`,
					],
					`기타 필요한 사항은 별도로 정할 수 있다.`,
				],
			},
			{
				title: `징계위원회 및 징계심의`,
				content: [
					`징계위원회의 위원장은 징계의결을 위한 회의 7일 전까지 징계위원회의 위원들에게는 회의 일시, 장소, 의제 등을, 징계대상 사원에게는 서면으로 출석통지를 각 통보한다.`,
					`징계위원회는 징계사유를 조사한 서류와 입증자료 및 당사자의 진술 등 충분한 증거를 확보하여 공정하게 심의한다. 이 경우, 징계대상자가 징계위원회에 출석을 원하지 아니하거나 서면진술을 하였을 때는 진술권포기서 또는 서면진술서를 징구하여 기록에 첨부하고 서면심사만으로 징계의결을 할 수 있다.`,
					`징계위원회의 위원이 징계대상자와 친족관계에 있거나 그 징계사유와 관계가 있을 때에는 그 위원은 그 징계의결에 관여하지 못한다.`,
					`징계위원회는 의결 전에 해당사원에게 소명할 기회를 부여한다.`,
					`징계위원회는 징계 대상자가 2회에 걸쳐 출석요구에 불응하거나 소명을 거부하는 경우 또는 소명을 포기하는 의사를 표시하는 경우에는 소명 없이 징계의결할 수 있다.`,
					`간사는 징계의결을 위한 회의에 참석하여 회의록을 작성하고 이를 보관한다.`,
					`징계위원회는 재적위원 과반수 이상 출석으로 개최되며, 출석위원 과반수 이상의 찬성으로 의결한다.`,
					`기타 필요한 사항은 별도로 정할 수 있다.`,
				],
			},
			{
				title: `재심절차`,
				content: [
					`징계처분을 받은 사원은 징계결정이 부당하다고 인정될 때 징계통보를 받은 날로부터 7일 이내에 서면으로 재심신청을 할 수 있다.`,
					`재심을 요청받은 경우 징계위원회는 10일 이내에 재심을 위한 회의를 개최하여야 하며 그 절차는 전조를 준용한다.`,
					`기타 필요한 사항은 별도로 정할 수 있다.`,
				],
			},
			{
				title: `징계 결과의 게시 및 통보`,
				content: [
					`징계 사유와 결과는 ${company}에 게시(전자적 방법을 포함) 등의 방법으로 ${company} 구성원에게 모두 알리는 것을 원칙으로 하되, ${company}의 결정에 따라 그러하지 아니할 수 있다.`,
					`징계 사유와 결과는 사원에게는 반드시 서면으로 통보한다.`,
					`기타 필요한 사항은 별도로 정할 수 있다.`,
				],
			},
			{
				title: `징계혐의자의 대기발령`,
				content: [
					`${companyUn} 사내질서를 유지하기 위하여 징계사유 조사를 위해 징계위원회 개최 전에 대기발령을 명할 수 있고, 대기발령 기간 동안에는 근로기준법 복무의무 조항에 따른 휴업수당을 지급한다. 다만, 대기발령지를 ${company}에 둘 경우에는 100% 지급한다.`,
					`${companyUn} 사내질서를 유지하기 위하여 징계사유 발생 시 징계혐의자에 대하여 징계위원회 개최 후 징계결과 통보 시까지 대기발령을 명할 수 있고, 대기발령 기간 동안에는 근로기준법 복무의무 조항에 따른 휴업수당을 지급한다. 다만, 대기발령지를 ${company}에 둘 경우에는 100% 지급한다.`,
					`기타 필요한 사항은 별도로 정할 수 있다.`,
				],
			},
			{
				title: `징계권의 위임`,
				content: [
					`사원에 대한 징계는 ${company}의 대표자가 행한다. 다만, 징계의 종류 중 경고와 견책의 징계조치는 각 부서의 장 또는 임원에게 위임할 수 있다.`,
					`제1항과 관련 징계권이 위임된 경우에 있어서는 제54조의 징계위원회는 별도로 구성하지 않을 수 있으며 제54조에서 정하는 절차를 생략할 수 있고, 징계혐의자에 대한 사실관계 확인과 대상자 소명 기회의 부여만으로 징계위원회의 구성에서 세부적인 운영절차까지를 대신할 수 있다.`,
					`기타 필요한 사항은 별도로 정할 수 있다.`,
				],
			},
			{
				title: `징계의 종류`,
				content: [
					`징계의 종류는 다음 각 호와 같다.`,
					[
						`경고 : 경고 사유와 재발 시 징계가 가중됨이 안내되는 서면 경고장을 발부한다.`,
						`견책 : 피징계 사실관계와 피징계자의 반성과 재발방지 내용이 담긴 시말서를 징구한다.`,
						`정직 : 정직의 기간은 3개월 이하로 하고, 그 기간은 사원으로서 신분은 보유하나 직무에 종사하지 못하며, 급여는 지급하지 아니한다.`,
						`해고 : 징계해고한다.`,
					],
					`제1항의 징계는 민사상 책임이나 형사상의 책임에 영향을 미치지 아니한다.`,
					`기타 필요한 사항은 별도로 정할 수 있다.`,
				],
			},
			{
				title: `경고사유`,
				content: [
					`${companyUn} 다음 각 호의 하나에 해당하는 사원에게 서면 경고장을 발부한다.`,
					[
						`조퇴`,
						[
							`가. 월 단위로 조퇴를 2회 한 때`,
							`나. 정당한 사유 없이 매월 단위로 조퇴를 1회 한 때`,
							`다. 위 가, 나를 포함하여 1년 총 조퇴일수가 6회에 다다른 경우`,
						],
						`지각`,
						[
							`가. 매월 단위로 지각을 2회 한 때`,
							`나. 정당한 사유 없이 매월 단위로 지각를 1회 한 때`,
							`다. 위 가, 나를 포함하여 1년 총 지각 횟수가 6회에 다다른 경우`,
						],
						`외출`,
						[
							`가. 매월 단위로 무단외출을 2회 한 때`,
							`나. 허가된 외출시간을 위반한 외출이 2회를 한 때`,
						],
						`복무의무`,
						[
							`가. 복무의무 조항 제1항의 권고의무의 각 호를 합한 위반 행위가 3회인 때`,
							`나. 복무의무 조항 제2항 제1호 내지 제8호의 강행의무의 각 호를 합한 위반 행위가 2회인 때`,
							`다. 복무의무 조항 제3항 제1호와 제2호의 금지행위의 위반 행위가 1회인 때`,
						],
						`기타 위와 유사한 사유가 인정될 때`,
					],
					`기타 필요한 사항은 별도로 정할 수 있다.`,
				],
			},
			{
				title: `견책사유`,
				content: [
					`${companyUn} 다음 각 호의 하나에 해당하는 사원을 견책 징계로 시말서를 징구한다.`,
					[
						`결근`,
						[
							`가. 매월 단위로 1일 이상 무단으로 결근한 때`,
							`나. 매월 단위로 2일 이상 유∙무단 불문하고 결근한 때(단, 일정한 절차에 의한 질병으로 인한 병가는 제외)`,
							`다. 가, 나를 포함하여 1년 총 결근 일수가 6회에 다다른 때`,
						],
						`조퇴`,
						[
							`가. 매월 단위로 조퇴를 3회 한 때`,
							`나. 정당한 사유 없이 매월 단위로 조퇴를 2회 한 때`,
							`다. 가, 나를 포함하여 1년 총 조퇴일수가 10회에 다다른 때`,
						],
						`지각`,
						[
							`가. 매월 단위로 지각을 3회 한 때`,
							`나. 정당한 사유 없이 매월 단위로 지각를 2회 한 때`,
							`다. 가, 나를 포함하여 1년 총 지각 횟수가 10회에 다다른 때`,
						],
						`외출`,
						[
							`가. 매월 단위로 무단외출을 3회 한 때`,
							`나. 허가된 외출시간을 위반한 외출이 3회를 이상 한 때`,
						],
						`아래 각 호의 복무의무를 위반한 경우`,
						[
							`가. 복무의무 조항 제1항의 권고의무의 각 호를 합한 위반 행위가 4회인 때`,
							`나. 복무의무 조항 제2항 제1호 내지 제8호의 강행의무의 각 호를 합한 위반 행위가 3회인 때`,
							`다. 복무의무 조항 제2항 제9호 내지 제16호의 강행의무의 각 호를 합한 위반 행위가 1회인 때`,
							`라. 복무의무 조항 제3항 제1호와 제2호의 금지행위의 위반 행위가 2회인 때`,
							`마. 복무의무 조항 제3항 제3호의 금지행위의 위반 행위가 1회인 때`,
						],
						`직무상 기한을 2회 이상 준수하지 못한 경우`,
						`본 규칙 제92조의 건강검진을 받지 않는 경우`,
						`사원 본인 월급 대비 20% 미만의 경미한 ${company}의 재산상 손실을 발생시킨 경우`,
						`부하 사원에게 업무와 무관한 개인적인 일을 명령함으로써 부하사원의 ${company} 업무 수행을 방해한 때`,
						`기타 위와 유사한 사유가 인정될 때`,
					],
					`기타 필요한 사항은 별도로 정할 수 있다.`,
				],
			},
			{
				title: `정직사유`,
				content: [
					`${companyUn} 다음 각 호의 하나에 해당하는 사원을 정직의 징계한다.`,
					[
						`월 2회 이상 무단 결근한 경우`,
						`월 6회 이상 지각, 조퇴, 외출한 경우`,
						`아래 각 호의 복무의무를 위반한 경우`,
						[
							`가. 복무의무 조항 제1항의 권고의무의 각 호를 합한 위반 행위가 5회 이상인 때`,
							`나. 복무의무 조항 제2항 제1호 내지 제8호의 강행의무의 각 호를 합한 위반 행위가 4회 이상인 때`,
							`다. 복무의무 조항 제2항 제9호 내지 제16호의 강행의무의 각 호를 합한 위반 행위가 2회 이상인 때`,
							`라. 복무의무 조항 제3항 제1호와 제2호의 금지행위의 위반 행위가 2회 이상인 때`,
							`마. 복무의무 조항 제3항 제3호의 금지행위의 위반 행위가 1회 이상인 때`,
						],
						`사원 본인 월급 대비 20% 미만의 경미한 ${company}의 재산상 손실을 발생시킨 경우가 2회 이상이거나 사원 본인 월급 대비 50% 이상 100% 미만의 ${company}의 재산상 손실을 발생시킨 경우`,
						`견책 이상의 징계를 1년에 2회 이상, 통산하여 3회 이상 받은 자로서 개전의 정이 없다고 인정된 경우`,
						`사원이 전보 또는 휴직한 때 사무인계의 해태 또는 불이행으로 손해가 발생한 경우`,
						`정기 인사고과에서 S등급, A등급, B등급, C등급, D등급 중 D등급을 3회 이상 받았을 때`,
						`부하 사원에게 업무와 무관한 개인적인 일을 명령함으로써 부하사원의 ${company} 업무 수행을 심하게 방해한 때`,
						`직장 내 괴롭힘을 한 때`,
						`근무시간 중 사적인 음주 행위를 한 때`,
						`직무상 법정 교육훈련을 이수하지 못한 경우`,
						`근무시간 중 개인적인 업무를 수행한 경우`,
						`직무상 기한을 2회 이상 준수하지 못한 경우`,
						`임원∙상사∙동료사원∙부하사원에 대한 경미한 언어폭력`,
						`임원∙상사∙동료사원∙부하사원에 대한 경미한 험담`,
						`임원∙상사∙동료사원∙부하사원에 대한 직장 내 괴롭힘`,
						`${company} 구성원 상호간의 경미한 이간질 행위`,
						`성희롱에 준하는 행위를 한 때`,
						`직장 내 성희롱 사건 처리 관련자가 사건 처리와 관련하여 알게 된 비밀을 누설한 경우`,
						`직장 상사 또는 동료사원을 (쌍방)폭행하는 행위`,
						`법인카드의 개인적 사용`,
						`시간외근로수당의 부당취득`,
						`사내정보를 이용한 부당이득`,
						`협력회사 등에 정보유출`,
						`동료사원의 임금수준 및 임금구성항목을 알려고 한 경우`,
						`거래처의 협조관계나 거래관계를 단절시킨 원인이 된 경우`,
						`본 규칙 제92조의 건강검진을 받지 않는 경우가 2회 이상인 경우`,
						`허위보고를 한 때`,
						`사회통념상 담당업무에 요구되는 업무능력의 부족`,
						`3개월의 업무수행능력이 동일·유사한 업무를 담당하는 사원의 평균 업무수행능력(영업직은 영업 실적)의 30% 이하로 떨어진 경우`,
						`제1심에서 집행유예 6개월 미만의 형사처벌을 받은 사원`,
						`고용보험법 시행규칙 별표 2의 수급자격이 제한되지 아니하는 정당한 이직사유에 해당하거나 이에 준하는 사유가 아님에도 불구하고 근로계약서 및 임금계약서 작성을 거부하거나 제출하지 아니하는 행위가 발생한 경우`,
						`고용보험법 시행규칙 별표 2의 수급자격이 제한되지 아니하는 정당한 이직사유에 해당하거나 이에 준하는 사유가 아님에도 불구하고 ${company}의 정당한 인사명령을 거부하는 행위가 발생한 경우`,
						`수습기간에 대한 인사고과에서 S등급, A등급, B등급, C등급, D등급 중 C등급이나 D등급을 받은 경우`,
						`정기 인사고과에서 S등급, A등급, B등급, C등급, D등급 중 D등급을 연속하여 2회를 받았을 때`,
						`기타 위와 유사한 사유가 인정될 때`,
					],
					`기타 필요한 사항은 별도로 정할 수 있다.`,
				],
			},
		];

		// if (['대한민국', '한국', '일본']?.includes(company)) {
		// 	const additionalSection = {
		// 		title: `소정근로시간`,
		// 		content: [
		// 			`사원의 소정근로시간은 아래와 같다.`,
		// 			[
		// 				`1일 소정근로시간은 휴게시간을 제외하고 8시간을 원칙으로 하되, 단시간 사원의 경우 1일 8시간을 한도로 근로계약에 의거 소정근로시간을 달리 정할 수 있다.`,
		// 				`1주 소정근로시간은 40시간을 원칙으로 하되, 단시간 사원의 경우 1주 40시간 한도로 근로계약에 의거 소정근로시간을 달리 정할 수 있다.`,
		// 			],
		// 			`15세 이상 18세 미만인 사원의 소정근로시간은 아래와 같다.`,
		// 			[
		// 				`1일 소정근로시간은 휴게시간을 제외하고 7시간을 원칙으로 하되, 단시간 사원의 경우 1일 7시간을 한도로 근로계약에 의거 소정근로시간을 달리 정할 수 있다.`,
		// 				`1주 소정근로시간은 35시간을 원칙으로 하되, 단시간 사원의 경우 1주 35시간 한도로 근로계약에 의거 소정근로시간을 달리 정할 수 있다.`,
		// 			],
		// 			`1주 소정근로시간은 월요일의 시업시각부터 기산하고, 1월 소정근로시간은 매월 1일의 시업시각부터 기산한다.`,
		// 			`경영상 필요한 경우 근로계약서 또는 임금 계약서의 작성을 통하여 기존에 제1항 및 제2항의 규정의 적용받는 것과 달리 1일 또는 1주 소정근로시간을 약정할 수 있다.`,
		// 			`고용노동부장관의 승인을 받은 감시적, 단속적 근로에 종사하는 자(경비직, 시설관리직, 운전기사 등) 및 관리, 감독업무 또는 기밀 사무를 취급하는 자는 본 규칙에서 정한 근로시간, 휴게와 휴일에 관한 규정은 적용하지 아니한다.`,
		// 			`기타 필요한 사항은 별도로 정할 수 있다.`,
		// 		],
		// 	};
		// 	baseSections.splice(1, 0, additionalSection); // "목적" 다음에 "소정근로시간" 삽입
		// }

		const excludedCompanies = ['주식회사 허브601'];

		// 조건에 따라 섹션 제거
		if (excludedCompanies?.includes(company)) {
			// "title"이 여러 개일 때 제거할 섹션 목록
			const excludedTitles = ['영업비밀 관리위원회'];

			baseSections = baseSections.filter(
				(section) => !excludedTitles?.includes(section.title)
			);
		}
		const numOfWorkers = profile?.numOfWorkers;
		// 조건에 따라 섹션 제거
		if (numOfWorkers < 30) {
			// "title"이 여러 개일 때 제거할 섹션 목록
			const excludedTitles = ['채용 절차의 공정화에 관한 법률 이행'];

			baseSections = baseSections.filter(
				(section) => !excludedTitles?.includes(section.title)
			);
		}

		return baseSections;
	}, [serviceStartDate, company, companyUn, companyGa, profile]);

	useEffect(() => {
		setSectionCount(sections.length);
	}, [sections, setSectionCount]);

	const [openSections, setOpenSections] = useState({});

	useEffect(() => {
		const newOpenSections = sections.reduce((acc, section) => {
			acc[section?.title] = allOpen;
			return acc;
		}, {});
		setOpenSections(newOpenSections);
	}, [allOpen, sections]);

	const toggleSection = (section) => {
		setOpenSections((prevState) => ({
			...prevState,
			[section]: !prevState[section],
		}));
	};

	return (
		<>
			<div className='jang sectionup'>제4장 복무제도</div>
			{sections.map((section, index) => (
				<div key={index}>
					<RuleSection
						number={
							index +
							1 +
							previousSectionCount1 +
							previousSectionCount2 +
							previousSectionCount3
						}
						title={section?.title}
						content={section?.content}
						isOpen={!!openSections[section?.title]}
						toggleOpen={() => toggleSection(section?.title)}
						amended={section?.amended}
					/>
				</div>
			))}
		</>
	);
}
