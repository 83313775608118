import { setConversations, setConversationHistory } from "../eventActions";
import { store } from "../../../index";

const BASE_URL = process.env.REACT_APP_API_SERVER_URL;
// const BASE_URL = "http://localhost:5000";

export const connectWithRestfulApiServer = async (currentUserProfile) => {
  const userIdentifier = currentUserProfile?.id;
  const companyName = currentUserProfile?.companyName || "Unknown Company";
  
  // 세션 및 대화 목록 요청
  try {
    const response = await fetch(`${BASE_URL}/session-history`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ userIdentifier, companyName }),
    });

    if (!response.ok) {
      // 서버 응답이 성공적이지 않은 경우, 에러 처리
      throw new Error(`Server responded with status: ${response.status}`);
    }
  
    const data = await response.json();
    
    // 대화 목록 설정
    const { conversations } = data;
    store.dispatch(setConversations(conversations));
    
    // 추가적으로 필요한 대화 상세 정보 요청 등을 여기에 구현
  } catch (error) {
    console.error("Failed to fetch session history:", error);
  }
}

// 대화 세부 정보 가져오기
export const fetchConversationDetails = async (conversationId) => {
  try {
    const response = await fetch(`${BASE_URL}/conversation-details/${conversationId}`);
    const conversationDetails = await response.json();
    
    // 대화 세부 정보를 상태 관리 시스템에 저장
    store.dispatch(setConversationHistory(conversationDetails));
  } catch (error) {
    console.error("Failed to fetch conversation details:", error);
  }
}

// 대화 메시지 전송
export const sendConversationMessage = async (message, conversationId, currentUserProfile) => {
  const userIdentifier = currentUserProfile?.id;
  const companyName = currentUserProfile?.companyName || "Unknown Company";
  
  try {
    await fetch(`${BASE_URL}/conversation-message`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        userIdentifier,
        message,
        conversationId,
        companyName,
      }),
    });
  } catch (error) {
    console.error("Failed to send conversation message:", error);
  }
}

// 대화 목록 삭제
export const deleteConversations = async ({currentUserProfile}) => {
  const userIdentifier = currentUserProfile?.id;
  try {
    await fetch(`${BASE_URL}/conversations-delete`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ userIdentifier }),
    });
  } catch (error) {
    console.error("Failed to delete conversations:", error);
  }
}