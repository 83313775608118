import React, { useEffect, useState } from 'react';
import { Form, Formik } from 'formik';
import { Button } from 'semantic-ui-react';
import * as Yup from 'yup';
import { toast } from 'react-toastify';
import MySelectInput from '../../../app/common/form/MySelectInput';
import { month, copyYear } from '../../../app/api/dropdownOption';
import axios from 'axios'; // 필요에 따라 설치해야 할 수 있습니다.
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';

export default function PaydocuCopy({companyId}) {
  const history = useHistory();
  const [isCopying, setIsCopying] = useState(false);
  const [isMounted, setIsMounted] = useState(true);

  const API_BASE_URL = process.env.REACT_APP_API_SERVER_URL; // 실제 서버 URL로 변경하세요.

  useEffect(() => {
    // 컴포넌트 마운트 시 isMounted를 true로 설정
    setIsMounted(true);

    // cleanup 함수에서 isMounted를 false로 설정
    return () => {
      setIsMounted(false);
    };
  }, []);

  const copyPayrollData = (sourceYear, sourceMonth, targetYear, targetMonth) => {

    const sourceYearMonth = sourceYear + sourceMonth;
    const targetYearMonth = targetYear + targetMonth;

    const confirmCopy = window.confirm(`${sourceYear}년 ${sourceMonth}월을 ${targetYear}년 ${targetMonth}월로 전체 복사하시겠습니까?`); // Show confirmation dialog
    if (!confirmCopy) return;

    setIsCopying(true);

    const postData = {
      sourceMonth: sourceYearMonth,
      targetMonth: targetYearMonth,
      companyId: companyId
    };

    axios.post(`${API_BASE_URL}/copyPayrollData`, postData)
      .then((result) => {
        if (isMounted) {
          toast.success(result.data.message);
          // window.location.reload(); // Refresh the page after successful copy
          history.push('/paydocus');
        }
      })
      .catch((error) => {
        if (isMounted) {
          console.error('Error:', error);
          toast.error('급여 데이터 복사 중 오류가 발생했습니다.');
        }
      })
      .finally(() => {
        if (isMounted) {
          setIsCopying(false);
        }
      });
  };

  return (
    <>
      <Formik
        initialValues={{
          sourceYear: '',
          sourceMonth: '',
          targetYear: '',
          targetMonth: ''
        }}
        validationSchema={Yup.object({
          sourceYear: Yup.string().required('복사할 원본 연도를 선택해주세요.'),
          sourceMonth: Yup.string().required('복사할 원본 월을 선택해주세요.'),
          targetYear: Yup.string().required('복사될 대상 연도를 선택해주세요.'),
          targetMonth: Yup.string().required('복사될 대상 월을 선택해주세요.')
        })}
        onSubmit={(values, {setSubmitting}) => {
          try {
            copyPayrollData(values.sourceYear, values.sourceMonth, values.targetYear, values.targetMonth);
            setSubmitting(false);
          } catch (error) {
            console.log(error.message);
            toast.error(error.message);
            setSubmitting(false);
          }
        }}
      >
        {({values, isSubmitting, isValid}) => (
          <>
            <Form className='ui form' style={{ display: 'flex', justifyContent: 'space-between' }}>
              <div className='margin' style={{width: '19.5%', marginRight: '3px'}}>
                <MySelectInput 
                  label='복사할 원본 연도' 
                  name='sourceYear' 
                  options={copyYear.map(option => ({
                    ...option,
                    text: String(option.text),
                  }))}
                />
              </div>
              <div className='margin' style={{width: '19.5%', marginRight: '3px'}}>
                <MySelectInput 
                  label='복사할 원본 월' 
                  name='sourceMonth' 
                    options={month.map(option => ({
                    ...option,
                    text: String(option.text),
                  }))}
                />
              </div>
              <div className='margin' style={{width: '19.5%', marginRight: '3px'}}>
                <MySelectInput 
                  label='복사될 대상 연도' 
                  name='targetYear' 
                  options={copyYear.map(option => ({
                    ...option,
                    text: String(option.text),
                  }))}
                />
              </div>
              <div className='margin' style={{width: '19.5%', marginRight: '3px'}}>
                <MySelectInput 
                  label='복사될 대상 월' 
                  name='targetMonth' 
                    options={month.map(option => ({
                    ...option,
                    text: String(option.text),
                  }))}
                />
              </div>
              <Button
                  loading={isCopying || isSubmitting}
                  disabled={isCopying || isSubmitting || !isValid}
                  floated='left'
                  type='submit'
                  size='large'
                  positive
                  content='월 전체 급여 복사'
                />
            </Form>
            * 월 전체 복사 기능 <p style={{color:'red'}}>(단, 퇴직자, 일용직, 시급직은 복사되지 않습니다.)</p>
          </>
        )}
      </Formik>
      <hr />
    </>
  );
}
