import React from 'react';
import { Link } from 'react-router-dom';
import { Label } from 'semantic-ui-react';

export default function ClientReqLastPreventData({
	lastPreventData,
	authLevel,
}) {
	return (
		<>
			{authLevel < 30 ? (
				<>
					<Label
						style={{ cursor: 'pointer' }}
						as={Link}
						to={`/pricePolicyPaper`}>
						고용안정지원금을 위한 감원방지기간 안내는 PREMIUM 서비스에서
						제공됩니다.
					</Label>
					<hr />
				</>
			) : (
				<>
					<Label>{lastPreventData ? lastPreventData[3] : null}</Label>
					<Label>{lastPreventData ? lastPreventData[4] : null}</Label>
					<Label>{lastPreventData ? lastPreventData[5] : null}</Label>
					<hr />
				</>
			)}
		</>
	);
}
