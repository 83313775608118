import React from 'react'
import { Label, Table } from 'semantic-ui-react'

export default function OpenDetailBasicInfoListItem4In({
  youthSupportFundPerson,
  replacedWorkerFundPerson,
  isDayWorker,
  isTimeWorker,
  daylyTimePay,
  Item,
  decryptoClientReq,
  isExceptTimeLawWorker,
  numOfWorkers,
  enterDate,
  numberToCommaString,
  daylyPay,
  retiredDate,
  formatDateWithMoment,
  totalMonthWageAmount,
  roundUp2,
  deductWage,
  calHourLowestWage,
  fixedMonthRealWorkingHoursETL,
  isEtcWorker,
  roundUp,
  mTPCTime
}) {
  const supportFundNotice = youthSupportFundPerson  === '2024 청년 일자리 도약 장려금 후보' || replacedWorkerFundPerson === '육아 관련 대체인력지원금 후보' ? true : false
  return (
    <>
      {isDayWorker 
        ?
          <Item.Description>
            <Table>
              <Table.Header>
                <Table.Row>
                  <Table.HeaderCell>성명 </Table.HeaderCell>
                  <Table.HeaderCell>근무시작일</Table.HeaderCell>
                  <Table.HeaderCell>담당업무</Table.HeaderCell>
                  {isTimeWorker ? <Table.HeaderCell>시급</Table.HeaderCell> : <Table.HeaderCell>일급</Table.HeaderCell>}
                </Table.Row>
              </Table.Header>
              <Table.Body>
                <Table.Row>
                  <Table.Cell>{decryptoClientReq.worker?.name}</Table.Cell>
                  <Table.Cell>{enterDate}</Table.Cell>
                  <Table.Cell>{decryptoClientReq.workField ? decryptoClientReq.workField : decryptoClientReq.workDetailField}</Table.Cell>
                  {isTimeWorker ? <Table.Cell>{numberToCommaString(daylyTimePay)}원</Table.Cell> : <Table.Cell>{numberToCommaString(daylyPay)}원</Table.Cell>}
                </Table.Row>
              </Table.Body>
            </Table>
          </Item.Description>
        :
        <>
          {supportFundNotice ? 
            <>
              <Label>고용안정지원금은 회사요건, 감원방지기간 등 검토할 조건이 까다롭고, 잘못 신청시 행정처분의 대상이 됩니다.</Label>
              <Label color='orange'>반드시 고용노동부(고용안정센터)나 자문노무사에게 문의 후 신중한 신청할 것을 권고드립니다.</Label>
            </>
            : null}
          <Item.Description>
            <Table style={{textAlign : 'center'}}>
              <Table.Header>
                <Table.Row>
                  {decryptoClientReq?.retiredDate ? 
                    <Table.HeaderCell style={{color:'red'}}>퇴사자</Table.HeaderCell>
                  :
                    <Table.HeaderCell>성명</Table.HeaderCell>
                  }
                  <Table.HeaderCell>입사일</Table.HeaderCell>
                  {decryptoClientReq?.retiredDate ? 
                    <Table.HeaderCell>퇴직일</Table.HeaderCell>
                  :
                    <Table.HeaderCell>계약종료일</Table.HeaderCell>
                  }
                  <Table.HeaderCell>부서</Table.HeaderCell>
                  <Table.HeaderCell>직위</Table.HeaderCell>
                  <Table.HeaderCell>월급계</Table.HeaderCell>
                  {/* <Table.HeaderCell>시급(통상임금)</Table.HeaderCell> */}
                  <Table.HeaderCell>실수령액</Table.HeaderCell>
                  <Table.HeaderCell>시급(최저임금법)</Table.HeaderCell>
                  {isExceptTimeLawWorker ? <Table.HeaderCell>실근로시간</Table.HeaderCell> : numOfWorkers >= 5 ? <Table.HeaderCell>소정근로시간</Table.HeaderCell> : <Table.HeaderCell>실근로시간</Table.HeaderCell>}
                  <Table.HeaderCell>* 월총유급시간</Table.HeaderCell>
                </Table.Row>
              </Table.Header>
              <Table.Body>
                <Table.Row>
                  <Table.Cell>
                    {decryptoClientReq?.worker?.name}
                  </Table.Cell>
                  <Table.Cell>{enterDate}</Table.Cell>
                  {decryptoClientReq?.retiredDate ? 
                    <Table.Cell>{retiredDate}</Table.Cell>
                    :
                    <Table.Cell>{decryptoClientReq?.contractEndDate ? `${formatDateWithMoment(decryptoClientReq?.contractEndDate)}` : '정년'}</Table.Cell>
                  }
                  <Table.Cell>{decryptoClientReq?.workerDivisions}</Table.Cell>
                  <Table.Cell>{decryptoClientReq?.workerTitles}</Table.Cell>
          
                  <Table.Cell>{totalMonthWageAmount}원</Table.Cell>
                  {/* <Table.Cell>{numberToCommaString(roundUp(hourOrdinaryWage))}원</Table.Cell> */}
                  <Table.Cell>{numberToCommaString(roundUp2(deductWage))}원 </Table.Cell>
                  <Table.Cell>{numberToCommaString(roundUp2(calHourLowestWage))}원</Table.Cell>
                  <>
                    {isExceptTimeLawWorker ? <Table.Cell>주 {roundUp2(fixedMonthRealWorkingHoursETL / 4.35)} 시간</Table.Cell> :
                      <>
                        {numOfWorkers >= 5 
                          ? 
                            <>
                              {isEtcWorker 
                                ? 
                                  <Table.Cell>1주 {roundUp(decryptoClientReq?.etc?.weekLBTime)}시간</Table.Cell> 
                                :
                                  <Table.Cell>1주 {roundUp(decryptoClientReq?.lawBase?.weekLBTime)}시간</Table.Cell> 
                              }
                            </>
                          : 
                            <Table.Cell>1주 {roundUp(decryptoClientReq?.lawBase?.weekLBTime)}시간</Table.Cell>
                        } 
                      </>
                    }
                  </>
                  <Table.Cell>{mTPCTime}</Table.Cell>                                  
                </Table.Row>
              </Table.Body>
            </Table>
            <p style={{marginTop : '5px'}}>* (통상임금 + 법정가산수당) / 총유급시간 = 통상시급(소수점 처리상 표시되는 최저시급과 근소한 차이가 있을 수 있습니다.)</p>
          </Item.Description>
          {decryptoClientReq.worker?.memo && 
            <>
              <hr />
              <span style={{color: 'blue'}}>특이사항 등 메모 : </span>  <span>{decryptoClientReq.worker?.memo}</span>
            </>
          }
        </>
      }
    </>
  )
}
