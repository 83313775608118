import * as moment from 'moment';

//특정일 기준 기본연차
export function calALSpecificBagicYear(enterDate, workersNumber, specificDate = null) {
  const enterD = moment(enterDate, 'YYYY-MM-DD');
  const executeSpecificDate = moment(specificDate, 'YYYY-MM-DD');
  const emplPeriodY = executeSpecificDate.diff(enterD, 'years');
  return parseInt(workersNumber) < 5 ? 0 : emplPeriodY >= 1 ? 15 : 0;
}

//특정일 기준 가산연차
export function calALSpecificPlusYear(enterDate, workersNumber, specificDate) {
  const enterD = moment(enterDate, 'YYYY-MM-DD');
  const executeSpecificDate = moment(specificDate, 'YYYY-MM-DD');
  const emplPeriodY = executeSpecificDate.diff(enterD, 'years');
  const sumPlus = emplPeriodY >= 3 ? Math.floor((emplPeriodY - 1) / 2) : 0;
  return parseInt(workersNumber) < 5 ? 0 : sumPlus >= 10 ? 10 : sumPlus;
}

//특정일 기준 월 단위 연차
export function calALSpecificMonth(enterDate, workersNumber, specificDate) {
  const enterD = moment(enterDate, 'YYYY-MM-DD');
  const executeSpecificDate = specificDate ? moment(specificDate, 'YYYY-MM-DD') : moment();
  const emplPeriodY = executeSpecificDate.diff(enterD, 'years');
  const emplPeriodD = executeSpecificDate.diff(enterD, 'month');
  return parseInt(workersNumber) < 5 ? 0 : emplPeriodY < 1 ? emplPeriodD : 0;
}


export function calALMonth(enterDateReal, workersNumber, specificDate = null) {

  const executeDate = specificDate ? moment(specificDate, 'YYYY-MM-DD') : moment(); 
  const enterDate = moment(enterDateReal, 'YYYY-MM-DD')
  const enterYear = moment(enterDate, 'YYYY-MM-DD').format('yyyy')
  const enterYearEnd = moment(enterYear + '-12-31', 'YYYY-MM-DD')

  const enterPlusOneYearStart = moment(enterYearEnd).add(1,'days')
  const enterPlusOneYearEnd = moment(enterYearEnd).add(1,'year')

  const oneYearWorker = executeDate.isBetween(enterDate, enterYearEnd, undefined, "[]")
  const secondYearWorker = executeDate.isBetween(enterYearEnd, enterPlusOneYearEnd, undefined, "(]")  
  
  let firstYearMo;
  if (oneYearWorker) {
    firstYearMo = executeDate.diff(enterDate, 'month')
  } else if (secondYearWorker) {
    firstYearMo = enterYearEnd.diff(enterDate, 'month')
  } else {
    firstYearMo = 0
  }
  
  let secondYearMo;
  if (secondYearWorker) {
    // enterPlusOneYearStart로부터 executeDate까지의 완전한 달 수를 계산
    const monthsDiff = executeDate.diff(enterPlusOneYearStart, "months");
    // 완전한 달의 마지막 날짜를 확인
    const fullMonthDate = enterPlusOneYearStart.clone().add(monthsDiff, "months");
    // executeDate가 fullMonthDate의 다음 달에 위치하는지 확인
    secondYearMo = executeDate.isBefore(fullMonthDate) ? monthsDiff : monthsDiff + 1;
  
    // 첫 해가 11개월인 경우, 두 번째 해 근무 개월은 0으로 처리
    if (firstYearMo === 11) {
      secondYearMo = 0;
    }
    // 첫 해와 두 번째 해의 합이 11을 초과하지 않도록 조정
    else if (firstYearMo + secondYearMo > 11) {
      secondYearMo = 11 - firstYearMo;
    }
  } else {
    secondYearMo = 0;
  }
  
  let result
  if (secondYearWorker) {
    result = secondYearMo
  } else if (oneYearWorker) {
    result = firstYearMo
  } else {
    result = 0
  }

  return parseInt(workersNumber) < 5 ? 0 : [result, firstYearMo, secondYearMo];
}

//오늘 기준 법정 연차 합계
export function calALSpecificTotal(enterDate, workersNumber, specificDate) {
  return calALSpecificBagicYear(enterDate, workersNumber, specificDate)
    + calALSpecificPlusYear(enterDate, workersNumber, specificDate)
    + calALSpecificMonth(enterDate, workersNumber, specificDate);
}

export function calALSpecificRemain(enterDate, workersNumber, usedAL, specificDate) {
  return calALSpecificBagicYear(enterDate, workersNumber, specificDate)
    + calALSpecificPlusYear(enterDate, workersNumber, specificDate)
    + calALSpecificMonth(enterDate, workersNumber, specificDate)
    - usedAL;
}