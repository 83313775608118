import React, { useEffect } from 'react';
import { Table } from 'semantic-ui-react';
import {
	commaStringToNumber,
	convertObjectToText,
	daylyWorkerTotalMonthWageAmountF,
	holiBasicTimeText,
	holiBTimePaidWageF,
	holiNightTimeText,
	holiNTimePaidWageF,
	holiOTimePaidWageF,
	holiOverTimeText,
	nightTimeText,
	numberToCommaString,
	overTimeText,
	roundUp,
	roundUp2,
	workDayNTimePaidWageF,
	workDayOTimePaidWageF,
} from '../../../../../../../app/common/util/util';

import ClientReqDLCWageTableDayWorkerDeductDetail from '../deductDetail/ClientReqDLCWageTableDayWorkerDeductDetail';
import ClientReqDLCWageTableDayWorkerDetailAWage5 from './ClientReqDLCWageTableDayWorkerDetailAWage5';
import ClientReqDLCWageTableDayWorkerDetailAWage6 from './ClientReqDLCWageTableDayWorkerDetailAWage6';
import ClientReqDLCWageTableDayWorkerDetailAWage7 from './ClientReqDLCWageTableDayWorkerDetailAWage7';

export default function ClientReqDLCWageTableDayWorkerPayDetail({
	dayWorkerTaxText,
	dayWorkerDayBasicWorkingTime,
	weekBLTimeTWThisMonth,
	setWeekBLTimeTWThisMonth,
	isDayOrWorker,
	weekBLTimeTW,
	setWeekBLTimeTW,
	finalPaidWeekObj,
	lawBasePaidWeekObj,
	finalPaidHoliWageTimeSum,
	lawBaseWorkDatesWorkHourThisMonthOnlySum,
	week14OverBLTime,
	timeWorkerPlusMonthOWT,
	timeWorkerPlusMonthNWT,
	timeWorkerPlusMonthHoliBasicTime,
	timeWorkerPlusMonthOWTHoli,
	timeWorkerPlusMonthOffBasicTime,
	timeWorkerPlusMonthOWTOff,

	values,
	initialTax,
	dayWorkerTax,
	dayWokerTimeWage,
	healthInsureOver60DayWorker,
	constructOrNot,
	dayWorkerWage,
	dayWorkerHoliWage,
	dayWorkerOverWorkDayWage,
	countOfNomalDayWork,
	countOfOverWorkingDayWork,
	countOfHoliDayWork,
	thisYear,
	thisMonth,
	handelInputChange,
	numOfWorkers,
	inputWageStyle,
	centerStyle,
	gumakStyle,
	inputCommentStyle,

	dayWorkerPlusMonthOWT,
	dayWorkerPlusMonthNWT,
	dayWorkerPlusMonthHoliBasicTime,
	dayWorkerPlusMonthOWTHoli,
	dayWorkerPlusMonthNWTHoli,

	dayWorkerCAWage5,
	dayWorkerCAWEName5,
	dayWorkerCAWage5Comment,
	dayWorkerCAWage6,
	dayWorkerCAWEName6,
	dayWorkerCAWage6Comment,
	dayWorkerCAWage7,
	dayWorkerCAWEName7,
	dayWorkerCAWage7Comment,
	dayWorkerDeductEtcName1,
	dayWorkerDeductEtcWage1,
	dayWorkerDeductEtcWage1Comment,
	dayWorkerDeductEtcName2,
	dayWorkerDeductEtcWage2,
	dayWorkerDeductEtcWage2Comment,
	dayWorkerDeductEtcName3,
	dayWorkerDeductEtcWage3,
	dayWorkerDeductEtcWage3Comment,

	dayWorkerUserFixedTax,
	dayWorkerUserFixedRegionTax,
	dayWorkerUserFixedHealthCare,
	dayWorkerUserFixedOldCare,
	dayWorkerUserFixedPension,
	dayWorkerUserFixedNoJobInsurance,

	dayWorkerAbsentTimes,
	inputTax,

	dayWorkerPlusDeductHealthCare,
	dayWorkerPlusDeductOldCare,
	dayWorkerPlusDeductWorkTax,
	dayWorkerPlusDeductWorkRegionTax,
	dayWorkerPlusDeductDuru,
	dayWorkerPlusMinus4Ins,
	dayWorkerPlusDeductWorkerCompanyLoan,

	dayWorkerPlusDeductHealthCareComment,
	dayWorkerPlusDeductOldCareComment,
	dayWorkerPlusDeductWorkTaxComment,
	dayWorkerPlusDeductWorkRegionTaxComment,
	dayWorkerPlusMinus4InsComment,
	dayWorkerPlusDeductWorkerCompanyLoanComment,
	daylyTimePay,
	timeWorkerTimeWage,
	timeWorkerTimePaidHoliWage,
	monthTotalWorkTime,
	normalWorkerWeekWorkDays,
	filteredSelectedDays,
	dayWorkerSpecialBonus,
	timeWorkerSpecialBonus,
	netAgeEntered,
}) {
	const dayWorkerPlusOverTimeValue = workDayOTimePaidWageF(
		0,
		commaStringToNumber(dayWorkerPlusMonthOWT),
		dayWokerTimeWage,
		numOfWorkers
	);
	const dayWorkerPlusOverTimeText = overTimeText(
		0,
		commaStringToNumber(dayWorkerPlusMonthOWT),
		dayWokerTimeWage,
		numOfWorkers
	);

	const dayWorkerPlusNightTimeValue = workDayNTimePaidWageF(
		0,
		commaStringToNumber(dayWorkerPlusMonthNWT),
		dayWokerTimeWage,
		numOfWorkers
	);
	const dayWorkerPlusNightTimeText = nightTimeText(
		0,
		commaStringToNumber(dayWorkerPlusMonthNWT),
		dayWokerTimeWage,
		numOfWorkers
	);

	const dayWorkerPlusHoliBTimeValue = holiBTimePaidWageF(
		0,
		commaStringToNumber(dayWorkerPlusMonthHoliBasicTime),
		dayWokerTimeWage,
		numOfWorkers
	);
	const dayWorkerPlusHoliBTimeText = holiBasicTimeText(
		0,
		commaStringToNumber(dayWorkerPlusMonthHoliBasicTime),
		dayWokerTimeWage,
		numOfWorkers
	);

	const dayWorkerPlusHoliOTimeValue = holiOTimePaidWageF(
		0,
		commaStringToNumber(dayWorkerPlusMonthOWTHoli),
		dayWokerTimeWage,
		numOfWorkers
	);
	const dayWorkerPlusHoliOTimeText = holiOverTimeText(
		0,
		commaStringToNumber(dayWorkerPlusMonthOWTHoli),
		dayWokerTimeWage,
		numOfWorkers
	);

	const dayWorkerPlusHoliNTimeValue = holiNTimePaidWageF(
		0,
		commaStringToNumber(dayWorkerPlusMonthNWTHoli),
		dayWokerTimeWage,
		numOfWorkers
	);
	const dayWorkerPlusHoliNTimeText = holiNightTimeText(
		0,
		commaStringToNumber(dayWorkerPlusMonthNWTHoli),
		dayWokerTimeWage,
		numOfWorkers
	);

	const totalMonthWageInfo = daylyWorkerTotalMonthWageAmountF(
		week14OverBLTime,
		monthTotalWorkTime,
		timeWorkerTimeWage,
		timeWorkerTimePaidHoliWage,
		countOfNomalDayWork,
		dayWorkerWage,
		countOfHoliDayWork,
		dayWorkerHoliWage,
		countOfOverWorkingDayWork,
		dayWorkerOverWorkDayWage,
		dayWorkerPlusMonthOWT,
		dayWokerTimeWage,
		numOfWorkers,
		dayWorkerPlusMonthNWT,
		dayWorkerPlusMonthHoliBasicTime,
		dayWorkerPlusMonthOWTHoli,
		dayWorkerPlusMonthNWTHoli,
		dayWorkerCAWage5,
		dayWorkerCAWage6,
		dayWorkerCAWage7,
		dayWorkerAbsentTimes,
		timeWorkerPlusMonthOWT,
		timeWorkerPlusMonthNWT,
		timeWorkerPlusMonthHoliBasicTime,
		timeWorkerPlusMonthOWTHoli,
		timeWorkerPlusMonthOffBasicTime,
		timeWorkerPlusMonthOWTOff,
		finalPaidWeekObj,
		finalPaidHoliWageTimeSum,
		normalWorkerWeekWorkDays,
		daylyTimePay,
		lawBasePaidWeekObj,
		lawBaseWorkDatesWorkHourThisMonthOnlySum,
		commaStringToNumber(timeWorkerSpecialBonus),
		commaStringToNumber(dayWorkerSpecialBonus)
	);

	const totalCountOfWorkingDay = totalMonthWageInfo?.totalCountOfWorkingDay;
	const totalMonthWageAmount = totalMonthWageInfo?.totalMonthWageAmount;

	const finalWeekPaidBLTime = totalMonthWageInfo?.finalWeekPaidBLTime;
	const lawBaseWeekPaidBLTimeThisMonth =
		totalMonthWageInfo?.lawBaseWeekPaidBLTimeThisMonth;

	const totalTimeWorkerWage = totalMonthWageInfo?.totalTimeWorkerWage;
	const totalTimeWorkerPaidHoliWage =
		totalMonthWageInfo?.totalTimeWorkerPaidHoliWage;

	const totalDayWorkerWage = totalMonthWageInfo?.totalDayWorkerWage;
	// const totalDayWorkerPaidHoliWage = lawBaseWeekPaidBLTimeThisMonth >= 16 ? 8 * lawBaseWeekPaidBLTimeThisMonth : 0;

	const totalDayWorkerHoliWage = totalMonthWageInfo?.totalDayWorkerHoliWage;
	const totalDayWorkerOverWorkDayWage =
		totalMonthWageInfo?.totalDayWorkerOverWorkDayWage;

	const numdayWorkerAbsentTimes = totalMonthWageInfo?.numdayWorkerAbsentTimes;
	const absentTimeWage = totalMonthWageInfo?.absentTimeWage;

	const numOWageTimeW = totalMonthWageInfo?.numOWageTimeW;
	const numNWageTimeW = totalMonthWageInfo?.numNWageTimeW;
	const numHBWageTimeW = totalMonthWageInfo?.numHBWageTimeW;
	const numHOWageTimeW = totalMonthWageInfo?.numHOWageTimeW;
	const numOffWageTimeW = totalMonthWageInfo?.numOffWageTimeW;
	const numOffOWageTimeW = totalMonthWageInfo?.numOffOWageTimeW;

	const paidHoliWageDays = totalMonthWageInfo?.paidHoliWageDays;
	const finalPaidHoliWageTime = totalMonthWageInfo?.finalPaidHoliWageTime;
	const numPaidHoliTimeW = totalMonthWageInfo?.numPaidHoliTimeW || 0;
	const numPaidHoliDayW = totalMonthWageInfo?.numPaidHoliDayW || 0;

	const paidHoliWageText = '(' + convertObjectToText(finalPaidWeekObj) + ')';
	// const totalTimeWorkerWageWithSpecialBonus = totalMonthWageInfo?.totalTimeWorkerWageWithSpecialBonus
	// const totalDayWorkerMonthWageWithSpecialBonus = totalMonthWageInfo?.totalDayWorkerMonthWageWithSpecialBonus

	useEffect(() => {
		setWeekBLTimeTW(finalWeekPaidBLTime);
	}, [setWeekBLTimeTW, finalWeekPaidBLTime]);

	useEffect(() => {
		setWeekBLTimeTWThisMonth(lawBaseWeekPaidBLTimeThisMonth);
	}, [lawBaseWeekPaidBLTimeThisMonth, setWeekBLTimeTWThisMonth]);

	return (
		<>
			<>
				<Table.Row>
					<Table.Cell colSpan='9'>{` `}</Table.Cell>
				</Table.Row>
				<Table.Row>
					<Table.Cell textAlign='left' colSpan='9'>
						<span
							style={{
								fontSize: '1.2rem',
								color: 'darkcyan',
								fontWeight: 'bold',
							}}>
							3. 월급 구성항목 및 계산방법
						</span>
					</Table.Cell>
				</Table.Row>
				{daylyTimePay > 0 ? (
					<>
						<Table.Row>
							<Table.Cell className='gubun' style={centerStyle}>
								기본임금
							</Table.Cell>
							<Table.Cell className='hangmok' style={centerStyle}>
								임금
							</Table.Cell>
							<Table.Cell className='gumak' style={gumakStyle}>
								{numberToCommaString(roundUp(totalTimeWorkerWage))}
							</Table.Cell>
							<Table.Cell colSpan='6' className='sulmung'>
								통상시급 {numberToCommaString(roundUp(timeWorkerTimeWage))}원 *
								총 기본근무시간 {Math.floor(monthTotalWorkTime * 100) / 100}시간
								{<br />}
								(근로를 제공한 모든 일자(휴무일과 휴일이 포함된 경우 이를
								포함)의 1일 8시간 이내의 모든 근로)
							</Table.Cell>
						</Table.Row>
						{week14OverBLTime === '주휴수당 포함' ? (
							<Table.Row>
								<Table.Cell className='gubun' style={centerStyle}>
									주휴수당
								</Table.Cell>
								<Table.Cell className='hangmok' style={centerStyle}>
									임금
								</Table.Cell>
								<Table.Cell className='gumak' style={gumakStyle}>
									{numberToCommaString(roundUp(totalTimeWorkerPaidHoliWage))}
								</Table.Cell>
								<Table.Cell colSpan='6' className='sulmung'>
									주휴수당 시급
									{numberToCommaString(roundUp(timeWorkerTimePaidHoliWage))}원 *
									총 기본근무시간 {Math.floor(monthTotalWorkTime * 100) / 100}
									시간
								</Table.Cell>
							</Table.Row>
						) : (
							<Table.Row>
								<Table.Cell className='gubun' style={centerStyle}>
									주휴수당
								</Table.Cell>
								<Table.Cell className='hangmok' style={centerStyle}>
									임금
								</Table.Cell>
								<Table.Cell className='gumak' style={gumakStyle}>
									{numberToCommaString(roundUp(numPaidHoliTimeW))}
								</Table.Cell>
								{numPaidHoliTimeW > 0 ? (
									<>
										<Table.Cell colSpan='6' className='sulmung'>
											통상시급{numberToCommaString(roundUp(timeWorkerTimeWage))}
											원 * 총 주휴시간{' '}
											{Math.floor(finalPaidHoliWageTime * 100) / 100}시간
											{<br />}
											{`[(시급제(단시간) 근로자 4주 소정근로시간 (${numberToCommaString(
												roundUp(finalPaidHoliWageTimeSum)
											)})) / (통상근로자 4주 소정근무일(${
												normalWorkerWeekWorkDays ? normalWorkerWeekWorkDays : 5
											})) * 주휴일수 (${paidHoliWageDays})]`}
											{<br />}
											{paidHoliWageText}
										</Table.Cell>
									</>
								) : (
									<Table.Cell colSpan='6'>
										1주 15시간을 초과하지 않습니다.
									</Table.Cell>
								)}
							</Table.Row>
						)}
						{numOWageTimeW ? (
							<Table.Row>
								<Table.Cell className='gubun' style={centerStyle}>
									평일연장근로
								</Table.Cell>
								<Table.Cell className='hangmok' style={centerStyle}>
									평균임금
								</Table.Cell>
								<Table.Cell className='gumak' style={gumakStyle}>
									{numberToCommaString(roundUp(numOWageTimeW))}
								</Table.Cell>
								<Table.Cell colSpan='6' className='sulmung'>
									통상시급 {numberToCommaString(roundUp(timeWorkerTimeWage))}원
									* 8시간 초과
									{timeWorkerPlusMonthOWT}시간{' '}
									{numOfWorkers >= 5 ? '* 1.5배' : '* 1배'}
								</Table.Cell>
							</Table.Row>
						) : null}
						{numNWageTimeW ? (
							<Table.Row>
								<Table.Cell className='gubun' style={centerStyle}>
									야간근로{<br />}가산임금
								</Table.Cell>
								<Table.Cell className='hangmok' style={centerStyle}>
									평균임금
								</Table.Cell>
								<Table.Cell className='gumak' style={gumakStyle}>
									{numberToCommaString(roundUp(numNWageTimeW))}
								</Table.Cell>
								<Table.Cell colSpan='6' className='sulmung'>
									통상시급 {numberToCommaString(roundUp(timeWorkerTimeWage))}원
									* 야간근로 {timeWorkerPlusMonthNWT}시간{' '}
									{numOfWorkers >= 5 ? '* 0.5배' : '* 0.5배'}
									{<br />}(근로를 제공한 모든 일자(휴무일과 휴일이 포함된 경우
									이를 포함)의 오후 10시이후 오전 6시이전 모든 야간 근로)
								</Table.Cell>
							</Table.Row>
						) : null}
						{numHBWageTimeW ? (
							<Table.Row>
								<Table.Cell className='gubun' style={centerStyle}>
									휴일기본{<br />}가산임금
								</Table.Cell>
								<Table.Cell className='hangmok' style={centerStyle}>
									평균임금
								</Table.Cell>
								<Table.Cell className='gumak' style={gumakStyle}>
									{numberToCommaString(roundUp(numHBWageTimeW))}
								</Table.Cell>
								<Table.Cell colSpan='6' className='sulmung'>
									통상시급 {numberToCommaString(roundUp(timeWorkerTimeWage))}원
									* 휴일근로 {timeWorkerPlusMonthHoliBasicTime}시간{' '}
									{numOfWorkers >= 5 ? '* 0.5배' : '* 0배'}
									{<br />}(휴일 8시간 이내 근무에 대한 댓가 1배는 기본임금에
									포함되어 있으므로 가산임금 0.5배만 계산함.)
								</Table.Cell>
							</Table.Row>
						) : null}
						{numHOWageTimeW ? (
							<Table.Row>
								<Table.Cell className='gubun' style={centerStyle}>
									휴일초과{<br />}임금
								</Table.Cell>
								<Table.Cell className='hangmok' style={centerStyle}>
									평균임금
								</Table.Cell>
								<Table.Cell className='gumak' style={gumakStyle}>
									{numberToCommaString(roundUp(numHOWageTimeW))}
								</Table.Cell>
								<Table.Cell colSpan='6' className='sulmung'>
									통상시급 {numberToCommaString(roundUp(timeWorkerTimeWage))}원
									* 휴일근로 8시간 초과 {timeWorkerPlusMonthOWTHoli}
									시간 {numOfWorkers >= 5 ? '* 2배' : '* 1배'}
									{<br />}(휴일 8시간 초과근로에 대해서는 기본임금에 포함되어
									있지 않으므로 기본근로와 가산근로 합계 2배를 계산함.)
								</Table.Cell>
							</Table.Row>
						) : null}
						{numOffWageTimeW ? (
							<Table.Row>
								<Table.Cell className='gubun' style={centerStyle}>
									휴무일기본{<br />}가산임금
								</Table.Cell>
								<Table.Cell className='hangmok' style={centerStyle}>
									평균임금
								</Table.Cell>
								<Table.Cell className='gumak' style={gumakStyle}>
									{numberToCommaString(roundUp(numOffWageTimeW))}
								</Table.Cell>
								<Table.Cell colSpan='6' className='sulmung'>
									통상시급 {numberToCommaString(roundUp(timeWorkerTimeWage))}원
									* 휴무일근로 {timeWorkerPlusMonthOffBasicTime}시간{' '}
									{numOfWorkers >= 5 ? '* 0.5배' : '* 0배'}
									{<br />}(휴무일 8시간 이내 근무에 대한 댓가 1배는 기본임금에
									포함되어 있으므로 가산임금 0.5배만 계산함.)
								</Table.Cell>
							</Table.Row>
						) : null}
						{numOffOWageTimeW ? (
							<Table.Row>
								<Table.Cell className='gubun' style={centerStyle}>
									휴무일초과{<br />}임금
								</Table.Cell>
								<Table.Cell className='hangmok' style={centerStyle}>
									평균임금
								</Table.Cell>
								<Table.Cell className='gumak' style={gumakStyle}>
									{numberToCommaString(roundUp(numOffOWageTimeW))}
								</Table.Cell>
								<Table.Cell colSpan='6' className='sulmung'>
									통상시급 {numberToCommaString(roundUp(timeWorkerTimeWage))}원
									* 휴무일근로 8시간 초과 {timeWorkerPlusMonthOWTOff}시간{' '}
									{numOfWorkers >= 5 ? '* 1.5배' : '* 1배'}
									{<br />}(휴무일 8시간 초과근로에 대해서는 기본임금에 포함되어
									있지 않으므로 기본근로와 가산근로 합계 1.5배를 계산함.)
								</Table.Cell>
							</Table.Row>
						) : null}

						{commaStringToNumber(timeWorkerSpecialBonus) > 0 ? (
							<Table.Row>
								<Table.Cell className='gubun' style={centerStyle}>
									특별급여
								</Table.Cell>
								<Table.Cell className='hangmok' style={centerStyle}>
									기타금품
								</Table.Cell>
								<Table.Cell className='gumak' style={gumakStyle}>
									{numberToCommaString(timeWorkerSpecialBonus)}
								</Table.Cell>
								<Table.Cell colSpan='6' className='sulmung'>
									근로제공과 관계없는 은혜적 금품으로 평균임금에 산입되지
									아니한다.
								</Table.Cell>
							</Table.Row>
						) : null}
					</>
				) : (
					<>
						{week14OverBLTime === '주휴수당 포함' ? (
							<Table.Row>
								<Table.Cell className='gubun' style={centerStyle}>
									평일
								</Table.Cell>
								<Table.Cell className='hangmok' style={centerStyle}>
									임금(주휴포함)
								</Table.Cell>
								<Table.Cell className='gumak' style={gumakStyle}>
									{numberToCommaString(roundUp(totalDayWorkerWage))}
								</Table.Cell>
								<Table.Cell colSpan='6' className='sulmung'>
									{numberToCommaString(roundUp(dayWorkerWage))}원 * 평일{' '}
									{countOfNomalDayWork} 일
								</Table.Cell>
							</Table.Row>
						) : (
							<>
								<Table.Row>
									<Table.Cell className='gubun' style={centerStyle}>
										평일
									</Table.Cell>
									<Table.Cell className='hangmok' style={centerStyle}>
										임금
									</Table.Cell>
									<Table.Cell className='gumak' style={gumakStyle}>
										{numberToCommaString(roundUp(totalDayWorkerWage))}
									</Table.Cell>
									<Table.Cell colSpan='6' className='sulmung'>
										{numberToCommaString(roundUp(dayWorkerWage))}원 * 평일{' '}
										{countOfNomalDayWork} 일
									</Table.Cell>
								</Table.Row>
								<Table.Row>
									<Table.Cell className='gubun' style={centerStyle}>
										주휴수당
									</Table.Cell>
									<Table.Cell className='hangmok' style={centerStyle}>
										임금
									</Table.Cell>
									<Table.Cell className='gumak' style={gumakStyle}>
										{numberToCommaString(roundUp(numPaidHoliDayW))}
									</Table.Cell>
									{numPaidHoliDayW > 0 ? (
										<>
											<Table.Cell colSpan='6' className='sulmung'>
												통상시급{numberToCommaString(roundUp(dayWokerTimeWage))}
												원 * 총 주휴시간 {roundUp2(finalPaidHoliWageTime)}시간
												{<br />}
												{`[(일급제(단시간) 근로자 4주 소정근로시간 (${numberToCommaString(
													roundUp(finalPaidHoliWageTimeSum)
												)})) / (통상근로자 4주 소정근무일(${
													normalWorkerWeekWorkDays
														? normalWorkerWeekWorkDays
														: 5
												})) * 주휴일수 (${paidHoliWageDays})]`}
												{<br />}
												{paidHoliWageText}
											</Table.Cell>
										</>
									) : (
										<Table.Cell colSpan='6'>
											1주 15시간을 초과하지 않습니다.
										</Table.Cell>
									)}
								</Table.Row>
							</>
						)}
						{countOfHoliDayWork ? (
							<Table.Row>
								<Table.Cell className='gubun' style={centerStyle}>
									휴일
								</Table.Cell>
								<Table.Cell className='hangmok' style={centerStyle}>
									임금
								</Table.Cell>
								<Table.Cell className='gumak' style={gumakStyle}>
									{numberToCommaString(roundUp(totalDayWorkerHoliWage))}
								</Table.Cell>
								<Table.Cell colSpan='6' className='sulmung'>
									{numberToCommaString(roundUp(dayWorkerHoliWage))}원 * 휴일{' '}
									{countOfHoliDayWork} 일
								</Table.Cell>
							</Table.Row>
						) : null}
						{countOfOverWorkingDayWork ? (
							<Table.Row>
								<Table.Cell className='gubun' style={centerStyle}>
									휴무일
								</Table.Cell>
								<Table.Cell className='hangmok' style={centerStyle}>
									임금
								</Table.Cell>
								<Table.Cell className='gumak' style={gumakStyle}>
									{numberToCommaString(roundUp(totalDayWorkerOverWorkDayWage))}
								</Table.Cell>
								<Table.Cell colSpan='6' className='sulmung'>
									{numberToCommaString(roundUp(dayWorkerOverWorkDayWage))}원 *
									휴무일 {countOfOverWorkingDayWork} 일
								</Table.Cell>
							</Table.Row>
						) : null}
						{commaStringToNumber(dayWorkerPlusMonthOWT) > 0 ? (
							<Table.Row>
								<Table.Cell className='gubun' style={centerStyle}>
									평균임금
								</Table.Cell>
								<Table.Cell className='hangmok' style={centerStyle}>
									{numOfWorkers >= 5 ? '시간외수당' : '초과수당'}
								</Table.Cell>
								<Table.Cell className='gumak' style={gumakStyle}>
									{dayWorkerPlusOverTimeValue}
								</Table.Cell>
								<Table.Cell colSpan='6' className='sulmung'>
									{dayWorkerPlusOverTimeText}
								</Table.Cell>
							</Table.Row>
						) : null}
						{commaStringToNumber(dayWorkerPlusMonthNWT) > 0 ? (
							<Table.Row>
								<Table.Cell className='gubun' style={centerStyle}>
									평균임금
								</Table.Cell>
								<Table.Cell className='hangmok' style={centerStyle}>
									야간수당
								</Table.Cell>
								<Table.Cell className='gumak' style={gumakStyle}>
									{dayWorkerPlusNightTimeValue}
								</Table.Cell>
								<Table.Cell colSpan='6' className='sulmung'>
									{dayWorkerPlusNightTimeText}
								</Table.Cell>
							</Table.Row>
						) : null}
						{commaStringToNumber(dayWorkerPlusMonthHoliBasicTime) > 0 ? (
							<Table.Row>
								<Table.Cell className='gubun' style={centerStyle}>
									평균임금
								</Table.Cell>
								<Table.Cell className='hangmok' style={centerStyle}>
									휴일 기본수당
								</Table.Cell>
								<Table.Cell className='gumak' style={gumakStyle}>
									{dayWorkerPlusHoliBTimeValue}
								</Table.Cell>
								<Table.Cell colSpan='6' className='sulmung'>
									{dayWorkerPlusHoliBTimeText}
								</Table.Cell>
							</Table.Row>
						) : null}
						{commaStringToNumber(dayWorkerPlusMonthOWTHoli) > 0 ? (
							<Table.Row>
								<Table.Cell className='gubun' style={centerStyle}>
									평균임금
								</Table.Cell>
								<Table.Cell className='hangmok' style={centerStyle}>
									휴일 연장수당
								</Table.Cell>
								<Table.Cell className='gumak' style={gumakStyle}>
									{dayWorkerPlusHoliOTimeValue}
								</Table.Cell>
								<Table.Cell colSpan='6' className='sulmung'>
									{dayWorkerPlusHoliOTimeText}
								</Table.Cell>
							</Table.Row>
						) : null}
						{commaStringToNumber(dayWorkerPlusMonthNWTHoli) > 0 ? (
							<Table.Row>
								<Table.Cell className='gubun' style={centerStyle}>
									평균임금
								</Table.Cell>
								<Table.Cell className='hangmok' style={centerStyle}>
									휴일 야간수당
								</Table.Cell>
								<Table.Cell className='gumak' style={gumakStyle}>
									{dayWorkerPlusHoliNTimeValue}
								</Table.Cell>
								<Table.Cell colSpan='6' className='sulmung'>
									{dayWorkerPlusHoliNTimeText}
								</Table.Cell>
							</Table.Row>
						) : null}
						{absentTimeWage ? (
							<Table.Row>
								<Table.Cell className='gubun' style={centerStyle}>
									통상임금
								</Table.Cell>
								<Table.Cell className='hangmok' style={centerStyle}>
									지각 등 <br /> 시간공제
								</Table.Cell>
								<Table.Cell className='gumak' style={gumakStyle}>
									<span style={{ color: 'red' }}>
										-{numberToCommaString(absentTimeWage)}
									</span>
								</Table.Cell>
								<Table.Cell
									colSpan='6'
									className='sulmung'>{`지각, 조퇴, 외출 등 공제 ${numberToCommaString(
									numdayWorkerAbsentTimes
								)}시간 * 시급 ${numberToCommaString(
									dayWokerTimeWage
								)}원`}</Table.Cell>
							</Table.Row>
						) : null}
						{commaStringToNumber(dayWorkerSpecialBonus) > 0 ? (
							<Table.Row>
								<Table.Cell className='gubun' style={centerStyle}>
									특별상여금
								</Table.Cell>
								<Table.Cell className='hangmok' style={centerStyle}>
									기타금품
								</Table.Cell>
								<Table.Cell className='gumak' style={gumakStyle}>
									{dayWorkerSpecialBonus}
								</Table.Cell>
								<Table.Cell colSpan='6' className='sulmung'>
									근로제공과 관계없는 은혜적 금품으로 평균임금에 산입되지
									아니한다.
								</Table.Cell>
							</Table.Row>
						) : null}
					</>
				)}
				<Table.Row>
					<ClientReqDLCWageTableDayWorkerDetailAWage5
						centerStyle={centerStyle}
						gumakStyle={gumakStyle}
						inputWageStyle={inputWageStyle}
						inputCommentStyle={inputCommentStyle}
						dayWorkerCAWage5={dayWorkerCAWage5}
						dayWorkerCAWEName5={dayWorkerCAWEName5}
						dayWorkerCAWage5Comment={dayWorkerCAWage5Comment}
						handelInputChange={handelInputChange}
					/>
				</Table.Row>
				<Table.Row>
					<ClientReqDLCWageTableDayWorkerDetailAWage6
						centerStyle={centerStyle}
						gumakStyle={gumakStyle}
						inputWageStyle={inputWageStyle}
						inputCommentStyle={inputCommentStyle}
						dayWorkerCAWage6={dayWorkerCAWage6}
						dayWorkerCAWEName6={dayWorkerCAWEName6}
						dayWorkerCAWage6Comment={dayWorkerCAWage6Comment}
						handelInputChange={handelInputChange}
					/>
				</Table.Row>
				<Table.Row>
					<ClientReqDLCWageTableDayWorkerDetailAWage7
						centerStyle={centerStyle}
						gumakStyle={gumakStyle}
						inputWageStyle={inputWageStyle}
						inputCommentStyle={inputCommentStyle}
						dayWorkerCAWage7={dayWorkerCAWage7}
						dayWorkerCAWEName7={dayWorkerCAWEName7}
						dayWorkerCAWage7Comment={dayWorkerCAWage7Comment}
						handelInputChange={handelInputChange}
					/>
				</Table.Row>
				<Table.Row>
					<Table.Cell className='gubun' style={centerStyle}>
						총 합계
					</Table.Cell>
					<Table.Cell className='hangmok' style={centerStyle}>
						임금
					</Table.Cell>
					<Table.Cell className='gumak' style={gumakStyle}>
						{numberToCommaString(roundUp(totalMonthWageAmount))}
					</Table.Cell>
					<Table.Cell colSpan='6' className='sulmung'>
						일당 총 합계
					</Table.Cell>
				</Table.Row>

				<ClientReqDLCWageTableDayWorkerDeductDetail
					dayWorkerTaxText={dayWorkerTaxText}
					dayWorkerDayBasicWorkingTime={dayWorkerDayBasicWorkingTime}
					totalCountOfWorkingDay={totalCountOfWorkingDay}
					isDayOrWorker={isDayOrWorker}
					daylyTimePay={daylyTimePay}
					timeWorkerTotalWorkDays={filteredSelectedDays.length}
					weekBLTimeTW={weekBLTimeTW} //주휴수당용(전달과 함친 주)
					weekBLTimeTWThisMonth={weekBLTimeTWThisMonth} //당월 4대보험용
					lawBaseWorkDatesWorkHourThisMonthOnlySum={
						lawBaseWorkDatesWorkHourThisMonthOnlySum
					}
					values={values}
					initialTax={initialTax}
					dayWorkerTax={dayWorkerTax}
					healthInsureOver60DayWorker={healthInsureOver60DayWorker}
					constructOrNot={constructOrNot}
					gumakStyle={gumakStyle}
					centerStyle={centerStyle}
					dayWorkerWage={dayWorkerWage}
					countOfNomalDayWork={countOfNomalDayWork}
					countOfOverWorkingDayWork={countOfOverWorkingDayWork}
					countOfHoliDayWork={countOfHoliDayWork}
					dayWorkerHoliWage={dayWorkerHoliWage}
					dayWorkerOverWorkDayWage={dayWorkerOverWorkDayWage}
					totalMonthWageAmount={totalMonthWageAmount}
					thisYear={thisYear}
					thisMonth={thisMonth}
					inputTax={inputTax}
					inputWageStyle={inputWageStyle}
					inputCommentStyle={inputCommentStyle}
					dayWorkerUserFixedTax={dayWorkerUserFixedTax}
					dayWorkerUserFixedRegionTax={dayWorkerUserFixedRegionTax}
					dayWorkerUserFixedHealthCare={dayWorkerUserFixedHealthCare}
					dayWorkerUserFixedOldCare={dayWorkerUserFixedOldCare}
					dayWorkerUserFixedPension={dayWorkerUserFixedPension}
					dayWorkerUserFixedNoJobInsurance={dayWorkerUserFixedNoJobInsurance}
					dayWorkerPlusDeductHealthCare={dayWorkerPlusDeductHealthCare}
					dayWorkerPlusDeductOldCare={dayWorkerPlusDeductOldCare}
					dayWorkerPlusDeductWorkTax={dayWorkerPlusDeductWorkTax}
					dayWorkerPlusDeductWorkRegionTax={dayWorkerPlusDeductWorkRegionTax}
					dayWorkerPlusDeductDuru={dayWorkerPlusDeductDuru}
					dayWorkerPlusMinus4Ins={dayWorkerPlusMinus4Ins}
					dayWorkerPlusDeductWorkerCompanyLoan={
						dayWorkerPlusDeductWorkerCompanyLoan
					}
					dayWorkerPlusDeductHealthCareComment={
						dayWorkerPlusDeductHealthCareComment
					}
					dayWorkerPlusDeductOldCareComment={dayWorkerPlusDeductOldCareComment}
					dayWorkerPlusDeductWorkTaxComment={dayWorkerPlusDeductWorkTaxComment}
					dayWorkerPlusDeductWorkRegionTaxComment={
						dayWorkerPlusDeductWorkRegionTaxComment
					}
					dayWorkerPlusMinus4InsComment={dayWorkerPlusMinus4InsComment}
					dayWorkerPlusDeductWorkerCompanyLoanComment={
						dayWorkerPlusDeductWorkerCompanyLoanComment
					}
					dayWorkerDeductEtcName1={dayWorkerDeductEtcName1}
					dayWorkerDeductEtcWage1={dayWorkerDeductEtcWage1}
					dayWorkerDeductEtcWage1Comment={dayWorkerDeductEtcWage1Comment}
					dayWorkerDeductEtcName2={dayWorkerDeductEtcName2}
					dayWorkerDeductEtcWage2={dayWorkerDeductEtcWage2}
					dayWorkerDeductEtcWage2Comment={dayWorkerDeductEtcWage2Comment}
					dayWorkerDeductEtcName3={dayWorkerDeductEtcName3}
					dayWorkerDeductEtcWage3={dayWorkerDeductEtcWage3}
					dayWorkerDeductEtcWage3Comment={dayWorkerDeductEtcWage3Comment}
					handelInputChange={handelInputChange}
					timeWorkerSpecialBonus={timeWorkerSpecialBonus}
					dayWorkerSpecialBonus={dayWorkerSpecialBonus}
					netAgeEntered={netAgeEntered}
				/>
			</>
		</>
	);
}
