import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Grid } from 'semantic-ui-react';
import useFirestoreDoc from '../../../app/hooks/useFirestoreDoc';
import { getUserProfile } from '../../../app/firestore/firestoreService';
import LoadingComponent from '../../../app/layout/LoadingComponent';
import { listenToSelectedUserProfile } from '../profileActions';
import CustomerInfoView from './CustomerInfoView';
import PricePolicyPaper from '../../auth/PricePolicyPaper';

export default function ProfilePage({ match }) {
	const dispatch = useDispatch();
	const { selectedUserProfile, currentUserProfile } = useSelector(
		(state) => state.profile
	);
	const { currentUser } = useSelector((state) => state.auth);
	const { loading, error } = useSelector((state) => state.async);
	let profile;

	useFirestoreDoc({
		query: () => getUserProfile(match.params.id),
		data: (profile) => dispatch(listenToSelectedUserProfile(profile)),
		deps: [dispatch, match.params.id],
		shouldExcute: match.params.id !== currentUser.uid,
	});

	if (match.params.id === currentUser.uid) {
		profile = currentUserProfile;
	} else {
		profile = selectedUserProfile;
	}

	if ((loading && !profile) || (!profile && !error))
		return <LoadingComponent content='Loading profile...' />;

	if (!profile?.id) return <PricePolicyPaper />;

	return (
		<>
			{
				// profile?.id ?
				<Grid>
					<Grid.Column width={16}>
						<CustomerInfoView
							profile={profile}
							isCurrentUser={currentUser?.uid === profile?.id}
							currentUserProfile={currentUserProfile}
						/>
					</Grid.Column>
				</Grid>
				// :
				// <PricePolicyPaper />
			}
		</>
	);
}
