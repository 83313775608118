import { toast } from 'react-toastify';
import { auth, db, storage } from '../config/firebase';
import { createUserWithEmailAndPassword, 
  signInWithEmailAndPassword, 
  GoogleAuthProvider, 
  signInWithPopup, 
  signOut, 
  updateProfile, 
  updatePassword } from 'firebase/auth';
import { ref as databaseRef, push } from 'firebase/database';
import { ref as storageRef, deleteObject, uploadBytes } from 'firebase/storage';

import { setUserProfileData } from './firestoreService';

export function firebaseObjectToArray(snapshot) {
  if (snapshot) {
    return Object.entries(snapshot).map(e=> Object.assign({}, e[1], {id: e[0]}))
  }
}

export function signInWithEmail(creds) {
  return signInWithEmailAndPassword(auth, creds.email, creds.password);
}

export function signOutFirebase() {
  return signOut(auth);
}

export async function registerInFirebase(creds) {
  try {
    const result = await createUserWithEmailAndPassword(auth, creds.email, creds.password);
    await updateProfile(result.user, {
      displayName: creds.displayName,
    });
    return await setUserProfileData(result.user);
  } catch (error) {
    throw error;
  }
}

export async function socialLogin(selectedProvider) {
  let provider;
  // if (selectedProvider === 'facebook') {
  //   provider = new firebase.auth.FacebookAuthProvider();
  // }
  if (selectedProvider === 'google') {
    provider = new GoogleAuthProvider();
  }
  try {
    const result = await signInWithPopup(auth, provider);
    if (result.additionalUserInfo.isNewUser) {
      await setUserProfileData(result.user);
    }
  } catch (error) {
    toast.error(error)
  }
}

export function updateUserPassword(creds) {
  const user = auth.currentUser;
  return updatePassword(user, creds.newPassword1);
}

export function uploadToFirebaseStorage(file, filename) {
  const user = auth.currentUser;
  const storagefile = storageRef(storage, `${user.displayName}/user_files/${filename}`);
  return uploadBytes(storagefile, file);
}

export function deleteFromFirebaseStorage(filename) {
  const userDisplayName = auth.currentUser.displayName;
  const fileRef = storageRef(storage, `${userDisplayName}/user_files/${filename}`);
  return deleteObject(fileRef);
}

export function addEventChatComment(eventId, values) {
  const user = auth.currentUser;
  const newComment = {
    displayName : user.displayName,
    photoURL : user.photoURL,
    uid: user.uid,
    text: values.comment,
    date: Date.now(),
    parentId: values.parentId,
  }
  return push(databaseRef(db, `chat/${eventId}`), newComment);
}


export function getEventChatRef(eventId) {
  return databaseRef(db, `chat/${eventId}`).orderByKey();
}


export function addClientReq4InChatComment(clientReq4InId, values) {
  const user = auth.currentUser;
  const newComment = {
    displayName : user.displayName,
    photoURL : user.photoURL,
    uid: user.uid,
    text: values.comment,
    date: Date.now(),
    parentId: values.parentId,
  }
  return push(databaseRef(db, `chatClientReq4In/${clientReq4InId}`), newComment);
}

export function getClinetReq4InChatRef(clientReq4InId) {
  return databaseRef(db, `chatClientReq4In/${clientReq4InId}`).orderByKey();
}

export function getUserFeedRef() {
  const user =  auth.currentUser;
  return databaseRef(db, `posts/${user?.uid}`).orderByKey().limitToLast(5);
}