import { format } from 'date-fns';
import React from 'react';
import { Table } from 'semantic-ui-react';
import { decrypto } from '../../../../../app/firestore/firestoreService';

export default function ClientReqDLCTail({
	clientInfo,
	selectedClientReq4In,
	worker,
}) {
	let today = new Date();

	const decryptoData = {};
	for (const prop in selectedClientReq4In) {
		decryptoData[prop] =
			prop === 'workerSocialNumberFront'
				? // || prop === "workerName"
				  decrypto(selectedClientReq4In[prop], String(process.env.CRYPTO_KEY))
				: selectedClientReq4In[prop];
	}

	const ceoName = clientInfo?.ceoName
		? clientInfo?.ceoName
		: selectedClientReq4In?.company?.ceoName
		? selectedClientReq4In?.company?.ceoName
		: selectedClientReq4In?.ceoName;
	const companyAddress = clientInfo?.companyAddress
		? clientInfo?.companyAddress
		: selectedClientReq4In?.company?.addr
		? selectedClientReq4In?.company?.addr
		: selectedClientReq4In?.companyAddress;
	const companyName = clientInfo?.companyName
		? clientInfo?.companyName
		: selectedClientReq4In?.company?.companyName
		? selectedClientReq4In?.company?.companyName
		: selectedClientReq4In?.companyName;

	return (
		<>
			<br />
			<table width='100%' align='center'>
				<tbody>
					<tr>
						<td align='center'>{format(today, 'yyyy-MM-dd')}</td>
					</tr>
				</tbody>
			</table>
			<br />
			<Table width='100%' height='100' textAlign='center'>
				<Table.Body>
					<Table.Row>
						<Table.Cell width={8}>회사명 : {companyName}</Table.Cell>
						<Table.Cell width={8}>
							근로자 성명 : {worker} (서명 또는 인)
						</Table.Cell>
					</Table.Row>
					<Table.Row>
						<Table.Cell width={8}>대표자 성명 : {ceoName} (인)</Table.Cell>
						{/* <Table.Cell width ={8}>근로자 생년월일 : {decryptoData?.workerSocialNumber?.split("-")[0]}</Table.Cell> */}
						<Table.Cell width={8}>
							근로자 생년월일 : {decryptoData?.workerSocialNumberFront}
						</Table.Cell>
					</Table.Row>
					<Table.Row>
						<Table.Cell width={8}>사업주 주소 : {companyAddress}</Table.Cell>
						<Table.Cell textAlign='left' width={8}>
							근로자 주소 : {decryptoData?.worker?.address}
						</Table.Cell>
					</Table.Row>
				</Table.Body>
			</Table>
			<br />
			<div style={{ textAlign: 'center', border: '1px' }}></div>
			<Table width='100%' textAlign='center'>
				<Table.Body>
					<Table.Row>
						<Table.Cell
							colSpan='8'
							textAlign='center'>{`본 계약서 1부를 교부받았습니다. 확인자 :  ${worker} (서명 또는 인)`}</Table.Cell>
					</Table.Row>
					<Table.Row>
						<Table.Cell
							colSpan='8'
							align='left'>{`본 계약서를 ${worker}(이)가 교부확인란에 직접 서명(또는 인)을 하지 않은 상태라 하더라도 ${worker}의 이메일, 카카오톡, 문자로 발송(반송된 경우 제외)되거나 ${worker}(이)가 로그인해서 확인하고 출력할 수 있는 사내인트라넷(AI MONUSA 포함)에 올린 경우 본 계약서는 ${worker}에게 교부된 것으로 간주함(근거: 근로기준법 제17조의 서면교부의무와 계약의 효력발생 요건(당사자간의 서명날인 등으로 인한 합의)이 구분되는 점 및 2021.11.19.시행 임금명세서 교부 의무 설명자료(2021.11 고용노동부) 참조).`}</Table.Cell>
					</Table.Row>
				</Table.Body>
			</Table>
		</>
	);
}
