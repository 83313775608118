import React from 'react'
import { Table } from 'semantic-ui-react'

export default function ClientReqDLCWageTableDetailAWage5({
  gumakStyle,
  centerStyle,
  inputWageStyle,
  inputCommentStyle,
  cAWage5,
  cAWEName5,
  cAWage5Comment,
  handelInputChange
}) {

  return (
    <Table.Row textAlign='center'>
      <Table.Cell className='gubun' style={centerStyle}>평균임금</Table.Cell>
      <Table.Cell className='gumak' style={centerStyle}><input style={inputWageStyle} name='cAWEName5' value = {cAWEName5} onChange= {(e) => handelInputChange(e)} /></Table.Cell>
      <Table.Cell className='gumak' style={gumakStyle}><input style={inputWageStyle} name='cAWage5' value = {cAWage5} onChange= {(e) => handelInputChange(e)} /></Table.Cell>
      <Table.Cell textAlign='left' colSpan='6' className='sulmung'><textarea style={inputCommentStyle} name='cAWage5Comment' value = { cAWage5Comment } onChange = {(e) => handelInputChange(e)}/></Table.Cell>
    </Table.Row>
  )
}
