import React, { useRef } from 'react';
import { format } from 'date-fns';
import { useDispatch, useSelector } from 'react-redux';
import { Redirect, useHistory } from 'react-router-dom';
import { Button, Grid, Table } from 'semantic-ui-react';
import {
	numberToCommaString,
	printWageTable,
} from '../../../../app/common/util/util';
import { listenToClientReq4InFromFirestore } from '../../../../app/firestore/firestoreService4In';
import useFirestoreDoc from '../../../../app/hooks/useFirestoreDoc';
import LoadingComponent from '../../../../app/layout/LoadingComponent';
import { listenToSelectedClientReq4In } from '../clientReqActions4In';
import moment from 'moment';
import PaydocuPrintOneTable from '../../../companyManage/paydocuDashboard/PaydocuPrintOneTable';
import * as XLSX from 'xlsx';

export default function ClientRetireFundPage({ match }) {
	const htmlContentRef = useRef(null);

	const history = useHistory();

	const dispatch = useDispatch();
	const { selectedClientReq4In } = useSelector(
		(state) => state.clientReq4InStore
	);
	const { loading, error } = useSelector((state) => state.async);
	const { currentUserProfile } = useSelector((state) => state.profile);

	//노무사는 노무사가 입력한 회사로 회사이름 설정 가능하고, 유저는 본인 회원가입한 상호로만 회사이름 설정됨.
	const isWorker = currentUserProfile?.isWorker;

	const companyName = isWorker
		? currentUserProfile?.workerInputCompanyName
			? currentUserProfile?.workerInputCompanyName
			: selectedClientReq4In?.companyName
		: selectedClientReq4In?.companyName;

	const worker = selectedClientReq4In?.worker?.name;

	const workerEnteredDate = moment(
		selectedClientReq4In?.workerEnteredDate
	).format('YYYY-MM-DD');
	const retiredDate = moment(selectedClientReq4In?.retiredDate).format(
		'YYYY-MM-DD'
	);
	const totalWorkingDays = selectedClientReq4In?.retireWage?.totalWorkingDays
		? selectedClientReq4In?.retireWage?.totalWorkingDays
		: 0;

	const retiredMonthDay = moment(
		selectedClientReq4In?.retireWage?.retiredMonthDay
	).format('YYYY-MM-DD');
	const retiredMonthDayEnd = moment(
		selectedClientReq4In?.retireWage?.retiredMonthDayEnd
	).format('YYYY-MM-DD');
	const retiredMonthDayCount = selectedClientReq4In?.retireWage
		?.retiredMonthDayCount
		? selectedClientReq4In?.retireWage?.retiredMonthDayCount
		: 0;
	const realAverWageOfRetiredMonth = selectedClientReq4In?.retireWage
		?.realAverWageOfRetiredMonth
		? selectedClientReq4In?.retireWage?.realAverWageOfRetiredMonth
		: 0;

	const retiredMonthDay01 = moment(
		selectedClientReq4In?.retireWage?.retiredMonthDay01
	).format('YYYY-MM-DD');
	const retiredMonthDayEnd01 = moment(
		selectedClientReq4In?.retireWage?.retiredMonthDayEnd01
	).format('YYYY-MM-DD');
	const retiredMonthDayCount01 = selectedClientReq4In?.retireWage
		?.retiredMonthDayCount01
		? selectedClientReq4In?.retireWage?.retiredMonthDayCount01
		: 0;
	const retireMonth1TotalWage = selectedClientReq4In?.retireWage
		?.retireMonth1TotalWage
		? selectedClientReq4In?.retireWage?.retireMonth1TotalWage
		: 0;

	const retiredMonthDay02 = moment(
		selectedClientReq4In?.retireWage?.retiredMonthDay02
	).format('YYYY-MM-DD');
	const retiredMonthDayEnd02 = moment(
		selectedClientReq4In?.retireWage?.retiredMonthDayEnd02
	).format('YYYY-MM-DD');
	const retiredMonthDayCount02 = selectedClientReq4In?.retireWage
		?.retiredMonthDayCount02
		? selectedClientReq4In?.retireWage?.retiredMonthDayCount02
		: 0;
	const retireMonth2TotalWage = selectedClientReq4In?.retireWage
		?.retireMonth2TotalWage
		? selectedClientReq4In?.retireWage?.retireMonth2TotalWage
		: 0;

	const retiredMonthDay03 = moment(
		selectedClientReq4In?.retireWage?.retiredMonthDay03
	).format('YYYY-MM-DD');
	const retiredMonthDayEnd03 = moment(
		selectedClientReq4In?.retireWage?.retiredMonthDayEnd03
	).format('YYYY-MM-DD');
	const retiredMonthDayCount03 = selectedClientReq4In?.retireWage
		?.retiredMonthDayCount03
		? selectedClientReq4In?.retireWage?.retiredMonthDayCount03
		: 0;
	const retireMonth3TotalWage = selectedClientReq4In?.retireWage
		?.retireMonth3TotalWage
		? selectedClientReq4In?.retireWage?.retireMonth3TotalWage
		: 0;

	const retireYearTotalAnnualLeavesWages = selectedClientReq4In?.retireWage
		?.retireYearTotalAnnualLeavesWages
		? selectedClientReq4In?.retireWage?.retireYearTotalAnnualLeavesWages
		: 0;
	const retireYearTotalBonusWages = selectedClientReq4In?.retireWage
		?.retireYearTotalBonusWages
		? selectedClientReq4In?.retireWage?.retireYearTotalBonusWages
		: 0;

	const dayOrdinaryWage = selectedClientReq4In?.retireWage?.dayOrdinaryWage
		? selectedClientReq4In?.retireWage?.dayOrdinaryWage
		: 0;
	const retireAverageDayWage = selectedClientReq4In?.retireWage
		?.retireAverageDayWage
		? selectedClientReq4In?.retireWage?.retireAverageDayWage
		: 0;
	const retireFund = selectedClientReq4In?.retireWage?.retireFund
		? selectedClientReq4In?.retireWage?.retireFund
		: 0;

	const retiredFundTax = selectedClientReq4In?.retireWage?.retiredFundTax
		? selectedClientReq4In?.retireWage?.retiredFundTax
		: 0;
	const retiredFundRegionTax = selectedClientReq4In?.retireWage
		?.retiredFundRegionTax
		? selectedClientReq4In?.retireWage?.retiredFundRegionTax
		: 0;
	const finalRetiredFund = selectedClientReq4In?.retireWage?.finalRetiredFund
		? selectedClientReq4In?.retireWage?.finalRetiredFund
		: 0;
	const exceptAverPeriodAndWageMemo = selectedClientReq4In?.retireWage
		?.exceptAverPeriodAndWageMemo
		? selectedClientReq4In?.retireWage?.exceptAverPeriodAndWageMemo
		: '';

	useFirestoreDoc({
		shouldExcute:
			match.params.id !== selectedClientReq4In?.id &&
			Location.pathname !== '/createClientReq4In',
		query: () => listenToClientReq4InFromFirestore(match.params.id),
		data: (clientReq4In) =>
			dispatch(listenToSelectedClientReq4In(clientReq4In)),
		deps: [match.params.id, dispatch],
	});

	function goBack() {
		history.goBack();
	}

	if (loading || (!selectedClientReq4In && !error))
		return <LoadingComponent content='Loading event ...' />;
	if (error) return <Redirect to='/error' />;

	const isRetireFund = true;

	const printFunc = () => {
		printWageTable();
	};

	// Table 데이터를 XLSX 형식으로 변환
	const tableToExcel = () => {
		const table = document.getElementById('wageTable');
		const workbook = XLSX.utils.table_to_book(table, { sheet: 'Sheet1' });
		XLSX.writeFile(workbook, `${companyName}의 ${worker} 퇴직금명세서.xlsx`);
	};

	return (
		<Grid>
			<Grid.Column width={16}>
				<Button onClick={() => goBack()} color='teal'>
					이전페이지
				</Button>
				<Button type='button' color='blue' onClick={() => printFunc()}>
					프린트 출력
				</Button>
				<PaydocuPrintOneTable
					worker={worker}
					isRetireFund={isRetireFund}
					htmlContentRef={htmlContentRef}
					companyName={companyName}
				/>
				<Button type='button' color='brown' onClick={() => tableToExcel()}>
					Excel 저장
				</Button>
				<div id='wageTable' ref={htmlContentRef}>
					<Table border='1' width='100%' textAlign='center' id='table-to-xls'>
						<Table.Body align='center'>
							<Table.Row>
								<Table.Cell
									colSpan={'4'}
									height={'70'}
									style={{ fontSize: '1.3rem', color: 'blue' }}>
									{worker}의 퇴직금 명세서
								</Table.Cell>
							</Table.Row>
							<Table.Row align='left'>
								<Table.Cell colSpan={'4'}>회사명 : {companyName}</Table.Cell>
							</Table.Row>
							<Table.Row>
								<Table.Cell>성명</Table.Cell>
								<Table.Cell>입사일</Table.Cell>
								<Table.Cell>퇴사일</Table.Cell>
								<Table.Cell>재직일수</Table.Cell>
							</Table.Row>
							<Table.Row>
								<Table.Cell>{worker}</Table.Cell>
								<Table.Cell>{workerEnteredDate}</Table.Cell>
								<Table.Cell>{retiredDate} </Table.Cell>
								<Table.Cell>
									{numberToCommaString(totalWorkingDays)}{' '}
								</Table.Cell>
							</Table.Row>
							<Table.Row>
								<Table.Cell colSpan={'2'}>최종1월</Table.Cell>
								<Table.Cell>기간</Table.Cell>
								<Table.Cell>평균임금합계</Table.Cell>
							</Table.Row>
							<Table.Row>
								<Table.Cell>{retiredMonthDay}</Table.Cell>
								<Table.Cell>{retiredMonthDayEnd}</Table.Cell>
								<Table.Cell>{retiredMonthDayCount} </Table.Cell>
								<Table.Cell>
									{numberToCommaString(realAverWageOfRetiredMonth)}{' '}
								</Table.Cell>
							</Table.Row>
							<Table.Row>
								<Table.Cell colSpan={'2'}>최종2월</Table.Cell>
								<Table.Cell>기간</Table.Cell>
								<Table.Cell>평균임금합계</Table.Cell>
							</Table.Row>
							<Table.Row>
								<Table.Cell>{retiredMonthDay01}</Table.Cell>
								<Table.Cell>{retiredMonthDayEnd01}</Table.Cell>
								<Table.Cell>{retiredMonthDayCount01} </Table.Cell>
								<Table.Cell>
									{numberToCommaString(retireMonth1TotalWage)}{' '}
								</Table.Cell>
							</Table.Row>
							<Table.Row>
								<Table.Cell colSpan={'2'}>최종3월</Table.Cell>
								<Table.Cell>기간</Table.Cell>
								<Table.Cell>평균임금합계</Table.Cell>
							</Table.Row>
							<Table.Row>
								<Table.Cell>{retiredMonthDay02}</Table.Cell>
								<Table.Cell>{retiredMonthDayEnd02}</Table.Cell>
								<Table.Cell>{retiredMonthDayCount02} </Table.Cell>
								<Table.Cell>
									{numberToCommaString(retireMonth2TotalWage)}{' '}
								</Table.Cell>
							</Table.Row>
							<Table.Row>
								<Table.Cell colSpan={'2'}>최종4월</Table.Cell>
								<Table.Cell>기간</Table.Cell>
								<Table.Cell>평균임금합계</Table.Cell>
							</Table.Row>
							<Table.Row>
								<Table.Cell>{retiredMonthDay03}</Table.Cell>
								<Table.Cell>{retiredMonthDayEnd03}</Table.Cell>
								<Table.Cell>{retiredMonthDayCount03} </Table.Cell>
								<Table.Cell>
									{numberToCommaString(retireMonth3TotalWage)}{' '}
								</Table.Cell>
							</Table.Row>
							<Table.Row>
								<Table.Cell colSpan={'4'}>
									퇴직일 기준 직전 1년간 수령한 액수의 12분의 3
								</Table.Cell>
							</Table.Row>
							<Table.Row>
								<Table.Cell colSpan={'2'}>평균임금 산입 연차수당</Table.Cell>
								<Table.Cell colSpan={'2'}>평균임금 산입 상여금</Table.Cell>
							</Table.Row>
							<Table.Row>
								<Table.Cell colSpan={'2'}>
									{numberToCommaString(retireYearTotalAnnualLeavesWages)}
								</Table.Cell>
								<Table.Cell colSpan={'2'}>
									{numberToCommaString(retireYearTotalBonusWages)}{' '}
								</Table.Cell>
							</Table.Row>
							{exceptAverPeriodAndWageMemo ? (
								<>
									<Table.Row>
										<Table.Cell colSpan={'4'}>
											평균임금에서 제외되는 기간과 임금 설명
										</Table.Cell>
									</Table.Row>
									<Table.Row>
										<Table.Cell colSpan={'4'}>
											{exceptAverPeriodAndWageMemo}
										</Table.Cell>
									</Table.Row>
								</>
							) : null}
							<Table.Row>
								<Table.Cell>통상임금(일급)</Table.Cell>
								<Table.Cell>평균임금(일급)</Table.Cell>
								<Table.Cell colSpan={'2'}>퇴직금(세전)</Table.Cell>
							</Table.Row>
							<Table.Row>
								<Table.Cell>{numberToCommaString(dayOrdinaryWage)}</Table.Cell>
								<Table.Cell>
									{numberToCommaString(retireAverageDayWage)}
								</Table.Cell>
								<Table.Cell colSpan={'2'}>
									{numberToCommaString(retireFund)}{' '}
								</Table.Cell>
							</Table.Row>
							<Table.Row>
								<Table.Cell>퇴직소득세</Table.Cell>
								<Table.Cell>퇴직지방소득세</Table.Cell>
								<Table.Cell colSpan={'2'}>퇴직금(세후)</Table.Cell>
							</Table.Row>
							<Table.Row>
								<Table.Cell>{numberToCommaString(retiredFundTax)}</Table.Cell>
								<Table.Cell>
									{numberToCommaString(retiredFundRegionTax)}
								</Table.Cell>
								<Table.Cell colSpan={'2'}>
									{numberToCommaString(finalRetiredFund)}{' '}
								</Table.Cell>
							</Table.Row>

							<Table.Row width='100%' align='center'>
								<Table.Cell colSpan={'4'} align='center'>
									{format(new Date(), 'yyyy-MM-dd')}
								</Table.Cell>
							</Table.Row>
							<Table.Row>
								<Table.Cell colSpan={'4'} align='left'>
									주요 안내사항 : 근로제공 의무와 직접적(대상적)으로 연관되지
									않은 경영성과급, 특별상여금, PS, 사업주가 임의 지급한 금품
									등과 퇴직 년도에 발생한 연차휴가 중 퇴직일까지 사용하지 아니한
									연차휴가 미사용 수당은 근로기준법 제2조 평균임금 정의에 따라
									퇴직금 계산을 위한 평균임금에 산입되지 않습니다.
								</Table.Cell>
							</Table.Row>
							<Table.Row>
								<Table.Cell
									colSpan={'4'}
									align='center'>{`퇴직금 명세서를 교부받았음을 확인합니다. :  ${worker} (서명 또는 인)`}</Table.Cell>
							</Table.Row>
						</Table.Body>
					</Table>
				</div>
			</Grid.Column>
		</Grid>
	);
}
