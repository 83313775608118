import React, { useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import { Button, Modal, Header } from 'semantic-ui-react';

const CustomConfirm = ({ title, content, open, onCancel, onConfirm }) => {
  // 초기 선택된 버튼을 'confirm'으로 설정합니다.
  const [selectedButton, setSelectedButton] = useState('confirm');

  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.key === 'Enter' && open) {
        // Enter 키를 누를 때, selectedButton 상태에 따라 onConfirm 또는 onCancel 실행
        selectedButton === 'confirm' ? onConfirm() : onCancel();
      } else if (event.key === 'ArrowRight' || event.key === 'ArrowLeft') {
        // 화살표 키를 통해 선택된 버튼을 전환
        setSelectedButton(selectedButton === 'cancel' ? 'confirm' : 'cancel');
      }
    };

    document.addEventListener('keydown', handleKeyDown);
    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, [open, onConfirm, onCancel, selectedButton]);

  return ReactDOM.createPortal(
    <Modal open={open} onClose={onCancel} size="small">
      <Header content={title} />
      <Modal.Content>
        <p>{content}</p>
      </Modal.Content>
      <Modal.Actions>
        <Button negative onClick={onCancel} autoFocus={selectedButton === 'cancel'}>
          취소
        </Button>
        <Button positive onClick={onConfirm} autoFocus={selectedButton === 'confirm'}>
          확인
        </Button>
      </Modal.Actions>
    </Modal>,
    document.body
  );
};

export default CustomConfirm;
