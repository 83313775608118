import React, { useEffect, useState } from 'react'
import moment from 'moment'
import { Button, Table } from 'semantic-ui-react'
import { calALFinSpecificBagicYear, calALFinSpecificPlusYear, calALFinSpecificRatioYear } from '../../../../annualLeave-libs/finALSpec-lib'
import { calALMonth, calALSpecificBagicYear, calALSpecificMonth, calALSpecificPlusYear } from '../../../../annualLeave-libs/lawALSpec-lib'
import { apiService } from '../../../../annualLeave-libs/calculateAnnualLeaveCalALFinMonthApiService'

export default function OpenDetailAnnualLeavesCalcPastYear({
  
  targetYear,
  Item,
  finBaseDate,
  finBaseMonthDay,
  enterDate,
  secondYearForAnnualLeave,
  numOfWorkers,
}) {

  const [calALFinSpecificMonthV, setCalALFinSpecificMonthV] = useState(0);
  const workerEnteredTargetYearBeforeYes = moment(enterDate).format('yyyy') > +targetYear ? true : false
  const specificTargetYear = String(targetYear)
  const specificTargetYearDate = String(targetYear) + '-12-31'


  useEffect(() => {
    const fetchData = async () => {
      try {
        const data = await apiService.calculateAnnualLeaveCalALFinMonth({
          enterDate: enterDate,
          finBaseDate: finBaseDate,
          workersNumber: numOfWorkers,
          specificDate: specificTargetYearDate
        });
        setCalALFinSpecificMonthV(data[0]); // API의 응답 구조에 따라 조정 필요
      } catch (error) {
        console.error('Error fetching data: ', error);
      }
    };

    fetchData();
  }, [enterDate, finBaseDate, numOfWorkers, specificTargetYearDate]);
  
  // const calALFinSpecificMonthV = calculateAnnualLeaveCalALFinMonth(enterDate, finBaseDate, numOfWorkers, specificTargetYearDate)[0]
  const [openLastThreeYearAL, setOpenLastThreeYearAL] = useState(false)

  const calALFinSpecificRatioYearV = calALFinSpecificRatioYear(enterDate, finBaseDate, numOfWorkers, specificTargetYearDate)
  const calALFinSpecificBagicYearV = calALFinSpecificBagicYear(enterDate, finBaseDate, numOfWorkers, specificTargetYearDate)
  const calALFinSpecificPlusYearV = calALFinSpecificPlusYear(enterDate, finBaseDate, numOfWorkers, specificTargetYearDate)
  const sumCalALFinSpecific = 
    (calALFinSpecificMonthV ? calALFinSpecificMonthV : 0) 
    + (calALFinSpecificRatioYearV ? calALFinSpecificRatioYearV : 0)
    + (calALFinSpecificBagicYearV ? calALFinSpecificBagicYearV : 0)
    + (calALFinSpecificPlusYearV ? calALFinSpecificPlusYearV : 0)

  const calALSpecificMonthThisYearV = calALMonth(enterDate, numOfWorkers, specificTargetYearDate)[0]
  const calALSpecificMonthV =  Math.max(calALSpecificMonth(enterDate, numOfWorkers, specificTargetYearDate), 0)
  const calALSpecificBagicYearV = calALSpecificBagicYear(enterDate, numOfWorkers, specificTargetYearDate)
  const calALSpecificPlusYearV = calALSpecificPlusYear(enterDate, numOfWorkers, specificTargetYearDate)
  const sumCalALSpecific = secondYearForAnnualLeave(enterDate, targetYear) ? 
    (calALSpecificMonthThisYearV ? calALSpecificMonthThisYearV : 0) 
    + (calALSpecificBagicYearV ? calALSpecificBagicYearV : 0)
    + (calALSpecificPlusYearV ? calALSpecificPlusYearV : 0)
  : 
    (calALSpecificMonthV ? calALSpecificMonthV : 0)
     + (calALSpecificBagicYearV ? calALSpecificBagicYearV : 0)
     + (calALSpecificPlusYearV ? calALSpecificPlusYearV : 0)
  
  return (
    <>
      {!workerEnteredTargetYearBeforeYes ? <Button onClick={() => setOpenLastThreeYearAL(!openLastThreeYearAL)}>{!openLastThreeYearAL ? <span style={{color : 'blue'}}>{targetYear}년 연차현황 보기</span> : <span style={{color : 'teal'}}>{targetYear}년 연차현황 닫기</span> }</Button> : null}
      {(!workerEnteredTargetYearBeforeYes && openLastThreeYearAL) &&
        <>
          <hr />
          {finBaseDate
            ?
              <>
                <Item.Description>
                  <div><strong>{specificTargetYear}년12월31일 현재 연차현황[회계기준({finBaseMonthDay})]</strong><span style={{color: "red"}}>* (참고용)</span></div>
                </Item.Description>
                <Item.Description>
                  <Table  style={{textAlign : 'center'}}>
                    <Table.Header>
                      <Table.Row>
                        <Table.HeaderCell>구분</Table.HeaderCell>
                        <Table.HeaderCell>월 단위</Table.HeaderCell>
                        <Table.HeaderCell>비례</Table.HeaderCell>
                        <Table.HeaderCell>년기본</Table.HeaderCell>
                        <Table.HeaderCell>년가산</Table.HeaderCell>
                        <Table.HeaderCell>합계</Table.HeaderCell>
                      </Table.Row>
                    </Table.Header>
                    <Table.Body>
                      <Table.Row>
                        <Table.Cell><strong>회계기준</strong></Table.Cell>
                        <Table.Cell>{calALFinSpecificMonthV}</Table.Cell>
                        <Table.Cell>{calALFinSpecificRatioYearV}</Table.Cell>
                        <Table.Cell>{calALFinSpecificBagicYearV}</Table.Cell>
                        <Table.Cell>{calALFinSpecificPlusYearV}</Table.Cell>
                        <Table.Cell>{sumCalALFinSpecific}</Table.Cell>

                      </Table.Row>
                      <Table.Row>
                        <Table.Cell><strong>법정기준</strong></Table.Cell>
                        {secondYearForAnnualLeave(enterDate, targetYear) ? <Table.Cell>{calALSpecificMonthThisYearV}</Table.Cell> : <Table.Cell>{calALSpecificMonthV}</Table.Cell>}
                        <Table.Cell>'해당없음'</Table.Cell>
                        <Table.Cell>{calALSpecificBagicYearV}</Table.Cell>
                        <Table.Cell>{calALSpecificPlusYearV}</Table.Cell>
                        <Table.Cell>{sumCalALSpecific}</Table.Cell>

                      </Table.Row>
                    </Table.Body>
                  </Table>
                </Item.Description>
              </>
            :
              <>
                <Item.Description>
                  <div><strong>{specificTargetYear}년12월31일 현재 연차현황[법정(입사일)기준]</strong><span style={{color: "red"}}>* (참고용)</span></div>
                </Item.Description>
                <Item.Description>
                  <Table  style={{textAlign : 'center'}}>
                    <Table.Header>
                      <Table.Row>
                        <Table.HeaderCell>구분</Table.HeaderCell>
                        <Table.HeaderCell>월 단위</Table.HeaderCell>
                        <Table.HeaderCell>년기본</Table.HeaderCell>
                        <Table.HeaderCell>년가산</Table.HeaderCell>
                        <Table.HeaderCell>합계</Table.HeaderCell>

                      </Table.Row>
                    </Table.Header>
                    <Table.Body>
                      <Table.Row>
                        <Table.Cell>법정기준</Table.Cell>
                        {secondYearForAnnualLeave(enterDate, targetYear) ? <Table.Cell>{calALSpecificMonthThisYearV}</Table.Cell> : <Table.Cell>{calALSpecificMonthV}</Table.Cell>}
                        <Table.Cell>{calALSpecificBagicYearV}</Table.Cell>
                        <Table.Cell>{calALSpecificPlusYearV}</Table.Cell>
                        <Table.Cell>{sumCalALSpecific}</Table.Cell>
                      </Table.Row>
                    </Table.Body>
                  </Table>
                </Item.Description>
              </>
            }
        </>
      } 
    </>
  )
}
